export function convertNumberToWords(num: number): string {
  if (num === 0) return 'Zero';

  const ones = [
    '',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine',
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen',
  ];

  const tens = [
    '',
    '',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety',
  ];

  const thousands = ['', 'Thousand'];

  let words = '';

  // Convert thousands place
  if (num >= 1000) {
    words += ones[Math.floor(num / 1000)] + ' ' + thousands[1] + ' ';
    num %= 1000;
  }

  // Convert hundreds place
  if (num >= 100) {
    words += ones[Math.floor(num / 100)] + ' Hundred ';
    num %= 100;
  }

  // Convert tens and ones places
  if (num >= 20) {
    words += tens[Math.floor(num / 10)] + ' ';
    num %= 10;
  }

  if (num > 0) {
    words += ones[num] + ' ';
  }

  return words.trim();
}
