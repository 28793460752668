import React, { FC } from 'react';
import { Box, Tooltip } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { convertNumberToWords } from '../../../../utils';
import { IDuration } from './trigger/duration.helper';
import { EDuration } from './useSectionDuration';

interface TProps {
  duration: IDuration;
}

const TooltipInfo: FC<TProps> = ({ duration }) => {
  const { t } = useTranslation(['launcher']);
  return (
    <Box fontSize='12px'>
      <Box fontWeight='700'>
        <Trans
          t={t}
          i18nKey='launcher:configDurTooltip'
          count={duration?.durationValue}
          context={duration?.durationUnit}
          values={{ value: duration?.durationValue }}
        />
      </Box>

      <Trans
        t={t}
        i18nKey='launcher:configDurTooltipDesc'
        count={duration?.durationValue}
        context={duration?.durationUnit}
        values={{
          value: convertNumberToWords(duration.durationValue!).toLowerCase(),
        }}
      />
    </Box>
  );
};

interface IProps {
  duration: EDuration;
}

const DurationTooltip: FC<IProps> = ({ duration, children }) => {
  return (
    <Tooltip
      hasArrow
      shouldWrapChildren
      paddingX='8px'
      paddingY='6px'
      borderRadius='4px'
      maxW='260px'
      isDisabled={!duration?.isExpected}
      label={<TooltipInfo duration={duration} />}
    >
      {children}
    </Tooltip>
  );
};

export default DurationTooltip;
