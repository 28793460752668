import { Box, Flex } from '@chakra-ui/react';
import React, { useCallback, useRef } from 'react';
import { UseConfirm, useConfirm } from '../../../../../../ui-components';
import { HeaderColors } from '../../../../../../configs';
import DuplicateBoard, { DuplicateProps } from './DuplicateBoard';

interface IProps extends DuplicateProps {}

type IDuplicateBoard = (props: IProps) => void;

const useDuplicateBoard = (): IDuplicateBoard => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  return useCallback((props) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      title: (
        <Flex align='center'>
          <Box
            mr={3}
            w={3}
            minW={3}
            borderRadius={4}
            bg={HeaderColors.Purple}
            style={{ aspectRatio: '1/2' }}
          />
          <Flex flexDir='column' overflow='hidden'>
            <Box fontWeight='600' fontSize='16px' color='#1a1d1f' isTruncated>
              {`Duplicate ${props.title}`}
            </Box>
            <Box fontSize='12px' fontWeight='400' color='#6F767E'>
              Note: This will include all phase and task settings
            </Box>
          </Flex>
        </Flex>
      ),
      content: <DuplicateBoard {...props} />,
      isCentered: true,
      size: '2xl',
      footer: null,
      contentProps: {
        paddingTop: '8px',
        paddingBottom: '24px',
        paddingInline: '8px',
        borderRadius: '12px',
      },
    });
  }, []);
};

export default useDuplicateBoard;
