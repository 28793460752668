import { gql } from '@apollo/client';
import {
  LauncherAssignee,
  LauncherConfigFragment,
  LauncherTaskStep,
} from './fragment.graphql';
import { TASK_DEPENDENCY } from '../../../../types';

interface NewLauncherTask extends LauncherAssignee {
  description?: string;
  title: string;
  selected?: boolean;
  duration?: number;
  docRequired?: boolean;
  completed?: boolean;
  dependency?: TASK_DEPENDENCY;
  steps: LauncherTaskStep[];
}

interface Content extends LauncherAssignee {
  category: string;
  selected?: boolean;
  tasks: NewLauncherTask[];
}

export interface NewLauncherInput {
  contents: Content[];
  description?: string;
  published?: boolean;
  title: string;
}

export const ADD_CONFIG_QUERY = gql`
  ${LauncherConfigFragment}
  mutation AddLauncher($input: NewLauncherAddInput) {
    AddLauncher(input: $input) {
      ...LauncherConfig
    }
  }
`;
