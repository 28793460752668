import { Box, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiagramSubtask } from '@fortawesome/pro-light-svg-icons';
import React, { FC } from 'react';

interface IProps {
  tasks: any[];
  duration?: string;
}

const PhaseButtonLower: FC<IProps> = ({ tasks, duration }) => {
  return (
    <Flex gap={6} fontSize='14px' color='#6F767E'>
      <Box>
        <FontAwesomeIcon icon={faDiagramSubtask as IconProp} />{' '}
        {`${tasks?.length} ${tasks?.length === 1 ? 'task' : 'tasks'}`}
      </Box>
      {duration ? <Box>&bull;</Box> : null}
      {duration ? <Box>{duration}</Box> : null}
    </Flex>
  );
};

PhaseButtonLower.displayName =
  'displayName:pages/LocationsNew/Components/ProjectDetailPhase/components/PhaseAccordionButton/PhaseButtonLower';

export default PhaseButtonLower;
