import { AccordionButton, Flex } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import PhaseButtonLower from './PhaseButtonLower';
import PhaseButtonUpper from './phase-button-upper/PhaseButtonUpper';
import {
  ExtendedLauncherContent,
  PhaseData,
} from '../../store/project-detail-phase.types';
import { useProjectDetailPhaseContext } from '../../store/context';

interface IProps {
  eid: string;
  index: number;
}

const PhaseAccordionButton: FC<IProps> = ({ eid, index }) => {
  const { phaseData } = useProjectDetailPhaseContext((state) => ({
    phaseData: state?.launcherData,
  }));
  const data = useMemo(() => {
    return phaseData?.contents?.filter((content) => content?.eid === eid)?.[0]!;
  }, [eid, phaseData]);
  return (
    <AccordionButton>
      <Flex align='center' justify='space-between' w='full'>
        <Flex flexDir='column' gap={4} w='full'>
          <PhaseButtonUpper index={index} eid={eid} />
          <PhaseButtonLower tasks={data?.tasks} duration={data?.duration} />
        </Flex>
      </Flex>
    </AccordionButton>
  );
};

PhaseAccordionButton.displayName =
  'displayName:pages/LocationsNew/Components/ProjectDetailPhase/components/PhaseAccordionButton/PhaseAccordionButton';

export default PhaseAccordionButton;
