import React, { FC, useMemo } from 'react';
import { Flex, IconButton, Image, Tooltip } from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';
import styled from '@emotion/styled';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { IconImage, IconsPlaceholder } from '../../../../../ui-components';
import { usersEntityObj } from '../../../../../sub-components/Header';

import { LauncherAssignee } from '../../common';
import { useAddAssignee } from '../../post-setup/add-assignee';

interface AProps {
  userIds?: string[];
}

const AssigneeList: FC<AProps> = ({ userIds = [] }) => {
  const entityObjData = useReactiveVar(usersEntityObj);

  const users = useMemo(() => {
    return entityObjData?.filter((u) => userIds.includes(u.eid));
  }, [entityObjData, userIds]);

  return (
    <React.Fragment>
      {users?.map((user) => {
        return (
          <Flex
            key={user.eid}
            align='center'
            gap={2}
            bg='#B1E5FC80'
            paddingX={2}
            borderRadius='5px'
            fontWeight='500'
            cursor='pointer'
            color='#000'
            height={8}
          >
            <IconImage
              name={user.name}
              thumbnail={user.profilePic}
              borderRadius='4px'
              boxSize={18}
            />
            <span>{user.name}</span>
          </Flex>
        );
      })}
    </React.Fragment>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  //justify-content: end;

  > img,
  > div {
    //margin-left: -12px;
  }
`;

interface IProps {
  assignee: LauncherAssignee;
  onChange: (assignee: LauncherAssignee) => void;
}

const TaskAssignee: FC<IProps> = ({ assignee, onChange }) => {
  const editAssignee = useAddAssignee();

  const onEditClick = () => {
    editAssignee({
      value: assignee,
      onChange: onChange,
    });
  };

  if (assignee?.assignedType === 'user') {
    return (
      <Wrapper>
        <AssigneeList userIds={assignee?.assignedUser} />

        <IconButton
          size='sm'
          aria-label='message'
          variant='outline'
          icon={<FontAwesomeIcon icon={faPen as IconProp} />}
          onClick={onEditClick}
        />
      </Wrapper>
    );
  }

  return (
    <Flex gap={2}>
      <Tooltip
        label='Location owners of the launching location'
        hasArrow
        borderRadius='6px'
        padding='10px'
        placement='bottom-end'
        maxW='200px'
        bg='#000000CC'
        fontSize='12px'
      >
        <Flex
          align='center'
          gap={2}
          bg='#B1E5FC80'
          paddingX={2}
          borderRadius='5px'
          fontWeight='500'
          cursor='pointer'
          color='#000'
          height={8}
        >
          <Image
            src={IconsPlaceholder['location']}
            borderRadius='4px'
            boxSize='20px'
          />
          <span>Location owners</span>
        </Flex>
      </Tooltip>

      <IconButton
        size='sm'
        aria-label='message'
        variant='outline'
        icon={<FontAwesomeIcon icon={faPen as IconProp} />}
        onClick={onEditClick}
      />
    </Flex>
  );
};

export default TaskAssignee;
