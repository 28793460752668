import { Switch } from '@chakra-ui/react';
import Dropdown from 'atoms/Dropdown';
import React, { useEffect, useState } from 'react';
import MultiSelectChipDropdown from 'sub-components/MultiSelectChipDropdown';
import { IMultiSelectListData } from 'sub-components/MultiSelectChipDropdown/MultiSelectChipDropdown';
import { IThirdStepData } from '../assign-publish-types.ts/AssignPublishTypes';
import { AssignPublishFormWrapper } from './AssignPublishForm.styles';
import CardColorHeader from './CardColorHeader';
import { useTranslation } from 'react-i18next';
import { CustomSelectComponent } from 'sub-components/CustomDropdowns';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { AuthRole } from 'authorization';

interface IAssignPublishForm {
  setThirdStepSelections: React.Dispatch<React.SetStateAction<IThirdStepData>>;
  thirdStepData: IThirdStepData;
  thirdStepSelections: IThirdStepData;
  setThirdStepData: React.Dispatch<React.SetStateAction<IThirdStepData>>;
}

const AssignPublishForm = ({
  thirdStepSelections,
  thirdStepData,
  setThirdStepSelections,
  setThirdStepData,
}: IAssignPublishForm) => {
  const { t } = useTranslation('training');
  const [activateLocationMemberForm, setActivateLocationMemberForm] =
    useState(false);

  useEffect(() => {
    if (
      thirdStepSelections?.locations?.length > 0 ||
      thirdStepSelections?.members?.length > 0
    ) {
      setActivateLocationMemberForm(true);
    }
  }, [thirdStepSelections]);

  const selectedRolesHandler = (selectedRoles: IMultiSelectListData[]) => {
    setThirdStepSelections({ ...thirdStepSelections, roles: selectedRoles });
  };

  const selectedSupervisorsHandler = (
    selectedSupervisors: IMultiSelectListData[]
  ) => {
    setThirdStepSelections({
      ...thirdStepSelections,
      supervisors: selectedSupervisors,
    });
  };

  const activateLocationMemberFormHandler = () => {
    setActivateLocationMemberForm(true);
  };

  const selectedLocationsHandler = (
    selectedLocations: IMultiSelectListData[]
  ) => {
    setThirdStepSelections({
      ...thirdStepSelections,
      locations: selectedLocations,
    });
  };

  const selectedMembersHandler = (selectedMembers: IMultiSelectListData[]) => {
    setThirdStepSelections({
      ...thirdStepSelections,
      members: selectedMembers,
    });
  };

  const getSelectedMembers = () => {
    return thirdStepSelections?.members?.map((member) => member?.id);
  };

  const getSelectedSupervisors = () => {
    return thirdStepSelections?.supervisors?.map(
      (supervisor) => supervisor?.id
    );
  };

  const membersChangeHandler = (values: string[]) => {
    let foundMembers = thirdStepData?.members?.filter((member) =>
      values?.includes(member?.id)
    );
    foundMembers?.forEach((member) => (member.selected = true));
    setThirdStepSelections({ ...thirdStepSelections, members: foundMembers });
  };

  const supervisorsChangeHandler = (values: string[]) => {
    let foundSupervisors = thirdStepData?.supervisors?.filter((supervisor) =>
      values?.includes(supervisor?.id)
    );
    foundSupervisors?.forEach((supervisor) => (supervisor.selected = true));
    setThirdStepSelections({
      ...thirdStepSelections,
      supervisors: foundSupervisors,
    });
  };

  const getMemberOptions = () => {
    let _members: IMultiSelectListData[] = JSON.parse(
      JSON.stringify(thirdStepData?.members)
    );
    _members?.forEach((member) => {
      member.value = member.id;
    });
    return _members;
  };

  const getSupervisorOptions = () => {
    let _supervisors: IMultiSelectListData[] = JSON.parse(
      JSON.stringify(thirdStepData?.supervisors)
    );
    _supervisors?.forEach((supervisor) => {
      supervisor.value = supervisor.id;
    });
    return _supervisors?.filter((sup) => sup?.authRole !== AuthRole?.WORKER);
  };

  return (
    <AssignPublishFormWrapper>
      <CardColorHeader heading={t('who_will_undergo')} color='#cabdff'>
        <div>
          <label className='label'>{t('assignToJobs')}</label>
          <MultiSelectChipDropdown
            thirdStepSelections={thirdStepSelections}
            placeholder={t('assignToJobsPlaceholder')}
            dropDownData={thirdStepData.roles}
            color='rgba(255, 216, 141, 0.7)'
            selectedDataHandler={selectedRolesHandler}
            setThirdStepData={setThirdStepData}
            thirdStepData={thirdStepData}
            type='role'
            showImage={false}
          />
        </div>
        {!activateLocationMemberForm && (
          <span
            style={{
              color: '#2a85ff',
              textDecoration: 'underline',
              fontSize: '14px',
              cursor: 'pointer',
            }}
            onClick={activateLocationMemberFormHandler}
          >
            {t('like_to_assign_instead')}
          </span>
        )}
        {activateLocationMemberForm && (
          <div>
            <div>
              <label className='label'>{t('assign_to_locations')}</label>
              <MultiSelectChipDropdown
                thirdStepSelections={thirdStepSelections}
                placeholder={t('assign_to_locations_placeholder')}
                dropDownData={thirdStepData.locations}
                color='rgba(181, 228, 202, 0.6)'
                selectedDataHandler={selectedLocationsHandler}
                setThirdStepData={setThirdStepData}
                thirdStepData={thirdStepData}
                type='location'
                showImage={false}
              />
            </div>
          </div>
        )}
        {activateLocationMemberForm && (
          <div>
            <div>
              <label className='label'>{t('assign_to_members')}</label>
              {/* <MultiSelectChipDropdown
                thirdStepSelections={thirdStepSelections}
                placeholder={t('assign_to_members_placeholder')}
                dropDownData={thirdStepData.members}
                color='rgba(202, 189, 255, 0.4)'
                selectedDataHandler={selectedMembersHandler}
                setThirdStepData={setThirdStepData}
                thirdStepData={thirdStepData}
                type='member'
                showImage={true}
              /> */}
              <CustomSelectComponent
                options={getMemberOptions()}
                value={getSelectedMembers()}
                placeholder='Select member(s)'
                showHeader
                headerTitle='Select member(s)'
                headerBarColor={HeaderColors.Purple}
                showHeaderCloseIcon
                showSearchField
                showDivider
                bottomOutsideBtn={{
                  show: true,
                  title: 'Add selected members',
                }}
                showFilter
                showSelectAll
                onChange={(values: string[]) => membersChangeHandler(values)}
              />
            </div>
          </div>
        )}
      </CardColorHeader>

      <CardColorHeader
        heading='Who will be supervising this training?'
        color='#b1e5fc'
      >
        <div>
          <label className='label'>{t('assign_to_supervisors')}</label>
          {/* <MultiSelectChipDropdown
            thirdStepSelections={thirdStepSelections}
            placeholder={t('assign_to_supervisors_placeholder')}
            dropDownData={thirdStepData.supervisors}
            color='#b1e5fc'
            selectedDataHandler={selectedSupervisorsHandler}
            setThirdStepData={setThirdStepData}
            thirdStepData={thirdStepData}
            type='supervisor'
            showImage={true}
          /> */}
          <CustomSelectComponent
            authRoleFilterOptions={[
              {
                label: 'Super Admins',
                value: AuthRole.SUPER_ADMIN,
              },
              {
                label: 'Admins',
                value: AuthRole.ADMIN,
              },
              {
                label: 'Location Owners',
                value: AuthRole.LOCATION_OWNER,
              },
            ]}
            options={getSupervisorOptions()}
            value={getSelectedSupervisors()}
            placeholder='Select supervisor(s)'
            showHeader
            headerTitle='Select supervisor(s)'
            headerBarColor={HeaderColors.Purple}
            showHeaderCloseIcon
            showSearchField
            showDivider
            bottomOutsideBtn={{
              show: true,
              title: 'Add selected supervisors',
            }}
            showFilter
            showSelectAll
            onChange={(values: string[]) => supervisorsChangeHandler(values)}
          />
        </div>
      </CardColorHeader>

      {/* <CardColorHeader
        heading='How often should it be assigned to people?'
        color='#ffbc99'
      >
        <div className='repeat-training-div'>
          <label className='label'>Repeat every day, week, month, etc</label>
          <Switch size={'lg'} />
        </div>
      </CardColorHeader> */}
    </AssignPublishFormWrapper>
  );
};

AssignPublishForm.displayName =
  'displayName:pages/Training/CreateTrainingPath/CreateContainer/AssignPublish/AssignPublishForm/AssignPublishForm';

export default AssignPublishForm;
