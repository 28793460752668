import React, { FC } from 'react';
import { useSearchParam } from '../../../hooks';
import { useRouteState } from '../../../routes';

import { LauncherConfigProvider } from './common';
import { ConfigBoardView } from './board-view';
import { ConfigEditorView } from './editor-view';

type ViewMode = 'editor' | 'board';

interface IProps {}

const LauncherBoardConfig: FC<IProps> = () => {
  const viewMode = useSearchParam<{ viewMode: ViewMode }>().viewMode;

  const { state } = useRouteState({
    viewMode: viewMode,
  });

  return (
    <LauncherConfigProvider>
      {state.viewMode === 'editor' ? <ConfigEditorView /> : <ConfigBoardView />}
    </LauncherConfigProvider>
  );
};

LauncherBoardConfig.displayName =
  'sub-components/Launcher/launcher-config/LauncherBoardConfig';

export default LauncherBoardConfig;
