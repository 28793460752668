import React, { FC, useMemo } from 'react';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { usersEntityObj } from '../../../../Header';
import { IconImage, IconsPlaceholder } from '../../../../../ui-components';

import { LauncherAssignee } from '../launcher-config.fragment';

const AssigneeList: FC = ({ children }) => {
  const assignedUser = useWatch<LauncherAssignee, 'assignedUser'>({
    name: 'assignedUser',
  });
  const entityObjData = useReactiveVar(usersEntityObj);

  const users = useMemo(() => {
    return entityObjData?.filter((u) => assignedUser?.includes(u.eid));
  }, [entityObjData, assignedUser]);

  return (
    <Flex gap={2} flexWrap='wrap'>
      {users?.map((user) => (
        <Flex
          key={user.eid}
          gap={2}
          padding='4px 10px 4px 6px'
          bg='#B1E5FC80'
          borderRadius='5px'
          alignItems='center'
          height={7}
        >
          <IconImage
            name={user.name}
            thumbnail={user.profilePic}
            borderRadius='5px'
            boxSize={20}
          />
          <Box fontSize='13px' fontWeight='500'>
            {user.name}
          </Box>
        </Flex>
      ))}

      {children}
    </Flex>
  );
};

interface IProps {
  onEditClick?: () => void;
}

const PhaseSettingAssignee: FC<IProps> = ({ onEditClick }) => {
  const assignedType = useWatch<LauncherAssignee, 'assignedType'>({
    name: 'assignedType',
  });

  if (assignedType === 'user') {
    return (
      <AssigneeList>
        <IconButton
          aria-label='edit-button'
          variant='outline'
          size='xs'
          onClick={onEditClick}
          boxSize={7}
        >
          <FontAwesomeIcon icon={faPen as IconProp} />
        </IconButton>
      </AssigneeList>
    );
  }

  return (
    <Flex gap={2} flexWrap='wrap'>
      <Flex
        gap={2}
        padding='4px 10px 4px 6px'
        bg='#B1E5FC80'
        borderRadius='5px'
        alignItems='center'
        height={7}
      >
        <IconImage
          name=''
          thumbnail={IconsPlaceholder['location']}
          borderRadius='5px'
          boxSize={20}
        />
        <Box fontSize='13px' fontWeight='500'>
          Location owner
        </Box>
      </Flex>

      <IconButton
        aria-label='edit-button'
        variant='outline'
        size='xs'
        onClick={onEditClick}
        boxSize={7}
      >
        <FontAwesomeIcon icon={faPen as IconProp} />
      </IconButton>
    </Flex>
  );
};

PhaseSettingAssignee.displayName = 'sc/L/lc/c/pd/PhaseSettingAssignee';

export default PhaseSettingAssignee;
