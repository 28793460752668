import React, { FC } from 'react';
import { Box, Center, Image, Tooltip, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { getImage } from 'utils';

interface IAssignee {
  eid: string;
  name: string;
  profilePic: string;
}

interface IProps {
  assignees: IAssignee[];
}

const AuditAssignee: FC<IProps> = ({ assignees = [] }) => {
  const { t } = useTranslation('training');
  const displayedAssignees = assignees.slice(0, 2);
  const moreAssignee = assignees.length - 2;
  const flexDirection = moreAssignee > 9 ? 'column' : 'row';

  return (
    <Flex direction={flexDirection} alignItems='center' gap='5px'>
      <Tooltip
        label={assignees.map((value) => value.name).join(', ')}
        hasArrow
        p='8px 12px'
        borderRadius='8px'
      >
        <Flex position='relative' width='max-content'>
          {displayedAssignees.map((user, index) => (
            <Image
              key={user.eid}
              src={getImage(user.profilePic, user.name)}
              width='20px'
              height='20px'
              borderRadius='50%'
              ml={index === 0 ? 0 : '-8px'}
            />
          ))}
        </Flex>
      </Tooltip>
      {moreAssignee > 0 && (
        <Box
          as='span'
          fontWeight={500}
          fontSize='10px'
          color='rgba(111, 118, 126, 1)'
        >
          +{moreAssignee}
        </Box>
      )}
      {/* {moreAssignee > 0 &&
        (moreAssignee < 10 ? (
          <Center
            border='1px solid #6f767e'
            borderRadius='50%'
            width='20px'
            height='20px'
            fontSize='10px'
            fontWeight='500'
            color='#6f767e'
            ml='4px'
          >
            +{moreAssignee}
          </Center>
        ) : (
          <Box
            fontSize='12px'
            fontWeight='500'
            color='#6f767e'
            pt='2px'
            ml='4px'
          >
            {t('count_more', { value: moreAssignee })}
          </Box>
        ))} */}
    </Flex>
  );
};

AuditAssignee.displayName =
  'sub-components/audits/audit-tab/supervised/components/GridView/components/AuditAssignee';

export default AuditAssignee;
