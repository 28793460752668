import { useMutation } from '@apollo/client';
import React from 'react';
import { LocationLaunchTask } from 'types/location-launcher/details/location-tasks';
import { MARK_LAUNCHER_TASK_UNDONE } from './state-drawer.graphql';
import {
  MarkTaskUndoneResponse,
  MarkTaskUndoneVariables,
} from './state-drawer.types';

interface IProps {
  stepId: string;
  taskId: string;
  launchId: string;
  formResponseId?: string;
}

interface MarkIncompleteCallbacks {
  onSuccess?: (data?: LocationLaunchTask) => void;
  onError?: () => void;
}

type IMarkAsUndone = (props: IProps) => void;

export const useMarkAsUndone = (callbacks?: MarkIncompleteCallbacks) => {
  const [markAsUndone, { loading: markAsUndoneLoading }] = useMutation<
    MarkTaskUndoneResponse,
    MarkTaskUndoneVariables
  >(MARK_LAUNCHER_TASK_UNDONE);

  const handleMarkIncomplete = async ({
    launchId,
    stepId,
    taskId,
    formResponseId,
  }: IProps) => {
    return await markAsUndone({
      variables: {
        launchId: launchId,
        taskId: taskId,
        ...(stepId ? { stepId: stepId } : {}),
      },
    });
  };

  return { markAsUndone: handleMarkIncomplete, markAsUndoneLoading };
};
