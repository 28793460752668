import React, { FC } from 'react';
import { Box, Flex, IconButton, Tooltip } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { useLauncherConfigContext } from '../../common';
import { usePhaseSetting } from '../../common/phase-details';
import SectionDuration from './SectionDuration';

import SectionAssignee from '../../board-view/sections/SectionAssignee'; // TODO

import { ReactComponent as EditIcon } from '../../../../../assets/images/edit-black.svg';

interface IProps {
  sectionIndex: number;
}

const SectionHeader: FC<IProps> = ({ sectionIndex }) => {
  const phaseSetting = usePhaseSetting();

  const section = useLauncherConfigContext((state) => {
    const value = state.config.contents?.[sectionIndex];

    return {
      eid: value?.eid,
      title: value?.category,
      totalTasks: value?.tasks?.length,
      assignedType: value?.assignedType,
      assignedUser: value?.assignedUser,
      duration: 10,
      select: state.selectSection,
      isSelected: state.selectedSection === sectionIndex,
    };
  });

  return (
    <Flex
      gap={3}
      paddingInline='24px'
      paddingBlock='12px'
      align='center'
      bg='#FFFFFF'
      borderBlock='1px solid #6F767E'
    >
      <Flex
        flex={1}
        gap={3}
        align='center'
        paddingBlock='12px'
        onClick={() => section.select(sectionIndex)}
        cursor='pointer'
      >
        <FontAwesomeIcon
          icon={faPlay as IconProp}
          color='#6F767E'
          rotation={section.isSelected ? 90 : undefined}
          style={{
            transition: 'transform 300ms ease-in-out',
          }}
        />
        <Box flex={1} fontSize='18px' fontWeight='600'>
          {section?.title} ({section.totalTasks})
        </Box>
      </Flex>
      <Flex gap={3} align='center'>
        <SectionDuration sectionIndex={sectionIndex} />
        <SectionAssignee
          assignedType={section.assignedType!}
          assignedUser={section.assignedUser || []}
        />
        <Tooltip
          hasArrow
          paddingX='8px'
          paddingY='6px'
          borderRadius='4px'
          placement='bottom-end'
          label='Add phase triggers and edit other phase details'
        >
          <IconButton
            size='sm'
            aria-label='edit-section'
            variant='outline'
            borderRadius='4px'
            icon={<EditIcon width={21} height={21} />}
            onClick={() => {
              phaseSetting({
                sectionIndex: sectionIndex,
              });
            }}
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
};

SectionHeader.displayName = 'sc/L/lc/ev/s/SectionHeader';

export default SectionHeader;
