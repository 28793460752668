import React, { FC } from 'react';
import {
  Checkbox,
  CheckboxGroup,
  Flex,
  Stack,
  useModalContext,
} from '@chakra-ui/react';
import { Button } from '../../../../../../../../atoms';
import { useSafeState } from '../../../../../../../../hooks';

import TitleHeader from '../../../../../../../CardEditor/TitleHeader';
import SelectAdminUser from './SelectAdminUser';
import UserListContainer from './UserListContainer';
import { useLocationOwners } from './useLocationOwners';

interface IValues {
  admins?: string[];
  owners?: string[];
}

interface IProps {
  initialValues?: IValues;
  locationId?: string;
  onSaveClick?: (values: IValues) => void;
}

type RemindType = ('admins' | 'owners')[];

const RemindToOtherContent: FC<IProps> = ({
  locationId,
  onSaveClick,
  initialValues,
}) => {
  const [admins, updateAdmins] = useSafeState<string[]>(
    initialValues?.admins || []
  );

  const [owners, updateOwners] = useSafeState<string[]>(
    initialValues?.owners || []
  );
  const [value, updateValue] = useSafeState<RemindType>(() => {
    const value: RemindType = [];
    if (initialValues?.owners?.length) {
      value.push('owners');
    }
    if (initialValues?.admins?.length) {
      value.push('admins');
    }
    return value;
  });

  const { options } = useLocationOwners(locationId);

  const { onClose } = useModalContext();

  const onAdminRemove = (user: string) => {
    updateAdmins((prevState) => {
      return prevState.filter((U) => U !== user);
    });
  };

  const onOwnerRemove = (user: string) => {
    updateOwners((prevState) => {
      return prevState.filter((U) => U !== user);
    });
  };

  const isEmpty = !(admins?.length + owners?.length);

  const onClick = () => {
    onSaveClick?.({
      admins: admins.slice(),
      owners: owners.slice(),
    });
    setTimeout(onClose);
  };

  return (
    <Flex flexDir='column'>
      <UserListContainer
        admins={admins}
        owners={owners}
        removeAdmin={onAdminRemove}
        removeOwner={onOwnerRemove}
        locationId={locationId}
      />

      <TitleHeader title='Whom would you like to remind?' />

      <CheckboxGroup value={value} onChange={updateValue as never}>
        <Stack
          marginTop='16px'
          spacing={4}
          sx={{
            '.chakra-checkbox__label': {
              fontSize: 13,
              fontWeight: 600,
            },
            '.chakra-checkbox__control': {
              borderRadius: '6px',
            },
          }}
          hidden={!locationId}
        >
          <Checkbox
            size='lg'
            value='owners'
            name='remind-to'
            onChange={(event) => {
              if (event.target.checked) {
                updateOwners(options);
              } else {
                updateOwners([]);
              }
            }}
          >
            Location owner of the selected location
          </Checkbox>
          <Checkbox size='lg' value='admins' name='remind-to'>
            Super admins/ Admins
          </Checkbox>
        </Stack>
        <SelectAdminUser
          withoutLocation={!locationId}
          value={admins}
          onChange={updateAdmins}
        />
      </CheckboxGroup>

      <Flex
        flexDir='column'
        position='sticky'
        bottom='-3.25rem'
        marginTop='8px'
      >
        <Button colorScheme='blue' isDisabled={isEmpty} onClick={onClick}>
          Done
        </Button>
      </Flex>
    </Flex>
  );
};

RemindToOtherContent.displayName =
  'displayName:packages/sop-web/src/sub-components/nexus/Compliance/Create/components/common/document-reminder/remind-to-user/RemindToOtherContent';
export default RemindToOtherContent;
