import React, { FC, useEffect, useState } from 'react';
import { Box, Button, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUserDataSelector } from '../../../../../../hooks';
import { ChannelConfig } from '../../../../../../types';

import { CustomSelectComponent } from '../../../../../../sub-components/CustomDropdowns';
import { SelectOption } from '../../../../../../sub-components/tasks/create-task/common';
import { useMembersData } from '../../../../../../sub-components/tasks/create-task/Supervisors';

import { IFormInput } from '../../create-channel.types';

interface IProps {
  control: Control<IFormInput>;
  prefix: keyof Omit<ChannelConfig, 'channelCantLeave'>;
}

const InnerMemberSelection: FC<IProps> = ({ control, prefix }) => {
  const { t } = useTranslation(['task', 'setting']);

  const entityId = useUserDataSelector((state) => state?.entityId);

  const [members, setMembers] = useState<SelectOption[]>([]);

  const { getUsersData, loading } = useMembersData(setMembers);

  useEffect(() => {
    getUsersData({
      variables: {
        entityId: entityId,
        status: ['active'],
        type: ['user'],
      },
    });
  }, [entityId]);

  return (
    <Box>
      <Box fontSize='14px' fontWeight={500} color='#6F767E' mb='6px'>
        Select members
      </Box>

      <Controller
        control={control}
        name={`${prefix}.members`}
        render={({ field, fieldState }) => {
          return (
            <FormControl mt={2} isInvalid={!!fieldState?.error}>
              <CustomSelectComponent
                placeholder={t('task:placeholder.selectMembers')}
                placeholderColor='#1A1D1F'
                headerTitle={t('task:placeholder.selectMembers')}
                bottomOutsideBtn={{
                  show: true,
                  title: t('task:placeholder.addSelectMembers'),
                }}
                headerBarColor='#CABDFF'
                searchFieldPlaceholder={t('setting:searchByNameJobOrLoc')}
                options={members}
                showFilter
                value={field.value}
                showDivider
                showSelectAll
                showSearchField
                showHeader
                showHeaderCloseIcon
                onChange={field.onChange}
                loading={loading}
              />
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </Box>
  );
};

const MemberSelection: FC<IProps> = ({ control, prefix }) => {
  const [addMember, setAddMember] = useState(false);

  const value = useWatch({
    control,
    name: `${prefix}.members`,
    exact: true,
  });

  if (addMember || value?.length) {
    return <InnerMemberSelection prefix={prefix} control={control} />;
  }

  return (
    <Button
      variant='link'
      fontSize='14px'
      fontWeight='400'
      colorScheme='blue'
      textDecoration='underline'
      onClick={() => setAddMember(true)}
    >
      Would you like to assign to specific members instead?
    </Button>
  );
};

export default MemberSelection;
