import React, { CSSProperties, FC, ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

interface TitleHeaderProps {
  title: string | ReactNode;
  desc?: string;
  titleStyle?: CSSProperties;
  descStyle?: CSSProperties;
  isRequired?: boolean;
}

const TitleHeader: FC<TitleHeaderProps> = ({
  title,
  desc,
  titleStyle,
  descStyle,
  isRequired,
}: TitleHeaderProps) => {
  return (
    <>
      <Box
        fontWeight='600'
        fontSize='14px'
        lineHeight='24px'
        letterSpacing='-0.01em'
        color='#33383f'
        style={titleStyle}
      >
        {title} {isRequired ? <span style={{ color: 'red' }}> *</span> : null}
      </Box>
      {desc && (
        <Box
          fontWeight='400'
          fontSize='11px'
          lineHeight='18px'
          letterSpacing='-0.01em'
          color='#6f767e'
          style={descStyle}
        >
          {desc}
        </Box>
      )}
    </>
  );
};

TitleHeader.displayName = 'sc/CE/TitleHeader';

export default TitleHeader;
