import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  Box,
  Center,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  RadioGroup,
  useControllableState,
} from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { eventBus } from '../../../../../shared/eventEmit';
import {
  BaseTriggerEntity,
  LauncherContent,
  TriggerEntity,
} from '../launcher-config.fragment';
import PhaseList from './PhaseList';
import { PhaseSelectItem } from './PhaseSelect';
import { TriggerInput } from './trigger.types';
import { triggerKey } from './trigger.data';
import { TriggerSelectWrapper } from './phase-select.styles';

const checkDeadline = (trigger: TriggerEntity | null) => {
  if (!trigger || trigger?.triggerChoice === 'default') {
    return false;
  }
  switch (trigger?.triggerChoice) {
    case 'activation':
      return trigger?.activationTrigger?.deadlineType === 'duration';
    case 'deadline':
      return !!trigger?.deadlineTrigger?.dependentOn;
  }
};

export const mapPhases = (contents: LauncherContent[] = []) => {
  return contents.map((con) => ({
    eid: con.eid!,
    title: con.category!,
    // haveDeadline: checkDeadline(con.trigger), // Disable phases selection without deadline
    tasks: con.tasks.map((task) => ({
      eid: task.eid!,
      title: task.title!,
      // haveDeadline: checkDeadline(task.trigger || con.trigger), // Disable task selection without deadline
    })),
  }));
};

interface IProps {
  taskPhaseId: string;
  triggerType: 'activationTrigger' | 'deadlineTrigger';
  value?: string | null;
  onChange?: (value: string) => void;
  dependOnValue?: BaseTriggerEntity['dependentOn'];
  onDependChange?: (value?: BaseTriggerEntity['dependentOn']) => void;
  type: 'phase' | 'task';
}

const PhaseTaskSelect: FC<IProps> = ({
  taskPhaseId,
  triggerType,
  type,
  value,
  onChange,
  dependOnValue,
  onDependChange,
}) => {
  const { t, i18n } = useTranslation('launcher');
  const [phases, setPhases] = useState<PhaseSelectItem[]>([]);

  const triggerOrder = useWatch<
    TriggerInput,
    `trigger.${typeof triggerType}.order`
  >({
    name: `trigger.${triggerType}.order`,
  });

  useEffect(() => {
    eventBus.emit('GET_CONFIG', (state: any) => {
      setPhases(mapPhases(state.config.contents));
    });
  }, []);

  const [dependOn, updateDepend] = useControllableState<
    BaseTriggerEntity['dependentOn']
  >({
    value: dependOnValue,
    onChange: onDependChange,
  });

  const [state, updateState] = useControllableState<string>({
    value: value!,
    onChange: onChange,
    // defaultValue: 'standard',
  });

  const onSectionChange = (newValue: string) => {
    const [itemId, dependValue] = newValue.split('-');
    // @ts-ignore
    updateState(itemId ? itemId : null);
    updateDepend(dependValue as never);
  };

  const stateValue = useMemo((): string => {
    if (!state && !dependOn) {
      if (type === 'phase') {
        return t('trigger.selectPhase');
      }
      return t('trigger.selectPhaseOrTask');
    }

    if (triggerKey[dependOn]) {
      return triggerKey[dependOn];
    }

    for (let i = 0; i < phases.length; i++) {
      const phase = phases[i];
      if (phase.eid === state) {
        return `Phase ${i + 1} - ${phase.title}`;
      } else if (dependOn === 'task') {
        for (let j = 0; j < phase.tasks.length; j++) {
          const task = phase.tasks[j];
          if (task.eid === state) {
            return `Phase ${i + 1}, Task  ${j + 1} - ${task.title}`;
          }
        }
      }
    }

    return '';
  }, [phases, state, dependOn, type, i18n.language]);

  const internalValue = [state || '', dependOn].join('-');

  return (
    <Popover matchWidth strategy='fixed'>
      {/*// @ts-ignore */}
      <PopoverTrigger>
        <Flex
          gap={2}
          width='100%'
          alignItems='center'
          height='48px'
          minWidth={0}
          borderRadius='12px'
          borderWidth={2}
          borderColor='#EFEFEF'
          paddingInline='14px'
        >
          <Box
            flex={1}
            fontWeight='600'
            color={stateValue ? '#2A85FF' : '#6F767E'}
            isTruncated
          >
            {stateValue}
          </Box>
          <Center boxSize='24px'>
            <FontAwesomeIcon
              fontSize={16}
              color='#2A85FF'
              icon={faChevronDown as IconProp}
            />
          </Center>
        </Flex>
      </PopoverTrigger>
      <PopoverContent width='inherit'>
        <PopoverArrow />
        <PopoverBody maxHeight='380px' overflow='auto'>
          <TriggerSelectWrapper>
            <RadioGroup value={internalValue} onChange={onSectionChange}>
              <Accordion allowToggle>
                <PhaseList
                  taskPhaseId={taskPhaseId}
                  phases={phases}
                  triggerType={triggerOrder}
                  type={type}
                />
              </Accordion>
            </RadioGroup>
          </TriggerSelectWrapper>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

PhaseTaskSelect.displayName = 'sc/L/lc/c/t/PhaseTaskSelect';

export default PhaseTaskSelect;
