import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Center, Image, Text, useToast } from '@chakra-ui/react';
import Lottie from 'react-lottie';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { TextLoop } from 'react-text-loop-next';
import { useTranslation } from 'react-i18next';

import { authTokenVar, userObj } from 'sop-commons/src/client/clientFactory';

import icon from '../../assets/images/delightreeIcon.svg';
import { GET_STARTED } from '../../appRoutes';
import { GET_USER } from '../Login/login.graphql';
import { useLogout } from '../../hooks';
import animationData from '../../assets/lotties/business-loading.json';
import { roleObj } from 'ui-components/DashboardMenu';

const TextList = [
  'Food is the ingredient that binds us together.',
  'The customer is always right.',
  'Hospitality is a way of life.',
  'A happy customer is a repeat customer.',
  'The best way to predict the future is to create it.',
  'A restaurant is only as good as its last meal.',
  'A recipe for success: great food, great service, and a great atmosphere.',
  'The only way to do great work is to love what you do.',
  'The secret to success in the restaurant business is to never stop learning and improving.',
  'People will always come back to a place where they feel welcome and valued.',
];

interface State {
  accessToken: string;
}

interface IProps {}

const NewBusinessLoading: FC<IProps> = () => {
  const { t } = useTranslation('auth');
  const toast = useToast({
    position: 'top-right',
  });
  const logoutCurrentUser = useLogout();
  const history = useHistory();
  const state = useLocation<State>().state;
  const timeRef = useRef<any>();

  const textData = useMemo(() => {
    return TextList.sort(() => Math.random() - 0.5);
  }, []);

  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {
      userObj(data?.user || {});
      const roles = data?.user?.entity?.roles;
      if (roles && roles.length) {
        let roleWithColor: any = {};
        roles.forEach((role: any) => {
          roleWithColor[role.name] = role.color;
        });

        roleObj(roleWithColor);
      }
      authTokenVar(localStorage.getItem('authToken'));
      setTimeout(() => {
        history.replace('/');
      }, 8000);
    },
    onError: (error) => {
      toast({
        status: 'error',
        title: error.message,
      });
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (state?.accessToken) {
      timeRef.current = setTimeout(async () => {
        await logoutCurrentUser();
        localStorage.setItem('authToken', state.accessToken);
        localStorage.setItem('refreshAuthToken', state.accessToken);
        getUser({
          variables: {},
          context: {
            headers: {
              Authorization: `Bearer ${state.accessToken}`,
            },
          },
        });
      }, 100);
    }
    return () => {
      if (timeRef.current) {
        clearTimeout(timeRef.current);
      }
    };
  }, [state, getUser, logoutCurrentUser]);

  if (!state?.accessToken) {
    return <Redirect to={GET_STARTED} />;
  }

  return (
    <Center pos='relative' height='full' flexDir='column'>
      <Image pos='absolute' boxSize='54px' src={icon} top='50px' left='50px' />
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={120}
        width={120}
      />
      <Text
        as='div'
        width='full'
        fontFamily='Inter'
        fontWeight='700'
        fontSize='36px'
        lineHeight='60px'
        textAlign='center'
        color='black'
        userSelect='none'
        sx={{
          '.text-loop-container': {
            width: '100%',
            textAlign: 'center',
          },

          '.text-loop-container div': {
            minWidth: '100%',
          },
          '.text-loop-container > div > div': {
            whiteSpace: 'initial',
          },
        }}
        overflow='hidden'
      >
        <TextLoop
          className='text-loop-container'
          fade
          mask
          springConfig={{ stiffness: 200, damping: 11 }}
        >
          {textData.map((value, index) => {
            return (
              <Text key={index} as='div' w='full' maxWidth='full' px='32px'>
                {value}
              </Text>
            );
          })}
        </TextLoop>
        <Text
          as='div'
          fontWeight='400'
          fontSize='16px'
          lineHeight='20px'
          color='#555555'
          mt={3}
        >
          {t('take_upto')}
        </Text>
      </Text>
    </Center>
  );
};

export default NewBusinessLoading;
