import { useLazyQuery } from '@apollo/client';
import {
  LAUNCHER_LOCATION_BY_ID,
  LocationLaunchByIdResponse,
} from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import { LOCATION_LAUNCH_TASK_BY_ID, SINGLE_TASK_DETAILS } from '../api';
import {
  LocationLaunchTaskByIdResponse,
  LocationLaunchTaskByIdVariable,
} from '../types';

export const useService = () => {
  const [getLocationLaunchById, locationLaunchByIdData] = useLazyQuery<
    LocationLaunchByIdResponse,
    { eid: string }
  >(LAUNCHER_LOCATION_BY_ID, {
    fetchPolicy: 'network-only',
  });

  const [getLocationLaunchTasksById, locationLaunchTasksByIdData] =
    useLazyQuery<
      LocationLaunchTaskByIdResponse,
      LocationLaunchTaskByIdVariable
    >(LOCATION_LAUNCH_TASK_BY_ID, {
      fetchPolicy: 'network-only',
    });

  return {
    locLaunchById: {
      getLocationLaunchById,
      locationLaunchByIdData,
    },
    locationLaunchTasksById: {
      getLocationLaunchTasksById,
      locationLaunchTasksByIdData,
    },
  };
};
