import React, { FC } from 'react';
import { Flex, Text, useDisclosure } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { KnowMore } from 'sub-components/NotificationCenter/States';
interface IProps {}

const Description: FC<IProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Flex justify={'space-between'} align={'center'} ml={2} mr={2} mb={2}>
        <Flex flexDir={'column'} gap={2} w={'fit-content'}>
          <Text fontWeight={500} fontSize={'20px'} color={'#1A1D1F'}>
            What would you like to get notified about?
          </Text>
          <Text fontSize={'14px'} color={'#6F767E'}>
            Choose what you would like to be notified of
          </Text>
        </Flex>
        <Flex
          gap={2}
          color={'#2A85FF'}
          align={'center'}
          fontWeight={700}
          fontSize={15}
          onClick={() => onOpen()}
          cursor={'pointer'}
        >
          <FontAwesomeIcon icon={faInfoCircle as IconProp} size={'lg'} />
          <Text>What do the colours indicate?</Text>
        </Flex>
      </Flex>
      <KnowMore isOpen={isOpen} onClose={onClose} />
    </>
  );
};
Description.displayName = 'sc/NC/S/c/Description.tsx';
export default Description;
