import { useLazyQuery, useReactiveVar } from '@apollo/client';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Center,
  Flex,
  Box,
  Text,
} from '@chakra-ui/react';
import { AuthRole } from 'authorization';
import { useUserDataSelector, useUserEntity } from 'hooks';
import QuestionTypes from 'pages/Audits/Detail/components/QuestionTypes/QuestionTypes';
import { useResponse } from 'pages/Audits/Detail/components/QuestionTypes/utils/useResponse';
import React, { FC, useEffect, useMemo } from 'react';
import { usersEntityObj } from 'sub-components/Header';
import Loader from 'sub-components/Loader';
import { AuditSessionAssignments } from '../audit-tab/supervised/types';
import {
  AuditTaskSessionDetailResponse,
  AuditTaskSessionDetailVariable,
  AUDIT_TASK_DETAIL,
} from './task-detail.graphql';

interface IProps {
  auditId: string;
  sessionId: string;
  qid: string;
  flagged: string[];
}

const TaskDetailSection: FC<IProps> = ({
  auditId,
  sessionId,
  qid,
  flagged,
}) => {
  const loggedInAuthRole = useUserDataSelector((state) => state.authRole);
  const userEntityData = useReactiveVar(usersEntityObj);
  const loggedInUserLocations = useUserEntity((entity) => entity?.locations);

  const [getAuditTaskSessionDetails, { data, loading }] = useLazyQuery<
    AuditTaskSessionDetailResponse,
    AuditTaskSessionDetailVariable
  >(AUDIT_TASK_DETAIL, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (sessionId) {
      getAuditTaskSessionDetails({
        variables: {
          eid: sessionId,
        },
      });
    }
  }, [sessionId]);

  const getLocationName = (locationId: string): string => {
    const location = userEntityData.find((loc) => loc.eid === locationId);
    return location ? location.name : 'Unknown Location';
  };

  const flaggedData = useMemo(() => {
    if (!data?.AuditSessionById) return [];
    let obj: any = {
      foundQuestion: undefined,
      foundResponse: undefined,
    };

    const { assignments } = data.AuditSessionById;

    let accessibleLocationIds: string[] = [];

    if (
      loggedInAuthRole === AuthRole.SUPER_ADMIN ||
      loggedInAuthRole === AuthRole.ADMIN
    ) {
      accessibleLocationIds = userEntityData
        .filter((location) => location.type === 'branch')
        .map((location) => location.eid);
    } else if (loggedInAuthRole === AuthRole.LOCATION_OWNER) {
      accessibleLocationIds = (loggedInUserLocations || []).map(
        (loc) => loc.eid
      );
    } else {
      accessibleLocationIds = [];
    }

    const filteredAssignments = assignments.filter((assignment) =>
      accessibleLocationIds.includes(assignment.locationId)
    );

    let foundQuestion = data?.AuditSessionById?.questions?.find(
      (q) => q?.eid === qid
    );
    let flaggedLocations: AuditSessionAssignments[] = [];
    if (foundQuestion) {
      filteredAssignments?.map((assignment) => {
        const responseForQuestion = assignment.response.find(
          (response: any) => response.qid === qid
        );
        let isResponseFlagged = false;
        if (responseForQuestion) {
          isResponseFlagged = responseForQuestion.isFlagged;
          if (responseForQuestion.responseId.length > 0) {
            const selectedOptions =
              foundQuestion?.options.filter((option) =>
                responseForQuestion.responseId.includes(option.eid)
              ) || [];
            if (selectedOptions.some((option) => option.isFlagged)) {
              isResponseFlagged = true;
            }
          }
        }
        if (isResponseFlagged) {
          flaggedLocations.push(assignment);
        }
      });
    }

    flaggedLocations?.map((flagged) => {
      let foundResponse = flagged?.response?.find((res) => res?.qid === qid);
      if (foundQuestion) {
        obj.foundQuestion = foundQuestion;
        obj.foundResponse = foundResponse;
        // return {
        //     foundQuestion,
        //     foundResponse
        // }
      }
    });
    return obj;
  }, [
    data?.AuditSessionById,
    loggedInAuthRole,
    userEntityData,
    loggedInUserLocations,
    qid,
    flagged,
  ]);

  const responses = useResponse(
    flaggedData?.foundResponse,
    flaggedData?.foundQuestion
  );

  const flaggedCount = useMemo(() => {
    return (
      responses?.reduce((acc, value) => {
        if (value?.isFlagged) {
          return acc + 1;
        }
        return acc;
      }, 0) || 0
    );
  }, [responses]);

  if (loading) {
    return (
      <Flex w='full'>
        <Center w='full' p={4}>
          <Loader />
        </Center>
      </Flex>
    );
  }

  if (!flaggedCount) {
    return <></>;
  }

  return (
    <Accordion
      allowToggle
      padding='10px 20px'
      gap='24px'
      borderRadius='10px 0px 0px 0px'
      opacity={1}
      boxShadow='2px 2px 12px 0px rgba(41, 45, 50, 0.12)'
    >
      <AccordionItem border='none'>
        {({ isExpanded }) => (
          <>
            <AccordionButton
              fontWeight={700}
              fontSize='15px'
              height='72px'
              padding='0 24px'
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              border='none'
              _focus={{ boxShadow: 'none', outline: 'none' }}
              _hover={{ bg: 'none' }}
            >
              <Text>Related Flags ({flaggedCount})</Text>
              <Text color='blue.500' fontWeight='500' cursor='pointer'>
                {isExpanded ? 'View less' : 'View'}
              </Text>
            </AccordionButton>
            <AccordionPanel p={4}>
              <QuestionTypes
                rawQuestion={flaggedData?.foundQuestion}
                rawResponse={flaggedData?.foundResponse}
              />
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default TaskDetailSection;
