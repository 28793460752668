import React, { FC, useMemo } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { formatNumber } from '../../../../utils';
import { SectionEntity } from '../types';

interface IProps {
  title: string;
  questionsCount?: number;
  sectionIndex: number;
}

const QuesSectionTitleView: FC<IProps> = ({
  title,
  questionsCount,
  sectionIndex,
}) => {
  const questions = useWatch<SectionEntity, 'data'>({
    name: 'data',
  });

  const completed = useMemo(() => {
    return questions?.filter((question) => question.isCompleted).length;
  }, [questions]);

  const flagCount = useMemo(() => {
    return questions?.filter((ques) => ques.isFlagged).length || 'NA';
  }, [questions]);

  const score = useMemo(() => {
    const _score = questions?.reduce(
      (acc, question) => {
        if (question.hasScoring) {
          acc.total += question.score!;
          acc.current += question.responseScore!;
        }

        return acc;
      },
      {
        total: 0,
        current: 0,
      }
    );
    if (!_score) {
      return 0;
    }

    return formatNumber((_score.current / _score.total) * 100 || 0);
  }, [questions]);

  return (
    <Flex align='end' gap={3} justify='space-between' paddingX={4}>
      <Text fontWeight={600} fontSize='20px'>
        {sectionIndex + 1}. {title}
      </Text>

      <Flex
        fontSize='12px'
        fontWeight={500}
        color='#6F767E'
        align='center'
        gap='8px'
      >
        <Box color='#1A1D1F' fontWeight={600}>
          {completed}/{questionsCount}
        </Box>
        <Box>Question</Box>

        {/*<Box boxSize={1} borderRadius='full' bg='#6F767E' />*/}
        {/*<Box color='#1A1D1F' fontWeight={600}>*/}
        {/*  0/45*/}
        {/*</Box>*/}
        {/*<Box>Points</Box>*/}

        <Box boxSize={1} borderRadius='full' bg='#6F767E' />
        <Box color='#1A1D1F' fontWeight={600}>
          {score}%
        </Box>
        <Box>Score</Box>

        <Box boxSize={1} borderRadius='full' bg='#6F767E' />
        <Box color='#1A1D1F' fontWeight={600}>
          {flagCount}
        </Box>
        <Box>Flag</Box>
      </Flex>
    </Flex>
  );
};

export default QuesSectionTitleView;
