import React, { FC, useContext, useEffect, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { ILocationData, ISetSeeModal } from './set-see-modal-types';
import { userObj } from 'sop-commons/src/client/clientFactory';
import CustomFilterAccordionNew from './CustomFilterAccordion/CustomFilterAccordionNew';
import SetSeeDataContext from './SetSeeModalStore/set-see-data-context';
import PrimaryButton from 'atoms/PrimaryButton';
import { useTranslation } from 'react-i18next';
import {
  dataSetHandler,
  generateVisibilityObject,
  locationUserRoleDataHandler,
  roleListUpdateHandler,
} from './helper';
import { loginUserBranchRootObj } from 'sub-components/Header';
import useCombinedStore from 'zustandStore/store';
import { IVisibility } from 'pages/Chapters/CreateChapterModal/UploadFile/UploadFile';
import Heading from './Heading';
import PublicCheckbox from './PublicCheckbox';
import MessagesRender from './MessagesRender';
import SameAsParent from './SameAsParent';
import GenerateConditionsText from './GenerateConditionsText';
import { AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';

const GET_USERS = gql`
  query SetSeeModalNewUsers {
    userDescendants {
      authRole
      eid
      name
      profilePic
      role
      type
      status
      username
      locations {
        eid
        name
      }
    }
  }
`;

const SetSeeModalNew: FC<ISetSeeModal> = ({
  isLoading,
  modalType,
  showVisibilityInfo,
  title,
  onClose,
  folderVisibilityUpdateHandler,
  setCreateVisibility,
}) => {
  const userBranchRootObj = useReactiveVar(loginUserBranchRootObj);
  const loggedInUserAuthRole = useUserDataSelector((state) => state?.authRole);
  const {
    visibilityScope,
    visibilitySelection,
    operationType,
    updateVisibilitySelection,
    updateCustomSectionExpand,
  } = useCombinedStore();
  const { t } = useTranslation(['common']);
  const userData = useReactiveVar(userObj);
  const _locationsData: any = useReactiveVar(loginUserBranchRootObj);
  /** Make a copy of `visibilitySelection` on initial load of component, and if user clicks on `Cancel` button, then overwrite `visibilitySelection` with `copiedVisibilityData` */
  const [copiedVisibilityData, setCopiedVisibilityData] = useState<
    IVisibility | undefined
  >(undefined);
  const [apiHitOnce, setApiHitOnce] = useState(false);
  const [queriesCompleted, setQueriesCompleted] = useState({
    users: false,
    branch: false,
  });

  const setSeeCtx = useContext(SetSeeDataContext);

  const getLOLocations = () => {
    let filteredLocationsArray = (_locationsData?.descendantBranches || [])
      .filter((location: ILocationData) => {
        return (userData?.locations || []).find((userLocation) => {
          return userLocation.eid === location.eid;
        });
      })
      ?.map((loc: any) => loc?.eid);
    return filteredLocationsArray;
  };

  useEffect(() => {
    if (visibilitySelection) {
      if (
        visibilitySelection?.locations?.length > 0 ||
        visibilitySelection?.roles?.length > 0 ||
        visibilitySelection?.users?.length > 0
      ) {
        updateCustomSectionExpand(true);
      } else {
        if (loggedInUserAuthRole === AuthRole.LOCATION_OWNER) {
          updateCustomSectionExpand(true);
          // updateVisibilitySelection({
          //   ...visibilitySelection,
          //   condition: 'and',
          //   visibility: 'private',
          //   ...(operationType === 'add' ? { locations: getLOLocations() } : {}),
          // });
        } else {
          updateCustomSectionExpand(false);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (!apiHitOnce) {
      // getLocationUserRoleData();
      setSeeCtx?.updateModalType(modalType);
    }
  }, [apiHitOnce]);

  useEffect(() => {
    if (!copiedVisibilityData) {
      setCopiedVisibilityData(visibilitySelection);
    }
  }, [visibilitySelection]);

  // useEffect(() => {
  //   console.log('visibility : ', visibilityData);
  //   updateVisibilitySelection({
  //     locations: visibilityData?.locations || [],
  //     roles: visibilityData?.roles || [],
  //     users: visibilityData?.users || [],
  //     condition: visibilityData?.condition || undefined,
  //     visibility: visibilityData?.visibility
  //   });
  // }, [])

  // useEffect(() => {
  //   dataSetHandler(
  //     isCreate,
  //     visibilityData,
  //     visibilitySelection,
  //     setSeeCtx,
  //     userData,
  //     store
  //   );
  // }, [
  //   visibilitySelection,
  //   visibilityData,
  //   setSeeCtx?.membersList,
  //   setSeeCtx?.locationsList,
  //   setSeeCtx?.rolesList,
  // ]);

  useEffect(() => {
    roleListUpdateHandler(userData, setSeeCtx);
  }, [userData]);

  // const { data } = useQuery(GET_BRANCH, {
  //   onCompleted: () => {
  //     setQueriesCompleted((prev) => ({ ...prev, branch: true }));
  //   },
  // });

  const { data: _userData } = useQuery(GET_USERS, {
    onCompleted: () => {
      setQueriesCompleted((prev) => ({ ...prev, users: true }));
    },
  });

  useEffect(() => {
    if (queriesCompleted.users) {
      locationUserRoleDataHandler(
        { branch: userBranchRootObj },
        _userData,
        userData,
        setSeeCtx
      );
      setQueriesCompleted({ users: false, branch: false });
    }
  }, [queriesCompleted]);

  const addVisibilityHandler = () => {
    let visibilityObject = generateVisibilityObject(setSeeCtx, userData);
    let selectedLocations = setSeeCtx?.selectedLocationsList;
    let selectedMembers = setSeeCtx?.selectedMembersList;
    let selectedRoles = setSeeCtx?.selectedRolesList;
    setCreateVisibility && setCreateVisibility(visibilityObject);
    onClose('update', visibilityObject, {
      selectedLocations,
      selectedMembers,
      selectedRoles,
    });
  };

  const applyBtnStatusHandler = () => {
    if (
      isLoading ||
      (setSeeCtx?.selectionType === 'private' &&
        setSeeCtx?.selectedLocationsList?.length === 0 &&
        setSeeCtx?.selectedMembersList?.length === 0 &&
        setSeeCtx?.selectedRolesList?.length === 0)
    ) {
      return true;
    }
    return false;
  };

  const folderVisibilityUpdate = () => {
    let visibilityObject = generateVisibilityObject(setSeeCtx, userData);
    setCreateVisibility && setCreateVisibility(visibilityObject);
    folderVisibilityUpdateHandler?.();
    onClose('update');
  };

  const disabledCheckHandler = () => {
    let flag = false;
    if (
      (visibilitySelection?.visibility === 'private' &&
        visibilitySelection?.locations?.length === 0 &&
        visibilitySelection?.roles?.length === 0 &&
        visibilitySelection?.users?.length === 0) ||
      !visibilitySelection?.visibility ||
      (visibilitySelection?.locations?.length === 0 &&
        loggedInUserAuthRole === AuthRole.LOCATION_OWNER)
    ) {
      flag = true;
    }
    if (isLoading) {
      flag = true;
    }
    return flag;
  };

  const onUpdateHandler = () => {
    if (folderVisibilityUpdateHandler) {
      folderVisibilityUpdateHandler?.();
    } else {
      onClose('close');
    }
  };

  const btnComponent = () => {
    return (
      <Flex mt='1rem' justify='flex-end' gap='20px'>
        <PrimaryButton
          type='button'
          size='lg'
          title={t('common:cancel')}
          style={{
            padding: '0px 37px',
            maxWidth: '146px',
          }}
          variant='outline'
          onClick={() => {
            onClose('close');
            if (copiedVisibilityData) {
              updateVisibilitySelection(copiedVisibilityData);
            }
          }}
        />
        <PrimaryButton
          type='button'
          size='lg'
          title={
            operationType === 'add' ? t('common:apply') : t('common:update')
          }
          style={{
            padding: '0px 37px',
            maxWidth: '146px',
            backgroundColor: '#2a85ff',
            color: 'white',
          }}
          variant='solid'
          isLoading={isLoading}
          disabled={disabledCheckHandler()}
          // onClick={folderVisibilityUpdate}
          // onClick={folderVisibilityUpdate}
          onClick={onUpdateHandler}
        />
      </Flex>
    );
    // if (visibilityScope) {
    // }
    // return (
    //   <Flex mt='1rem' justify='flex-end' gap='20px'>
    //     <PrimaryButton
    //       type='button'
    //       size='lg'
    //       title={t('common:cancel')}
    //       style={{
    //         padding: '0px 37px',
    //         maxWidth: '146px',
    //       }}
    //       variant='solid'
    //       onClick={() => {
    //         onClose('close');
    //         if (copiedVisibilityData) {
    //           updateVisibilitySelection(copiedVisibilityData);
    //         }
    //       }}
    //     />
    //     <PrimaryButton
    //       type='button'
    //       size='lg'
    //       title={t('common:apply')}
    //       style={{
    //         padding: '0px 37px',
    //         maxWidth: '146px',
    //         backgroundColor: '#2a85ff',
    //         color: 'white',
    //       }}
    //       variant='solid'
    //       // onClick={!isCreate ? updateVisibilityHandler : addVisibilityHandler}
    //       // onClick={addVisibilityHandler}
    //       onClick={onUpdateHandler}
    //       isLoading={isLoading}
    //       disabled={disabledCheckHandler()}
    //     />
    //   </Flex>
    // );
  };

  const onCloseBtn = () => {
    onClose('close');
    if (copiedVisibilityData) {
      updateVisibilitySelection(copiedVisibilityData);
    }
  };

  return (
    <Box>
      <Heading title={title} onClose={onCloseBtn} />
      <PublicCheckbox />
      <SameAsParent />
      <CustomFilterAccordionNew />
      <MessagesRender
        showVisibilityInfo={showVisibilityInfo}
        btnComponent={btnComponent()}
      />
      {setSeeCtx?.modalType === 'write' && btnComponent()}
    </Box>
  );
};

SetSeeModalNew.displayName =
  'displayName:pages/Handbook/setVisibilityModal/SetSeeModal/SetSeeModalNew';

export default SetSeeModalNew;
