import { FC } from 'react';
import { ConfigProvider, Table } from 'antd';
import { Box } from '@chakra-ui/react';
import { TableDataType } from '../../types/compliance.types';
import { DefaultTableEmptyState } from './';
import Loader from 'sub-components/Loader';
import '../styles.scss';

interface IProps {
  tableData: TableDataType[];
  searchFieldText: string;
  complianceLoader: boolean;
  totalRowsCount: number;
  getComplianceListing: any;
  getComplianceFilters: any;
  columns: any;
  filters: boolean;
  complianceCategoryId: string;
}

const ComplianceTable: FC<IProps> = ({
  tableData,
  searchFieldText,
  complianceLoader,
  totalRowsCount,
  getComplianceListing,
  getComplianceFilters,
  columns,
  filters,
  complianceCategoryId,
}) => {
  return (
    <Box>
      <ConfigProvider
        renderEmpty={() => (
          <>
            <DefaultTableEmptyState
              searchFieldText={searchFieldText}
              filters={filters}
              complianceCategoryId={complianceCategoryId}
            />
          </>
        )}
      >
        <Table<TableDataType>
          loading={complianceLoader && { indicator: <Loader /> }}
          columns={columns}
          dataSource={tableData}
          pagination={{
            total: totalRowsCount,
            defaultPageSize: 10,
            onChange: (page) => {
              getComplianceListing({
                variables: getComplianceFilters(page),
              });
            },
          }}
        />
      </ConfigProvider>
    </Box>
  );
};

ComplianceTable.displayName =
  'displayName:sub-components/nexus/Compliance/Listing/components/ComplianceTable';

export default ComplianceTable;
