import React, { FC, useCallback, useMemo } from 'react';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import moment from 'moment/moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { IGraphRange } from './useGraphRangeVariable';

interface IProps {
  data: IGraphRange;
  onChange: (newData: IGraphRange) => void;
}

const GraphFooter: FC<IProps> = ({ data, onChange }) => {
  const textValue = useMemo(() => {
    if (data.type === 'weekly') {
      return moment(data.startDate)
        .format('MMM YY')
        .concat(' - ', moment(data.endDate).format('MMM YY'));
    }
    return moment(data.startDate).year();
  }, [data]);

  const onPrevClick = useCallback(() => {
    switch (data.type) {
      case 'monthly':
        onChange?.({
          type: data.type,
          startDate: moment(data.startDate).subtract(1, 'year').toISOString(),
          endDate: moment(data.endDate).subtract(1, 'year').toISOString(),
        });
        break;
      case 'weekly':
        onChange?.({
          type: data.type,
          startDate: moment(data.startDate).subtract(1, 'month').toISOString(),
          endDate: moment(data.endDate).subtract(1, 'month').toISOString(),
        });
        break;
      default:
        return null;
    }
  }, [data]);

  const onNextClick = useCallback(() => {
    switch (data.type) {
      case 'monthly':
        onChange?.({
          type: data.type,
          startDate: moment(data.startDate).add(1, 'year').toISOString(),
          endDate: moment(data.endDate).add(1, 'year').toISOString(),
        });
        break;
      case 'weekly':
        onChange?.({
          type: data.type,
          startDate: moment(data.startDate).add(1, 'month').toISOString(),
          endDate: moment(data.endDate).add(1, 'month').toISOString(),
        });
        break;
      default:
        return null;
    }
  }, [data]);

  return (
    <Flex justify='space-between' px='16px'>
      <IconButton
        size='sm'
        aria-label='prev'
        onClick={onPrevClick}
        icon={
          <FontAwesomeIcon icon={faArrowLeft as IconProp} color='#84818A' />
        }
      />
      <Box>{textValue}</Box>
      <IconButton
        size='sm'
        aria-label='next'
        onClick={onNextClick}
        icon={
          <FontAwesomeIcon icon={faArrowRight as IconProp} color='#84818A' />
        }
      />
    </Flex>
  );
};

GraphFooter.displayName = 'sc/t/p/c/g/GraphFooter';

export default GraphFooter;
