import { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';

interface ItemProps {
  label: any;
  count: number;
  isSelected: boolean;
  onClick: () => void;
  status: any;
  countColor: string;
}

const TabItem: FC<ItemProps> = ({
  label,
  count,
  isSelected,
  onClick,
  countColor,
}) => {
  if (count === 0) return null;

  return (
    <Box
      onClick={onClick}
      fontSize='15px'
      fontWeight='600'
      color={isSelected ? '#33383F' : '#6F7E7C'}
      pb='12px'
      px='16px'
      py='8px'
      borderBottom='3px solid'
      borderBottomColor={isSelected ? '#2A85FF' : 'transparent'}
      cursor='pointer'
      _hover={{ color: '#33383F' }}
      textAlign='center'
      display='inline-flex'
      alignItems='center'
      justifyContent='center'
      gap='4px'
      whiteSpace='nowrap'
    >
      <Text as='span'>{label}</Text>
      <Text as='span' color={countColor}>
        ({count})
      </Text>
    </Box>
  );
};

export default TabItem;
