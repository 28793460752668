import { useLazyQuery, useMutation } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { useToast } from '@chakra-ui/react';
import { AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocationFieldEntity } from 'sop-commons/src/client';
import { FieldType } from './LocationListContainer';
import {
  EntityMetaResponse,
  GET_LOCATION_TABLE_FIELDS,
  UPDATE_LOCATION_TABLE_FIELD_VISIBILITY,
} from './locations.graphql';
import { initialData, staticColumnIds } from './static-data';

export const useCustomColumnsHandler = () => {
  const toast = useToast({
    isClosable: true,
    duration: 3000,
    position: 'top-right',
  });

  const { loggedInUserEid, locationFields, loggedInUserAuthRole } =
    useUserDataSelector((state) => ({
      loggedInUserAuthRole: state?.authRole,
      loggedInUserEid: state?.eid,
      locationFields: state?.entity?.locationFields,
    }));

  const { t } = useTranslation(['common']);

  const fetchCountRef = useRef(0);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [processingLoading, setProcessingLoading] = useState(false);
  const processingCountRef = useRef(0);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [fieldsData, setFieldsData] = useState<FieldType[]>([]);

  const startFetch = () => {
    fetchCountRef.current += 1;
    setFetchLoading(true);
  };

  const endFetch = () => {
    fetchCountRef.current -= 1;
    if (fetchCountRef.current <= 0) {
      setFetchLoading(false);
      fetchCountRef.current = 0;
    }
  };

  const startProcessing = () => {
    processingCountRef.current += 1;
    setProcessingLoading(true);
  };

  const endProcessing = () => {
    processingCountRef.current -= 1;
    if (processingCountRef.current <= 0) {
      setProcessingLoading(false);
      processingCountRef.current = 0;
    }
  };

  const isInitializationNeeded = (columns: FieldType[] = []) => {
    // if ([AuthRole.SUPER_ADMIN, AuthRole.ADMIN].includes(loggedInUserAuthRole)) {
    //   return locationFields?.length === 0 && columns?.length === 0;
    // } else if (loggedInUserAuthRole === AuthRole.LOCATION_OWNER) {
    //   const visibleFields = locationFields?.filter(
    //     (field) => field?.visibleToLocations
    //   );
    //   return visibleFields?.length === 0 && columns?.length === 0;
    // }
    return locationFields?.length === 0 && columns?.length === 0;
  };

  const [getLocationTableColumns] = useLazyQuery<
    { FetchUserMeta: EntityMetaResponse },
    { userId: string }
  >(GET_LOCATION_TABLE_FIELDS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let locationColumns = data?.FetchUserMeta?.locationColumns || [];
      console.log({ locationColumnsLog: locationColumns });
      if (isInitializationNeeded(locationColumns)) {
        initializeColumns();
      } else {
        const { isEqual, columnsCount, fieldsCount } = compareFieldsAndColumns(
          locationFields,
          locationColumns
        );
        if (
          columnsCount === 0 &&
          fieldsCount === 0 &&
          locationColumns?.length === 0
        ) {
          const filteredInitialColumns = locationColumns?.filter((column) =>
            staticColumnIds?.includes(column?.id!)
          );
          updateTableColumnsHandler(filteredInitialColumns);
        } else {
          if (isEqual) {
            processFetchedColumns(data);
          } else {
            mergeAndUpdateColumns(data);
          }
        }
      }
      endFetch();
    },
    onError: () => {
      endFetch();
      toast({
        status: 'error',
        title: t('common:error'),
        description: 'Custom columns could not be fetched',
      });
    },
  });

  const [updateTableColumns] = useMutation(
    UPDATE_LOCATION_TABLE_FIELD_VISIBILITY,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setUpdateLoading(false);
        startFetch();
        getLocationTableColumns({
          variables: {
            userId: loggedInUserEid,
          },
        });
      },
      onError: () => {
        setUpdateLoading(false);
        toast({
          status: 'error',
          title: t('common:error'),
          description: 'Custom columns could not be updated',
        });
      },
    }
  );

  const initializeColumns = () => {
    setUpdateLoading(true);
    updateTableColumns({
      variables: {
        input: {
          eid: loggedInUserEid,
          locationColumns: initialData,
        },
      },
    });
  };

  const compareFieldsAndColumns = (
    locationFields: LocationFieldEntity[] = [],
    locationColumns: FieldType[] = []
  ) => {
    const fieldsEids = locationFields?.map((field) => field?.eid);
    const columnsEids =
      locationColumns
        ?.filter((col) => col?.id && !staticColumnIds?.includes(col?.id))
        ?.map((col) => col?.id!) || [];

    const fieldsEidSet = new Set(fieldsEids);
    const columnsEidSet = new Set(columnsEids);

    if (fieldsEidSet?.size !== columnsEidSet?.size) {
      return {
        isEqual: false,
        fieldsCount: fieldsEidSet?.size,
        columnsCount: columnsEidSet?.size,
      };
    }

    for (let eid of fieldsEidSet) {
      if (!columnsEidSet?.has(eid)) {
        return {
          isEqual: false,
          fieldsCount: fieldsEidSet?.size,
          columnsCount: columnsEidSet?.size,
        };
      }
    }

    return {
      isEqual: true,
      fieldsCount: fieldsEidSet?.size,
      columnsCount: columnsEidSet?.size,
    };
  };

  const processFetchedColumns = (data: {
    FetchUserMeta: EntityMetaResponse;
  }) => {
    let locationColumns = data?.FetchUserMeta?.locationColumns || [];
    startProcessing();
    let processedColumns: FieldType[] = [];

    if (
      [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(loggedInUserAuthRole)
    ) {
      processedColumns = handleMandatoryColumns(locationColumns);
    } else {
      const mandatoryColumns = handleMandatoryColumns(locationColumns);
      processedColumns = filterVisibleColumns(mandatoryColumns);
    }

    const defaultColumnNames = processedColumns?.filter((item: FieldType) =>
      staticColumnIds?.includes(item?.id!)
    );

    if (defaultColumnNames?.length > 0) {
      setFieldsData(processedColumns);
    } else {
      const updatedFieldsData = [...initialData, ...processedColumns] || [];
      setFieldsData(updatedFieldsData);
    }
    endProcessing();
  };

  const handleMandatoryColumns = (fetchedColumns: FieldType[] = []) => {
    startProcessing();
    const clonedColumns = cloneDeep(fetchedColumns);
    clonedColumns?.forEach((fetched) => {
      locationFields?.forEach((field) => {
        if (fetched?.id === field?.eid) {
          if (field?.validations?.isRequired) {
            fetched.mandatory = true;
            fetched.visibility = true;
          } else {
            fetched.mandatory = false;
            fetched.visibility = fetched?.visibility || false;
          }
        }
      });
    });
    endProcessing();
    return clonedColumns;
  };

  const filterVisibleColumns = (columns: FieldType[] = []) => {
    startProcessing();
    const filteredColumns = columns?.filter((col) => {
      const field = locationFields?.find((field) => field?.eid === col?.id);
      return field ? field?.visibleToLocations : true;
    });
    endProcessing();
    return filteredColumns;
  };

  const mergeAndUpdateColumns = (data: {
    FetchUserMeta: EntityMetaResponse;
  }) => {
    let locationColumns = data?.FetchUserMeta?.locationColumns || [];
    startProcessing();
    const locationFieldsMap = new Map<string, LocationFieldEntity>(
      locationFields?.map((field) => [field?.eid, field])
    );

    const updatedColumns: FieldType[] =
      locationColumns?.reduce<FieldType[]>((acc, column) => {
        if (staticColumnIds?.includes(column?.id!)) {
          acc?.push(column);
        } else if (locationFieldsMap?.has(column?.id!)) {
          const field = locationFieldsMap?.get(column?.id!)!;
          acc?.push({
            id: field?.eid,
            field: field?.fieldName,
            visibility: column?.visibility || false,
            mandatory: column?.mandatory,
          });
          locationFieldsMap?.delete(field?.eid);
        }
        return acc;
      }, []) || [];

    const newColumns: FieldType[] =
      Array?.from(locationFieldsMap?.values())?.map((field) => ({
        id: field?.eid,
        field: field?.fieldName,
        visibility: field?.visibleToLocations,
        mandatory: field?.validations?.isRequired,
      })) || [];

    updateTableColumnsHandler([...updatedColumns, ...newColumns] || []);
    endProcessing();
  };

  const updateTableColumnsHandler = (locationColumns: FieldType[] = []) => {
    setUpdateLoading(true);
    updateTableColumns({
      variables: {
        input: {
          eid: loggedInUserEid,
          locationColumns: locationColumns,
        },
      },
    });
  };

  useEffect(() => {
    if (loggedInUserEid) {
      startFetch();
      getLocationTableColumns({
        variables: {
          userId: loggedInUserEid,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUserEid, locationFields]);

  return {
    fetchLoading,
    fieldsData,
    processingLoading,
    updateLoading,
    setFieldsData,
    updateTableColumnsHandler,
  };
};
