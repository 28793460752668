import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LOCATIONS_PAGE } from 'appRoutes';

interface LocationState {
  backToTitle?: string;
}

interface IProps {
  title: string;
}

interface CustomState {
  prevPageLocation?: string;
}

const LocationDetailsHeading: FC<IProps> = ({ title }) => {
  const history = useHistory<CustomState>();

  const locState = useLocation<LocationState>().state;

  const onBackClick = () => {
    if (locState?.backToTitle) {
      return history.goBack();
    }
    return history.push(LOCATIONS_PAGE);
  };

  return (
    <Flex gap='10px' cursor='pointer' onClick={onBackClick} w='fit-content'>
      <FontAwesomeIcon icon={faArrowLeft as IconProp} size='2x' />
      <Flex flexDir='column'>
        <Text fontWeight={600} fontSize='24px'>
          {title}
        </Text>
        <Text fontWeight={400} fontSize='15px' color='#6F767E'>
          {locState?.backToTitle ? locState?.backToTitle : 'Back to Locations'}
        </Text>
      </Flex>
    </Flex>
  );
};

export default LocationDetailsHeading;
