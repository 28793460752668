import React, { FC } from 'react';
import {
  Box,
  useCheckboxGroupContext,
  useControllableState,
} from '@chakra-ui/react';
import { useRemindUserData } from './useRemindUserData';
import { MenuContainer } from '../../../../../../../CustomDropdowns';

interface IProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  withoutLocation?: boolean;
}

const SelectAdminUser: FC<IProps> = ({ value, onChange, withoutLocation }) => {
  const { options } = useRemindUserData();
  const group = useCheckboxGroupContext();

  const [internalValue, updateValue] = useControllableState<string[]>({
    defaultValue: [],
    value: value,
    onChange: onChange,
  });

  function handleOptionClick(eid: string) {
    let newSelectedValues = [];

    if (internalValue?.includes(eid)) {
      newSelectedValues = internalValue?.filter((val) => val !== eid);
    } else {
      newSelectedValues = [...(internalValue || []), eid];
    }
    updateValue(newSelectedValues);
  }

  const isChecked = withoutLocation
    ? withoutLocation
    : group?.value?.includes('admins');

  if (!isChecked) {
    return <Box height='16px' />;
  }

  return (
    <Box paddingLeft={withoutLocation ? undefined : '28px'}>
      <MenuContainer
        showSearchField
        showDivider
        showSelectAll
        searchFieldPlaceholder='Search by name'
        options={options}
        handleOptionClick={handleOptionClick}
        selectedValues={internalValue || []}
        setSelectedValues={updateValue}
      />
    </Box>
  );
};

SelectAdminUser.displayName =
  'displayName:sub-components/nexus/Compliance/Create/components/common/document-reminder/remind-to-user/SelectAdminUser';
export default SelectAdminUser;
