import { Box, Center, Flex, IconButton, Progress } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HeaderColors } from 'configs';
import { FC, useMemo, useState } from 'react';
import { ILocationLaunchTask } from 'sub-components/Launcher/location-owner/types';
import TaskStatusIcon from 'ui-components/TaskStatusIcon';
import {
  faCalendarLines,
  faDiagramSubtask,
} from '@fortawesome/pro-light-svg-icons';
import { faCircleCheck, faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import {
  faLocationDot,
  faUpRightFromSquare,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';
import { ActionMenu } from 'ui-components';
import { StateDrawer } from 'sub-components/Launcher/location-owner/StatesDrawer/StateDrawer';
import { ITaskStatus } from 'ui-components/TaskStatusIcon/types';
import { TaskType } from 'sub-components/Launcher/dashboard/dashboard-grapghql';
import { useHistory } from 'routes';
import { MY_LAUNCHING_LOCATION_TASK_DETAILS } from 'appRoutes';
import { convertTaskData } from '../utils';
import {
  LocationPhaseDetails,
  PhaseTask,
} from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';

interface IProps {
  categories: TaskType[];
  task: ILocationLaunchTask;
  locationName: string;
  refetch?: (
    resData?: ILocationLaunchTask,
    phaseDetails?: LocationPhaseDetails[],
    task?: PhaseTask
  ) => void;
}

const TaskContent: FC<IProps> = ({
  categories,
  task,
  locationName,
  refetch,
}) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const category = useMemo(() => {
    return (
      categories
        ?.filter((cat) => cat?.eid === task?.taskCategoryEid)
        ?.map((cat) => cat?.name)?.[0] || '-'
    );
  }, [categories]);

  const actionHandler = (_: string, value: string) => {
    if (value === 'open') {
      return history.push({
        pathname: MY_LAUNCHING_LOCATION_TASK_DETAILS,
        params: {
          locationId: task?.locationId,
          launchId: task?.launchId,
          taskId: task?.eid,
        },
      });
    }
  };

  const detailsSection = (task: ILocationLaunchTask) => {
    let status = task?.status as string;
    if (status === 'overdue') {
      return (
        <Flex color={HeaderColors.Red.Mid} align='center' gap='5px'>
          <FontAwesomeIcon icon={faCalendarLines as IconProp} />
          <Box as='span'>
            {task?.dueDate && moment(task?.dueDate)?.isValid()
              ? moment(task?.dueDate)?.format('MMM DD')
              : '-'}
          </Box>
          <Box as='span'>&bull;</Box>
          <Box as='span'>{category}</Box>
          <Box as='span'>&bull;</Box>
          <Box as='span'>
            <FontAwesomeIcon icon={faDiagramSubtask as IconProp} />{' '}
            {`${task?.steps?.length} ${
              task?.steps?.length === 1 ? 'substep' : 'substeps'
            }`}
          </Box>
        </Flex>
      );
    } else if (status === 'duesoon') {
      return (
        <Flex color={'#8163fc'} align='center' gap='5px'>
          <FontAwesomeIcon icon={faCalendarLines as IconProp} />
          <Box as='span'>
            {task?.dueDate && moment(task?.dueDate)?.isValid()
              ? moment(task?.dueDate)?.format('MMM DD')
              : '-'}
          </Box>
          <Box as='span'>&bull;</Box>
          <Box as='span'>{category}</Box>
          <Box as='span'>&bull;</Box>
          <Box as='span'>
            <FontAwesomeIcon icon={faDiagramSubtask as IconProp} />{' '}
            {`${task?.steps?.length} ${
              task?.steps?.length === 1 ? 'substep' : 'substeps'
            }`}
          </Box>
        </Flex>
      );
    } else if (status === 'inProgress') {
      return (
        <Flex color={HeaderColors.DarkBlue} align='center' gap='5px'>
          <FontAwesomeIcon icon={faCalendarLines as IconProp} />
          <Box as='span'>
            {task?.dueDate && moment(task?.dueDate)?.isValid()
              ? moment(task?.dueDate)?.format('MMM DD')
              : '-'}
          </Box>
          <Box as='span'>&bull;</Box>
          <Box as='span'>{category}</Box>
          <Box as='span'>&bull;</Box>
          <Box as='span'>
            <FontAwesomeIcon icon={faDiagramSubtask as IconProp} />{' '}
            {`${task?.steps?.length} ${
              task?.steps?.length === 1 ? 'subtask' : 'subtasks'
            }`}
          </Box>
        </Flex>
      );
    } else if (status === 'yetToStart' || status === 'locked') {
      return (
        <Flex align='center' gap='5px' color='rgba(111, 118, 126, 1)'>
          <FontAwesomeIcon icon={faCalendarLines as IconProp} />
          <Box as='span'>{task?.unlockMessage}</Box>
          <Box as='span'>&bull;</Box>
          <Box as='span'>
            <FontAwesomeIcon icon={faDiagramSubtask as IconProp} />{' '}
            {`${task?.steps?.length} ${
              task?.steps?.length === 1 ? 'subtask' : 'subtasks'
            }`}
          </Box>
        </Flex>
      );
    } else if (status === 'completed') {
      return (
        <Flex color={HeaderColors.GreenDark} align='center' gap='5px'>
          <FontAwesomeIcon icon={faCalendarLines as IconProp} />
          <Box as='span'>
            Completed on{' '}
            {task?.completedAt && moment(task?.completedAt)?.isValid()
              ? moment(task?.completedAt)?.format('MMM DD')
              : '-'}
          </Box>
          <Box as='span'>&bull;</Box>
          <Box as='span'>{category}</Box>
          <Box as='span'>&bull;</Box>
          <Box as='span'>
            <FontAwesomeIcon icon={faDiagramSubtask as IconProp} />{' '}
            {`${task?.steps?.length} ${
              task?.steps?.length === 1 ? 'subtask' : 'subtasks'
            }`}
          </Box>
        </Flex>
      );
    }
    return <></>;
  };
  return (
    <Flex
      bg='white'
      p='18px 24px'
      borderRadius='12px'
      justify='space-between'
      align='center'
      cursor='pointer'
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setOpen(true);
      }}
    >
      {/* Left section */}
      <Flex flexDir='column' gap={1}>
        <Flex gap={3} align='center'>
          {/* Status Icon */}
          <TaskStatusIcon
            status={task.status as ITaskStatus}
            iconStyles={{ marginTop: '2px', width: '20px', height: '20px' }}
          />
          {/* Details */}
          <Flex fontWeight={500}>{task?.title}</Flex>
        </Flex>
        <Flex fontSize='12px' marginLeft='8'>
          {detailsSection(task)}
        </Flex>
      </Flex>

      {/* Right section */}
      <Flex gap='2rem' align='center'>
        <Flex gap='15px'>
          {/* Locaction name badge */}
          <Flex
            bg='rgba(239, 239, 239, 1)'
            padding='5px 10px'
            borderRadius='7px'
            align='center'
            gap='8px'
          >
            <Center bg='white' borderRadius='5px' boxSize='24px'>
              <FontAwesomeIcon
                icon={faLocationDot as IconProp}
                color='rgba(111, 118, 126, 1)'
                fontSize='12px'
              />
            </Center>
            <Box as='span'>{locationName}</Box>
          </Flex>

          {/* Progress bar */}
          {task?.steps?.length > 0 && (
            <Flex
              align='flex-start'
              gap='5px'
              flexDir='column'
              justify='center'
            >
              <Flex align='center' gap='5px' color='rgba(146, 146, 157, 1)'>
                <FontAwesomeIcon icon={faCircleCheck as IconProp} />
                <Box>
                  {task?.steps?.filter((step) => step?.completedAt)?.length ||
                    0}
                  /{task?.steps?.length || 0}
                </Box>
              </Flex>
              <Progress
                w='100px'
                h='5px'
                value={
                  task?.steps?.filter((step) => step?.completedAt)?.length || 0
                }
                max={task?.steps?.length || 0}
                sx={{
                  '& > div': {
                    backgroundColor: '#3DD598',
                  },
                }}
                borderRadius='5px'
              />
            </Flex>
          )}
        </Flex>
        <Flex
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <ActionMenu
            menuData={[
              {
                name: 'Open',
                value: 'open',
                icon: faUpRightFromSquare,
                normalIcon: 'fontAwesome',
              },
            ]}
            clickedItem={actionHandler}
          >
            <IconButton
              aria-label='Options'
              icon={
                <FontAwesomeIcon
                  icon={faEllipsis as IconProp}
                  fontSize='20px'
                />
              }
              variant='none'
            />
          </ActionMenu>
        </Flex>
      </Flex>
      <StateDrawer
        status={task?.status}
        task={convertTaskData(task)}
        categories={categories}
        locationId={task?.locationId}
        // fields={data.lockedState.fields}
        // subtasks={data.lockedState.subtasks}
        isOpen={open}
        launchId={task?.launchId}
        toggleDrawer={() => setOpen((prev) => !prev)}
        refetchData={refetch}
      />
    </Flex>
  );
};

TaskContent.displayName =
  'displayName:sub-components/Launcher/tasks/components/TaskContent';

export default TaskContent;
