import { Box, Flex, Image } from '@chakra-ui/react';
import React, { FC } from 'react';
import CheckmarkIcon from 'assets/images/checkmark.webp';

const ModalContent: FC = () => {
  return (
    <Flex
      borderRadius='12px'
      bg='#F6F2FF'
      p='20px 24px'
      flexDir='column'
      gap={4}
    >
      <Box color='#6F767E' fontSize='12px' fontWeight={500}>
        Keep in mind,
      </Box>
      <Flex align='center' gap={3}>
        <Image src={CheckmarkIcon} boxSize='24px' />
        <Flex flexDir='column'>
          <Box as='span' fontSize='12px' fontWeight={600}>
            You can have either phase assignees or task assignees
          </Box>
          <Box
            as='span'
            color='#6F767E'
            fontSize='12px'
            fontWeight={600}
            lineHeight='24px'
          >
            Ex: If phase 1 is assigned to the Location owner, all tasks in phase
            1 will be assigned to Location owner
          </Box>
        </Flex>
      </Flex>
      <Flex align='center' gap={3}>
        <Image src={CheckmarkIcon} boxSize='24px' />
        <Flex flexDir='column'>
          <Box as='span' fontSize='12px' fontWeight={600}>
            When deselecting phases with dependencies, all linked phases will be
            auto deselected.
          </Box>
          <Box
            as='span'
            color='#6F767E'
            fontSize='12px'
            fontWeight={600}
            lineHeight='24px'
          >
            Ex: If phase 2 is dependant on phase 1, marking phase 1 as completed
            means phase 2 will be marked as completed as well
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

ModalContent.displayName = 'p/LN/C/PDP/c/sC/ModalContent';

export default ModalContent;
