import React, { FC } from 'react';
import { Grid } from '@chakra-ui/react';
import { AppRoute, useHistory } from '../../../routes';
import {
  BoardItemEntity,
  BUSINESS_LAUNCHER,
  useBoardActionHandler,
} from '../../../sub-components/Launcher/launcher-config';
import BoardCard from './BoardCard';

interface IProps {
  boardList: BoardItemEntity[];
}

const BoardsList: FC<IProps> = ({ boardList = [] }) => {
  const history = useHistory();

  const onClick = (data: BoardItemEntity) => {
    history.push({
      pathname: AppRoute.LAUNCHER_BOARD_CONFIG,
      params: {
        boardId: data.eid,
      },
    });
  };

  const onActionClick = useBoardActionHandler({
    commonConfig: {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: BUSINESS_LAUNCHER,
        },
      ],
    },
  });

  return (
    <Grid templateColumns='repeat(3, 1fr)' gap={5}>
      {boardList.map((board, index) => (
        <BoardCard
          data={board}
          key={index}
          onClick={onClick}
          onActionClick={onActionClick}
        />
      ))}
    </Grid>
  );
};

export default BoardsList;
