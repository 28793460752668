import React, { FC } from 'react';
import EditorViewHeader from './EditorViewHeader';
import ConfigEditorContainer from './ConfigEditorContainer';
import { Flex } from '@chakra-ui/react';
import EditorPhasesList from './EditorPhasesList';

interface IProps {}

const ConfigEditorView: FC<IProps> = () => {
  return (
    <Flex flex={1} flexDir='column' overflow='hidden'>
      <EditorViewHeader />

      <EditorPhasesList />

      <ConfigEditorContainer />
    </Flex>
  );
};

ConfigEditorView.displayName =
  'sub-components/Launcher/launcher-config/editor-view/ConfigEditorView';

export default ConfigEditorView;
