import { useLazyQuery } from '@apollo/client';
import {
  TaskType,
  TASK_TYPES,
} from 'sub-components/Launcher/dashboard/dashboard-grapghql';

export const useService = () => {
  const [getLauncherTaskCategories, launcherTaskCategoriesData] = useLazyQuery<{
    EntityLauncherTaskCategories: TaskType[];
  }>(TASK_TYPES, {
    // fetchPolicy: 'network-only',
  });
  return {
    launcherTaskCategories: {
      getLauncherTaskCategories,
      launcherTaskCategoriesData,
    },
  };
};
