import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import BaseTriggerInfo from './BaseTriggerInfo';

interface IProps {
  onAddClick?: () => void;
  type: 'phase' | 'task';
}

const NewCustomTriggerInfo: FC<IProps> = ({ onAddClick, type }) => {
  const { t } = useTranslation('launcher');

  return (
    <BaseTriggerInfo
      title={t('triggerIntro.setCustomActOrDeadTrigger')}
      buttonText={t('triggerIntro.addActOrDeadTrigger')}
      data={[
        {
          title: t('triggerIntro.hintOne', { context: type }),
          caption: t('triggerIntro.exampleOne', { context: type }),
        },
        {
          title: t('triggerIntro.hintTwo', { context: type }),
          caption: t('triggerIntro.exampleTwo'),
        },
      ]}
      onClick={onAddClick}
    />
  );
};

NewCustomTriggerInfo.displayName = 'sc/L/lc/c/t/NewCustomTriggerInfo';

export default NewCustomTriggerInfo;
