import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { Box, Center } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  FORMS_RESPONSE,
  FORMS_RESPONSE_EXPORT,
  FORM_BY_ID_RESPONSE,
  GET_FORM_RESPONDENTS,
  FormExportEntity,
} from 'pages/forms/forms-graphql';
import {
  FormRespondents,
  IFormRespondents,
  IFormResponse,
  IFormResponseArgs,
  IFormResponseItemsEntity,
  Respondent,
} from 'pages/forms/forms-types';
import FormDataContext from 'pages/forms/FormStore/form-data-context';
import { FormResponseTableWrapper } from './FormResponseTable.styles';
import ListPagination from 'atoms/ListPagination';
import Loader from 'sub-components/Loader';
import { ADD_LOG_FORM_RESPONSE_EXPORT } from './form-response-graphql';
import { GenerateFormResponseSheet } from './GenerateFormResponseSheetHandler/GenerateFormResponseSheet';
import { FormTable } from './FormResponseTableComponent';
import EmptyFormResponse from './EmptyFormResponse';
import { userObj } from 'sop-commons/src/client';
import { AmplitudeEventNames } from 'shared/amplitudeEvents/amplitude-events-types';
import { deployEvent } from 'shared/amplitudeEvents/AmplitudeEvents';
import FormReportExportFooter from './FormResponseExportFooter';
import {
  getLocationIds,
  getTableData,
  getTableHeader,
  getUserIds,
} from './form-response-helper';
import { useLocation } from 'react-router-dom';
import UserResponseFromUrlModal from './UserResponseFromUrlModal';
import { QuestionEntity } from '../../../../types';
import { HiddenResponse } from '../../../../configs';
import { IDateFilterRangeEntity } from 'ui-components/DateFilter/types';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'authorization';
import { usersEntityObj } from 'sub-components/Header';

export interface IAssigneeUsersEntity {
  eid: string;
  name: string;
  profilePic: string;
  authRole: string;
  role: string;
  locations: {
    name: string;
    eid: string;
  }[];
  selected?: boolean;
}

export interface ILocationFilter {
  name: string;
  eid: string;
  selected: boolean;
}

interface IProps {
  dateRangeFilter: IDateFilterRangeEntity | undefined;
  formId: string;
  sessionId?: string;
  showLocation?: boolean;
  showFilters?: boolean;
  usersData?: Respondent[];
  locationsData?: ILocationFilter[];
  userFilterClickHandler?: (user: Respondent) => void;
  locationFilterClickHandler?: (location: ILocationFilter) => void;
  setIsAnyTableFilterSelected?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IUserResponseFromUrl {
  userData: IFormResponseItemsEntity | undefined;
  showChat: boolean;
  show: boolean;
}

const FormResponseTable: FC<IProps> = ({
  dateRangeFilter,
  formId,
  sessionId,
  showFilters,
  showLocation,
  usersData,
  locationsData,
  userFilterClickHandler,
  locationFilterClickHandler,
  setIsAnyTableFilterSelected,
}) => {
  const { t } = useTranslation(['common', 'form']);
  const entityUserData = useReactiveVar(usersEntityObj);
  const [questions, setQuestions] = useState<QuestionEntity[]>([]);
  const formCtx = useContext(FormDataContext);
  const [page, setPage] = useState(1);
  const loggedInUserAuthRole = useUserDataSelector((state) => state.authRole);
  const [userResponseFromUrl, setUserResponseFromUrl] =
    useState<IUserResponseFromUrl>({
      userData: undefined,
      showChat: false,
      show: false,
    });
  const userData = useReactiveVar(userObj);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');

  useEffect(() => {
    if (userId && formCtx?.responseData?.items?.length > 0) {
      let foundUser = formCtx?.responseData?.items?.find(
        (item) => item?.userId === userId
      );
      if (foundUser) {
        setUserResponseFromUrl({
          ...userResponseFromUrl,
          show: true,
          userData: foundUser,
        });
      }
    }
  }, [userId, formCtx?.responseData?.items]);

  const [formResponseExportEvent] = useMutation(ADD_LOG_FORM_RESPONSE_EXPORT, {
    onCompleted: () => {
      deployEvent(AmplitudeEventNames.FORM_RESPONSE_EXPORT);
    },
  });

  const [getFormByIdResponse, { loading: formByIdResponseLoading }] =
    useLazyQuery(FORM_BY_ID_RESPONSE, {
      fetchPolicy: 'network-only',
    });

  const [getFormsResponse, { loading: formsResponseLoading }] = useLazyQuery<
    { FormResponse: IFormResponse },
    IFormResponseArgs
  >(FORMS_RESPONSE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let _formResponse: IFormResponse = JSON.parse(
        JSON.stringify(data?.FormResponse)
      );
      _formResponse?.items?.map((item, index) => {
        item?.form?.questions?.map((question) => {
          question?.options?.map((option) => {
            option.selected = false;
          });
        });
        item?.response?.map((res) => {
          res.isExpanded = false;
        });
      });
      formCtx.updateResponseData(_formResponse);
    },
  });

  const [getFormRespondents, { loading: formRespondentsLoading }] =
    useLazyQuery<IFormRespondents, { formId: string }>(GET_FORM_RESPONDENTS, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        let _formRespondents: FormRespondents = JSON.parse(
          JSON.stringify(data?.FormRespondents)
        );
        _formRespondents?.locations?.map(
          (location) => (location.selected = false)
        );
        _formRespondents?.users?.map((user) => (user.selected = false));
        formCtx.formRespondentsHandler({ FormRespondents: _formRespondents });
      },
    });

  const formResponseExportEventHandler = () => {
    formResponseExportEvent({
      variables: {
        input: {
          entityId: userData?.entityId,
          eventName: 'FormResponseExport',
          eventType: 'Query',
          // payload: { formId: params?.id },
          payload: { formId: formId },
          source: 'Client',
          userId: userData?.eid,
        },
      },
    });
  };

  const optionFilterChangeHandler = () => {
    let filteredOptionIds: string[] = [];
    questions?.map((_question) => {
      _question?.options?.map((_option) => {
        if (_option.selected) {
          filteredOptionIds?.push(_option?.eid);
        }
      });
    });
    return filteredOptionIds;
  };

  useEffect(() => {
    if (!formId) return;
    getQuery();
    getFormByIdResponse({
      variables: {
        eid: formId,
        ...(loggedInUserAuthRole === AuthRole.LOCATION_OWNER
          ? { override: true }
          : {}),
      },
    });
  }, [formId, page, formCtx.searchString]);

  useEffect(() => {
    const _formData = formCtx?.responseData?.items?.[0]?.form;

    if (_formData?.questions?.length > 0) {
      let incomingQuestions = _formData.questions.filter(
        (question) => !HiddenResponse.includes(question?.qType)
      );
      let _questions = incomingQuestions.map((incomingQuestion) => {
        const existingQuestion = questions.find(
          (question) => question.eid === incomingQuestion.eid
        );
        return existingQuestion ? existingQuestion : incomingQuestion;
      });
      setQuestions(_questions);
    }
  }, [formCtx?.responseData?.items?.[0]?.form?.questions]);

  useEffect(() => {
    getQuery();
  }, [dateRangeFilter]);

  const getQuery = (ids?: string[]) => {
    let queryObj: IFormResponseArgs = {
      filter: {
        // formId: params?.id,
        formId: formId,
        ...(sessionId ? { sessionId: sessionId } : null),
        ...(getUserIds(usersData)?.length > 0
          ? { userId: getUserIds(usersData) }
          : null),
        ...(getLocationIds(locationsData)?.length > 0
          ? { locations: getLocationIds(locationsData) }
          : null),
        ...(ids
          ? { optionsFilter: ids }
          : optionFilterChangeHandler()?.length > 0
          ? { optionsFilter: optionFilterChangeHandler() }
          : null),
        unique: false,
        ...(formCtx.searchString?.length > 0
          ? { query: formCtx.searchString }
          : null),
        ...(dateRangeFilter?.from && dateRangeFilter?.to
          ? {
              startDate: dateRangeFilter?.from?.toISOString(),
              endDate: dateRangeFilter?.to?.toISOString(),
            }
          : dateRangeFilter?.from
          ? { startDate: dateRangeFilter?.from?.toISOString() }
          : dateRangeFilter?.to
          ? { endDate: dateRangeFilter?.to?.toISOString() }
          : null),
      },
      page: page,
      perPage: 10,
      sort: '_ID_DESC',
    };
    if (
      getUserIds(usersData)?.length > 0 ||
      getLocationIds(locationsData)?.length > 0 ||
      optionFilterChangeHandler()?.length > 0 ||
      (ids && ids?.length > 0)
    ) {
      setIsAnyTableFilterSelected?.(true);
    } else {
      setIsAnyTableFilterSelected?.(false);
    }
    // if (params?.id) {
    if (formId) {
      getFormsResponse({
        variables: queryObj,
      });
      getFormRespondents({
        variables: {
          formId: formId,
        },
      });
    }
  };

  const [getFormExportData, { loading: exportDataLoading }] = useLazyQuery<
    Record<'FormResponseExport', FormExportEntity[]>
  >(FORMS_RESPONSE_EXPORT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      GenerateFormResponseSheet(
        questions,
        data?.FormResponseExport,
        formCtx?.formResponseByIdData
      );
    },
  });

  const generageUserWiseReportHandler = () => {
    formResponseExportEventHandler();
    getFormExportData({
      variables: {
        // formId: params?.id,
        formId: formId,
        ...(sessionId ? { sessionId: sessionId } : null),
        ...(getUserIds(usersData)?.length > 0
          ? { userIds: getUserIds(usersData) }
          : null),
        ...(getLocationIds(locationsData)?.length > 0
          ? { locations: getLocationIds(locationsData) }
          : null),
        ...(optionFilterChangeHandler()?.length > 0
          ? { optionsFilter: optionFilterChangeHandler() }
          : null),
        ...(dateRangeFilter?.from && dateRangeFilter?.to
          ? {
              startDate: dateRangeFilter?.from?.toISOString(),
              endDate: dateRangeFilter?.to?.toISOString(),
            }
          : dateRangeFilter?.from
          ? { startDate: dateRangeFilter?.from?.toISOString() }
          : dateRangeFilter?.to
          ? { endDate: dateRangeFilter?.to?.toISOString() }
          : null),
      },
    });
  };

  const renderLoader = () => {
    return (
      <Center w='100%' h='100%'>
        <Loader size='lg' />
      </Center>
    );
  };

  // const formResponseItems = useMemo(() => {
  //   if (!formCtx?.responseData?.items || !entityUserData) {
  //     return [];
  //   }

  //   const userStatusMap = new Map(
  //     entityUserData?.map((user) => [user?.eid, user?.status])
  //   );

  //   return formCtx?.responseData?.items?.filter((response) => {
  //     const userStatus = userStatusMap?.get(response?.userId);
  //     return userStatus === 'active' || userStatus === 'pending';
  //   });
  // }, [formCtx?.responseData?.items, entityUserData]);

  const renderTable = () => {
    return (
      <>
        <FormTable
          data={getTableData(questions, formCtx?.responseData?.items)}
          columns={getTableHeader(
            t,
            showFilters,
            usersData,
            getSelectedCountHandler,
            onFilterCloseHandler,
            userFilterClickHandler,
            formId,
            showLocation,
            locationsData,
            locationFilterClickHandler,
            questions,
            setQuestions,
            onQuestionFilterChange
          )}
        />
        <FormReportExportFooter
          count={formCtx?.responseData?.pageInfo?.itemCount}
          exportDataLoading={exportDataLoading}
          generageUserWiseReportHandler={generageUserWiseReportHandler}
        />
        {((formCtx?.responseData?.pageInfo?.currentPage === 1 &&
          formCtx?.responseData?.pageInfo?.hasNextPage) ||
          formCtx?.responseData?.pageInfo?.currentPage > 1) && (
          <Box marginBottom={'4rem'}>
            <ListPagination
              onPageChange={(page: number) => setPage(page)}
              data={formCtx?.responseData?.items}
              totalRegisters={formCtx.responseData.count}
              page={page}
              registersPerPage={10}
            />
          </Box>
        )}
        {userId && (
          <UserResponseFromUrlModal
            userResponseFromUrl={userResponseFromUrl}
            formId={formId}
            setUserResponseFromUrl={setUserResponseFromUrl}
          />
        )}
      </>
    );
  };

  const renderEmptyState = () => {
    return <EmptyFormResponse />;
  };

  const compRender = () => {
    if (
      formsResponseLoading ||
      formRespondentsLoading ||
      formByIdResponseLoading
    ) {
      return renderLoader();
    } else if (
      formCtx?.responseData?.items?.length > 0 ||
      optionFilterChangeHandler()?.length > 0 ||
      getUserIds(usersData)?.length > 0 ||
      getLocationIds(locationsData)?.length > 0 ||
      formCtx.searchString?.trim()?.length > 0 ||
      dateRangeFilter?.from
    ) {
      return renderTable();
    } else {
      return renderEmptyState();
    }
  };

  const generateTable = () => {
    return <FormResponseTableWrapper>{compRender()}</FormResponseTableWrapper>;
  };

  const onQuestionFilterChange = (ids?: string[]) => {
    getQuery(ids);
  };

  const onFilterCloseHandler = () => {
    getQuery();
  };

  const getSelectedCountHandler = (type: 'user' | 'location') => {
    let selection = type === 'user' ? usersData : locationsData;
    return (selection || []).filter((user) => user.selected === true).length;
  };

  return generateTable();
};

export default FormResponseTable;
