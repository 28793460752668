import { Box, Flex } from '@chakra-ui/react';
import { useAuditDetailContext } from 'pages/Audits/Detail/store/context';
import React, { FC } from 'react';
import { AuditSessionEntity } from 'sub-components/audits/audit-tab/supervised/types';

interface IProps {
  auditData: AuditSessionEntity | undefined;
}

const AssigneeDetail: FC<IProps> = ({ auditData }) => {
  const selectedLocationFilter = useAuditDetailContext(
    (state) => state.selectedLocationFilter
  );

  if (
    selectedLocationFilter &&
    selectedLocationFilter?.value !== 'allLocations'
  ) {
    return null;
  }

  return (
    <Flex justify='space-between' color='#1A1D1F'>
      <Box flex={1} fontWeight={600}>
        Assignees:
      </Box>
      <Flex flex={1} color='#6F767E' fontWeight={500} justifyContent='flex-end'>
        {auditData?.assignments?.length || 0}{' '}
        {auditData?.assignments?.length === 1 ? 'location' : 'locations'}
      </Flex>
    </Flex>
  );
};

AssigneeDetail.displayName = 'sc/a/d/c/O/c/SS/c/AssigneeDetail';

export default AssigneeDetail;
