import { useQuery } from '@apollo/client';
import { Box, Center, Flex, Image, Skeleton } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { MoreTrainingCell } from 'pages/Training/TrainingList/Component';
import {
  MemberTrainingList,
  MemberTrainingModal,
} from 'pages/Training/TrainingList/modal';
import {
  getAllTracks,
  TrackResponse,
} from 'pages/Training/TrainingList/training.graphql';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, SortingTable } from 'sub-components/ChakraTable/SortingTable';
import EmptyState from 'sub-components/EmptyState';
import UserTrainings from 'sub-components/TrackTrainingProgress/UserTrainings';
import WorkerPathName from 'sub-components/TrackTrainingProgress/WorkerPathName';
import { SortByName } from 'utils/sorting';
import TrackMembersEmpty from './TrackMembersEmpty';
import { deployEvent, CommonAmplitudeEvents } from 'shared/amplitudeEvents';
import Loader from 'sub-components/Loader';
import { getImage } from 'utils';
import { HeaderColors } from 'configs';
import { AllClear } from 'sub-components/nexus/NexusBase/components/common';
import { Button } from 'atoms';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { TRAINING_URL } from 'appRoutes';
import { useHistory } from 'react-router-dom';
import { IButtonValue } from 'pages/Training/TrainingList/TrackingContainer';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  table {
    background-color: white;
    thead {
      background-color: #f4f4f4;
      height: 44px !important;
      tr {
        border-radius: 5px;
        th:first-child {
          padding: 0px 20px !important;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        th:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
    tbody {
      tr {
        td:first-child {
          padding: 20px 0px 20px 20px !important;
        }
      }
    }
  }
`;

interface IProps {
  setShowViewAllBtn: React.Dispatch<React.SetStateAction<boolean>>;
  event?: any;
  layout?: 'card' | 'table';
}

const TrackMembers: FC<IProps> = ({
  setShowViewAllBtn,
  event,
  layout = 'table',
}) => {
  const { t, i18n } = useTranslation(['home', 'training', 'role']);
  const history = useHistory();
  const [selectedUser, setSelectedUser] = useState<
    MemberTrainingModal | undefined
  >(undefined);
  const { data, loading } = useQuery<TrackResponse>(getAllTracks, {
    fetchPolicy: 'network-only',
  });

  const sliceSize = 3;
  let slicedData = new MemberTrainingList(
    data?.trackWorkersTraining?.slice(0, sliceSize) || []
  )?.data;

  // const slicedData = useCallback(() => {
  //   if (
  //     data &&
  //     data?.trackWorkersTraining &&
  //     data?.trackWorkersTraining?.length > 0
  //   ) {
  //     let threeItems = data?.trackWorkersTraining?.slice(0, 3);
  //     return new MemberTrainingList(threeItems).data;
  //   }
  //   return [];
  // }, [data]);

  useEffect(() => {
    setShowViewAllBtn(slicedData.length > 0);
  }, [slicedData, setShowViewAllBtn]);

  const columns = useMemo((): Column<MemberTrainingModal>[] => {
    return [
      {
        Header: t('home:member'),
        accessor: 'name',
        width: '33.33%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <WorkerPathName
              pathName={value}
              thumbnail={row?.original.profilePic}
              index={row.index}
              address={row?.original.address}
              status='active'
              onClick={() => {
                setSelectedUser(row?.original);
                event && deployEvent(event);
              }}
            />
          );
        },
        sortType: SortByName,
      },
      {
        Header: t('role:job'),
        accessor: 'authRole',
        width: '33.33%',
        disableSortBy: true,
        Cell: ({ cell: { value, row } }) => {
          return (
            <Flex alignItems={'center'}>
              <Box
                mb='0px'
                fontSize='15px'
                fontWeight='600'
                textTransform='capitalize'
                pr={'20px'}
              >
                {value}
                <Box fontSize='13px' color='#2A85FF' pt={1} pr={'20px'}>
                  {row.original.role}
                </Box>
              </Box>
            </Flex>
          );
        },
      },
      {
        Header: t('home:assign_training'),
        accessor: 'assignedTrainings',
        width: '33.33%',
        Cell: ({ cell: { value } }) => {
          return (
            <MoreTrainingCell
              value={value}
              background='rgba(177, 229, 252, 0.4)'
            />
          );
        },
        disableSortBy: true,
      },
    ];
  }, [i18n.language]);
  const tableData = useMemo(() => {
    return new MemberTrainingList(data?.trackWorkersTraining).data;
  }, [data]);
  const filteredData = useMemo(() => {
    return tableData;
  }, [tableData]);

  const cardLayoutRender = () => {
    let _data = new MemberTrainingList(data?.trackWorkersTraining || [])?.data
      ?.filter(
        (data) =>
          data?.completedTrainings?.length + data?.unfinishedTrainings?.length >
          0
      )
      ?.slice(0, 3);
    if (loading) {
      return (
        <Center h='30vh'>
          <Loader />
        </Center>
      );
    }
    if (_data?.length === 0) {
      return (
        <Center h='30vh'>
          <AllClear desc='No overdue trainings for members' />
        </Center>
      );
    }
    return (
      <Flex gap={4} flexDir='column'>
        {_data?.map((slice, index) => (
          <Flex
            key={index}
            gap={2}
            borderRadius='12px'
            bg='#fcfcfc'
            border='1px solid #efefef'
            p='12px'
            cursor='pointer'
            onClick={() => {
              setSelectedUser(slice);
              event && deployEvent(event);
            }}
          >
            <Image
              src={getImage(slice?.profilePic, slice?.name)}
              boxSize='50px'
              borderRadius='12px'
            />
            <Flex flexDir='column' gap={2}>
              <Flex>{slice?.name}</Flex>
              <Flex>
                <Box color={HeaderColors.Red.Mid} fontWeight={600}>
                  {slice?.unfinishedTrainings?.length}
                </Box>
                &nbsp;
                <Box color='#6f767e' fontWeight={600}>
                  /&nbsp;
                  {slice?.unfinishedTrainings?.length +
                    slice?.completedTrainings?.length}{' '}
                  overdue
                </Box>
              </Flex>
            </Flex>
          </Flex>
        ))}
        {selectedUser?.eid ? (
          <UserTrainings
            user={selectedUser}
            onClose={() => setSelectedUser(undefined)}
            event={CommonAmplitudeEvents}
          />
        ) : null}
        <Button
          variant='outline'
          size='lg'
          onClick={() =>
            history.push(TRAINING_URL, {
              tabSelected: 'members' as IButtonValue,
            })
          }
          rightIcon={<ArrowForwardIcon fontSize='20px' />}
        >
          View all
        </Button>
      </Flex>
    );
  };

  return layout === 'table' ? (
    <>
      <SortingTable
        colorScheme='blue'
        emptyData={{
          content: <TrackMembersEmpty />,
        }}
        page={1}
        columns={columns}
        data={slicedData}
        isLoading={loading}
        isResponsive
        isHidePagination={true}
      />
      {selectedUser?.eid ? (
        <UserTrainings
          user={selectedUser}
          onClose={() => setSelectedUser(undefined)}
          event={CommonAmplitudeEvents}
        />
      ) : null}
    </>
  ) : (
    cardLayoutRender()
  );
};

export default TrackMembers;
