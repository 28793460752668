import { ArrowForwardIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Flex,
  HStack,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import { shallowEqual } from 'utils';
import debounce from 'lodash.debounce';
import moment from 'moment';
import { ITasksForToday } from 'pages/Home/Home';
import MyTaskItem from 'pages/TasksDashboard/Components/MyTaskItem';
import NotRelevant from 'pages/TasksDashboard/MyTasks/component/MyTaskListing/Listing/NotRelevant';
import { processTaskData } from 'pages/TasksDashboard/MyTasks/functions/processTaskData';
import {
  getMyTasks,
  getTaskById,
} from 'pages/TasksDashboard/MyTasks/services/MyTasks.service';
import {
  INotRelevantUserDetails,
  ITaskListDataEntity,
} from 'pages/TasksDashboard/tasks.types';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from 'ui-components/Loader';
import SingleUserChatModal from 'ui-components/SingleUserChatModal';
import MyTasksEmpty from './MyTasksEmpty';
import { deployEvent } from 'shared/amplitudeEvents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarLines } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TASKS_URL } from 'appRoutes';

interface IProps {
  hideViewBtn: boolean;
  layout?: 'simple' | 'full';
  setHideViewBtn: React.Dispatch<React.SetStateAction<boolean>>;
  tasksForTodayData: ITasksForToday;
  setTasksForTodayData: React.Dispatch<React.SetStateAction<ITasksForToday>>;
  event?: any;
}

export type IClickedHandlerType = 'open' | 'relevant';

const MyTasksTab: FC<IProps> = ({
  hideViewBtn,
  layout = 'full',
  setHideViewBtn,
  tasksForTodayData,
  setTasksForTodayData,
  event,
}) => {
  const userData = useUserDataSelector(
    (state) => ({
      authRole: state.authRole,
      type: state.type,
      timezone: state.timezone,
    }),
    shallowEqual
  );
  const history = useHistory();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedTaskId, setSelectedTaskId] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const { execute, loading, error, data } = getMyTasks();

  const getArgs = () => {
    // const lastYearDateISO = moment()?.subtract(1, 'years')?.toISOString();
    const sevenDaysAgoISO = moment().subtract(7, 'days').toISOString();
    return {
      endDate: sevenDaysAgoISO,
    };
  };

  useEffect(() => {
    execute({
      variables: getArgs(),
    });
  }, []);

  const {
    execute: executeTaskById,
    loading: taskByIdLoading,
    error: taskByIdError,
    data: taskByIdData,
  } = getTaskById();

  useEffect(() => {
    executeTaskById({
      variables: {
        eid: selectedTaskId,
      },
    });
  }, [isDrawerOpen]);

  const getDueTodayCount = () => {
    if (data?.MyTaskSession && data?.MyTaskSession?.length > 0) {
      let { taskDetails } = processTaskData(data, userData);
      const dueTodayArray = taskDetails.filter(
        (item) => item.type === 'dueToday'
      );
      return dueTodayArray.reduce((acc, curr: any) => acc.concat(curr.data), [])
        .length;
    }
    return 0;
  };

  useEffect(() => {
    if (userData?.authRole === AuthRole.WORKER || userData?.type === 'branch') {
      const count = getDueTodayCount();
      setTasksForTodayData((prevState) => ({
        ...prevState,
        total: count,
      }));
    }
  }, [data]);

  const filteredData = useMemo(() => {
    let { taskDetails } = processTaskData(data, userData);
    const selectedTasks = taskDetails
      .filter((task) => ['overdue', 'dueToday'].includes(task.type))
      ?.map((task) => task?.data)
      ?.flat()
      ?.slice(0, 3);

    if (selectedTasks?.length > 0) {
      setHideViewBtn(false);
    } else {
      setHideViewBtn(true);
    }
    return selectedTasks;
  }, [data]);

  const clickedHandler = (
    type: IClickedHandlerType,
    data: ITaskListDataEntity
  ) => {
    if (type === 'relevant') {
      setSelectedTaskId(data?.eid);
      setIsDrawerOpen(true);
    }
  };

  const getUsersList = () => {
    let _userDetails: INotRelevantUserDetails[] =
      taskByIdData?.TaskById?.supervisorsUsers;
    if (!searchQuery?.trim()) {
      return _userDetails;
    } else {
      return _userDetails?.filter((user) =>
        user?.name
          ?.toLowerCase()
          ?.trim()
          ?.includes(searchQuery?.toLowerCase()?.trim())
      );
    }
  };

  useEffect(() => {
    const delayDebounceFn = debounce(() => {
      getUsersList();
    }, 2000);
    delayDebounceFn();
    return () => delayDebounceFn.cancel();
  }, [searchQuery]);

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const simpleLayoutRender = () => {
    return (
      <Flex flexDir='column' gap={4}>
        {filteredData?.map((filter: ITaskListDataEntity) => (
          <Flex
            gap={2}
            borderRadius='12px'
            bg='#fcfcfc'
            border='1px solid #efefef'
            p='12px'
            cursor='pointer'
            flexDir='column'
            onClick={() => history.push(TASKS_URL)}
            key={filter.eid}
          >
            <HStack w='full' justify='space-between'>
              <Box as='span' fontWeight={600} color='#6f767e'>
                <FontAwesomeIcon icon={faCalendarLines as IconProp} />{' '}
                {filter?.date}
              </Box>
              <ArrowForwardIcon fontSize='2xl' color='#6f767e' />
            </HStack>
            <Box as='span' fontWeight={600}>
              {filter?.title}
            </Box>
            <Box as='span' fontWeight={600} color='#6f767e' fontSize='12px'>
              {filter?.countData.completedCount}/{filter?.countData.totalCount}{' '}
              left
            </Box>
          </Flex>
        ))}
      </Flex>
    );
  };

  const fullLayoutRender = () => {
    return (
      <Flex flexDir='column' gap={1}>
        <List spacing={1}>
          {filteredData?.map((filter) => (
            <ListItem
              key={filter?.eid}
              padding='12px 24px'
              bg='#FFFFFF'
              borderRadius='8px'
              _hover={{ bg: '#F9F9F9' }}
              onClick={() => event && deployEvent(event)}
            >
              <MyTaskItem
                task={filter}
                backToTitle='Back to Home'
                clickedHandler={(type: IClickedHandlerType, data: any) =>
                  clickedHandler(type, data)
                }
                truncRepeat
              />
            </ListItem>
          ))}
        </List>

        {userData?.authRole !== AuthRole.WORKER && userData?.type !== 'branch' && (
          <Center
            bg='white'
            gap='5px'
            color='#2A85FF'
            cursor='pointer'
            onClick={() => history.push('/tasks/my-tasks')}
            py='8px'
            borderBottomRadius='8px'
          >
            <Text fontWeight={600} fontSize='12.41px'>
              View all
            </Text>
            <ChevronRightIcon />
          </Center>
        )}
        {isDrawerOpen && (
          <NotRelevant
            taskByIdLoading={taskByIdLoading}
            isDrawerOpen={isDrawerOpen}
            searchQuery={searchQuery}
            getUsersList={getUsersList}
            onDrawerClose={onDrawerClose}
            setSearchQuery={setSearchQuery}
            setSelectedUserId={setSelectedUserId}
          />
        )}
        {selectedUserId && (
          <SingleUserChatModal
            usePortal
            selectedUser={selectedUserId}
            onClose={() => {
              setSelectedUserId('');
              setIsDrawerOpen(true);
            }}
          />
        )}
      </Flex>
    );
  };

  const layoutRender = () => {
    if (layout === 'simple') {
      return simpleLayoutRender();
    }
    return fullLayoutRender();
  };

  return loading ? (
    <Center h='30vh' bg='white'>
      <Loader />
    </Center>
  ) : (
    <>
      {filteredData?.length > 0 ? (
        layoutRender()
      ) : (
        <Flex bg='white' justify='center' py={4}>
          <MyTasksEmpty />
        </Flex>
      )}
    </>
  );
};

export default MyTasksTab;
