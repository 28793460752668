import { Box, Flex } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import { useProjectDetailPhaseContext } from '../../store/context';
import {
  ExtendedLauncherContent,
  PhaseData,
} from '../../store/project-detail-phase.types';
import { SelectionChip } from './phase-button-upper/components';

interface IProps {
  eid: string;
  index: number;
}

const PhaseName: FC<IProps> = ({ eid, index }) => {
  const { mode, phaseData } = useProjectDetailPhaseContext((state) => ({
    mode: state?.view,
    phaseData: state?.launcherData,
  }));
  const data = useMemo(() => {
    return phaseData?.contents?.filter((content) => content?.eid === eid)?.[0]!;
  }, [eid, phaseData]);
  return (
    <Flex gap='20px' align='center'>
      <Flex fontSize='16px' fontWeight={600}>
        <Box as='span'>{index + 1}.</Box>&nbsp;
        <Box as='span' textAlign='left'>
          {data?.category}
        </Box>
      </Flex>
      {mode === 'view' && <SelectionChip data={data} />}
    </Flex>
  );
};

PhaseName.displayName =
  'displayName:pages/LocationsNew/Components/ProjectDetailPhase/components/PhaseAccordionButton/PhaseName';

export default PhaseName;
