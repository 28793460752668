import React, { FC } from 'react';
import { Collapse, Flex } from '@chakra-ui/react';
import SectionHeader from './SectionHeader';
import SectionTasks from './SectionTasks';
import AddTaskButton from './AddTaskButton';
import { useLauncherConfigContext } from '../../common';

interface IProps {
  sectionIndex: number;
}

const EditorSectionItem: FC<IProps> = ({ sectionIndex }) => {
  const isOpen = useLauncherConfigContext((state) => {
    return state.selectedSection === sectionIndex;
  });

  return (
    <Flex flexDir='column' id={`section-item-${sectionIndex}`}>
      <SectionHeader sectionIndex={sectionIndex} />
      <Collapse in={isOpen}>
        <SectionTasks sectionIndex={sectionIndex} />
        <AddTaskButton sectionIndex={sectionIndex} />
      </Collapse>
    </Flex>
  );
};

EditorSectionItem.displayName = 'sc/L/lc/ev/s/EditorSectionItem';

export default React.memo(EditorSectionItem);
