import { gql } from '@apollo/client';
import { AuthRole } from '../../../../authorization';

export interface EntityUser {
  eid: string;
  name: string;
  email?: string;
  authRole: AuthRole;
  profilePic?: string;
  role: string;
  locations: Array<{
    eid: string;
    name: string;
  }>;
  createdAt: string;
  status: string;
}

export const EntityUserFragment = gql`
  fragment entityUser on User {
    eid
    name
    email
    authRole
    profilePic
    role
    locations {
      eid
      name
    }
    createdAt
    status
  }
`;
