import React, { FC, useEffect, useRef } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useLauncherConfigContext } from '../../common';
import { AddPhasePopup } from '../../common/add-phase';

interface IProps {}

const AddSection: FC<IProps> = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { addNewSection, openAddPhase } = useLauncherConfigContext((state) => {
    return {
      addNewSection: state.addNewSection,
      openAddPhase: state.openAddPhase,
    };
  });

  useEffect(() => {
    if (addNewSection) {
      setTimeout(() => {
        ref.current?.scrollIntoView({
          block: 'nearest',
          behavior: 'auto',
        });
      }, 100);
    }
  }, [addNewSection]);

  if (!addNewSection) {
    return (
      <Flex
        gap={3}
        p={4}
        maxHeight={12}
        bg='#FFFFFF'
        align='center'
        borderRadius='12px'
        cursor='pointer'
        userSelect='none'
        width='340px'
        minW='340px'
        onClick={() => openAddPhase(true)}
      >
        <FontAwesomeIcon size='lg' icon={faPlus as IconProp} color='#6F767E' />
        <Box fontSize='15px' fontWeight={500} color='#33383F'>
          Add Phase
        </Box>
      </Flex>
    );
  }

  return (
    <Flex flexDir='column' gap={3} width='340px' minW='340px'>
      <AddPhasePopup ref={ref} />
    </Flex>
  );
};

export default AddSection;
