import { InternalRefetchQueriesInclude, useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { toArray } from '../../../../../utils';

import { BaseBoardItemEntity } from '../../common';
import {
  DELETE_LAUNCHER_CONFIG,
  LauncherResponse,
  RENAME_LAUNCHER_CONFIG,
} from './board-action.graphql';
import { useDeleteBoard } from './delete-board';
import { useDuplicateBoard } from './duplicate-board';
import { useRenameBoard } from './rename-board';
import { useDuplicateBoardMutation } from './useDuplicateBoardMutation';

interface IProps {
  onRename?: (configTitle: string) => void;
  onDeleted?: () => void;
  onDuplicated?: (configEid: string) => void;
  commonConfig?: {
    awaitRefetchQueries?: boolean;
    refetchQueries?: InternalRefetchQueriesInclude;
  };
}

type BoardActionHandler = (action: string, data: BaseBoardItemEntity) => void;

export const useBoardActionHandler = (props?: IProps): BoardActionHandler => {
  const deleteBoard = useDeleteBoard();
  const duplicateBoard = useDuplicateBoard();
  const renameBoard = useRenameBoard();

  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const [updateConfig] = useMutation<LauncherResponse>(RENAME_LAUNCHER_CONFIG, {
    ...props?.commonConfig,
    onCompleted: (data) => {
      props?.onRename?.(data?.UpdateLauncherTasks?.title);
      toast({
        status: 'success',
        title: 'Success',
        description: 'Board name successfully updated.',
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Error!',
        description: 'Failed to update board title. Please try again.',
      });
    },
  });

  const [deleteConfig] = useMutation<LauncherResponse>(DELETE_LAUNCHER_CONFIG, {
    ...props?.commonConfig,
    onCompleted: () => {
      props?.onDeleted?.();
      toast({
        status: 'success',
        title: 'Success',
        description: 'Board successfully deleted.',
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Error!',
        description: 'Failed to delete board. Please try again.',
      });
    },
  });

  const [duplicateConfig] = useDuplicateBoardMutation({
    commonConfig: props?.commonConfig,
    onCompleted: (data) => {
      props?.onDuplicated?.(data?.eid);
    },
  });

  return (action: string, data: BaseBoardItemEntity) => {
    switch (action) {
      case 'rename':
        renameBoard({
          title: data.title,
          onSaveClick: async (title) => {
            const res = await updateConfig({
              variables: { input: { eid: data.eid, title: title } },
            });

            if (res.errors) {
              return Promise.reject(res.errors);
            }
          },
        });
        return;
      case 'duplicate':
        duplicateBoard({
          title: data.title,
          onSaveClick: async (title) => {
            const res = await duplicateConfig({
              variables: { eid: data.eid, title: title },
            });

            if (res.errors) {
              return Promise.reject(res.errors);
            }
          },
        });
        return;
      case 'delete':
        deleteBoard({
          title: data.title,
          locations: toArray(data.locationLaunches).reduce<string[]>(
            (acc, l) => {
              if (!l.isLive) {
                acc.push(l.details?.locationName);
              }
              return acc;
            },
            []
          ),
          onDeleteClick: async (deleteLocation) => {
            const res = await deleteConfig({
              variables: { eid: data.eid, deleteLocation },
            });

            if (res.errors) {
              return Promise.reject(res.errors);
            }
          },
        });
        return;
      default:
        console.log('Action clicked ==> ', action);
    }
  };
};
