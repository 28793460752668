import React, { FC } from 'react';
import { Box, Flex, Text, Image } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faLocationDot,
  faUser,
} from '@fortawesome/pro-light-svg-icons';
import { formatDateTime } from '../listing/helper';
import { getImage } from 'utils';
import { AssignmentType, SessionDetailEntity } from './types/report.types';
import { InfoCard, MoreDetails } from './components';
import { formatHeadingAndDetails } from './helper';
import RenderAllSections from './RenderAllSections';

type IProps = {
  auditData: SessionDetailEntity;
  additionalData: any;
  mappedQuestionData: any;
  assignment: AssignmentType;
};

const Overview: FC<IProps> = ({
  auditData,
  additionalData,
  mappedQuestionData,
  assignment,
}) => {
  return (
    <Box style={{ scrollMargin: '8vh' }} id='overview'>
      <Box>
        <Box border='1px solid #EFEFEF' borderRadius='12px'>
          <Flex
            gap={4}
            alignItems='center'
            padding='20px'
            borderTopRadius='12px'
          >
            <Image
              src={getImage(
                additionalData?.companyDetails?.profilePic,
                additionalData?.companyDetails?.name
              )}
              height={'82px'}
              alt='company-profile'
              borderRadius='8px'
            />
            <Flex gap={1} direction='column'>
              <Text
                fontSize='28px'
                fontWeight={600}
                lineHeight='35px !important'
              >
                {auditData?.title}
              </Text>
            </Flex>
          </Flex>

          <Flex>
            {Object.entries(additionalData?.cardInfo).map((item) => {
              const data = formatHeadingAndDetails(
                item,
                mappedQuestionData,
                additionalData
              );
              return (
                <InfoCard
                  key={item[0]}
                  heading={data?.heading}
                  details={data?.details}
                />
              );
            })}
          </Flex>

          <Flex flexDir='column'>
            {Object.entries(additionalData?.moreInfo).map((item) => {
              const data = formatHeadingAndDetails(
                item,
                mappedQuestionData,
                additionalData
              );
              return (
                <MoreDetails
                  key={item[0]}
                  heading={data?.heading}
                  details={data?.details}
                />
              );
            })}
          </Flex>
        </Box>
        <Box>
          <RenderAllSections
            mappedQuestionData={mappedQuestionData}
            additionalData={additionalData}
            assignment={assignment}
            auditData={auditData}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Overview;
