import { Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from 'atoms';
import React, { FC } from 'react';

const DeleteSubFolder: FC = () => {
  return (
    <Flex flexDir='column' gap={4}>
      <Flex
        bg='#B1E5FC66'
        p='16px 24px'
        borderRadius='10px'
        align='center'
        gap='10px'
      >
        <FontAwesomeIcon icon={faTrashCan as IconProp} color='#2A85FF' />
        <Text>Deleting this subfolder will delete all chapters.</Text>
      </Flex>
      <Flex align='center' justify='space-between' gap={2}>
        <PrimaryButton
          title='Move chapters'
          variant='solid'
          colorScheme='blue'
          style={{ width: '70%' }}
        />
        <PrimaryButton
          title='Delete anyway'
          variant='solid'
          colorScheme='red'
          style={{ width: '30%' }}
        />
      </Flex>
    </Flex>
  );
};

DeleteSubFolder.displayName =
  'displayName:pages/Chapters/CreateEditSubFolder/components/DeleteSubFolder';

export default DeleteSubFolder;
