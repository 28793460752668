import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Button } from '../../../../../../atoms';
import { eventBus } from '../../../../../../shared/eventEmit';
import TitleHeader from '../../../../../CardEditor/TitleHeader';
import {
  BaseTriggerEntity,
  TriggerEntity,
} from '../../launcher-config.fragment';
import TriggerContainer from '../task-trigger-setting/TriggerContainer';
import { mapPhases } from '../../trigger/PhaseTaskSelect';
import { triggerKey } from '../../trigger';
import { PhaseSelectItem } from '../../trigger/PhaseSelect';

interface IProps {
  title: string;
  desc: string;
  buttonText: string;
  onClick?: () => void;
}

const BaseSwitchTrigger: FC<IProps> = ({
  title,
  desc,
  buttonText,
  onClick,
}) => {
  return (
    <TriggerContainer>
      <div>
        <TitleHeader
          title={title}
          desc={desc}
          titleStyle={{ fontSize: '13px' }}
          descStyle={{ fontSize: '12px' }}
        />
      </div>

      <Button
        leftIcon={<AddIcon />}
        variant='outline'
        borderWidth='2px'
        borderRadius='12px'
        bgColor='white'
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </TriggerContainer>
  );
};

interface DProps {
  type: TriggerEntity['triggerChoice'];
  trigger?: BaseTriggerEntity;
  // title: string;
  description: string;
  onEditClick?: () => void;
}

export const BaseDetailView: FC<DProps> = ({
  type,
  trigger,
  description,
  onEditClick,
}) => {
  const [phases, setPhases] = useState<PhaseSelectItem[]>([]);

  useEffect(() => {
    eventBus.emit('GET_CONFIG', (state: any) => {
      setPhases(mapPhases(state.config.contents));
    });
  }, []);

  const title = useMemo(() => {
    if (type === 'activation') {
      return `Unlock ${trigger?.value} ${trigger?.unit} ${trigger?.order}`;
    }
    if (type === 'deadline') {
      return `Complete by ${trigger?.value} ${trigger?.unit} ${trigger?.order}`;
    }
  }, [type, trigger]);

  const dependTitle = useMemo((): string => {
    if (!trigger?.itemId && !trigger?.dependentOn) {
      return '';
    }

    if (triggerKey[trigger?.dependentOn]) {
      return triggerKey[trigger?.dependentOn];
    }

    if (trigger.dependentOn === 'phase') {
      return phases.find((phase) => phase.eid === trigger?.itemId)?.title!;
    }

    if (trigger.dependentOn === 'task') {
      for (const phase of phases) {
        const task = phase.tasks.find((task) => task.eid === trigger?.itemId);
        if (task) {
          return task.title;
        }
      }
    }

    return '';
  }, [phases, trigger]);

  return (
    <TriggerContainer flexDir='row'>
      <Flex flex={1} flexDir='column'>
        <Box fontSize='13px' fontWeight='600' color='#33383F'>
          {`${title} ${dependTitle || ''}`}
        </Box>
        <Box fontSize='12px' fontWeight='400' color='#999999'>
          {description}
        </Box>
      </Flex>
      <IconButton
        aria-label='edit-button'
        variant='outline'
        size='sm'
        onClick={onEditClick}
      >
        <FontAwesomeIcon icon={faPen as IconProp} />
      </IconButton>
    </TriggerContainer>
  );
};

export default BaseSwitchTrigger;
