import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Button } from './../../atoms';
import { ReactComponent as BackIcon } from 'assets/images/chevronBackward.svg';
import { useHistory } from 'react-router-dom';
import { Authorize, AuthRole } from '../../authorization';
import { AddIcon } from '@chakra-ui/icons';
import { ActionButton } from 'ui-components';
import { useTranslation } from 'react-i18next';

const NotificationHeading: FC = () => {
  const history = useHistory();
  const { t } = useTranslation(['notifications']);

  return (
    <Flex w='full' justify='space-between' align='center'>
      <Flex flexDir='column' gap={2}>
        <Text fontWeight={600} fontSize='28px' minH={8}>
          {t('notifications:common.heading_text')}
        </Text>

        <Button
          fontSize='14px'
          fontWeight={500}
          width='max-content'
          variant='link'
          leftIcon={<BackIcon width='16' height='16' />}
          _hover={{
            textDecoration: 'none',
          }}
          onClick={() => history.push('/')}
        >
          Back to Dashboard
        </Button>
      </Flex>
    </Flex>
  );
};

export default NotificationHeading;
