import React, { FC } from 'react';
import { Accordion, Flex } from '@chakra-ui/react';

interface IProps {}

const TaskFormContainer: FC<IProps> = ({ children }) => {
  return (
    <Accordion
      as={Flex}
      flexDir='column'
      gap={6}
      defaultIndex={0}
      reduceMotion
      allowToggle
    >
      {children}
    </Accordion>
  );
};

TaskFormContainer.displayName =
  'sub-components/Launcher/launcher-config/common/config-task-form/TaskFormContainer';

export default TaskFormContainer;
