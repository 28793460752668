import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useMemo, useState } from 'react';
import TaskStatusIcon from 'ui-components/TaskStatusIcon/TaskStatusIcon';
import LociconIcon from 'assets/images/nexus/compliance/locIcon.svg';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarLines,
  faDiagramSubtask,
  faCalendarCheck,
} from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import { LTaskCategoryEntity } from 'sub-components/Launcher/launcher-config/common/launcher-config.graphql';
import TaskAssignee from '../../../../../src/sub-components/Launcher/launcher-config/board-view/sections/launcher-tasks/TaskAssignee';
import { LocationPhaseDetails, PhaseTask } from './LauncherLocation.graphql';
import {
  getDependentPhase,
  getDependentTask,
  getTaskCategoryLabel,
  getTaskDueDate,
} from './helper';
import { StateDrawer } from 'sub-components/Launcher/location-owner/StatesDrawer/StateDrawer';
import { AuthRole, Authorize } from 'authorization';
import { useUserDataSelector } from 'hooks';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';

interface LocationTaskProps {
  loading?: boolean;
  task: PhaseTask;
  categories: LTaskCategoryEntity[];
  launchId?: string;
  locationId: string;
  refetchData?: () => void;
  phaseDetails: LocationPhaseDetails[];
  goLiveDate?: Date;
}

const LauncherLocationTask: FC<LocationTaskProps> = ({
  loading,
  task,
  categories,
  launchId,
  locationId,
  refetchData,
  phaseDetails,
  goLiveDate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => setIsOpen((state) => !state);
  const allUsers = useReactiveVar(usersEntityObj);

  const getCompletedByUserName = (eid: string) => {
    return allUsers.find((user) => user.eid === eid)?.name || '';
  };

  const getColorByTaskStatus = (status: string) => {
    switch (status) {
      case 'overdue':
        return '#FF6A55';
      case 'inProgress':
        return '#2A85FF';
      case 'locked':
        return '#6F767E';
      case 'completed':
        return '#83BF6E';
      case 'duesoon':
        return '#8E59FF';
      default:
        return '#6F767E';
    }
  };

  const { currentUserId, currentUserAuthRole } = useUserDataSelector(
    (state) => ({
      currentUserId: state.eid,
      currentUserAuthRole: state.authRole,
    })
  );

  const schedule = useMemo(() => {
    const {
      dueDate,
      completedAt,
      triggerDetails,
      startDate,
      title,
      completedBy,
    } = task;
    const now = moment().utc();

    const due = getTaskDueDate(task, goLiveDate as Date);

    if (completedAt) {
      return `Completed on ${moment(completedAt).format(
        'MMM D'
      )} by ${getCompletedByUserName(completedBy)}`;
    }

    const getDueMessage = (due: moment.Moment) => {
      if (due.isSameOrBefore(now)) {
        const overdueDays = now.diff(due, 'days');
        return `Overdue by ${overdueDays} day${overdueDays !== 1 ? 's' : ''}`;
      }

      const duration = moment.duration(due.diff(now));

      if (duration.years() > 0) {
        return `Due in ${duration.years()} year${
          duration.years() !== 1 ? 's' : ''
        } ${duration.months()} month${
          duration.months() !== 1 ? 's' : ''
        } ${duration.days()} day${duration.days() !== 1 ? 's' : ''}`;
      }

      if (duration.months() > 0) {
        return `Due in ${duration.months()} month${
          duration.months() !== 1 ? 's' : ''
        } ${duration.days()} day${duration.days() !== 1 ? 's' : ''}`;
      }

      if (duration.days() > 0) {
        return `Due in ${duration.days()} day${
          duration.days() !== 1 ? 's' : ''
        }`;
      }

      if (duration.hours() > 0) {
        return `Due in ${duration.hours()} hour${
          duration.hours() !== 1 ? 's' : ''
        }`;
      }

      return `Due in ${duration.minutes()} minute${
        duration.minutes() !== 1 ? 's' : ''
      }`;
    };

    return getDueMessage(due);
  }, [task]);

  const dueDate = useMemo(() => {
    const { triggerDetails } = task;

    if (!triggerDetails) return moment(goLiveDate).format('MMM D');
    let dependentItem;

    if (triggerDetails.dependantOn === 'phase') {
      dependentItem = getDependentPhase(
        phaseDetails,
        triggerDetails.triggerItemId
      );
    }

    if (triggerDetails.dependantOn === 'task') {
      dependentItem = getDependentTask(
        phaseDetails,
        triggerDetails.triggerItemId
      );
    }

    const isDependantItemComplete = dependentItem?.isCompleted || false;
    const dependantItemCompletedAt = dependentItem?.completedAt || goLiveDate;

    return getTaskDueDate(
      task,
      goLiveDate as Date,
      isDependantItemComplete,
      dependantItemCompletedAt
    ).format('MMM D');
  }, [task]);

  const status = task.status;
  const categoryLabel = getTaskCategoryLabel(
    categories,
    task?.category as string
  );

  const shouldShowTask = (): boolean => {
    if (
      currentUserAuthRole === AuthRole.SUPER_ADMIN ||
      currentUserAuthRole === AuthRole.ADMIN
    ) {
      return true;
    }

    if (
      (currentUserAuthRole === AuthRole.LOCATION_OWNER &&
        task.assignedType === 'location') ||
      task.assignedUser?.includes(currentUserId)
    ) {
      return true;
    }

    return false;
  };

  return shouldShowTask() ? (
    <Flex
      background='#F4F4F4'
      padding='18px 24px'
      borderRadius='12px'
      justifyContent='space-between'
      alignItems='center'
      onClick={toggleDrawer}
      cursor='pointer'
    >
      <Box display='flex' gap='15px'>
        <Box>
          <Flex gap={3} alignItems='center'>
            <TaskStatusIcon
              status={status}
              iconStyles={{ marginTop: '2px', width: '20px', height: '20px' }}
            />

            <Text
              fontSize={14}
              fontWeight={500}
              color={status === 'completed' ? '#6F767E' : '#272B30'}
              textDecoration={status === 'completed' ? 'line-through' : 'none'}
            >
              {task.title}
            </Text>
          </Flex>

          <Flex
            gap={2}
            alignItems='center'
            marginLeft={8}
            color={getColorByTaskStatus(status)}
          >
            {status === 'completed' ? (
              <>
                <FontAwesomeIcon
                  icon={faCalendarCheck as IconProp}
                  fontSize='12px'
                />
                <Text fontSize={12}>{schedule}</Text>
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  icon={faCalendarLines as IconProp}
                  fontSize='12px'
                />
                <Text fontSize={12} maxWidth='80%'>
                  {task.unlockMessage ? task.unlockMessage : dueDate}
                </Text>
                {status !== 'locked' && (
                  <>
                    <Box
                      width='4px'
                      height='4px'
                      borderRadius='full'
                      background='#D1D8E0'
                    />
                    <Text color={getColorByTaskStatus(status)} fontSize={12}>
                      {schedule}
                    </Text>
                  </>
                )}
              </>
            )}

            {categoryLabel && status !== 'locked' ? (
              <>
                <Box
                  width='4px'
                  height='4px'
                  borderRadius='full'
                  background='#D1D8E0'
                />
                <Text color={getColorByTaskStatus(status)} fontSize={12}>
                  {categoryLabel}
                </Text>
              </>
            ) : null}

            <Box
              width='4px'
              height='4px'
              borderRadius='full'
              background='#D1D8E0'
            />
            <Box display='flex' gap={2} alignItems='center'>
              <FontAwesomeIcon
                icon={faDiagramSubtask as IconProp}
                fontSize='12px'
              />
              <Text fontSize={12}>
                {task.steps.length} subtask{task.steps.length > 1 && 's'}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Box>

      {task.assignedUser?.length ? (
        <TaskAssignee
          assignedType={task.assignedType!}
          assignedUser={task.assignedUser || []}
        />
      ) : (
        <Image src={LociconIcon} />
      )}

      <StateDrawer
        status={status}
        task={task}
        launchId={launchId}
        locationId={locationId}
        isOpen={isOpen}
        toggleDrawer={toggleDrawer}
        refetchData={refetchData}
        phaseDetails={phaseDetails}
        categories={categories}
      />
    </Flex>
  ) : null;
};

LauncherLocationTask.displayName =
  'displayName:sub-components/Launcher/dashboard/Location/LauncherLocationTask';

export default LauncherLocationTask;
