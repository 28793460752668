import { Box, Flex } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import React, { FC } from 'react';
import { AuditStatusFunctionEntity } from 'sub-components/audits/audit-tab/supervised/types';

interface IProps {
  auditStatus: AuditStatusFunctionEntity;
}

const StatusDetail: FC<IProps> = ({ auditStatus }) => {
  const getAuditStatus = () => {
    if (auditStatus === 'overdue') {
      return (
        <Box
          bg={HeaderColors.Red?.Mid}
          borderRadius='6px'
          p='2px 8px'
          color='black'
        >
          Overdue
        </Box>
      );
    } else if (auditStatus === 'ongoing') {
      return (
        <Box
          bg={HeaderColors.Yellow}
          borderRadius='6px'
          p='2px 8px'
          color='black'
        >
          Ongoing
        </Box>
      );
    } else if (auditStatus === 'upcoming') {
      return (
        <Box
          bg={HeaderColors.Purple}
          borderRadius='6px'
          p='2px 8px'
          color='black'
        >
          Upcoming
        </Box>
      );
    } else if (auditStatus === 'completed') {
      return (
        <Box
          bg={HeaderColors.Green}
          borderRadius='6px'
          p='2px 8px'
          color='black'
        >
          Completed
        </Box>
      );
    }
  };
  return (
    <Flex justify='space-between' color='#1A1D1F'>
      <Box flex={1} fontWeight={600}>
        Status:
      </Box>
      <Flex flex={1} color='#6F767E' fontWeight={500} justify='flex-end'>
        {getAuditStatus()}
      </Flex>
    </Flex>
  );
};

StatusDetail.displayName = 'sc/a/d/c/O/c/SS/c/StatusDetail';

export default StatusDetail;
