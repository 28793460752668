import React, { FC } from 'react';
import { Box, ButtonGroup, Flex } from '@chakra-ui/react';
import { cloneDeep } from '@apollo/client/utilities';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from 'atoms';
import { AuditHelper } from 'configs';
import { QuestionType } from 'types';
import { toArray } from 'utils';

import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import { IFormInput } from '../form-types';
import { AuditQuestionContainer } from './audit-questions';
import { AuditOptionsEntity } from '../audit.types';
import NullComp from '../../../forms/component/NullComp';

const AuditContentSection: FC = () => {
  const { getValues, setValue } = useFormContext<IFormInput>();
  const { fields, append, insert, remove, move, update } = useFieldArray<
    IFormInput,
    'questions'
  >({
    name: 'questions',
  });

  const isAuditQuestion = useWatch<IFormInput, 'isAuditQuestion'>({
    name: 'isAuditQuestion',
  });

  const addQuestion = () => {
    const tempEid = uuidv4();
    setValue('selectedQuestion', tempEid);
    append({
      tempEid: tempEid,
      label: '',
      options: [
        {
          tempEid: uuidv4(),
          label: '',
        },
      ],
      qType: QuestionType.MULTI_CHOICE,
    });
  };

  const addSection = () => {
    const questions = getValues('questions') || [];

    const length = questions.filter(
      (Q) => Q.qType === QuestionType.SECTION && !Q.isDisabled
    ).length;

    const tempEid = uuidv4();
    setValue('selectedQuestion', tempEid);
    append({
      tempEid: tempEid,
      label: `Section ${length + 1}`,
      options: [],
      qType: QuestionType.SECTION,
    });
  };

  const handleRemove = (index: number) => {
    const question = getValues(`questions.${index}`);
    if (isAuditQuestion && question?.eid) {
      update(index, {
        ...question,
        isDisabled: true,
      });
    } else {
      remove(index);
    }
    setTimeout(AuditHelper.save, 100);
  };

  const handleDuplicate = (index: number) => {
    const tempEid = uuidv4();
    setValue('selectedQuestion', tempEid);
    const questions = getValues('questions') || [];
    const ques = questions[index];
    insert(
      index + 1,
      {
        eid: undefined,
        tempEid: tempEid,
        qType: ques.qType,
        isRequired: ques.isRequired,
        isDisabled: ques.isDisabled,
        hasFlaggedResponse: ques.hasFlaggedResponse,
        requiresEvidence: ques.requiresEvidence,
        hasScoring: ques.hasScoring,
        options: toArray(cloneDeep(ques.options)).reduce<AuditOptionsEntity[]>(
          (acc, opt) => {
            if (opt.isDisabled) {
              return acc;
            }
            delete opt.eid;
            acc.push(opt);
            return acc;
          },
          []
        ),
        label: ques.label.concat(' - (copy)'),
        subTitle: ques.subTitle,
        score: ques.score,

        // isMultiSelect?: boolean;
        // hasOtherOption?: boolean;
        // imageRequired?: boolean;
      },
      {
        shouldFocus: true,
        focusName: `questions.${index + 1}.label`,
      }
    );

    setTimeout(AuditHelper.save, 100);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <Flex flexDir='column' gap={5}>
      <Box pb={2}>
        <TitleHeader
          title='Content section'
          desc='This is where you add your inspection questions and how you want them to be answered'
        />
      </Box>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={(event) => {
          const oldIndex = event?.active.data.current?.questionIndex;
          const newIndex = event?.over?.data.current?.questionIndex;

          if (
            typeof oldIndex === 'number' &&
            typeof newIndex === 'number' &&
            oldIndex !== newIndex
          ) {
            move(oldIndex, newIndex);
            setTimeout(AuditHelper.save, 100);
          }
        }}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext items={fields} strategy={verticalListSortingStrategy}>
          {fields?.map((field, questionIndex) => {
            return (
              <AuditQuestionContainer
                fieldId={field.id}
                key={field.tempEid}
                questionIndex={questionIndex}
                handleRemove={handleRemove}
                handleDuplicate={handleDuplicate}
              />
            );
          })}
        </SortableContext>
      </DndContext>

      <ButtonGroup size='lg' variant='outline' spacing={4} color='#1A1D1F'>
        <Button
          leftIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
          onClick={addQuestion}
        >
          Add Question
        </Button>
        <Button
          leftIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
          onClick={addSection}
        >
          Add section
        </Button>
      </ButtonGroup>
      <NullComp />
    </Flex>
  );
};

export default AuditContentSection;
