import { FC, ReactNode } from 'react';
import { Center, Flex } from '@chakra-ui/react';
import {
  LauncherLocationTaskProgress,
  LauncherLocationTasksWrapper,
} from '../dashboard/Location';
import { LocationHeader } from './LocationHeader';
import { useControl } from './layers';
import { Loader } from 'sub-components';

interface ILocation {
  name: string;
  address: string;
  contact: string;
}

interface ISubtask {
  id: number;
  title: string;
  isCompleted: boolean;
}

interface IField {
  icon: any;
  label: string;
  value: string | ReactNode;
}

interface ILockedState {
  status: 'overdue' | 'locked' | 'yetToStart' | 'inProgress';
  fields: IField[];
  subtasks: ISubtask[];
}

interface IProps {
  location: ILocation;
  lockedState: ILockedState;
}

const LocationOwnerContainer: FC<IProps> = () => {
  const control = useControl();
  return control?.loading ? (
    <Center h='80vh'>
      <Loader />
    </Center>
  ) : (
    <Flex flexDirection='column' marginTop={1} gap={5}>
      <LocationHeader />
      <Flex flexDirection='column' marginTop={1} gap={3}>
        <LauncherLocationTaskProgress
          goLiveDate={control?.locLaunchData?.liveDate || new Date()}
          phaseDetails={control?.locLaunchData?.contents || []}
        />
        <LauncherLocationTasksWrapper
          locationId={control?.locLaunchData?.locationId || ''}
          launchId={control?.locationLaunchId}
          phaseDetails={control?.locLaunchData?.contents || []}
          liveDate={control?.locLaunchData?.liveDate || new Date()}
          refetchData={control?.refetchData}
        />
      </Flex>
    </Flex>
  );
};

LocationOwnerContainer.displayName =
  'displayName:sub-components/Launcher/location-owner/LocationOwnerContainer';

export default LocationOwnerContainer;
