import React, { FC } from 'react';
import { LauncherTasksContainerProps } from '../../launcher-tasks-types';
import ByLocation from '../ByLocation';
import ByTask from '../ByTask';
import ByStatus from '../ByStatus';
import { Box } from '@chakra-ui/react';

const LauncherTasksContainer: FC<LauncherTasksContainerProps> = ({
  categories,
  data,
  activeFilterLabel,
  refetch,
}) => {
  const render = () => {
    switch (activeFilterLabel) {
      case 'location':
        return (
          <ByLocation categories={categories} data={data} refetch={refetch} />
        );
      case 'taskType':
        return <ByTask categories={categories} data={data} refetch={refetch} />;
      case 'status':
        return (
          <ByStatus categories={categories} data={data} refetch={refetch} />
        );
      default:
        return null;
    }
  };

  return <Box marginTop='1rem'>{render()}</Box>;
};

LauncherTasksContainer.displayName =
  'displayName:sub-components/Launcher/tasks/components/common/LauncherTasksContainer';

export default LauncherTasksContainer;
