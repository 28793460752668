import React, { FC } from 'react';
import { Flex, useModalContext, useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useReactiveVar } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { useUploadImage, useUserDataSelector } from '../../../../../hooks';
import { ActionButton } from '../../../../../ui-components';
import { updateObject } from '../../../../../utils/objectHelper';

import UploadDocument from './common/UploadDocument';
import { IDocumentInput } from './common/compliance.input';
import {
  ADD_COMPLIANCE,
  AddComplianceInput,
  AddComplianceResponse,
  MetaComplianceData,
} from './add-document.graphql';
import { Input, toComplianceInput } from './compliance.helper';
import { eventBus } from '../../../../../shared/eventEmit';
import {
  GET_NEXUS_COMPLIANCES,
  GET_NEXUS_COMPLIANCES_CATEGORY,
} from 'pages/NexusDashboard/Compliance/query/nexus-compliance.graphql';

interface IProps {
  localSave?: boolean;
  predefinedLicense?: string;
  preDefinedLocationName?: string;
  preDefinedLocationId?: string;
  localSaveCb?: (data: Input) => void;
  successCb?: (data: Input | undefined) => void;
  initialFile?: File;
  metaComplianceData?: MetaComplianceData;
}

const NewDocumentContent: FC<IProps> = ({
  localSave,
  predefinedLicense,
  preDefinedLocationName,
  preDefinedLocationId,
  localSaveCb,
  successCb,
  initialFile,
  metaComplianceData,
}) => {
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const userId = useUserDataSelector((state) => state.eid);
  const { onClose } = useModalContext();

  const methods = useForm<IDocumentInput>({
    defaultValues: {
      expiryDate: null as never,
      reminder: {
        remindType: 'days',
        remindBefore: 1,
      },
      predefinedLicense: predefinedLicense,
      preDefinedLocationName: preDefinedLocationName,
      preDefinedLocationId: preDefinedLocationId,
      file: initialFile
        ? {
            type: initialFile.type,
            name: initialFile.name,
            fileSize: initialFile.size,
            mimetype: initialFile.type,
            rawFile: initialFile,
            createdBy: userId,
          }
        : undefined,
    },
  });

  const [addCompliance] = useMutation<
    AddComplianceResponse,
    AddComplianceInput
  >(ADD_COMPLIANCE, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Compliance document successfully added.',
      });
      successCb?.({
        categoryId: methods?.getValues('category')?.value,
        file: methods?.getValues('file'),
        reminder: methods?.getValues('reminder'),
        title: methods?.getValues('title'),
        expiryDate: methods?.getValues('expiryDate'),
        locationId: methods?.getValues('location')?.value,
      });
      eventBus.emit('refetchCompliance');
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Compliance document creation failed!',
      });
    },
    refetchQueries: [
      GET_NEXUS_COMPLIANCES,
      'EntityCompliancePagination',
      GET_NEXUS_COMPLIANCES_CATEGORY,
      'EntityComplianceCategories',
    ],
  });

  const uploadImage = useUploadImage();

  const onSubmit = async (values: IDocumentInput) => {
    const input = toComplianceInput(cloneDeep(values), metaComplianceData);
    if (values.file?.rawFile) {
      const fileUrl = await uploadImage(values.file?.rawFile!);
      methods.setValue('file.url', fileUrl);
      updateObject(input, ['file', 'url'], fileUrl);
    }
    if (localSave) {
      setTimeout(onClose);
      return localSaveCb?.(input);
    }
    const response = await addCompliance({ variables: { input: input } });

    if (response.errors) {
      return Promise.reject(response.errors);
    }

    setTimeout(onClose);
  };

  return (
    <FormProvider {...methods}>
      <Flex flexDir='column' gap={4}>
        <UploadDocument />
        <ActionButton
          isFullWidth
          colorScheme='blue'
          mt={2}
          actionFn={methods.handleSubmit(onSubmit)}
          isDisabled={!methods.formState.isDirty}
        >
          Add document
        </ActionButton>
      </Flex>
    </FormProvider>
  );
};

NewDocumentContent.displayName =
  'displayName:sub-components/nexus/Compliance/Create/components/NewDocumentContent';

export default NewDocumentContent;
