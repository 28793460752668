import React, { FC, useState } from 'react';
import { Box, Text, Flex, Button } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModuleFilterContent from './ModuleFilterContent';
import { IFilters } from '../../types';

interface IProps extends IFilters {}

const ModuleAccordion: FC<IProps> = ({
  setSelectedModules,
  checkedModules,
  setCheckedModules,
  handleModuleSelect,
}) => {
  const [showModuleFilterContent, setShowModuleFilterContent] = useState(false);

  return (
    <Box>
      <Flex
        cursor={'pointer'}
        borderRadius={'12px'}
        p={'5px 12px'}
        backgroundColor={'#F4F4F4'}
        justifyContent={'space-between'}
        alignItems={'center'}
        onClick={() => setShowModuleFilterContent(!showModuleFilterContent)}
      >
        <Text
          fontWeight={600}
          fontSize={'14px'}
          cursor={'pointer'}
          borderRadius='12px'
          padding='6px 12px'
          _hover={{
            bg: '#EFEFEF',
            color: 'black',
          }}
        >
          Module type
        </Text>
        <FontAwesomeIcon icon={faChevronDown as IconProp} />
      </Flex>
      {showModuleFilterContent && (
        <ModuleFilterContent
          setSelectedModules={setSelectedModules}
          checkedModules={checkedModules}
          setCheckedModules={setCheckedModules}
          handleModuleSelect={handleModuleSelect}
        />
      )}
    </Box>
  );
};
ModuleAccordion.displayName = 'sc/NC/D/c/f/m/ModuleAccordion.tsx';
export default ModuleAccordion;
