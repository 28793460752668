import { HeaderColors } from 'configs';
import moment from 'moment';
import { DashboardFiltersType } from '../types';

export const dashboardFilters: DashboardFiltersType = [
  {
    id: 1,
    filterKey: 'status',
    filterLabel: 'By status',
  },
  {
    id: 2,
    filterKey: 'location',
    filterLabel: 'By location',
  },
  {
    id: 3,
    filterKey: 'taskType',
    filterLabel: 'Task type',
  },
];

export const dummyTasks = [
  {
    name: 'Overdue Tasks',
    key: 'overdue',
    color: '#FF1F0033',
    count: 5,
    selected: false,
  },
  {
    name: 'Due Today',
    key: 'today',
    color: '#CABDFF',
    count: 5,
    selected: false,
  },
  {
    name: "This Week's Tasks",
    color: '#B1E5FC',
    key: 'week',
    count: 5,
    selected: false,
  },
  {
    name: 'Total Tasks',
    color: '#FFD88D',
    count: 5,
    key: 'total',
    selected: false,
  },
];

export const launcherTasks = [
  {
    eid: 1,
    status: 'overdue',
    title: 'Complete financial report and submit for review.',
    location: 'New York',
    steps: 5,
    completedSteps: 3,
    taskType: 'Finance',
    subTasks: 3,
    startDate: moment(new Date(2024, 10, 1)),
    endDate: moment(new Date(2024, 10, 10)),
    expectedCompletionDate: '10th Nov',
    recurringDays: 'Mon, Tue, Wed',
    recurringTrigger: 'End of every month',
    lockedDependencyOn: 'Obtain certificate of occupancy',
    timeRemaining: '5 hours',
    heading: { label: 'overdue', color: HeaderColors.Red.Mid },
  },
  {
    eid: 2,
    status: 'today',
    title: 'Prepare necessary paperwork for regulatory compliance.',
    location: 'California',
    steps: 5,
    completedSteps: 1,
    taskType: 'Legal',
    subTasks: 4,
    startDate: moment(new Date(2024, 10, 11)),
    endDate: moment(new Date(2024, 10, 15)),
    expectedCompletionDate: '15th Nov',
    recurringDays: 'Thu, Fri',
    recurringTrigger: 'First Monday of every month',
    lockedDependencyOn: 'Prepare any necessary paperwork',
    timeRemaining: '3 hours',
    heading: { label: 'today', color: HeaderColors.Blue },
  },
  {
    eid: 3,
    status: 'inProgress',
    title: 'Monitor and restock supplies for daily operations.',
    location: 'Texas',
    steps: 5,
    completedSteps: 2,
    taskType: 'Operations',
    subTasks: 5,
    startDate: moment(new Date(2024, 10, 12)),
    endDate: moment(new Date(2024, 10, 20)),
    expectedCompletionDate: '20th Nov',
    recurringDays: 'Sat, Sun',
    recurringTrigger: 'Last Friday of every month',
    lockedDependencyOn: 'Monitor and restock supplies',
    timeRemaining: '8 hours',
    heading: { label: 'inProgress', color: HeaderColors.Green },
  },
  {
    eid: 4,
    status: 'yetToStart',
    title: 'Organize team training session for updated procedures.',
    location: 'Chicago',
    steps: 5,
    completedSteps: 0,
    taskType: 'HR',
    subTasks: 6,
    startDate: moment(new Date(2024, 10, 18)),
    endDate: moment(new Date(2024, 10, 25)),
    expectedCompletionDate: '25th Nov',
    recurringDays: 'Tue, Wed, Thur',
    recurringTrigger: '31st Dec of every year',
    lockedDependencyOn: 'Organize required training sessions',
    timeRemaining: '12 hours',
    heading: { label: 'yetToStart', color: HeaderColors.Yellow },
  },
  {
    eid: 5,
    status: 'today',
    title: 'Prepare financial audit documentation.',
    location: 'Florida',
    steps: 5,
    completedSteps: 5,
    taskType: 'Finance',
    subTasks: 3,
    startDate: moment(new Date(2024, 10, 8)),
    endDate: moment(new Date(2024, 10, 12)),
    expectedCompletionDate: '12th Nov',
    recurringDays: 'Mon, Fri',
    recurringTrigger: 'First Friday of every month',
    lockedDependencyOn: 'Review accounts for discrepancies',
    timeRemaining: '2 hours',
    heading: { label: 'today', color: HeaderColors.GreenDark },
  },
  {
    eid: 6,
    status: 'inProgress',
    title: 'Analyze and report marketing trends.',
    location: 'Illinois',
    steps: 5,
    completedSteps: 4,
    taskType: 'Marketing',
    subTasks: 2,
    startDate: moment(new Date(2024, 10, 10)),
    endDate: moment(new Date(2024, 10, 18)),
    expectedCompletionDate: '18th Nov',
    recurringDays: 'Tue, Thu',
    recurringTrigger: 'Last Thursday of every month',
    lockedDependencyOn: 'Review social media engagement',
    timeRemaining: '6 hours',
    heading: { label: 'inProgress', color: HeaderColors.Purple },
  },
  {
    eid: 7,
    status: 'overdue',
    title: 'Draft and finalize new hiring policies.',
    location: 'Alaska',
    steps: 5,
    completedSteps: 1,
    taskType: 'HR',
    subTasks: 4,
    startDate: moment(new Date(2024, 9, 25)),
    endDate: moment(new Date(2024, 10, 30)),
    expectedCompletionDate: '30th Nov',
    recurringDays: 'Mon, Wed',
    recurringTrigger: 'First day of every quarter',
    lockedDependencyOn: 'Review compliance regulations',
    timeRemaining: '7 hours',
    heading: { label: 'overdue', color: HeaderColors.Red.Dark },
  },
  {
    eid: 8,
    status: 'yetToStart',
    title: 'Coordinate with vendors for supply chain updates.',
    location: 'New York',
    steps: 5,
    completedSteps: 0,
    taskType: 'Operations',
    subTasks: 6,
    startDate: moment(new Date(2024, 10, 15)),
    endDate: moment(new Date(2024, 10, 22)),
    expectedCompletionDate: '22nd Nov',
    recurringDays: 'Fri, Sat',
    recurringTrigger: 'End of fiscal year',
    lockedDependencyOn: 'Inspect vendor agreements',
    timeRemaining: '15 hours',
    heading: { label: 'yetToStart', color: HeaderColors.Gray.Primary },
  },
  {
    eid: 9,
    status: 'overdue',
    title: 'Plan and execute new marketing campaign.',
    location: 'California',
    steps: 5,
    completedSteps: 4,
    taskType: 'Marketing',
    subTasks: 5,
    startDate: moment(new Date(2024, 9, 20)),
    endDate: moment(new Date(2024, 10, 12)),
    expectedCompletionDate: '12th Nov',
    recurringDays: 'Mon, Thu',
    recurringTrigger: 'First day of every year',
    lockedDependencyOn: 'Finalize budget approval',
    timeRemaining: '9 hours',
    heading: { label: 'overdue', color: HeaderColors.Orange },
  },
  {
    eid: 10,
    status: 'inProgress',
    title: 'Review and improve onboarding process.',
    location: 'Texas',
    steps: 5,
    completedSteps: 3,
    taskType: 'HR',
    subTasks: 4,
    startDate: moment(new Date(2024, 10, 8)),
    endDate: moment(new Date(2024, 10, 16)),
    expectedCompletionDate: '16th Nov',
    recurringDays: 'Tue, Fri',
    recurringTrigger: 'Every Friday afternoon',
    lockedDependencyOn: 'Review candidate feedback',
    timeRemaining: '10 hours',
    heading: { label: 'inProgress', color: HeaderColors.DarkBlue },
  },
];
