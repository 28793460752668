import { gql } from '@apollo/client';
import { PageInfo } from 'types';
import { ChapterItem } from './chapter.types';

export interface ChapterListResponse {
  SopPagination: {
    count: number;
    items: Array<ChapterItem>;
    pageInfo: PageInfo;
  };
}

export type ChapterSort =
  | '_ID_ASC'
  | '_ID_DESC'
  | 'CREATEDAT_ASC'
  | 'CREATEDAT_DESC'
  | 'CREATEDBY_ASC'
  | 'CREATEDBY_DESC'
  | 'LASTUPDATED_ASC'
  | 'LASTUPDATED_DESC'
  | 'STATUS_ASC'
  | 'STATUS_DESC'
  | 'TITLE_ASC'
  | 'TITLE_DESC'
  | 'VIEWCOUNT_ASC'
  | 'VIEWCOUNT_DESC'
  | 'VIEWCOUNT__LASTUPDATED_DESC'
  | 'VIEWCOUNT__LASTUPDATED_ASC';

export interface ChapterListVariable {
  page: number;
  perPage: number;
  sort: ChapterSort;
}

export const GET_CHAPTERS = gql`
  query SopPagination(
    $page: Int
    $perPage: Int
    $sort: SortFindManySopInput
    $filter: FilterFindManySopInput
  ) {
    SopPagination(
      page: $page
      perPage: $perPage
      sort: $sort
      filter: $filter
    ) {
      count
      items {
        title
        sopType
        files {
          name
          url
          type
          mimetype
          fileSize
        }
        createdBy
        createdByUser {
          eid
          name
          profilePic
          role
        }
        icon
        entityId
        media {
          playbackId
          assetId
          type
          rawUrl
          mediaUrl
          thumbnail
          status
          meta
          _id
        }
        status
        viewCount
        lastViewed
        lastUpdated
        category
        categoryId
        thumbnail
        visibility
        visibleTo {
          locations
          roles
          users
          condition
        }
        visibleToDetails {
          condition
          locations {
            eid
            name
          }
          roles
          users {
            eid
            name
          }
        }
        createdAt
        smartPageEnabled
        eid
        trainings {
          title
        }
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const DELETE_SOP = gql`
  mutation DeleteSop($eid: String!) {
    deleteSop(eid: $eid) {
      succeed
    }
  }
`;

export const GET_CHAPTER_BY_ID = gql`
  query SopById($eid: String!) {
    SopById(eid: $eid) {
      title
      icon
      content
      createdBy
      entityId
      category
      categoryId
      notifyUsers
      thumbnail
      smartPageEnabled
      eid
      status
      visibleTo {
        users
        locations
        roles
        condition
        _id
      }
      visibility
    }
  }
`;

export const BULK_DELETE_SOP = gql`
  mutation DeleteSopBulk($eids: [String!]) {
    deleteSopBulk(eids: $eids) {
      succeed
    }
  }
`;

export const BULK_MOVE_SOP = gql`
  mutation MoveItems($input: moveItemsTypeInput) {
    moveItems(input: $input) {
      succeed
    }
  }
`;

export const GET_FOLDERS_LIST = gql`
  query EntityCategories {
    EntityCategories {
      children {
        eid
        name
        status
        icon
        color
        createdBy
        createdAt
        updatedAt
        createdByUser {
          eid
          name
          profilePic
        }
        visibility
        visibleTo {
          condition
          locations
          roles
          users
        }
      }
      eid
      name
      status
      icon
      color
      visibility
      createdAt
      updatedAt
      visibleTo {
        condition
        locations
        roles
        users
      }
    }
  }
`;
