import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Box, Grid } from '@chakra-ui/react';

import { ListBaseProps } from '../chapert-view.types';
import ChapterGridItem from './ChapterGridItem';
import ListPagination from '../../../atoms/ListPagination';
import Loader from '../../../ui-components/Loader';
import EmptyChapter from '../ChapterListView/EmptyChapter';
import { SelectOption } from 'atoms';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import { ChapterModal } from '../modal';
import { cloneDeep } from '@apollo/client/utilities';
import { getSortedData } from '../ChapterContainer/helper';

interface IProps extends ListBaseProps {
  typeFilter: SelectOption<string> | null;
  checkboxSelectHandler: (
    type: 'single' | 'all',
    selectedValue: boolean,
    selectedId?: string,
    selectedType?: string
  ) => void;
  filterBy: SelectOption<string> | null;
  setHeight: React.Dispatch<React.SetStateAction<number>>;
  selectedItems: {
    type: string;
    eid: string;
  }[];
}

const ChapterGridView: FC<IProps> = ({
  dataList,
  currentPage,
  perPage,
  itemCount = 0,
  onPageChange,
  loading,
  filterBy,
  typeFilter,
  selectedItems,
  checkboxSelectHandler,
  onClickedHandler,
  getMenuData,
  onAddChapterClick,
  setHeight,
}) => {
  const listRef = useRef<HTMLDivElement | null>(null);
  const userObject = useReactiveVar(userObj);

  const _dataList = useMemo(() => {
    return getSortedData(dataList, filterBy);
  }, [filterBy, dataList]);

  useEffect(() => {
    if (listRef.current && _dataList?.length > 0) {
      setHeight(listRef.current.scrollHeight);
    }
  }, [_dataList]);

  if (loading) {
    return (
      <div className='sop-list-loader'>
        <Loader />
      </div>
    );
  }

  if (!loading && _dataList.length === 0) {
    return (
      <Box pl='8px' className='sop-list-loader'>
        <EmptyChapter onAddChapterClick={onAddChapterClick} />
      </Box>
    );
  }

  return (
    <Box pt='16px' w='full'>
      <Grid
        ref={listRef}
        templateColumns={{
          base: 'repeat(4, 1fr)',
          '2xl': 'repeat(5, 1fr)',
        }}
        gap={5}
      >
        {_dataList.map((value) => {
          return (
            <ChapterGridItem
              key={value.eid}
              data={value}
              userObject={userObject}
              onClick={onClickedHandler}
              getMenuData={getMenuData}
              selectedItems={selectedItems}
              checkboxSelectHandler={checkboxSelectHandler}
            />
          );
        })}
      </Grid>
      {(typeFilter?.value !== 'subFolders' || !typeFilter) &&
        itemCount > 10 && (
          <ListPagination
            onPageChange={onPageChange}
            data={_dataList}
            totalRegisters={itemCount || 0}
            page={currentPage || 1}
            registersPerPage={perPage}
          />
        )}
    </Box>
  );
};

export default ChapterGridView;
