import React, { ReactNode, useCallback } from 'react';
import { AlertDialogCloseButton, Center, Flex } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import { BoxHeader, useConfirm } from '../../../../ui-components';
import { HeaderColors } from '../../../../configs';

interface IProps {
  title?: string;
  description: ReactNode;
  buttonText: string;
  cancelText?: string | null;
  onConfirm?: () => void;
  onCancel?: () => void;
}

type LauncherAlert = (props: IProps) => void;

export const useLauncherAlert = (): LauncherAlert => {
  const confirm = useConfirm();

  return useCallback(
    ({ title, description, buttonText, onConfirm, onCancel, ...rest }) => {
      confirm({
        title: (
          <Flex justify='space-between'>
            <BoxHeader
              color={HeaderColors.Orange}
              title={title || 'Please note'}
              fontSize='18px'
            />
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        isCentered: true,
        content: (
          <Center flexDir='column' gap={5} pt={4}>
            <Center boxSize='56px' borderRadius='full' bg='#FFD88D80'>
              <FontAwesomeIcon
                icon={faTriangleExclamation as IconProp}
                color='#F4A305'
                fontSize='1.5em'
              />
            </Center>

            <Flex textAlign='center' color='#33383F'>
              {description}
            </Flex>
          </Center>
        ),

        contentProps: {
          minWidth: '600px',
          borderRadius: '12px',
          paddingY: '12px',
        },

        ...rest,

        okText: buttonText,
        onOK: onConfirm,
        onCancel: onCancel,

        footerProps: {
          flexDir: 'column-reverse',
          gap: 2,
        },
        okButtonProps: { width: 'full', size: 'lg', marginLeft: 0 },
        cancelButtonProps: { width: 'full', size: 'lg', variant: 'outline' },
      });
    },
    []
  );
};
