import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { COMPLETE_LAUNCHER_TASK } from './state-drawer.graphql';
import { LocationLaunchTask } from 'types/location-launcher/details/location-tasks';
import {
  CompleteLauncherTaskResponse,
  CompleteLauncherTaskVariables,
} from './state-drawer.types';

interface IProps {
  stepId: string;
  taskId: string;
  launchId: string;
  formResponseId?: string;
}

interface MarkCompleteCallbacks {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useMarkAsComplete = (
  onSuccess?: (data?: LocationLaunchTask) => void,
  onError?: () => void
) => {
  const [markAsComplete, { loading: markAsCompleteLoading }] = useMutation<
    CompleteLauncherTaskResponse,
    CompleteLauncherTaskVariables
  >(COMPLETE_LAUNCHER_TASK);

  const handleMarkComplete = async ({
    launchId,
    stepId,
    taskId,
    formResponseId,
  }: IProps) => {
    return await markAsComplete({
      variables: {
        input: {
          launchId: launchId,
          taskId: taskId,
          ...(stepId ? { stepId: stepId } : {}),
          ...(formResponseId ? { formResponseId: formResponseId } : {}),
        },
      },
    });
  };

  return { markAsComplete: handleMarkComplete, markAsCompleteLoading };
};
