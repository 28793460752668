import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { AlertBox } from '../../../../../atoms';

interface IProps {
  type: 'phase' | 'task';
}

const TriggerAlert: FC<IProps> = ({ type }) => {
  const { t } = useTranslation('launcher');

  return (
    <AlertBox
      icon={faCircleExclamation}
      iconProps={{
        color: '#FFA43D',
      }}
      childProps={{
        color: '#FF961F',
      }}
      border='1px solid #FFAE52'
      bg='#FCF1E5'
      fontSize='12px'
      marginBottom='4px'
    >
      {t('trigger.info', { context: type })}
    </AlertBox>
  );
};

export const TriggerOr: FC<IProps> = () => {
  return (
    <Box fontSize='13px' fontWeight='600' textAlign='center'>
      Or
    </Box>
  );
};

TriggerAlert.displayName = 'sc/L/lc/c/t/TriggerAlert';
TriggerOr.displayName = 'sc/L/lc/c/t/TriggerOr';

export default TriggerAlert;
