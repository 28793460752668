import { gql } from '@apollo/client';

export const AUDIT_OVERVIEW = gql`
  query AuditOverviewData($eid: String!) {
    AuditSessionById(eid: $eid) {
      repeatDetails
      audit {
        auditors {
          locations
          userId
        }
        status
      }
      assignments {
        auditors
        locationId
        completedBy
        startedBy
        maxScore
        totalScore
        completedPercent
        isCompleted
        comments
        response
        completedAt
        reportsUrl
      }
      auditId
      auditType
      auditors {
        eid
        name
      }
      completedAt
      createdAt
      description
      dueDate
      eid
      entityId
      isLastSession
      isCompleted
      name
      questions
      repeatCycle
      startDate
      title
      updatedAt
    }
  }
`;
