import { Box, Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useMemo } from 'react';
import {
  GroupedTasksData,
  TaskFilterType,
} from 'sub-components/Launcher/tasks/types';

interface TaskData {
  name: string;
  color: string;
  count: number;
  selected?: boolean;
}

interface TaskNavbarProps {
  tasks: GroupedTasksData[];
  taskFilters: TaskFilterType[];
  setTaskFilters: React.Dispatch<React.SetStateAction<TaskFilterType[]>>;
}

const TaskNavbar: FC<TaskNavbarProps> = ({
  tasks,
  taskFilters,
  setTaskFilters,
}) => {
  const updateSelection = (value: TaskFilterType['value']) => {
    let _taskFilters = [...taskFilters];
    _taskFilters?.map((filter) => {
      if (filter.value === value) {
        filter.selected = !filter.selected;
        filter.showSelection = true;
      } else {
        filter.selected = false;
        filter.showSelection = true;
      }
    });
    setTaskFilters(_taskFilters);
  };

  const tasksCount = (status: TaskFilterType['value']) => {
    if (!tasks) return 0;
    const allTasks = tasks.flatMap(
      (task) => task.groupedPhases?.flatMap((group) => group?.tasks || []) || []
    );

    if (status === 'totalTasks') return allTasks?.length || 0;
    if (status === 'thisWeek')
      return allTasks?.filter(
        (task) =>
          task?.status === 'dueToday' ||
          task?.status === 'duesoon' ||
          task?.status === 'inProgress'
      )?.length;
    return allTasks.filter((task) => task?.status === status).length;
  };

  return (
    <Flex gap={'20px'} align='center'>
      {taskFilters?.map((taskFilter, index) => (
        <Flex
          key={index}
          cursor='pointer'
          p={6}
          borderRadius='8px'
          border={taskFilter.selected ? '1px solid #2b85ff' : 'none'}
          bg='white'
          flexDir='column'
          w='266px'
          gap='10px'
          mb='10px'
          onClick={() => updateSelection(taskFilter?.value)}
        >
          <Flex align='center' justify='space-between'>
            <Flex gap='10px' align='center'>
              <Box
                h='20px'
                w='12px'
                bg={taskFilter.bannerColor}
                borderRadius='2px'
              />
              <Text>{taskFilter.label}</Text>
            </Flex>
            {taskFilter.selected && (
              <FontAwesomeIcon
                icon={faCircleCheck as IconProp}
                color='#2b85ff'
              />
            )}
          </Flex>
          <Text fontWeight={700} fontSize='20px'>
            {tasksCount(taskFilter?.value)} Tasks
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

TaskNavbar.displayName =
  'displayName:sub-components/Launcher/tasks/components/my-tasks/TaskNavbar/TaskNavbar';

export default TaskNavbar;
