import { TriggerEntity, TriggerUnit } from '../launcher-config.fragment';
import { toNumber } from '../../../../../utils';

/**
 * DT -> Deadline TRIGGER
 * PSD -> Project Start Date
 * GLD -> Go Live Date
 *
 * 1. DT -> 3 day after PSD
 * 2. DT -> 3 day after GLD
 */

// type IDuration = readonly [number | undefined, TriggerUnit | undefined];

export type IDuration = {
  durationValue?: number;
  durationUnit?: TriggerUnit;
};

export const getDuration = (trigger?: TriggerEntity | null): IDuration => {
  // if (!trigger || trigger?.triggerChoice === 'default') {
  //   return [undefined, undefined] as const;
  // }
  //
  // switch (trigger?.triggerChoice) {
  //   case 'activation':
  //     return [
  //       trigger?.activationTrigger?.deadlineValue,
  //       trigger?.activationTrigger?.deadlineUnit,
  //     ] as const;
  //   case 'deadline':
  //     return [
  //       trigger?.deadlineTrigger?.value,
  //       trigger?.deadlineTrigger?.unit,
  //     ] as const;
  // }

  if (!trigger || trigger?.triggerChoice === 'default') {
    return {
      durationValue: undefined,
      durationUnit: undefined,
    };
  }

  switch (trigger?.triggerChoice) {
    case 'activation':
      if (trigger?.activationTrigger?.order === 'before') {
        return {
          durationValue: toNumber(trigger?.activationTrigger?.value),
          durationUnit: trigger?.activationTrigger?.unit,
        };
      }
      return {
        durationValue: toNumber(trigger?.activationTrigger?.deadlineValue),
        durationUnit: trigger?.activationTrigger?.deadlineUnit,
      };
    case 'deadline':
      if (trigger?.deadlineTrigger?.order === 'before') {
        return {
          durationValue: undefined,
          durationUnit: undefined,
        };
      }
      return {
        durationValue: toNumber(trigger?.deadlineTrigger?.value),
        durationUnit: trigger?.deadlineTrigger?.unit,
      };
  }
};
