import { LauncherAssignee } from 'pages/launcher/config-editor/common';
import { ExtendedLauncherContent } from '../../store/project-detail-phase.types';
import { LauncherTask } from 'sub-components/Launcher/launcher-config/common/launcher-config.fragment';
import { cloneDeep } from '@apollo/client/utilities';
import { useProjectDetailPhaseContext } from '../../store/context';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import { useAddTaskToLocationOwner } from '../modals/addTaskToLocationOwner';

export const useChangeWarning = () => {
  const entityData = useReactiveVar(usersEntityObj);
  const { mode, phaseData, updatePhaseData } = useProjectDetailPhaseContext(
    (state) => ({
      mode: state?.view,
      phaseData: state?.launcherData,
      updatePhaseData: state?.updateLauncherData,
    })
  );
  const addTaskToLocationOwner = useAddTaskToLocationOwner();

  const normalAssignment = (
    eid: string,
    task: LauncherTask,
    value: LauncherAssignee
  ) => {
    let _phaseData = cloneDeep(phaseData);
    _phaseData?.contents?.forEach((content) => {
      if (content?.eid === eid) {
        content?.tasks?.forEach((cTask) => {
          if (cTask?.eid === task?.eid) {
            cTask.assignedType = value?.assignedType;
            cTask.assignedUser = value?.assignedUser || [];
          }
        });
      }
    });
    updatePhaseData(_phaseData);
  };

  const changeWarningHandler = (
    phase: ExtendedLauncherContent,
    eid: string,
    value: LauncherAssignee,
    task: LauncherTask
  ) => {
    if (phase?.assignedType && task?.assignedType) {
      // Phase has assignee and task has assignee, so normal addition to task assignee
      normalAssignment(eid, task, value);
    } else if (phase?.assignedType && !task?.assignedType) {
      // Phase has assignee but task does not have assignee, so phase assignee be removed and add to task assignee
      if (value?.assignedType === 'location') {
        // Selected task is being assigned to LOCATION OWNERS
        let previousAssigneeNames =
          entityData
            ?.filter((entity) =>
              phase?.assignedUser?.some((assignee) => assignee === entity?.eid)
            )
            ?.map((user) => user?.name) || [];
        addTaskToLocationOwner({
          assigneeNames: ['Location owner'],
          previousAssigneeNames:
            phase?.assignedType === 'location'
              ? ['Location owner']
              : previousAssigneeNames,
          onOkPress: () => {
            let _phaseData = cloneDeep(phaseData);
            _phaseData?.contents?.forEach((content) => {
              if (content?.eid === eid) {
                content.assignedType = undefined;
                content.assignedUser = [];
                content?.tasks?.forEach((cTask) => {
                  if (cTask?.eid === task?.eid) {
                    cTask.assignedType = 'location';
                    cTask.assignedUser = [];
                  }
                });
              }
            });
            updatePhaseData(_phaseData);
          },
        });
      } else if (value?.assignedType === 'user') {
        // Selected task is being assigned to MEMBER(s)
        let previousAssigneeNames =
          entityData
            ?.filter((entity) =>
              phase?.assignedUser?.some((assignee) => assignee === entity?.eid)
            )
            ?.map((user) => user?.name) || [];
        let assigneeNames =
          entityData
            ?.filter((entity) =>
              value?.assignedUser?.some((assignee) => assignee === entity?.eid)
            )
            ?.map((user) => user?.name) || [];
        addTaskToLocationOwner({
          assigneeNames: assigneeNames,
          previousAssigneeNames:
            phase?.assignedType === 'location'
              ? ['Location owner']
              : previousAssigneeNames,
          onOkPress: () => {
            let _phaseData = cloneDeep(phaseData);
            _phaseData?.contents?.forEach((content) => {
              if (content?.eid === eid) {
                content.assignedType = undefined;
                content.assignedUser = [];
                content?.tasks?.forEach((cTask) => {
                  if (cTask?.eid === task?.eid) {
                    cTask.assignedType = value?.assignedType;
                    cTask.assignedUser = value?.assignedUser || [];
                  } else {
                    cTask.assignedType = phase?.assignedType;
                    cTask.assignedUser = phase?.assignedUser || [];
                  }
                });
              }
            });
            updatePhaseData(_phaseData);
          },
        });
      } else {
        let _phaseData = cloneDeep(phaseData);
        _phaseData?.contents?.forEach((content) => {
          if (content?.eid === eid) {
            content?.tasks?.forEach((cTask) => {
              if (cTask?.eid === task?.eid) {
                cTask.assignedType = undefined;
                cTask.assignedUser = [];
              }
            });
          }
        });
        updatePhaseData(_phaseData);
      }
    } else if (!phase?.assignedType && task?.assignedType) {
      normalAssignment(eid, task, value);
    } else if (!phase?.assignedType && !task?.assignedType) {
      normalAssignment(eid, task, value);
    }
  };

  return changeWarningHandler;
};
