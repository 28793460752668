import React, { FC } from 'react';
import { Button } from '../../../../../atoms';
import { useComplianceUpload } from '../../Create/components';

interface IProps {
  complianceId: string;
  disabled?: string;
  preDefinedLocationId?: string;
}

const ComplianceUpdate: FC<IProps> = ({
  complianceId,
  disabled = false,
  preDefinedLocationId = '',
}) => {
  const complianceUpload = useComplianceUpload();

  return (
    <Button
      disabled={disabled}
      size='xs'
      fontSize='12px'
      colorScheme='blue'
      paddingInline={3}
      height='28px'
      onClick={() => {
        complianceUpload({
          complianceId: complianceId,
          preDefinedLocationId: preDefinedLocationId,
        });
      }}
    >
      Update
    </Button>
  );
};

ComplianceUpdate.displayName =
  'displayName:sub-components/nexus/Compliance/Listing/components/ComplianceUpdate';

export default ComplianceUpdate;
