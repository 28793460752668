import React, { FC } from 'react';
import { Button } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LAUNCHER_ADD_LOCATION } from '../../../../appRoutes';
import { FlexCard } from '../../../../atoms';
import EmptyState from '../../../EmptyState';

interface IProps {
  haveConfig?: boolean;
}

const EmptyLauncherTasks: FC<IProps> = ({ haveConfig }) => {
  const history = useHistory();
  const { t } = useTranslation('launcher');

  if (!haveConfig) {
    return (
      <FlexCard alignItems='center' justifyContent='center' p='90px'>
        <EmptyState
          image='SingleLocation'
          title={t('noLocationProgress')}
          description={t('noLocationProgressDesc')}
        />
      </FlexCard>
    );
  }

  return (
    <FlexCard alignItems='center' justifyContent='center' p='90px'>
      <EmptyState
        image='SingleLocation'
        title={t('noLocationProgress')}
        description={t('noLocationProgressDesc')}
        showButton
        buttonProps={
          <Button
            mt={4}
            variant='solid'
            colorScheme='blue'
            size='md'
            isFullWidth
            onClick={() => history.push(LAUNCHER_ADD_LOCATION)}
          >
            {t('addLocations')}
          </Button>
        }
      />
    </FlexCard>
  );
};

export default EmptyLauncherTasks;
