import { Box, Center, Flex, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDown,
  faArrowUp,
  faTriangle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { HeaderColors } from 'shared/headerColors';
import { AuditStatusFunctionEntity } from 'sub-components/audits/audit-tab/supervised/types';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { CalculatedData } from '../types/performance-analytics.types';
import NoData from './NoData';

interface IProps {
  _auditStatus: AuditStatusFunctionEntity;
  calculatedData: CalculatedData | undefined;
}

const AnalyticsGraph: FC<IProps> = ({ _auditStatus, calculatedData }) => {
  console.log('CALCULATED PERFORMANCE DATA : ', calculatedData);
  if (!calculatedData?.hasOverallAvg) {
    return <NoData />;
  }

  return (
    <Flex flexDir='column' gap={6} h='316px'>
      <GenericColorHeader
        title='Performance Analytics'
        color={HeaderColors.Green}
      />
      <Flex flexDir='column'>
        <Box
          as='span'
          color='rgba(111, 118, 126, 1)'
          fontSize='15px'
          fontWeight={500}
        >
          Average score
        </Box>
        <Flex gap={2}>
          <Box fontWeight={600} fontSize='48px'>
            {calculatedData?.overallAverageScore}%
          </Box>
          <Flex>
            {calculatedData?.percentageChange?.changeType === 'increase' && (
              <Flex flexDir='column'>
                <Flex align='center' gap='2px'>
                  <Box color='#83BF6E'>
                    {calculatedData?.percentageChange?.value}%
                  </Box>
                  <FontAwesomeIcon
                    icon={faArrowUp as IconProp}
                    color='#83BF6E'
                  />
                </Flex>
                <Box color='#6D6D6D'>from last month</Box>
              </Flex>
            )}
            {calculatedData?.percentageChange?.changeType === 'decrease' && (
              <Flex flexDir='column'>
                <Flex align='center' gap='2px'>
                  <Box color='#FF6A55'>
                    {calculatedData?.percentageChange?.value}%
                  </Box>
                  <FontAwesomeIcon
                    icon={faArrowDown as IconProp}
                    color='#FF6A55'
                  />
                </Flex>
                <Box color='#6D6D6D'>from last month</Box>
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex flexDir='column' gap={6}>
          <Flex gap={4} align='center'>
            <Flex flexDir='column' w='40%'>
              <Tooltip label={calculatedData?.bestLocation?.name} hasArrow>
                <Box
                  fontWeight={600}
                  fontSize='13px'
                  isTruncated
                  maxW='200px'
                  cursor='pointer'
                >
                  {calculatedData?.bestLocation?.name}
                </Box>
              </Tooltip>
              <Box
                color='rgba(109, 109, 109, 1)'
                fontSize='12px'
                fontWeight={500}
              >
                (Highest)
              </Box>
            </Flex>
            <Flex gap={2}>
              <Box
                boxSize='30px'
                bg='rgba(142, 89, 255, 0.25)'
                borderRadius='3px'
              />
              <Box
                boxSize='30px'
                bg='rgba(142, 89, 255, 0.5)'
                borderRadius='3px'
              />
              <Box
                boxSize='30px'
                bg='rgba(142, 89, 255, 0.75)'
                borderRadius='3px'
              />
              <Box
                boxSize='30px'
                bg='rgba(142, 89, 255, 0.85)'
                borderRadius='3px'
              />
              <Center
                boxSize='30px'
                bg='rgba(142, 89, 255, 1)'
                borderRadius='3px'
              >
                <Box color='white' fontSize='12px' fontWeight={600}>
                  {calculatedData?.bestLocation?.currentScore}
                </Box>
              </Center>
              {(calculatedData?.bestLocation?.performanceImprovement || 0) >
                0 && (
                <Flex align='center' gap='2px'>
                  <FontAwesomeIcon
                    icon={faTriangle as IconProp}
                    color='#83BF6E'
                  />
                  <Box as='span' color='#83BF6E'>
                    {calculatedData?.bestLocation?.performanceImprovement}
                  </Box>
                </Flex>
              )}
            </Flex>
          </Flex>
          {calculatedData?.worstLocation && (
            <Flex gap={4} align='center'>
              <Flex flexDir='column' w='40%'>
                <Tooltip label={calculatedData?.worstLocation?.name} hasArrow>
                  <Box
                    fontWeight={600}
                    fontSize='13px'
                    isTruncated
                    maxW='200px'
                    cursor='pointer'
                  >
                    {calculatedData?.worstLocation?.name}
                  </Box>
                </Tooltip>
                <Box
                  color='rgba(109, 109, 109, 1)'
                  fontSize='12px'
                  fontWeight={500}
                >
                  (Lowest)
                </Box>
              </Flex>
              <Flex gap={2}>
                <Box
                  boxSize='30px'
                  bg='rgba(142, 89, 255, 0.25)'
                  borderRadius='3px'
                />
                <Box
                  boxSize='30px'
                  bg='rgba(142, 89, 255, 0.5)'
                  borderRadius='3px'
                />
                <Center
                  boxSize='30px'
                  bg='rgba(142, 89, 255, 0.75)'
                  borderRadius='3px'
                >
                  <Box color='white' fontSize='12px' fontWeight={600}>
                    {calculatedData?.worstLocation?.currentScore}
                  </Box>
                </Center>
                {(calculatedData?.worstLocation?.performanceDecline || 0) >
                  0 && (
                  <Flex align='center' gap='2px'>
                    <FontAwesomeIcon
                      icon={faTriangle as IconProp}
                      style={{ transform: 'rotateX(180deg)' }}
                      color='#FF6A55'
                    />
                    <Box as='span' color='#FF6A55'>
                      {calculatedData?.worstLocation?.performanceDecline}
                    </Box>
                  </Flex>
                )}
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

AnalyticsGraph.displayName =
  'pages/Audits/Detail/components/PerformanceAnalytics/components/AnalyticsGraph';

export default AnalyticsGraph;
