import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { SelectOption } from 'sub-components/CustomDropdowns';
import { FilterItem } from '../Summary/types';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'sop-commons/src/client';
import { filterFilterCopiesByAuthRole } from '../helper';

interface IProps {
  setSelectedModules?: React.Dispatch<React.SetStateAction<FilterItem[]>>;
  isCompactView?: boolean;
  resetHandler?: () => void;
  checkedModules: FilterItem[];
  handleRemoveFilter?: any;
  handleModuleSelect?: any;
  selectedModules?: any[];
}

const FilterByModule: FC<IProps> = ({
  isCompactView = true,
  checkedModules,
  handleModuleSelect,
  selectedModules,
}) => {
  const { t } = useTranslation(['notifications']);
  const { isOpen, onToggle } = useDisclosure();
  const { loggedinUserAuthRole } = useUserDataSelector((state) => ({
    loggedinUserAuthRole: state.authRole,
  }));
  return (
    <Popover isLazy placement='bottom-start' arrowSize={20} offset={[100, 10]}>
      {isCompactView && (
        <PopoverTrigger>
          <Flex position={'relative'}>
            {selectedModules && selectedModules?.length > 0 && (
              <Flex
                zIndex={1}
                top={'-5px'}
                right={'-5px'}
                alignItems={'center'}
                justifyContent={'center'}
                height={'16px'}
                width={'16px'}
                position={'absolute'}
                borderRadius={'50%'}
                color={'white'}
                backgroundColor={'#2A85FF'}
              >
                <Text fontSize={'10px'}>{selectedModules?.length}</Text>
              </Flex>
            )}
            <Button
              p={'8px 16px'}
              borderRadius={'12px'}
              variant={'solid'}
              bgColor={'#FFFFFF'}
              border={'2px solid #EFEFEF'}
              color={'#6F767E'}
              fontWeight={600}
              rightIcon={<FontAwesomeIcon icon={faChevronDown as IconProp} />}
            >
              {t('notifications:summary.filters.placeholder')}
            </Button>
          </Flex>
        </PopoverTrigger>
      )}
      <PopoverContent
        minW={'300px'}
        borderRadius={'16px'}
        padding={'16px'}
        boxShadow={2}
      >
        <PopoverArrow borderRadius={'md'} />
        <PopoverBody>
          <Flex flexDir={'column'} columnGap={2}>
            {filterFilterCopiesByAuthRole(
              loggedinUserAuthRole,
              checkedModules
            ).map((item) => {
              return (
                <Flex
                  key={item?.eid}
                  _hover={{ bgColor: '#F4F4F4', color: '#1A1D1F' }}
                  cursor={'pointer'}
                  borderRadius={'12px'}
                  fontSize={'16px'}
                  p={'12px'}
                  color={'#6F767E'}
                  fontWeight={600}
                  justify={'space-between'}
                >
                  {item.label}
                  <Checkbox
                    isChecked={item?.checked}
                    size={'lg'}
                    borderRadius={'lg'}
                    value={item.value}
                    onChange={(e) => handleModuleSelect(e, item)}
                  />
                </Flex>
              );
            })}
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
FilterByModule.displayName = 'sc/NC/C/FilterByModule.tsx';
export default React.memo(FilterByModule);
