// @ts-ignore
import React, { useState, useRef, FC } from 'react';
import { useForm } from 'react-hook-form';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useMutation, gql, useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  // authUserVar,
  authTokenVar,
  userObj,
} from 'sop-commons/src/client/clientFactory';
// import scss
import AuthContainer from '../../sub-components/AuthContainer';
import './ResetPassword.scss';

import PrimaryButton from '../../atoms/PrimaryButton';
import PasswordInput from '../../atoms/PasswordInput';
import { useRouter } from '../../utils';

import { GET_USER } from '../Login/login.graphql';
import { roleObj } from '../../ui-components/DashboardMenu';

const VERIFY_RESET_PASSWORD_AUTH = gql`
  mutation VerifyResetPasswordAuth($token: String!, $newPassword: String!) {
    verifyResetPasswordAuth(token: $token, newPassword: $newPassword) {
      accessToken
    }
  }
`;

const ResetPassword: FC = () => {
  const { t } = useTranslation(['common', 'auth']);
  const history = useHistory();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [updateAccessToken, setUpdateAccessToken] = useState(false);
  const [error, setError] = useState<any>();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isValid, dirtyFields, isDirty },
    getValues,
    watch,
  } = useForm();

  const password = useRef({});
  password.current = watch('newPassword', '');

  const [
    getUser,
    { loading: getUserLoading, error: getUserError, data: getUserData },
  ] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {
      setIsLoading(false);
      userObj(data?.user || {});
      const roles = data?.user?.entity?.roles;
      if (roles && roles.length) {
        let roleWithColor: any = {};
        roles.forEach((role: any) => {
          roleWithColor[role.name] = role.color;
        });

        roleObj(roleWithColor);
      }

      if (data?.user?.status === 'inactive') {
        setUpdateAccessToken(false);
        localStorage.setItem('authToken', '');
        setTimeout(() => {
          history.push('/');
        }, 300);
      } else {
        if (updateAccessToken) {
          authTokenVar(localStorage.getItem('authToken'));
        }
        setTimeout(() => {
          history.push('/');
        }, 300);
      }
    },
    fetchPolicy: 'network-only',
  });

  const [verifyResetPasswordAuth] = useMutation(VERIFY_RESET_PASSWORD_AUTH, {
    onCompleted: (data) => {
      setIsLoading(false);
      localStorage.setItem(
        'authToken',
        data?.verifyResetPasswordAuth?.accessToken
      );

      setUpdateAccessToken(true);
      getUser({
        variables: {},
        context: {
          headers: {
            Authorization: `Bearer ${data?.verifyResetPasswordAuth?.accessToken}`,
          },
        },
      });
    },
    onError: (error) => {
      setIsLoading(false);
      setError(error);
    },
  });

  const getErrorMessage = () => {
    if (error?.message) {
      return error?.message;
    }
    return '';
  };

  const onFinish = (values: any) => {
    const { query }: any = router;
    setError(null);
    if (values.newPassword === values.confirmPassword) {
      if (query?.auth) {
        setIsLoading(true);
        verifyResetPasswordAuth({
          variables: {
            newPassword: values.newPassword,
            token: query?.auth,
          },
        });
      }
    }
  };

  return (
    <AuthContainer>
      <div>
        <div className='login-heading-container'>
          {t('auth:reset_password')}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            className='login-sub-heading-container'
            style={{ width: '293px', marginBottom: '15px' }}
          >
            {t('auth:enter_new_pass')}
          </div>
        </div>

        <div className='login-form-parent-container'>
          <form
            className='login-form-container'
            onSubmit={handleSubmit(onFinish)}
          >
            <FormControl isInvalid={!!errors?.newPassword}>
              <PasswordInput
                id='new-password'
                leftIcon={true}
                rightIcon={true}
                formProps={{
                  ...register('newPassword', {
                    required: t('validation.password_required'),
                    minLength: {
                      value: 6,
                      message: t('validation.password_min_six'),
                    },
                  }),
                }}
                placeholder={t('placeholder.create_password')}
                error={errors?.newPassword}
              />
              <FormErrorMessage>
                {errors?.newPassword?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors?.confirmPassword} mt={3}>
              <PasswordInput
                id='confirm-new-password'
                leftIcon={true}
                rightIcon={true}
                formProps={{
                  ...register('confirmPassword', {
                    required: t('validation.conf_pass_required'),
                    minLength: {
                      value: 6,
                      message: t('validation.password_min_six'),
                    },
                    validate: (value) =>
                      value === password.current ||
                      t('validation.password_not_match'),
                  }),
                }}
                placeholder={t('placeholder.confirm_password')}
                error={errors?.confirmPassword}
              />

              <FormErrorMessage>
                {errors?.confirmPassword?.message}
              </FormErrorMessage>
            </FormControl>
            <PrimaryButton
              mt={4}
              type='submit'
              size='lg'
              isLoading={isLoading}
              disabled={
                !getValues().newPassword || !getValues().confirmPassword
              }
              title={t('auth:save_sign_in')}
              variant='solid'
              colorScheme='blue'
            />
            <div className='login-error-message'>{getErrorMessage()}</div>
          </form>
        </div>
      </div>
      <div className='invite-already-member' />
    </AuthContainer>
  );
};

ResetPassword.displayName = 'displayName:pages/ResetPassword/index';
export default ResetPassword;
