import { GroupedLocation, GroupedPhase } from '../types';

export function groupTasksByPhase(
  tasks: GroupedLocation['data'][number][]
): GroupedPhase[] {
  const groupedPhases = tasks.reduce((acc, task) => {
    const existingPhase = acc.find((p) => p.phaseEid === task.phaseEid);

    if (existingPhase) {
      existingPhase.tasks.push(task);
    } else {
      acc.push({
        phaseEid: task.phaseEid,
        phaseName: task.phase,
        tasks: [task],
      });
    }

    return acc;
  }, [] as GroupedPhase[]);

  return groupedPhases;
}
