import {
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Tabs,
  Box,
  Text,
  Stack,
  Button,
  Flex,
  Center,
  Divider,
} from '@chakra-ui/react';
import { useUserDataSelector } from 'hooks';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'sub-components/Loader';
import {
  FilterByModule,
  NotificationCard,
} from 'sub-components/NotificationCenter/Common';
import { useService } from 'sub-components/NotificationCenter/layers/useService';
import AppliedFiltersState from 'sub-components/NotificationCenter/States/AppliedFilters';
import {
  IFilters,
  INotificationItem,
} from 'sub-components/NotificationCenter/types/service-types';
import {
  NotificationFilters,
  NotificationFiltersParams,
} from 'sub-components/NotificationCenter/types/types';
import { FilterItem, SummaryData, TabItem } from '../types';
import MarkReadBtn from './../../Details/components/MarkReadBtn';
import moment from 'moment';
import DefaultEmptyState from '../../Common/DefaultEmptyState';
import InfiniteScroll from 'ui-components/InfiniteScroll';
import { Pagination, PaginationProps } from 'antd';

interface IProps {
  selectedModules: FilterItem[];
  setSelectedModules: React.Dispatch<React.SetStateAction<FilterItem[]>>;
  customStyles?: any;
  isCompactView?: boolean;
  resetHandler: () => void;
  checkedModules: any;
  handleRemoveFilter: any;
  handleModuleSelect: any;
  notifications?: INotificationItem[];
  notificationFilters: NotificationFilters;
  notificationHistoryData?: any;
  notificationData: INotificationItem[];
  notificationLoading?: any;
  handleUpdateNotificationReadStatus: (eid: string, status: string) => void;
  setTabName?: any;
  tabName: string;
  markAllNotificationRead?: () => void;
  searchFieldText?: string;
  notificationCount: number;
  decideFilterAndRefetch: (pageIndex?: number) => void;
  markGroupedNotificationsRead?: () => any;
}

const _timeLines: ['today', 'yesterday', 'week', 'month'] = [
  'today',
  'yesterday',
  'week',
  'month',
];
const _tabItems: TabItem[] = ['all', 'unread', 'read', 'assignedToMe'];

const defaultStyles = {
  position: 'sticky',
  top: 0,
  zIndex: 10,
  // backgroundColor: 'white',
  backdropFilter: 'blur(6px)',
  bg: 'rgba(255, 255, 255, 0.8)',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
};

const SummaryTabs: FC<IProps> = ({
  selectedModules,
  setSelectedModules,
  customStyles,
  isCompactView = true,
  resetHandler,
  checkedModules,
  handleModuleSelect,
  handleRemoveFilter,
  notificationFilters,
  notificationData,
  notificationHistoryData,
  notificationLoading,
  handleUpdateNotificationReadStatus,
  setTabName,
  markAllNotificationRead,
  searchFieldText,
  notificationCount,
  decideFilterAndRefetch,
  tabName,
  markGroupedNotificationsRead,
}) => {
  const { t } = useTranslation(['notifications']);

  const [tabIndex, setTabIndex] = React.useState<number>(0);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);

    if (index === 0) {
      setTabName('all');
      notificationHistoryData({
        variables: notificationFilters({}),
      });
    } else if (index === 1) {
      setTabName('unread');
      notificationHistoryData({
        variables: notificationFilters({ isUnreadFilter: true }),
      });
    } else if (index === 2) {
      setTabName('read');
      notificationHistoryData({
        variables: notificationFilters({ isReadFilter: true }),
      });
    } else if (index === 3) {
      setTabName('assignedToMe');
      notificationHistoryData({
        variables: notificationFilters({ assignedToMeFilter: true }),
      });
    }
  };

  const filteredAndGroupedData = () => {
    // Group data by date categories using timelines
    let timelineMappedData: Record<
      'today' | 'yesterday' | 'week' | 'month',
      INotificationItem[]
    >;

    timelineMappedData = notificationData.reduce(
      (tabSpecificData, curr) => {
        // const createdAt = moment(curr.createdAt);
        // const today = moment.utc().startOf('day');

        // Calculate the difference in days
        // const diffInDays = today.diff(createdAt, 'days');
        const diffInDaysTimeIncluded = Math.floor(
          (new Date().getTime() - new Date(curr?.createdAt).getTime()) /
            (1000 * 60 * 60 * 24)
        ); //include diff In Time As well
        if (diffInDaysTimeIncluded === 0) {
          tabSpecificData.today.push(curr);
        } else if (diffInDaysTimeIncluded === 1) {
          tabSpecificData.yesterday.push(curr);
        } else if (diffInDaysTimeIncluded <= 7) {
          tabSpecificData.week.push(curr);
        } else if (diffInDaysTimeIncluded <= 30) {
          tabSpecificData.month.push(curr);
        }
        return tabSpecificData;
      },
      {
        today: [],
        yesterday: [],
        week: [],
        month: [],
      } as Record<'today' | 'yesterday' | 'week' | 'month', INotificationItem[]>
    );
    return timelineMappedData;
  };

  const tabItems = _tabItems.map((key) => {
    return {
      key,
      label: t(`notifications:summary.tabs.${key}`),
      panelData: notificationData,
    };
  });

  const renderGroupedData = (
    groupedData: Record<
      'today' | 'yesterday' | 'week' | 'month',
      INotificationItem[]
    >,
    timelineKey: 'today' | 'yesterday' | 'week' | 'month'
  ) => {
    const timelineLabel = t(`notifications:summary.timelines.${timelineKey}`);
    const timeLineData = groupedData[timelineKey];
    if (!timeLineData || timeLineData.length === 0) return null;
    return (
      <Box mb={4} ml={-4} mr={-4}>
        <Flex my={'10px'} alignItems={'center'}>
          <Text fontWeight={'bold'} mb={2}>
            {timelineLabel}
          </Text>
          {tabName !== 'read' && (
            <MarkReadBtn
              markAllNotificationRead={markAllNotificationRead}
              isCompactView={isCompactView}
              timeLineKey={timelineKey}
              filteredAndGroupedData={filteredAndGroupedData}
              markGroupedNotificationsRead={markGroupedNotificationsRead}
            />
          )}
        </Flex>
        {timeLineData.map((notification) => (
          <Stack gap={4} key={notification.eid}>
            <NotificationCard
              key={notification.eid}
              notificationData={notification}
              handleUpdateNotificationReadStatus={
                handleUpdateNotificationReadStatus
              }
            />
          </Stack>
        ))}
      </Box>
    );
  };

  const handlePaginate: PaginationProps['onChange'] = (page) => {
    decideFilterAndRefetch(page);
    // setCurrentPage(page);
  };

  return (
    <Tabs
      position={'relative'}
      isLazy
      colorScheme={'gray'}
      index={tabIndex}
      onChange={handleTabsChange}
    >
      <TabList style={customStyles ? customStyles : defaultStyles}>
        {tabItems.map((item) => (
          <Tab
            key={item.key}
            fontWeight={600}
            fontSize={'15px'}
            color={'#6F767E'}
            borderBottom={'4px'}
          >
            {item.label}
          </Tab>
        ))}
        {!isCompactView &&
          notificationData.length > 0 &&
          tabName !== 'read' && (
            <MarkReadBtn
              isCompactView={isCompactView}
              markAllNotificationRead={markAllNotificationRead}
            />
          )}
      </TabList>

      {isCompactView && selectedModules && selectedModules.length > 0 && (
        <AppliedFiltersState
          appliedFilters={selectedModules}
          setSelectedModules={setSelectedModules}
          isCompactView
          resetHandler={resetHandler}
          checkedModules={checkedModules}
          handleRemoveFilter={handleRemoveFilter}
          handleModuleSelect={handleModuleSelect}
        />
      )}

      {notificationData?.length === 0 && !notificationLoading && (
        <DefaultEmptyState
          searchFieldText={searchFieldText}
          selectedModules={selectedModules}
        />
      )}

      <TabPanels>
        {tabItems.map((item) => {
          return (
            <TabPanel key={item.key}>
              {!isCompactView && !notificationLoading ? (
                item?.panelData?.map((notification, key) => {
                  return (
                    <Box key={key} ml={-4} mr={-4}>
                      <Stack gap={0} key={key}>
                        <NotificationCard
                          isCompactView={false}
                          key={key}
                          notificationData={notification}
                          handleUpdateNotificationReadStatus={
                            handleUpdateNotificationReadStatus
                          }
                        />
                        <Divider />
                      </Stack>
                    </Box>
                  );
                })
              ) : isCompactView && !notificationLoading ? (
                _timeLines.map((timelineKey) =>
                  renderGroupedData(filteredAndGroupedData(), timelineKey)
                )
              ) : (
                <Flex justifyContent={'center'} height={'70vh'}>
                  <Center>
                    <Loader />
                  </Center>
                </Flex>
              )}
            </TabPanel>
          );
        })}
      </TabPanels>
      {notificationData.length > 0 && !isCompactView && (
        <Pagination
          onChange={handlePaginate}
          total={notificationCount}
          // current={currentPage}
          align={'end'}
          showSizeChanger={false}
        />
      )}
    </Tabs>
  );
};
SummaryTabs.displayName = 'sc/NC/S/c/SummaryTabs.tsx';
export default React.memo(SummaryTabs);
