import { LauncherAssignee, TriggerEntity } from './launcher-config.fragment';
import { updateObject } from '../../../../utils/objectHelper';
import {
  LauncherConfigAction,
  LauncherContentInput,
} from './launcher-config.store';

type Assignee = Partial<LauncherAssignee>;

export const diffAssignee = (current?: Assignee, value?: Assignee): boolean => {
  const prev: LauncherAssignee = {
    assignedType: current?.assignedType!,
    assignedUser: current?.assignedUser || [],
  };
  const next: LauncherAssignee = {
    assignedType: value?.assignedType!,
    assignedUser: value?.assignedUser || [],
  };

  if (prev.assignedType !== next?.assignedType) {
    return true;
  }
  if (next.assignedType === 'user') {
    if (prev.assignedUser.length !== next.assignedUser.length) {
      return true;
    }

    const sortPrev = prev.assignedUser.slice().sort(); // Create a shallow copy and sort
    const sortNext = next.assignedUser.slice().sort(); // Create a shallow copy and sort

    for (let i = 0; i < sortPrev.length; i++) {
      if (sortPrev[i] !== sortNext[i]) {
        return true; // Arrays with different elements are not equal
      }
    }
    return false;
  }

  return false;
};

export const triggerEnabled = (trigger: Partial<TriggerEntity>) => {
  return trigger?.triggerChoice && trigger?.triggerChoice !== 'default';
};

export const findTriggerItem = (trigger: TriggerEntity | null) => {
  if (!trigger) {
    return [undefined, undefined];
  }

  switch (trigger?.triggerChoice) {
    case 'activation':
      return [
        trigger?.activationTrigger?.itemId,
        trigger?.activationTrigger?.dependentOn,
      ];
    case 'deadline':
      return [
        trigger?.deadlineTrigger?.itemId,
        trigger?.deadlineTrigger?.dependentOn,
      ];
    case 'default':
      return [undefined, trigger?.deadlineTrigger?.dependentOn];
  }
};

// On task or phase deletion update the dependent tasks and phases trigger
export function onDeleteUpdateTrigger(
  state: LauncherConfigAction,
  dependId: string
): void {
  for (const content of state.config.contents) {
    if (content?.meta?.triggerItemId === dependId) {
      updateObject(content, ['meta', 'triggerItemId'], undefined);
      content.trigger = {
        triggerChoice: 'default',
      };
    }

    for (const task of content.tasks) {
      if (task?.meta?.triggerItemId === dependId) {
        updateObject(task, ['meta', 'triggerItemId'], undefined);
        task.trigger = {
          triggerChoice: 'default',
        };
      }
    }
  }
}

export const getSectionIndex = (
  contents: LauncherContentInput[] = [],
  sectionIndex?: number,
  sectionEid?: string
): number => {
  if (sectionIndex! >= 0) {
    return sectionIndex!;
  }
  return contents.findIndex((c) => c.eid === sectionEid);
};
