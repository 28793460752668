import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AuthRole, withAuthorization } from '../../../../authorization';
import { AppRoute } from '../../../../routes';
import DashboardContainer from '../../../../sub-components/DashboardContainer';

import LauncherSetupForm from './LauncherSetupForm';

const LauncherSetupContainer: FC = () => {
  const { t } = useTranslation('launcher');
  return (
    <DashboardContainer>
      <Flex flexDir='column' mb={6}>
        <Box fontSize='24px' fontWeight='700' color='#272B30'>
          {t('setUpLocLauncher')}
        </Box>
        <Box fontSize='15px' fontWeight='400' color='#6F767E'>
          {t('setUpLocLauncherDesc')}
        </Box>
      </Flex>

      <LauncherSetupForm />
    </DashboardContainer>
  );
};

export default withAuthorization({
  permittedFor: 'user',
  permittedRoles: [AuthRole.SUPER_ADMIN],
  redirectTo: AppRoute.LAUNCHER_URL,
})(LauncherSetupContainer);
