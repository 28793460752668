import React, { FC } from 'react';
import { Flex, Spacer } from '@chakra-ui/react';

import BoardViewNavigation from './BoardViewNavigation';
import BoardViewGroup from './BoardViewGroup';
import { BoardFilter } from './filters';
import FilterView from './filters/FilterView';

interface IProps {}

const BoardViewAction: FC<IProps> = () => {
  return (
    <Flex mb={4} gap={3}>
      <FilterView />
      <Spacer />
      <BoardViewGroup />
      <BoardFilter />
      <BoardViewNavigation />
    </Flex>
  );
};

export default BoardViewAction;
