import { Flex } from '@chakra-ui/react';
import {
  Heading,
  LocationLeaderboard,
  MyLocationsSection,
  // MyRevenue,
} from './components';
import React, { FC } from 'react';
import CustomerFeedback from './components/CustomerFeedback';
import ImageGallery from './components/ImageGallery';
import { useNexusBoardContext } from '../../store/context';
import { SingleLocationState } from './components/LocationLeaderboard/components';

const LeftSection: FC<{
  exportRef: React.RefObject<HTMLDivElement>;
}> = ({ exportRef }) => {
  const { locationsCount } = useNexusBoardContext((state) => ({
    locationsCount: state?.locationDetailsData?.locationCount,
  }));

  let singleLocationRender = () => (
    <Flex w='full' flexDir='column' gap={4}>
      <MyLocationsSection />
      <SingleLocationState />
    </Flex>
  );

  let normalRender = () => (
    <Flex w='full' flexDir='column' gap={4}>
      <MyLocationsSection />
      <LocationLeaderboard />
      {/* <MyRevenue /> */}
      <CustomerFeedback />
      <ImageGallery />
    </Flex>
  );

  return (
    <Flex w='full' flexDir='column'>
      <Heading exportRef={exportRef} />
      {locationsCount > 1 ? normalRender() : singleLocationRender()}
    </Flex>
  );
};

LeftSection.displayName =
  'sub-components/nexus/NexusBase/components/LeftSection';

export default LeftSection;
