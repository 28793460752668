import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { SelectOption } from '../../../../../atoms';
import { useLauncherConfigContext } from '../../common';
import AddTaskButton from '../sections/AddTaskButton';
import CategoryHeader from './CategoryHeader';
import CategoryTasks, { ICategoryTask } from './CategoryTasks';

interface IProps {
  data: ICategoryTask[];
  category: SelectOption;
}

const BoardCategoryItem: FC<IProps> = ({ category, data = [] }) => {
  const openTaskForm = useLauncherConfigContext((state) => state.openTaskForm);

  return (
    <Flex flexDir='column' gap={3} width='340px' minW='340px'>
      <CategoryHeader category={category} taskCount={data.length} />
      <AddTaskButton
        onAddClick={() =>
          openTaskForm({
            sectionIndex: -1,
            taskIndex: -1,
            lastCategory: category.value,
          })
        }
      />
      <CategoryTasks tasks={data} />
    </Flex>
  );
};

export default React.memo(BoardCategoryItem);
