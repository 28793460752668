import React, { FC } from 'react';
import {
  AlertDialogCloseButton,
  Box,
  Flex,
  useModalContext,
} from '@chakra-ui/react';
import { ActionButton, BoxHeader } from '../../../../../../ui-components';
import { HeaderColors } from '../../../../../../configs';
import { BaseBoardProps } from './delete-board.types';

interface IProps extends BaseBoardProps {}

const ConfirmDeleteBoard: FC<IProps> = ({ deleteProps, actionHandler }) => {
  const { onClose } = useModalContext();

  const _onSaveClick = async () => {
    if (deleteProps?.onDeleteClick) {
      await deleteProps.onDeleteClick?.(true);
      onClose();
    }
  };

  return (
    <Flex flexDir='column' gap={5}>
      <Flex paddingTop={3} justify='space-between'>
        <BoxHeader
          color={HeaderColors.Red.Dark}
          title={`Confirm deleting “${deleteProps?.title}” locations ?`}
          fontSize='18px'
          boxWidth={3}
        />

        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
        />
      </Flex>

      <Box fontSize='16px' fontWeight={500} color='#6F767E'>
        The location(s) and their members will be deleted. This action cannot be
        undone.
      </Box>

      <Flex gap={4}>
        <ActionButton
          flex={1}
          size='lg'
          variant='outline'
          actionFn={() => actionHandler('back', deleteProps)}
        >
          Back
        </ActionButton>

        <ActionButton
          flex={1}
          size='lg'
          colorScheme='red'
          actionFn={_onSaveClick}
        >
          Delete location/s
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default ConfirmDeleteBoard;
