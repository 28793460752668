import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import HeaderSection from './Details/components/HeaderSection';
import { useService } from './layers/useService';
import {
  GET_NOTIFICATION_HISTORY,
  GET_UNREAD_NOTIFICATIONS,
  MarkAllReadInput,
  MARK_ALL_READ,
  MARK_READ_UNREAD_SINGLE_NOTIFICATION,
} from './notiifications.graphql';
import AppliedFiltersState from './States/AppliedFilters';
import { SummaryWrapper } from './Summary/components';
import { FilterItem } from './Summary/types';
import { IFilters, INotificationResponse } from './types/service-types';
import { NotificationFiltersParams } from './types/types';
import { moduleData } from './constants';
import useCombinedStore from 'zustandStore/store';

const NotificationContainer: FC = () => {
  const [searchFieldText, setSearchFieldText] = useState('');
  const [selectedExpiryFilter, setSelectedExpiryFilter] = useState('');
  const [selectedModules, setSelectedModules] = React.useState<FilterItem[]>(
    []
  );
  const [checkedModules, setCheckedModules] =
    useState<FilterItem[]>(moduleData);
  const [notificationData, setNotificationData] = useState<
    INotificationResponse[]
  >([]);
  const [tabName, setTabName] = useState('all');
  const { updateUnseenCount } = useCombinedStore();

  const decideFilterAndRefetch: (pageIndex?: number) => void = (pageIndex) => {
    if (tabName === 'all') {
      notificationHistoryData({
        variables: notificationFilters({
          pageIndex,
        }),
      });
    } else if (tabName === 'unread') {
      notificationHistoryData({
        variables: notificationFilters({ isUnreadFilter: true, pageIndex }),
      });
    } else if (tabName === 'read') {
      notificationHistoryData({
        variables: notificationFilters({ isReadFilter: true, pageIndex }),
      });
    } else if (tabName === 'assignedToMe') {
      notificationHistoryData({
        variables: notificationFilters({ assignedToMeFilter: true, pageIndex }),
      });
    }
  };

  const notificationFilters = ({
    pageIndex = 1,
    sort = '_ID_DESC',
    assignedToMeFilter,
    isReadFilter,
    isUnreadFilter,
  }: NotificationFiltersParams) => {
    let filter: {
      isSeen?: boolean;
      searchQuery?: string;
      subCategory?: string[];
      userId?: string;
      category?: string[];
    } = {
      searchQuery: '',
    };

    if (searchFieldText) {
      filter.searchQuery = searchFieldText;
    }

    if (selectedModules?.length > 0) {
      filter.category = selectedModules?.map((item) => item?.value);
    }

    if (assignedToMeFilter) {
      filter.subCategory = [
        'taskAssigned',
        'trainingAssignment',
        'auditCreated',
      ];
    }
    if (isReadFilter) {
      filter.isSeen = true;
    }

    if (isUnreadFilter) {
      filter.isSeen = false;
    }

    return {
      page: pageIndex,
      perPage: 10,
      filter: filter,
      sort: sort,
    };
  };
  const [notificationCount, setNotificationCount] = useState<number>(1);
  const [
    notificationHistoryData,
    { data, loading: notificationLoading, error, refetch: notificationRefetch },
  ] = useLazyQuery<INotificationResponse, IFilters>(GET_NOTIFICATION_HISTORY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setNotificationData(data?.Notifications?.items);
      setNotificationCount(data?.Notifications?.count);
    },
    onError: (err) => {
      console.log('Error while fetching notification history:', err);
    },
  });

  const [getUnseenNotificationCount] = useLazyQuery(GET_UNREAD_NOTIFICATIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      updateUnseenCount(data?.UnseenNotificationCount);
    },
    onError: (err) => {
      console.log('Error while fetching notification read count --- ', err);
    },
  });

  const [updateNotificationReadState] = useMutation(
    MARK_READ_UNREAD_SINGLE_NOTIFICATION,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        getUnseenNotificationCount();
        decideFilterAndRefetch();
      },
      onError: (err) => {
        console.log('Error while updating notification read status: ', err);
      },
    }
  );

  const [markAllNotificationRead] = useMutation<never, MarkAllReadInput>(
    MARK_ALL_READ,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        getUnseenNotificationCount();
        decideFilterAndRefetch();
      },
      onError: (err) => {
        console.log('err --- ', err);
      },
    }
  );

  const { notificationHistoryDetails, fetchNotificationHistory } = useService();
  const { loading, notificationHistoryResult } = notificationHistoryDetails;

  const handleModuleSelect = (
    e: ChangeEvent<HTMLInputElement>,
    item: FilterItem
  ) => {
    if (e?.target?.checked) {
      const newSelectedModules = [
        ...selectedModules,
        { ...item, checked: true },
      ];
      setSelectedModules(newSelectedModules);
    }

    if (!e?.target?.checked) {
      const newSelectedModules = selectedModules?.filter(
        (i) => i?.value !== item?.value
      );
      setSelectedModules(newSelectedModules);
    }

    setCheckedModules((prevState) => {
      const newObj = prevState?.map((i) => {
        if (item?.value === i?.value) {
          return { ...i, checked: e?.target?.checked };
        } else {
          return { ...i };
        }
      });

      return newObj;
    });
  };

  const handleRemoveFilter = (item: FilterItem) => {
    setSelectedModules((prev) => {
      return prev.filter((prev) => prev.value !== item?.value);
    });

    setCheckedModules((prevState) => {
      const newObj = prevState?.map((i) => {
        if (item?.value === i?.value) {
          return { ...i, checked: false };
        } else {
          return { ...i };
        }
      });

      return newObj;
    });
  };

  const resetAllFilters = () => {
    setSelectedModules([]);
    setCheckedModules((prevState) => {
      const newObj = prevState?.map((i) => {
        return { ...i, checked: false };
      });
      return newObj;
    });
  };

  const handleNotificationSort = (filterType: string) => {
    notificationHistoryData({
      variables: notificationFilters({ sort: filterType }),
    });
  };

  const handleUpdateNotificationReadStatus = (eid: string, status: boolean) => {
    updateNotificationReadState({
      variables: {
        eid: eid,
        isSeen: status,
      },
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      decideFilterAndRefetch();
    }, 500);
    return () => clearTimeout(timer);
  }, [searchFieldText, selectedModules]);

  return (
    <Box
      style={{
        marginTop: '20px',
        backgroundColor: 'white',
        padding: '1rem',
        borderRadius: '12px',
      }}
    >
      <HeaderSection
        placeholder={'Search by title'}
        searchFieldText={searchFieldText}
        setSearchFieldText={setSearchFieldText}
        selectedExpiryFilter={selectedExpiryFilter}
        setSelectedExpiryFilter={setSelectedExpiryFilter}
        setSelectedModules={setSelectedModules}
        selectedModules={selectedModules}
        checkedModules={checkedModules}
        setCheckedModules={setCheckedModules}
        handleModuleSelect={handleModuleSelect}
        handleNotificationSort={handleNotificationSort}
      />

      {selectedModules && selectedModules.length > 0 && (
        <AppliedFiltersState
          isCompactView={false}
          appliedFilters={selectedModules}
          setSelectedModules={setSelectedModules}
          resetHandler={resetAllFilters}
          checkedModules={checkedModules}
          handleRemoveFilter={handleRemoveFilter}
        />
      )}

      <SummaryWrapper
        customStyles={{
          marginTop: '24px',
          backgroundColor: 'white',
          width: '350px',
        }}
        isCompactView={false}
        resetHandler={resetAllFilters}
        checkedModules={checkedModules}
        handleRemoveFilter={handleRemoveFilter}
        handleModuleSelect={handleModuleSelect}
        selectedModules={selectedModules}
        setSelectedModules={setSelectedModules}
        notifications={notificationHistoryResult}
        notificationFilters={notificationFilters}
        notificationHistoryData={notificationHistoryData}
        notificationData={notificationData}
        notificationLoading={notificationLoading}
        handleUpdateNotificationReadStatus={handleUpdateNotificationReadStatus}
        setTabName={setTabName}
        markAllNotificationRead={markAllNotificationRead}
        searchFieldText={searchFieldText}
        notificationCount={notificationCount}
        decideFilterAndRefetch={decideFilterAndRefetch}
        tabName={tabName}
      />
    </Box>
  );
};
NotificationContainer.displayName =
  'sub-components/NotificationCenter/NotificationContainer';
export default React.memo(NotificationContainer);
