import { Box, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';

interface DashNavbarData {
  name: string;
  color: string;
  count: string;
}

interface DashNavbarProps {
  locations: DashNavbarData[];
  launcherEmptyState: boolean;
}

const DashNavbar: FC<DashNavbarProps> = ({ locations, launcherEmptyState }) => {
  return (
    <Flex gap={'20px'} align='center' my={6}>
      {locations.map((location, index) => (
        <Flex
          key={index}
          cursor='pointer'
          p={6}
          borderRadius='8px'
          bg='white'
          flexDir='column'
          w='full'
          gap='10px'
          mb='10px'
        >
          <Flex align='center' justify='space-between'>
            <Flex gap='10px' align='center'>
              <Box h='20px' w='12px' bg={location.color} borderRadius='2px' />
              <Text>{location.name}</Text>
            </Flex>
          </Flex>
          <Text fontWeight={700} fontSize='20px'>
            {launcherEmptyState ? `-` : location.count}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

export default DashNavbar;
