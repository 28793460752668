import React, { FC, useEffect } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useRouteState } from '../../../routes';
import { ListingContainer } from '../../../sub-components/audits/listing';

const notSelectedTabStyle = {
  fontWeight: 600,
  color: '#6F767E',
  fontSize: '15px',
  padding: '8px 16px',
  borderRadius: '8px',
  cursor: 'pointer',
};

const selectedTabStyle = {
  backgroundColor: '#EFEFEF',
  fontWeight: 600,
  fontSize: '15px',
  padding: '8px 16px',
  borderRadius: '8px',
  cursor: 'pointer',
};

interface IProps {}

const TemplateContainer: FC<IProps> = () => {
  const { state, updateState } = useRouteState('template', {
    templateView: 'template',
  });

  useEffect(() => {
    const auditTab = sessionStorage.getItem('auditDraftTab');
    if (auditTab) {
      updateState({
        templateView: auditTab,
      });
      sessionStorage.removeItem('auditDraftTab');
    }
  }, []);

  function setTemplateView(name: string) {
    updateState({
      templateView: name,
    });
  }

  const templateView = state?.templateView;

  return (
    <Flex flexDir='column' gap={4}>
      <Flex justify='space-between'>
        <Flex w='80%' userSelect={'none'}>
          <Text
            style={
              templateView === 'template'
                ? selectedTabStyle
                : notSelectedTabStyle
            }
            onClick={() => setTemplateView('template')}
          >
            Drafts
          </Text>
          <Text
            style={
              templateView === 'library'
                ? selectedTabStyle
                : notSelectedTabStyle
            }
            onClick={() => setTemplateView('library')}
          >
            Template Library
          </Text>
        </Flex>
      </Flex>
      <ListingContainer templateView={templateView} />
    </Flex>
  );
};

export default TemplateContainer;
