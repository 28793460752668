import React, { FC, useCallback } from 'react';
import { Flex } from '@chakra-ui/react';
import { BoxHeader } from '../../../../../../../ui-components';
import { AuditSessionEntity } from 'sub-components/audits/audit-tab/supervised/types';
import { auditStatus } from 'sub-components/audits/audit-tab/supervised/utils';
import { useAuditDetailContext } from 'pages/Audits/Detail/store/context';
import {
  AssigneeDetail,
  DateDetail,
  FrequencyDetail,
  ReportDetail,
  StatusDetail,
} from './components';

interface IProps {
  auditData: AuditSessionEntity | undefined;
}

const SummarySection: FC<IProps> = ({ auditData }) => {
  console.log('summary section : ', auditData);

  const selectedLocationFilter = useAuditDetailContext(
    (state) => state.selectedLocationFilter
  );

  const findAuditStatus = useCallback(() => {
    if (
      !selectedLocationFilter ||
      selectedLocationFilter?.value === 'allLocations'
    ) {
      let isAllCompleted = auditData?.isCompleted;
      let hasAuditStarted = auditData?.assignments?.some(
        (assignment) => assignment.startedBy
      );
      let status = auditStatus(
        !!isAllCompleted,
        auditData?.dueDate,
        !!hasAuditStarted
      );
      return status;
    } else {
      let selectedLocation = auditData?.assignments?.find(
        (assignment) => assignment?.locationId === selectedLocationFilter?.value
      );
      if (selectedLocation) {
        const currentDate = new Date();
        const dueDate = auditData?.dueDate
          ? new Date(auditData?.dueDate)
          : null;
        const isOverdue =
          dueDate && !selectedLocation.isCompleted && currentDate > dueDate;

        const assignmentStatus = selectedLocation.isCompleted
          ? 'completed'
          : isOverdue
          ? 'overdue'
          : selectedLocation.startedBy
          ? 'ongoing'
          : 'upcoming';
        return assignmentStatus;
      }
    }
  }, [selectedLocationFilter]);

  return (
    <Flex
      flexDir='column'
      border='2px solid #EFEFEF'
      borderRadius='10px'
      p='16px 20px'
      gap={8}
      w='full'
      h='316px'
    >
      <BoxHeader
        boxWidth={3.5}
        fontSize='18px'
        title='Details'
        color='#FFBC99'
      />

      <DateDetail auditStatus={findAuditStatus()} auditData={auditData} />

      <AssigneeDetail auditData={auditData} />

      <StatusDetail auditStatus={findAuditStatus()} />

      <FrequencyDetail auditData={auditData} />

      <ReportDetail auditData={auditData} auditStatus={findAuditStatus()} />
    </Flex>
  );
};

SummarySection.displayName = 'sc/a/d/c/O/c/SS/SummarySection';

export default SummarySection;
