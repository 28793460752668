import React, { FC } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AppliedFilters, FilterItem } from '../Summary/types';
import { DeleteIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCross } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faXmark } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
  appliedFilters: AppliedFilters;
  setSelectedModules: React.Dispatch<React.SetStateAction<FilterItem[]>>;
  isCompactView?: boolean; //used at Drawer or not
  removeSingleFilter?: (filterkey: string) => void;
  resetHandler: () => void;
  addSingleFilter?: (filterkey: string) => void;
  checkedModules?: any;
  handleRemoveFilter: (item: FilterItem) => void;
  handleModuleSelect?: any;
}

const AppliedFiltersState: FC<IProps> = ({
  appliedFilters,
  setSelectedModules,
  isCompactView = false,
  resetHandler,
  checkedModules,
  handleRemoveFilter,
  handleModuleSelect,
}) => {
  const { t } = useTranslation(['notifications']);

  const _visibleFilters = React.useMemo(() => {
    return isCompactView ? appliedFilters.slice(0, 2) : appliedFilters;
  }, [appliedFilters, isCompactView]);

  return (
    <Flex align={'center'} justify={'flex-start'} gap={'12px'} mt={4} mb={2}>
      <Flex>
        <Text color={'#33383F'} fontWeight={600} fontSize={'15px'}>
          {t('notifications:utils.filterApplied')} :
        </Text>
      </Flex>
      <Flex gap={2} flexWrap={'wrap'}>
        {_visibleFilters?.map((item) => {
          return (
            <>
              {item?.checked && (
                <Button
                  minWidth={'fit-content'}
                  variant={'solid'}
                  _hover={{
                    bgColor: '#CABDFF',
                  }}
                  key={item.eid}
                  bg={'#CABDFF'}
                  fontWeight={600}
                  fontSize={'14px'}
                  border={'2px solid #EFEFEF'}
                  // p={'8px 16px'}
                  borderRadius={'12px'}
                  color={'#1A1D1F'}
                  rightIcon={
                    <FontAwesomeIcon
                      icon={faXmark as IconProp}
                      onClick={() => handleRemoveFilter(item)}
                    />
                  }
                >
                  {item.label}
                </Button>
              )}
            </>
          );
        })}

        {isCompactView && appliedFilters.length > 2 && (
          <Button
            variant={'solid'}
            _hover={{
              bgColor: '#CABDFF',
            }}
            bg={'#CABDFF'}
            fontWeight={600}
            fontSize={'14px'}
            border={'2px solid #EFEFEF'}
            p={'8px 16px'}
            borderRadius={'12px'}
            color={'#1A1D1F'}
          >
            +{appliedFilters.length - 2}
          </Button>
        )}

        <Button
          variant={'outline'}
          bg={'#FFFFFF'}
          border={'2px solid #EFEFEF'}
          borderRadius={'12px'}
          p={'8px 16px'}
          gap={'8px'}
          fontWeight={600}
          onClick={resetHandler}
        >
          {t('notifications:utils.resetFilters')}
        </Button>
      </Flex>
    </Flex>
  );
};
AppliedFiltersState.displayName = 'sc/NC/S/AppliedFilters.tsx';
export default AppliedFiltersState;
