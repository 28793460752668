import React, { FC, useMemo } from 'react';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  useControllableState,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../../../../../../atoms';
import { useSafeState } from '../../../../../../hooks';
import { useConfigCategoryList } from '../../../common/config-task-form';

interface IProps {
  value?: string[];
  onChange?: (value: string[]) => void;
}

const TaskTypeList: FC<IProps> = ({ value, onChange }) => {
  const { t } = useTranslation('launcher');
  const [query, updateQuery] = useSafeState('');
  const { categories } = useConfigCategoryList();

  const [internalValue, updateValue] = useControllableState({
    value: value,
    onChange: onChange,
  });

  const filterList = useMemo(() => {
    if (!query) return categories;
    return categories.filter((category) =>
      category.label.toLowerCase().includes(query.toLowerCase())
    );
  }, [categories, query]);

  return (
    <Flex
      flexDir='column'
      border='1px solid #EFEFEF'
      bg='#FCFCFC'
      borderRadius='10px'
      padding='12px 16px'
      gap='10px'
    >
      <SearchInput
        hideShortcuts
        placeholder={t('searchByType')}
        onChange={(ev) => updateQuery(ev.target.value)}
      />

      <CheckboxGroup value={internalValue} onChange={updateValue as never}>
        {filterList.map((category) => (
          <React.Fragment key={category.value}>
            <Flex align='center' gap='10px'>
              <Box flex={1} fontSize='14px' fontWeight='600' isTruncated>
                {category.label}
              </Box>
              <Checkbox value={category.value} size='lg' />
            </Flex>
            <Divider borderColor='#EFEFEF' _last={{ display: 'none' }} />
          </React.Fragment>
        ))}
      </CheckboxGroup>
    </Flex>
  );
};

export default TaskTypeList;
