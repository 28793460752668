import React, { forwardRef } from 'react';
import { Box, Flex, Switch, SwitchProps } from '@chakra-ui/react';

interface IProps extends Omit<SwitchProps, 'value'> {
  title: string;
  caption: string;
  value?: boolean;
}

const TaskSwitchInput = forwardRef<never, IProps>(
  ({ title, caption, value, ...props }, ref) => {
    return (
      <Flex justify='space-between' align='center'>
        <Flex flexDir='column'>
          <Box fontWeight='600' color='#33383F'>
            {title}
          </Box>
          <Box fontSize='12px' color='#6F767E'>
            {caption}
          </Box>
        </Flex>
        <Switch {...props} isChecked={value as never} ref={ref} />
      </Flex>
    );
  }
);

TaskSwitchInput.displayName = 'sc/L/lc/c/ctf/c/TaskSwitchInput';

export default TaskSwitchInput;
