import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { HeaderColors } from '../../../../../configs';
import {
  BoxHeader,
  UseConfirm,
  useConfirm,
} from '../../../../../ui-components';

import { LauncherAssignee } from '../launcher-config.fragment';
import AddAssigneeContent from './AddAssigneeContent';

interface IProps {
  value?: LauncherAssignee;
  title?: string;
  description?: string;
  onChange?: (value: LauncherAssignee) => void;
}

type AddAssignee = (props: IProps) => void;

export const useAddAssignee = (): AddAssignee => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  const confirm = useConfirm();

  return useCallback((props) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      title: (
        <Flex flexDir='column' gap={1}>
          <Flex justify='space-between' paddingLeft={2}>
            <BoxHeader
              title={props?.title || 'Select assignee(s)'}
              fontSize='18px'
              color={HeaderColors.Purple}
              boxWidth={3}
            />
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>

          <Box
            fontSize='14px'
            fontWeight='400'
            color='#2A85FF'
            paddingLeft='32px'
          >
            {props?.description ||
              'If a phase assignee is added later, it will override this task assignee'}
          </Box>
        </Flex>
      ),

      content: <AddAssigneeContent {...props} />,
      isCentered: true,
      contentProps: {
        paddingTop: '8px',
        paddingBottom: '12px',
        minW: '680px',
      },
      footer: null,
    });
  }, []);
};
