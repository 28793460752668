import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AuditQuestionEntity } from 'types';
import {
  QuestionsState,
  SectionEntity,
  SessionDetailEntity,
} from '../../../../../../sub-components/audits/take-audit/types';
import { usersEntityObj } from '../../../../../../sub-components/Header';
import { useAuditDetailContext } from '../../../store/context';
import {
  DisplaySession,
  QuestionResponseSectionEntity,
  ResponseStatus,
} from '../types/audit-question.types';
import { useServiceLayer } from './useServiceLayer';
import moment from 'moment';
import { SelectOption } from 'atoms';
import {
  AuditSessionAssignments,
  AuditSessionEntity,
} from 'sub-components/audits/audit-tab/supervised/types';
import { ControlLayer } from '../types/helper.types';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'authorization';
import { cloneDeep } from '@apollo/client/utilities';

export const useControlLayer = (isAuditRepeating: boolean): ControlLayer => {
  const params = useParams<{ auditId: string; sessionId: string }>();
  const selectedTab = useAuditDetailContext((state) => state.selectedTab);
  const entityObj = useReactiveVar(usersEntityObj);
  const [sessions, setSessions] = useState<SelectOption[]>([]);
  const { loggedInUserAuthRole, loggedInUserEid } = useUserDataSelector(
    (state) => ({
      loggedInUserEid: state?.eid,
      loggedInUserAuthRole: state?.authRole,
    })
  );
  const [selectedSession, setSelectedSession] = useState<SelectOption>({
    label: '',
    value: '',
  });

  const [selectedLocationFilter, setSelectedLocationFilter] =
    useState<SelectOption>({ label: 'All locations', value: 'all' });

  const [selectedAuditSessionId, setSelectedAuditSessionId] = useState(
    params?.sessionId
  );
  const [selectedQuestionId, setSelectedQuestionId] = useState<string>('');

  const {
    auditCycle: { auditCyclesResponse, getAuditCycles },
    questionDetails: { getQuestionDetails, questionDetailsResponse },
  } = useServiceLayer();
  const auditQuestionInstance = questionDetailsResponse;

  useEffect(() => {
    if (isAuditRepeating && selectedTab === 'questionWise') {
      getAuditCycles({
        variables: {
          page: 1,
          perPage: 1000,
          filter: {
            auditIds: [params?.auditId],
          },
          sort: 'DUEDATE_DESC',
        },
      });
    }
  }, [selectedTab, isAuditRepeating]);

  useEffect(() => {
    getQuestionDetails({
      variables: {
        eid: selectedAuditSessionId,
      },
    });
  }, [selectedAuditSessionId]);

  const transformSessions = (
    sessions: SessionDetailEntity[]
  ): DisplaySession[] => {
    if (!sessions || sessions.length === 0) return [];

    // const sorted = [...sessions].sort(
    //   (a, b) =>
    //     new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    // );

    const transformed: DisplaySession[] = sessions.map(
      (session, index, arr) => {
        if (index === 0)
          return { ...session, label: 'Current cycle', value: session?.eid };
        if (index === 1)
          return { ...session, label: 'Previous cycle', value: session?.eid };
        return {
          ...session,
          label: `${moment(session?.startDate)?.format('DD MMM YYYY')}`,
          value: session?.eid,
        };
        // return {
        //   ...session,
        //   label: `${moment(session?.startDate)?.format('DD MMM YY')} - ${moment(
        //     session?.dueDate
        //   )?.format('DD MMM YY')}`,
        //   value: session?.eid,
        // };
      }
    );

    return transformed;
  };

  const displaySessions = useMemo(() => {
    let sessions = transformSessions(
      auditCyclesResponse?.data?.AuditSessionPagination?.items || []
    );
    let foundSession = sessions?.find(
      (session) => session?.eid === params?.sessionId
    );
    if (foundSession) {
      setSelectedSession({
        label: foundSession?.label,
        value: foundSession?.eid,
      });
    }
    return sessions;
  }, [auditCyclesResponse?.data?.AuditSessionPagination?.items, params]);

  useEffect(() => {
    setSelectedQuestionId('');
  }, [selectedSession]);

  const refetchHandler = () => {
    auditQuestionInstance?.refetch?.();
  };

  const auditLocations = useMemo(() => {
    let assignments: AuditSessionAssignments[] = [];
    if (loggedInUserAuthRole === AuthRole.ADMIN) {
      assignments =
        auditQuestionInstance?.data?.AuditSessionById?.assignments?.filter(
          (assignment) => assignment?.auditors?.includes(loggedInUserEid)
        ) || [];
    } else {
      assignments =
        auditQuestionInstance?.data?.AuditSessionById?.assignments || [];
    }
    if (!assignments || assignments.length === 0) {
      return [{ label: 'All locations', value: 'all' }];
    }

    const entityMap = new Map(entityObj.map((entity) => [entity.eid, entity]));

    const locations: SelectOption[] = assignments
      .map((assignment) => {
        const entity = entityMap.get(assignment?.locationId);
        return entity ? { value: entity.eid, label: entity.name } : null;
      })
      .filter(Boolean) as SelectOption[];

    return [{ label: 'All locations', value: 'all' }, ...locations];
  }, [
    auditQuestionInstance?.data?.AuditSessionById,
    entityObj,
    loggedInUserAuthRole,
    loggedInUserEid,
  ]);

  const getResponseStatus = (
    dueDate: string,
    response: string | string[],
    anyResponsePresent: boolean
  ): ResponseStatus => {
    const now = moment();
    let isResponsePresent = false;
    if (typeof response === 'string') {
      isResponsePresent = !!response;
    } else if (Array.isArray(response)) {
      isResponsePresent = response.length > 0;
    }

    if (isResponsePresent) {
      return 'submitted';
    } else if (anyResponsePresent && !isResponsePresent) {
      return 'noResponseFound';
    } else if (now.isAfter(moment(dueDate)) && !isResponsePresent) {
      return 'missed';
    } else if (
      (moment(dueDate).isAfter(now) && !isResponsePresent) ||
      !dueDate
    ) {
      return 'notSubmitted';
    }

    return 'submitted';
  };

  const processAuditData = (
    data: AuditSessionEntity
  ): QuestionResponseSectionEntity[] => {
    let currentSessionTasks = data?.task?.filter(
      (task) => task?.audit?.sessionId === params?.sessionId
    );
    return data.questions
      ?.filter((question) => question?.qType !== 'section')
      ?.map((question) => {
        let currentQuestionTask = currentSessionTasks?.filter(
          (task) => task?.audit?.qid === question?.eid
        );
        let assignments: AuditSessionAssignments[] = [];
        if (selectedLocationFilter?.value === 'all') {
          assignments = data.assignments;
        } else if (
          selectedLocationFilter?.value &&
          selectedLocationFilter?.value !== 'all'
        ) {
          assignments = data?.assignments?.filter(
            (assignment) =>
              assignment?.locationId === selectedLocationFilter?.value
          );
        }
        const responses = assignments.map((assignment) => {
          const location = entityObj?.find(
            (loc) => loc?.eid === assignment?.locationId
          );
          let locationName = location ? location?.name : 'Unknown Location';
          let rawResponse = assignment?.response;
          const responseForQuestion = assignment.response.find(
            (response: any) => response.qid === question.eid
          );
          let isResponseFlagged = false;
          if (responseForQuestion) {
            isResponseFlagged = responseForQuestion.isFlagged;
            if (responseForQuestion.responseId.length > 0) {
              const selectedOptions = question.options.filter((option) =>
                responseForQuestion.responseId.includes(option.eid)
              );
              if (selectedOptions.some((option) => option.isFlagged)) {
                isResponseFlagged = true;
              }
            }
          }
          let response = responseForQuestion?.response
            ? responseForQuestion.response
            : responseForQuestion?.responseId?.length &&
              responseForQuestion.responseId.length > 0
            ? responseForQuestion.responseId
            : responseForQuestion?.responseUrl?.length &&
              responseForQuestion.responseUrl.length > 0
            ? responseForQuestion.responseUrl
            : '';
          const anyResponsePresent =
            assignment.response && assignment.response.length > 0;
          assignment.response && assignment.response.length > 0;
          return {
            locationId: location?.eid,
            locationName: locationName,
            locationOwner: findLocationOwner(location?.eid),
            response: response,
            isFlagged: isResponseFlagged,
            isCorrect: responseForQuestion
              ? responseForQuestion.isCorrect
              : false,
            comment: responseForQuestion ? responseForQuestion.noteText : '',
            evidenceImages: responseForQuestion?.images,
            respondedAt: responseForQuestion
              ? responseForQuestion.respondedAt
              : '',
            question,
            rawResponse,
            responseForQuestion,
            responseStatus: getResponseStatus(
              data?.dueDate,
              response,
              anyResponsePresent
            ),
          };
        });

        return {
          questionId: question?.eid,
          questionText: question?.label,
          hasFlaggedResponse: question?.hasFlaggedResponse,
          responsesByLocation: responses,
          taskData: currentQuestionTask || [],
        };
      });
  };

  const checkIfQuestionIsFlagged = (
    question: AuditQuestionEntity,
    assignments: AuditSessionAssignments[]
  ): boolean => {
    return assignments.some((assignment) => {
      const responseForQuestion = assignment.response.find(
        (response) => response.qid === question.eid
      );

      if (responseForQuestion) {
        if (responseForQuestion.isFlagged) {
          return true;
        }

        if (responseForQuestion.responseId.length > 0) {
          const selectedOptions = question.options.filter((option) =>
            responseForQuestion.responseId.includes(option.eid)
          );
          return selectedOptions.some((option) => option.isFlagged);
        }
      }

      return false;
    });
  };

  const questionData = useMemo(() => {
    const categorizedData: Array<SectionEntity> = [];

    let currentSection: SectionEntity = {
      eid: 'default',
      sectionName: 'Audit Questionnaire',
      data: [],
      selected: false,
      questionCount: 0,
      isSectionFlagged: false,
    };

    let session = cloneDeep(auditQuestionInstance?.data?.AuditSessionById);
    if (loggedInUserAuthRole === AuthRole.ADMIN) {
      const filteredAssignments =
        auditQuestionInstance?.data?.AuditSessionById?.assignments?.filter(
          (assignment) => assignment?.auditors?.includes(loggedInUserEid)
        );
      if (session) {
        session.assignments = filteredAssignments || [];
      }
    }
    session?.questions?.forEach((question) => {
      if (question?.qType === 'section') {
        if (currentSection?.data?.length > 0) {
          categorizedData.push(currentSection);
        }
        currentSection = {
          eid: question.eid,
          sectionName: question.label,
          data: [],
          selected: false,
          questionCount: 0,
          isSectionFlagged: false,
        };
      } else {
        const isFlagged = checkIfQuestionIsFlagged(
          question,
          session?.assignments || []
        );
        currentSection.data.push(question as QuestionsState);
        if (isFlagged) {
          currentSection.isSectionFlagged = true;
        }
      }
    });
    if (currentSection.data.length > 0) {
      categorizedData.push(currentSection);
    }
    return categorizedData;
  }, [auditQuestionInstance?.data]);

  useEffect(() => {
    if (!selectedQuestionId && questionData && questionData.length > 0) {
      setSelectedQuestionId(questionData[0].eid);
    }
  }, [questionData, selectedQuestionId]);

  const findLocationOwner = (locationId?: string) => {
    if (!locationId) {
      return {
        eid: '',
        name: '',
        profilePic: '',
      };
    }
    let foundLocationowner = entityObj?.filter((ent) =>
      ent?.locations?.some((loc) => loc?.eid === locationId)
    )?.[0];
    return {
      eid: foundLocationowner?.eid,
      name: foundLocationowner?.name,
      profilePic: foundLocationowner?.profilePic,
    };
  };

  const questionSectionData = useMemo((): QuestionResponseSectionEntity[] => {
    let session = cloneDeep(auditQuestionInstance?.data?.AuditSessionById);
    if (loggedInUserAuthRole === AuthRole.ADMIN) {
      const filteredAssignments =
        auditQuestionInstance?.data?.AuditSessionById?.assignments?.filter(
          (assignment) => assignment?.auditors?.includes(loggedInUserEid)
        );
      if (session) {
        session.assignments = filteredAssignments || [];
      }
    }
    let _final: QuestionResponseSectionEntity[] = [];
    if (session) {
      _final = processAuditData(session);
    }
    return _final;
  }, [auditQuestionInstance?.data, selectedLocationFilter, entityObj]);

  const auditData = useMemo(() => {
    let session = auditQuestionInstance?.data?.AuditSessionById;
    return session;
  }, [auditQuestionInstance?.data]);

  const handleLocationFilterChange = (value: SelectOption) => {
    setSelectedLocationFilter(value);
    setSelectedQuestionId('');
  };

  return {
    auditData,
    auditLocations,
    displaySessions,
    loading: auditQuestionInstance?.loading,
    questionData,
    questionSectionData,
    selectedLocationFilter,
    selectedSession,
    sessions,
    sessionsLoading: auditCyclesResponse?.loading,
    refetchHandler,
    setSelectedAuditSessionId,
    handleLocationFilterChange,
    setSelectedSession,
    setSessions,
    selectedQuestionId,
    setSelectedQuestionId,
  };
};
