import React, { FC, useCallback, useRef } from 'react';
import { Box, Button, Center, Flex, Text } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import {
  components,
  GroupBase,
  MenuProps,
  OptionProps,
  Props,
  Select,
} from 'chakra-react-select';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { SelectOption } from 'atoms/Dropdown';
import {
  getChakraSelectStyles,
  SelectStyles,
} from 'atoms/Dropdown/dropdown.styles';
import { PrimaryButton, SearchInput } from 'atoms';
import { faFolder, faFolderUser } from '@fortawesome/pro-regular-svg-icons';

interface CustomOptionProps extends OptionProps<SelectOption, false> {
  checkIcon?: React.ReactElement;
}

const CustomOption: FC<CustomOptionProps> = ({ checkIcon, ...props }) => {
  const { isSelected, children } = props;
  return (
    <components.Option {...props}>
      <Flex align='center' justify='space-between'>
        <Flex gap='5px' align='center'>
          <Center boxSize='32px' border='1px solid #DDDDDD' borderRadius='5px'>
            <FontAwesomeIcon
              icon={
                (props.data?.visibility === 'private'
                  ? faFolderUser
                  : faFolder) as IconProp
              }
              color='#6F767E'
              fontSize='15px'
            />
          </Center>
          <Text
            flex={1}
            fontSize='15px'
            noOfLines={1}
            pr='8px'
            color='#272b30'
            fontWeight='500'
          >
            {children}
          </Text>
        </Flex>
        {isSelected &&
          (checkIcon ? (
            checkIcon
          ) : (
            <FontAwesomeIcon icon={faCheck as IconProp} color='#83bf6e' />
          ))}
      </Flex>
    </components.Option>
  );
};

interface IProps
  extends Pick<
    Props<SelectOption, false, GroupBase<SelectOption>>,
    'options' | 'value' | 'onChange' | 'placeholder'
  > {
  onMove?: () => void;
  isDisabled?: boolean;
  selectStyles?: SelectStyles;
  loading?: boolean;
  checkIcon?: React.ReactElement;
}

const FolderSubFolderSelect: FC<IProps> = ({
  options,
  value,
  onChange,
  isDisabled,
  placeholder,
  onMove,
  selectStyles,
  loading,
  checkIcon,
}) => {
  const { t } = useTranslation(['card', 'category']);
  const selectRef = useRef<any>(null);

  const Menu = useCallback(
    (menuProps: MenuProps<SelectOption, false>) => {
      return (
        <components.Menu {...menuProps}>
          <Flex>
            <SearchInput placeholder='Search' />
          </Flex>
          <Box px={3} pt={3}></Box>
          {menuProps.children}
          <Flex p={2}>
            {/* <Button
                isFullWidth
                colorScheme='green'
                onClick={() => {
                selectRef.current?.blur();
                onCreatePress?.();
                }}
                justifyContent='flex-start'
                variant='solid'
                fontSize='15px'
            >
                Move
            </Button> */}
            <PrimaryButton
              title='Move'
              onClick={onMove}
              disabled={!value}
              colorScheme='green'
              variant='solid'
            />
          </Flex>
        </components.Menu>
      );
    },
    [onMove]
  );

  return (
    <Select<SelectOption, false>
      ref={selectRef}
      isLoading={loading}
      options={options}
      closeMenuOnSelect={false}
      value={value}
      onChange={onChange}
      size='lg'
      isDisabled={isDisabled || loading}
      isSearchable={false}
      placeholder={placeholder}
      chakraStyles={getChakraSelectStyles<SelectOption, false>({
        ...selectStyles,
        menuList: {
          padding: '4px 12px 12px',
          ...selectStyles?.menu,
        },
        control: {
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '12px',
          fontSize: '15px',
          outline: 'none',
          ...selectStyles?.control,
        },
      })}
      styles={{
        option: (base, optionProps) => ({
          ...base,
          backgroundColor: 'none',
          borderBottom: '1px solid #efefef',
          borderRadius: optionProps?.isFocused ? 8 : 0,
          ':last-child': {
            borderBottom: 'none',
          },
        }),
        menu: (base) => ({
          ...base,
          borderRadius: '8px',
        }),
      }}
      components={{
        Option: (props) => <CustomOption {...props} checkIcon={checkIcon} />,
        Menu,
      }}
    />
  );
};

FolderSubFolderSelect.displayName =
  'displayName:pages/Chapters/CreateEditSubFolder/components/FolderSubFolderSelect';
export default FolderSubFolderSelect;
