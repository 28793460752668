import { Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faGear } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { FilterItem } from 'sub-components/NotificationCenter/Summary/types';
import { Filters, Searchbox, SortByFilter } from './';
import { IFilters } from './../../types';

interface IProps extends IFilters {}

const HeaderSection: FC<IProps> = ({
  placeholder,
  searchFieldText,
  setSearchFieldText,
  selectedExpiryFilter,
  setSelectedExpiryFilter,
  setSelectedModules,
  selectedModules,
  checkedModules,
  setCheckedModules,
  handleModuleSelect,
  handleNotificationSort,
}) => {
  const history = useHistory();
  return (
    <Flex gap={6} alignItems={'center'}>
      <Searchbox
        placeholder={placeholder}
        searchFieldText={searchFieldText}
        setSearchFieldText={setSearchFieldText}
      />
      <SortByFilter handleNotificationSort={handleNotificationSort} />
      <Filters
        setSelectedModules={setSelectedModules}
        selectedModules={selectedModules}
        checkedModules={checkedModules}
        setCheckedModules={setCheckedModules}
        handleModuleSelect={handleModuleSelect}
      />
      <FontAwesomeIcon
        onClick={() => history.push('/notifications/settings')}
        cursor={'pointer'}
        fontSize={'20px'}
        icon={faGear as IconProp}
      />
    </Flex>
  );
};
HeaderSection.displayName = 'sc/NC/D/c/NCHeaderSection.tsx';
export default React.memo(HeaderSection);
