import React, { FC } from 'react';
import {
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { BoxHeader } from '../../../../../ui-components';
import { HeaderColors } from '../../../../../configs';

import { EditorConfigTaskForm, useLauncherConfigContext } from '../../common';

interface IProps {}

const BoardTaskForm: FC<IProps> = () => {
  const taskForm = useLauncherConfigContext((state) => state.taskForm);
  const action = useLauncherConfigContext((state) => ({
    closeTaskForm: state.closeTaskForm,
  }));

  const onClose = () => action.closeTaskForm();

  return (
    <Drawer
      isOpen={!!taskForm}
      placement='right'
      onClose={onClose}
      // finalFocusRef={btnRef}
      closeOnOverlayClick={false}
    >
      <DrawerOverlay />
      <DrawerContent maxW='xl'>
        <DrawerHeader>
          <Center justifyContent='space-between'>
            <BoxHeader
              title={taskForm?.taskEid ? 'Edit task' : 'Add task'}
              color={HeaderColors.Purple}
              fontSize='18px'
            />
            <DrawerCloseButton
              size='sm'
              position='relative'
              colorScheme='blue'
              right='initial'
              top='initial'
              borderRadius='full'
            />
          </Center>
        </DrawerHeader>

        <DrawerBody>
          <EditorConfigTaskForm />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

BoardTaskForm.displayName =
  'sub-components/Launcher/launcher-config/board-view/board-task-form/BoardTaskForm';

export default BoardTaskForm;
