import React, { FC } from 'react';
import { Flex, IconButton } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisStrokeVertical,
  faPlus,
  faStopwatch,
} from '@fortawesome/pro-regular-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import TaskAssignee from './TaskAssignee';
import { ActionMenu, IActionMenuData } from '../../../../../../ui-components';

import editIcon from '../../../../../../assets/images/edit-black.svg';
import deleteIcon from '../../../../../../assets/images/trash-red.svg';
import { LauncherAssignee } from '../../../common/launcher-config.fragment';

const MenuData: IActionMenuData[] = [
  {
    name: 'Add new task above',
    value: 'add-above',
    normalIcon: 'fontAwesome',
    icon: faPlus,
  },
  {
    name: 'Add new task below',
    value: 'add-below',
    normalIcon: 'fontAwesome',
    icon: faPlus,
  },
  {
    name: 'Edit task',
    value: 'edit',
    icon: editIcon,
  },
  {
    name: 'Delete task',
    value: 'delete',
    icon: deleteIcon,
    textColor: '#FF6A55',
  },
];

interface IProps extends Partial<LauncherAssignee> {
  taskIndex: number;
  isLastTask?: boolean;
  actionHandler: (action: string) => void;
}

const LauncherTaskAction: FC<IProps> = ({
  taskIndex,
  isLastTask,
  actionHandler,
  assignedType,
  assignedUser = [],
}) => {
  return (
    <Flex flexDir='column' justify='space-between' gap={2}>
      <Flex className='more-action' gap='4px' visibility='hidden'>
        <IconButton
          size='xs'
          aria-label='move-up'
          variant='ghost'
          borderRadius='3px'
          icon={<ChevronUpIcon boxSize='20px' color='#6F767E' />}
          isDisabled={taskIndex === 0}
          onClick={() => actionHandler('move-up')}
        />

        <IconButton
          size='xs'
          aria-label='move-down'
          variant='ghost'
          borderRadius='3px'
          icon={<ChevronDownIcon boxSize='20px' color='#6F767E' />}
          isDisabled={isLastTask}
          onClick={() => actionHandler('move-down')}
        />

        <ActionMenu
          menuData={MenuData}
          clickedItem={(_, value) => actionHandler(value)}
        >
          <IconButton
            size='xs'
            aria-label='menu'
            variant='ghost'
            borderRadius='3px'
            icon={
              <FontAwesomeIcon
                icon={faEllipsisStrokeVertical as IconProp}
                size='lg'
                color='#6F767E'
              />
            }
          />
        </ActionMenu>
      </Flex>
      <TaskAssignee assignedType={assignedType!} assignedUser={assignedUser} />
    </Flex>
  );
};

export default LauncherTaskAction;
