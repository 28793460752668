import { gql } from '@apollo/client';
import { UserType } from 'types';
import { AuthRole } from 'authorization';

export interface ShareMemberEntity {
  eid: string;
  name: string;
  profilePic?: string;
  type: UserType;
  status: string;
  locations: Array<{
    eid: string;
    name: string;
  }>;
  address: {
    city: string;
    state: string;
  };
  role: string;
  authRole: AuthRole;
}

export interface ShareMemberResponse {
  EntityUser: ShareMemberEntity[];
}

export const SHARE_MEMBER_QUERY = gql`
  query ShareToMember(
    $status: [userStatusEnum]
    $entityId: String!
    $type: [userTypeEnum]
  ) {
    EntityUser(status: $status, entityId: $entityId, type: $type) {
      eid
      name
      profilePic
      type
      status
      locations {
        eid
        name
      }
      address {
        address
        city
        zipCode
        state
        country
        _id
      }
      role
      authRole
    }
  }
`;
