import React, { FC, useEffect } from 'react';
import { Center } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Loader } from 'sub-components';
import { AuditSummary, QuestionSectionContainer } from './components';
import { useControlLayer, useServiceLayer } from './layers';
import { IFormInput } from './types';
import { auditSchema } from './audit-validation';

const TakeAuditContainer: FC = () => {
  const service = useServiceLayer();
  const control = useControlLayer({ service });

  const methods = useForm<IFormInput>({
    resolver: yupResolver(auditSchema as never),
    defaultValues: {
      sectionData: [],
      sessionData: undefined,
    },
  });

  useEffect(() => {
    methods.setValue('sectionData', control?.categorizeQuestions);
    methods.setValue('maxSectionCount', control?.categorizeQuestions?.length);
    methods.setValue('sessionData', control?.sessionData);
    methods.setValue('lastUpdated', new Date().getTime());
  }, [control?.categorizeQuestions, control?.sessionData]);

  if (service.sessionDetails.sessionDetailResult.loading) {
    return (
      <Center h='89vh'>
        <Loader />
      </Center>
    );
  }

  return (
    <FormProvider {...methods}>
      <AuditSummary />
      <QuestionSectionContainer />
    </FormProvider>
  );
};

export default TakeAuditContainer;
