import React, { FC } from 'react';
import { Box, Flex, Spacer } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleExclamation,
  faStopwatch,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Trans, useTranslation } from 'react-i18next';

import { useSectionDuration } from '../../common';
import DurationTooltip from '../../common/DurationTooltip';

interface IProps {
  sectionIndex: number;
}

const SectionDuration: FC<IProps> = ({ sectionIndex }) => {
  const { t } = useTranslation(['launcher']);
  const duration = useSectionDuration(sectionIndex);

  if (!duration?.durationUnit) {
    return null;
  }

  return (
    <>
      <Flex gap='8px' align='center'>
        <FontAwesomeIcon
          icon={faStopwatch as IconProp}
          size='lg'
          color='#6F767E'
        />
        <Box fontSize='15px' fontWeight='600' color='#5A5A64' pt='2px'>
          {duration?.isExpected ? `Est: ` : null}
          <Trans
            t={t}
            i18nKey='launcher:configDuration'
            count={duration?.durationValue}
            context={duration?.durationUnit}
            values={{ value: duration?.durationValue }}
          />
        </Box>

        {duration?.isExpected ? (
          <DurationTooltip duration={duration}>
            <FontAwesomeIcon
              icon={faCircleExclamation as IconProp}
              color='#6F767E'
              fontSize='14px'
            />
          </DurationTooltip>
        ) : null}
      </Flex>
      <Spacer />
    </>
  );
};

SectionDuration.displayName =
  'sub-components/Launcher/launcher-config/board-view/sections/SectionDuration';

export default SectionDuration;
