import React, { FC } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  StackDivider,
  VStack,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faFlag } from '@fortawesome/pro-solid-svg-icons';
import {
  QuestionsState,
  SectionEntity,
} from '../../../../../../sub-components/audits/take-audit/types';
import { QuestionResponseSectionEntity } from '../types/audit-question.types';

const AccordionWrapper = styled.div`
  width: 100%;
  .chakra-accordion__item:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .chakra-accordion__item:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const IconsContainer = styled(Flex)`
  gap: 10px;
  align-items: center;
  flex-shrink: 0;
  min-width: 60px;
  justify-content: flex-end;
`;

interface IProps {
  questionData: SectionEntity[];
  selectedQuestionId: string;
  onQuestionSelect: (questionId: string) => void;
  questionSectionData?: QuestionResponseSectionEntity[];
}

/**
 * Checks if the given section or any of its children is selected.
 */
const isSectionSelected = (
  question: SectionEntity,
  selectedQuestionId: string
) => {
  if (question?.eid === selectedQuestionId) return true;
  if (question?.data?.some((child: any) => child.eid === selectedQuestionId))
    return true;
  return false;
};

const AccordionSection: FC<IProps> = ({
  questionData,
  selectedQuestionId,
  questionSectionData,
  onQuestionSelect,
}) => {
  const isQuestionFlagged = (questionId: string) => {
    const questionResponse = questionSectionData?.find(
      (section) => section.questionId === questionId
    );

    // Check if question has flagged responses
    const hasFlaggedResponses = questionResponse?.responsesByLocation?.some(
      (response) => response?.isFlagged
    );

    return hasFlaggedResponses;
  };

  const handleAccordionChange = (
    question: SectionEntity,
    isExpanded: boolean
  ) => {
    if (isExpanded && question?.data?.[0]?.eid) {
      onQuestionSelect(question.data[0].eid);
    } else if (isExpanded) {
      onQuestionSelect(question.eid);
    }
  };

  return (
    <AccordionWrapper>
      <Accordion allowToggle allowMultiple w='full'>
        {questionData?.map((question, index) => {
          const sectionSelected = isSectionSelected(
            question,
            selectedQuestionId
          );
          const headerTextColor = sectionSelected ? 'black' : 'gray';

          return (
            <AccordionItem bg='rgba(239, 239, 239, 1)' key={question.eid}>
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    onClick={() => handleAccordionChange(question, !isExpanded)}
                  >
                    <Flex w='full' align='flex-start'>
                      <Box
                        flex='1'
                        textAlign='left'
                        fontWeight={600}
                        color={headerTextColor}
                        wordBreak='break-word'
                      >
                        {`${index + 1}. ${question.sectionName}`}
                      </Box>
                      <IconsContainer>
                        {question.isSectionFlagged && (
                          <FontAwesomeIcon
                            icon={faFlag as IconProp}
                            color='rgba(255, 106, 85, 1)'
                            fontSize='14px'
                          />
                        )}
                        <AccordionIcon />
                      </IconsContainer>
                    </Flex>
                  </AccordionButton>
                  <AccordionPanel bg='white' py={3} px={4}>
                    <VStack divider={<StackDivider />}>
                      {question?.data?.map(
                        (child: QuestionsState, qIndex: number) => {
                          const childTextColor =
                            child.eid === selectedQuestionId ? 'black' : 'gray';
                          const isFlagged = isQuestionFlagged(child.eid);
                          return (
                            <Flex
                              w='full'
                              justify='space-between'
                              align='flex-start'
                              key={qIndex}
                              cursor='pointer'
                              onClick={() => onQuestionSelect(child.eid)}
                            >
                              <Box
                                color={childTextColor}
                                flex='1'
                                wordBreak='break-word'
                              >
                                {child.label}
                              </Box>
                              <Flex
                                gap='17px'
                                alignItems='center'
                                flexShrink={0}
                                minW='60px'
                                justify='flex-end'
                              >
                                {isFlagged && (
                                  <FontAwesomeIcon
                                    icon={faFlag as IconProp}
                                    color='rgba(255, 106, 85, 1)'
                                    fontSize='14px'
                                  />
                                )}
                                <FontAwesomeIcon
                                  icon={faArrowRight as IconProp}
                                  color={childTextColor}
                                  fontSize='14px'
                                />
                              </Flex>
                            </Flex>
                          );
                        }
                      )}
                    </VStack>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          );
        })}
      </Accordion>
    </AccordionWrapper>
  );
};

export default AccordionSection;
