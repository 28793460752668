import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DashHeader } from './DashHeader';
import { DashNavbar } from './DashNavbar';
import { HeaderColors } from '../../../configs';
import { LocationAttentionContainer } from './LocationAttention';
import { AllLocationTable } from './AllLoacationtable';
import { Box, Center } from '@chakra-ui/react';
import { EmptyLauncherTasks, SingleLocation } from './States';
import LiveModal from 'sub-components/LocationLaunchDashboard/LiveModal';
import { useLocationLoginDetailModal } from 'sub-components/LocationLaunchDashboard/LocationLoginDetails/useLocationLoginDetail';
import { useQuery } from '@apollo/client';
import Loader from 'sub-components/Loader';
import {
  calculateBusinessCompletionRate,
  calculateLocationStatus,
  transformLocationLaunchList,
  transformTabledata,
} from './helper';
import {
  LOCATION_LAUNCH_LIST,
  LocationLaunchListResponse,
  TASK_TYPES,
  TaskTypeArray,
} from './dashboard-grapghql';
import {
  LAUNCHER_LOCATION_DETAILS,
  LAUNCHER_SUPERVISORS,
} from 'pages/launcher/details/graphql/location-launch-details.graphql';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'sop-commons/src/client';
import { LauncherLocationDetail } from 'pages/launcher/details/types/location-launch-details.types';

interface IProps {}

const DashboardContainerConfig: FC<IProps> = () => {
  //location login detail for username and password to make a location live
  const locationLoginDetails = useLocationLoginDetailModal();

  //data fetching
  const {
    data: locationData,
    loading: locationLoading,
    refetch,
  } = useQuery<LocationLaunchListResponse>(LOCATION_LAUNCH_LIST, {
    fetchPolicy: 'network-only',
  });
  const { data: taskList } = useQuery<TaskTypeArray>(TASK_TYPES);

  // states
  const [launcherOneLocationAdded, setLauncherOneLocationAdded] =
    useState<boolean>(false);
  const [selected, setSelected] = useState<LauncherLocationDetail>();
  const [showLiveModal, setShowLiveModal] = useState<boolean>(false);

  //API data
  const locationsData = locationData?.LocationLaunchList ?? [];
  const taskListData = taskList?.EntityLauncherTaskCategories ?? [];
  const haveConfig = !!locationData?.BusinessLaunchers?.count;

  //live locations
  const liveLocations = locationsData.filter((location) => !location.isLive);

  //Live Modal refetch
  const refetchHandler = () => {
    refetch?.();
  };

  //live modal onclick functionality to check and make location live
  const onClick = useCallback(
    (locationId: string) => {
      let foundLocation: any = locationsData?.find(
        (value: any) => value.locationId === locationId
      );

      setSelected(foundLocation);
      if (!foundLocation?.location?.username) {
        locationLoginDetails({
          locationData: foundLocation,
          locationName: foundLocation?.location?.name,
          type: 'developmentToOpen',
          onClose: () => {},
          // afterSuccess: () => history.push(LOCATIONS_PAGE),
          afterSuccess: () => {},
          moveToLocationLiveModal: () => setShowLiveModal(true),
        });
      } else {
        setShowLiveModal(true);
      }
    },
    [locationsData]
  );

  //single location test case
  useEffect(() => {
    setLauncherOneLocationAdded(liveLocations.length === 1);
  }, [locationsData]);

  // Memoized calculation for location stats
  const locations = useMemo(() => {
    const { lagging, onTime } = calculateLocationStatus(locationsData);

    return [
      {
        name: 'Total Locations',
        color: HeaderColors.Orange,
        count: locationsData
          .filter((location) => !location.isLive)
          .length.toString(),
      },
      {
        name: 'Avg. completion rate',
        color: HeaderColors.Blue,
        count: `${calculateBusinessCompletionRate(locationsData)}%`,
      },
      {
        name: 'No. of locations on time',
        color: HeaderColors.Purple,
        count: onTime.toString(),
      },
      {
        name: 'No. of locations lagging',
        color: HeaderColors.Green,
        count: lagging.toString(),
      },
    ];
  }, [locationsData]);

  // console.log(locationsData);

  // Transform data for cards
  const cardsData = useMemo(
    () => transformLocationLaunchList(locationsData),
    [locationsData, liveLocations]
  );

  // console.log("cardsData", cardsData);

  //Transform data for table
  const tableData = useMemo(
    () => transformTabledata(locationsData),
    [locationsData]
  );

  // console.log(tableData);

  // Early return for loading state
  if (locationLoading) {
    return (
      <Center h='60vh'>
        <Loader />
      </Center>
    );
  }

  // Render appropriate content based on data
  const renderContent = () => {
    if (liveLocations.length === 0) {
      return <EmptyLauncherTasks haveConfig={haveConfig} />;
    }

    if (launcherOneLocationAdded) {
      return (
        <Box display={'flex'} flexDirection={'column'} gap={'20px'}>
          <SingleLocation cardData={liveLocations[0]} />
          <LocationAttentionContainer
            locMakeLiveHandler={onClick}
            cardsData={cardsData}
          />
        </Box>
      );
    }

    return (
      <>
        <LocationAttentionContainer
          locMakeLiveHandler={onClick}
          cardsData={cardsData}
        />
        <AllLocationTable
          tableData={tableData}
          taskTypeOptions={taskListData}
        />
      </>
    );
  };

  return (
    <>
      <DashHeader haveConfig={haveConfig} />
      {!launcherOneLocationAdded && (
        <DashNavbar
          locations={locations}
          launcherEmptyState={locationsData.length === 0}
        />
      )}
      {renderContent()}
      {selected?.locationId && showLiveModal && (
        <LiveModal
          isOpen={!!selected?.locationId}
          data={selected}
          refetchHandler={refetchHandler}
          onClose={() => {
            setSelected(undefined);
            setShowLiveModal(false);
          }}
        />
      )}
    </>
  );
};

export default DashboardContainerConfig;
