import React, { FC, useMemo } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { FormInput } from 'atoms';
import Dropdown, { SelectOption } from 'atoms/Dropdown';
import { BoxHeader } from 'ui-components';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import { IFormInput } from '../AddLocation/add-location.types';
import { EntityLabel, EntityTypes } from '../AddLocation/static-data';
import {
  CityStatesResponse,
  GET_CITY_STATES_OF_US,
} from '../../Teams/locations/Components/LocationSideDrawer/components/LocationForm/location-form.graphql';

const entityOptions: SelectOption[] = EntityTypes.map((value) => ({
  value: value,
  label: EntityLabel[value],
}));

interface IProps {
  isDisabled?: boolean;
}

const EntityDetails: FC<IProps> = ({ isDisabled }) => {
  const { t } = useTranslation(['location']);

  const { control } = useFormContext<IFormInput>();

  const { data } = useQuery<CityStatesResponse>(GET_CITY_STATES_OF_US);

  const cityStates = useMemo(() => {
    return Object.entries(data?.CityStates || {}).map(([value, city]) => {
      return {
        label: value,
        value: value,
      };
    });
  }, [data]);

  return (
    <Flex bg='white' borderRadius='8px' p={8} gap='20px' flexDir='column'>
      <BoxHeader
        fontSize='16px'
        color='#B5E4CA'
        title={t('location:entityDetails')}
      />

      <Grid templateColumns='repeat(3, 1fr)' gap='20px'>
        <Controller
          control={control}
          name='entityName'
          rules={{
            // required: {
            //   value: !isDisabled,
            //   message: t('location:validation.entityNameRequired'),
            // },
            validate: (value) => {
              if (value && value?.trim()?.length === 0) {
                return t('location:validation.entityNameInvalid');
              }
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl
                isDisabled={isDisabled}
                isInvalid={!!fieldState.error}
              >
                <TitleHeader title={t('location:entityName')} />

                <FormInput
                  mt={2}
                  size='lg'
                  variant='outline'
                  placeholder={t('location:placeholder.entityName')}
                  {...field}
                />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Box>
          <TitleHeader title={t('location:entityType')} />

          <Controller
            control={control}
            name='entityType'
            rules={
              {
                // required: {
                //   value: !isDisabled,
                //   message: t('location:validation.entityTypeRequired'),
                // },
              }
            }
            render={({ field, fieldState }) => {
              return (
                <FormControl mt={2} isInvalid={!!fieldState.error}>
                  <Dropdown
                    isDisabled={isDisabled}
                    placeholder={t('location:placeholder.entityType')}
                    size='lg'
                    options={entityOptions}
                    {...field}
                    selectStyles={{
                      container: {
                        background: 'transparent',
                      },
                    }}
                  />

                  <FormErrorMessage>
                    <span>{fieldState.error?.message}</span>
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />
        </Box>

        <Box>
          <TitleHeader title={t('location:countryOfFormation')} />
          <Controller
            control={control}
            name='countryOfFormation'
            rules={
              {
                // required: {
                //   value: !isDisabled,
                //   message: t('location:validation.countryOfFormationRequired'),
                // },
              }
            }
            render={({ field, fieldState }) => {
              return (
                <FormControl mt={2} isInvalid={!!fieldState.error}>
                  <Dropdown
                    isDisabled={isDisabled}
                    size='lg'
                    options={[
                      {
                        value: 'USA',
                        label: 'USA',
                      },
                    ]}
                    value={field.value}
                    onChange={field.onChange}
                    selectStyles={{
                      container: {
                        background: 'transparent',
                      },
                    }}
                  />

                  <FormErrorMessage>
                    <span>{fieldState.error?.message}</span>
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />
        </Box>

        <Box>
          <TitleHeader title={t('location:stateOfFormation')} />
          <Controller
            control={control}
            name='stateOfFormation'
            rules={
              {
                // required: {
                //   value: !isDisabled,
                //   message: t('location:validation.stateOfFormationRequired'),
                // },
              }
            }
            render={({ field, fieldState }) => {
              return (
                <FormControl mt={2} isInvalid={!!fieldState.error}>
                  <Dropdown
                    isDisabled={isDisabled}
                    size='lg'
                    options={cityStates}
                    value={field.value}
                    onChange={field.onChange}
                    selectStyles={{
                      container: {
                        background: 'transparent',
                      },
                    }}
                  />

                  <FormErrorMessage>
                    <span>{fieldState.error?.message}</span>
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />
        </Box>

        <Box>
          <TitleHeader title={t('location:taxPayerID')} />
          <Controller
            control={control}
            name='taxPayerId'
            rules={
              {
                // required: {
                //   value: !isDisabled,
                //   message: t('location:validation.stateOfFormationRequired'),
                // },
              }
            }
            render={({ field, fieldState }) => {
              return (
                <FormControl
                  isDisabled={isDisabled}
                  isInvalid={!!fieldState.error}
                >
                  <FormInput
                    mt={2}
                    size='lg'
                    placeholder='-'
                    variant='outline'
                    {...field}
                  />

                  <FormErrorMessage>
                    <span>{fieldState.error?.message}</span>
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />
        </Box>
      </Grid>
    </Flex>
  );
};

EntityDetails.displayName = 'p/LN/C/EntityDetails';

export default EntityDetails;
