import React, { FC } from 'react';
import DashboardContainer from 'sub-components/DashboardContainer';
import DashboardContainerConfig from 'sub-components/Launcher/dashboard/DashboardContainerConfig';

/**
 * The `LauncherDashboard` component represents the main dashboard of the location launcher.
 * This component also handles the modal functionality and filters the in-progress list based on selected criteria.
 *
 * @returns The rendered Launcher Dashboard component.
 */

const LauncherDashboard: FC = () => {
  return (
    <DashboardContainer>
      <DashboardContainerConfig />
    </DashboardContainer>
  );
};

export default React.memo(LauncherDashboard);
