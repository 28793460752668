import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { DurationTooltip, useSectionDuration } from '../../common';

interface IProps {
  sectionIndex: number;
}

const SectionDuration: FC<IProps> = ({ sectionIndex }) => {
  const { t } = useTranslation(['launcher']);
  const duration = useSectionDuration(sectionIndex);

  if (!duration?.durationUnit) {
    return null;
  }

  return (
    <DurationTooltip duration={duration}>
      <Flex
        fontSize='12px'
        fontWeight='500'
        color='#1A1D1F'
        border='2px solid #EFEFEF'
        borderRadius='8px'
        height='32px'
        paddingInline='10px'
        align='center'
        whiteSpace='nowrap'
      >
        {duration?.isExpected ? `Est: ` : null}
        <Trans
          t={t}
          i18nKey='launcher:configDuration'
          count={duration?.durationValue}
          context={duration?.durationUnit}
          values={{ value: duration?.durationValue }}
        />
      </Flex>
    </DurationTooltip>
  );
};

export default SectionDuration;
