import { Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { ISessionByIdForMyTasks } from 'pages/TasksDashboard/MyTasks/services/my-tasks-types';

interface IProps {
  data: ISessionByIdForMyTasks | undefined;
}

interface ILocationState {
  backToTitle: string;
}

const TitleHeading: FC<IProps> = ({ data }) => {
  const history = useHistory();
  const locState = useLocation<ILocationState>()?.state;
  const redirectHandler = () => {
    if (locState?.backToTitle) {
      history.goBack();
    } else {
      history.goBack();
    }
  };
  return (
    <Flex
      id='my-task-details-title-heading-1'
      gap='15px'
      cursor='pointer'
      w='fit-content'
      onClick={redirectHandler}
    >
      <FontAwesomeIcon
        icon={faArrowLeft as IconProp}
        size='2x'
        style={{ marginTop: '-1px' }}
      />
      <Flex id='my-task-details-title-heading-2' flexDir='column'>
        <Text
          id='my-task-details-title-heading-text-1'
          fontWeight={600}
          fontSize='24px'
        >
          {data?.SessionById?.title ||
            `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magnam, fuga!`}
        </Text>
        <Text
          id='my-task-details-title-heading-text-2'
          fontWeight={400}
          fontSize='15px'
          color='#6F767E'
        >
          {locState?.backToTitle || 'Back to tasks'}
        </Text>
      </Flex>
    </Flex>
  );
};

export default TitleHeading;
