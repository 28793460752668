import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { toArray } from '../../../../../utils';
import { useLauncherConfigContext } from '../../common';
import { LauncherTaskItem } from './launcher-tasks';

interface IProps {
  sectionIndex: number;
}

const SectionTasks: FC<IProps> = ({ sectionIndex }) => {
  const tasks = useLauncherConfigContext((state) => {
    return toArray(state.config.contents?.[sectionIndex]?.tasks).map(
      (t) => t.eid
    );
  });

  return (
    <Flex flexDir='column'>
      {tasks.map((_, taskIndex) => (
        <LauncherTaskItem
          sectionIndex={sectionIndex}
          taskIndex={taskIndex}
          key={taskIndex}
        />
      ))}
    </Flex>
  );
};

SectionTasks.displayName = 'sc/L/lc/ev/s/SectionTasks';

export default React.memo(SectionTasks);
