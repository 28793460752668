import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import BoardViewHeader from './BoardViewHeader';
import { BoardTaskForm } from './board-task-form';
import { BoardViewAction } from './board-view-actions';
import FooterButton from './FooterButton';
import SectionListWrapper from './SectionListWrapper';

interface IProps {}

const ConfigBoardView: FC<IProps> = () => {
  return (
    <Flex flex={1} flexDir='column'>
      <BoardViewHeader />

      <BoardViewAction />

      <SectionListWrapper />

      <BoardTaskForm />

      <FooterButton />
    </Flex>
  );
};

ConfigBoardView.displayName =
  'sub-components/Launcher/launcher-config/board-view/ConfigBoardView';

export default ConfigBoardView;
