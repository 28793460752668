import { InternalRefetchQueriesInclude, useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import {
  DUPLICATE_LAUNCHER_CONFIG,
  LauncherResponse,
} from './board-action.graphql';

interface IProps {
  commonConfig?: {
    awaitRefetchQueries?: boolean;
    refetchQueries?: InternalRefetchQueriesInclude;
  };
  onCompleted?: (data: LauncherResponse['CloneLauncher']) => void;
}

export const useDuplicateBoardMutation = (props?: IProps) => {
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  return useMutation<LauncherResponse>(DUPLICATE_LAUNCHER_CONFIG, {
    ...props?.commonConfig,
    onCompleted: (data) => {
      props?.onCompleted?.(data.CloneLauncher);
      toast({
        status: 'success',
        title: 'Success',
        description: 'Board successfully duplicated.',
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Error!',
        description: 'Failed to duplicate board. Please try again.',
      });
    },
  });
};
