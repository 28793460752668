import { Box, Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import {
  DashboardFiltersType,
  FilterKeyType,
} from 'sub-components/Launcher/tasks/TaskContainerConfig';
interface GroupByFilterProps {
  filters: DashboardFiltersType;
  handler: React.Dispatch<React.SetStateAction<FilterKeyType>>;
  initialFilter: FilterKeyType;
}
const GroupByFilter: FC<GroupByFilterProps> = ({
  filters,
  handler,
  initialFilter,
}) => {
  const [activeFilterKey, setActiveFilterKey] =
    React.useState<FilterKeyType>(initialFilter);
  const filterChipHandler = (selectedKey: FilterKeyType) => {
    setActiveFilterKey(selectedKey);
    handler(selectedKey);
  };
  return (
    <Flex mt={4} gap={2} cursor={'pointer'}>
      <Box p='8px 16px' gap='4px' borderRadius='8px' bg='#ffff' color='#6F767E'>
        <Text>Group by:</Text>
      </Box>
      {filters.map((filter) => (
        <Box
          key={filter.id}
          p='8px 16px'
          gap='4px'
          borderRadius='8px'
          bg={activeFilterKey === filter.filterKey ? '#ffff' : '#EFEFEF'}
          fontWeight={activeFilterKey === filter.filterKey ? 600 : 500}
          color={activeFilterKey === filter.filterKey ? 'black' : '#6F767E'}
          onClick={() => filterChipHandler(filter.filterKey)}
        >
          <Text>{filter.filterLabel}</Text>
        </Box>
      ))}
    </Flex>
  );
};

GroupByFilter.displayName =
  'displayName:sub-components/Launcher/dashboard/Filters/GroupByFilter';

export default GroupByFilter;
