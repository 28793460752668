import { Box, Text, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { ReactComponent as FlagFilledRedIcon } from 'assets/images/audits/FlagFilledRed.svg';

interface IProps {
  title?: string;
  textStyle?: any;
  headingHeight?: any;
  type?: 'web' | 'pdf';
  headingTextDesc?: any;
  headingColor?: any;
}

const defaultTextStyle = {
  fontWeight: '600',
  fontSize: '24px',
  paddingLeft: '1.5rem',
};

const defaultHeadingHeight = {
  paddingBottom: '30px',
};

const Heading: FC<IProps> = ({
  title,
  textStyle = defaultTextStyle,
  headingHeight = defaultHeadingHeight,
  type = 'web',
  headingTextDesc,
  headingColor,
}) => {
  const showNoHeadingBackground = [
    'Flagged Items',
    'Auditor Section',
    'Media Summary',
  ];
  return (
    <Box>
      {type === 'web' || type === 'pdf' ? (
        <Box>
          <Box style={headingHeight}></Box>
          <Flex
            // backgroundColor={
            //   !showNoHeadingBackground.includes(title) && '#2A85FF1A'
            // }
            justifyContent={type === 'pdf' ? 'space-between' : 'flex-start'}
            alignItems={'center'}
            py={type === 'pdf' ? '12px' : '12px'}
            gap={4}
          >
            {title === 'Flagged Items' && type === 'web' ? (
              <Flex gap={2} alignItems={'center'} paddingLeft={'1.5rem'}>
                <Box
                  borderRadius={'4px'}
                  height={'32px'}
                  width={'16px'}
                  backgroundColor={`#${headingColor ? headingColor : 'FFBC99'}`}
                ></Box>
                <Text style={{ fontWeight: '600', fontSize: '24px' }}>
                  {title}
                </Text>
                <FlagFilledRedIcon style={{ marginLeft: '10px' }} />
              </Flex>
            ) : (
              <Flex mx={'1.5rem'} alignItems={'center'}>
                <Box
                  borderRadius={'4px'}
                  height={'32px'}
                  width={'16px'}
                  backgroundColor={`#${headingColor ? headingColor : ''}`}
                ></Box>
                <Text style={textStyle}>{title}</Text>
              </Flex>
            )}
            <Text
              pr={'1.5rem'}
              color={'#6F767E'}
              fontWeight={600}
              fontSize={'18px'}
            >
              {title !== 'Flagged Items' && headingTextDesc
                ? headingTextDesc
                : ''}
            </Text>
          </Flex>
        </Box>
      ) : (
        <Flex paddingLeft={'1.5rem'} height={'35px'} alignItems={'center'}>
          <Text fontSize={'24px'} fontWeight={600}>
            {title}
          </Text>
        </Flex>
      )}
    </Box>
  );
};

export default Heading;
