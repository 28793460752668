import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  DefaultTriggerItem,
  NewCustomTriggerInfo,
  TriggerAlert,
  TriggerInput,
  TriggerOr,
} from '../trigger';
import DynamicActivationTrigger from '../config-task-form/task-trigger-setting/DynamicActivationTrigger';
import DynamicDeadlineTrigger from '../config-task-form/task-trigger-setting/DynamicDeadlineTrigger';
import SwitchToPhaseTrigger from './SwitchToPhaseTrigger';
import { LauncherTaskInput } from '../launcher-config.store';

interface IProps {
  tasks?: LauncherTaskInput[];
  triggerAssignment?: 'phase' | 'task';
}

const TriggerSetting: FC<IProps> = ({ tasks, triggerAssignment }) => {
  const { setValue } = useFormContext<TriggerInput>();
  const [value, isOpen] = useWatch<
    TriggerInput,
    ['trigger.triggerChoice', 'isOpen']
  >({
    name: ['trigger.triggerChoice', 'isOpen'],
  });

  const isDefault = !value || value === 'default';

  if (isDefault && !isOpen) {
    return (
      <Flex flexDir='column' gap='24px'>
        <DefaultTriggerItem
          label='Default Activation date'
          value='Project Start Date'
        />

        <DefaultTriggerItem label='Default Deadline' value='Go-live date' />

        {triggerAssignment === 'task' ? (
          <SwitchToPhaseTrigger
            tasks={tasks}
            onAddClick={() => setValue('isOpen', true)}
          />
        ) : (
          <NewCustomTriggerInfo
            type='phase'
            onAddClick={() => setValue('isOpen', true)}
          />
        )}
      </Flex>
    );
  }

  return (
    <Flex flexDir='column' gap={isDefault ? 3 : '20px'}>
      {isDefault ? <TriggerAlert type='phase' /> : null}
      <DynamicActivationTrigger type='phase' />
      {isDefault ? <TriggerOr type='phase' /> : null}
      <DynamicDeadlineTrigger type='phase' />
    </Flex>
  );
};

TriggerSetting.displayName = 'sc/L/lc/c/pd/TriggerSetting';

export default TriggerSetting;
