import React, { FC } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';

const pseudoStyles = {
  content: "''",
  height: '4px',
  background: '#B5E4CA',
  position: 'absolute',
  width: 'calc(50% - 24px)',
  top: '22px',
};

interface IProps {
  label: string;
  index: number | string;
  flex?: number;
}

const StepInfo: FC<IProps> = ({ label, index, ...rest }) => {
  return (
    <Flex
      flexDir='column'
      align='center'
      gap={2}
      position='relative'
      {...rest}
      _notFirst={{
        ':before': {
          ...pseudoStyles,
          left: 0,
        },
      }}
      _notLast={{
        ':after': {
          ...pseudoStyles,
          right: 0,
        },
      }}
    >
      <Center
        bg='#83BF6E'
        boxSize='48px'
        borderRadius='full'
        border='5px solid #B5E4CA'
        fontSize='18px'
        fontWeight={600}
        color='#FFFFFF'
      >
        {index}
      </Center>

      <Box fontSize='15px' fontWeight='500'>
        {label}
      </Box>
    </Flex>
  );
};

interface SpacerProps {}

const StepInfoSpacer: FC<SpacerProps> = () => {
  return <Flex />;
};

// @ts-ignore
StepInfo.Spacer = StepInfoSpacer;

export default StepInfo as typeof StepInfo & {
  Spacer: typeof StepInfoSpacer;
};
