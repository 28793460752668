import React, { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Authorize } from '../../authorization';
import { canAccessNexus } from '../../configs';
import { NotificationCenter, NotificationSettingsPage } from './index';
// import NexusDashboard from './NexusDashboard';
// import NexusFinanceBoard from './NexusFinanceBoard';
// import NexusCompliance from './Compliance/NexusCompliance';

const NotificationRoutes: FC = () => {
  let { path } = useRouteMatch();

  return (
    <Authorize renderNoAccess={() => <Redirect to='/' />}>
      <Switch>
        <Route path={path} component={NotificationCenter} />
      </Switch>
    </Authorize>
  );
};

export default NotificationRoutes;
