import React, { FC, useMemo } from 'react';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { SectionEntity } from '../../types';

interface IProps {
  section: SectionEntity;
  index: number;
  isSelected?: boolean;
  onClick: () => void;
}

const RightSectionCard: FC<IProps> = ({
  section,
  index,
  isSelected,
  onClick,
}) => {
  const completed = useMemo(() => {
    return section?.data?.filter((question) => question.isCompleted).length;
  }, [section?.data]);

  const isEqual = completed === section?.data?.length;

  return (
    <Flex
      flexDir='column'
      paddingX={4}
      paddingY={3}
      gap={1}
      borderRadius='10px'
      border='1px solid #EFEFEF'
      boxShadow='1px 1px 6px 0px #292D321A'
      aria-selected={isSelected}
      cursor='pointer'
      _selected={{
        bg: '#2A85FF0D',
        borderColor: '#2A85FF',
        cursor: 'initial',
      }}
      onClick={isSelected ? undefined : onClick}
    >
      <Flex color='#33383F' fontWeight='600' gap='3px'>
        <Box whiteSpace='nowrap' minW='16px'>
          {index + 1}.
        </Box>
        <Tooltip maxW='210px' label={section.sectionName}>
          <Box isTruncated>{section.sectionName}</Box>
        </Tooltip>
      </Flex>

      <Flex fontSize='12px' paddingLeft='19px' gap='8px'>
        <Box as='span' fontWeight='600' color={isEqual ? '#83BF6E' : '#1A1D1F'}>
          {completed}/{section.data?.length}
        </Box>
        <Box as='span' color='#6F767E' fontWeight='500'>
          Completed
        </Box>
      </Flex>
    </Flex>
  );
};

export default RightSectionCard;
