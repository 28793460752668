import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, SearchInputWithRef } from 'atoms';
import React, { FC, useMemo, useRef, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormTrigger,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectOption } from 'sub-components/CustomDropdowns';
import { InfoFormValues } from './InfoComponent/ProfileInfoForm';

interface IProps {
  memberList: SelectOption[];
  rolesList: SelectOption[];
  control: Control<InfoFormValues, any>;
  trigger: UseFormTrigger<InfoFormValues>;
  errors: FieldErrors<InfoFormValues>;
}

const TransferMemberList: FC<IProps> = ({
  memberList,
  rolesList,
  control,
  trigger,
  errors,
}) => {
  const { t } = useTranslation(['team', 'invite']);
  const [searchInput, setSearchInput] = useState('');
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const inputRef = useRef<HTMLInputElement>(null);
  const { watch } = useFormContext();

  const filteredMembers = useMemo(() => {
    return memberList.filter((member) => {
      const matchesSearch = member.label
        .toLowerCase()
        .includes(searchInput.toLowerCase());
      const matchesRole =
        selectedRoles.length === 0 || selectedRoles.includes(member.role);
      return matchesSearch && matchesRole;
    });
  }, [memberList, searchInput, selectedRoles]);

  const requestedBy = watch('requestedBy');

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement='bottom-start'
      initialFocusRef={inputRef}
    >
      <PopoverTrigger>
        <Button
          border={
            errors.requestedBy ? '1px solid red' : '1px solid transparent'
          }
          color='#6F767E'
          borderRadius='12px'
          display='flex'
          gap={2}
        >
          {requestedBy
            ? memberList.find((member) => member.value === requestedBy)?.label
            : 'Select new owner'}

          <FontAwesomeIcon
            icon={faChevronDown as IconProp}
            style={{
              transition: 'transform 0.3s ease',
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        </Button>
      </PopoverTrigger>

      <PopoverContent
        boxShadow='md'
        padding={3}
        borderRadius='12px'
        width='40rem'
        zIndex='1600'
      >
        <PopoverBody>
          <Flex gap={2}>
            <Box
              // workaround for input not getting focus after it is lost in the same window/context
              onClick={() => {
                inputRef?.current?.focus();
              }}
              width='100%'
            >
              <SearchInputWithRef
                hideShortcuts
                placeholder={t('team:searchMembers')}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                autoFocus
                ref={inputRef}
              />
            </Box>

            <Popover placement='bottom-end'>
              <PopoverTrigger>
                <Button>
                  <FontAwesomeIcon icon={faFilter as IconProp} />
                </Button>
              </PopoverTrigger>
              <PopoverContent
                boxShadow='md'
                padding={3}
                borderRadius='12px'
                width='15rem'
              >
                <CheckboxGroup
                  value={selectedRoles}
                  onChange={(roles) => {
                    setSelectedRoles(roles as string[]);
                    trigger('requestedBy');
                  }}
                >
                  <VStack align='start' spacing={2}>
                    {rolesList.map((role) => (
                      <Checkbox key={role.value} value={role.value}>
                        {role.label}
                      </Checkbox>
                    ))}
                  </VStack>
                </CheckboxGroup>
              </PopoverContent>
            </Popover>
          </Flex>
        </PopoverBody>

        {/* User List */}
        <PopoverBody p={0}>
          <Box maxHeight='300px' overflowY='auto'>
            <Controller
              name='requestedBy'
              control={control}
              defaultValue=''
              rules={{
                required: t('invite:validation.ownerRequired'),
              }}
              render={({ field, fieldState }) => (
                <FormControl isInvalid={!!fieldState.error}>
                  <RadioGroup
                    {...field}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                  >
                    <VStack align='start' p={3} spacing={3}>
                      {filteredMembers.map((member) => (
                        <Box
                          key={member.value}
                          display='flex'
                          gap={2}
                          borderRadius='12px'
                          p={2}
                          width='100%'
                          border='1px solid transparent'
                          _hover={{
                            borderColor: '#EFEFEF',
                          }}
                          _checked={{
                            borderColor: '#EFEFEF',
                          }}
                        >
                          <Radio value={member.value} gap={1}>
                            {member.label}
                          </Radio>
                        </Box>
                      ))}
                    </VStack>
                  </RadioGroup>
                  <FormErrorMessage>
                    <span>{fieldState.error?.message}</span>
                  </FormErrorMessage>
                </FormControl>
              )}
            />
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default TransferMemberList;
