import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { LauncherTaskItem } from '../sections/launcher-tasks';

export interface ICategoryTask {
  sectionIndex: number;
  taskIndex: number;
}

interface IProps {
  tasks: ICategoryTask[];
}

const CategoryTasks: FC<IProps> = ({ tasks }) => {
  return (
    <Flex flexDir='column' gap={3}>
      {tasks.map((task, index) => (
        <LauncherTaskItem
          sectionIndex={task.sectionIndex}
          taskIndex={task.taskIndex}
          key={index}
        />
      ))}
    </Flex>
  );
};

export default CategoryTasks;
