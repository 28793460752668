import { HeaderColors } from 'configs';
import React, { FC } from 'react';
import { BoxHeader } from 'ui-components';

const HeaderTitle: FC = () => {
  return (
    <BoxHeader
      fontSize='16px'
      color={HeaderColors.Purple}
      title={'Phases to be completed'}
    />
  );
};

HeaderTitle.displayName = 'p/LN/C/PDP/c/HeaderTitle';

export default HeaderTitle;
