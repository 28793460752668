import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { toArray } from '../../../../../utils';
import { useLauncherConfigContext } from '../../common';

import { useConfigCategoryList } from '../../common/config-task-form';
import BoardCategoryItem from './BoardCategoryItem';
import { ICategoryTask } from './CategoryTasks';

interface IProps {}

const BoardByCategoryLists: FC<IProps> = () => {
  const { categories } = useConfigCategoryList();

  const contents = useLauncherConfigContext((state) => {
    return toArray(state.config.contents)
      .slice()
      .reduce((acc, value, sectionIndex) => {
        for (let taskIndex = 0; taskIndex < value.tasks.length; taskIndex++) {
          const task = value.tasks[taskIndex];
          if (acc[task.category]) {
            acc[task.category].push({
              sectionIndex: sectionIndex,
              taskIndex: taskIndex,
            });
          } else {
            acc[task.category] = [
              { sectionIndex: sectionIndex, taskIndex: taskIndex },
            ];
          }
        }

        return acc;
      }, {} as Record<string, ICategoryTask[]>);
  });

  return (
    <Flex flex={1} gap={5} overflow='auto' id='board-view-sections'>
      {categories.map((category, index) => {
        return (
          <BoardCategoryItem
            key={index}
            category={category}
            data={contents[category.value]}
          />
        );
      })}
    </Flex>
  );
};

export default BoardByCategoryLists;
