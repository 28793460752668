import React, { FC, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { triggerKey } from '../../trigger';
import BaseTriggerInfo from '../../trigger/BaseTriggerInfo';
import { useLauncherAlert } from '../../useLauncherAlert';
import { LauncherTrigger } from '../../launcher-config.fragment';
import { useConfigPhaseOptions } from '../components';

interface IProps extends LauncherTrigger {
  onAddClick?: () => void;
}

const SwitchToTaskTrigger: FC<IProps> = ({ trigger, onAddClick }) => {
  const { t } = useTranslation('launcher');
  const changeAlert = useLauncherAlert();
  const phaseList = useConfigPhaseOptions();

  const [phaseName, completed] = useMemo(() => {
    if (trigger?.triggerChoice === 'activation' && trigger?.activationTrigger) {
      if (triggerKey[trigger?.activationTrigger?.dependentOn]) {
        return [triggerKey[trigger?.activationTrigger?.dependentOn], false];
      }

      for (let i = 0; i < phaseList?.length; i++) {
        if (phaseList[i].value === trigger?.activationTrigger?.itemId) {
          return [`Phase ${i + 1}: ${phaseList[i].label}`, true];
        }
      }

      return [''];
    }
    return ['Project start date', false];
  }, [trigger, phaseList]);

  return (
    <BaseTriggerInfo
      title={t('triggerIntro.customActOrDeadTrigger')}
      buttonText={t('triggerIntro.switchToTaskTrigger')}
      data={[
        {
          title: 'This phase already has a trigger applied',
          caption: `This task will be unlocked after “${phaseName}”${
            completed ? ' is completed.' : '.'
          }`,
        },
        {
          title: 'You can switch from phase to task based triggers',
          caption:
            'If a trigger is added to this task, the phase trigger will be removed, and all other tasks in this phase will retain their previous phase trigger settings.',
        },
      ]}
      onClick={() => {
        changeAlert({
          description: (
            <Box>
              Switching to task triggers will{' '}
              <strong>erase previously assigned phase triggers</strong> and all
              other tasks in the phase will be{' '}
              <strong>assigned task based triggers</strong> as well.
            </Box>
          ),
          buttonText: 'Erase phase triggers and set task triggers',
          onConfirm: onAddClick,
        });
      }}
    >
      <Flex
        flexDir='column'
        padding='18px 20px'
        bg='#FBFAFF'
        borderRadius='12px'
        fontSize='12px'
        fontWeight='600'
      >
        <Box color='#33383F'>You can either set phase or task triggers</Box>
        <Box color='#6F767E'>
          Since this phase already has a trigger, this task will be unlocked as
          per the phase trigger
        </Box>
      </Flex>
    </BaseTriggerInfo>
  );
};

export default SwitchToTaskTrigger;
