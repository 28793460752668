// @ts-ignore
import React, { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Image,
  Text,
} from '@chakra-ui/react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  authTokenVar,
  UserData,
  userObj,
} from 'sop-commons/src/client/clientFactory';
import './Login.scss';

import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Trans, useTranslation } from 'react-i18next';

import AuthContainer from '../../sub-components/AuthContainer';
import PasswordInput from '../../atoms/PasswordInput';
import FormInput from '../../atoms/FormInput';
import PrimaryButton from '../../atoms/PrimaryButton';
import ResponsiveModal from 'ui-components/ResponsiveModal';
import { GET_USER, SIGN_IN } from './login.graphql';
import { roleObj } from '../../ui-components/DashboardMenu';
import { useCustomLoginPage } from '../../hooks';
import useWindowResize from '../../ui-components/Members/useWindowResize/useWindowResize';
import { deployEvent, AmplitudeEvent } from '../../shared';
import {
  AmplitudeEventNames,
  EventPropertyType,
} from '../../shared/amplitudeEvents/amplitude-events-types';

interface InputData {
  email: string;
  password: string;
}

interface SignInResponse {
  signIn: {
    accessToken: string;
    refreshToken: string;
  };
}

export const Login: FC = () => {
  const { t } = useTranslation(['common', 'auth']);
  const history = useHistory();
  const { currentLabelData, currentLogo, isDefaultDomainUrl } =
    useCustomLoginPage();
  const resize = useWindowResize();

  const [updateAccessToken, setUpdateAccessToken] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showWorkerModal, setShowWorkerModal] = useState(false);
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, dirtyFields, isDirty },
    getValues,
  } = useForm({
    defaultValues: {
      email:
        localStorage.getItem('delightree-email') &&
        localStorage.getItem('delightree-email') !== 'null'
          ? localStorage.getItem('delightree-email')
          : '',
      password: '',
    },
  });

  useEffect(() => {
    deployEvent(AmplitudeEventNames.LOG_IN_PAGE_VISIT, {
      log_in_funnel_id: 2,
    });
  }, []);

  const [getUser] = useLazyQuery(GET_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data: { user: UserData }) => {
      if (data.user.status === 'active' || data?.user?.entity?.isActive) {
        setIsLoading(false);
        userObj(data?.user || {});
        const baseProperties = {
          user_id: data?.user?.eid,
          company_id: data?.user?.entityId,
          user_persona: data?.user?.authRole,
          location_name:
            data?.user?.locations?.map((loc) => loc?.name)?.join(', ') || '',
          business_name: data?.user?.entity?.name,
        };
        deployEvent(AmplitudeEventNames.USER_LOGS_IN, {
          super: baseProperties,
          event: {
            ...baseProperties,
            log_in_funnel_id: 2,
          },
          profile: baseProperties,
        });
        const roles = data?.user?.entity?.roles;
        if (roles && roles.length) {
          let roleWithColor: any = {};
          roles.forEach((role: any) => {
            roleWithColor[role.name] = role.color;
          });

          roleObj(roleWithColor);
        }
        if (updateAccessToken) {
          authTokenVar(localStorage.getItem('authToken'));
        }
        setTimeout(() => {
          history.push('/');
        }, 300);
        // if (data?.user?.authRole?.toLowerCase() !== WORKER) {
        // } else {
        //   setIsLoading(false);
        //   setShowWorkerModal(true);
        //   localStorage.removeItem('authToken');
        //   localStorage.removeItem('refreshAuthToken');
        // }
      } else {
        setIsLoading(false);
        setError({
          message: t('auth:contact_admin'),
        });
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshAuthToken');
      }
    },
  });

  const [signIn] = useMutation<SignInResponse, InputData>(SIGN_IN, {
    onCompleted: (data) => {
      localStorage.setItem('authToken', data?.signIn?.accessToken);
      localStorage.setItem('refreshAuthToken', data?.signIn?.refreshToken);
      setUpdateAccessToken(true);
      getUser({
        variables: {},
        context: {
          headers: {
            authorization: `Bearer ${data?.signIn?.accessToken}`,
          },
        },
      });
    },
    onError: (error) => {
      setIsLoading(false);
      let errObj: {
        password: { message: string; flag: boolean };
        email: { message: string; flag: boolean };
      } = {
        password: {
          message: '',
          flag: false,
        },
        email: {
          message: '',
          flag: false,
        },
      };
      if (error?.message?.toLowerCase()?.includes('not found')) {
        errObj.password.message = error?.message;
        errObj.password.flag = true;
        errObj.email.message = error?.message;
        errObj.email.flag = true;
      }
      if (error?.message?.toLowerCase()?.includes('password')) {
        errObj.password.message = error?.message;
        errObj.password.flag = true;
      }
      if (
        error?.message?.toLowerCase()?.includes('email') ||
        error?.message?.toLowerCase()?.includes('username')
      ) {
        errObj.email.message = error?.message;
        errObj.email.flag = true;
      }
      setError({ ...error, ...errObj });
    },
  });

  const [error, setError] = useState<any>();

  const onFinish = (values: any) => {
    setError(null);

    setIsLoading(true);
    signIn({
      variables: {
        email: values.email,
        password: values.password,
      },
    }).then((response) => {
      if (isRememberMeChecked) {
        localStorage.setItem('delightree-email', values.email);
      }
    });
  };

  const getErrorMessage = () => {
    if (error?.message) {
      if (error?.message === 'You have already authorized.') {
        return (
          <Trans
            t={t}
            i18nKey='auth:already_authorized'
            components={[<a key='reload' onClick={() => location.reload()} />]}
          />
        );
      }

      return error?.message;
    }
    return '';
  };

  const getRightIcon = () => {
    if (isDirty) {
      if (errors?.email?.message || error?.email?.flag) {
        return (
          <FontAwesomeIcon
            icon={faTriangleExclamation as IconProp}
            color={'#ff6a55'}
            style={{ cursor: 'pointer' }}
          />
        );
      } else {
        return (
          <FontAwesomeIcon
            icon={faCheck as IconProp}
            color={'#83BF6E'}
            style={{ cursor: 'pointer' }}
          />
        );
      }
    }
  };

  const onClose = () => {
    setShowWorkerModal(false);
  };

  return (
    <AuthContainer>
      <div>
        {/* <div className='login-heading-container'>{t('auth:sign_in')}</div> */}
        <div className='login-heading-container'>{currentLabelData?.title}</div>
        {!isDefaultDomainUrl && resize?.[0] >= 1200 && (
          <Flex align='center' justify='center' my={4}>
            <Link to='/'>
              <Image src={currentLogo} w='145px' ml='2px' alt='delightree' />
            </Link>
          </Flex>
        )}
        {currentLabelData?.description && (
          <div className='login-sub-heading-container'>
            {/* {t('auth:login_delightree')} */}
            {currentLabelData?.description}
          </div>
        )}
        <form
          className='login-form-container'
          onSubmit={handleSubmit(onFinish)}
        >
          <Controller
            name={'email'}
            control={control}
            rules={{
              required: t('auth:validation.username_required'),
              // pattern: {
              //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              //   message: 'Invalid email address',
              // },
            }}
            render={({ field, fieldState }) => {
              return (
                <FormControl
                  isInvalid={!!fieldState.error}
                  // isInvalid={!!errors?.email?.message || error?.email?.flag}
                >
                  <FormInput
                    size='lg'
                    leftIcon={faEnvelope as IconProp}
                    id='email'
                    type='text'
                    placeholder={t('auth:your_email_username')}
                    {...field}
                    spellCheck={false}
                    rightIcon={getRightIcon()}
                  />

                  <FormErrorMessage>
                    {fieldState?.error?.message}
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />

          <Controller
            name={'password'}
            control={control}
            rules={{
              required: t('validation.password_required'),
              minLength: {
                value: 6,
                message: t('validation.password_min_six'),
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <FormControl mt={3} isInvalid={!!fieldState.error}>
                  <PasswordInput
                    id='login-password'
                    leftIcon={true}
                    rightIcon={true}
                    placeholder={t('auth:password')}
                    formProps={{ ...field }}
                    error={!!fieldState.error}
                  />

                  <FormErrorMessage>
                    {fieldState?.error?.message}
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />

          <div className='login-remember-forgot-container'>
            <div style={{ flex: '1 1 auto' }}>
              <Checkbox
                size='md'
                className='login-check-box-container'
                onChange={(e) => setIsRememberMeChecked(e.target.checked)}
              >
                {t('auth:remember_me')}
              </Checkbox>
            </div>
            <Link to='/forgot-password'>
              <Text size='sm' className='login-forgot-text-container'>
                {t('auth:forgot_password')}
              </Text>
            </Link>
          </div>
          <PrimaryButton
            mt={4}
            type='submit'
            size='lg'
            isLoading={isLoading}
            disabled={!getValues().email || !getValues().password}
            title={t('auth:sign_in_btn')}
            variant='solid'
            colorScheme='blue'
          />

          <div className='login-error-message'>{getErrorMessage()}</div>
        </form>
        {showWorkerModal && (
          <ResponsiveModal onClose={onClose} isOpen={showWorkerModal} />
        )}
      </div>
      <div className='invite-already-member' />
    </AuthContainer>
  );
};
