import React, { FC } from 'react';
import { FlexProps } from '@chakra-ui/react';
import { useLauncherConfigContext } from '../context';
import { useTaskBeforeSave } from '../useTaskBeforeSave';
import ConfigTaskForm from './ConfigTaskForm';

interface IProps {
  bg?: FlexProps['bg'];
}

const EditorConfigTaskForm: FC<IProps> = ({ bg }) => {
  const taskForm = useLauncherConfigContext((state) => state.taskForm);
  const action = useLauncherConfigContext((state) => ({
    closeTaskForm: state.closeTaskForm,
    saveTask: state.saveTask,
  }));
  const beforeSave = useTaskBeforeSave();

  return (
    <ConfigTaskForm
      bg={bg}
      sectionIndex={taskForm?.sectionIndex!}
      taskIndex={taskForm?.taskIndex!}
      defaultValues={
        {
          ...(taskForm?.task || {}),
          phaseEid: taskForm?.phase?.eid,
          phase: taskForm?.phase,
        } as never
      }
      beforeSave={beforeSave}
      onSave={action.saveTask}
      phaseSelectDisabled={!!taskForm?.phase?.eid}
    />
  );
};

export default EditorConfigTaskForm;
