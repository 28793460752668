import React, { FC } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../atoms';
import TriggerInfoItem, { TInfoItem } from './TriggerInfoItem';

import { ReactComponent as LightIcon } from '../../../../../assets/images/lighting.svg';

interface IProps {
  title: string;
  onClick?: () => void;
  buttonText: string;
  data: TInfoItem[];
}

const BaseTriggerInfo: FC<IProps> = ({
  title,
  onClick,
  buttonText,
  data = [],
  children,
}) => {
  const { t } = useTranslation('launcher');

  return (
    <Flex
      flexDir='column'
      gap={4}
      padding={4}
      bg='#CABDFF2E'
      borderRadius='12px'
    >
      <Flex gap={4} align='center'>
        <LightIcon width='34' height='40' />

        <Box flex={1} fontSize='16px' fontWeight='600' color='#272B30'>
          {title}
        </Box>

        <Center
          padding='2px 8px'
          bg='#8E59FF'
          fontSize='12px'
          fontWeight='600'
          color='white'
          borderRadius='6px'
        >
          {t('triggerIntro.introNew')}
        </Center>
      </Flex>

      {children}

      <Flex
        gap={3}
        flexDir='column'
        padding='18px 20px'
        bg='#FBFAFF'
        borderRadius='12px'
      >
        <Box fontSize='12px' fontWeight='500' color='#6F767E'>
          {t('triggerIntro.whatDoesThisMean')}
        </Box>

        {data.map((item, i) => (
          <TriggerInfoItem key={i} title={item.title} caption={item.caption} />
        ))}
      </Flex>

      <Button
        variant='link'
        height='40px'
        bgColor='#FFFFFF'
        borderColor='#EFEFEF'
        colorScheme='blue'
        fontSize='13px'
        borderRadius='12px'
        _hover={{}}
        leftIcon={<AddIcon />}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </Flex>
  );
};

BaseTriggerInfo.displayName = 'sc/L/lc/c/t/BaseTriggerInfo';

export default BaseTriggerInfo;
