import React, { FC, ReactNode, useCallback, useRef } from 'react';
import {
  AlertDialogCloseButton,
  Box,
  ButtonProps,
  Flex,
  Stack,
} from '@chakra-ui/react';

import { BoxHeader, UseConfirm, useConfirm } from '../../../../ui-components';
import { useSchedulerInfo } from '../Scheduler/useSchedulerInfo';

export const TaskDeleteContent: FC = () => {
  return (
    <Box
      bg='rgba(177, 229, 252, 0.4)'
      p='20px'
      borderRadius='10px'
      color='#33383F'
      lineHeight='26px'
    >
      <Box fontWeight='400'>
        This action will permanently delete the task from the organization! If
        you only want to remove it temporarily, you can terminate it instead.
      </Box>
      <Box fontWeight='700'>Are you sure you want to delete this task?</Box>
    </Box>
  );
};
export interface ITaskTerminateContent {
  customDesc?: string;
}
export const TaskTerminateContent: FC<ITaskTerminateContent> = ({
  customDesc = `This action will not delete the task completely from the organization!
  You will be able to restart the terminated task.`,
}) => {
  return (
    <Box
      bg='rgba(177, 229, 252, 0.4)'
      p='20px'
      borderRadius='10px'
      color='#33383F'
      lineHeight='26px'
    >
      <Box
        fontWeight='400'
        dangerouslySetInnerHTML={{ __html: customDesc }}
      ></Box>
      <Box fontWeight='700'>Are you sure you want to terminate this task?</Box>
    </Box>
  );
};
export interface ITaskRestartContent {
  taskSettings: Array<Record<string, string>>;
}
export const TaskRestartContent: FC<ITaskRestartContent> = ({
  taskSettings,
}) => {
  return (
    <Box
      bg='rgba(177, 229, 252, 0.4)'
      p='20px'
      borderRadius='10px'
      color='#33383F'
      lineHeight='26px'
    >
      <Box fontWeight='700'>
        Are you sure you want to restart this task with the following settings?
      </Box>
      <Stack></Stack>
    </Box>
  );
};

interface IProps extends Pick<ButtonProps, 'colorScheme'> {
  title: string;
  content: ReactNode;
  buttonTitle: string;
  onClick: (...args: any[]) => any | PromiseLike<any>;
  isFullWidth?: boolean;
  cancelText?: string;
  cancelButtonProps?: Omit<ButtonProps, 'onClick'>;
  okButtonProps?: Omit<ButtonProps, 'onClick'>;
  onCancel?: (...args: any[]) => any | PromiseLike<any>;
}

type TaskActionModal = (props: IProps) => void;

export const useTaskActionModal = (): TaskActionModal => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  return useCallback(
    ({
      title,
      content,
      buttonTitle,
      colorScheme,
      isFullWidth = false,
      onClick,
      cancelText = null,
      cancelButtonProps,
      okButtonProps = {
        size: 'lg',
        colorScheme: colorScheme,
        minWidth: '180px',
        fontSize: '15px',
        isFullWidth: isFullWidth,
      },
      onCancel,
    }) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }

      confirmRef.current = confirm({
        title: (
          <Flex gap='12px'>
            <Box flex={1}>
              <BoxHeader title={title} color='#CABDFF' />
            </Box>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        content: content,
        size: '3xl',
        isCentered: true,
        contentProps: {
          py: '12px',
          px: '8px',
          borderRadius: '16px',
        },
        okButtonProps: okButtonProps,
        cancelButtonProps: cancelButtonProps,
        okText: buttonTitle,
        onOK: onClick,
        onCancel,
        cancelText: cancelText,
      });
    },
    []
  );
};
