import React, { useMemo } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';

import { ColumnsType, SorterResult } from 'atoms/Table';
import { ActionMenu } from 'ui-components';
import { Any } from 'types';
import { useUserDataSelector } from '../../../../hooks';
import { AuthRole } from '../../../../authorization';

import DarkEditIcon from '../../../../assets/images/editIcon.svg';
import CalendarIcon from '../../../../assets/images/calendar.svg';
import CopyIcon from '../../../../assets/images/copy.svg';
import ArchiveIcon from '../../../../assets/images/archive.svg';

import { AuditTemplateItem } from '../query/audits-listing.graphql';
import { stringToColor } from '../common/helpers';

interface IProps<T> {
  actionHandler?: (action: string, value: T) => void | Promise<void | Any>;
  sorter?: SorterResult<T>;
}

export const useMyTemplateColumns = (
  props?: IProps<AuditTemplateItem>
): ColumnsType<AuditTemplateItem> => {
  const { actionHandler } = props || {};

  const authRole = useUserDataSelector((state) => state?.authRole);

  const menuData = useMemo(() => {
    const _menu = [
      {
        name: 'Edit',
        value: 'edit',
        icon: DarkEditIcon,
      },
    ];

    if (authRole === AuthRole.SUPER_ADMIN) {
      _menu.push({
        name: 'Schedule',
        value: 'schedule',
        icon: CalendarIcon,
      });
    }

    return _menu.concat([
      {
        name: 'Duplicate Draft',
        value: 'duplicateTemplate',
        icon: CopyIcon,
      },
      {
        name: 'Archive Draft',
        value: 'archiveTemplate',
        icon: ArchiveIcon,
      },
    ]);
  }, [authRole]);

  return useMemo((): ColumnsType<AuditTemplateItem> => {
    return [
      {
        title: 'Template Name',
        dataIndex: 'title',
        width: '35%',
        render: (value) => {
          return <Box noOfLines={1}>{value}</Box>;
        },
      },
      {
        title: 'Template Type',
        dataIndex: 'category',
        key: 'category',
        sorter: true,
        render: (category) => (
          <Center
            borderRadius='6px'
            fontSize='14px'
            fontWeight='500'
            maxW='max-content'
            padding='4px 10px'
            bg={stringToColor(category?.eid)}
          >
            {category?.name || 'Not Defined'}
          </Center>
        ),
      },
      {
        title: 'Created on',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        render: (createdAt) => (
          <Flex fontWeight={500} whiteSpace='nowrap'>
            {moment(createdAt).format('DD MMM YYYY')}
          </Flex>
        ),
      },
      {
        title: 'Last Activity',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        sorter: true,
        render: (updatedAt) => {
          const _moment = moment(updatedAt);
          return (
            <Flex gap={2} align='center' whiteSpace='nowrap'>
              <Flex fontWeight={500}>{_moment.format('DD MMM YYYY')}</Flex>
              <Flex fontSize='12px' fontWeight={500} color='#6F767E'>
                {_moment.format('hh:mm A')}
              </Flex>
            </Flex>
          );
        },
      },
      {
        dataIndex: 'action',
        align: 'center',
        render: (__, data) => (
          <Center>
            <ActionMenu
              menuData={menuData}
              arrowSize={10}
              closeOnBlur={true}
              offset={[24, 8]}
              clickedItem={(_, clickedItem) => {
                return actionHandler?.(clickedItem, data);
              }}
              strategy={'fixed'}
            >
              <Box w='min-content' px={1}>
                <FontAwesomeIcon
                  cursor={'pointer'}
                  fontSize={'1.2rem'}
                  icon={faEllipsis as IconProp}
                />
              </Box>
            </ActionMenu>
          </Center>
        ),
      },
    ];
  }, [actionHandler]);
};
