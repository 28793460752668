import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { HeaderColors } from '../../../../../configs';
import {
  BoxHeader,
  UseConfirm,
  useConfirm,
} from '../../../../../ui-components';

import { LauncherAssignee } from '../../common';
import AddAssigneeContent from './AddAssigneeContent';

interface IProps {
  value?: LauncherAssignee;
  onChange?: (value: LauncherAssignee) => void;
}

export const useAddAssignee = () => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  const confirm = useConfirm();

  return useCallback((props: IProps) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      title: (
        <Flex justify='space-between' paddingLeft={2}>
          <BoxHeader
            title='Select assignee(s)'
            fontSize='18px'
            color={HeaderColors.Purple}
            boxWidth={3}
          />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),

      content: <AddAssigneeContent {...props} />,
      isCentered: true,
      contentProps: {
        paddingTop: '8px',
        paddingBottom: '12px',
        minW: '680px',
      },
      footer: null,
    });
  }, []);
};
