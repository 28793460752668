import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GET_NOTIFICATION_HISTORY,
  MARK_ALL_READ,
} from '../notiifications.graphql';
import {
  IFilters,
  INotificationResponse,
  useServiceType,
} from '../types/service-types';

// export const useService: useServiceType = (filterOptions) => {
//   const {
//     data: notificationHistoryResult,
//     loading,
//     error,
//   } = useQuery<INotificationResponse, never>(GET_NOTIFICATION_HISTORY, {
//     fetchPolicy: 'network-only',
//     variables: filterOptions,
//     onCompleted: (data) => {
//       //   console.log('notification history --- ', data);
//     },
//     onError: (err) => {
//       console.log('error while fetching notification history --- ', err);
//     },
//   });

//   return {
//     notificationHistoryDetails: {
//       notificationHistoryResult:
//         notificationHistoryResult?.Notifications?.items,
//       notificationsCount: notificationHistoryResult?.Notifications?.count,
//       loading,
//     },
//   };
// };
export const useService: useServiceType = () => {
  const [fetchNotificationHistory, { data, loading, error }] = useLazyQuery<
    INotificationResponse,
    IFilters
  >(GET_NOTIFICATION_HISTORY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      console.log('Notification history fetched:', data);
    },
    onError: (err) => {
      console.log('Error while fetching notification history:', err);
    },
  });

  const [MarkAllNotificationSeen] = useMutation(MARK_ALL_READ);

  return {
    fetchNotificationHistory,
    notificationHistoryDetails: {
      notificationHistoryResult: data?.Notifications?.items,
      notificationsCount: data?.Notifications?.count,
      loading,
    },
    MarkAllNotificationSeen,
  };
};
