import { ApolloError, useLazyQuery } from '@apollo/client';
import {
  LAUNCHER_LOCATION_BY_ID,
  LocationLaunchByIdResponse,
} from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import {
  GET_LAUNCHING_LOCATION_DETAILS_BY_LAUNCHER_ID,
  LOCATION_LAUNCH_TASKS_V2,
} from '../api';
import {
  LaunchingLocationDetailsByLauncherIdResponse,
  LaunchingLocationDetailsByLauncherIdVariables,
  LocationLaunchTasksResponseV2,
  LocationLaunchTasksVariablesV2,
} from '../types';

export const useService = (
  onLaunchTaskSuccess?: (data: LocationLaunchTasksResponseV2) => void,
  onLaunchTaskError?: (error: ApolloError) => void,
  onLocDetailsByLauncherIdSuccess?: (
    data: LaunchingLocationDetailsByLauncherIdResponse
  ) => void,
  onLocDetailsByLauncherIdError?: (error: ApolloError) => void
) => {
  const [getLocationLaunchTasksV2, locationLaunchTasksV2Data] = useLazyQuery<
    LocationLaunchTasksResponseV2,
    LocationLaunchTasksVariablesV2
  >(LOCATION_LAUNCH_TASKS_V2, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => onLaunchTaskSuccess?.(data),
    onError: (error) => onLaunchTaskError?.(error),
  });

  const [getLocDetailsByLauncherId, locDetailsByLauncherIdData] = useLazyQuery<
    LaunchingLocationDetailsByLauncherIdResponse,
    LaunchingLocationDetailsByLauncherIdVariables
  >(GET_LAUNCHING_LOCATION_DETAILS_BY_LAUNCHER_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => onLocDetailsByLauncherIdSuccess?.(data),
    onError: (error) => onLocDetailsByLauncherIdError?.(error),
  });

  const [getLauncherLocationById, launcherLocationByIdData] = useLazyQuery<
    LocationLaunchByIdResponse,
    { eid: string }
  >(LAUNCHER_LOCATION_BY_ID, {
    fetchPolicy: 'network-only',
  });

  return {
    locationLaunchTasksV2: {
      getLocationLaunchTasksV2,
      locationLaunchTasksV2Data,
    },
    locationDetailsByLauncherId: {
      getLocDetailsByLauncherId,
      locDetailsByLauncherIdData,
    },
    launcherLocationById: {
      getLauncherLocationById,
      launcherLocationByIdData,
    },
  };
};
