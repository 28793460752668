import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { cloneDeep } from '@apollo/client/utilities';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Authorize, AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import { ActionButton } from 'ui-components';

import {
  Address,
  BasicDetails,
  EntityDetails,
  OtherDetails,
} from '../Components';
import UpdateLoginDetails from './UpdateLoginDetails';

import { IFormInput } from '../AddLocation/add-location.types';
import { addLocationFormat } from './formatSubmitData';
import {
  UPDATE_LOCATION_QUERY,
  UpdateLocationVariable,
} from './update-location.graphql';
import { getDefaultFormData } from '../AddLocation/default-form-data';
import AddLocationHeader from '../AddLocation/AddLocationHeader';
import DeleteLocation from './DeleteLocation';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import { getTimezones } from 'shared/graphql/SharedGraphql';

export interface EditLocationRef {
  initForm: (values: IFormInput) => void;
}

interface IProps {
  isEdit?: boolean;
  locationId?: string;
  onMoveSuccess?: () => void;
}

const EditLocationForm = forwardRef<EditLocationRef, IProps>(
  ({ isEdit, locationId, onMoveSuccess }, ref) => {
    const { t } = useTranslation(['common', 'location']);
    const toast = useToast({
      position: 'top-right',
      isClosable: true,
      duration: 3000,
    });
    const history = useHistory();
    const partialDisabled = useUserDataSelector(
      (state) => state.authRole === AuthRole.LOCATION_OWNER
    );

    const methods = useForm<IFormInput>({
      defaultValues: getDefaultFormData(),
    });

    const locationEid = methods?.watch('eid');

    useImperativeHandle(
      ref,
      () => {
        return {
          initForm: (values) => {
            methods.reset(values);
          },
        };
      },
      [methods.reset]
    );

    const { execute: getTimezonesData } = getTimezones((timezones) => {
      methods.setValue('timezonesData', timezones);
    });

    useEffect(() => {
      if (locationEid) {
        getTimezonesData();
      }
    }, [locationEid]);

    const [updateLocation, { loading }] = useMutation<
      never,
      UpdateLocationVariable
    >(UPDATE_LOCATION_QUERY, {
      onCompleted: () => {
        toast({
          status: 'success',
          title: t('common:success'),
          description: t('location:location_update_success'),
        });
        history.goBack();
      },
      onError: (error) => {
        if (error?.message?.includes('Username')) {
          methods.setError(
            'username',
            {
              type: 'custom',
              message: error.message,
            },
            {
              shouldFocus: true,
            }
          );
        } else {
          toast({
            status: 'error',
            title: t('common:error'),
            description: t('location:locationUpdateError'),
          });
        }
      },
    });

    const onSubmit = async (data: IFormInput) => {
      deployEvent(AmplitudeEventNames.LOCATION_ADD_BUTTON, {
        add_location_funnel_id: 5,
        location_id: locationId,
        location_type: data.locationType,
        location_name: data.locationName ?? 'Miami',
        status: data.locationStatus.value,
        email_address: data.locationEmail ?? 'xyz@gmail.com',
        owner: data.locationOwners ?? ['Joe', 'Ryan'],
        location_user_name: data.username ?? 'abctest',
        job_edit_funnel_id: 5,
        Address: data.address ?? 'Abc, Floor 1',
        State: data.state ?? 'Haryana',
        City: data.city ?? 'Gurugram',
        Zipcode: data.zipCode ?? '123412',
        entity_name: data.entityName ?? 'Foodapanda',
        entity_type: data.entityType ?? 'LLC',
        country_of_formation: data.countryOfFormation ?? 'USA',
        state_of_formation: data.stateOfFormation ?? 'Alaska',
        location_Type: data.locationType ?? 'Affiliate',
        location_edit_funnel_id: 5,
        source: data.locationStatus.label ?? 'Pre- launch location',
      });
      const inputData = addLocationFormat(cloneDeep(data), locationId!);

      await updateLocation({
        variables: {
          input: inputData,
        },
      });
    };

    return (
      <FormProvider {...methods}>
        <Flex flexDir='column' gap={4} pb={5}>
          <AddLocationHeader title={t('location:edit_location')} />

          <BasicDetails isEdit={isEdit} partialDisabled={partialDisabled} />

          <UpdateLoginDetails locationId={locationId} />

          <Address />

          <EntityDetails />

          <OtherDetails isDisabled={partialDisabled} />

          <Flex justify='flex-end' gap='12px'>
            <Authorize
              permittedFor='user'
              permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
            >
              <DeleteLocation onMoveSuccess={onMoveSuccess} />
            </Authorize>

            <ActionButton
              size='lg'
              type='submit'
              colorScheme='blue'
              minW='170px'
              fontSize='15px'
              fontWeight='600'
              borderRadius='7px'
              actionFn={methods.handleSubmit(onSubmit)}
            >
              {t('common:update')}
            </ActionButton>
          </Flex>
        </Flex>
      </FormProvider>
    );
  }
);

EditLocationForm.displayName = 'Edit Location Form';

export default EditLocationForm;
