import React, { useMemo } from 'react';
import { Box, Center } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';

import { ColumnsType, SorterResult } from 'atoms';
import { ActionMenu } from 'ui-components';
import { Any } from '../../../../types';

import { AuditTemplateItem } from '../query/audits-listing.graphql';

import RestoreIcon from '../../../../assets/images/restore.svg';

interface IProps<T> {
  actionHandler?: (action: string, value: T) => void | Promise<void | Any>;
  sorter?: SorterResult<T>;
}

export const useArchivedColumns = (
  props?: IProps<AuditTemplateItem>
): ColumnsType<AuditTemplateItem> => {
  const { actionHandler } = props || {};
  return useMemo((): ColumnsType<AuditTemplateItem> => {
    return [
      {
        title: 'Draft Name',
        dataIndex: 'title',
        width: '35%',
        render: (title) => {
          return (
            <Box fontWeight={500} noOfLines={1}>
              {title}
            </Box>
          );
        },
      },
      {
        title: 'Deleted On',
        dataIndex: 'updatedAt',
        key: 'deletedOn',
        render: (deletedOn) => {
          const _moment = moment(deletedOn);
          return <Box fontWeight='500'>{_moment.format('DD MMM YYYY')}</Box>;
        },
      },
      {
        title: 'Assignees',
        dataIndex: 'assignee',
        key: 'assignee',
        render: () => {
          return (
            <Box fontWeight='500' fontStyle='italic' color='#6F767E'>
              Unassigned
            </Box>
          );
        },
      },
      {
        title: 'Last Activity',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (updatedAt) => {
          const _moment = moment(updatedAt);
          return <Box fontWeight='500'>{_moment.format('DD MMM YYYY')}</Box>;
        },
      },
      {
        dataIndex: 'action',
        align: 'center',
        render: (__, data) => (
          <Center>
            <ActionMenu
              menuData={[
                {
                  name: 'View Draft',
                  value: 'viewTemplate',
                  icon: faArrowUpRight,
                  normalIcon: 'fontAwesome',
                },
                {
                  name: 'Restore',
                  value: 'restore',
                  icon: RestoreIcon,
                },
              ]}
              arrowSize={10}
              closeOnBlur={true}
              offset={[24, 8]}
              clickedItem={(_, clickedItem) => {
                return actionHandler?.(clickedItem, data);
              }}
              strategy={'fixed'}
            >
              <Box w='min-content' px={1}>
                <FontAwesomeIcon
                  cursor={'pointer'}
                  fontSize={'1.2rem'}
                  icon={faEllipsis as IconProp}
                />
              </Box>
            </ActionMenu>
          </Center>
        ),
      },
    ];
  }, [actionHandler]);
};
