import { Center, Flex } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DashboardContainer from 'sub-components/DashboardContainer';
import Loader from 'ui-components/Loader';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { UserData, userObj } from 'sop-commons/src/client';
import Heading from './sub-components/Heading';
import ProgressDetails from './sub-components/ProgressDetails';
import StepListing from './sub-components/StepListing';
import Details from './sub-components/Details';
import TitleHeading from './sub-components/TitleHeading';
import { useTerminatedTaskModal } from 'pages/TasksDashboard/Components/sub-components/TerminatedTaskModal';
import { ISessionByIdForMyTasks } from '../../services/my-tasks-types';
import { SESSION_BY_ID_FOR_MY_TASK_DETAILS } from '../../services/my-tasks.graphql';
import {
  AmplitudeEventNames,
  CommonAmplitudeEvents,
  deployEvent,
} from 'shared/amplitudeEvents';
import moment from 'moment-timezone';
import { taskRepeat } from 'utils/taskRepeat';
import { RepeatDetails } from 'sub-components/tasks/create-task/task.types';
import { showTimeText } from './sub-components/Details/Details';

const MyTaskDetails: FC = () => {
  const userData = useReactiveVar(userObj);
  const history = useHistory();
  const terminatedTaskModal = useTerminatedTaskModal();
  const [isLoading, setIsLoading] = useState(false);

  /** If `shouldTriggerRefetch` is true, we will hit the api but will not show `loadingComponent`, instead will use this value in `StepListing` component */
  const [shouldTriggerRefetch, setShouldTriggerRefetch] = useState(false);
  const [eventCaptured, setEventCaptured] = useState(false);
  const { taskId, sessionId } = useParams<{
    taskId: string;
    sessionId: string;
  }>();

  const statusHandler = (
    item: ISessionByIdForMyTasks['SessionById'] | undefined,
    userData: UserData
  ) => {
    let itemDate = moment.utc(item?.dueDate);
    let userTimezone = userData?.timezone;
    let _now = moment()?.tz(userTimezone);
    let currentDate = moment.utc(
      _now?.format('DD-MMM-YYYY hh:mm a'),
      'DD-MMM-YYYY hh:mm a'
    );
    if (
      itemDate.isBefore(currentDate) &&
      item?.currentUserProgress?.completedTasks?.length !== item?.itemCount
    ) {
      return 'Overdue';
    } else if (
      itemDate.isSame(currentDate, 'day') &&
      item?.currentUserProgress?.completedTasks?.length !== item?.itemCount
    ) {
      return 'Today';
    } else if (
      itemDate.isAfter(currentDate) &&
      item?.currentUserProgress?.completedTasks?.length !== item?.itemCount
    ) {
      return 'Upcoming';
    } else {
      return 'Completed';
    }
  };

  const [execute, { data, refetch }] = useLazyQuery<
    ISessionByIdForMyTasks,
    { eid: string }
  >(SESSION_BY_ID_FOR_MY_TASK_DETAILS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      setIsLoading(false);
      setShouldTriggerRefetch(false);

      if (!eventCaptured) {
        captureEvent(data);
      }
    },
    onError: () => {
      setIsLoading(false);
      setShouldTriggerRefetch(false);
    },
  });

  const captureEvent = (data: ISessionByIdForMyTasks | undefined) => {
    if (!data) return;
    let dueDateStringify = '';
    if (data?.SessionById?.dueDate && data?.SessionById?.task?.timezone) {
      dueDateStringify = showTimeText(
        data?.SessionById?.dueDate,
        data?.SessionById?.task?.timezone
      );
    }
    deployEvent(AmplitudeEventNames.ASSIGNED_TASK_VISIT, {
      task_completion_funnel_id: 2,
      task_id: taskId,
      total_steps: data?.SessionById?.taskItems?.length || 0,
      total_forms:
        data?.SessionById?.taskItems?.filter((item) => item?.type === 'form')
          ?.length || 0,
      total_chapters:
        data?.SessionById?.taskItems?.filter((item) => item?.type === 'sop')
          ?.length || 0,
      total_to_do_items:
        data?.SessionById?.taskItems?.filter((item) => item?.type === 'todo')
          ?.length || 0,
      percent_completion: +(
        data?.SessionById?.currentUserProgress?.completedPercent || 0
      )?.toFixed(0),
      status: statusHandler(data?.SessionById, userData),
      due_date: dueDateStringify || '',
      days_till_due_date: '',
      repeat: taskRepeat(
        data?.SessionById?.task?.repeatDetails as RepeatDetails<Date>
      ),
    });

    setEventCaptured(true);
  };

  useEffect(() => {
    if (sessionId) {
      setIsLoading(true);
      execute({
        variables: {
          eid: sessionId,
        },
      });
    }
  }, [sessionId]);

  const goBackHandler = () => {
    history.push('/tasks/my-tasks');
  };

  useEffect(() => {
    if (
      data?.SessionById &&
      data?.SessionById?.task?.status === 'TASK_TERMINATED'
    ) {
      terminatedTaskModal({
        btnTxt: 'Go back',
        callback: goBackHandler,
      });
    }
  }, [data]);
  console.log({ data });

  const renderComponent = () => {
    return (
      <Flex id='my-task-details-1' flexDir='column' gap={4}>
        <TitleHeading data={data} />
        <Flex id='my-task-details-2' gap='10px' w='full'>
          <Flex
            id='my-task-details-3'
            bg='white'
            w='60%'
            flexDir='column'
            py={6}
            borderRadius='8px'
            gap={4}
          >
            <Heading data={data} />
            <Flex id='my-task-details-4' flexDir='column' gap={2}>
              <ProgressDetails
                data={data}
                userData={{
                  name: userData?.name,
                  profilePic: userData?.profilePic,
                }}
              />
              <StepListing
                data={data}
                id={taskId}
                refetch={refetch}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                shouldTriggerRefetch={shouldTriggerRefetch}
                setShouldTriggerRefetch={setShouldTriggerRefetch}
                event={CommonAmplitudeEvents.VIEW_MY_TASK_STEP_FORM}
              />
            </Flex>
          </Flex>
          <Details data={data} userData={userData} />
        </Flex>
      </Flex>
    );
  };

  const loadingComponent = () => {
    return (
      <Center w='full' h='80vh'>
        <Loader />
      </Center>
    );
  };

  const renderer = () => {
    if (!shouldTriggerRefetch) {
      if (isLoading) {
        return loadingComponent();
      } else {
        return renderComponent();
      }
    } else {
      return renderComponent();
    }
  };

  return <DashboardContainer>{renderer()}</DashboardContainer>;
};

MyTaskDetails.displayName = 'MyTasks/Details';

export default MyTaskDetails;
