import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import ExpandableFilter from './ExpandableFilter';
import TaskTypeList from './TaskTypeList';
import TaskAssigneeList from './TaskAssigneeList';
import FilterCheckItem from './FilterCheckItem';
import { useLauncherConfigContext } from '../../../common';

import { ReactComponent as MultiSelectIcon } from '../../../../../../assets/images/multiselect.svg';
import { ReactComponent as UserIcon } from '../../../../../../assets/images/userIcon.svg';
import { ReactComponent as TimerIcon } from '../../../../../../assets/images/timer.svg';

interface IProps {}

const FilterContents: FC<IProps> = () => {
  const { t } = useTranslation('launcher');
  const { filterBy, changeFilterBy } = useLauncherConfigContext((state) => ({
    filterBy: state.filterBy,
    changeFilterBy: state.changeFilterBy,
  }));

  return (
    <Flex flexDir='column' py='8px' gap='1px' userSelect='none' overflow='auto'>
      <ExpandableFilter
        title={t('taskType')}
        icon={<MultiSelectIcon color='currentColor' />}
      >
        <TaskTypeList
          value={filterBy?.taskTypes}
          onChange={(newValue) => changeFilterBy('taskTypes', newValue)}
        />
      </ExpandableFilter>

      <ExpandableFilter
        title={t('taskAssignee')}
        icon={<UserIcon color='currentColor' />}
      >
        <TaskAssigneeList
          value={filterBy?.assignees}
          onChange={(newValue) => changeFilterBy('assignees', newValue)}
        />
      </ExpandableFilter>

      <FilterCheckItem
        title={t('filter.phaseWithDeadline')}
        value='deadline'
        icon={<TimerIcon color='currentColor' />}
        isChecked={filterBy?.other?.deadline}
        onChange={(event) =>
          changeFilterBy('other.deadline', event.target.checked)
        }
      />

      <FilterCheckItem
        title={t('filter.phaseWithoutDeadline')}
        value='nonDeadline'
        icon={<TimerIcon color='currentColor' />}
        isChecked={filterBy?.other?.nonDeadline}
        onChange={(event) =>
          changeFilterBy('other.nonDeadline', event.target.checked)
        }
      />
    </Flex>
  );
};

export default FilterContents;
