// import scss
import '../SearchInput/SearchInput.scss';
import { ReactComponent as SearchIcon } from '../../assets/images/searchIcon.svg';
import { ReactComponent as CommandSvg } from '../../assets/images/commandIcon.svg';
import {
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  InputGroupProps,
  InputProps,
} from '@chakra-ui/react';
import { FC, forwardRef } from 'react';

const getControlText = () => {
  if (typeof navigator !== 'undefined' && navigator.appVersion.indexOf('Mac')) {
    return <CommandSvg />;
  }
  return 'ctrl';
};

interface IProps {
  size?: InputGroupProps['size'];
  width?: string | number;
  hideShortcuts?: boolean;
  placeholder?: string;
  onChange?: InputProps['onChange'];
  onBlur?: InputProps['onBlur'];
  onFocus?: InputProps['onFocus'];

  value?: string;
  className?: string;
  hideSearchIcon?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}

const SearchInputWithRef = forwardRef<HTMLInputElement, IProps>(
  (
    {
      size,
      width,
      hideShortcuts,
      placeholder,
      onChange,
      onBlur,
      value,
      className,
      disabled,
      readOnly,
      hideSearchIcon,
      autoFocus,
      onFocus,
    },
    ref // Forwarded ref
  ) => {
    return (
      <InputGroup className='search-input' size={size} style={{ width: width }}>
        {!hideSearchIcon && (
          <InputLeftElement pointerEvents='none'>
            <SearchIcon />
          </InputLeftElement>
        )}
        <Input
          ref={ref} // Attach the forwarded ref here
          placeholder={placeholder}
          variant='filled'
          style={{
            fontSize: '15px',
            borderRadius: 12,
            backgroundColor: '#f4f4f4',
            border: 'none',
          }}
          className={className || 'chakra-input'}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          autoFocus={autoFocus}
          readOnly={readOnly}
          onFocus={onFocus}
          disabled={disabled}
        />
        {!hideShortcuts && (
          <InputRightElement width='3rem'>
            <div
              className='eventclass-earch-modal-shortcut search-command-container'
              style={{ marginRight: '22px' }}
            >
              {getControlText()} <span style={{ marginLeft: '6px' }}>K</span>
            </div>
          </InputRightElement>
        )}
      </InputGroup>
    );
  }
);

export default SearchInputWithRef;
