import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import {
  BoxHeader,
  useConfirm,
  UseConfirm,
} from '../../../../../ui-components';
import { HeaderColors } from '../../../../../configs';
import { AppRoute, useHistory } from '../../../../../routes';
import { useLatest } from '../../../../../hooks';
import AddBoardContent from './AddBoardContent';

export const useAddBoard = () => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const history = useHistory();
  const apolloClient = useApolloClient();

  const pushRef = useLatest(history.push);
  const replaceRef = useLatest(history.replace);

  return useCallback(() => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <BoxHeader
          color={HeaderColors.Purple}
          title='Create board'
          fontSize='18px'
        />
      ),

      content: (
        <AddBoardContent
          onCreateFromScratch={(values) => {
            if (values.type === 'scratch') {
              pushRef.current({
                pathname: AppRoute.LAUNCHER_BOARDS,
                state: values.title,
              });
            } else if (values.type === 'duplicate') {
              replaceRef.current({
                pathname: AppRoute.LAUNCHER_BOARD_CONFIG,
                search: history.location.search,
                params: {
                  boardId: values.launcherId,
                },
              });
            }

            confirmRef.current?.destroy();
          }}
        />
      ),
      isCentered: true,
      contentProps: {
        minWidth: '600px',
        borderRadius: '12px',
        paddingY: '12px',
      },
      footer: null,
    });

    return confirmRef.current;
  }, []);
};
