import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';

import { LauncherTrigger } from '../../launcher-config.fragment';
import TaskTriggerIntro from './TaskTriggerIntro';
import TriggerSettings from './TriggerSettings';

interface IProps {
  taskName: string;
  defaultValue: LauncherTrigger;
  onSaveChanges: (data: LauncherTrigger) => void;
}

const TaskTriggerContainer: FC<IProps> = ({
  defaultValue,
  onSaveChanges,
  taskName,
}) => {
  return (
    <Flex>
      <TaskTriggerIntro />

      <TriggerSettings
        taskName={taskName}
        defaultValue={defaultValue}
        onSaveChanges={onSaveChanges}
      />
    </Flex>
  );
};

TaskTriggerContainer.displayName = 'sc/L/lc/c/ctf/tts/TaskTriggerContainer';

export default TaskTriggerContainer;
