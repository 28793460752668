import { Box, Flex, Text, Image, Divider } from '@chakra-ui/react';
import { FC } from 'react';
import { formatDateTime } from 'sub-components/audits/listing/helper';
import { getImage } from 'utils';
import { AuditSessionAssignments, PdfDataType } from '../../types/report.types';

type pdfOverviewType = {
  pdfData: PdfDataType;
  assignment: AuditSessionAssignments;
  additionalData: any;
};

const PdfOverview: FC<pdfOverviewType> = ({
  pdfData,
  assignment,
  additionalData,
}) => {
  let addressDetail = '';
  if (pdfData?.address) {
    addressDetail = `${
      pdfData?.address?.city ? pdfData?.address?.city + ' ,' : ''
    } ${pdfData?.address?.state ? pdfData?.address?.state + ' ,' : ''} ${
      pdfData?.address?.zipCode ? pdfData?.address?.zipCode : ''
    }`;
  }

  return (
    <>
      <Box mt={'32px'}>
        <Flex gap={4} alignItems={'center'}>
          <Image
            borderRadius={'8px'}
            src={getImage(pdfData?.profilePic, pdfData?.companyName)}
            alt={'profile'}
            width={'auto'}
            height='92px'
          />
          <Text fontSize={'20px'} fontWeight={600}>
            {pdfData?.companyName}
          </Text>
        </Flex>

        <Flex
          marginBottom={'100px'}
          marginTop={'50px'}
          gap={'100px'}
          direction={'column'}
          alignItems={'center'}
        >
          <Text fontWeight={600} fontSize={'24px'}>
            {pdfData?.reportTitle?.toUpperCase()}
          </Text>
        </Flex>

        <Box
          style={{
            border: '1px solid #EFEFEF',
            borderRadius: '12px',
            margin: '2rem 0px',
            scrollMargin: '8vh',
          }}
        >
          <Flex p={'18px'}>
            <Flex width={'50%'}>
              <Text color={'#6F767E'} fontSize={'15px'}>
                Score
              </Text>
            </Flex>
            <Flex width={'50%'}>
              {assignment?.maxScore > 0 ? (
                <Text color={'#272B30'} fontSize={'20px'}>
                  {`${(
                    (assignment?.totalScore / assignment?.maxScore
                      ? assignment?.totalScore / assignment?.maxScore
                      : 0) * 100
                  )?.toFixed(2)}%`}
                  &nbsp; ({assignment?.totalScore}/{assignment?.maxScore})
                </Text>
              ) : (
                <Text>--</Text>
              )}
            </Flex>
          </Flex>
          <Divider />
          <Flex p={'18px'}>
            <Flex width={'50%'}>
              <Text color={'#6F767E'} fontSize={'15px'}>
                Flagged items
              </Text>
            </Flex>
            <Flex width={'50%'}>
              <Text color={'#272B30'} fontSize={'20px'}>
                {pdfData?.flaggedItemCount
                  ? pdfData?.flaggedItemCount + ' Flags'
                  : '--'}
              </Text>
            </Flex>
          </Flex>
          <Divider />
          <Flex p={'18px'}>
            <Flex width={'50%'}>
              <Text color={'#6F767E'} fontSize={'15px'}>
                Report number
              </Text>
            </Flex>
            <Flex width={'50%'}>
              <Text color={'#272B30'} fontSize={'20px'}>
                {pdfData?.reportNumber}
              </Text>
            </Flex>
          </Flex>
          <Divider />
          <Flex p={'18px'}>
            <Flex width={'50%'}>
              <Text color={'#6F767E'} fontSize={'15px'}>
                Location
              </Text>
            </Flex>
            <Flex width={'50%'}>
              <Text color={'#272B30'} fontSize={'20px'}>
                {addressDetail?.trim() ? addressDetail : '--'}
              </Text>
            </Flex>
          </Flex>
          <Divider />
          <Flex p={'18px'}>
            <Flex width={'50%'}>
              <Text color={'#6F767E'} fontSize={'15px'}>
                Conducted on
              </Text>
            </Flex>
            <Flex width={'50%'}>
              <Text color={'#272B30'} fontSize={'20px'}>
                {pdfData?.auditDate
                  ? formatDateTime(pdfData?.auditDate, additionalData?.timeZone)
                      .dateString
                  : '--'}
              </Text>
            </Flex>
          </Flex>
          <Divider />
          <Flex p={'18px'}>
            <Flex width={'50%'}>
              <Text color={'#6F767E'} fontSize={'15px'}>
                Conducted by
              </Text>
            </Flex>
            <Flex gap={2} alignItems={'center'} width={'50%'}>
              <Image
                borderRadius={'50%'}
                src={getImage(pdfData?.auditorProfilePic, pdfData?.auditorName)}
                alt={'profile'}
                width={'25px'}
                height='25px'
              />
              <Text color={'#272B30'} fontSize={'20px'}>
                {pdfData?.auditorName}
              </Text>
            </Flex>
          </Flex>
          <Divider />
        </Box>
      </Box>
    </>
  );
};

export default PdfOverview;
