import { Box, Flex, Progress, Text, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendar,
  faClockThree,
  faRepeat,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Outliner from './Outliner';
import { ProcessData } from '../../../types';
import SectionFilter from './SectionFilter';
import { filterOptions } from './filter-options';
import { SelectOption } from 'atoms';
import AuditAssignee from './AuditAssignee';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import AuditCard from './AuditCard';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { HeaderColors } from 'configs';

interface IProps {
  data: ProcessData[];
  refetchHandler?: () => void;
}

const OnGoing: FC<IProps> = ({ data, refetchHandler }) => {
  const entityUsers = useReactiveVar(usersEntityObj);

  const [selectedFilter, setSelectedFilter] = useState<
    SelectOption | undefined
  >(undefined);

  const filteredOngoingData = useMemo(() => {
    const ongoingData =
      data?.filter((ongoing) => ongoing?.auditStatus === 'ongoing') || [];
    if (!selectedFilter?.value) {
      return ongoingData;
    }
    let sortedData = [...ongoingData];
    switch (selectedFilter?.value) {
      case 'dueDateFirst':
        sortedData.sort((a, b) => {
          const today = moment();

          const dueDateA = a.dueDate ? moment.utc(a.dueDate) : null;
          const dueDateB = b.dueDate ? moment.utc(b.dueDate) : null;

          const diffA = dueDateA ? Math.abs(dueDateA.diff(today)) : Infinity;
          const diffB = dueDateB ? Math.abs(dueDateB.diff(today)) : Infinity;

          return diffA - diffB;
        });
        break;
      case 'nameDesc':
        sortedData.sort((a, b) => b.title.localeCompare(a.title));
        break;
      case 'nameAsc':
        sortedData.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case 'frequency':
        // Implement sorting by frequency if needed
        break;
      default:
        break;
    }
    return sortedData;
  }, [data, selectedFilter]);

  const handleFilterChange = (filter: SelectOption) => {
    setSelectedFilter((prevVal) =>
      prevVal?.value === filter.value ? undefined : filter
    );
  };

  const titleRender = (ongoing: ProcessData) => {
    return (
      <Tooltip label={ongoing?.title} hasArrow placement='top'>
        <Text fontWeight={600} isTruncated maxW='190px'>
          {ongoing?.title}
        </Text>
      </Tooltip>
    );
  };

  const scheduleRender = (ongoing: ProcessData) => {
    return (
      <Flex
        align='center'
        gap='5px'
        color='rgba(111, 118, 126, 1)'
        fontSize='12px'
        fontWeight={500}
      >
        <Flex gap='3px' align='center'>
          <FontAwesomeIcon
            icon={faCalendar as IconProp}
            fontSize='12px'
            style={{ paddingBottom: '2px' }}
          />
          <Box as='span'>
            {moment(ongoing?.dueDate)?.isValid()
              ? moment.utc(ongoing?.dueDate)?.format('DD MMM YY')
              : 'No Deadline'}
          </Box>
        </Flex>
        <Box>&bull;</Box>
        <FontAwesomeIcon icon={faRepeat as IconProp} />
        <Tooltip label={ongoing?.repeatStatus} hasArrow placement='top'>
          <Text textTransform='capitalize' isTruncated maxW='90px'>
            {ongoing?.repeatStatus || 'Does not repeat'}
          </Text>
        </Tooltip>
      </Flex>
    );
  };

  const footerRender = (ongoing: ProcessData) => {
    return (
      <Flex w='full' align='center' gap='5px' justify='flex-start'>
        <Flex align='center' gap='5px' minW='fit-content'>
          <FontAwesomeIcon
            icon={faLocationDot as IconProp}
            color={HeaderColors.GreenDark}
          />
          <Box fontWeight={500} fontSize='12px'>
            {
              ongoing?.assignments?.filter(
                (assignment) => assignment?.startedBy
              )?.length
            }
            /{ongoing?.assignments?.length} done
          </Box>
        </Flex>
        <Flex w='full' align='center' gap='10px'>
          <Progress
            sx={{
              '& > div:first-of-type': {
                background: 'rgba(255, 216, 141, 1)',
              },
              backgroundColor: 'rgba(239, 239, 239, 1)',
            }}
            size='sm'
            value={
              ongoing?.assignments?.filter(
                (assignment) => assignment?.startedBy
              )?.length || 0
            }
            max={ongoing?.assignments?.length}
            w='full'
            borderRadius='7px'
          />
          <AuditAssignee
            assignees={entityUsers?.filter((user) =>
              ongoing?.auditors?.some((auditor) => auditor?.eid === user?.eid)
            )}
          />
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex flexDir='column' gap={4} minW='244px' maxW='244px'>
      <Outliner bg='rgba(255, 216, 141, 1)'>
        <Flex align='center'>
          <Text fontWeight={600} fontSize='14px'>
            Ongoing
          </Text>
          &nbsp;
          <Text fontWeight={600} fontSize='14px'>
            ({filteredOngoingData?.length})
          </Text>
        </Flex>
        {filteredOngoingData?.length > 0 && (
          <SectionFilter
            options={filterOptions}
            value={selectedFilter}
            handleOptionClick={handleFilterChange}
          />
        )}
      </Outliner>
      {filteredOngoingData?.map((ongoing, index) => (
        <AuditCard
          key={ongoing?.eid}
          auditData={ongoing}
          auditTitle={titleRender(ongoing)}
          scheduleDetails={scheduleRender(ongoing)}
          footerDetails={footerRender(ongoing)}
          refetchHandler={refetchHandler}
        />
      ))}
    </Flex>
  );
};

export default OnGoing;
