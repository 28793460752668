import React, { FC, useState } from 'react';
import { UserEntityData } from 'shared/graphql/shared-types';
import { EntityLocations, LocationsEntity } from 'sop-commons/src/client';
import { FiltersType } from '../../ComplianceListContainer';
import BaseFilter from './BaseFilter';
import { ClearFilterType } from './FilterContainer';
import LocationFilter from './LocationFilter';
import UploadedBy from './UploadedBy';

export type PageNameType = 'base' | 'uploadedBy' | 'locations';

interface IProps {
  filters: FiltersType;
  setFilters: FiltersType;
  handleExpiredDocFilter: () => void;
  handleLocationSelection: (
    e: React.ChangeEvent<HTMLInputElement>,
    location: LocationsEntity | EntityLocations
  ) => void;
  handleUploadedBySelection: (
    e: React.ChangeEvent<HTMLInputElement>,
    user: UserEntityData
  ) => void;
  clearFilters: (type?: ClearFilterType) => void;
  complianceLoader: any;
}

const MainContainer: FC<IProps> = ({
  handleExpiredDocFilter,
  setFilters,
  filters,
  handleLocationSelection,
  handleUploadedBySelection,
  clearFilters,
  complianceLoader,
}) => {
  const [pageName, setPageName] = useState<PageNameType>('base');

  if (pageName === 'locations') {
    return (
      <LocationFilter
        clearFilters={clearFilters}
        handleLocationSelection={handleLocationSelection}
        filters={filters}
        setPageName={setPageName}
        complianceLoader={complianceLoader}
      />
    );
  }

  if (pageName === 'uploadedBy') {
    return (
      <UploadedBy
        clearFilters={clearFilters}
        handleUploadedBySelection={handleUploadedBySelection}
        filters={filters}
        setPageName={setPageName}
        complianceLoader={complianceLoader}
      />
    );
  }

  return (
    <BaseFilter
      clearFilters={clearFilters}
      filters={filters}
      setPageName={setPageName}
      handleExpiredDocFilter={handleExpiredDocFilter}
    />
  );
};

export default MainContainer;
