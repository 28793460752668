import React, { FC } from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  useModalContext,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { Input } from '../../../../../../atoms';
import { ActionButton } from '../../../../../../ui-components';
import TitleHeader from '../../../../../../sub-components/CardEditor/TitleHeader';

export interface RenameProps {
  title: string;
  onSaveClick?: (title: string) => Promise<void>;
}

const RenameBoard: FC<RenameProps> = ({ title, onSaveClick }) => {
  const { onClose } = useModalContext();
  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm({
    defaultValues: {
      title: title,
    },
  });

  const _onSaveClick = async (values: { title: string }) => {
    if (onSaveClick) {
      await onSaveClick?.(values.title);
      onClose();
    }
  };

  return (
    <Flex flexDir='column' gap={5}>
      <Flex flexDir='column' gap={3}>
        <TitleHeader
          title='Please enter a new name for your board'
          isRequired
        />

        <Controller
          control={control}
          name='title'
          rules={{
            required: 'Board name is required',
            validate: (value) => {
              if (value && value?.trim()?.length === 0) {
                return 'Invalid board name';
              }
            },
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              <Input size='lg' {...field} />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />
      </Flex>

      <Flex gap={4}>
        <ActionButton flex={1} size='lg' variant='outline' actionFn={onClose}>
          Cancel
        </ActionButton>
        <ActionButton
          flex={1}
          size='lg'
          colorScheme='blue'
          isDisabled={!isDirty}
          actionFn={handleSubmit(_onSaveClick)}
        >
          Save changes
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default RenameBoard;
