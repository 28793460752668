import React, { FC } from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Flex,
  FlexProps,
} from '@chakra-ui/react';
import { useFormState } from 'react-hook-form';

import { ReactComponent as CompletedIcon } from '../../../../../assets/images/tasks/completed.svg';

interface IProps {
  bg?: FlexProps['bg'];
  title: string;
  subTitle: React.ReactNode;
  fieldKey?: string[];
}

const TaskFormItem: FC<IProps> = ({
  bg,
  title,
  subTitle,
  fieldKey,
  children,
}) => {
  // const fieldKey = ['title', 'phase', 'category'];

  const { errors } = useFormState();

  const errorKeys = Object.keys(errors);

  const isInvalid = React.useMemo(() => {
    if (!fieldKey?.length) {
      return false;
    }

    return errorKeys.some((k) => fieldKey.includes(k));
  }, [errorKeys, fieldKey]);

  return (
    <AccordionItem
      borderTopWidth={0}
      bg={bg}
      borderRadius='12px'
      aria-invalid={isInvalid}
      _invalid={{
        '&:has(button[aria-expanded="false"])': {
          border: '1px solid #ff6a55',
          bg: 'rgba(255, 188, 153, 0.3)',
        },
      }}
      _last={{
        borderBottomWidth: 0,
      }}
    >
      <AccordionButton borderRadius='12px' paddingY={4}>
        <Flex flex={1} align='center' gap={3} overflow='hidden'>
          <Center boxSize='60px' minW='60px' bg='#B5E4CA4D' borderRadius='8px'>
            <CompletedIcon width='20' height='20' />
          </Center>
          <Flex
            flex={1}
            flexDir='column'
            gap='2px'
            textAlign='start'
            overflow='hidden'
            paddingRight={2}
          >
            <Box fontSize='16px' fontWeight='600' color='#1A1D1F'>
              {title}
            </Box>
            <Box fontSize='12px' fontWeight='400' color='#6F767E' isTruncated>
              {subTitle}
            </Box>
          </Flex>
        </Flex>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>{children}</AccordionPanel>
    </AccordionItem>
  );
};

TaskFormItem.displayName =
  'sub-components/Launcher/launcher-config/common/config-task-form/TaskFormItem';

export default TaskFormItem;
