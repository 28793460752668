import { IDeleteSubFolderProps } from 'pages/Chapters/ChapterContainer/hooks/useChapterContainerMisc';
import React, { FC } from 'react';
import { QueryLazyOptions } from '@apollo/client';
import { useUserDataQuery } from 'hooks';
import { ISelectedFolderData } from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import {
  ChapterListVariable,
  ChapterSort,
} from 'pages/Chapters/chapters.graphql';
import { ConfirmModal, EmptyContainerModal, MoveSubFolderChapters } from '.';
import useCombinedStore from 'zustandStore/store';

interface IProps {
  fetchChapterList: (
    options?: QueryLazyOptions<ChapterListVariable> | undefined
  ) => void;
  getChaptetVariables: () => {
    page: number;
    perPage: number;
    filter: Record<string, any>;
    sort: ChapterSort;
  };
  selectedFolder: ISelectedFolderData;
}

const DeleteSubFolderContainer: FC<IProps> = ({
  selectedFolder,
  fetchChapterList,
  getChaptetVariables,
}) => {
  const { deleteSubFolderData, updateDeleteSubFolderData } = useCombinedStore();
  const onUserFetchCompleted = () => {
    fetchChapterList({
      variables: getChaptetVariables(),
    });
  };
  const [fetchUserData] = useUserDataQuery({
    onCompleted: onUserFetchCompleted,
  });
  const onCloseHandler = () => {
    updateDeleteSubFolderData({
      selectedSubFolderDetails: undefined,
      showConfirmModal: false,
      showEmptyStateModal: false,
      showMoveChaptersModal: false,
    });
    // setDeleteSubFolderData((prev) => {
    //   return {
    //     selectedSubFolderDetails: undefined,
    //     showConfirmModal: false,
    //     showEmptyStateModal: false,
    //     showMoveChaptersModal: false,
    //   };
    // });
  };
  const onDeleteSuccess = () => {
    onCloseHandler();
  };
  const compRender = () => {
    if (deleteSubFolderData.showEmptyStateModal) {
      return (
        <EmptyContainerModal
          goBack={() => {
            updateDeleteSubFolderData({
              ...deleteSubFolderData,
              showConfirmModal: true,
              showEmptyStateModal: false,
              showMoveChaptersModal: false,
            });
          }}
          onClose={() => {
            updateDeleteSubFolderData({
              ...deleteSubFolderData,
              showConfirmModal: false,
              showEmptyStateModal: false,
              showMoveChaptersModal: false,
            });
            // setDeleteSubFolderData((prev) => {
            //   return {
            //     ...prev,
            //     showConfirmModal: false,
            //     showEmptyStateModal: false,
            //     showMoveChaptersModal: false,
            //   };
            // });
          }}
          open={deleteSubFolderData.showEmptyStateModal}
          selectedFolderData={deleteSubFolderData.selectedSubFolderDetails}
          onDeleteSuccess={onDeleteSuccess}
        />
      );
    }
    if (deleteSubFolderData?.showMoveChaptersModal) {
      return (
        <MoveSubFolderChapters
          open={deleteSubFolderData?.showMoveChaptersModal}
          onClose={() => {
            updateDeleteSubFolderData({
              ...deleteSubFolderData,
              showMoveChaptersModal: false,
            });
            // setDeleteSubFolderData((prev) => {
            //   return {
            //     ...prev,
            //     showMoveChaptersModal: false,
            //   };
            // });
          }}
          selectedFolderData={deleteSubFolderData?.selectedSubFolderDetails}
          onMoveSuccess={onDeleteSuccess}
          onMoveError={onDeleteSuccess}
        />
      );
    }
    if (deleteSubFolderData?.showConfirmModal) {
      return (
        <ConfirmModal
          open={deleteSubFolderData?.showConfirmModal}
          onClose={onCloseHandler}
          selectedFolderData={deleteSubFolderData.selectedSubFolderDetails}
          onDeleteSuccess={onDeleteSuccess}
          onDeleteError={onCloseHandler}
        />
      );
    }
    return null;
  };
  return compRender();
};

DeleteSubFolderContainer.displayName =
  'displayName:pages/Chapters/CreateEditSubFolder/components/DeleteSubFolderContainer';

export default DeleteSubFolderContainer;
