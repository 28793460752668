import React, { FC, useMemo } from 'react';
import { HeaderColors } from 'configs';
import GenericColorHeader from 'sub-components/GenericColorHeader/GenericColorHeader';
import CommonContainer from 'ui-components/CommonContainer/CommonContainer';
import { Button, Flex, Stack, Text, Tooltip } from '@chakra-ui/react';
import { BoxHeader } from 'ui-components';
import { calculateLocationCompletionRate, formatDateToCustom } from '../helper';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';

interface IProps {
  cardData: any;
}
const SingleLocation: FC<IProps> = ({ cardData }) => {
  const history = useHistory();

  const currentTask = cardData.tasks?.find((task: any) => !task.isCompleted);

  const cards = useMemo(() => {
    if (!cardData) return [];
    return [
      {
        name: 'Completion rate',
        color: HeaderColors.Orange,
        data: `${calculateLocationCompletionRate(cardData)}%`,
      },
      {
        name: 'Current task',
        color: HeaderColors.Blue,
        data: currentTask?.title || '-',
      },
      {
        name: 'Start date',
        color: HeaderColors.Purple,
        data: formatDateToCustom(cardData?.startDate),
      },
      {
        name: 'Go-live date',
        color: HeaderColors.Green,
        data: formatDateToCustom(cardData?.liveDate),
      },
    ];
  }, [cardData]);

  return (
    <CommonContainer
      display='flex'
      flexDir='column'
      gap={4}
      bg='white'
      borderRadius='6px'
      p='20px'
      mt={4}
    >
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <GenericColorHeader
          title={cardData.location.name}
          color={HeaderColors.Green}
        />
        <Button
          aria-label='Action button'
          bg={'#FFFFFF'}
          borderRadius='4px'
          variant='solid'
          onClick={() =>
            history.push(`/launcher/location/${cardData.launchId}`)
          }
        >
          <ChevronRightIcon w={'32px'} h={'32px'} />
        </Button>
      </Flex>
      <Flex align={'center'} justifyContent={'space-between'} gap={2}>
        {cards.map((launcherLocCard, index) => {
          return (
            <Stack
              bg={'#F4F4F4'}
              p={'17px 25px'}
              key={index}
              w={'24%'}
              h={'130px'}
              borderRadius={'8px'}
              gap={2}
              cursor={'pointer'}
            >
              <BoxHeader
                title={launcherLocCard.name}
                color={launcherLocCard.color}
                fontSize={'14px'}
                fontWeight={400}
                textColor={'#6F767E'}
              />
              {launcherLocCard.name === 'Current task' ? (
                <Tooltip label={launcherLocCard.data} hasArrow>
                  <Text
                    fontWeight={600}
                    fontSize={'18px'}
                    noOfLines={2}
                    sx={{
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {launcherLocCard.data}
                  </Text>
                </Tooltip>
              ) : (
                <Text
                  fontWeight={600}
                  fontSize={'18px'}
                  lineHeight={'21.78px'}
                  noOfLines={2}
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {launcherLocCard.data}
                </Text>
              )}
            </Stack>
          );
        })}
      </Flex>
    </CommonContainer>
  );
};

export default SingleLocation;
