import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Trans } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  onAddClick?: () => void;
}

const AddTaskButton: FC<IProps> = ({ onAddClick }) => {
  return (
    <Flex
      gap={3}
      p={4}
      bg='#FFFFFF'
      align='center'
      borderRadius='12px'
      cursor='pointer'
      _active={{
        boxShadow: 'md',
      }}
      userSelect='none'
      onClick={onAddClick}
    >
      <FontAwesomeIcon size='lg' icon={faPlus as IconProp} color='#6F767E' />
      <Box fontSize='15px' fontWeight='500' color='#33383F'>
        <Trans ns={['task']} i18nKey='task:addNewTask' />
      </Box>
    </Flex>
  );
};

export default AddTaskButton;
