import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

import { IGraphRange } from './useGraphRangeVariable';
import ButtonSelect from '../ButtonSelect';

interface IProps {
  data: IGraphRange;
  onChange: (newData: IGraphRange) => void;
}

const GraphMonthWeekSelect: FC<IProps> = ({ data, onChange }) => {
  const { t } = useTranslation(['task']);

  const onGraphTypeChange = (type: IGraphRange['type']) => {
    if (type === 'monthly') {
      onChange?.({
        type: type,
        startDate: moment().startOf('year').toISOString(),
        endDate: moment().endOf('year').toISOString(),
      });
    } else if (type === 'weekly') {
      onChange?.({
        type: type,
        startDate: moment().subtract(1, 'month').startOf('month').toISOString(),
        endDate: moment().endOf('month').toISOString(),
      });
    }
  };

  return (
    <Flex gap={2}>
      <ButtonSelect
        isSelected={data.type === 'weekly'}
        onClick={() => onGraphTypeChange('weekly')}
      >
        {t('task:weekly')}
      </ButtonSelect>

      <ButtonSelect
        isSelected={data.type === 'monthly'}
        onClick={() => onGraphTypeChange('monthly')}
      >
        {t('task:monthly')}
      </ButtonSelect>
    </Flex>
  );
};

GraphMonthWeekSelect.displayName = 'sc/t/p/c/g/GraphMonthWeekSelect';

export default GraphMonthWeekSelect;
