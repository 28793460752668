import React, { FC, useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import {
  Box,
  AlertDialogCloseButton,
  Modal,
  useDisclosure,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Stack,
  Flex,
  Button,
  Divider,
  Checkbox,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContentComponent from 'pages/Profile/TabComponents/InfoComponent/password-change/ContentComponent';
import { ActionButton, BoxHeader, UseConfirm, useConfirm } from 'ui-components';
import { NotificationsSummaryDrawer } from '../Summary';
import { _notificationModulesOffered } from '../constants';
import { useUserDataSelector } from 'hooks';
import { filterFilterCopiesByAuthRole } from '../helper';
import { NotificationModule } from '../types/types';

interface IProps {
  isFirstTimeStateOpen: boolean;
  closeFirstTimeState: () => void;
  openSummaryDrawer: () => void;
  isSummaryDrawerOpen: boolean;
  closeSummaryDrawer: () => void;
  openNotificationDrawer: boolean;
}

const IntroToNotificationConfigFeature: FC<IProps> = ({
  isFirstTimeStateOpen,
  closeFirstTimeState,
  openSummaryDrawer,
  isSummaryDrawerOpen,
  closeSummaryDrawer,
  openNotificationDrawer,
}) => {
  const { loggedinUserAuthRole } = useUserDataSelector((state) => ({
    loggedinUserAuthRole: state.authRole,
  }));
  // const useFirstTimeUserState = useCallback(
  //     () => {
  //         confirmRef.current = confirm({
  //             Wrapper: ({ children }: any) => {
  //                 return (
  //                     <ApolloProvider client={apolloClient}>
  //                         {children}
  //                     </ApolloProvider>
  //                 );
  //             },
  //             title: (
  //                 <Flex gap='12px'>
  //                     <Box flex={1}>
  //                         <BoxHeader
  //                             // @ts-ignore
  //                             title={'What would you like to be notified of?'}
  //                             color='#FFBC99'
  //                         />
  //                     </Box>
  //                     <AlertDialogCloseButton
  //                         pos='relative'
  //                         borderRadius='full'
  //                         top='0'
  //                         right='0'
  //                     />
  //                 </Flex>
  //             ),
  //             isCentered: true,
  //             content: <ContentComponent />,
  //             contentProps: {
  //                 py: '16px',
  //                 px: '20px',
  //                 minW: '680px',
  //             },
  //             footer: null,
  //         });
  //     },
  //     [confirm]
  // );
  const handleContinueClick = () => {
    closeFirstTimeState();
    setTimeout(() => openSummaryDrawer());
  };
  return (
    <>
      <Modal
        onClose={closeFirstTimeState}
        isOpen={isFirstTimeStateOpen}
        size={'2xl'}
      >
        <ModalOverlay />
        <ModalContent borderRadius={'12px'} p={'30px 15px'}>
          <ModalHeader>
            <Stack gap={'26px'}>
              <Flex gap='12px'>
                <Box flex={1}>
                  <BoxHeader
                    // @ts-ignore
                    title={'Set-up your notification center'}
                    color='#FFBC99'
                    fontSize={'20px'}
                  />
                </Box>
                <AlertDialogCloseButton
                  pos='relative'
                  borderRadius='full'
                  top='0'
                  right='0'
                />
              </Flex>
              <Text fontSize={'14px'} fontWeight={600} color={'#33383F'}>
                What would you like to be notified of?
              </Text>
            </Stack>
          </ModalHeader>
          <ModalBody>
            <Stack>
              <Divider color={'#EFEFEF'} />
              {filterFilterCopiesByAuthRole(
                loggedinUserAuthRole,
                _notificationModulesOffered
              ).map((item: NotificationModule, index: number) => {
                return (
                  <Box key={item?.title}>
                    <Flex
                      key={index}
                      p={'12px'}
                      align={'flex-start'}
                      justify={'flex-start'}
                      gap={'13px'}
                    >
                      <Box mt={1}>
                        {item.value === 'system' ? (
                          <Checkbox
                            size={'lg'}
                            gap={'6px'}
                            borderRadius={'12px'}
                            colorScheme={'gray'}
                            isChecked
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCheck as IconProp}
                            color={'#2A85FF'}
                            height={'24px'}
                            width={'24px'}
                            size={'lg'}
                          />
                        )}
                      </Box>
                      <Stack>
                        <Text
                          color={'#33383F'}
                          fontWeight={600}
                          fontSize={'16px'}
                        >
                          {item.title}
                        </Text>
                        <Text
                          color={'#33383F'}
                          fontSize={'12px'}
                          letterSpacing={'-1%'}
                        >
                          {item.label}
                        </Text>
                      </Stack>
                    </Flex>
                    <Divider color={'#EFEFEF'} />
                  </Box>
                );
              })}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ActionButton
              isFullWidth
              colorScheme={'blue'}
              mt={2}
              close={handleContinueClick}
            >
              Continue
            </ActionButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {openNotificationDrawer && (
        <NotificationsSummaryDrawer
          isSummaryDrawerOpen={isSummaryDrawerOpen}
          closeSummaryDrawer={closeSummaryDrawer}
        />
      )}
    </>
  );
};
IntroToNotificationConfigFeature.displayName =
  'sc/NC/S/IntroToNotificationConfigFeature.tsx';
export default React.memo(IntroToNotificationConfigFeature);
