import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import { useAuditDetailContext } from 'pages/Audits/Detail/store/context';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { SelectOption } from 'atoms';
import moment from 'moment';
import { useService } from './useService';
import {
  Control,
  LocationOwnerEntity,
  ProcessDataEntity,
  Punctuality,
} from '../types';
import {
  AuditSessionAssignments,
  AuditSessionEntity,
  AuditSessionPaginateVariables,
} from 'sub-components/audits/audit-tab/supervised/types';
import { DateRange } from 'react-day-picker';
import { PageInfo } from 'types';
import { formatNumber } from '../../../../../../utils';
import { UserEntityData } from 'shared/graphql/shared-types';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'authorization';

const initialPageInfo: PageInfo = {
  currentPage: 1,
  perPage: 1000,
  pageCount: 0,
  itemCount: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const useControl = (): Control => {
  const { auditId, sessionId } = useParams<{
    auditId: string;
    sessionId: string;
  }>();
  const {
    auditHistory: { getAuditHistory, auditHistoryResult },
    sessionLocations: {
      getAuditHistorySessionLocations,
      auditHistorySessionLocations,
    },
  } = useService();
  const { loggedInUserAuthRole, loggedInUserEid } = useUserDataSelector(
    (state) => ({
      loggedInUserEid: state?.eid,
      loggedInUserAuthRole: state?.authRole,
    })
  );
  const selectedTab = useAuditDetailContext((state) => state.selectedTab);
  const entityData = useReactiveVar(usersEntityObj);
  const userEntityData = useReactiveVar(usersEntityObj);
  const [selectedLocationsFilter, setSelectedLocationsFilter] = useState<
    SelectOption[]
  >([]);
  const [locationFilterOptions, setLocationFilterOptions] = useState<
    SelectOption[]
  >([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>(initialPageInfo);
  const [selectedDateRange, setSelectedDateRange] = useState<
    DateRange | undefined
  >(undefined);

  const [processedData, setProcessedData] = useState<ProcessDataEntity[]>([]);

  const getFilteredData = (
    items: AuditSessionEntity[]
  ): AuditSessionEntity[] => {
    if (loggedInUserAuthRole === AuthRole.ADMIN) {
      const filteredItems: AuditSessionEntity[] = items.map((session) => {
        const filteredAssignments = session?.assignments?.filter((assignment) =>
          assignment?.auditors?.includes(loggedInUserEid)
        );

        return {
          ...session,
          assignments: filteredAssignments,
        };
      });
      return filteredItems;
    }
    return items;
  };

  useEffect(() => {
    if (
      (auditHistoryResult?.data?.AuditSessionPagination?.items || [])?.length >
      0
    ) {
      const groupedData: ProcessDataEntity[] = [];
      const fetchedPagination =
        auditHistoryResult?.data?.AuditSessionPagination.pageInfo ||
        initialPageInfo;
      const fetchedCount =
        auditHistoryResult?.data?.AuditSessionPagination.count || 0;

      setPageInfo((prev) => ({
        ...prev,
        ...fetchedPagination,
        itemCount: fetchedCount,
      }));
      let initialFilteredAuditHistoryData = getFilteredData(
        auditHistoryResult?.data?.AuditSessionPagination?.items || []
      );
      let filteredAuditHistoryData = [
        ...initialFilteredAuditHistoryData,
      ]?.reverse();
      filteredAuditHistoryData?.forEach((item) => {
        const parentRow: ProcessDataEntity = {
          key: `date-${item.eid}`,
          isDateRange: true,
          dateRange: `${
            moment(item.startDate)?.isValid()
              ? moment.utc(item.startDate).format('DD MMM')
              : '-'
          } - ${
            moment(item.dueDate)?.isValid()
              ? moment.utc(item.dueDate).format('DD MMM')
              : '-'
          }`,
          children: item.assignments?.map((assignment) => {
            let auditStatus = assignment.completedAt
              ? Punctuality.COMPLETED
              : moment().isAfter(moment.utc(item?.dueDate))
              ? Punctuality.MISSED
              : Punctuality.ON_TIME;
            return {
              key: `assignment-${assignment.locationId}`,
              isDateRange: false,
              locationName:
                entityData?.find(
                  (entity) => entity.eid === assignment.locationId
                )?.name || '-',
              locationId: assignment.locationId,
              locationOwner: findLocationOwner(assignment.locationId),
              conductedOn: moment(assignment.completedAt)?.isValid()
                ? moment.utc(assignment.completedAt).format('DD MMM, YYYY')
                : '-',
              punctuality: auditStatus,
              auditScore: assignment?.maxScore
                ? `${formatNumber(
                    (assignment?.totalScore / assignment?.maxScore) * 100 || 0
                  )}% - ${assignment.totalScore || 0}/${assignment.maxScore}`
                : 'N/A',
              totalFlags: calcFlag(item.questions, assignment.response),
              reportsUrl: assignment.reportsUrl,
              isCompleted: assignment?.isCompleted,
              item: item,
            };
          }),
        };

        groupedData.push(parentRow);
      });

      setProcessedData(groupedData);
    } else {
      setProcessedData([]);
    }
  }, [auditHistoryResult?.data, loggedInUserEid, loggedInUserAuthRole]);

  useEffect(() => {
    if (auditHistorySessionLocations?.data) {
      let locations: UserEntityData[] = [];
      if (loggedInUserAuthRole === AuthRole.ADMIN) {
        let filteredLocations =
          auditHistorySessionLocations?.data?.AuditSessionById?.assignments?.filter(
            (assignment) => assignment?.auditors?.includes(loggedInUserEid)
          );
        locations = userEntityData?.filter((loc: UserEntityData) =>
          filteredLocations?.some(
            (assignment) => loc?.eid === assignment?.locationId
          )
        );
      } else {
        locations = userEntityData?.filter((loc: UserEntityData) =>
          auditHistorySessionLocations?.data?.AuditSessionById?.assignments?.some(
            (assignment: AuditSessionAssignments) =>
              loc?.eid === assignment?.locationId
          )
        );
      }
      let locationsArr = locations?.map((loc) => {
        return {
          ...loc,
          label: loc?.name,
          value: loc?.eid,
        };
      });
      setLocationFilterOptions(locationsArr);
    }
  }, [auditHistorySessionLocations?.data, loggedInUserAuthRole]);

  const findLocationOwner = (locationId: string): LocationOwnerEntity => {
    let foundLocationowner = userEntityData?.filter((ent) =>
      ent?.locations?.some((loc) => loc?.eid === locationId)
    )?.[0];
    return {
      eid: foundLocationowner?.eid,
      name: foundLocationowner?.name,
      profilePic: foundLocationowner?.profilePic,
    };
  };

  const getVariables = () => {
    let filterObj: AuditSessionPaginateVariables = {
      page: pageInfo.currentPage,
      perPage: pageInfo?.perPage,
      locationIds: selectedLocationsFilter.map((loc) => loc.value),
      filter: {
        auditIds: [auditId],
        locations: selectedLocationsFilter.map((loc) => loc.value),
      },
    } as AuditSessionPaginateVariables;
    if (filterObj && filterObj.filter) {
      if (
        selectedDateRange?.from &&
        selectedDateRange?.to &&
        moment(selectedDateRange?.from)?.isValid() &&
        moment(selectedDateRange?.to)?.isValid()
      ) {
        filterObj.filter.startDate = moment(selectedDateRange.from)
          .utcOffset(0, true)
          .startOf('day')
          .toISOString();
        filterObj.filter.endDate = moment(selectedDateRange.to)
          .utcOffset(0, true)
          .endOf('day')
          .toISOString();
      }
    }
    return filterObj;
  };

  const getAuditHistoryFn = () => {
    if (selectedTab === 'auditHistory') {
      getAuditHistory({
        variables: getVariables(),
      });
    }
  };

  const getSessionLocationsFn = () => {
    if (selectedTab === 'auditHistory') {
      getAuditHistorySessionLocations({
        variables: {
          eid: sessionId,
        },
      });
    }
  };

  useEffect(() => {
    getSessionLocationsFn();
  }, [selectedTab, auditId]);

  useEffect(() => {
    getAuditHistoryFn();
  }, [
    selectedTab,
    selectedLocationsFilter,
    pageInfo?.currentPage,
    selectedDateRange,
    auditId,
  ]);

  const handleFilterChange = (options: SelectOption[]) => {
    setSelectedLocationsFilter(options);
  };

  const calcFlag = (questions: any[], response: any) => {
    let flagCount = response?.filter((res: any) => res?.isFlagged) || [];
    return flagCount?.length;
  };

  const scorePercentStr = (
    tScore: number | string,
    mScore: number | string
  ) => {
    const totalScore = typeof tScore === 'number' ? tScore : 0;
    const maxScore = typeof mScore === 'number' ? mScore : 0;

    let percentage = 0;

    if (maxScore > 0) {
      percentage = (totalScore / maxScore) * 100;
    } else {
      console.warn('maxScore is zero or undefined. Setting percentage to 0.');
    }
    const percentageBinary = percentage.toString(2);
    return percentageBinary;
  };

  const setSelectedDateRangeHandler = (range: DateRange | undefined) => {
    setPageInfo((prev) => ({
      ...prev,
      currentPage: 1,
    }));
    setSelectedDateRange(range);
  };

  const updatePageHandler = (newPage: number) => {
    setPageInfo({
      ...pageInfo,
      currentPage: newPage,
    });
  };

  const refetchHandler = () => {
    auditHistoryResult?.refetch?.();
  };

  return {
    loading: auditHistoryResult?.loading,
    locationFilterOptions,
    pageInfo,
    processedData,
    selectedDateRange,
    selectedLocationsFilter,
    handleFilterChange,
    refetchHandler,
    setSelectedDateRangeHandler,
    updatePageHandler,
  };
};
