import React, { ChangeEventHandler, FC, useCallback } from 'react';
import { Box, Flex, Spacer } from '@chakra-ui/react';
import { chakraComponents } from 'chakra-react-select';
import { Trans, useTranslation } from 'react-i18next';

import { SelectOption } from '../../../../atoms';
import SearchInput from '../../../../atoms/SearchInput';
import Dropdown from '../../../../atoms/Dropdown';

import AuthRoleFilter from './AuthRoleFilter';

interface IProps {
  searchQuery?: string;
  onSearch?: ChangeEventHandler<HTMLInputElement>;

  sortBy?: SelectOption | null;
  onSortChange?: (value: SelectOption | null) => void;

  filterBy?: SelectOption | null;
  onFilterChange?: (value: SelectOption | null) => void;
}

const SearchFilterHeader: FC<IProps> = ({
  searchQuery,
  onSearch,
  sortBy,
  onSortChange,
  filterBy,
  onFilterChange,
}) => {
  const { t } = useTranslation(['handbook', 'team']);

  const _onSortChange = useCallback(
    (option: SelectOption) => {
      if (option?.value === sortBy?.value) {
        onSortChange?.(null);
      } else {
        onSortChange?.(option);
      }
    },
    [sortBy, onSortChange]
  );

  return (
    <Flex gap='12px' mb='8px'>
      <SearchInput
        disabled={true}
        size='md'
        width='340px'
        hideShortcuts={true}
        placeholder={t('team:searchMember')}
        onChange={onSearch}
        value={searchQuery}
      />
      <Spacer />

      <Flex gap='12px'>
        <Box width='210px' zIndex='100'>
          <Dropdown
            placeholder={t('handbook:sort')}
            value={sortBy}
            onChange={_onSortChange}
            options={[
              // {
              //   label: t('handbook:filter.last_updated'),
              //   value: 'Last Updated',
              // },
              {
                label: t('handbook:filter.last_created'),
                value: 'Last Created',
              },
              {
                label: t('handbook:filter.alphabetically'),
                value: 'Alphabetically',
              },
              // { label: t('handbook:filter.active'), value: 'Active' },
              // { label: t('handbook:filter.inactive'), value: 'Inactive' },
            ]}
            selectStyles={{
              singleValue: {
                color: '#6f767e',
                fontWeight: '600',
              },
            }}
            components={{
              SingleValue: (valProps) => {
                return (
                  <chakraComponents.SingleValue {...valProps}>
                    <Trans
                      t={t}
                      i18nKey='handbook:sort_by'
                      values={{
                        value: valProps.children,
                      }}
                    />
                  </chakraComponents.SingleValue>
                );
              },
            }}
          />
        </Box>

        <Box width='210px' zIndex='100'>
          <AuthRoleFilter filterBy={filterBy} onFilterChange={onFilterChange} />
        </Box>
      </Flex>
    </Flex>
  );
};

export default SearchFilterHeader;
