import React, { FC } from 'react';
import { AuthRole, withAuthorization } from '../../authorization';
import { AppRoute } from '../../routes';
import { DashboardContainer } from '../../sub-components';
import TaskDetails from 'sub-components/Launcher/tasks/task-details/TaskDetails';
import { TaskDetailsHeading } from 'sub-components/Launcher/tasks/components/task-details/components';

interface IProps {}

const LauncherTasksDetailsPage: FC<IProps> = () => {
  //catch the taskId form url params and get the task details from the api, TODO
  return (
    <DashboardContainer>
      <TaskDetails />
    </DashboardContainer>
  );
};

export default withAuthorization({
  permittedFor: 'user',
  permittedRoles: [AuthRole.SUPER_ADMIN, AuthRole.ADMIN],
  redirectTo: AppRoute.HOME_URL,
})(LauncherTasksDetailsPage);
