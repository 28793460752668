import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NOTIFICATIONS } from 'appRoutes';

interface IProps {}

const NotificationSettingsHeader: FC<IProps> = () => {
  const history = useHistory();

  const onBackClick = () => {
    return history.push(NOTIFICATIONS);
  };

  return (
    <Flex
      gap='10px'
      cursor='pointer'
      onClick={onBackClick}
      w='fit-content'
      mt={5}
    >
      <Flex flexDir='column' gap={2}>
        <Text fontWeight={600} fontSize='32px'>
          Notification centre settings
        </Text>
        <Flex align={'center'} gap={2}>
          <FontAwesomeIcon icon={faArrowLeft as IconProp} size='1x' />
          <Text fontWeight={400} fontSize='15px' color='#6F767E'>
            Back to Notification centre
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
NotificationSettingsHeader.displayName =
  'sc/NC/S/c/NotificationSettingsHeader.tsx';

export default NotificationSettingsHeader;
