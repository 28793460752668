import { Box, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import timer from 'assets/images/dashboard/timer.svg';
import { PhaseTask } from './LauncherLocation.graphql';
import moment from 'moment';

interface IProps {
  status: 'current' | 'last' | 'upcoming';
  task: PhaseTask;
}

const TaskViewCard: FC<IProps> = ({ status, task }) => {
  const getColorByStatus = () => {
    switch (status) {
      case 'current':
        return '#8E59FF';
      case 'last':
        return '#2A85FF';
      case 'upcoming':
        return '#6F767E';
      default:
        return '#6F767E';
    }
  };

  const getSchedule = () => {
    if (status === 'current') {
      const sinceText = moment(task.startDate).utc().fromNow(true);
      return `Since ${sinceText}`;
    } else if (status === 'upcoming') {
      return 'After current task';
    } else if (status === 'last') {
      const sinceText = moment(task.completedAt).fromNow(true);
      return `${sinceText} ago`;
    }
  };

  return (
    <Box
      borderRadius='.75rem'
      padding='1rem'
      boxShadow='0px 0px 4.7px 0px #00000029'
      width='100%'
    >
      <Box display='flex' justifyContent='space-between'>
        <Text fontSize={14} fontWeight={600} color={getColorByStatus()}>
          {status === 'current'
            ? 'Current Task'
            : status === 'last'
            ? 'Last Task'
            : 'Upcoming task'}
        </Text>
        <Box display='flex' gap='.25rem' alignItems='center'>
          <Box
            as='img'
            src={timer}
            alt='Time for the task'
            height='24px'
            width='24px'
          />
          <Box as='span' fontSize={14} fontWeight={600} color='#33383F'>
            {getSchedule()}
          </Box>
        </Box>
      </Box>
      <Text
        color='#6F767E'
        fontWeight={500}
        fontSize={14}
        marginTop='.2rem'
        lineHeight='24px'
      >
        {task.title}
      </Text>
    </Box>
  );
};

TaskViewCard.displayName =
  'sub-components/Launcher/dashboard/Location/TaskViewCard';

export default TaskViewCard;
