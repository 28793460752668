import moment from 'moment';
import { match } from 'ts-pattern';
import isEmpty from 'lodash/isEmpty';

import { toArray } from '../../../../../utils';

import { IFormInput, IWeekDays, Weekdays } from '../../task.types';

import { getPublishDate as _getPublishDate } from '../../validation/helper';

const sortWeekdays = <T extends IWeekDays>(a: T, b: T) => {
  return Weekdays.indexOf(a) - Weekdays.indexOf(b);
};

const findNextWeekDay = (
  weekdays: IWeekDays[] = [],
  inp?: moment.Moment
): moment.Moment => {
  const res =
    weekdays.find((it) => moment().isoWeekday(it).isAfter(inp)) || weekdays[0];

  const next = inp || moment();

  if (
    next
      .clone()
      .startOf('isoWeek')
      .day(res)
      .isBefore(inp || moment(), 'date')
  ) {
    return next.startOf('isoWeek').day(res).add(1, 'week');
  } else {
    return next.day(res);
  }
};

export const getDueDate = (values: IFormInput): moment.Moment | undefined => {
  const DT = moment(values.dueTime);
  const PT = _getPublishDate(
    values.startDateType,
    values.startDate,
    values.startTime
  );
  switch (values?.taskRunFor) {
    case 'once':
      return match(values?.taskDue!)
        .with('nextWeek', () => {
          if (values.dueTime) {
            return moment()
              .startOf('isoWeek')
              .add(1, 'week')
              .day('monday')
              .minutes(DT.minutes())
              .hours(DT.hours());
          }
          return undefined;
        })
        .with('endOfMonth', () => moment().endOf('month'))
        .with('endOfYear', () => moment().endOf('year'))
        .with('date', () => {
          if (values.taskDueDate && values.dueTime) {
            return moment(values.taskDueDate)
              .minutes(DT.minutes())
              .hours(DT.hours());
          }
          return undefined;
        })
        .otherwise(() => undefined);
    case 'repeat':
      return match(values?.repeatCycle!)
        .with('daily', () => {
          return PT.minutes(DT.minutes()).hours(DT.hours());
        })
        .with('weekly', () => {
          const weekdays = toArray(values.weekDays).sort(sortWeekdays);

          return findNextWeekDay(weekdays, PT)
            .minutes(DT.minutes())
            .hours(DT.hours());
        })
        .with('monthly', () => {
          if (values.repeatMonthly === 'lastDay') {
            return PT.endOf('month').minutes(DT.minutes()).hours(DT.hours());
          }
          const repeatDate = moment(values.repeatMonthly).date();

          if (PT.date() > repeatDate) {
            PT.add(1, 'month');
          }
          return PT.date(repeatDate).minutes(DT.minutes()).hours(DT.hours());
        })
        .with('yearly', () => {
          const nextDate = moment(values.repeatYearly).isBefore(PT)
            ? moment(values.repeatYearly).add(1, 'year')
            : moment(values.repeatYearly);
          return PT.year(nextDate.year())
            .date(nextDate.date())
            .month(nextDate.month())
            .minutes(DT.minutes())
            .hours(DT.hours());
        })
        .with('days', () => {
          return PT.add(values.noOfDays, 'day');
        })
        .otherwise(() => undefined);
    default:
      return undefined;
  }
};

export const getPublishDate = (
  values: IFormInput
): moment.Moment | undefined => {
  switch (values?.startDateType) {
    case 'date':
      return _getPublishDate(
        values.startDateType,
        values.startDate,
        values.startTime
      );
    case 'now':
      return moment();
    default:
      return undefined;
  }
};

export const convertMinutes = (minutes: number = 0): string => {
  const values: string[] = [];
  const days = Math.floor(minutes / 1440);

  if (days) {
    values.push(`${days} days`);
  }

  // Calculate the number of hours
  const hours = Math.floor((minutes % 1440) / 60);

  if (hours) {
    values.push(`${hours} hrs`);
  }

  // Calculate the number of minutes
  const _minutes = minutes % 60;
  if (_minutes || isEmpty(values)) {
    values.push(`${_minutes} mins`);
  }

  return values.join(', ');
};
