import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { SelectOption } from 'atoms';
import { useMutation } from '@apollo/client';
import {
  RemoveCategoryQuery,
  RemoveCategoryResponse,
  RemoveCategoryVariable,
} from 'sub-components/Sidebar/category/add-category.graphql';
import { useTranslation } from 'react-i18next';
import { useUserData } from 'hooks';
import EmptyContainer from './EmptyContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowUpRight,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { getChaptersList, getFoldersList } from 'shared/graphql/SharedGraphql';
import { GET_CHAPTERS } from 'pages/Chapters/chapters.graphql';

const EmptyContainerModal: FC<{
  open: boolean;
  goBack: () => void;
  onClose: () => void;
  onDeleteSuccess: () => void;
  selectedFolderData: SelectOption<string> | undefined;
}> = ({ open, goBack, onClose, onDeleteSuccess, selectedFolderData }) => {
  const { t } = useTranslation();
  const entityId = useUserData()?.entityId;
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });
  const onFetchFoldersListSuccess = () => {
    onDeleteSuccess();
  };
  const { execute: fetchFoldersList, loading: gettingFoldersList } =
    getFoldersList(onFetchFoldersListSuccess);
  const [deleteCategory, { loading }] = useMutation<
    RemoveCategoryResponse,
    RemoveCategoryVariable
  >(RemoveCategoryQuery, {
    onCompleted: (response) => {
      if (response?.removeCategoryAnyway) {
        toast({
          status: 'success',
          title: t('success'),
          description: 'Sub folder deleted successfully',
        });
        fetchFoldersList();
      }
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('error'),
        description: 'Sub Folder could not be deleted',
      });
      onDeleteSuccess();
    },
    refetchQueries: [{ query: GET_CHAPTERS }],
    awaitRefetchQueries: true,
  });
  const deleteAnywayHandler = () => {
    if (!selectedFolderData) return;
    deleteCategory({
      variables: {
        eid: entityId,
        // category: data.name,
        categoryId: selectedFolderData.value,
      },
    });
  };
  return (
    <Modal isOpen={open} onClose={onClose} isCentered size='lg'>
      <ModalOverlay />
      <ModalContent p={3} borderRadius='12px'>
        <ModalHeader>
          <Box>
            <Flex gap='12px' align='center'>
              <Box cursor='pointer'>
                <FontAwesomeIcon
                  icon={faArrowLeft as IconProp}
                  fontSize='17px'
                  onClick={goBack}
                />
              </Box>
              <Box
                flex={1}
                fontWeight='600'
                fontSize='20px'
                lineHeight='32px'
                color='#1a1d1f'
                userSelect='none'
              >
                Move chapters
              </Box>
              <ModalCloseButton m={3} />
            </Flex>
            <Flex
              mt={3}
              fontSize='14px'
              fontWeight={400}
              color='#33383F'
              bg='rgba(177, 229, 252, 0.4)'
              py='12px'
              px='16px'
              borderRadius='10px'
              gap='12px'
              align='center'
            >
              <FontAwesomeIcon
                icon={faArrowUpRight as IconProp}
                color='#2A85FF'
              />
              Moving chapters will remove them from their current subfolder.
            </Flex>
          </Box>
        </ModalHeader>
        <ModalBody>
          <EmptyContainer
            close={() => {}}
            loading={loading || gettingFoldersList}
            name={selectedFolderData?.label!}
            onDeletePress={deleteAnywayHandler}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

EmptyContainerModal.displayName =
  'displayName:pages/Chapters/CreateEditSubFolder/components/EmptyContainerModal';

export default EmptyContainerModal;
