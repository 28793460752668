export const HOME_URL = '/';

export const PROFILE_URL = '/profile';

// Chapters
export const CHAPTER_VIEW = '/chapters/view/:chapterId';

// Forms
export const FORMS_URL = '/forms';
export const FORM_SUBMIT = '/form/submit/:id';

export const SETTINGS_URL = '/settings';
export const CHAT_URL = '/chat';
export const HANDBOOK_URL = '/handbook/:folder?';
// export const CHAPTERS_URL = '/folders';
export const CHAPTER_URL = '/folders/:folderId?/:subFolderId?';
// export const SUB_FOLDER = '/folders/:folderId/:subFolderId';
export const REPORT_URL = '/reports';
export const TRAINING_URL = '/training';
export const TRAINING_DRAFT_URL = '/drafts/list';
export const HANDBOOK_HOME = '/handbook';
export const CHAPTER_HOME = '/folders';
export const TRAINING_EDIT_URL = '/training/:id/edit';
export const TRAINING_PLAY_URL = '/training-play/:trainingId';
export const TRAINING_PATH_URL_ITERATION_ONE = '/training/play/:trainingId';

export const INITIALIZE = '/initializing';
export const GET_STARTED = '/get-started';

export const TEAMS_HOME = '/teams';
export const TEAMS_PAGE = '/teams/:teamType';

export const MEMBERS_PAGE = '/teams/members';
export const JOBS_PAGE = '/teams/jobs';

// MEMBER_PAGE path  is ==> /teams/members/:memberId
export const MEMBER_PAGE = '/teams/:teamType/:memberId';
// Tasks

export const TASK_DASHBOARD = '/tasks/dashboard';
export const TASKS_CREATE_URL = '/tasks/create-task';
export const TASKS_EDIT_URL = '/tasks/edit/:taskId';

export const TASKS_URL = '/tasks/my-tasks';
export const TASKS_PAGE = '/tasks/:taskType?';
// export const TASK_DETAILS_WITHOUT_SESSSION = '/tasks/supervised/details/:id';

export const TASK_DETAILS = '/tasks/supervised/details/:id/:sessionId?';

export const TASK_PLAY_URL =
  '/tasks/my-tasks/play/:taskId/:id/:sessionId/:type';

export const REVENUE_DASHBOARD = '/revenue-dashboard';
// Task Performance

export const ASSIGNEE_PERFORMANCE = '/tasks/performance';
export const JOB_PERFORMANCE = '/tasks/performance/job';
export const LOCATION_PERFORMANCE = '/tasks/performance/location';
export const LOCATIONS_PAGE = '/locations';

export const ADD_LOCATION = '/locations/add-location';
export const ADD_EXISTING_LOCATION = '/locations/add-existing-location';
export const REVIEW_ADD_LOCATION = '/locations/review-add/:locationId';
export const ADD_PRE_LAUNCH_LOCATION = '/locations/add-pre-launch-location';
export const EDIT_LOCATION = '/locations/edit/:locationId';
export const LOCATION_DETAIL = '/locations/:locationId';

// Launcher Services
export const LAUNCHER_URL = '/launcher';
export const LAUNCHER_DASHBOARD = '/launcher/dashboard';
export const LAUNCHER_ADD_LOCATION = '/launcher/add-location';
export const LAUNCHER_ADD_LOCATION_FROM_BOARD =
  '/launcher/add-location-from-board/:launchId';
export const LAUNCHER_PRE_LAUNCH_TO_LAUNCHING =
  '/launcher/pre-launch-to-launching/:locationId';
export const LAUNCHER_SETUP = '/launcher/setup';
export const LAUNCHER_OPENING_TASKS = '/launcher/opening-tasks';
export const LAUNCHER_BOARDS = '/launcher/boards';
export const LAUNCHER_BOARD_CONFIG = '/launcher/boards/config/:boardId?';
export const LAUNCHER_TASKS = '/launcher/tasks';
export const LAUNCHER_TASKS_DETAILS = '/launcher/tasks/details/:taskId';
export const LAUNCHER_DOCUMENT_LISTING =
  '/launcher/location/:locationId/documents';

/**
 * @deprecated
 *
 * Use **LAUNCHER_OPENING_TASKS** with query-params
 * @example /launcher/opening-tasks?viewMode=editor
 */
export const LAUNCHER_CONFIG_EDITOR = '/launcher/config-editor';

export const LAUNCHER_DETAIL = '/launcher/detail/:locId';

export const LAUNCHER_LOCATION_DETAIL = '/launcher/location/:locationId';

export const MY_LAUNCHING_LOCATION_TASK_DETAILS =
  '/launcher/my-tasks/:locationId/:launchId/:taskId';

export const MANAGE_CHANNEL = '/manage-channel';

// Audits
export const AUDIT_LIST = '/audits';
export const CREATE_AUDIT_TEMPLATE = `${AUDIT_LIST}/template/create` as const;
export const EDIT_AUDIT_TEMPLATE =
  `${AUDIT_LIST}/template/:templateId/edit` as const;
export const SCHEDULE_AUDIT = `${AUDIT_LIST}/schedule/:templateId?` as const;
export const AUDIT_SETTINGS = `${AUDIT_LIST}/settings/:auditId` as const;
export const AUDIT_QUESTIONS_EDIT =
  `${AUDIT_LIST}/questions/:auditId/edit` as const;
export const AUDIT_DETAILS =
  `${AUDIT_LIST}/details/:auditId/:sessionId/:locationId?` as const;
export const AUDIT_REPORT =
  `${AUDIT_LIST}/report/lI/:locationId/sI/:sessionId` as const;

export const PUBLIC_AUDIT_REPORT = `/audits/public-report/lI/:locationId/sI/:sessionId`;

// Nexus
export const NEXUS = '/nexus';

export const COMPLIANCE = '/compliance';
export const GALLERY = '/gallery';
export const LOCATIONS_RATING = '/locations-rating';

export const ASSETS = '/assets';

export const WALKTHROUGH_DOCS = '/walkthrough-docs';

// Notification center
export const NOTIFICATIONS = '/notifications';
export const NOTIFICATIONS_SETTINGS = '/notifications/settings';
