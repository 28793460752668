import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { capitalizeFirstLetter } from '../../../../../utils';
import { useLauncherConfigContext } from '../../common';
import { triggerKey } from '../../common/trigger';

interface IProps {
  sectionIndex: number;
}

const SectionDependDetails: FC<IProps> = ({ sectionIndex }) => {
  const data = useLauncherConfigContext((state) => {
    const value = state.config.contents?.[sectionIndex];

    const active = value?.trigger?.activationTrigger;

    return {
      trigger: active,
      phaseName:
        triggerKey[active?.dependentOn!] ||
        state.config.contents?.find((c) => c.eid === active?.itemId)
          ?.category ||
        '',
    };
  });

  if (!data.trigger?.order) {
    return null;
  }

  return (
    <Box fontSize='13px' fontWeight='600' color='#2A85FF' isTruncated>
      To be Unlocked: {capitalizeFirstLetter(data.trigger?.order)}{' '}
      {data.phaseName}
    </Box>
  );
};

SectionDependDetails.displayName =
  'sub-components/Launcher/launcher-config/board-view/sections/SectionDependDetails';

export default SectionDependDetails;
