import React, { FC } from 'react';
import BaseTriggerIntro, { IntroItem } from '../../trigger/BaseTriggerIntro';

interface IProps {}

const TaskTriggerIntro: FC<IProps> = () => {
  return (
    <BaseTriggerIntro
      title='Add task triggers'
      infoText='This task will be unlocked once a location is added, allowing assignees to complete tasks at any time before the go-live date, in any order.'
    >
      <IntroItem>Set when the task is visible to the assignee</IntroItem>
      <IntroItem>Set by when the assignee needs to complete it</IntroItem>
      <IntroItem>
        Set task dependencies <br />
        Ex: “Unlock this task after phase 3 is completed”
      </IntroItem>
      <IntroItem>
        Add task durations <br />
        i.e “complete this task within 15 days of activation”
      </IntroItem>
    </BaseTriggerIntro>
  );
};

TaskTriggerIntro.displayName = 'sc/L/lc/c/ctf/tts/TaskTriggerIntro';

export default TaskTriggerIntro;
