import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { Description, NotificationPreferencesContainer } from './index';

interface IProps {}

const NotificationSettingsContainer: FC<IProps> = () => {
  return (
    <Flex p={'24px'} bg={'#FFFFFF'} borderRadius={'12px'} mt={'24px'} w='full'>
      <Flex flexDir={'column'} gap={4} w='full'>
        <Description />
        <NotificationPreferencesContainer />
      </Flex>
    </Flex>
  );
};
NotificationSettingsContainer.displayName =
  'sc/NC/S/c/NotificationSettingsContainer.tsx';
export default NotificationSettingsContainer;
