import React, { FC } from 'react';
import {
  AlertDialogCloseButton,
  Center,
  Flex,
  useModalContext,
} from '@chakra-ui/react';

import { ActionButton, BoxHeader, ConfirmConfig } from '../../../ui-components';
import { HeaderColors } from '../../../configs';

interface IProps {
  callback: (ok: boolean) => void;
}

const Content: FC<IProps> = ({ callback }) => {
  const { onClose } = useModalContext();

  return (
    <Center flexDir='column' gap={5} pt={1}>
      <Flex fontSize='15px' fontWeight='600' color='#6F767E'>
        Changes made on the schedule page won’t be saved, but don’t worry—your
        updates to the template questions are safe. Are you sure you want to
        continue?
      </Flex>

      <Flex w='full' gap={3}>
        <ActionButton
          flex={1}
          actionFn={() => callback(true)}
          close={onClose}
          variant='outline'
        >
          Go back
        </ActionButton>

        <ActionButton
          flex={1}
          actionFn={() => callback(false)}
          close={onClose}
          colorScheme='blue'
        >
          Stay on this page
        </ActionButton>
      </Flex>
    </Center>
  );
};

export default function AuditScheduleAlert(
  callback: (ok: boolean) => void
): Partial<ConfirmConfig> {
  return {
    title: (
      <Flex justify='space-between' gap={3}>
        <BoxHeader title='Unsaved changes' color={HeaderColors.Purple} />
        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
          onClick={() => callback(false)}
        />
      </Flex>
    ),

    content: <Content callback={callback} />,
    isCentered: true,
    contentProps: {
      maxWidth: '640px',
    },
  };
}
