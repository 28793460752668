import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import DragDropComponent from './DragDropComponent';
import { IDocumentInput } from './compliance.input';
import UpdateDocumentForm from './UpdateDocumentForm';

interface IProps {
  complianceId?: string;
  type?: 'update' | 'reminder';
  preDefinedLocationId?: string;
}

const UpdateDocument: FC<IProps> = ({
  complianceId,
  type,
  preDefinedLocationId,
}) => {
  const file = useWatch<IDocumentInput, 'file'>({
    name: 'file',
  });

  const isUploaded = !file?.rawFile;

  return (
    <Flex flexDir='column' gap={3}>
      <Flex flexDir='column' gap={2}>
        <Text fontWeight={600} fontSize='15px' color='#1A1D1F'>
          {isUploaded ? 'Uploaded document' : 'Upload document'}
        </Text>

        <DragDropComponent
          type={type}
          subTitle='JPG, PNG, PDF &bull; Max size 10 MB'
          accept={{
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'application/pdf': ['.pdf'],
          }}
        />
      </Flex>

      {file && (
        <UpdateDocumentForm
          preDefinedLocationId={preDefinedLocationId}
          type={type}
          complianceId={complianceId}
        />
      )}
    </Flex>
  );
};

UpdateDocument.displayName =
  'displayName:sub-components/nexus/Compliance/Create/components/common/UpdateDocument';

export default UpdateDocument;
