import { toArray } from 'utils/utils';

import { IFormInput } from './add-location.types';
import {
  CreateLocationInputs,
  LauncherAssigneeType,
  LocationInputs,
} from './add-location.graphql';
import moment from 'moment';

export const addLocationFormat = (
  data: IFormInput,
  launcherId: string
): CreateLocationInputs => {
  const _supervisors: Set<string> = new Set();
  data?.checkListData?.forEach((listData) => {
    listData?.assignedToUsers?.forEach((user) => {
      if (user?.eid) {
        _supervisors.add(user.eid);
      }
    });
  });
  let supervisorsArray = Array.from(_supervisors);
  return {
    details: {
      address: {
        address: data.address,
        city: data.city,
        state: data.state,
        zipCode: data.zipCode,
      },
      countryOfFormation: data.countryOfFormation?.value || 'USA',
      entityName: data.entityName,
      entityType: data.entityType?.value,
      files: [],
      locationEmail: toArray(data.locationEmail?.map((v) => v.email)).filter(
        Boolean
      ),
      locationName: data.locationName,
      locationPhone: toArray(data.locationPhone?.map((v) => v.phone)).filter(
        Boolean
      ),
      locationStatus: data.locationStatus.value,
      locationType: data.locationType,
      stateOfFormation: data.stateOfFormation?.value || '',
      taxPayerId: data.taxPayerId,
      timezone: data.timezone?.value,
    },
    launcherId: launcherId,
    liveDate: moment.utc(data?.startDate)?.startOf('day')?.toISOString(),
    // password: data.password!,
    supervisors: supervisorsArray,
    // taskLists: data.checkList,
    // username: data.username,
    phases: data?.selectedBoardData?.map((data) => {
      return {
        assignedType: data?.assignedType || 'location',
        assignedUser: data?.assignedUser || [],
        eid: data?.eid,
        isCompleted: data?.selectedChoice === 'markComplete',
        selected:
          data?.selectedChoice === 'phaseRequired' || !data?.selectedChoice,
        tasks: data?.tasks?.map((task) => ({
          assignedType: task?.assignedType || 'location',
          assignedUser: task?.assignedUser || [],
          eid: task?.eid,
        })),
      };
    }),
    // phases: data?.checkListData?.map((checkListData) => {
    //   return {
    //     assignees:
    //       checkListData?.assignedToUsers
    //         ?.map((user) => user?.eid || '')
    //         ?.filter(Boolean) || [],
    //     phaseEid: checkListData?.id,
    //     selected: checkListData?.selected,
    //   };
    // }),
  };
  // return {
  //   // Basic details
  //   locationType: data.locationType,
  //   name: data.locationName,
  //   locationStatus: data.locationStatus?.value,
  //   locationEmail: toArray(data.locationEmail?.map((v) => v.email)).filter(
  //     Boolean
  //   ),
  //   locationPhone: toArray(data.locationPhone?.map((v) => v.phone)).filter(
  //     Boolean
  //   ),
  //   locationOwners: toArray(
  //     data.locationOwners?.map((v) => v.owner?.value)
  //   ).filter(Boolean),

  //   // Login Details
  //   username: data.username,
  //   password: data.password!,

  //   // Address
  //   address: {
  //     address: data.address,
  //     city: data.city,
  //     state: data.state,
  //     zipCode: data.zipCode,
  //   },
  //   timezone: data.timezone,

  //   // Entity details
  //   entityName: data.entityName,
  //   entityType: data.entityType?.value,
  //   countryOfFormation: data.countryOfFormation?.value!,
  //   stateOfFormation: data.stateOfFormation?.value!,
  //   taxPayerId: data.taxPayerId,

  //   // Other details
  //   otherFields: toArray(data.otherFields).map((field) => {
  //     return {
  //       fieldId: field.fieldId,
  //       value: field.value,
  //       files: field.files || [],
  //       options: toArray(field.options)
  //         .map((value) => {
  //           return value?.value;
  //         })
  //         .filter(Boolean),
  //     };
  //   }),
  // };
};
