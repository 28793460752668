import React, { FC } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Radio,
  RadioGroup,
  useModalContext,
  useToast,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Input } from '../../../../../atoms';
import { ActionButton } from '../../../../../ui-components';
import TitleHeader from '../../../../CardEditor/TitleHeader';
import { useDuplicateBoardMutation } from '../../board-view';
import BoardSelect from './BoardSelect';
import { AddBoardInput } from './board-list.types';

interface ItemProps {
  title: string;
  value: string;
  caption: string;
}

const RadioItem: FC<ItemProps> = ({ title, value, caption, children }) => {
  return (
    <Flex gap={4}>
      <Radio size='lg' value={value} />
      <Flex flex={1} flexDir='column'>
        <Box fontSize='15px' fontWeight='600' color='#1A1D1F'>
          {title}
        </Box>
        <Box fontSize='12px' fontWeight='400' color='#6F767E'>
          {caption}
        </Box>
        {children}
      </Flex>
    </Flex>
  );
};

interface IProps {
  onCreateFromScratch?: (values: AddBoardInput) => void;
}

const AddBoardContent: FC<IProps> = ({ onCreateFromScratch }) => {
  const { onClose } = useModalContext();
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<AddBoardInput>({
    defaultValues: {
      title: '',
    },
  });

  const [duplicateConfig] = useDuplicateBoardMutation({
    onCompleted: (data) => {
      onCreateFromScratch?.({
        type: 'duplicate',
        launcherId: data?.eid,
        title: data?.title,
      });
    },
  });

  const submitProxy = async (values: AddBoardInput) => {
    if (values.type === 'scratch') {
      return onCreateFromScratch?.(values);
    }

    const res = await duplicateConfig({
      variables: { eid: values.launcherId, title: values.title },
    });

    if (res.errors) {
      return Promise.reject(res.errors);
    }
  };

  return (
    <Flex flexDir='column' gap={6}>
      <Flex flexDir='column' gap={3}>
        <TitleHeader title='New board title' isRequired />

        <Controller
          control={control}
          name='title'
          rules={{
            required: 'Board name is required',
            validate: (value) => {
              if (value && value?.trim()?.length === 0) {
                return 'Invalid board name';
              }
            },
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              <Input size='lg' {...field} />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />
      </Flex>

      <Controller
        control={control}
        name='type'
        rules={{
          required: 'Please select a option',
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid}>
            <RadioGroup display='flex' flexDir='column' gap={6} {...field}>
              <RadioItem
                value='duplicate'
                title='Duplicate existing board'
                caption='Note: This will include all phase and task settings'
              >
                <BoardSelect
                  isSelected={field.value === 'duplicate'}
                  control={control}
                />
              </RadioItem>

              <RadioItem
                value='scratch'
                title='Start from scratch'
                caption='Add new phases and tasks'
              />
            </RadioGroup>
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />

      <Flex gap={4}>
        <Button flex={1} size='lg' variant='outline' onClick={onClose}>
          Cancel
        </Button>
        <ActionButton
          flex={1}
          size='lg'
          colorScheme='blue'
          isDisabled={!isDirty}
          actionFn={handleSubmit(submitProxy)}
        >
          Create board
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default AddBoardContent;
