import React, { FC, useContext, useMemo, useState } from 'react';
import { Flex, Image, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import moment from 'moment';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthRole } from 'authorization';
import { getImage, shallowEqual } from 'utils';
import { MessageIconButton, SearchInput } from 'atoms';
import { useUserDataSelector } from 'hooks';

import {
  Column,
  SortingTable,
} from '../../../../../sub-components/ChakraTable/SortingTable';
import GenericColorHeader from '../../../../../sub-components/GenericColorHeader';
import SupervisedTaskDetailsDataContext from '../../store/supervised-task-details-context';
import ImageText from '../../../Components/sub-components/ImageText';
import IconText from '../../../Components/sub-components/IconText';
import SingleUserChatModal from 'ui-components/SingleUserChatModal';
import PerformanceMemberName from '../../../../../sub-components/tasks/performance/common/PerformanceMemberName';
import EmptyState from 'sub-components/EmptyState';
import { IAssigneeUsers } from '../../utils/utils';
import { useHasChatAccess } from 'hooks/useChatRestriction';

const Wrapper = styled.div`
  table {
    width: 100% !important;
    background: #ffffff;
  }

  padding-bottom: 5px;

  table > tbody > tr:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }

  .sop-list-loader {
    height: 250px;
  }
`;

interface TaskAssigneesItem {
  eid: string;
  assigneeName: string;
  profilePic: string;
  progress: {
    completedTasks: number;
    totalTasks: number;
  };
  authRole: string;
  role: string;
  location: string;
  createdBy: {
    name: string;
    profilePic: string;
  };
  assignedOn: string;
}

const TaskAssignees: FC = () => {
  const hasChatAccess = useHasChatAccess();
  const supervisedCtx = useContext(SupervisedTaskDetailsDataContext);
  const userData = useUserDataSelector(
    (state) => ({
      authRole: state.authRole,
      locations: state.locations,
    }),
    shallowEqual
  );

  const [selectedUserId, setSelectedUserId] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const getAssigneeData: TaskAssigneesItem[] = useMemo(() => {
    let _assigneeUsers: IAssigneeUsers[] = JSON.parse(
      JSON.stringify(
        supervisedCtx?.taskDetails?.lastSession?.assigneeUsers ||
          supervisedCtx?.taskDetails?.assignedToUsers ||
          []
      )
    );
    let assigneeUsers: IAssigneeUsers[] = [];
    let progress = JSON.parse(
      JSON.stringify(supervisedCtx?.taskDetails?.lastSession?.progress || [])
    );
    let totalCount =
      supervisedCtx?.taskDetails?.lastSession?.itemCount ||
      supervisedCtx?.taskDetails?.taskItems?.length ||
      0;
    let assigneeData: TaskAssigneesItem[] = [];
    if (userData?.authRole === AuthRole.LOCATION_OWNER) {
      let locationDescendants = userData?.locations || [];
      const filteredAssigneeUsers = _assigneeUsers.filter((user: any) =>
        user.locations.some((location: any) =>
          locationDescendants.some(
            (descendant) => descendant.eid === location.eid
          )
        )
      );
      assigneeUsers =
        filteredAssigneeUsers.filter((assignee) =>
          ['active', 'pending']?.includes(assignee.status)
        ) || [];
    } else {
      assigneeUsers = _assigneeUsers.filter((assignee) =>
        ['active', 'pending']?.includes(assignee.status)
      );
    }
    if (supervisedCtx?.taskDetails?.lastSession) {
      assigneeUsers?.map((user: any) => {
        if (user?.type === 'branch') return;
        progress?.map((_progress: any) => {
          if (_progress?.userId === user?.eid) {
            assigneeData?.push({
              eid: user?.eid,
              assigneeName: user?.name || '-',
              profilePic: getImage(user?.profilePic, user?.name),
              progress: {
                completedTasks: _progress?.completedTasks?.length,
                totalTasks: totalCount,
              },
              authRole: user?.authRole || '-',
              role: user?.role || '-',
              location: user?.locations?.[0]?.name || '-',
              createdBy: {
                name: supervisedCtx?.taskDetails?.createdByUser?.name,
                profilePic: getImage(
                  supervisedCtx?.taskDetails?.createdByUser?.profilePic,
                  supervisedCtx?.taskDetails?.createdByUser?.name
                ),
              },
              assignedOn: moment(supervisedCtx?.taskDetails?.createdAt)?.format(
                'DD MMM'
              ),
            });
          }
        });
      });
    } else {
      assigneeUsers?.map((user: any) => {
        if (user?.type === 'branch') return;
        assigneeData?.push({
          eid: user?.eid,
          assigneeName: user?.name || '-',
          profilePic: getImage(user?.profilePic, user?.name),
          progress: {
            completedTasks: 0,
            totalTasks: totalCount,
          },
          authRole: user?.authRole || '-',
          role: user?.role || '-',
          location: user?.locations?.[0]?.name || '-',
          createdBy: {
            name: supervisedCtx?.taskDetails?.createdByUser?.name,
            profilePic: getImage(
              supervisedCtx?.taskDetails?.createdByUser?.profilePic,
              supervisedCtx?.taskDetails?.createdByUser?.name
            ),
          },
          assignedOn: moment(supervisedCtx?.taskDetails?.createdAt)?.format(
            'DD MMM'
          ),
        });
      });
    }
    if (!searchQuery) {
      return assigneeData;
    } else {
      return assigneeData?.filter((assignee) =>
        assignee?.assigneeName
          ?.toLowerCase()
          ?.trim()
          ?.includes(searchQuery?.toLowerCase()?.trim())
      );
    }
  }, [supervisedCtx?.taskDetails, searchQuery]);

  const columns = useMemo((): Column<TaskAssigneesItem>[] => {
    return [
      {
        Header: 'Name',
        accessor: 'assigneeName',
        width: 'calc(40% - 60px)',
        Cell: ({ cell: { value, row } }) => {
          return (
            <PerformanceMemberName
              name={value}
              role={row.original.role}
              authRole={row.original.authRole as never}
              profilePic={row.original.profilePic}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'Location',
        accessor: 'location',
        width: '18%',
        Cell: ({ cell: { value } }) => {
          return (
            <ImageText
              backgroundColor='#B5E4CA99'
              text={value}
              boldText
              isTruncated
              maxW='100px'
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'Assigned by',
        accessor: 'createdBy',
        width: '18%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <ImageText
              boldText
              backgroundColor='#CABDFF66'
              text={value?.name}
              img={
                <Image
                  src={value?.profilePic}
                  boxSize='20px'
                  borderRadius='5px'
                  marginRight='5px'
                />
              }
              isTruncated
              maxW='100px'
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'Assigned on',
        accessor: 'assignedOn',
        width: '12%',
        Cell: ({ cell: { value, row } }) => {
          return <Text as='b'>{value}</Text>;
        },
        disableSortBy: true,
      },
      {
        Header: 'Progress',
        accessor: 'progress',
        width: '12%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <IconText
              icon={
                <FontAwesomeIcon
                  icon={faCircleCheck as IconProp}
                  color={
                    value?.completedTasks === value?.totalTasks
                      ? '#83BF6E'
                      : '#FF6A55'
                  }
                />
              }
              text={`${value?.completedTasks}/${value?.totalTasks}`}
              textColor={
                value?.completedTasks === value?.totalTasks
                  ? '#83BF6E'
                  : '#FF6A55'
              }
              bg={
                value?.completedTasks === value?.totalTasks
                  ? '#B5E4CA33'
                  : '#FF6A5512'
              }
              padding='5px 10px'
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'eid',
        width: '60px',
        Cell: ({ cell: { value, row } }) => {
          return (
            <Flex justify='flex-end'>
              <MessageIconButton
                hasChatAccess={hasChatAccess({
                  eid: row?.original?.eid,
                  role: row?.original?.role,
                  authRole: row?.original?.authRole as AuthRole,
                })}
                onClick={() => setSelectedUserId(value)}
              />
            </Flex>
          );
        },
        disableSortBy: true,
      },
    ];
  }, []);

  return (
    <Flex bg='white' p={6} borderRadius='8px' flexDir='column' gap='20px'>
      <GenericColorHeader title='Assignees' color='#FFD88D' />
      <SearchInput
        placeholder='Search'
        value={searchQuery}
        hideShortcuts
        width={'400px'}
        onChange={(e) => setSearchQuery(e?.target?.value)}
      />
      <Wrapper>
        <SortingTable
          colorScheme='blue'
          page={1}
          onPageChange={() => {}}
          emptyData={{
            content: (
              <EmptyState
                image={searchQuery ? 'Search' : 'MemberTraining'}
                title={
                  searchQuery
                    ? 'Assignee not found'
                    : 'No members are assigned in your location'
                }
              />
            ),
          }}
          columns={columns}
          data={getAssigneeData}
          isLoading={false}
          totalRegisters={10}
          isResponsive
          isHidePagination
          onRowSelectChange={() => {}}
        />
      </Wrapper>
      {selectedUserId && (
        <SingleUserChatModal
          selectedUser={selectedUserId}
          onClose={() => setSelectedUserId('')}
        />
      )}
    </Flex>
  );
};

TaskAssignees.displayName =
  'displayName:pages/TasksDashboard/TaskDetails/components/TaskAssignees/TaskAssignees';

export default TaskAssignees;
