import React, { cloneElement, FC, Fragment } from 'react';
import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
} from '@chakra-ui/react';

import { IActionMenuData } from './action-menu.types';
import ActionMenuItem from './ActionMenuItem';
import MenuItemWrapper from './MenuItemWrapper';
import { useMountedRef } from '../../hooks';

interface IProps {
  arrowSize?: number;
  closeOnBlur?: boolean;
  offset?: [number, number];
  menuData?: Array<IActionMenuData>;
  strategy?: 'absolute' | 'fixed';
  clickedItem?: (
    name: string | undefined,
    value: string | undefined
  ) => void | Promise<void | null>;
  minWidth?: string;
}

const ActionMenu: FC<IProps> = ({
  menuData,
  children,
  arrowSize,
  closeOnBlur,
  offset,
  clickedItem,
  strategy,
  minWidth = '280px',
}) => {
  const mountedRef = useMountedRef();
  const itemClickHandler = async (
    menu: IActionMenuData,
    callback: () => void
  ) => {
    await clickedItem?.(menu?.name, menu.value);
    if (mountedRef.current) {
      callback();
    }
  };

  return (
    <Popover
      placement='bottom-end'
      arrowSize={arrowSize}
      closeOnBlur={closeOnBlur}
      offset={offset}
      strategy={strategy}
    >
      {({ onClose }) => (
        <>
          {/*// @ts-ignore*/}
          <PopoverTrigger>{children}</PopoverTrigger>
          <PopoverContent
            borderRadius='8px'
            width='unset'
            minW={minWidth || '280px'}
          >
            <PopoverArrow />
            <PopoverBody borderRadius='8px' boxShadow='lg' px='16px'>
              <Flex flexDir='column' py='8px' gap='1px'>
                {menuData?.map((menu, index) => (
                  <Fragment key={`${menu.value || menu.name}--${index}`}>
                    {menu.render ? (
                      menu.render({ menu })
                    ) : (
                      <MenuItemWrapper
                        menu={menu}
                        onClick={(e) => {
                          e.stopPropagation();
                          return itemClickHandler(menu, onClose);
                        }}
                      >
                        {menu.showChild ? (
                          <Stack>
                            <Flex gap={2}>
                              <ActionMenuItem menu={menu} />
                            </Flex>
                            <Box
                              as='span'
                              color={menu.descColor}
                              w={'250px'}
                              lineHeight={'18px'}
                            >
                              {menu.desc}
                            </Box>
                          </Stack>
                        ) : (
                          <ActionMenuItem menu={menu} />
                        )}
                      </MenuItemWrapper>
                    )}
                  </Fragment>
                ))}
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default ActionMenu;
