import React, { useState, FC } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useMutation, gql } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';

import { faEnvelope, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
// import scss
import './ForgotPassword.scss';

// import components
import AuthContainer from '../../sub-components/AuthContainer';
import FormInput from '../../atoms/FormInput';
import PrimaryButton from '../../atoms/PrimaryButton';
import { EMAIL_REGEX } from '../../utils/constants';
import { EmailEncoder } from '../../utils';

const FORGOT_PASSWORD_REQUEST = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email) {
      succeed
    }
  }
`;

const ForgotPassword: FC = () => {
  const { t } = useTranslation(['common', 'auth']);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>();
  const history = useHistory();
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
    getValues,
  } = useForm();

  const [resetPassword] = useMutation(FORGOT_PASSWORD_REQUEST, {
    onError: (error) => {
      setIsLoading(false);
      setError(error);
    },
  });

  const onFinish = (values: any) => {
    setError(null);
    setIsLoading(true);
    resetPassword({
      variables: {
        email: values.email,
      },
    }).then((response) => {
      if (response?.data?.resetPassword?.succeed) {
        const encodedEmail = EmailEncoder.encode(values.email);
        history.push(`/otp?email=${encodedEmail}`);
      }
      setIsLoading(false);
    });
  };

  const getErrorMessage = () => {
    if (error?.message) {
      return error?.message;
    }
    return '';
  };

  const getRightIcon = () => {
    if (!isSubmitted) return null;

    if (errors?.email) {
      return (
        <FontAwesomeIcon
          icon={faTriangleExclamation as IconProp}
          color={'#ff6a55'}
          style={{ cursor: 'pointer' }}
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          icon={faCheck as IconProp}
          color={'#83BF6E'}
          style={{ cursor: 'pointer' }}
        />
      );
    }
  };

  return (
    <AuthContainer>
      <div>
        <div className='login-heading-container'>
          {t('auth:forgot_password')}
        </div>
        <Flex justify='center'>
          <div
            className='login-sub-heading-container'
            style={{ width: '260px', marginBottom: '20px' }}
          >
            {t('auth:receive_reset_link')}
          </div>
        </Flex>
        <div className='login-form-parent-container'>
          <form
            className='login-form-container'
            onSubmit={handleSubmit(onFinish)}
          >
            <Controller
              name='email'
              control={control}
              rules={{
                // @ts-ignore
                required: t('validation.email_required'),
                pattern: {
                  value: EMAIL_REGEX,
                  message: t('validation.email_invalid'),
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState.error}>
                    <FormInput
                      isInvalid={!!fieldState.error}
                      size='lg'
                      leftIcon={faEnvelope as IconProp}
                      // @ts-ignore
                      placeholder={t('auth:your_email')}
                      rightIcon={getRightIcon()}
                      {...field}
                    />
                    <FormErrorMessage>
                      {fieldState?.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
            <PrimaryButton
              mt={4}
              type='submit'
              size='lg'
              isLoading={isLoading}
              disabled={!!errors.email}
              // @ts-ignore
              title={t('submit')}
              variant='solid'
              colorScheme='blue'
            />
            <div className='login-error-message'>{getErrorMessage()}</div>
          </form>
          <Link to='/login'>
            <div className='back-to-signin-container'>
              <div>
                <FontAwesomeIcon icon={faChevronLeft as IconProp} />
              </div>
              <div className='back-to-signin-text'>
                {t('auth:back_sign_in')}
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className='invite-already-member' />
    </AuthContainer>
  );
};

ForgotPassword.displayName = 'displayName:pages/ForgotPassword/index';

export default ForgotPassword;
