import React, { FC } from 'react';
import { Box, Flex, Spacer } from '@chakra-ui/react';
import get from 'lodash/get';
import {
  useLauncherConfigContext,
  useLauncherConfirm,
  useLauncherWarning,
} from '../../../common';
import LauncherTaskFooter from './LauncherTaskFooter';
import { TaskItemWrapper } from './task-item.styles';
import LauncherTaskAction from './LauncherTaskAction';
import TaskDependDetails from './TaskDependDetails';
import { useTaskItemWithFilter } from './useTaskItemWithFilter';

interface IProps {
  sectionIndex: number;
  taskIndex: number;
}

const LauncherTaskItem: FC<IProps> = ({ sectionIndex, taskIndex }) => {
  const task = useTaskItemWithFilter(sectionIndex, taskIndex);
  const maxLength = useLauncherConfigContext((state) => {
    return get(state.config.contents, [sectionIndex, 'tasks']).length;
  });
  const categories = useLauncherConfigContext((state) => state.taskCategory);
  const actions = useLauncherConfigContext((state) => ({
    openTaskForm: state.openTaskForm,
    deleteTask: state.deleteTask,
    moveTask: state.moveTask,
    haveLocation: !!state.config?.locations,
  }));

  const deleteConfirm = useLauncherConfirm();
  const warning = useLauncherWarning();

  const actionHandler = (action: string) => {
    switch (action) {
      case 'edit':
        actions.openTaskForm({
          sectionIndex,
          taskIndex,
          taskEid: task!.eid,
        });
        break;
      case 'add-above':
        actions.openTaskForm({
          sectionIndex,
          taskIndex: taskIndex,
          lastCategory: task!.category,
        });
        break;
      case 'add-below':
        actions.openTaskForm({
          sectionIndex,
          taskIndex: taskIndex + 1,
          lastCategory: task!.category,
        });
        break;
      case 'move-up':
        actions.moveTask(sectionIndex, taskIndex, taskIndex - 1);
        break;
      case 'move-down':
        actions.moveTask(sectionIndex, taskIndex, taskIndex + 1);
        break;
      case 'delete':
        {
          const isDefault =
            !task?.trigger || task?.trigger?.triggerChoice === 'default';

          if (actions?.haveLocation) {
            warning({
              header: 'Delete task permanently',
              title: 'This task has active locations working on it.',
              description:
                'Deleting this task will result to these locations losing their progress',
              buttonText: 'Delete task',
              onConfirm: () => actions.deleteTask(sectionIndex, taskIndex),
            });
          } else if (isDefault) {
            deleteConfirm({
              description:
                'Deleting this task will result it complete removal of the task and its progress.',
              buttonText: 'Yes, delete',
              onConfirm: () => actions.deleteTask(sectionIndex, taskIndex),
            });
          } else {
            deleteConfirm({
              title: 'This task has trigger dependencies!',
              description:
                'Deleting it will remove all triggers associated with it.',
              buttonText: 'Yes, delete',
              onConfirm: () => actions.deleteTask(sectionIndex, taskIndex),
            });
          }
        }
        break;
      default:
        // eslint-disable-next-line no-console
        console.log(action);
    }
  };

  const isLocked = task?.trigger?.triggerChoice === 'activation';
  const isRequired = task?.isRequired;

  if (!task) {
    return null;
  }

  return (
    <TaskItemWrapper
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: 'easeInOut', duration: 0.2 }}
    >
      <Flex flex={1} flexDir='column' overflow='hidden'>
        {isLocked ? (
          <TaskDependDetails
            sectionIndex={sectionIndex}
            taskIndex={taskIndex}
            isRequired={isRequired}
            title={task.title}
            trigger={task?.trigger?.activationTrigger!}
          />
        ) : (
          <Box fontSize='15px' fontWeight='500' color='#33383F'>
            {task.title}
            {isRequired ? <span style={{ color: 'red' }}> *</span> : null}
          </Box>
        )}
        <Box fontSize='12px' color='#6F767E' _empty={{ display: 'none' }}>
          {task.description}
        </Box>
        <Spacer />
        <LauncherTaskFooter
          category={categories[task.category]}
          stepsCount={task?.steps?.length}
          trigger={task.trigger}
        />
      </Flex>
      <LauncherTaskAction
        taskIndex={taskIndex}
        isLastTask={maxLength === taskIndex + 1}
        assignedType={task.assignedType}
        assignedUser={task.assignedUser}
        actionHandler={actionHandler}
      />
    </TaskItemWrapper>
  );
};

export default LauncherTaskItem;
