import { Center, Flex } from '@chakra-ui/react';
import React, { FC, useContext, useEffect, useState } from 'react';
import DashboardContainer from 'sub-components/DashboardContainer';
import ProgressRate from './ProgressRate/ProgressRate';
import TaskDetailsHeader from './TaskDetailsHeader';
import TaskOverviewRoot from './TaskOverview';
import TaskSupervisors from './TaskSupervisors/TaskSupervisors';
import TaskLocations from './TaskLocations/TaskLocations';
import TaskAssignees from './TaskAssignees/TaskAssignees';
import { getAllSessionsForTask } from '../services/task-details.service';
import { useParams } from 'react-router-dom';
import Details from './Details';
import {
  ISessionById,
  ISessionByIdArgs,
  ISupervisedTasksEntity,
} from 'pages/TasksDashboard/tasks.types';
import SupervisedTaskDetailsDataProvider from '../store/SupervisedTaskDetailsProvider';
import SupervisedTaskDetailsDataContext from '../store/supervised-task-details-context';
import Loader from 'ui-components/Loader';
import LocationWisePerformance from './Graphs/LocationWisePerformance';
import JobPerformance from './Graphs/JobPerformance';
import TaskTimeline from './TaskTimeline';
import moment from 'moment-timezone';
import { useLazyQuery } from '@apollo/client';
import {
  GET_SESSION_BY_ID,
  TASK_BY_ID,
} from '../services/task-details.graphql';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import { TaskItemEntity } from './TaskOverview/task-item.types';
import { showTimeText } from './Details/Details';
import { taskRepeat } from 'utils/taskRepeat';
import { RepeatDetails } from 'sub-components/tasks/create-task/task.types';

interface IProps {
  id: string;
  sessionId: string;
}

const TaskDetails: FC<IProps> = ({ id, sessionId: initialSessionId }) => {
  const [selectedDateString, setSelectedDateString] = useState('');
  const supervisedCtx = useContext(SupervisedTaskDetailsDataContext);
  const [showTimeline, setShowTimeline] = useState(false);
  const [sessionId, setSessionId] = useState<string>('');

  const generateDataToTrack = (data: ISupervisedTasksEntity) => {
    const totalSteps: TaskItemEntity[] =
      data?.lastSession?.taskItems || data?.taskItems || [];
    const totalForms =
      totalSteps?.filter((item) => item?.type === 'form')?.length || 0;
    const totalChapters =
      totalSteps?.filter((item) => item?.type === 'sop')?.length || 0;
    const totalToDoItems =
      totalSteps?.filter((item) => item?.type === 'todo')?.length || 0;
    const progressRate = data?.lastSession?.progressRate || 0;
    const dueDate = data?.lastSession?.dueDate
      ? showTimeText(moment?.utc(data?.lastSession?.dueDate), data?.timezone)
      : '-';
    const repeat = taskRepeat(
      data?.repeatDetails as unknown as RepeatDetails<Date>
    );
    return {
      task_id: id,
      total_steps: totalSteps?.length || 0,
      total_forms: totalForms,
      total_chapters: totalChapters,
      total_to_do_items: totalToDoItems,
      completion_rate: progressRate,
      status: '',
      due_date: dueDate,
      days_till_due_date: '',
      repeat: repeat,
    };
  };

  const eventTrack = (data: ISupervisedTasksEntity) => {
    deployEvent(
      AmplitudeEventNames.TASK_PERFORMANCE_DASHBOARD_VISIT,
      generateDataToTrack(data)
    );
  };

  const [executeTaskById, { loading: taskByIdLoading }] = useLazyQuery<{
    TaskById: ISupervisedTasksEntity;
  }>(TASK_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      supervisedCtx?.taskSupervisedDetailsHandler(
        data?.TaskById as ISupervisedTasksEntity
      );
      if (!sessionId) {
        setSelectedDateString(
          moment?.utc(data?.TaskById?.lastSession?.dueDate)?.format('DD MMM')
        );
        eventTrack(data?.TaskById);
      } else {
        fetchSpecificSession(sessionId);
      }
    },
  });

  const [executeSessionById, { loading: sessionByIdLoading }] = useLazyQuery<
    ISessionById,
    ISessionByIdArgs
  >(GET_SESSION_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      supervisedCtx?.taskSupervisedDetailsHandler({
        ...supervisedCtx?.taskDetails,
        lastSession: data?.SessionById,
      });
      setSelectedDateString(
        moment?.utc(data?.SessionById?.dueDate)?.format('DD MMM')
      );
      eventTrack({
        ...supervisedCtx?.taskDetails,
        lastSession: data?.SessionById,
      });
    },
  });

  const {
    execute: executeAllSessionsForTask,
    loading: allSessionsForTaskLoading,
    error: allSessionsForTaskError,
    data: allSessionsForTaskData,
  } = getAllSessionsForTask();

  useEffect(() => {
    if (!sessionId) {
      setSessionId(initialSessionId);
    }
  }, [initialSessionId, sessionId]);

  useEffect(() => {
    executeAllSessionsForTask({
      variables: {
        page: 1,
        perPage: 100,
        filter: {
          taskId: id,
        },
        sort: '_ID_DESC',
      },
    });

    executeTaskById({
      variables: {
        eid: id,
      },
    });
  }, []);

  useEffect(() => {
    if (!sessionId) return;
    executeSessionById({
      variables: {
        eid: sessionId,
      },
    });
  }, [sessionId]);

  const shouldRender = (component: string): boolean => {
    let _taskDetails = supervisedCtx?.taskDetails;
    let assignedLocationsType = _taskDetails?.assignedLocationsType;
    let assignedTo = _taskDetails?.assignedTo;
    let assignedToLocations = _taskDetails?.assignedToLocations;
    let assignedToRoles = _taskDetails?.assignedToRoles;

    const conditions: any = {
      LocationWisePerformance:
        assignedLocationsType !== 'locationUser' ||
        assignedToLocations?.length === 0 ||
        assignedTo?.length > 0 ||
        assignedToRoles?.length > 0,

      JobPerformance:
        assignedLocationsType !== 'locationUser' ||
        assignedToLocations?.length === 0 ||
        assignedTo?.length > 0 ||
        assignedToRoles?.length > 0,

      TaskAssignees:
        assignedLocationsType !== 'locationUser' ||
        assignedToLocations?.length === 0 ||
        assignedTo?.length > 0 ||
        assignedToRoles?.length > 0,

      TaskLocations:
        assignedLocationsType === 'locationUser' &&
        assignedToLocations?.length > 0,
    };

    return conditions[component] || false;
  };

  const fetchSpecificSession = (sessionId: string) => {
    setSessionId(sessionId);
    executeSessionById({
      variables: {
        eid: sessionId,
      },
    });
  };

  return (
    <>
      {!taskByIdLoading && !sessionByIdLoading ? (
        <Flex flexDir='column' gap='20px'>
          <TaskDetailsHeader
            historyData={(
              allSessionsForTaskData?.SessionPagination?.items || []
            )?.slice(0, 5)}
            isMore={(allSessionsForTaskData?.SessionPagination?.count || 0) > 5}
            selectedDateString={selectedDateString}
            setSelectedDateString={setSelectedDateString}
            setShowTimeline={setShowTimeline}
            setSessionId={setSessionId}
            fetchSpecificSession={fetchSpecificSession}
          />
          <Flex gap='20px'>
            <Flex
              id='task-details-root-left'
              flexDir='column'
              w='60%'
              maxH='100vh'
            >
              <TaskOverviewRoot sessionId={sessionId} />
            </Flex>
            <Flex flexDir='column' w='40%' gap='20px'>
              <ProgressRate />
              <Details />
              <TaskSupervisors />
            </Flex>
          </Flex>
          <Flex gap='20px' w='full'>
            {shouldRender('LocationWisePerformance') && (
              <LocationWisePerformance taskId={id} sessionId={sessionId} />
            )}
            {shouldRender('JobPerformance') && (
              <JobPerformance taskId={id} sessionId={sessionId} />
            )}
          </Flex>
          {shouldRender('TaskLocations') && <TaskLocations />}
          {shouldRender('TaskAssignees') && <TaskAssignees />}
          <TaskTimeline
            isOpen={showTimeline}
            onClose={() => setShowTimeline(false)}
            allSessionsForTaskData={allSessionsForTaskData}
            setSessionId={setSessionId}
            selectedDateString={selectedDateString}
            setSelectedDateString={setSelectedDateString}
            fetchSpecificSession={fetchSpecificSession}
          />
        </Flex>
      ) : (
        <Center h='80vh'>
          <Loader />
        </Center>
      )}
    </>
  );
};

const TaskDetailsRoot: FC = () => {
  const params = useParams<{ id: string; sessionId: string }>();

  return (
    <DashboardContainer>
      <SupervisedTaskDetailsDataProvider>
        <TaskDetails id={params.id} sessionId={params.sessionId} />
      </SupervisedTaskDetailsDataProvider>
    </DashboardContainer>
  );
};

TaskDetailsRoot.displayName = 'TaskDetails/C/TaskDetailsRoot';

export default TaskDetailsRoot;
