import get from 'lodash/get';
import { createStore } from 'zustandStore';
import { immer } from 'zustandStore/middleware';
import { Any } from '../../../../types';
import { updateObject } from '../../../../utils/objectHelper';
import { getAssignedUser } from '../../../../pages/launcher/config-editor';
import {
  LauncherAssignee,
  LauncherTaskStep,
  LauncherTrigger,
} from './launcher-config.fragment';
import {
  diffAssignee,
  findTriggerItem,
  getSectionIndex,
  onDeleteUpdateTrigger,
  triggerEnabled,
} from './config-store.helper';
import { getDuration } from './trigger/duration.helper';

export interface LauncherTaskInput
  extends Partial<LauncherAssignee>,
    LauncherTrigger {
  eid: string;
  title: string;
  description: string;
  category: string;
  isRequired?: boolean;
  docRequired?: boolean;
  steps: LauncherTaskStep[];
}

export interface LauncherContentInput
  extends Partial<LauncherAssignee>,
    LauncherTrigger {
  eid: string;
  category: string;
  tasks: LauncherTaskInput[];
  triggerAssignment?: 'task' | 'phase';
}

interface LauncherConfigInput {
  eid?: string;
  title: string;
  locations?: number;
  contents: LauncherContentInput[];
}

interface EditTaskInput {
  sectionIndex: number;
  taskIndex: number;
  taskEid?: string;
  lastCategory?: string;
}

type ConfigGroupBy = 'phase' | 'category';

export interface IConfigFilter {
  taskTypes?: string[];
  assignees?: string[];
  other?: {
    deadline?: boolean;
    nonDeadline?: boolean;
  };
}

export interface LauncherConfigState {
  // Filter & group
  groupBy?: ConfigGroupBy;
  filterBy?: IConfigFilter;

  config: LauncherConfigInput;
  configMapObject: Record<string, any>;
  taskCategory: Record<string, string>;
  isConfigDirty?: boolean;
  initializing: boolean;
  taskForm?: EditTaskInput & {
    phase?: Omit<LauncherContentInput, 'tasks'>;
    task?: LauncherTaskInput;
  };
  selectedSection?: number;
  addNewSection?: boolean;
}

export interface LauncherConfigAction extends LauncherConfigState {
  getConfig: () => LauncherConfigInput;
  getConfigMapObject: () => Record<string, any>;
  completeSave: () => void;

  // Filter & group
  changeGroupBy: (value?: ConfigGroupBy) => void;
  changeFilterBy: (key: string, value: Any) => void;

  updateConfigTitle: (title: string) => void;

  // Phase Action
  openAddPhase: (value: boolean) => void;
  addNewPhase: (data: LauncherContentInput) => void;
  deletePhase: (sectionIndex: number) => void;
  selectSection: (sectionIndex: number) => void;
  getSectionData: (sectionIndex: number) => LauncherContentInput;
  updateSectionAssignee: (
    sectionIndex: number,
    data: Pick<LauncherContentInput, 'assignedType' | 'assignedUser'>
  ) => void;
  updateSection: (
    sectionIndex: number,
    data: Omit<LauncherContentInput, 'tasks'>
  ) => void;
  movePhase: (fromIndex: number, toIndex: number) => void;
  unlockPhase: (sectionIndex: number) => void;

  // Task Action
  openTaskForm: (props: EditTaskInput) => void;
  closeTaskForm: () => void;
  saveTask: (data: LauncherTaskInput) => void;
  moveTask: (sectionIndex: number, fromIndex: number, toIndex: number) => void;
  deleteTask: (sectionIndex: number, taskIndex: number) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createLauncherConfigStore = () => {
  return createStore<LauncherConfigAction>()(
    immer((set, getState) => ({
      config: {} as LauncherConfigInput,
      configMapObject: {},
      taskCategory: {},
      initializing: true as boolean,
      // taskForm: undefined,

      getConfig: () => {
        return getState().config;
      },
      getConfigMapObject: () => {
        return getState().configMapObject;
      },

      completeSave: () => {
        set((state) => {
          state.isConfigDirty = false;
        });
      },

      getSectionData: (sectionIndex: number) => {
        return getState().config.contents[sectionIndex];
      },

      // Filter & group
      changeGroupBy: (nextGroupBy) => {
        set((state) => {
          if (state.groupBy === nextGroupBy) {
            state.groupBy = undefined;
          } else {
            state.groupBy = nextGroupBy;
          }
        });
      },
      changeFilterBy: (key, value) => {
        set((state) => {
          if (!state.filterBy) {
            state.filterBy = {};
          }
          updateObject(state.filterBy, key, value);
        });
      },

      updateConfigTitle: (title) => {
        set((state) => {
          state.config.title = title;
        });
      },

      openAddPhase: (value) => {
        set((state) => {
          state.addNewSection = value;
        });
      },

      addNewPhase: (data) => {
        set((state) => {
          const haveTrigger = triggerEnabled(data.trigger!);

          if (haveTrigger) {
            const [itemId] = findTriggerItem(data?.trigger);
            updateObject(data, ['meta', 'triggerItemId'], itemId);
          }

          state.config.contents.push(data);

          state.isConfigDirty = true;
        });
      },

      deletePhase: (sectionIndex) => {
        set((state) => {
          const section = state.config.contents[sectionIndex];

          state.config.contents.splice(sectionIndex, 1);

          onDeleteUpdateTrigger(state, section.eid);

          for (const task of section.tasks) {
            onDeleteUpdateTrigger(state, task.eid);
          }

          state.isConfigDirty = true;
        });
      },

      selectSection: (sectionIndex) => {
        set((state) => {
          if (state.selectedSection === sectionIndex) {
            state.selectedSection = undefined;
          } else {
            state.selectedSection = sectionIndex;
          }
        });
      },

      updateSectionAssignee: (sectionIndex, data) => {
        set((state) => {
          if (state.config.contents[sectionIndex]) {
            state.config.contents[sectionIndex].assignedType =
              data.assignedType;
            state.config.contents[sectionIndex].assignedUser =
              data.assignedUser;

            state.config.contents[sectionIndex].tasks.map((task) => {
              task.assignedType = data.assignedType;
              task.assignedUser = data.assignedUser;
            });

            state.isConfigDirty = true;
          }
        });
      },

      updateSection: (sectionIndex, data) => {
        set((state) => {
          if (state.config.contents[sectionIndex]) {
            const assigneeChanged = diffAssignee(
              state.config.contents[sectionIndex],
              data
            );
            state.config.contents[sectionIndex].category = data.category;
            state.config.contents[sectionIndex].assignedType =
              data.assignedType;
            state.config.contents[sectionIndex].assignedUser =
              data.assignedUser;

            state.config.contents[sectionIndex].trigger = data.trigger;

            const haveTrigger = triggerEnabled(data.trigger!);

            if (haveTrigger) {
              const [itemId] = findTriggerItem(data?.trigger);
              updateObject(
                state.config.contents[sectionIndex],
                ['meta', 'triggerItemId'],
                itemId
              );
            } else {
              updateObject(
                state.config.contents[sectionIndex],
                ['meta', 'triggerItemId'],
                undefined
              );
            }

            if (haveTrigger) {
              if (
                state.config.contents[sectionIndex].triggerAssignment === 'task'
              ) {
                state.config.contents[sectionIndex].tasks.map((task) => {
                  if (task.trigger) {
                    updateObject(task, ['trigger'], undefined);
                    updateObject(task, ['meta', 'triggerItemId'], undefined);
                  }
                });
              }

              state.config.contents[sectionIndex].triggerAssignment = 'phase';
            }

            if (assigneeChanged) {
              state.config.contents[sectionIndex].tasks.map((task) => {
                task.assignedType = data.assignedType;
                task.assignedUser = data.assignedUser;
              });
            }

            state.isConfigDirty = true;
          }
        });
      },

      movePhase: (fromIndex, toIndex) => {
        set((state) => {
          state.config.contents.splice(
            toIndex,
            0,
            state.config.contents.splice(fromIndex, 1)[0]
          );

          state.isConfigDirty = true;
        });
      },

      unlockPhase: (sectionIndex) => {
        set((state) => {
          state.config.contents[sectionIndex].trigger = {
            triggerChoice: 'default',
          };

          state.isConfigDirty = true;
        });
      },

      // Task Actions
      openTaskForm: (data) => {
        set((state) => {
          const taskForm: LauncherConfigState['taskForm'] = {
            ...data,
          };

          const phase = state.config.contents[data.sectionIndex];

          if (phase) {
            taskForm.phase = {
              eid: phase.eid,
              category: phase.category,
              trigger: phase.trigger,
            };
          }

          if (data.taskEid) {
            taskForm.task = get(state.config.contents, [
              data.sectionIndex,
              'tasks',
              data.taskIndex,
            ]);

            /**
             * Adding task from by-category listing using add-above and add-bottom action
             */
          } else if (state.groupBy === 'category' && !taskForm.task) {
            // @ts-ignore
            taskForm.task = {
              category: data.lastCategory!,
            };
          }

          state.taskForm = taskForm;
        });
      },

      closeTaskForm: () => {
        set((state) => {
          state.taskForm = undefined;
        });
      },

      saveTask: (task) => {
        set((state) => {
          const temp = state.taskForm;

          if (temp) {
            const haveTrigger = triggerEnabled(task.trigger!);

            if (haveTrigger) {
              const [itemId] = findTriggerItem(task?.trigger);
              updateObject(task, ['meta', 'triggerItemId'], itemId);
              const { durationValue, durationUnit } = getDuration(task.trigger);

              if (durationUnit) {
                updateObject(
                  task,
                  ['meta', 'duration'],
                  `${durationValue}-${durationUnit}`
                );
              }
            } else {
              updateObject(task, ['meta', 'triggerItemId'], undefined);
              updateObject(task, ['meta', 'duration'], undefined);
            }

            const sectionIndex = getSectionIndex(
              state.config.contents,
              temp.sectionIndex,
              // @ts-ignore
              task?.phaseEid
            );

            if (temp.task?.eid) {
              state.config.contents[sectionIndex].tasks[temp.taskIndex] = {
                ...task,
              };
            } else {
              if (!task?.assignedType) {
                const section = state.config.contents[sectionIndex];
                if (section.assignedType) {
                  task.assignedType = section.assignedType;
                  task.assignedUser = getAssignedUser(section as never);
                }
              }

              if (temp.taskIndex === -1) {
                state.config.contents[sectionIndex].tasks.push(task);
              } else {
                state.config.contents[sectionIndex].tasks.splice(
                  temp.taskIndex,
                  0,
                  task
                );
              }
            }

            if (haveTrigger) {
              if (
                state.config.contents[sectionIndex].triggerAssignment ===
                'phase'
              ) {
                const phaseTrigger =
                  state.config.contents[sectionIndex].trigger;
                const triggerId =
                  state.config.contents[sectionIndex].meta?.triggerItemId;
                state.config.contents[sectionIndex].tasks.map((_task) => {
                  if (_task.eid !== task.eid) {
                    _task.trigger = phaseTrigger;
                    updateObject(_task, ['meta', 'triggerItemId'], triggerId);
                  }
                });
              }

              state.config.contents[sectionIndex].trigger = {
                triggerChoice: 'default',
              };
              state.config.contents[sectionIndex].triggerAssignment = 'task';
            }

            state.taskForm = undefined;

            state.isConfigDirty = true;
          }
        });
      },

      moveTask: (sectionIndex, fromIndex, toIndex) => {
        set((state) => {
          state.config.contents[sectionIndex].tasks.splice(
            toIndex,
            0,
            state.config.contents[sectionIndex].tasks.splice(fromIndex, 1)[0]
          );

          state.isConfigDirty = true;
        });
      },

      deleteTask: (sectionIndex, taskIndex) => {
        set((state) => {
          if (state.config.contents[sectionIndex]) {
            const dependId =
              state.config.contents[sectionIndex].tasks[taskIndex]?.eid;
            state.config.contents[sectionIndex].tasks.splice(taskIndex, 1);

            onDeleteUpdateTrigger(state, dependId);

            state.isConfigDirty = true;
          }
        });
      },
    }))
  );
};

export type LauncherConfigStore = ReturnType<typeof createLauncherConfigStore>;
