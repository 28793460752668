import React, { forwardRef } from 'react';
import {
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { useIdGenerator } from '../useIdGenerator';
import { useLauncherConfigContext } from '../context';
import AddSectionForm from './AddSectionForm';
import { useLauncherWarning } from '../useLauncherWarning';

interface IProps {
  onNewPhaseAdded?: () => void;
}

const AddPhasePopup = forwardRef<HTMLDivElement, IProps>(
  ({ onNewPhaseAdded }, ref) => {
    const generateId = useIdGenerator();
    const warning = useLauncherWarning();

    const { close, addNewPhase, haveLocation } = useLauncherConfigContext(
      (state) => {
        return {
          close: () => state.openAddPhase(false),
          addNewPhase: state.addNewPhase,
          haveLocation: !!state.config?.locations,
        };
      }
    );

    const onSubmit = async (value: string) => {
      if (haveLocation) {
        await new Promise((resolve, reject) => {
          warning({
            header: 'Add a new phase',
            title: 'There might not be enough time to complete the phase',
            description:
              'Based on the current Go-live date, there may not be enough time to complete this phase. You can either adjust the Go-live date or choose not to add this phase to the board.',
            buttonText: 'Continue to add phase',
            onConfirm: () => resolve(true),
            onCancel: () => reject('Cancelled'),
          });
        });
      }

      const newPhaseId = await generateId();

      addNewPhase({
        eid: newPhaseId,
        category: value,
        tasks: [],
        trigger: {
          triggerChoice: 'default',
        },
      });
      onNewPhaseAdded?.();
    };

    return (
      <Popover isLazy isOpen placement='bottom-start' matchWidth>
        {/*// @ts-ignore */}
        <PopoverTrigger>
          <Flex ref={ref} width='full' />
        </PopoverTrigger>
        <PopoverContent
          width='unset'
          borderRadius='12px'
          boxShadow='lg'
          _focus={{}}
        >
          <PopoverBody px={4} pb={3}>
            <AddSectionForm onSubmit={onSubmit} onClose={close} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }
);

AddPhasePopup.displayName = 'AddPhasePopup';

export default AddPhasePopup;
