import get from 'lodash/get';
import { useLauncherConfigContext } from '../../../common';
import {
  IConfigFilter,
  LauncherTaskInput,
} from '../../../common/launcher-config.store';
import { LauncherAssignee } from '../../../common/launcher-config.fragment';

const assigneeCheck = (
  assignee?: Partial<LauncherAssignee>,
  filterBy?: IConfigFilter
) => {
  const assignees = filterBy?.assignees || [];
  if (assignees?.length && assignee?.assignedType === 'user') {
    if (assignee?.assignedUser?.some((as) => assignees.includes(as))) {
      return true;
    }
  }

  if (assignees?.length) {
    return false;
  }
  return undefined;
};

const assigneeAndTypesCheck = (
  taskData: LauncherTaskInput,
  filterBy?: IConfigFilter
) => {
  const assignees = filterBy?.assignees || [];
  if (assignees?.length && taskData?.assignedType === 'user') {
    if (taskData?.assignedUser?.some((as) => assignees.includes(as))) {
      return true;
    }
  }
  const taskTypes = filterBy?.taskTypes || [];
  if (taskTypes?.length) {
    if (taskTypes.includes(taskData.category)) {
      return true;
    }
  }
  if (assignees?.length || taskTypes?.length) {
    return false;
  }
  return undefined;
};

export const useTaskItemWithFilter = (
  sectionIndex: number,
  taskIndex: number
) => {
  return useLauncherConfigContext((state) => {
    const section = get(state.config.contents, [sectionIndex]);

    const taskData = get(section, ['tasks', taskIndex]);

    const andTypesCheck = assigneeAndTypesCheck(taskData, state.filterBy);
    // Section assignee check
    const sectionCheck = assigneeCheck(section, state.filterBy);

    if (sectionCheck === undefined && andTypesCheck === undefined) {
      return taskData;
    }

    if (
      taskData?.assignedUser?.length ||
      taskData?.assignedType === 'location'
    ) {
      return andTypesCheck ? taskData : undefined;
    }

    return andTypesCheck || sectionCheck ? taskData : undefined;
  });
};
