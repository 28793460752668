import { useMemo } from 'react';
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import queryString from 'query-string';

import { layouts, AllLayout } from 'sop-commons/src/utils/cardLayouts';
import { ACTION_THUMBNAIL, QUIZ_THUMBNAIL } from '../configs/default-image';

export { useRouteMatch };

export { getAvatar } from './avatar';
export { searchRegExp } from './regex';

export { getRole } from './getRole';
export { getImage } from './image';
export { shallowEqual, deepEqual } from './shallowEqual';
export { getFileIcon } from './file-icon';
export * from './utils';
export { EmailEncoder } from './EmailEncoder';
export * from './numberToWords';

export const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location,
      history,
    };
  }, [params, match, location, history]);
};

export const searchTypeObj: any = {
  category: {
    title: 'category',
    color: '#3ba578',
  },
  chapter: {
    title: 'chapter',
    color: '#6c63ff',
  },
  bundles: {
    title: 'bundles',
    color: '#db4c3f',
  },
  roles: {
    title: 'roles',
    color: '#ff933d',
  },
};

export const backgroundColorList = [
  '#ffbc99',
  '#cabdff',
  '#b1e5fc',
  '#b5e4ca',
  '#ffd88d',
  '#ffffff',
];

export const actionCardBackgroundColorList = [
  '#ffbc99',
  '#cabdff',
  '#b1e5fc',
  '#b5e4ca',
  '#ffd88d',
  '#ffffff',
  '#000000',
];

export const textJsonFormat = (
  heading_level: number,
  textAlign: string,
  text?: string
) => {
  return {
    type: 'text',
    attrs: {
      headingLevel: heading_level,
      textAlign: textAlign,
    },
    text: text || '',
  };
};

export const tiptapJsonFormat = () => {
  return {
    type: 'tiptap',
    attrs: {},
    tiptap: {},
  };
};

export const imageJsonFormat = (layout: string) => {
  return {
    type: 'image',
    attrs: {
      layout: layout,
    },
    image: [],
  };
};
export const actionJsonFormat = (level: number, textAlign: string) => {
  return {
    type: 'text',
    attrs: {
      headingLevel: level,
      textAlign: textAlign,
    },
    text: '',
  };
};

export const quizChoiceJsonFormat = (layout: any, choiceOptionList: any) => {
  const choiceList = choiceOptionList ? choiceOptionList : [];
  if (layout === 'single-choice') {
    return {
      type: 'choices',
      attrs: {},
      choices: choiceList,
      correctAnswer: '',
    };
  } else {
    return {
      type: 'choices',
      attrs: {},
      choices: choiceList,
      correctAnswer: [],
    };
  }
};

export const mixedCardJsonFormat = (layout: AllLayout) => {
  if (layout === layouts.MIXED_TOP_IMAGE_TEXT) {
    return [
      {
        type: 'image',
        attrs: {
          layout: 'single-image',
        },
        image: [],
      },
      {
        type: 'tiptap',
        attrs: {},
        tiptap: {},
      },
    ];
  } else if (layout === layouts.MIXED_BOTTOM_IMAGE_TEXT) {
    return [
      {
        type: 'tiptap',
        attrs: {},
        tiptap: {},
      },
      {
        type: 'image',
        attrs: {
          layout: 'single-image',
        },
        image: [],
      },
    ];
  } else if (layout === layouts.MIXED_HORIZONTAL_SPLIT_IMAGE_TEXT) {
    return [
      {
        type: 'image',
        attrs: {
          layout: 'horizontal-split',
        },
        image: [],
      },
      {
        type: 'tiptap',
        attrs: {},
        tiptap: {},
      },
    ];
  } else if (layout === layouts.MIXED_VERTICAL_SPLIT_IMAGE_TEXT) {
    return [
      {
        type: 'image',
        attrs: {
          layout: 'vertical-split',
        },
        image: [],
      },
      {
        type: 'tiptap',
        attrs: {},
        tiptap: {},
      },
    ];
  } else if (
    layout === layouts.MIXED_HORIZONTAL_SPLIT_SECOND_COLUMN_IMAGE_TEXT
  ) {
    return [
      {
        type: 'image',
        attrs: {
          layout: 'horizontal-split-second-column',
        },
        image: [],
      },
      {
        type: 'tiptap',
        attrs: {},
        tiptap: {},
      },
    ];
  } else if (layout === layouts.MIXED_VERTICAL_SPLIT_SECOND_ROW_IMAGE_TEXT) {
    return [
      {
        type: 'image',
        attrs: {
          layout: 'vertical-split-second-row',
        },
        image: [],
      },
      {
        type: 'tiptap',
        attrs: {},
        tiptap: {},
      },
    ];
  } else if (layout === layouts.MIXED_QUADRANT_IMAGE_TEXT) {
    return [
      {
        type: 'image',
        attrs: {
          layout: 'quadrant',
        },
        image: [],
      },
      {
        type: 'tiptap',
        attrs: {},
        tiptap: {},
      },
    ];
  }
};
export const formJsonFormat = () => {
  return {
    type: 'form',
    url: '',
  };
};

export const getFormat = (
  cardType: string,
  layout: string,
  entityId: string,
  backgroundColor: string | null,
  title?: string,
  status?: string
) => {
  if (cardType === 'video') {
    const videoCard: any = {
      cardType: cardType,
      entityId: entityId,
      content: [
        {
          type: 'video',
          attrs: {},
          video: '',
        },
        {
          type: 'caption',
          attrs: {},
          caption: '',
        },
      ],
      raw_content: '',
      title: title,
    };
    if (status) {
      videoCard.status = status;
    }
    return {
      ...videoCard,
    };
  } else {
    const updatedCard: any = {
      cardType: cardType,
      entityId: entityId,
      backgroundColor: backgroundColor,
      layout: layout,
      content: [],
      raw_content: '',
      subTitle: '',
      thumbnail: '',
      title: title,
    };
    if (title) {
      updatedCard.raw_content = title;
    }
    if (status) {
      updatedCard.status = status;
    }
    return {
      ...updatedCard,
    };
  }
};

export const cardJsonFormat = (
  cardType: string,
  layout: string,
  entityId: string,
  backgroundColor: string | null,
  choiceOptionList?: any,
  title?: any,
  status?: any
) => {
  let cardData: any = getFormat(
    cardType,
    layout,
    entityId,
    backgroundColor,
    title,
    status
  );

  if (cardType === 'text') {
    if (layout === 'simple-text') {
      cardData.content = [textJsonFormat(2, 'center')];
    } else if (layout === 'rich-text') {
      cardData.content = [tiptapJsonFormat()];
    }
    cardData.thumbnail =
      'https://sop-uploads-staging.s3.amazonaws.com/extras/1002.png';
  } else if (cardType === 'image') {
    cardData.content = [imageJsonFormat(layout)];
    // if (layout === 'single-image') {
    //   cardData.content = [imageJsonFormat()];
    // }
  } else if (cardType === 'action') {
    cardData.content = [actionJsonFormat(2, 'center')];
    cardData.thumbnail = ACTION_THUMBNAIL;
  } else if (cardType === 'quiz') {
    cardData.content = [quizChoiceJsonFormat(layout, choiceOptionList)];
    cardData.thumbnail = QUIZ_THUMBNAIL;
  } else if (cardType === 'mixed') {
    cardData.content = mixedCardJsonFormat(layout);
  } else if (cardType === 'form') {
    cardData.content = [formJsonFormat()];
    cardData.thumbnail =
      'https://sop-uploads-staging.s3.amazonaws.com/2l2kzw3m8pq70edbml58z9pr/1655217948967_197_Form Card Preview.png';
  }

  cardData.cardType = cardType;
  return cardData;
};

/*
 * @deprecate
 * moved to packages/sop-web/src/sub-components/CardEditor/card-editor.types.ts
 */
// export const CARD_TYPES = {
//   mixed: 'mixed',
//   video: 'video',
//   image: 'image',
//   quiz: 'quiz',
//   text: 'text',
//   action: 'action',
//   form: 'form',
// };

export const truncateString = (str: string = '', n: number): string => {
  if (!str) {
    return str;
  }
  return str.length > n ? str.slice(0, n - 1) + '...' : str;
};

export function getRandomNumber(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min) + min);
}
