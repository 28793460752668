import React, { FC } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { Authorize } from '../../authorization';
import { canAccessAudit } from '../../configs';
import {
  AUDIT_DETAILS,
  AUDIT_QUESTIONS_EDIT,
  AUDIT_SETTINGS,
  AUDIT_REPORT,
  CREATE_AUDIT_TEMPLATE,
  EDIT_AUDIT_TEMPLATE,
  PUBLIC_AUDIT_REPORT,
  SCHEDULE_AUDIT,
} from '../../appRoutes';
import {
  AuditPublicReportWrapper,
  AuditReportWrapper,
  AuditRouteWrapper,
} from '../../routeComponentDefinitions';
import AuditSchedule from '../../sub-components/audits/schedule/AuditSchedule';
import CreateAudit from './Create/CreateAudit';
import AuditDetail from './Detail';
import AuditList from './Listing/AuditList';
import { CreateTemplatePage } from './template';
import TakeAudit from './TakeAudit';
import AuditSettings from './AuditSettings';
import EditAuditQuestion from './EditAuditQuestion';

const AuditRoute: FC = () => {
  let { path } = useRouteMatch();
  const location = useLocation();
  return (
    <Authorize
      canAccess={canAccessAudit}
      renderNoAccess={() => <Redirect to='/' />}
    >
      <Switch>
        <Route exact path={path} component={AuditList} />
        <Route path={CREATE_AUDIT_TEMPLATE} component={CreateTemplatePage} />
        <Route path={EDIT_AUDIT_TEMPLATE} component={CreateAudit} />
        <Route
          key={location.pathname}
          path={AUDIT_DETAILS}
          component={AuditDetail}
        />
        <Route path={SCHEDULE_AUDIT} component={AuditSchedule} />
        <Route path={AUDIT_SETTINGS} component={AuditSettings} />
        <Route path={AUDIT_QUESTIONS_EDIT} component={EditAuditQuestion} />
        <Route
          path={`${path}/take-audit/lI/:locationId/sI/:sessionId`}
          component={TakeAudit}
        />
        <Route path={AUDIT_REPORT} component={AuditReportWrapper} />
      </Switch>
    </Authorize>
  );
};

export default AuditRoute;
