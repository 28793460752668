import { Button, Checkbox, Divider, Flex, Stack, Text } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import React, { FC } from 'react';
import { BoxHeader } from 'ui-components';
import { ReactComponent as GroupiconIcon } from 'assets/images/groupIcon.svg';
import { ReactComponent as LocationIcon } from 'assets/images/sidebar/location.svg';
import { ReactComponent as ChevronforwardIcon } from 'assets/images/chevronForward.svg';
import { PageNameType } from './MainContainer';
import { FiltersType } from '../../ComplianceListContainer';
import { ClearFilterType } from './FilterContainer';

interface IProps {
  filters: FiltersType;
  setPageName: React.Dispatch<React.SetStateAction<PageNameType>>;
  handleExpiredDocFilter: () => void;
  clearFilters: (type?: ClearFilterType) => void;
}

const BaseFilter: FC<IProps> = ({
  handleExpiredDocFilter,
  setPageName,
  filters,
  clearFilters,
}) => {
  return (
    <Flex gap={4} flexDir='column'>
      <Flex alignItems={'center'} mt={'30px'} justifyContent={'space-between'}>
        <BoxHeader title='Filters' color={HeaderColors.Purple} />
        <Text
          cursor={'pointer'}
          onClick={() => clearFilters()}
          fontSize={'14px'}
          fontWeight={400}
          color={filters?.totalFiltersTypes > 0 ? '#2A85FF' : '#6F767E'}
        >
          Clear All
        </Text>
      </Flex>

      <Flex mt={'10px'} gap={4} alignItems='center'>
        <Checkbox
          isChecked={filters?.expiredDocs}
          size='lg'
          onChange={handleExpiredDocFilter}
          iconSize={'24px'}
        />
        <Text
          color={'#333B4F'}
          fontWeight={400}
          lineHeight='16px'
          fontSize='14px'
        >
          Expired documents only
        </Text>
      </Flex>
      <Divider />
      <Flex
        onClick={() => setPageName('uploadedBy')}
        cursor={'pointer'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Flex gap={4}>
          <GroupiconIcon />
          <Text
            color={'#333B4F'}
            fontWeight={400}
            lineHeight='16px'
            fontSize='14px'
          >
            Uploaded By
          </Text>
          <Text
            color={'#6F767E'}
            fontWeight={400}
            lineHeight='16px'
            fontSize='14px'
          >
            {`${
              filters?.selectedUploadedBy?.length
                ? '(' + filters?.selectedUploadedBy?.length + ' selected)'
                : ''
            }`}
          </Text>
        </Flex>
        <ChevronforwardIcon />
      </Flex>
      <Divider />
      <Flex
        onClick={() => setPageName('locations')}
        cursor={'pointer'}
        mb={'30px'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Flex gap={4}>
          <LocationIcon width={'25px'} />
          <Text
            color={'#333B4F'}
            fontWeight={400}
            lineHeight='16px'
            fontSize='14px'
          >
            Locations
          </Text>
          <Text
            color={'#6F767E'}
            fontWeight={400}
            lineHeight='16px'
            fontSize='14px'
          >
            {`${
              filters?.selectedLocations?.length
                ? '(' + filters?.selectedLocations?.length + ' selected)'
                : ''
            }`}
          </Text>
        </Flex>
        <ChevronforwardIcon />
      </Flex>
    </Flex>
  );
};

export default BaseFilter;
