import { Flex } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import React, { FC, useMemo } from 'react';
import { TaskType } from 'sub-components/Launcher/dashboard/dashboard-grapghql';
import {
  LocationPhaseDetails,
  PhaseTask,
} from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import { ILocationLaunchTask } from 'sub-components/Launcher/location-owner/types';
import { TaskStatus } from 'sub-components/Launcher/shared';
import { LocationLaunchTask } from 'types/location-launcher/details/location-tasks';
import { BoxHeader } from 'ui-components';
import { GroupedTasksData } from '../types';
import TaskContent from './TaskContent';

interface IProps {
  categories: TaskType[];
  data: GroupedTasksData[];
  refetch?: (
    resData?: ILocationLaunchTask,
    phaseDetails?: LocationPhaseDetails[],
    task?: PhaseTask
  ) => void;
}

const ByStatus: FC<IProps> = ({ categories, data, refetch }) => {
  const byStatusData = useMemo(() => {
    let tasksArr: ILocationLaunchTask[] = [];
    data?.map((_data) => {
      _data?.groupedPhases?.map((group) => {
        group?.tasks?.forEach((task) =>
          tasksArr.push({
            ...task,
            locationName: _data?.locationName,
          })
        );
      });
    });
    const groupedTasks = tasksArr?.reduce((acc, task) => {
      const status = task?.status;
      if (!acc[status]) {
        acc[status] = [];
      }
      acc[status].push(task);
      return acc;
    }, {} as Record<string, ILocationLaunchTask[]>);

    // Define the desired status order
    const statusOrder = [
      'overdue',
      'duesoon',
      'inProgress',
      'locked',
      'completed',
    ];

    // Create ordered array of grouped tasks
    return statusOrder
      ?.map((status) => ({
        status,
        data: groupedTasks?.[status] || [],
      }))
      ?.filter((group) => group?.data?.length > 0);
  }, [data]);

  const headerLabel = (status: TaskStatus) => {
    switch (status) {
      case 'completed':
        return 'Completed';
      case 'duesoon':
        return 'Due soon';
      case 'inProgress':
        return 'In progress';
      case 'locked':
        return 'Yet to start';
      case 'overdue':
        return 'Overdue';
      case 'yetToStart':
        return 'Yet to start';
      default:
        return '';
    }
  };

  const headerLabelColor = (status: TaskStatus) => {
    switch (status) {
      case 'completed':
        return HeaderColors.Green;
      case 'dueToday':
      case 'duesoon':
      case 'inProgress':
        return HeaderColors.Purple;
      case 'locked':
      case 'thisWeek':
      case 'yetToStart':
        return HeaderColors.Blue;
      case 'overdue':
        return HeaderColors.Red.Mid;
      default:
        return HeaderColors.Purple;
    }
  };

  return (
    <Flex flexDir='column' gap={4} mt={4}>
      {byStatusData?.map((_data, dIndex) => {
        return (
          <Flex key={dIndex} flexDir='column' gap={4}>
            <BoxHeader
              color={headerLabelColor(_data?.status as TaskStatus)}
              title={`${headerLabel(_data?.status as TaskStatus)} (${
                _data?.data.length
              })`}
            />
            <Flex flexDir='column' gap={4}>
              {_data?.data?.map((task, tIndex) => {
                return (
                  <TaskContent
                    key={tIndex}
                    categories={categories}
                    locationName={task?.locationName || ''}
                    task={task}
                    refetch={refetch}
                  />
                );
              })}
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};

ByStatus.displayName =
  'displayName:sub-components/Launcher/tasks/components/ByStatus';

export default ByStatus;
