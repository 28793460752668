import React, { FC } from 'react';
import {
  Checkbox,
  CheckboxGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import { Controller, useWatch } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import TitleHeader from '../../../../../../CardEditor/TitleHeader';
import RemindOnDate from './RemindOnDate';
import RemindBefore from './RemindBefore';
import { IDocumentInput } from '../compliance.input';

const ReminderDetails: FC = () => {
  const { t } = useTranslation(['nexus']);

  const remindTo = useWatch<IDocumentInput, 'reminder.remindUserType'>({
    name: 'reminder.remindUserType',
  });

  if (!remindTo?.length) {
    return null;
  }

  return (
    <>
      <Divider />

      <Controller<IDocumentInput, 'reminder.reminderType'>
        name='reminder.reminderType'
        rules={{
          required: 'Select when to receive a reminder',
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={fieldState.invalid}>
              <TitleHeader
                title={t('nexus:documents.reminders.receiveReminder')}
                titleStyle={{ marginBottom: '8px' }}
              />

              <RadioGroup
                display='flex'
                flexDirection='column'
                gap={2}
                value={field.value}
                onChange={field.onChange}
                isDisabled={field.disabled}
              >
                <RemindBefore />

                <RemindOnDate />
              </RadioGroup>

              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <Divider />

      <Controller<IDocumentInput, 'reminder.remindChannels'>
        name='reminder.remindChannels'
        rules={{
          required: 'Select preferred mode of communication',
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={fieldState.invalid}>
              <TitleHeader
                title={t('nexus:documents.reminders.preferredCommMode')}
                titleStyle={{ marginBottom: '8px' }}
              />

              <CheckboxGroup
                value={field.value}
                onChange={field.onChange}
                isDisabled={field.disabled}
              >
                <Stack
                  ref={field.ref}
                  spacing={4}
                  sx={{
                    '.chakra-checkbox__label': {
                      fontSize: 13,
                      fontWeight: 600,
                    },
                    '.chakra-checkbox__control': {
                      borderRadius: '6px',
                    },
                  }}
                >
                  <Checkbox size='lg' value='sms' name={field.name}>
                    SMS
                  </Checkbox>
                  <Checkbox size='lg' value='email' name={field.name}>
                    Email
                  </Checkbox>
                </Stack>
              </CheckboxGroup>

              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </>
  );
};

ReminderDetails.displayName =
  'displayName:sub-components/nexus/Compliance/Create/components/common/document-reminder/ReminderDetails';

export default ReminderDetails;
