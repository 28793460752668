import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import {
  PromptAction,
  useBlockPrompt,
  useCloseConfirm,
} from '../../../../routes';
import { useLauncherConfigContext } from './context';
import { useConfigSaveAction } from './useConfigSaveAction';

interface IProps {}

const ConfigNavigationAlert: FC<IProps> = () => {
  const isConfigDirty = useLauncherConfigContext(
    (state) => state.isConfigDirty
  );
  const onSubmitClick = useConfigSaveAction();
  const pathname = useLocation().pathname;

  useCloseConfirm(isConfigDirty === true);

  useBlockPrompt({
    canShowPrompt: isConfigDirty === true,
    blockConfig: (nextLocation) => {
      // if (action === 'REPLACE') {
      //   return undefined;
      // }
      // return {
      //   alertType: 'UnsavedConfigAlert',
      // };
      if (nextLocation.pathname !== pathname) {
        return {
          alertType: 'UnsavedConfigAlert',
        };
      }

      return undefined;
    },
    actionHandler: async (action) => {
      if (action === PromptAction.SAVE_CONFIG) {
        await onSubmitClick();
      }
    },
  });

  return null;
};

export default ConfigNavigationAlert;
