import React, { FC, useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';

import {
  BoxHeader,
  useConfirm,
  UseConfirm,
} from '../../../../../../../../ui-components';
import { IFormInput } from '../../../../../../../tasks/create-task';

import {
  ChapterItem,
  TaskFormItem,
  TrainingItem,
} from '../../../../../../../tasks/create-task/TaskSteps/AddFormChapter/formChapter.graphql';

import { visibilityModalConfig } from './visibility-modal.config';

interface ResourceData {
  eid: string;
  trainingData?: TrainingItem;
  formData?: TaskFormItem;
  sopData?: ChapterItem;
}

interface CompProps {
  onAddClick?: (values: IFormInput['contents']) => void;
  values: ResourceData[];
}

interface IProps {
  title: string;
  values: ResourceData[];
  onAddClick?: (values: IFormInput['contents']) => void;
  onCancelClick?: (...args: any[]) => Promise<void> | void;
  component: FC<CompProps>;
}

type ResourceVisibility = (props: IProps) => void;

export const useResourceVisibility = (): ResourceVisibility => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  return useCallback(
    ({ title, values, onAddClick, onCancelClick, component: Container }) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }

      confirmRef.current = confirm({
        ...visibilityModalConfig,

        title: (
          <Flex gap='12px'>
            <Box flex={1}>
              <BoxHeader title={title} color='#CABDFF' />
            </Box>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
              onClick={onCancelClick}
            />
          </Flex>
        ),

        content: <Container values={values} onAddClick={onAddClick} />,
        onCancel: onCancelClick,
      });
    },
    []
  );
};
