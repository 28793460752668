import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';
import ModalContent from './ModalContent';
import { HeaderColors } from 'configs';

interface IProps {
  onOkPress?: (...args: any[]) => any | PromiseLike<any>;
  onCancelPress?: (...args: any[]) => any | PromiseLike<any>;
}

type IPhaseNotRequiredInfo = (props: IProps) => void;

export const usePhaseNotRequiredInfo = (): IPhaseNotRequiredInfo => {
  const { t } = useTranslation(['common', 'invite']);
  const confirm = useConfirm();

  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(
    ({ onOkPress, onCancelPress }) => {
      confirmRef.current = confirm({
        title: (
          <Flex justify='space-between'>
            <BoxHeader title='Please note' color={HeaderColors.Orange} />
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        content: (
          <ModalContent
            onCancelPress={() => {
              confirmRef?.current?.destroy();
            }}
            onOkPress={() => {
              onOkPress?.();
              confirmRef?.current?.destroy();
            }}
          />
        ),
        isCentered: true,
        size: 'sm',
        contentProps: {
          minW: '625px',
          borderRadius: '12px',
        },
        headerProps: {
          p: '24px',
        },
        bodyProps: {
          p: '24px',
        },
        okText: null,
        cancelText: null,
        footer: null,
      });
    },
    [confirm]
  );
};
