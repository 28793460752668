import { useMutation } from '@apollo/client';
import { DELETE_COMPLIANCE_DOCUMENT } from 'sub-components/nexus/Compliance/query/compliance.graphql';

export const useService = () => {
  const [deleteComplianceDocument, deleteComplianceData] = useMutation(
    DELETE_COMPLIANCE_DOCUMENT,
    {
      fetchPolicy: 'network-only',
    }
  );

  return {
    deleteCompliance: {
      deleteComplianceDocument,
      deleteComplianceData,
    },
  };
};
