import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';

import { useRemindUserData } from './useRemindUserData';
import UserItem from './UserItem';
import { useLocationOwners } from './useLocationOwners';

interface IProps {
  admins?: string[];
  owners?: string[];
  removeAdmin?: (userid: string) => void;
  removeOwner?: (userid: string) => void;
  locationId?: string;
}

const RemindsUserLists: FC<IProps> = ({
  admins,
  owners,
  removeAdmin,
  removeOwner,
  locationId,
}) => {
  const { adminObj } = useRemindUserData();
  const { ownerObj } = useLocationOwners(locationId);

  return (
    <Flex flexWrap='wrap' gap={2} marginTop='8px' _empty={{ display: 'none' }}>
      {owners?.map((user) => {
        return (
          <UserItem
            key={user}
            data={ownerObj[user]}
            onRemoveClick={() => removeOwner?.(user)}
          />
        );
      })}
      {admins?.map((user) => {
        return (
          <UserItem
            key={user}
            data={adminObj[user]}
            onRemoveClick={() => removeAdmin?.(user)}
          />
        );
      })}
    </Flex>
  );
};

RemindsUserLists.displayName =
  'displayName:packages/sop-web/src/sub-components/nexus/Compliance/Create/components/common/document-reminder/remind-to-user/RemindsUserLists';

export default RemindsUserLists;
