import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { FilterItem } from 'sub-components/NotificationCenter/Summary/types';
import { ModuleAccordion } from './filters/module';
import { IFilters } from './types';

interface IProps extends IFilters {}

const Filters: FC<IProps> = ({
  setSelectedModules,
  selectedModules,
  checkedModules,
  setCheckedModules,
  handleModuleSelect,
}) => {
  return (
    <Box>
      <Popover placement='bottom-start'>
        <PopoverTrigger>
          <Flex position={'relative'}>
            {selectedModules?.length > 0 && (
              <Flex
                zIndex={1}
                top={'-10px'}
                right={'-10px'}
                alignItems={'center'}
                justifyContent={'center'}
                height={'16px'}
                width={'16px'}
                position={'absolute'}
                borderRadius={'50%'}
                color={'white'}
                backgroundColor={'#2A85FF'}
              >
                <Text fontSize={'10px'}>{selectedModules?.length}</Text>
              </Flex>
            )}
            <Button
              fontSize={'14px'}
              color={'#6F767E'}
              _active={'transparent'}
              _hover={'transparent'}
              height={'30px'}
              backgroundColor={'transparent'}
              outlineColor={selectedModules?.length > 0 ? '#6F767E' : '#EFEFEF'}
              rightIcon={<FontAwesomeIcon icon={faChevronDown as IconProp} />}
            >
              Filters
            </Button>
          </Flex>
        </PopoverTrigger>
        <PopoverContent w={'280px'}>
          <PopoverArrow />
          <PopoverBody>
            <ModuleAccordion
              setSelectedModules={setSelectedModules}
              selectedModules={selectedModules}
              checkedModules={checkedModules}
              setCheckedModules={setCheckedModules}
              handleModuleSelect={handleModuleSelect}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
Filters.displayName = 'sc/NC/D/c/Filters.tsx';
export default Filters;
