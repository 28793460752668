import React, { FC, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import BaseTriggerInfo from '../trigger/BaseTriggerInfo';
import { useLauncherAlert } from '../useLauncherAlert';
import { LauncherTaskInput } from '../launcher-config.store';

interface IProps {
  onAddClick?: () => void;
  tasks?: LauncherTaskInput[];
}

const SwitchToPhaseTrigger: FC<IProps> = ({ tasks = [], onAddClick }) => {
  const { t } = useTranslation('launcher');
  const changeAlert = useLauncherAlert();

  const triggerLength = useMemo(() => {
    return tasks?.filter(
      (task) =>
        task.trigger?.triggerChoice && task.trigger?.triggerChoice !== 'default'
    )?.length;
  }, []);

  return (
    <BaseTriggerInfo
      title={t('triggerIntro.customActOrDeadTrigger')}
      buttonText={t('triggerIntro.switchToPhaseTrigger')}
      data={[
        {
          title: 'You can either set phase or task triggers',
          caption:
            'If a phase trigger is added, all task triggers will get overridden and all tasks in this phase will have the same activation and deadline trigger',
        },
      ]}
      onClick={() => {
        changeAlert({
          description: (
            <Box>
              Switching to phase triggers will{' '}
              <strong>erase previously assigned task triggers</strong> and all
              tasks in the phase will be{' '}
              <strong>assigned phase triggers</strong> as well.
            </Box>
          ),
          buttonText: 'Erase task triggers and set phase triggers',
          onConfirm: onAddClick,
        });
      }}
    >
      <Flex
        flexDir='column'
        padding='18px 20px'
        bg='#FBFAFF'
        borderRadius='12px'
        fontSize='12px'
        fontWeight='600'
      >
        <Box color='#33383F'>This phase has tasks with triggers assigned</Box>
        <Box color='#6F767E'>
          {triggerLength}/{tasks.length} tasks in this phase have dynamic
          activation or deadline triggers
        </Box>
      </Flex>
    </BaseTriggerInfo>
  );
};

export default SwitchToPhaseTrigger;
