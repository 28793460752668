import { gql } from '@apollo/client';

export const TRAINING_PAGINATION = gql`
  query TrainingPagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyTrainingInput
    $sort: SortFindManyTrainingInput
  ) {
    trainingPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        title
        entityId
        createdBy {
          email
          username
          name
          phone
          profilePic
          onboarded
          isDemo
          onboardedAt
          authRole
          type
          role
          entityId
          loginCount
          lastLogin
          isDeleted
          isRoot
          isInvited
          status
          childrens
          updatedAt
          createdAt
          eid
          locations {
            email
            username
            name
            phone
            profilePic
            onboarded
            isDemo
            onboardedAt
            authRole
            type
            role
            entityId
            loginCount
            lastLogin
            isDeleted
            isRoot
            isInvited
            status
            childrens
            updatedAt
            createdAt
            eid
          }
        }
        status
        thumbnail
        description
        contents {
          type
          eid
          title
        }
        visibility
        visibleTo {
          users
          locations
          roles
          _id
        }
        supervisors
        assignedTo
        userProgress
        updatedAt
        createdAt
        eid
        assignedToUsers {
          email
          username
          name
          phone
          profilePic
          onboarded
          isDemo
          onboardedAt
          authRole
          type
          role
          entityId
          loginCount
          lastLogin
          isDeleted
          isRoot
          isInvited
          status
          childrens
          updatedAt
          createdAt
          eid
        }
        supervisorsUsers {
          email
          username
          name
          phone
          profilePic
          onboarded
          isDemo
          onboardedAt
          authRole
          type
          role
          entityId
          loginCount
          lastLogin
          isDeleted
          isRoot
          isInvited
          status
          childrens
          updatedAt
          createdAt
          eid
        }
        contentDetails
        trainingContentList
        trainingContentExpanded
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const UPDATE_TRAINING = gql`
  mutation UpdateTraining($input: UpdateTrainingInput) {
    UpdateTraining(input: $input) {
      title
      eid
    }
  }
`;

export const TrainingPathsTableData = gql`
  query TrainingPathsTableData(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyTrainingInput
    $sort: SortFindManyTrainingInput
  ) {
    userTrainingNew(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        title
        createdBy {
          name
          eid
        }
        lastUpdatedBy {
          name
          profilePic
          eid
        }
        status
        thumbnail
        description
        visibility
        supervisors
        assignedTo
        updatedAt
        createdAt
        eid
        assignedToUsers {
          eid
          profilePic
          name
        }
        contentDetails
        trainingContentList
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const TRAINING_BY_ID_TINY = gql`
  query TrainingByIdTiny($eid: String!) {
    trainingById(eid: $eid) {
      title
      entityId
      overallProgress
      lastUpdated
      trainingContentTiny
      externalUsers {
        name
        authRole
        type
        role
        eid
        profilePic
        locations {
          eid
          name
        }
      }
      externalUserProgress
      createdBy {
        name
        profilePic
        authRole
        type
        role
        status
        eid
      }
      status
      thumbnail
      contentLastUpdate
      description
      visibility
      visibleTo {
        users
        locations
        roles
        _id
      }
      supervisors
      assignedTo
      assignedRoles
      assignedToLocations {
        eid
        name
      }
      assignedLocations
      updatedAt
      createdAt
      eid
      assignedToUsers {
        status
        assignedOn
        name
        authRole
        type
        role
        eid
        profilePic
        locations {
          eid
          name
        }
      }
      supervisorsUsers {
        name
        authRole
        type
        role
        eid
        profilePic
        locations {
          eid
          name
        }
      }
    }
  }
`;

export const TRAINING_BY_ID_COMPLETE = gql`
  query TrainingById($eid: String!) {
    trainingById(eid: $eid) {
      title
      entityId
      overallProgress
      lastUpdated
      trainingContentTiny
      externalUsers {
        email
        username
        name
        phone
        profilePic
        onboarded
        isDemo
        onboardedAt
        authRole
        type
        role
        entityId
        loginCount
        lastLogin
        isDeleted
        isRoot
        isInvited
        status
        childrens
        assignedOn
        updatedAt
        createdAt
        eid
        locations {
          email
          username
          name
          phone
          profilePic
          onboarded
          isDemo
          onboardedAt
          authRole
          type
          role
          entityId
          loginCount
          lastLogin
          isDeleted
          isRoot
          isInvited
          status
          childrens
          updatedAt
          createdAt
          eid
        }
      }
      externalUserProgress
      createdBy {
        email
        username
        name
        phone
        profilePic
        authRole
        type
        role
        entityId
        status
        eid
        locations {
          eid
          name
        }
      }
      status
      thumbnail
      contentLastUpdate
      description
      contents {
        type
        eid
        title
        backgroundColor
      }
      visibility
      visibleTo {
        users
        locations
        roles
        _id
      }
      supervisors
      assignedTo
      assignedRoles
      assignedToLocations {
        eid
        name
      }
      assignedLocations
      userProgress
      updatedAt
      createdAt
      eid
      assignedToUsers {
        email
        username
        name
        phone
        profilePic
        onboarded
        isDemo
        onboardedAt
        authRole
        type
        role
        entityId
        loginCount
        lastLogin
        isDeleted
        isRoot
        isInvited
        status
        childrens
        assignedOn
        updatedAt
        createdAt
        eid
        locations {
          email
          username
          name
          phone
          profilePic
          onboarded
          isDemo
          onboardedAt
          authRole
          type
          role
          entityId
          loginCount
          lastLogin
          isDeleted
          isRoot
          isInvited
          status
          childrens
          updatedAt
          createdAt
          eid
        }
      }
      supervisorsUsers {
        email
        username
        name
        phone
        profilePic
        onboarded
        isDemo
        onboardedAt
        authRole
        type
        role
        entityId
        loginCount
        lastLogin
        isDeleted
        isRoot
        isInvited
        status
        childrens
        updatedAt
        createdAt
        eid
        locations {
          email
          username
          name
          phone
          profilePic
          onboarded
          isDemo
          onboardedAt
          authRole
          type
          role
          entityId
          loginCount
          lastLogin
          isDeleted
          isRoot
          isInvited
          status
          childrens
          updatedAt
          createdAt
          eid
        }
      }
      contentDetails
      trainingItems
    }
  }
`;

export const getTrainingPathIcons = gql`
  query IconsList($iconsListPage2: Int, $iconsListPerPage2: Int) {
    iconsList(page: $iconsListPage2, perPage: $iconsListPerPage2) {
      items {
        url
        type
        label
        tags
        description
        backgroundColor
        updatedAt
        createdAt
        eid
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
