import './App.scss';
// @ts-ignore
import React from 'react';
import { AddSop } from './pages/AddSop/AddSop';
import { CompanyInfo } from './pages/CompanyInfo/CompanyInfo';
import Dashboard from './pages/Dashboard';
import { Sopwait } from './pages/SopWait';
import { SopList } from './pages/SopList';
import SopDetail from './pages/SopDetail';
import Auth from './Auth';
import Home from './pages/Home/Home';
import InviteRegistration from './pages/InviteRegistration';
import { ChatScreen } from './pages/Chat';
import MagicLink from './pages/MagicLink';
import Handbook from './pages/Handbook';
import CreateTrainingCard from './pages/CreateTrainingCard';
import HandbookDeck from './pages/Handbook/HandbookDeck';
import CreateDeck from './pages/CreateDeck';
import Training from './pages/Training';
import { TrainingList } from './pages/Training/TrainingList';
import Reports from './pages/Reports';
import OpenAICreation from './pages/OpenAICreation';
import SearchDelightbot from './pages/SearchDelightbot';
import DeleteUserForm from './pages/DeleteUserForm';
import TrainingPath from './pages/Training/TrainingPath/TrainingPath';
import EditTraining from './pages/EditTraining';
import ManagerTrainingView from './pages/Training/ManagerTrainingView/ManagerTrainingView';
import { CreateForm, FormRoot, FormSubmit } from './pages/forms';
import EditForm from './pages/forms/edit-form';
import CreateFormFromTemplate from './pages/forms/create-form-from-teplate';
import FormResponseRootContainer from './pages/forms/FormResponse/FormResponseRootContainer';
import CreateTrainingPathContainer from './pages/Training/CreateTrainingPath/CreateTrainingPathContainer';
import ChapterEditor from './sub-components/ChapterEditor/ChapterEditor';
import Chapters from './pages/Chapters/Chapters';
import { ChapterPreview, ChapterView } from './pages/Chapters';
import { TrainingPlay } from './pages/Training/TrainingPlay';
import { Profile } from './pages/Profile';
import { MemberDetails, Teams, TeamsPage } from './pages/Teams';
import TasksDashboard from './pages/TasksDashboard/TasksDashboard';
import {
  CreateTask,
  PerformanceDetailPage,
  AssigneeTaskPerformance,
  TaskPlay,
  JobTaskPerformance,
  LocationTaskPerformance,
  EditTask,
} from './pages/Tasks';
import { TasksPageContainer } from './pages/TasksDashboard';
import TaskDetailsRoot from './pages/TasksDashboard/TaskDetails/components/TaskDetailsRoot';
import { MyTaskDetails, MyTaskPlay } from './pages/TasksDashboard/MyTasks';
import { Settings } from './pages/Settings';
import { AddLocation, EditLocation, LocationPage } from './pages/Locations';
import LocationDetails from './pages/LocationDetails/LocationDetails';
import Playground from './pages/Playground/Playground';
import {
  LauncherBoardPage,
  LauncherSetup,
  LauncherStepsConfig,
} from './pages/launcher';
import LauncherDashboard from './pages/launcher/dashboard/LauncherDashboard';
import LocationLaunchDetails from './pages/launcher/details/LocationLaunchDetails';
import { LauncherAddLocation } from './pages/LocationsNew';
import ChatSetting from './pages/Settings/ChatSetting';
import ReviewAddLocation from './pages/ReviewAddLocation';
import { AuditReport } from './pages/Audits/Report';
import AuditRoute from './pages/Audits/AuditRoute';
import PublicReport from './pages/Audits/PublicReport/PublicReport';
import {
  GalleryPage,
  LocationRatingPage,
  NexusRoute,
} from './pages/NexusDashboard';
import LauncherLocationDetails from './sub-components/Launcher/dashboard/Location/LauncherLocationDetails';
import { DocumentListingPage } from './pages/launcher/document-listing';
import TaskDetails from './sub-components/Launcher/tasks/components/task-details/TaskDetails';

import NotificationRoutes from './pages/NotificationCenter/NotificationRoutes';

//  import RevenueDashboard from './sub-components/nexus/NexusBase/components/LeftSection/components/RevenueDashboard/RevenueDashboard';

export const CompanyInfoWarpper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <CompanyInfo />
    </Auth>
  );
};

export const AddSopWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <AddSop />
    </Auth>
  );
};

export const RequestSopWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <AddSop isOnboarded />
    </Auth>
  );
};

export const SopListWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <SopList />
    </Auth>
  );
};

export const ContinueSopWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <SopList isOnboarded />
    </Auth>
  );
};

export const SopWaitWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <Sopwait />
    </Auth>
  );
};

export const DashboardWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <Dashboard />
    </Auth>
  );
};

export const SopDetailWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <SopDetail />
    </Auth>
  );
};

export const HomeWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <Home />
    </Auth>
  );
};

export const SettingsWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <Settings />
    </Auth>
  );
};

export const HandbookWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <Handbook />
    </Auth>
  );
};
export const HandbookDetailWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <HandbookDeck />
    </Auth>
  );
};
export const InviteRegistrationWrapper = () => {
  return (
    <Auth allow='$unauthenticated' redirectTo='/'>
      <InviteRegistration />
    </Auth>
  );
};

export const ChatWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <ChatScreen />
    </Auth>
  );
};

export const MagicLinkWrapper = () => {
  return (
    <Auth allow='$unauthenticated' redirectTo='/'>
      <MagicLink />
    </Auth>
  );
};

export const CreateCardWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <CreateTrainingCard />
    </Auth>
  );
};

export const EditCardWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <CreateTrainingCard isEdit />
    </Auth>
  );
};

export const CreateDeckWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <CreateDeck />
    </Auth>
  );
};

export const EditDeckWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <CreateDeck isEdit />
    </Auth>
  );
};

export const AuditReportWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <AuditReport />
    </Auth>
  );
};

export const TrainingWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <Training />
    </Auth>
  );
};

export const TrainingWrapperNew = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <TrainingList />
    </Auth>
  );
};

export const ReportsWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <Reports />
    </Auth>
  );
};

export const EditTrainingWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <EditTraining />
    </Auth>
  );
};

export const FormsWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <FormRoot />
    </Auth>
  );
};

export const CreateFormsWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <CreateForm />
    </Auth>
  );
};

export const CreateFormsTemplateWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <CreateFormFromTemplate />
    </Auth>
  );
};

export const EditFormsWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <EditForm />
    </Auth>
  );
};

export const FormResponseWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <FormResponseRootContainer />
    </Auth>
  );
};

export const FormSubmitWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <FormSubmit />
    </Auth>
  );
};

export const ManagerTrainingViewWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <ManagerTrainingView />
    </Auth>
  );
};

export const TrainingPathWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <TrainingPath />
    </Auth>
  );
};

export const CreateTrainingPathContainerWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <CreateTrainingPathContainer />
    </Auth>
  );
};

export const OpenAICreateionWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <OpenAICreation />
    </Auth>
  );
};

export const SearchDelightbotWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <SearchDelightbot />
    </Auth>
  );
};

export const ChapterCreateEditorWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <ChapterEditor />
    </Auth>
  );
};

export const ChapterEditEditorWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <ChapterEditor />
    </Auth>
  );
};

export const ChapterPreviewWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <ChapterPreview />
    </Auth>
  );
};

export const ChapterListWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <Chapters />
    </Auth>
  );
};

export const ChapterViewWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <ChapterView />
    </Auth>
  );
};

export const TrainingPlayWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <TrainingPlay />
    </Auth>
  );
};

export const ProfileWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <Profile />
    </Auth>
  );
};

export const TeamsWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <Teams />
    </Auth>
  );
};

export const TeamsPageWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <TeamsPage />
    </Auth>
  );
};

export const MemberDetailWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <MemberDetails />
    </Auth>
  );
};

export const TasksDashboardWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='login'>
      <TasksDashboard />
    </Auth>
  );
};

export const CreateTaskWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <CreateTask />
    </Auth>
  );
};
export const LocationWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <LocationPage />
    </Auth>
  );
};

export const EditTaskWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <EditTask />
    </Auth>
  );
};
export const AddLocationWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <AddLocation />
    </Auth>
  );
};

export const ReviewAddLocationWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <ReviewAddLocation />
    </Auth>
  );
};

export const TasksPageWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <TasksPageContainer />
    </Auth>
  );
};
export const EditLocationWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <EditLocation />
    </Auth>
  );
};

export const AssigneeTaskPerformanceWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <AssigneeTaskPerformance />
    </Auth>
  );
};

export const JobTaskPerformanceWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <JobTaskPerformance />
    </Auth>
  );
};

export const LocationTaskPerformanceWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <LocationTaskPerformance />
    </Auth>
  );
};

export const PerformanceDetailsWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <PerformanceDetailPage />
    </Auth>
  );
};

export const TaskDetailsWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <TaskDetailsRoot />
    </Auth>
  );
};

export const TaskPlayWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <TaskPlay />
    </Auth>
  );
};

export const MyTaskDetailsWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <MyTaskDetails />
    </Auth>
  );
};

export const MyTaskPlayWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <MyTaskPlay />
    </Auth>
  );
};
export const LocationDetailsWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <LocationDetails />
    </Auth>
  );
};

export const DeleteUserWrapper = () => {
  return <DeleteUserForm />;
};

export const PlaygroundWrapper = () => {
  return <Playground />;
};

export const LauncherAddLocationWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <LauncherAddLocation />
    </Auth>
  );
};

export const LauncherStepsWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <LauncherStepsConfig />
    </Auth>
  );
};

export const LauncherBoardWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <LauncherBoardPage />
    </Auth>
  );
};

export const LauncherSetupWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <LauncherSetup />
    </Auth>
  );
};

export const LocationDocumentListingWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <DocumentListingPage />
    </Auth>
  );
};

export const LauncherDashboardWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <LauncherDashboard />
    </Auth>
  );
};

export const LauncherDetailWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <LocationLaunchDetails />
    </Auth>
  );
};

export const LauncherLocationDetailWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <LauncherLocationDetails />
    </Auth>
  );
};

export const ManageChannelWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <ChatSetting />
    </Auth>
  );
};

export const AuditRouteWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <AuditRoute />
    </Auth>
  );
};

export const AuditPublicReportWrapper = () => {
  return (
    <>
      <PublicReport />
    </>
  );
};

export const NexusWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <NexusRoute />
    </Auth>
  );
};

export const GalleryWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <GalleryPage />
    </Auth>
  );
};

export const LocationRatingWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <LocationRatingPage />
    </Auth>
  );
};

export const NotificationRoutesWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <NotificationRoutes />
    </Auth>
  );
};

// export const RevenueDashboardWrapper = () => {
//   return (
//     <Auth allow='$authenticated' redirectTo='/login'>
//       <RevenueDashboard />
//     </Auth>
//   );
// };

export const MyLaunchingLocationTaskDetailsWrapper = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <TaskDetails />
    </Auth>
  );
};
