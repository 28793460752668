import { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from '../../../../Header';
import { SelectOption } from '../../../../../atoms';
import { AuthRole } from '../../../../../authorization';

export const useAssigneeUsers = () => {
  const entityObjData = useReactiveVar(usersEntityObj);

  return useMemo(() => {
    if (entityObjData) {
      return entityObjData.reduce<SelectOption[]>((acc, user) => {
        if (
          [AuthRole.SUPER_ADMIN, AuthRole.ADMIN].includes(user.authRole) &&
          user.status === 'active' &&
          user.type === 'user'
        ) {
          acc.push({
            label: user.name,
            value: user.eid,
            authRole: user.authRole,
            profilePic: user?.profilePic,
            locations: user?.locations,
            role: user?.role,
          });
        }

        return acc;
      }, []);
    }
    return [];
  }, [entityObjData]);
};
