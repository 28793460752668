import React, { FC, useMemo } from 'react';
import { Control, useController } from 'react-hook-form';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Dropdown, SelectOption } from '../../../../../atoms';
import { truncateString } from '../../../../../utils';
import { useBusinessBoards } from './useBusinessBoards';
import { AddBoardInput } from './board-list.types';

const toValue = (value?: string): SelectOption | null => {
  if (value) {
    return { value: value, label: '' };
  }
  return null;
};

interface IProps {
  control: Control<AddBoardInput>;
  isSelected?: boolean;
}

const BoardSelect: FC<IProps> = ({ control, isSelected }) => {
  const { loading, data } = useBusinessBoards();

  const { field, fieldState } = useController({
    control: control,
    name: 'launcherId',
  });

  const options = useMemo(() => {
    return data.map((op) => ({
      label: truncateString(op.title, 60),
      value: op.eid,
    }));
  }, [data]);

  return (
    <FormControl isInvalid={fieldState.invalid} isDisabled={!isSelected}>
      <Dropdown
        options={options}
        isSearchable={false}
        isLoading={loading}
        value={toValue(field.value)}
        isDisabled={field.disabled}
        onChange={(newValue) => {
          return field.onChange(newValue.value);
          // return callAll(field.onChange, resetFields)(newValue.value);
        }}
        getOptionLabel={(option) => {
          if (!option?.label && option?.value) {
            return options.find((o) => o.value === option?.value)?.label;
          }
          return option?.label;
        }}
        selectStyles={{
          container: {
            marginTop: '8px',
          },
          menu: {
            maxWidth: '100%',
          },
          menuList: {
            minWidth: '100%',
          },
          singleValue: {
            fontSize: '14px',
            fontWeight: '600',
            color: '#6F767E',
          },
          placeholder: {
            fontSize: '14px',
          },
          dropdownIndicator: {
            color: '#6F767E',
          },
        }}
        inputStyle={{
          borderWidth: '2px',
          borderRadius: '12px',
          outline: 'none',
          backgroundColor: 'white',
        }}
      />
      <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default BoardSelect;
