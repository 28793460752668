import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useUserEntity } from '../hooks';

import {
  LAUNCHER_ADD_LOCATION,
  LAUNCHER_BOARD_CONFIG,
  LAUNCHER_BOARDS,
  LAUNCHER_DASHBOARD,
  LAUNCHER_DETAIL,
  LAUNCHER_LOCATION_DETAIL,
  LAUNCHER_OPENING_TASKS,
  LAUNCHER_PRE_LAUNCH_TO_LAUNCHING,
  LAUNCHER_SETUP,
  LAUNCHER_TASKS,
  LAUNCHER_URL,
  LAUNCHER_TASKS_DETAILS,
  LAUNCHER_DOCUMENT_LISTING,
  MY_LAUNCHING_LOCATION_TASK_DETAILS,
  LAUNCHER_ADD_LOCATION_FROM_BOARD,
} from '../appRoutes';
import { Authorize, AuthRole } from '../authorization';
import {
  LauncherConfigPage,
  LauncherTasksDetailsPage,
  LauncherTasksPage,
} from '../pages/launcher';
import {
  LauncherAddLocationWrapper,
  LauncherBoardWrapper,
  LauncherDashboardWrapper,
  LauncherDetailWrapper,
  LauncherSetupWrapper,
  LauncherStepsWrapper,
  LocationDocumentListingWrapper,
  MyLaunchingLocationTaskDetailsWrapper,
} from '../routeComponentDefinitions';
import LauncherPage from '../pages/launcher/LauncherPage';
import LauncherLocationDetails from '../sub-components/Launcher/dashboard/Location/LauncherLocationDetails';
import Auth from '../Auth';

const LauncherRouteInternal: FC = () => {
  const isLauncherEnabled = useUserEntity(
    (entity) => entity?.features?.launcher
  );

  if (!isLauncherEnabled) {
    return null;
  }

  return (
    <>
      <Switch>
        <Route exact path={LAUNCHER_URL} component={LauncherPage} />

        {/* TODO: Confirm auth once*/}
        <Route
          path={LAUNCHER_DOCUMENT_LISTING}
          component={LocationDocumentListingWrapper}
        />

        {/* TODO: This is not working */}
        {/* <Authorize
          permittedFor={['user']}
          permittedRoles={[
            AuthRole.SUPER_ADMIN,
            AuthRole?.ADMIN,
            AuthRole?.LOCATION_OWNER,
          ]}
        >
          <Route
            path={LAUNCHER_DOCUMENT_LISTING}
            component={LocationDocumentListingWrapper}
          />
        </Authorize> */}

        <Route exact path={LAUNCHER_TASKS} component={LauncherTasksPage} />
        <Route
          exact
          path={LAUNCHER_TASKS_DETAILS}
          component={LauncherTasksDetailsPage}
        />
        <Route path={LAUNCHER_DETAIL} component={LauncherDetailWrapper} />
        <Route
          path={MY_LAUNCHING_LOCATION_TASK_DETAILS}
          component={MyLaunchingLocationTaskDetailsWrapper}
        />

        <Authorize
          permittedFor={['user']}
          permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
        >
          <>
            <Route
              exact
              path={LAUNCHER_DASHBOARD}
              component={LauncherDashboardWrapper}
            />

            <Route
              exact
              path={LAUNCHER_BOARDS}
              component={LauncherBoardWrapper}
            />

            <Route path={LAUNCHER_SETUP} component={LauncherSetupWrapper} />

            <Route
              path={LAUNCHER_OPENING_TASKS}
              component={LauncherStepsWrapper}
            />

            <Route
              path={LAUNCHER_ADD_LOCATION}
              component={LauncherAddLocationWrapper}
            />

            <Route
              path={LAUNCHER_ADD_LOCATION_FROM_BOARD}
              component={LauncherAddLocationWrapper}
            />

            <Route
              path={LAUNCHER_PRE_LAUNCH_TO_LAUNCHING}
              component={LauncherAddLocationWrapper}
            />

            <Route
              path={LAUNCHER_BOARD_CONFIG}
              component={LauncherConfigPage}
            />

            <Route
              path={LAUNCHER_LOCATION_DETAIL}
              component={LauncherLocationDetails}
            />
          </>
        </Authorize>
      </Switch>
    </>
  );
};

const LauncherRoute: FC = () => {
  return (
    <Auth allow='$authenticated' redirectTo='/login'>
      <LauncherRouteInternal />
    </Auth>
  );
};

export default LauncherRoute;
