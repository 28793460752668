import { Box, Flex } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import React, { FC, useMemo } from 'react';
import { useProjectDetailPhaseContext } from '../../store/context';
import { analyzePhaseDependencies } from '../../utils';

interface IProps {
  index: number;
  eid: string;
}
const ToBeUnlockedText: FC<IProps> = ({ index, eid }) => {
  const { mode, phaseData } = useProjectDetailPhaseContext((state) => ({
    mode: state?.view,
    phaseData: state?.launcherData,
  }));
  const data = useMemo(() => {
    let allDependencies = analyzePhaseDependencies(phaseData?.contents || []);
    let currentDep = allDependencies?.find((dep) => dep?.phaseId === eid);
    return {
      contentData: phaseData?.contents?.filter(
        (content) => content?.eid === eid
      )?.[0]!,
      phaseDependency: currentDep,
    };
  }, [eid, phaseData]);

  if (
    !data?.phaseDependency ||
    (data?.phaseDependency?.dependsOnPhases?.length === 0 &&
      !data?.phaseDependency?.dependsOnStartDate)
  ) {
    return null;
  }

  return (
    <Flex color={HeaderColors.DarkBlue} fontSize='14px' textAlign='start'>
      To be unlocked after{' '}
      {data?.phaseDependency?.dependsOnStartDate
        ? 'Project start date'
        : data?.phaseDependency?.dependsOnPhases
            ?.map((p) => p?.category)
            ?.join(', ')}
    </Flex>
  );
};

ToBeUnlockedText.displayName =
  'displayName:pages/LocationsNew/Components/ProjectDetailPhase/components/PhaseAccordionButton/ToBeUnlockedText';

export default ToBeUnlockedText;
