import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { HeaderActionBtn, HeaderTitle } from '.';

interface IProps {}

const HeaderSection: FC<IProps> = () => {
  return (
    <Flex align='center' justify='space-between'>
      <HeaderTitle />
      <HeaderActionBtn />
    </Flex>
  );
};

HeaderSection.displayName = 'p/LN/C/PRP/c/HeaderSection';

export default HeaderSection;
