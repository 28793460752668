import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LAUNCHER_ADD_LOCATION } from '../../../../appRoutes';
import { AmplitudeEvent, deployEvent } from '../../../../shared';
import { HeaderColors } from '../../../../configs';
import { useOpenLocationConfirm } from '../../../../pages/Locations/OpenLocationConfirm/useOpenLocationConfirm';
import { Button } from '../../../../atoms';
import { AuthRole, Authorize } from '../../../../authorization';
import GenericColorHeader from 'sub-components/GenericColorHeader/GenericColorHeader';

interface IProps {
  haveConfig?: boolean;
}

const DashHeader: FC<IProps> = ({ haveConfig }) => {
  const history = useHistory();
  const { t } = useTranslation(['sidebar', 'team']);

  const openLocationConfirm = useOpenLocationConfirm();

  if (!haveConfig) {
    return (
      <Flex align='center' justify='space-between' mt={2}>
        <GenericColorHeader
          title={t('sidebar:locationLauncher')}
          color={HeaderColors.Orange}
        />
      </Flex>
    );
  }

  return (
    <Flex align='center' justify='space-between' mt={2}>
      <GenericColorHeader
        title={t('sidebar:locationLauncher')}
        color={HeaderColors.Orange}
      />

      <Authorize
        permittedFor={['user']}
        permittedRoles={[AuthRole.SUPER_ADMIN]}
      >
        <Button
          leftIcon={<AddIcon />}
          variant='outline'
          style={{
            color: HeaderColors.DarkBlue,
            backgroundColor: 'white',
          }}
          onClick={() => {
            openLocationConfirm({
              onOkPress: () => {
                deployEvent(AmplitudeEvent.LAUNCHER_ADD_LOCATION, {
                  location_launcher_visit_funnel_id: 5,
                });
                history.push(LAUNCHER_ADD_LOCATION);
              },
            });
          }}
        >
          {t('team:addNewLocation')}
        </Button>
      </Authorize>
    </Flex>
  );
};

export default DashHeader;
