import { Center, Flex } from '@chakra-ui/react';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { AccordionSection, HeaderSection } from './components';
import { ViewMode } from './types';
import {
  ProjectDetailPhaseProvider,
  useProjectDetailPhaseContext,
} from './store/context';
import { useFormContext } from 'react-hook-form';
import { IFormInput } from 'pages/LocationsNew/AddLocation/add-location.types';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import {
  LauncherConfigResponse,
  LAUNCHER_CONFIG,
} from 'sub-components/Launcher/launcher-config/common/launcher-config.graphql';
import { Loader } from 'sub-components';
import { processLauncherAssignments, toStoreData } from './utils';
import { ExtendedLauncherConfig } from './store/project-detail-phase.types';
import { usersEntityObj } from 'sub-components/Header';
import { getDuration } from 'sub-components/Launcher/launcher-config/common/trigger/duration.helper';
import { toArray } from 'utils';
import {
  convertToDays,
  convertToDuration,
  EDuration,
} from 'sub-components/Launcher/launcher-config/common/useSectionDuration';
import moment from 'moment';

interface IProps {}

const ProjectDetailPhase: FC<IProps> = () => {
  const businessUsers = useReactiveVar(usersEntityObj);
  const { control, getValues, handleSubmit, setValue } =
    useFormContext<IFormInput>();
  const { launcherData, updateLauncherData } = useProjectDetailPhaseContext(
    (state) => ({
      launcherData: state?.launcherData,
      updateLauncherData: state?.updateLauncherData,
    })
  );

  const [
    getLauncherDetailsById,
    { loading: gettingLauncherDetailsById, data },
  ] = useLazyQuery<LauncherConfigResponse>(LAUNCHER_CONFIG, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let storeData = toStoreData(data?.LauncherById);
      let processedStoreData = processLauncherAssignments(storeData!);
      updateLauncherData(processedStoreData);
    },
  });

  useEffect(() => {
    // Only fetch if:
    // 1. We don't have launcher data OR
    // 2. We have launcher data but it's for a different board
    if (
      getValues('selectedBoard') &&
      (!launcherData?.eid || launcherData?.eid !== getValues('selectedBoard'))
    ) {
      getLauncherDetailsById({
        variables: {
          eid: getValues('selectedBoard'),
        },
      });
    }
  }, [getValues('selectedBoard')]);

  useEffect(() => {
    if (!data) return;
    let duration: { duration: EDuration; phaseEid: string }[] = [];
    data?.LauncherById?.contents?.forEach((section) => {
      const phaseDuration = getDuration(section.trigger);
      if (
        section?.triggerAssignment === 'phase' &&
        phaseDuration.durationUnit
      ) {
        duration.push({
          duration: phaseDuration,
          phaseEid: section?.eid,
        });
      } else {
        const durationValue = toArray(section?.tasks).reduce(
          (acc, nextTask) => {
            return acc + convertToDays(getDuration(nextTask.trigger));
          },
          0
        );

        if (durationValue) {
          duration.push({
            // duration: convertToDuration(durationValue),
            duration: {
              durationValue: durationValue,
              durationUnit: 'days',
            },
            phaseEid: section?.eid,
          });
        }
      }
    });

    let resultDate = moment?.utc()?.startOf('day');

    duration.forEach(({ duration: { durationUnit, durationValue } }) => {
      if (durationValue && durationUnit) {
        resultDate = resultDate.add(durationValue, durationUnit);
      }
    });

    let finalDate = resultDate?.utc()?.startOf('day')?.toDate();
    setValue('startDate', finalDate);
    setValue('estimatedGoLiveDate', finalDate);
  }, [data]);

  if (gettingLauncherDetailsById) {
    return (
      <Center h='40vh' bg='white' borderRadius='8px' p={8} gap='20px'>
        <Loader />
      </Center>
    );
  }
  return (
    <Flex bg='white' borderRadius='8px' p={8} gap='20px' flexDir='column'>
      <HeaderSection />
      <AccordionSection />
    </Flex>
  );
};

ProjectDetailPhase.displayName = 'p/LN/C/PDP/ProjectDetailPhase';

export default ProjectDetailPhase;
