import { StateCreator } from 'zustand';

export interface HeaderState {
  unseenCount: number;
  updateUnseenCount: (count: number) => void;
}

export const headerSlice: StateCreator<HeaderState> = (set) => ({
  unseenCount: 0,
  updateUnseenCount: (count) => set(() => ({ unseenCount: count })),
});
