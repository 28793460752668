import React from 'react';
import { cloneDeep } from '@apollo/client/utilities';
import { SelectOption } from 'atoms';
import {
  IConditionType,
  IType,
} from 'pages/Handbook/setVisibilityModal/SetSeeModal/set-see-modal-types';
import { AuthRole, CategoryEntity, UserData } from 'sop-commons/src/client';
import { PageInfo } from 'types';
import { IHierarchy, VisibilityScope } from 'zustandStore/slices/useVisibility';
import { ChapterItem } from '../chapter.types';
import { CustomCategoryEntity } from '../ChapterHeader/components/BulkMoveContainer';
import { ChapterListResponse, ChapterSort } from '../chapters.graphql';
import { IVisibility } from '../CreateChapterModal/UploadFile/UploadFile';
import { ChapterListModal, ChapterModal } from '../modal';

interface IVisibleDataEntity {
  subSetData: {
    condition: string | undefined;
    locations: string[];
    roles: string[];
    users: string[];
  };
  superSetData: {
    locationsList: any[];
    rolesList: any[];
    membersList: any[];
  };
}

const dataConverter = ({
  subSetData: { condition, locations, roles, users },
  superSetData: { locationsList, rolesList, membersList },
}: IVisibleDataEntity) => {
  let foundLocations: any[] = [];
  let foundUsers: any[] = [];
  let foundRoles: any[] = [];
  if (locations?.length > 0) {
    foundLocations =
      locationsList?.filter((loc) => locations?.includes(loc?.eid)) || [];
  }
  if (users?.length > 0) {
    foundUsers = membersList?.filter((mem) => users?.includes(mem?.eid)) || [];
  }
  if (roles?.length > 0) {
    const rolesSet = new Set(roles || []);
    rolesList.forEach((role) => {
      if (rolesSet.has(role.eid) || rolesSet.has(role.name)) {
        foundRoles.push(role.name);
      }
    });
  }
  let obj = {
    condition: condition,
    locations: foundLocations,
    users: foundUsers,
    roles: foundRoles,
  };
  return obj;
};

export const parseFolderChapter = (
  foldersList: CategoryEntity[],
  data: ChapterListResponse | undefined,
  locationsList: any[],
  membersList: any[],
  rolesList: any[],
  folderId?: string,
  subFolderId?: string,
  pageInfo?: PageInfo | undefined,
  typeFilter?: SelectOption<string> | null,
  searchQuery?: string,
  sortBy?: ChapterSort | undefined
) => {
  let _foldersList: CategoryEntity[] = cloneDeep(foldersList || []);
  let _data: ChapterItem[] = cloneDeep(data?.SopPagination?.items || []);
  _data?.forEach((item: any) => {
    item.isSelected = false;
    item.type = 'chapter';
  });

  let subFolders: any[] = [];
  if (!subFolderId && pageInfo && pageInfo?.currentPage === 1) {
    let _subFolders: any[] = [];
    let parentFolderName = '';
    /** If there is no folder id, that means 'All' is selected and we need to show all the sub folders here */
    if (!folderId) {
      _foldersList?.forEach((folder) => {
        if (folder?.children?.length > 0) {
          folder?.children?.forEach((ch) => {
            parentFolderName = folder?.name;
            _subFolders?.push({
              ...ch,
              category: folder?.name,
              parentFolderId: folder?.eid,
              isSelected: false,
              lastUpdated: ch?.updatedAt,
              visibleToDetails: dataConverter({
                subSetData: ch?.visibleTo,
                superSetData: { locationsList, rolesList, membersList },
              }),
            });
          });
        }
      });
    } else {
      _foldersList?.map((folder) => {
        if (folder?.eid === folderId) {
          folder?.children?.forEach((ch) => {
            parentFolderName = folder?.name;
            _subFolders?.push({
              ...ch,
              category: folder?.name,
              parentFolderId: folder?.eid,
              isSelected: false,
              lastUpdated: ch?.updatedAt,
              visibleToDetails: dataConverter({
                subSetData: ch?.visibleTo,
                superSetData: { locationsList, rolesList, membersList },
              }),
            });
          });
        }
      });
    }
    if (sortBy) {
      _subFolders = _subFolders.sort((a, b) => {
        switch (sortBy) {
          case 'TITLE_ASC':
            return (a.name || '').localeCompare(b.name || '');
          case 'TITLE_DESC':
            return (b.name || '').localeCompare(a.name || '');
          case 'LASTUPDATED_ASC':
            return (
              new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
            );
          case 'LASTUPDATED_DESC':
            return (
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
            );
          case 'CREATEDAT_ASC':
            return (
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            );
          case 'CREATEDAT_DESC':
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
          case 'CREATEDBY_ASC':
            return (a.createdBy?.name || '').localeCompare(
              b.createdBy?.name || ''
            );
          case 'CREATEDBY_DESC':
            return (b.createdBy?.name || '').localeCompare(
              a.createdBy?.name || ''
            );
          default:
            return 0;
        }
      });
    }
    subFolders = cloneDeep(_subFolders);
    subFolders?.forEach((fol) => {
      fol.type = 'folder';
      fol.title = fol?.name;
      // fol.category = parentFolderName;
    });
  }
  let translatedDataList: ChapterModal[] = [];
  if (typeFilter?.value === 'all' || !typeFilter?.value) {
    if (searchQuery) {
      let filteredSubFolders =
        subFolders?.filter((sub) =>
          sub?.name
            ?.toLowerCase()
            ?.trim()
            ?.includes(searchQuery?.trim()?.toLowerCase())
        ) || [];
      translatedDataList = new ChapterListModal([
        ...filteredSubFolders,
        ..._data,
      ]).data;
    } else {
      translatedDataList = new ChapterListModal([...subFolders, ..._data]).data;
    }
  } else if (typeFilter?.value === 'subFolders') {
    if (searchQuery) {
      let filteredSubFolders =
        subFolders?.filter((sub) =>
          sub?.name
            ?.toLowerCase()
            ?.trim()
            ?.includes(searchQuery?.trim()?.toLowerCase())
        ) || [];
      translatedDataList = new ChapterListModal([...filteredSubFolders]).data;
    } else {
      translatedDataList = new ChapterListModal([...subFolders]).data;
    }
  } else {
    translatedDataList = new ChapterListModal([..._data]).data;
  }
  return translatedDataList;
};

export const allCheckboxSelect = (
  dataList: ChapterModal[],
  userObject: UserData
) => {
  return dataList
    ?.filter(
      (list) =>
        userObject?.eid === list?.createdBy?.eid ||
        userObject?.authRole === AuthRole.SUPER_ADMIN
    )
    ?.map((list) => list?.eid);
};

export const visibilityOperation = (
  statusValue: string | null,
  reset: () => void,
  setShowVisibilityModal: React.Dispatch<React.SetStateAction<boolean>>,
  updateHierarchyData: (data: IHierarchy | undefined) => void,
  updateVisibilityScope: (scope: VisibilityScope) => void,
  updateVisibilitySelection: (data: IVisibility | undefined) => void,
  allFoldersList: CustomCategoryEntity[],
  hierarchyData: IHierarchy | undefined,
  visibilityScope: VisibilityScope,
  parentFolderId?: string,
  folderId?: string,
  chapterData?: ChapterModal
) => {
  let foundParentFolder: CustomCategoryEntity | undefined = undefined;
  let foundFolder: CustomCategoryEntity | undefined = undefined;
  if (statusValue === 'RESTRICT') {
    foundParentFolder = hierarchyData?.parentFolder?.details;
    foundFolder = hierarchyData?.subFolder?.details;
  } else {
    foundParentFolder = allFoldersList?.find(
      (all) => all?.eid === parentFolderId
    );
    foundFolder = allFoldersList?.find((all) => all?.eid === folderId);

    if (chapterData && chapterData?.eid && chapterData?.categoryId) {
      let found = allFoldersList?.find((all) => all?.eid === folderId);
      if (found?.parentFolderId) {
        // the found folder is subfolder, will have parent folder
        foundParentFolder = allFoldersList?.find(
          (all) => all?.eid === found?.parentFolderId
        );
        foundFolder = found;
      } else {
        foundParentFolder = found;
        foundFolder = undefined;
      }
    }
  }
  reset();
  updateVisibilityScope(visibilityScope);
  let parentFolderObj = foundParentFolder
    ? {
        details: foundParentFolder,
        visibilityData: {
          locations: foundParentFolder?.visibleTo?.locations,
          roles: foundParentFolder?.visibleTo?.roles,
          users: foundParentFolder?.visibleTo?.users,
          ...(foundParentFolder?.visibleTo?.condition
            ? {
                condition: foundParentFolder?.visibleTo
                  ?.condition as IConditionType,
              }
            : {}),
          visibility: foundParentFolder?.visibility,
        },
      }
    : undefined;
  updateHierarchyData({
    parentFolder: parentFolderObj,
    subFolder: foundFolder
      ? {
          details: foundFolder,
          visibilityData: {
            locations: foundFolder?.visibleTo?.locations,
            roles: foundFolder?.visibleTo?.roles,
            users: foundFolder?.visibleTo?.users,
            ...(foundFolder?.visibleTo?.condition
              ? {
                  condition: foundFolder?.visibleTo
                    ?.condition as IConditionType,
                }
              : {}),
            visibility: foundFolder?.visibility,
          },
        }
      : undefined,
    ...(chapterData && chapterData?.type
      ? {
          chapter: {
            details: chapterData,
            visibilityData: {
              locations: chapterData?.visibleTo?.locations,
              roles: chapterData?.visibleTo?.roles,
              users: chapterData?.visibleTo?.users,
              condition:
                chapterData?.visibleTo?.locations?.length > 0 ||
                chapterData?.visibleTo?.roles?.length > 0
                  ? chapterData?.visibleTo?.condition
                  : undefined,
              visibility: chapterData?.visibility as IType,
            },
          },
        }
      : undefined),
  });

  updateVisibilitySelection({
    locations: visibilityDataRefactor(
      'location',
      visibilityScope,
      foundParentFolder,
      foundFolder,
      chapterData
    ),
    roles: visibilityDataRefactor(
      'role',
      visibilityScope,
      foundParentFolder,
      foundFolder,
      chapterData
    ),
    users: visibilityDataRefactor(
      'user',
      visibilityScope,
      foundParentFolder,
      foundFolder,
      chapterData
    ),
    condition: visibilityDataRefactor(
      'condition',
      visibilityScope,
      foundParentFolder,
      foundFolder,
      chapterData
    ),
    visibility: visibilityDataRefactor(
      'visibility',
      visibilityScope,
      foundParentFolder,
      foundFolder,
      chapterData
    ),
  });
  setShowVisibilityModal(true);
};

export const visibilityDataRefactor = (
  type: 'location' | 'role' | 'user' | 'condition' | 'visibility',
  visibilityScope: VisibilityScope,
  foundParentFolder: CustomCategoryEntity | undefined,
  foundFolder: CustomCategoryEntity | undefined,
  chapterData: ChapterModal | undefined
) => {
  switch (type) {
    case 'location':
      switch (visibilityScope) {
        case 'folder':
          return foundParentFolder?.visibleTo?.locations || [];
        case 'subFolder':
          return foundFolder?.visibleTo?.locations || [];
        case 'chapter':
          return chapterData?.visibleTo?.locations || [];
        default:
          return [];
      }
    case 'role':
      switch (visibilityScope) {
        case 'folder':
          return foundParentFolder?.visibleTo?.roles || [];
        case 'subFolder':
          return foundFolder?.visibleTo?.roles || [];
        case 'chapter':
          return chapterData?.visibleTo?.roles || [];
        default:
          return [];
      }
    case 'user':
      switch (visibilityScope) {
        case 'folder':
          return foundParentFolder?.visibleTo?.users || [];
        case 'subFolder':
          return foundFolder?.visibleTo?.users || [];
        case 'chapter':
          return chapterData?.visibleTo?.users || [];
        default:
          return [];
      }
    case 'condition':
      switch (visibilityScope) {
        case 'folder':
          return foundParentFolder &&
            foundParentFolder?.visibleTo?.locations?.length > 0 &&
            foundParentFolder?.visibleTo?.roles?.length > 0
            ? foundParentFolder?.visibleTo?.condition
            : undefined;
        case 'subFolder':
          return foundFolder &&
            foundFolder?.visibleTo?.locations?.length > 0 &&
            foundFolder?.visibleTo?.roles?.length > 0
            ? foundFolder?.visibleTo?.condition
            : undefined;
        case 'chapter':
          return chapterData?.visibleTo?.locations?.length > 0 &&
            chapterData?.visibleTo?.roles?.length > 0
            ? chapterData?.visibleTo?.condition
            : undefined;
        default:
          return undefined;
      }
    case 'visibility':
      switch (visibilityScope) {
        case 'folder':
          return foundParentFolder?.visibility;
        case 'subFolder':
          return foundFolder?.visibility;
        case 'chapter':
          return chapterData?.visibility;
        default:
          return 'public';
      }
    default:
      return;
  }
};

export const checkBoxCellDisableHandler = (
  userData: UserData,
  createdByEid?: string
) => {
  if (
    userData?.eid === createdByEid ||
    userData?.authRole === AuthRole.SUPER_ADMIN
  ) {
    return false;
  }
  return true;
};

export const getSortedData = (
  dataList: ChapterModal[],
  filterBy: SelectOption<string> | null
) => {
  const [chapters, subFolders] = cloneDeep(dataList)?.reduce(
    ([chaps, folders], item) => {
      if (item?.type === 'chapter') chaps?.push(item);
      else if (item?.type === 'folder') folders?.push(item);
      return [chaps, folders];
    },
    [[], []] as [ChapterModal[], ChapterModal[]]
  );

  if (!filterBy?.value || !subFolders?.length) {
    return [...subFolders, ...chapters];
  }

  const sortingStrategies = {
    AlphabeticallyAZ: (a: ChapterModal, b: ChapterModal) =>
      (a?.title || '')
        .toLowerCase()
        .localeCompare((b?.title || '').toLowerCase()),

    AlphabeticallyZA: (a: ChapterModal, b: ChapterModal) =>
      (b?.title || '')
        .toLowerCase()
        .localeCompare((a?.title || '').toLowerCase()),

    'Last Created': (a: ChapterModal, b: ChapterModal) =>
      new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime(),

    'Last Updated': (a: ChapterModal, b: ChapterModal) =>
      new Date(b?.updatedAt).getTime() - new Date(a?.updatedAt).getTime(),
  };

  const sortFn =
    sortingStrategies[filterBy.value as keyof typeof sortingStrategies];

  if (sortFn) {
    subFolders.sort(sortFn);
  }

  return [...subFolders, ...chapters];
};
