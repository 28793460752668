import { gql } from '@apollo/client';
import { FormCategory, QuestionEntity } from '../../types';
import { FormStatus, Question } from './create-form/form.types';
import { FormVisibility } from './create-form/visibility/visibility.types';

export const createForm = gql`
  mutation CreateForm($input: FormAddTypeInput) {
    createForm(input: $input) {
      eid
    }
  }
`;

export const UPDATE_FORM_QUERY = gql`
  mutation UpdateForm($input: FormUpdateTypeInput) {
    updateForm(input: $input) {
      eid
      status
    }
  }
`;

export const getDefaultCategories = gql`
  query FormCategories {
    FormCategories
  }
`;

export interface DefaultCategoryResponse {
  FormCategories: string[];
}

export const getFormCategories = gql`
  query EntityFormCategories {
    EntityFormCategories {
      categoryType
      createdBy
      eid
      entityId
      name
    }
  }
`;

export interface CategoryResponse {
  EntityFormCategories: FormCategory[];
}

export const getFormEditData = gql`
  query EditFormData($eid: String!) {
    FormById(eid: $eid) {
      title
      eid
      questions
      status
      thumbnail
      category
      categoryId
      description
      contributors
      notifyVia
      visibility
      visibleTo {
        condition
        locations
        roles
        users
      }
      responseVisibility
    }
  }
`;

interface EditableForm extends FormVisibility {
  title: string;
  eid: string;
  status: string;
  thumbnail: string;
  categoryId: string;
  category: string;
  description: string;
  questions: any[];
  contributors: string[];
  notifyVia?: ('sms' | 'email')[];
  responseVisibility?: 'public' | 'private';
}

export interface FormEditResponse {
  FormById: EditableForm;
}

export const formSearch = gql`
  query FormSearch(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyFormsInput
  ) {
    FormsPagination(page: $page, perPage: $perPage, filter: $filter) {
      count
      items {
        title
        eid
        category
        categoryId
        thumbnail
        description
        questions
        createdBy {
          eid
        }
      }
    }
  }
`;

export interface FormSearchVariable {}

export interface FormSearchItem {
  eid: string;
  title: string;
  thumbnail: string;
  category: string;
  description: string;
  questions: any[];
}

export interface FormSearchResponse {
  FormsPagination: {
    count: number;
    items: FormSearchItem[];
  };
}

export const getTemplateById = gql`
  query TemplateById($eid: String!) {
    FormById(eid: $eid) {
      title
      isTemplate
      category
      eid
      description
      category
      categoryId
      thumbnail
      status
      questions
    }
  }
`;

export interface TemplateDataResponse {
  FormById: {
    title: string;
    eid: string;
    isTemplate?: boolean;
    description: string;
    category: string;
    thumbnail: string;
    status: FormStatus;
    questions: Question[];
  };
}

export const getFormById = gql`
  query FormForSubmission($eid: String!, $override: Boolean) {
    FormById(eid: $eid, override: $override) {
      title
      eid
      description
      category
      categoryId
      thumbnail
      status
      questions
      contributors
      visibility
      visibleTo {
        locations
        roles
        users
        condition
      }
      createdBy {
        eid
      }
    }
  }
`;

interface FormResponseData extends FormVisibility {
  contributors?: string[];
  createdBy: {
    eid: string;
  };
}

export type FormResponse = {
  FormById: TemplateDataResponse['FormById'] & FormResponseData;
};
