import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { capitalizeFirstLetter } from '../../../../../../utils';
import { ActivationTrigger } from '../../../common/launcher-config.fragment';
import { useLauncherConfigContext } from '../../../common';
import { triggerKey } from '../../../common/trigger';
import { mapConfigToObject } from '../../../common/context.helper';

import { ReactComponent as LockIcon } from '../../../../../../assets/images/tasks/lock.svg';

interface IProps {
  title: string;
  trigger: ActivationTrigger;
  sectionIndex: number;
  taskIndex: number;
  isRequired?: boolean;
}

const TaskDependDetails: FC<IProps> = ({
  title,
  trigger,
  sectionIndex,
  taskIndex,
  isRequired,
}) => {
  const dependName = useLauncherConfigContext((state) => {
    const value = state.config.contents?.[sectionIndex]?.tasks?.[taskIndex];

    const active = value?.trigger?.activationTrigger;

    if (triggerKey[active?.dependentOn!]) {
      return triggerKey[active?.dependentOn!];
    }

    const details = mapConfigToObject(state.config?.contents)[active?.itemId!];

    return details?.title || details?.category;
  });

  return (
    <Flex flexDir='column'>
      <Box fontSize='15px' fontWeight='500' color='#33383F'>
        <LockIcon
          style={{ minWidth: 16, display: 'inline', marginBottom: -3 }}
          width='16'
          height='20'
          color='#6F767E'
        />
        &nbsp;{title}
        {isRequired ? <span style={{ color: 'red' }}> *</span> : null}
      </Box>
      <Box fontSize='13px' fontWeight='600' color='#2A85FF' isTruncated>
        To be Unlocked: {capitalizeFirstLetter(trigger?.order)} {dependName}
      </Box>
    </Flex>
  );
};

export default TaskDependDetails;
