import { Box, Flex } from '@chakra-ui/react';
import { useAuditDetailContext } from 'pages/Audits/Detail/store/context';
import React, { FC, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  AuditSessionEntity,
  AuditStatusFunctionEntity,
} from 'sub-components/audits/audit-tab/supervised/types';
import DownloadReport from 'sub-components/audits/common/download-report';

interface IProps {
  auditData: AuditSessionEntity | undefined;
  auditStatus: AuditStatusFunctionEntity;
}

const ReportDetail: FC<IProps> = ({ auditData, auditStatus }) => {
  const history = useHistory();
  const { sessionId } = useParams<{ sessionId: string }>();
  const selectedLocationFilter = useAuditDetailContext(
    (state) => state?.selectedLocationFilter
  );

  const { selectedLocationId, reportsUrl } = useMemo(() => {
    let selectedLocation = auditData?.assignments?.find(
      (assignment) => assignment?.locationId === selectedLocationFilter?.value
    );
    return {
      selectedLocationId: selectedLocation?.locationId || '',
      reportsUrl: selectedLocation?.reportsUrl || '',
    };
  }, [auditData, selectedLocationFilter]);

  const viewReport = () => {
    let selectedLocation = auditData?.assignments?.find(
      (assignment) => assignment?.locationId === selectedLocationFilter?.value
    );
    if (selectedLocation) {
      history.push(
        `/audits/report/lI/${selectedLocation?.locationId}/sI/${sessionId}`
      );
    }
  };

  if (
    !selectedLocationFilter ||
    selectedLocationFilter?.value === 'allLocations'
  ) {
    return null;
  }

  return (
    <Flex justify='space-between' color='#1A1D1F'>
      <Box flex={1} fontWeight={600}>
        Report:
      </Box>
      {auditStatus === 'completed' ? (
        <Flex align='center' gap={8}>
          <Box
            as='span'
            color='#2A85FF'
            fontSize='12px'
            fontWeight={600}
            cursor='pointer'
            onClick={viewReport}
          >
            View
          </Box>
          <DownloadReport
            locationId={selectedLocationId}
            sessionId={sessionId}
            reportsUrl={reportsUrl}
          >
            <Box
              as='span'
              color='#2A85FF'
              fontSize='12px'
              fontWeight={600}
              cursor='pointer'
              // onClick={downloadReport}
            >
              Download
            </Box>
          </DownloadReport>
        </Flex>
      ) : (
        <Box as='span' color='#6F767E' fontWeight={500}>
          Yet to be generated
        </Box>
      )}
    </Flex>
  );
};

ReportDetail.displayName = 'sc/a/d/c/O/c/SS/c/ReportDetail';

export default ReportDetail;
