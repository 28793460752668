import React, { FC } from 'react';
import { AspectRatio, Grid } from '@chakra-ui/react';
import { ImageItem } from '../types';
import Image from 'ui-components/Image';

interface IProps {
  images?: ImageItem[];
  colsNo?: number;
}

const GalleryImageList: FC<IProps> = ({ images = [], colsNo = 4 }) => {
  return (
    <Grid templateColumns={`repeat(${colsNo}, 1fr)`} gap={1}>
      {images?.map((item, index) => (
        <Image
          key={index}
          src={
            item.source === 'locPhotos'
              ? `${item.url}&width=500&height=500`
              : item.url
          }
          width={336}
          height={336}
          style={{
            width: '100%',
            objectFit: 'cover',
            aspectRatio: '1',
          }}
        />
      ))}
    </Grid>
  );
};

GalleryImageList.displayName = 'sc/n/G/c/GIL/GalleryImageList';

export default GalleryImageList;
