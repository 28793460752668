import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { ActionButton } from '../../../../ui-components';
import { useLauncherConfigContext } from './context';
import { BoardListPopup } from './boards-list';

interface IProps {}

const BoardNameButton: FC<IProps> = () => {
  const { configTitle, initializing } = useLauncherConfigContext((state) => ({
    configTitle: state.config?.title,
    initializing: state.initializing,
  }));

  // const configTitle = useLauncherConfigContext((state) => state.config?.title);

  return (
    <BoardListPopup>
      <ActionButton
        size='lg'
        variant='outline'
        bg='#FFFFFF'
        // isDisabled
        // _disabled={{}}
        color='#6F767E'
        maxWidth='300px'
        rightIcon={
          <FontAwesomeIcon fontSize={16} icon={faChevronDown as IconProp} />
        }
      >
        <Box as='span' isTruncated>
          Board: {configTitle}
        </Box>
      </ActionButton>
    </BoardListPopup>
  );
};

export default BoardNameButton;
