import React, { FC, ReactNode } from 'react';
import { Box, Center, Flex, useDisclosure } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface IProps {
  title: string;
  icon: ReactNode;
}

const ExpandableFilter: FC<IProps> = ({ title, children, icon }) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Box>
      <Flex
        align='center'
        gap='10px'
        color='#6F767E'
        padding={3}
        borderRadius='10px'
        aria-selected={isOpen}
        _selected={{ bg: '#F4F4F4', color: '#1A1D1F' }}
        _hover={{ bg: '#F4F4F4' }}
        onClick={onToggle}
      >
        <Center boxSize='24px'>{icon}</Center>
        <Box flex={1} fontSize='15px' fontWeight='600' isTruncated>
          {title}
        </Box>
        <ChevronDownIcon
          boxSize='22px'
          aria-selected={isOpen}
          _selected={{
            transform: 'rotate(180deg)',
          }}
        />
      </Flex>
      {isOpen ? children : null}
    </Box>
  );
};

export default ExpandableFilter;
