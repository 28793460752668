import React, { FC, useState } from 'react';
import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { MessageIconButton, SearchInput } from 'atoms';
import Loader from 'ui-components/Loader';
import { RoleAndAuthRole } from 'ui-components';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { getImage } from 'utils';
import EmptyState from 'sub-components/EmptyState';

interface NotRelevantProps {
  isOpen: Boolean;
  onToggle: () => void;
}
const NotRelevant: FC<NotRelevantProps> = ({ isOpen }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [taskByIdLoading, setTaskByIdLoading] = useState(false);

  const users = [
    {
      eid: '1',
      profilePic: 'https://via.placeholder.com/40',
      name: 'John Doe',
      authRole: 'Admin',
      role: 'Manager',
      locations: [{ eid: 'loc1', name: 'New York' }],
    },
    {
      eid: '2',
      profilePic: 'https://via.placeholder.com/40',
      name: 'Jane Smith',
      authRole: 'User',
      role: 'Supervisor',
      locations: [{ eid: 'loc2', name: 'California' }],
    },
    {
      eid: '3',
      profilePic: 'https://via.placeholder.com/40',
      name: 'Alice Johnson',
      authRole: 'Moderator',
      role: 'Team Lead',
      locations: [{ eid: 'loc3', name: 'Texas' }],
    },
  ];

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Drawer
      size='md'
      isOpen={isDrawerOpen}
      placement='right'
      onClose={() => setIsDrawerOpen(false)}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <GenericColorHeader
            title='Task not relevant'
            color={HeaderColors.Purple}
          />
        </DrawerHeader>
        <DrawerBody px={0}>
          {taskByIdLoading ? (
            <Center w='full' h='80vh'>
              <Loader />
            </Center>
          ) : (
            <Flex flexDir='column' gap='10px'>
              <Flex flexDir='column' gap='10px' px={6}>
                <Box bg='#CABDFF66' p={4} borderRadius='10px'>
                  If this task is not relevant then please message task
                  supervisors to remove you from the assignee list.
                </Box>
                <SearchInput
                  placeholder='Search supervisors, creators'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  hideShortcuts
                />
              </Flex>
              <List spacing='10px'>
                {filteredUsers.map((user, index) => (
                  <ListItem
                    key={index}
                    px={6}
                    py='10px'
                    _hover={{
                      bg: '#EFEFEFB2',
                    }}
                  >
                    <Flex gap='10px' align='center'>
                      <Image
                        src={getImage(user.profilePic, user.name)}
                        boxSize='40px'
                        borderRadius='full'
                      />
                      <Flex justify='space-between' align='center' w='full'>
                        <Flex flexDir='column'>
                          <Text
                            fontWeight={600}
                            p={0}
                            m={0}
                            isTruncated
                            maxW='300px'
                          >
                            {user.name}
                          </Text>
                          <Flex gap='5px' color='#92929D'>
                            <RoleAndAuthRole
                              role={user.role}
                              authRole={user.locations[0]?.name}
                              disableAuthRoleSplit
                              disableToolTip
                              roleProps={{
                                color: '#92929D',
                                fontWeight: '500',
                              }}
                              authRoleProps={{
                                color: '#92929D',
                                fontWeight: '500',
                              }}
                            />
                          </Flex>
                        </Flex>
                        <MessageIconButton />
                      </Flex>
                    </Flex>
                  </ListItem>
                ))}
              </List>
            </Flex>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

NotRelevant.displayName =
  'displayName:sub-components/Launcher/tasks/components/my-tasks/menu/NotRelevant';

export default NotRelevant;
