import React, { CSSProperties, FC, useMemo } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { IConditionType, IRoleData, IType } from '../set-see-modal-types';
import {
  ISelectedLocationData,
  ISelectedMemberData,
  ISelectedRoleData,
} from 'zustandStore/slices/useVisibility';
import useCombinedStore from 'zustandStore/store';
import { useReactiveVar } from '@apollo/client';
import { loginUserBranchRootObj, usersEntityObj } from 'sub-components/Header';
import { userObj } from 'sop-commons/src/client';

interface IProps {
  marginTop?: string | undefined;
  backgroundColor?: string | undefined;
  descTitle?: string;
  customIcon?: React.ReactChild;
  conditionOperatorColor?: CSSProperties['color'];
  defaultSubFolderMsg?: boolean;
  parentFolderName?: string;
  customElement?: React.ReactChild;
}

const GenerateConditionsText: FC<IProps> = ({
  marginTop = undefined,
  backgroundColor = undefined,
  descTitle,
  customIcon,
  conditionOperatorColor,
  defaultSubFolderMsg,
  parentFolderName,
  customElement,
}) => {
  const { hierarchyData, operationType, visibilityScope, visibilitySelection } =
    useCombinedStore();

  const userBranchRoot: any = useReactiveVar(loginUserBranchRootObj);
  const membersData = useReactiveVar(usersEntityObj);
  const userData = useReactiveVar(userObj);
  const locationsList = useMemo(() => {
    let _visibilitySelection =
      descTitle === 'Folder visibility:' && visibilityScope !== 'folder'
        ? hierarchyData?.parentFolder?.visibilityData
        : visibilitySelection;
    if (
      !_visibilitySelection?.locations ||
      !userBranchRoot?.descendantBranches
    ) {
      return [];
    }

    return _visibilitySelection.locations
      .map((location) => {
        const branch = userBranchRoot.descendantBranches.find(
          (branch: any) => branch.eid === location
        );
        if (branch) {
          return {
            eid: branch.eid,
            name: branch.name,
            label: branch.name,
            value: branch.eid,
          };
        }
        return null;
      })
      .filter((item) => item !== null);
  }, [visibilitySelection, userBranchRoot]);

  const rolesList = useMemo(() => {
    let _visibilitySelection =
      descTitle === 'Folder visibility:' && visibilityScope !== 'folder'
        ? hierarchyData?.parentFolder?.visibilityData
        : visibilitySelection;
    const roles = userData?.entity?.roles || [];
    let updatedRolesData: IRoleData[] = roles?.map<IRoleData>((role: any) => {
      return {
        ...role,
        label: role?.name,
        value: role?.name,
      };
    });
    if (!_visibilitySelection?.roles || !userData?.entity?.roles) {
      return [];
    }
    return _visibilitySelection?.roles
      ?.map((role) => {
        const _role = updatedRolesData?.find(
          (_r) => _r?.eid === role || _r?.name === role
        );
        if (_role) {
          return {
            eid: _role.eid,
            name: _role.name,
            label: _role.name,
            value: _role.eid,
          };
        }
        return null;
      })
      .filter((item) => item !== null);
  }, [visibilitySelection, userData?.entity?.roles]);

  const membersList = useMemo(() => {
    let _visibilitySelection =
      descTitle === 'Folder visibility:' && visibilityScope !== 'folder'
        ? hierarchyData?.parentFolder?.visibilityData
        : visibilitySelection;
    if (!_visibilitySelection?.users || !membersData) {
      return [];
    }

    return _visibilitySelection?.users
      .map((user) => {
        const foundUser = membersData?.find((_user) => _user?.eid === user);
        if (foundUser) {
          return {
            eid: foundUser.eid,
            name: foundUser.name,
            label: foundUser.name,
            value: foundUser.eid,
          };
        }
        return null;
      })
      .filter((item) => item !== null);
  }, [visibilitySelection, membersData]);

  const { t } = useTranslation('deck');
  const store = useCombinedStore();
  const conditionType =
    descTitle === 'Folder visibility:' && visibilityScope !== 'folder'
      ? store?.hierarchyData?.parentFolder?.visibilityData?.condition
      : store?.visibilitySelection?.condition;
  const selectionType =
    descTitle === 'Folder visibility:' && visibilityScope !== 'folder'
      ? store?.hierarchyData?.parentFolder?.visibilityData?.visibility
      : store?.visibilitySelection?.visibility;
  // const setSeeCtx = useContext(SetSeeDataContext);
  const selectedUsersLength = membersList?.length || 0;
  const selectedLocationsLength = locationsList?.length || 0;
  const selectedRolesLength = rolesList?.length || 0;

  const getLocationRoleText = () => {
    if (selectedLocationsLength && selectedRolesLength) {
      return (
        // <div>
        //   <Trans
        //     t={t}
        //     i18nKey='visibility.have_the_location_job'
        //     count={locationsList?.length}
        //     values={{
        //       value: getSerialNumberHandler('locationRole'),
        //       detail: getDetailsHandler('location'),
        //       operator: conditionType?.toLowerCase(),
        //       roleDetail: getDetailsHandler('role'),
        //     }}
        //     components={[
        //       <span
        //         key='operator'
        //         style={{
        //           fontWeight: '600',
        //           color: conditionOperatorColor || '#2a85ff',
        //         }}
        //       />,
        //     ]}
        //   />
        // </div>
        <Flex gap='5px'>
          <Text as='span'>{getSerialNumberHandler('locationRole')}</Text>
          <Box>
            Visible to people who are in the{' '}
            <Text as='span' fontWeight={700} mr='5px'>
              {getDetailsHandler('location')}
            </Text>
            <Text
              as='span'
              mr='5px'
              color={conditionOperatorColor || '#2a85ff'}
              fontWeight={700}
            >
              {conditionType?.toLowerCase()}
            </Text>
            <Text as='span' mr='5px'>
              have the job
            </Text>
            <Text as='span' fontWeight={700}>
              {getDetailsHandler('role')}
            </Text>
          </Box>
          {/* <div>
            <Trans
              t={t}
              i18nKey='visibility.have_the_location_job'
              count={locationsList?.length}
              values={{
                value: getSerialNumberHandler('locationRole'),
                detail: getDetailsHandler('location'),
                operator: conditionType?.toLowerCase(),
                roleDetail: getDetailsHandler('role'),
              }}
              components={[
                <span
                  key='operator'
                  style={{
                    fontWeight: '600',
                    color: conditionOperatorColor || '#2a85ff',
                  }}
                />,
              ]}
            />
          </div> */}
        </Flex>
      );
    } else if (selectedLocationsLength) {
      return (
        <Flex gap='5px'>
          <Text as='span'>{getSerialNumberHandler('locationRole')}</Text>
          <Box>
            <Text as='span' mr='5px'>
              Visible to people who are in the
            </Text>
            <Text as='span' fontWeight={700} mr='5px'>
              {getDetailsHandler('location')}
            </Text>
            <Text as='span'>
              {locationsList?.length === 1 ? 'location' : 'locations'}
            </Text>
          </Box>
          {/* <p>
            <Trans
              t={t}
              i18nKey='visibility.have_the_location'
              count={locationsList?.length}
              values={{
                value: getSerialNumberHandler('locationRole'),
                detail: getDetailsHandler('location'),
              }}
            />
          </p> */}
        </Flex>
      );
      // return (
      //   <p>
      //     <Trans
      //       t={t}
      //       i18nKey='visibility.have_the_location'
      //       count={locationsList?.length}
      //       values={{
      //         value: getSerialNumberHandler('locationRole'),
      //         detail: getDetailsHandler('location'),
      //       }}
      //     />
      //   </p>
      // );
    } else if (selectedRolesLength) {
      return (
        <Flex gap='5px'>
          <Text as='span'>{getSerialNumberHandler('locationRole')}</Text>
          <Box>
            <Text as='span' mr='5px'>
              Visible to people who have the job
            </Text>
            <Text as='span' fontWeight={700} mr='5px'>
              {getDetailsHandler('role')}
            </Text>
          </Box>
          {/* <p>
            <Trans
              t={t}
              i18nKey='visibility.have_the_job'
              values={{
                value: getSerialNumberHandler('locationRole'),
                detail: getDetailsHandler('role'),
              }}
            />
          </p> */}
        </Flex>
      );
      // return (
      //   <p>
      //     <Trans
      //       t={t}
      //       i18nKey='visibility.have_the_job'
      //       values={{
      //         value: getSerialNumberHandler('locationRole'),
      //         detail: getDetailsHandler('role'),
      //       }}
      //     />
      //   </p>
      // );
    } else {
      return null;
    }
  };

  const getSerialNumberHandler = (type: 'locationRole' | 'user') => {
    if (type === 'locationRole') {
      if (selectedUsersLength) {
        return '1. ';
      } else {
        return null;
      }
    } else {
      if (selectedLocationsLength || selectedRolesLength) {
        return '2. ';
      } else {
        return null;
      }
    }
  };

  const getDetailsHandler = (type: string) => {
    let _type: any[] = [];
    if (type === 'location') {
      _type = locationsList;
    } else {
      _type = rolesList;
    }
    let str = '';
    str = _type?.map((gen: any) => gen?.name)?.join(', ');
    return str;
  };

  const getOperatorHandler = () => {
    return (
      <span style={{ fontWeight: '600', color: '#2a85ff' }}>
        {conditionType?.toLowerCase()}&nbsp;
      </span>
    );
  };

  const getUserText = () => {
    if (selectedUsersLength) {
      // return (
      //   <span>
      //     {getSerialNumberHandler('user')}Visible to{' '}
      //     {generateUsersTextHandler()}
      //   </span>
      // );
      return (
        <Flex gap='5px'>
          <Text as='span'>{getSerialNumberHandler('user')}</Text>
          <Box>
            <Text as='span' mr='5px'>
              Visible to
            </Text>
            <Text as='span' mr='5px' fontWeight={700}>
              {generateUsersTextHandler()}
            </Text>
            <Text as='span' mr='5px'>
              regardless of{' '}
              {membersList?.length === 1
                ? 'his/her job and location'
                : 'their job and location'}
            </Text>
          </Box>
        </Flex>
      );
    } else {
      return null;
    }
  };

  const generateUsersTextHandler = () => {
    let generatedString = '';
    const memberList = membersList;
    if (memberList?.length < 3) {
      if (memberList?.length === 1) {
        // @ts-ignore
        generatedString = memberList.map((user) => user?.name).join(', ');
        // generatedString = t('visibility.user_text_singular_job_changed', {
        //   value: memberList.map((user) => user?.name).join(', '),
        // });
      } else {
        generatedString = memberList.map((user) => user?.name).join(', ');
        // generatedString = t('visibility.user_text_plural_job_changed', {
        //   value: memberList.map((user) => user?.name).join(', '),
        // });
      }
    } else {
      let _selectedUsers = JSON.parse(JSON.stringify(memberList));
      let lastElement = _selectedUsers?.pop();
      generatedString =
        _selectedUsers.map((user: any) => user.name).join(', ') +
        ' and ' +
        lastElement?.name;
      // generatedString = t('visibility.user_text_more_job_changed', {
      //   value: _selectedUsers.map((user: any) => user.name).join(', '),
      //   name: lastElement?.name,
      // });
    }
    return generatedString;
  };

  const getGenericText = (text: string) => {
    return (
      <div
        style={{
          backgroundColor: backgroundColor,
          marginTop: marginTop,
          borderRadius: '10px',
          padding: '16px 20px 16px 20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div style={{ placeSelf: 'flex-start', marginTop: '1px' }}>
          {customIcon ? (
            customIcon
          ) : (
            <FontAwesomeIcon
              icon={faEye as IconProp}
              style={{ color: '#2a85ff', marginRight: '10px' }}
            />
          )}
        </div>
        <Flex style={{ flexDirection: 'column' }}>
          {/* {visibilityScope === 'subFolder' && operationType === 'edit' && (
            <Flex align='center' gap='5px'>
              <Text fontWeight={700}>
                {hierarchyData?.subFolder?.details?.name || 'Sub folder'}
              </Text>{' '}
              visibility is set to -
            </Flex>
          )} */}
          {/* {descTitle && <Text fontWeight={700}>{descTitle}</Text>} */}
          {customElement}
          <span>{text}</span>
          {defaultSubFolderMsg && (
            <Text mt={2}>
              This is inherited visibility from the parent folder (
              {parentFolderName})
            </Text>
          )}
        </Flex>
      </div>
    );
  };

  const returnHandler = () => {
    if (
      (selectedUsersLength || selectedLocationsLength || selectedRolesLength) &&
      selectionType === 'private'
    ) {
      return (
        <Flex
          w='full'
          style={{
            backgroundColor: backgroundColor,
            marginTop: marginTop,
            borderRadius: '10px',
            padding: '16px 20px 16px 20px',
            alignItems: 'center',
          }}
        >
          <div style={{ placeSelf: 'flex-start', marginTop: '1px' }}>
            {customIcon ? (
              customIcon
            ) : (
              <FontAwesomeIcon
                icon={faEye as IconProp}
                style={{ color: '#2a85ff', marginRight: '10px' }}
              />
            )}
          </div>
          <Flex flexDir='column'>
            {/* {visibilityScope === 'subFolder' &&
              (operationType === 'add' || operationType === 'edit') && (
                <Flex align='center' gap='5px'>
                  <Text fontWeight={700}>
                    {hierarchyData?.subFolder?.details?.name || 'Sub folder'}
                  </Text>{' '}
                  visibility is set to -
                </Flex>
              )} */}
            {/* {descTitle && <Text fontWeight={700}>{descTitle}</Text>} */}
            {customElement}
            {selectionType === 'private' && getLocationRoleText()}
            {selectionType === 'private' && getUserText()}
            {defaultSubFolderMsg && (
              <Text mt={2}>
                This is inherited visibility from the parent folder (
                {parentFolderName})
              </Text>
            )}
          </Flex>
        </Flex>
      );
    } else if (selectionType === 'public') {
      switch (visibilityScope) {
        case 'folder':
          return getGenericText(
            'Everyone in the org, regardless of job or location, can see and search for this folder.'
          );
        case 'subFolder':
          return getGenericText(
            'Everyone in the org, regardless of job or location, can see and search for this sub folder.'
          );
        case 'chapter':
          return getGenericText(t('visibility.publicJobChanged'));
        default:
          return getGenericText(t('visibility.publicJobChanged'));
      }
    } else if (selectionType === 'private') {
      switch (visibilityScope) {
        case 'folder':
          return getGenericText(
            'Choose locations, jobs, or members who can view this folder.'
          );
        case 'subFolder':
          return getGenericText(
            'Choose locations, jobs, or members who can view this sub folder.'
          );
        case 'chapter':
          return getGenericText(t('visibility.privateJobChanged'));
        default:
          return getGenericText(t('visibility.privateJobChanged'));
      }
    } else {
      return null;
    }
  };

  return returnHandler();
};

GenerateConditionsText.displayName =
  'displayName:pages/Handbook/setVisibilityModal/SetSeeModal/GenerateConditionsText/GenerateConditionsText';

export default GenerateConditionsText;
