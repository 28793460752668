import React, { FC } from 'react';
import {
  AccordionButton,
  AccordionItem,
  Box,
  Flex,
  Radio,
} from '@chakra-ui/react';
import PhaseSelect, { PhaseSelectItem } from './PhaseSelect';

interface IProps {
  taskPhaseId: string;
  phases: PhaseSelectItem[];
  triggerType?: 'before' | 'after';
  type: 'phase' | 'task';
}

const PhaseList: FC<IProps> = ({
  taskPhaseId,
  phases = [],
  triggerType,
  type,
}) => {
  if (triggerType === 'before') {
    return (
      <AccordionItem borderTopWidth={0} borderRadius='12px'>
        <AccordionButton borderRadius='12px' padding={3}>
          <Flex flex={1} align='center' gap={3} overflow='hidden'>
            <Radio size='lg' value='-goLive' />
            <Box fontSize='15px' fontWeight='600' color='#1A1D1F' isTruncated>
              Go-Live date
            </Box>
          </Flex>
        </AccordionButton>
      </AccordionItem>
    );
  }

  return (
    <>
      <AccordionItem borderTopWidth={0} borderRadius='12px'>
        <AccordionButton borderRadius='12px' padding={3}>
          <Flex flex={1} align='center' gap={3} overflow='hidden'>
            <Radio size='lg' value='-startDate' />
            <Box fontSize='15px' fontWeight='600' color='#1A1D1F' isTruncated>
              Project start date
            </Box>
          </Flex>
        </AccordionButton>
      </AccordionItem>

      {phases.map((phase, index) => (
        <PhaseSelect
          taskPhaseId={taskPhaseId}
          type={type}
          phase={phase}
          phaseIndex={index}
          key={index}
        />
      ))}
    </>
  );
};

PhaseList.displayName = 'sc/L/lc/c/t/PhaseList';

export default PhaseList;
