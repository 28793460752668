import React, { FC, useEffect } from 'react';
import { Center, Flex } from '@chakra-ui/react';
import { useRouteState } from '../../../routes';

const AuditTabs: FC = () => {
  const { state, updateState } = useRouteState('audit', {
    selectedTab: 'supervisedByMe',
  });

  useEffect(() => {
    const auditTab = sessionStorage.getItem('auditInitialTab');
    if (auditTab) {
      updateState({
        selectedTab: auditTab,
      });
      sessionStorage.removeItem('auditInitialTab');
    }
  }, []);

  const selectedTab = state.selectedTab;

  function updateSelectedTab(name: string) {
    updateState({
      selectedTab: name,
    });
  }

  return (
    <Flex gap={4} fontSize='15px' fontWeight='600'>
      <Center
        h='40px'
        borderRadius='8px'
        cursor='pointer'
        aria-selected={selectedTab === 'supervisedByMe'}
        p='8px 16px'
        bg='#FCFCFC'
        color='#6F767E'
        _selected={{
          bg: '#EFEFEF',
          color: 'black',
        }}
        onClick={() => updateSelectedTab('supervisedByMe')}
      >
        Supervised by me
      </Center>

      <Center
        h='40px'
        borderRadius='8px'
        cursor='pointer'
        aria-selected={selectedTab === 'myAudits'}
        p='8px 16px'
        bg='#FCFCFC'
        color='#6F767E'
        _selected={{
          bg: '#EFEFEF',
          color: 'black',
        }}
        onClick={() => updateSelectedTab('myAudits')}
      >
        My Audits
      </Center>
    </Flex>
  );
};

export default AuditTabs;
