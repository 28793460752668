type AssetType = 'image' | 'file' | 'video' | 'audio' | 'document';

export const getAssetTypeFromMimeType = (mimetype: string): AssetType => {
  if (mimetype.startsWith('image/')) {
    return 'image';
  }

  if (mimetype.startsWith('video/')) {
    return 'video';
  }

  if (mimetype.startsWith('audio/')) {
    return 'audio';
  }

  if (mimetype.startsWith('application/') || mimetype.startsWith('text/')) {
    const documentMimeTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ];

    if (documentMimeTypes.includes(mimetype)) {
      return 'document';
    }

    return 'file';
  }

  return 'file';
};
