import { Flex, Text, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faUserGroup } from '@fortawesome/pro-regular-svg-icons';
import { faEarthAsia, faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { IChapterVisibleToDetails } from './chapter.types';

interface IProps {
  visibleData: {
    visibleTo: IChapterVisibleToDetails;
    visibility: string;
    status: string;
  };
}

const VisibilityBadge: FC<IProps> = ({ visibleData }) => {
  const customVisibility = () => {
    const locationNames = visibleData?.visibleTo?.locations
      ?.map((location) => location?.name)
      ?.join(', ');
    const roleNames = visibleData?.visibleTo?.roles?.join(', ');
    const userNames = visibleData?.visibleTo?.users
      ?.map((user) => user?.name)
      ?.join(', ');

    const locationString =
      visibleData?.visibleTo?.locations?.length > 0
        ? `people who are in ${locationNames}`
        : '';
    const rolesString =
      visibleData?.visibleTo?.roles?.length > 0
        ? visibleData?.visibleTo?.roles?.join(', ')
        : '';
    const usersString =
      visibleData?.visibleTo?.users?.length > 0 ? `${userNames}` : '';

    let result = 'Visible to ';

    if (locationNames) {
      result += locationString;
      if (roleNames || userNames) {
        result += `, ${visibleData?.visibleTo?.condition} `;
      }
    }

    if (roleNames) {
      result += rolesString;
      if (userNames) {
        result += ' and ';
      }
    }

    result += usersString;

    return result?.trim();
  };

  const statusRender = () => {
    if (visibleData?.visibility === 'public') {
      return (
        <Tooltip
          label='Everyone in the org, regardless of job or location, can see and search for this chapter.'
          hasArrow
          placement='bottom-start'
        >
          <Flex
            cursor='pointer'
            borderRadius='5px'
            color='#83BF6E'
            bg='#B5E4CA66'
            maxW='fit-content'
            align='center'
            gap='5px'
            p='2px 12px'
          >
            <FontAwesomeIcon icon={faEarthAsia as IconProp} color='#83BF6E' />
            <Text>Public</Text>
          </Flex>
        </Tooltip>
      );
    } else if (visibleData?.status === 'draft') {
      return (
        <Tooltip
          label='Visible only to you, until you publish'
          hasArrow
          placement='bottom-start'
        >
          <Flex
            cursor='pointer'
            borderRadius='5px'
            color='#DCA61C'
            bg='#FFCF5533'
            maxW='fit-content'
            align='center'
            gap='5px'
            p='2px 12px'
          >
            <FontAwesomeIcon icon={faLockKeyhole as IconProp} color='#DCA61C' />
            <Text>Private</Text>
          </Flex>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip label={customVisibility()} hasArrow placement='bottom-start'>
          <Flex
            cursor='pointer'
            borderRadius='5px'
            color='#8E59FF'
            bg='#CABDFF66'
            maxW='fit-content'
            align='center'
            gap='5px'
            p='2px 12px'
          >
            <FontAwesomeIcon icon={faUserGroup as IconProp} color='#8E59FF' />
            <Text>Custom</Text>
          </Flex>
        </Tooltip>
      );
    }
  };
  return statusRender();
};

export default VisibilityBadge;
