import React, { FC, useEffect, useRef } from 'react';
import { Flex } from '@chakra-ui/react';
import { toArray } from '../../../../utils';
import { useLauncherConfigContext } from '../common';
import PhaseName, { GoLive } from './PhaseName';

interface IProps {}

const EditorPhasesList: FC<IProps> = () => {
  const ref = useRef<HTMLDivElement>(null);
  const contents = useLauncherConfigContext((state) =>
    toArray(state.config.contents).map((ph) => ({
      eid: ph.eid,
      category: ph.category,
    }))
  );

  const sectionIndex = useLauncherConfigContext(
    (state) => state.taskForm?.sectionIndex
  );

  useEffect(() => {
    if (typeof sectionIndex === 'number') {
      // @ts-ignore
      ref.current?.childNodes?.item(sectionIndex)?.scrollIntoView?.({
        behavior: 'smooth',
      });
    }
  }, [sectionIndex]);

  return (
    <Flex pb={7} overflow='auto' ref={ref}>
      {contents.map((content, index) => (
        <PhaseName
          key={index}
          label={content.category}
          index={index + 1}
          isSelected={sectionIndex === index}
        />
      ))}
      <GoLive />
    </Flex>
  );
};

export default EditorPhasesList;
