import React, { FC } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TFuncKey } from 'i18next';

import { Dropdown, Input, SelectOption } from '../../../../../atoms';

import { TriggerInput } from './trigger.types';
import PhaseTaskSelect from './PhaseTaskSelect';
import { dropdownStyles } from './dropdown.styles';

interface IOption {
  label: TFuncKey<'audit', undefined>;
  value: string;
}

const options: IOption[] = [
  { label: 'days', value: 'days' },
  { label: 'weeks', value: 'weeks' },
  { label: 'months', value: 'months' },
];

const whenOptions: IOption[] = [
  { label: 'after', value: 'after' },
  { label: 'before', value: 'before' },
];

function isNumber(value: string) {
  return value.match(/[0-9]+/);
}

const toValue = (value?: string): SelectOption | undefined =>
  value ? { value: value, label: value } : undefined;

interface IProps {
  triggerType: 'activationTrigger' | 'deadlineTrigger';
  type: 'phase' | 'task';
}

const BaseTrigger: FC<IProps> = ({ triggerType, type }) => {
  const { t: ta } = useTranslation('audit');
  const { t } = useTranslation('launcher');

  const contentEid: string = useWatch({
    name: 'eid',
  });

  function resetFields(value: string) {
    if (value === 'before') {
      console.log('reset');
    }
  }

  return (
    <Flex flexDir='column' gap={2}>
      <Flex gap={2}>
        <Controller<TriggerInput, `trigger.${typeof triggerType}.value`>
          name={`trigger.${triggerType}.value`}
          rules={{
            required: 'Field is required',
          }}
          defaultValue={2}
          render={({ field, fieldState }) => {
            return (
              <FormControl width='unset' isInvalid={fieldState.invalid}>
                <Input
                  variant='auditOutline'
                  width='80px'
                  isInvalid={!!fieldState.error}
                  // isReadOnly={isReadOnly}
                  textAlign='center'
                  color='#2A85FF'
                  fontSize='14px'
                  fontWeight='600'
                  {...field}
                  onKeyPressCapture={(event) => {
                    if (!isNumber(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </FormControl>
            );
          }}
        />

        <Controller<TriggerInput, `trigger.${typeof triggerType}.unit`>
          name={`trigger.${triggerType}.unit`}
          rules={{
            required: 'Trigger unit type required',
          }}
          defaultValue='days'
          render={({ field, fieldState }) => {
            return (
              <FormControl
                flex={1}
                width='unset'
                isInvalid={fieldState.invalid}
              >
                <Dropdown
                  options={options}
                  isSearchable={false}
                  value={toValue(field.value)}
                  isDisabled={field.disabled}
                  onChange={(newValue) => {
                    return field.onChange(newValue.value);
                    // return callAll(field.onChange, resetFields)(newValue.value);
                  }}
                  getOptionLabel={(option) => ta(option.label)}
                  {...dropdownStyles}
                />
                <FormErrorMessage>
                  {fieldState?.error?.message}
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller<TriggerInput, `trigger.${typeof triggerType}.order`>
          name={`trigger.${triggerType}.order`}
          rules={{
            required: 'Trigger type required',
          }}
          defaultValue='after'
          render={({ field, fieldState }) => {
            return (
              <FormControl
                flex={1}
                width='unset'
                isInvalid={fieldState.invalid}
              >
                <Dropdown
                  options={whenOptions}
                  isSearchable={false}
                  value={toValue(field.value)}
                  isDisabled={field.disabled}
                  onChange={(newValue) => {
                    return callAll(field.onChange, resetFields)(newValue.value);
                  }}
                  getOptionLabel={(option) => ta(option.label)}
                  {...dropdownStyles}
                />
                <FormErrorMessage>
                  {fieldState?.error?.message}
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </Flex>

      <Controller<TriggerInput, `trigger.${typeof triggerType}.itemId`>
        name={`trigger.${triggerType}.itemId`}
        rules={{
          // required: 'Repeat cycle required',
          validate: (value, values) => {
            const depOn = values.trigger?.[triggerType]?.dependentOn;
            if (!depOn) {
              return 'Repeat cycle required';
            }

            if (['task', 'phase'].includes(depOn) && !value) {
              return 'Repeat cycle required';
            }

            switch (values.trigger?.[triggerType]?.order) {
              case 'before':
                if (depOn !== 'goLive') {
                  return 'Please select valid option';
                }
                break;
              case 'after':
                if (depOn === 'goLive') {
                  return 'Please select valid option';
                }
                break;
              default:
                return false;
            }
          },
        }}
        defaultValue=''
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={fieldState.invalid}>
              <Flex>
                <Controller<
                  TriggerInput,
                  `trigger.${typeof triggerType}.dependentOn`
                >
                  name={`trigger.${triggerType}.dependentOn`}
                  render={({ field: depField }) => {
                    return (
                      <PhaseTaskSelect
                        type={type}
                        taskPhaseId={contentEid}
                        triggerType={triggerType}
                        value={field.value}
                        onChange={field.onChange}
                        dependOnValue={depField.value}
                        onDependChange={depField.onChange}
                        // isDisabled={field.disabled}
                      />
                    );
                  }}
                />

                <Flex
                  align='center'
                  height='48px'
                  fontWeight='600'
                  color='#111315'
                  paddingX='14px'
                  whiteSpace='nowrap'
                >
                  {t('trigger.isCompleted')}
                </Flex>
              </Flex>

              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </Flex>
  );
};

BaseTrigger.displayName = 'sc/L/lc/c/t/BaseTrigger';

export default BaseTrigger;
