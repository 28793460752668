import { Box, Center, Flex, Text, Tooltip } from '@chakra-ui/react';
import React, { FC } from 'react';
import { PhaseTask } from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import { Image } from 'ui-components';
import { ReactComponent as FormiconIcon } from 'assets/images/formIcon.svg';
import { CloseIcon } from '@chakra-ui/icons';
import { useControl } from './layers';
import { LocationLaunchTaskByIdResponse } from 'sub-components/Launcher/tasks/components/task-details/types';

interface IProps {
  files: LocationLaunchTaskByIdResponse['LocationLaunchTaskById']['complianceFiles'];
  launchId: string;
  locationId: string;
  taskId: string;
  status: PhaseTask['status'];
  refetchData?: () => void;
}

const Attachments: FC<IProps> = ({
  files,
  launchId,
  locationId,
  status,
  taskId,
  refetchData,
}) => {
  const control = useControl(refetchData);
  if (files?.length === 0) {
    return <Box>-</Box>;
  }

  let locFiles = files?.filter((f) => f?.locationId === locationId) || [];

  return (
    <Box id='attachments-1'>
      <Flex id='attachments-2' flexWrap='wrap' gap='10px'>
        {locFiles?.map((file, index) => {
          const lastFile = file?.files?.[file?.files?.length - 1];
          return lastFile ? (
            <Flex
              key={index}
              id={`attachments-flex-${index}`}
              align='center'
              gap='5px'
            >
              {lastFile?.type?.includes('image') ? (
                <Image
                  src={lastFile?.url}
                  height={25}
                  width={25}
                  style={{ borderRadius: '50%' }}
                />
              ) : (
                <Center
                  boxSize='25px'
                  border='1px solid rgba(111, 118, 126, 1)'
                  borderRadius='50%'
                  p='3px'
                >
                  <FormiconIcon />
                </Center>
              )}
              <Tooltip hasArrow label={lastFile?.name}>
                <Text as='span' isTruncated maxW='140px' cursor='pointer'>
                  {lastFile?.name}
                </Text>
              </Tooltip>
              {status !== 'completed' &&
                status !== 'locked' &&
                status !== 'yetToStart' &&
                status !== 'yetToStart/locked' && (
                  <CloseIcon
                    cursor='pointer'
                    fontSize='8px'
                    boxSizing='content-box'
                    background='#EFEFEF'
                    borderRadius='100px'
                    padding='4px'
                    color='#6F767E'
                    onClick={() => {
                      control?.removeFile(
                        lastFile,
                        file?.files,
                        launchId,
                        taskId,
                        file?.eid
                      );
                    }}
                  />
                )}
            </Flex>
          ) : null;
        })}
      </Flex>
    </Box>
  );
};

Attachments.displayName =
  'displayName:sub-components/Launcher/location-owner/StatesDrawer/StateDrawer/Attachments/Attachments';

export default Attachments;
