import { useReactiveVar } from '@apollo/client';
import { Center, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyState from 'sub-components/EmptyState/EmptyState';
import { usersEntityObj } from 'sub-components/Header';
import { LTaskCategoryEntity } from 'sub-components/Launcher/launcher-config/common/launcher-config.graphql';
import GroupByAssignee from './GroupBy/GroupByAssignee';
import GroupByPhase from './GroupBy/GroupByPhase';
import GroupByStatus from './GroupBy/GroupByStatus';
import GroupByTaskType from './GroupBy/GroupByTaskType';
import { LocationPhaseDetails } from './LauncherLocation.graphql';
import { getPhaseById, getPhaseDependency, groupTask } from './helper';
export type TaskGroupTypes = 'Phase' | 'Task Type' | 'Assignee' | 'Status';
interface IProps {
  groupBy: TaskGroupTypes;
  phaseDetails: LocationPhaseDetails[];
  launchId?: string;
  liveDate: Date;
  locationId: string;
  refetchData?: () => void;
  categories: LTaskCategoryEntity[];
  selectedCategories: string[];
}

const TaskGroup: FC<IProps> = ({
  groupBy,
  phaseDetails,
  launchId,
  liveDate,
  locationId,
  refetchData,
  categories,
  selectedCategories,
}) => {
  const groupedTasks = groupTask(
    phaseDetails,
    groupBy,
    liveDate,
    selectedCategories
  );

  const entityObjData = useReactiveVar(usersEntityObj);

  const getAssigneeDetailsById = (userId: string) => {
    const user = entityObjData.find((user) => user.eid === userId);

    return {
      name: user?.name || 'Location owner',
      role: user?.role || 'Location owner',
    };
  };

  const [t] = useTranslation(['header']);

  return (
    <Flex flexDirection='column' gap={6}>
      {groupedTasks && Object.keys(groupedTasks).length ? (
        Object.keys(groupedTasks).map((key, index) => {
          const phase = getPhaseById(
            phaseDetails,
            groupedTasks[key].phaseId
          ) as LocationPhaseDetails;

          const phaseDependencyDetails = getPhaseDependency(
            groupedTasks[key].phaseTrigger,
            groupedTasks[key].phase,
            phaseDetails,
            liveDate
          );

          return (
            <>
              {groupBy === 'Phase' && (
                <GroupByPhase
                  key={index}
                  keyName={key}
                  phase={phase}
                  launchId={launchId}
                  getAssigneeDetailsById={getAssigneeDetailsById}
                  groupedTasks={groupedTasks}
                  locationId={locationId}
                  categories={categories}
                  phaseDependencyDetails={phaseDependencyDetails}
                  refetchData={refetchData}
                  phaseDetails={phaseDetails}
                />
              )}
              {groupBy === 'Status' && (
                <GroupByStatus
                  key={index}
                  keyName={key}
                  groupedTasks={groupedTasks}
                  launchId={launchId}
                  locationId={locationId}
                  categories={categories}
                  refetchData={refetchData}
                  phaseDetails={phaseDetails}
                />
              )}
              {groupBy === 'Task Type' && (
                <GroupByTaskType
                  key={index}
                  keyName={key}
                  groupedTasks={groupedTasks}
                  launchId={launchId}
                  locationId={locationId}
                  categories={categories}
                  refetchData={refetchData}
                  phaseDetails={phaseDetails}
                />
              )}
              {groupBy === 'Assignee' && (
                <GroupByAssignee
                  key={index}
                  keyName={key}
                  groupedTasks={groupedTasks}
                  launchId={launchId}
                  locationId={locationId}
                  categories={categories}
                  getAssigneeDetailsById={getAssigneeDetailsById}
                  refetchData={refetchData}
                  phaseDetails={phaseDetails}
                />
              )}
            </>
          );
        })
      ) : (
        <Center>
          <EmptyState
            image='Search'
            title={t('header:result_not_found')}
            description={t('header:not_found_desc')}
          />
        </Center>
      )}
    </Flex>
  );
};

TaskGroup.displayName =
  'displayName:sub-components/Launcher/dashboard/Location/TaskGroup';

export default TaskGroup;
