import React, { FC } from 'react';
import { Box, Flex, Skeleton } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../atoms';

// @ts-ignore
import { ReactComponent as BackIcon } from '../../../../assets/images/chevronBackward.svg';

interface IProps {
  auditTitle: string;
  loading: boolean;
  customNavigateText?: string;
}

const HeadingSection: FC<IProps> = ({
  auditTitle,
  customNavigateText = 'Back to Supervised by me',
  loading,
  children,
}) => {
  const history = useHistory();

  const handleBackClick = () => {
    //not working after notification navigations?
    // if (history.length > 1) {
    //   history.goBack();
    // } else {
    history.push('/audits');
    // }
  };

  return (
    <Flex flexDir='column' gap={2}>
      <Button
        fontSize='14px'
        fontWeight={500}
        width='max-content'
        variant='link'
        leftIcon={<BackIcon width='16' height='16' />}
        _hover={{
          textDecoration: 'none',
        }}
        onClick={handleBackClick}
      >
        {customNavigateText}
      </Button>

      <Flex w='full' justify='space-between' align='center' minH='48px'>
        {loading ? (
          <Skeleton width='28%' height='24px' borderRadius='5px' />
        ) : (
          <Box as='span' fontWeight={600} fontSize='28px' isTruncated>
            {auditTitle}
          </Box>
        )}
        {children}
      </Flex>
    </Flex>
  );
};

export default HeadingSection;
