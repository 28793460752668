import { Box, Text, Flex } from '@chakra-ui/react';
import { AuditResponse, Heading, PdfOverview } from './components';
import { generateValidId } from './helper';
import MediaSummary from './MediaSummary';
import AuditorSignature from './AuditorSignature';
import { useUserEntity } from 'hooks';
import {
  AssignmentType,
  AuditSessionAssignments,
  SectionDetailsResponseType,
  SessionDetailEntity,
} from './types/report.types';
import { FC } from 'react';
import RenderAllSections from './RenderAllSections';

interface IProps {
  auditData: SessionDetailEntity;
  assignment: AssignmentType;
  additionalData: any;
  mappedQuestionData: any;
  companyData?: any;
  entityUsers?: any;
  setCheckIfMembersLoaded?: any;
}

const ReportPdfView: FC<IProps> = ({
  auditData,
  assignment,
  additionalData,
  mappedQuestionData,
  companyData,
  entityUsers,
  setCheckIfMembersLoaded,
}) => {
  if (!companyData) {
    companyData = useUserEntity((state) => {
      return {
        name: state?.name,
        profilePic: state?.profilePic,
        address: state?.address,
      };
    });
  }

  let totalObtainedScore = 0;
  mappedQuestionData?.forEach((item: any) => {
    if (item?.obtainedScore) {
      totalObtainedScore += item?.obtainedScore;
    }
  });

  const pdfData = {
    profilePic: companyData?.profilePic,
    companyName: companyData?.name,
    companyAddress: companyData?.address,
    auditDate: assignment?.completedAt,
    address: {
      city: additionalData?.address?.city,
      state: additionalData?.address?.state,
      zipCode: additionalData?.address?.zipCode,
      country: additionalData?.address?.country,
    },
    reportTitle: auditData?.title,
    reportNumber: assignment?.assignmentId ? assignment?.assignmentId : '--',
    reportComment: '',
    flaggedItemCount: additionalData?.cardInfo?.flaggedItemCount?.count,
    auditorName: additionalData?.moreInfo?.conductedBy?.userDetails?.[0]?.name
      ? additionalData?.moreInfo?.conductedBy?.userDetails?.[0]?.name
      : '--',
    auditorProfilePic:
      additionalData?.moreInfo?.conductedBy?.userDetails?.[0]?.profilePic,
    overAllScore: additionalData?.cardInfo?.score,
    totalObtainedScore: totalObtainedScore,
  };

  return (
    <Box mt={'20px'} id='report-pdf-view'>
      <PdfOverview
        pdfData={pdfData}
        assignment={assignment}
        additionalData={additionalData}
      />

      <RenderAllSections
        type={'pdf'}
        auditData={auditData}
        mappedQuestionData={mappedQuestionData}
        additionalData={additionalData}
        assignment={assignment}
      />
    </Box>
  );
};

export default ReportPdfView;
