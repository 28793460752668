import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLatest } from '../../../../../../hooks';
import { LauncherTrigger } from '../../launcher-config.fragment';
import { useLauncherAlert } from '../../useLauncherAlert';
import {
  TaskTriggerInput,
  useTaskTriggerSetting,
} from '../task-trigger-setting';
import BaseSwitchTrigger, { BaseDetailView } from './BaseSwitchTrigger';

interface IProps {
  taskName: string;
}

const DeadlineData: FC<IProps> = ({ taskName }) => {
  const { t } = useTranslation('launcher');
  const changeAlert = useLauncherAlert();
  const addTrigger = useTaskTriggerSetting();
  const { getValues, setValue } = useFormContext<TaskTriggerInput>();
  const value = useWatch<LauncherTrigger, 'trigger.triggerChoice'>({
    name: 'trigger.triggerChoice',
  });
  const deadline = useWatch<LauncherTrigger, 'trigger.deadlineTrigger'>({
    name: 'trigger.deadlineTrigger',
  });

  const changeRef = useLatest(() => {
    addTrigger({
      taskName: taskName,
      defaultValue: {
        eid: getValues('eid'),
        trigger: {
          triggerChoice: 'deadline',
        },
      },
      onSaveChanges: (values) => {
        setValue('trigger', values.trigger, {
          shouldDirty: true,
        });
      },
    });
  });

  if (value === 'deadline') {
    return (
      <BaseDetailView
        type={value}
        trigger={deadline!}
        description='Activation: Project start date'
        onEditClick={() => {
          addTrigger({
            taskName: taskName,
            defaultValue: {
              eid: getValues('eid'),
              trigger: getValues('trigger'),
            },
            onSaveChanges: (values) => {
              setValue('trigger', values.trigger, {
                shouldDirty: true,
              });
            },
          });
        }}
      />
    );
  }

  return (
    <BaseSwitchTrigger
      title='Erase activation triggers and add a dynamic deadline trigger'
      desc='Note: All your activation settings will be reset to default if you choose to add a deadline trigger'
      buttonText='Switch to deadline trigger'
      onClick={() => {
        changeAlert({
          description: t('trigger.activationAlert'),
          buttonText: t('trigger.activationAlertBtn'),
          onConfirm: changeRef.current,
        });
      }}
    />
  );
};

export default DeadlineData;
