import { Button } from 'atoms';
import { Box, Image } from '@chakra-ui/react';
import Dark_editIcon from 'assets/images/dark_edit.png';
import React, { FC } from 'react';
import { ViewMode } from '../types';
import { HeaderColors } from 'configs';
import { useProjectDetailPhaseContext } from '../store/context';
import { cloneDeep } from '@apollo/client/utilities';
import { useStillConfused } from './StillConfused';

interface IProps {}

const HeaderActionBtn: FC<IProps> = () => {
  const { initData, mode, updateData, updateMode } =
    useProjectDetailPhaseContext((state) => ({
      initData: state?.launcherData,
      mode: state?.view,
      updateData: state?.updateLauncherData,
      updateMode: state?.updateView,
    }));

  const onClickHandler = () => {
    if (mode === 'view') {
      let _initData = cloneDeep(initData);
      _initData?.contents?.forEach((init) => {
        if (!init.selectedChoice) {
          init.selectedChoice = 'phaseRequired';
          init.showChoice = false;
        }
      });
      updateData(_initData);
    }
    updateMode('edit');
  };

  const stillConfused = useStillConfused();

  if (mode === 'edit') {
    return (
      <Box
        as='span'
        color={HeaderColors.DarkBlue}
        fontSize='12px'
        textDecor='underline'
        cursor='pointer'
        onClick={() => stillConfused({})}
      >
        Still confused?
      </Box>
    );
  }

  return (
    <Button
      size='sm'
      variant='outline'
      border='none'
      outline={'2px solid #B1E5FC'}
      rightIcon={<Image src={Dark_editIcon} boxSize='20px' />}
      onClick={onClickHandler}
    >
      Edit assigned phases
    </Button>
  );
};

HeaderActionBtn.displayName = 'p/LN/C/PDP/c/HeaderActionBtn';

export default HeaderActionBtn;
