import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { BoxHeader, useConfirm } from 'ui-components';
import ModalContent from './ModalContent';

interface IProps {
  onOkPress?: (...args: any[]) => any | PromiseLike<any>;
  onCancelPress?: (...args: any[]) => any | PromiseLike<any>;
}

type INotificationInfo = (props: IProps) => void;

export const useNotificationInfo = (): INotificationInfo => {
  const { t } = useTranslation(['common', 'invite']);
  const confirm = useConfirm();
  const confirmRef = React.useRef<ReturnType<typeof confirm>>();

  return useCallback(
    ({ onOkPress, onCancelPress }) => {
      confirmRef.current = confirm({
        title: (
          <Flex gap='12px' justify='space-between'>
            <BoxHeader
              // @ts-ignore
              title={'Please Note'}
              color={'#FFBC99'}
            />
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
              onClick={() => confirmRef?.current?.destroy()}
            />
          </Flex>
        ),
        content: (
          <ModalContent
            onOkPress={() => {
              onOkPress?.();
              confirmRef?.current?.destroy();
            }}
            onCancelPress={() => {
              onCancelPress?.();
              confirmRef?.current?.destroy();
            }}
          />
        ),
        isCentered: true,
        contentProps: {
          py: '16px',
          px: '10px',
          minW: '760px',
        },
        okButtonProps: {
          colorScheme: 'red',
          size: 'lg',
          fontSize: '15px',
          borderRadius: '7px',
          minW: '160px',
        },
        footer: null,
        okText: null,
        cancelText: null,
        onOK: null,
        onCancel: null,
      });
    },
    [confirm]
  );
};
