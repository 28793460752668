import React, { FC, useEffect, useState } from 'react';
import { CopyIcon, SettingsIcon } from '@chakra-ui/icons';
import {
  Text,
  Drawer,
  Flex,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  Box,
  DrawerFooter,
  Button,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleInfo, faCogs } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from 'atoms';
import { t } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import { BoxHeader } from 'ui-components';
import ShareMemberList from 'ui-components/ShareToMember/ShareMemberList';
import { faCog, faUserCog } from '@fortawesome/pro-light-svg-icons';
import { FilterByModule, SummaryWrapper } from './components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AppRoute } from 'routes';
import { FilterItem } from './types';
import {
  IFilters,
  INotificationItem,
  INotificationResponse,
} from '../types/service-types';
import {
  GET_NOTIFICATION_HISTORY,
  MarkAllReadInput,
  MarkGroupedNotificationReadInput,
  MARK_ALL_READ,
  MARK_GROUPED_NOTIFICATIONS_READ,
  MARK_READ_UNREAD_SINGLE_NOTIFICATION,
} from '../notiifications.graphql';
import { ApolloQueryResult, useLazyQuery, useMutation } from '@apollo/client';
import { NotificationFiltersParams } from '../types/types';
import { UnreadNotificationsCount } from 'shared/graphql/shared-types';
import { GET_UNREAD_NOTIFICATIONS } from 'sub-components/Header';
import { moduleData } from '../constants';
import useCombinedStore from 'zustandStore/store';

interface IProps {
  isSummaryDrawerOpen: boolean;
  closeSummaryDrawer: () => void;
}

const NotificationsSummaryDrawer: FC<IProps> = ({
  isSummaryDrawerOpen,
  closeSummaryDrawer,
}) => {
  const [notificationData, setNotificationData] = useState<
    INotificationResponse[]
  >([]);
  const [checkedModules, setCheckedModules] = useState(moduleData);
  const [selectedModules, setSelectedModules] = React.useState<FilterItem[]>(
    []
  );
  const { updateUnseenCount } = useCombinedStore();
  const [tabName, setTabName] = useState('all');
  const decideFilterAndRefetch = () => {
    if (tabName === 'all') {
      notificationHistoryData({
        variables: notificationFilters({}),
      });
    } else if (tabName === 'unread') {
      notificationHistoryData({
        variables: notificationFilters({ isUnreadFilter: true }),
      });
    } else if (tabName === 'read') {
      notificationHistoryData({
        variables: notificationFilters({ isReadFilter: true }),
      });
    } else if (tabName === 'assignedToMe') {
      notificationHistoryData({
        variables: notificationFilters({ assignedToMeFilter: true }),
      });
    }
  };

  const { t } = useTranslation(['notifications']);
  const history = useHistory();
  const locations = useLocation();
  const [
    notificationHistoryData,
    { data, loading: notificationLoading, error, refetch: notificationRefetch },
  ] = useLazyQuery<INotificationResponse, IFilters>(GET_NOTIFICATION_HISTORY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setNotificationData(data?.Notifications?.items);
    },
    onError: (err) => {
      console.log('Error while fetching notification history:', err);
    },
  });

  const [getUnseenNotificationCount] = useLazyQuery(GET_UNREAD_NOTIFICATIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      updateUnseenCount(data?.UnseenNotificationCount);
    },
    onError: (err) => {
      console.log('Error while fetching notification read count --- ', err);
    },
  });

  const [markAllNotificationRead] = useMutation<never, MarkAllReadInput>(
    MARK_ALL_READ,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        getUnseenNotificationCount();
        decideFilterAndRefetch();
      },
      onError: (err) => {
        console.log('err --- ', err);
      },
    }
  );
  const [updateNotificationReadState] = useMutation(
    MARK_READ_UNREAD_SINGLE_NOTIFICATION,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        getUnseenNotificationCount();
        decideFilterAndRefetch();
      },
      onError: (err) => {
        console.log('Error while updating notification read status: ', err);
      },
    }
  );

  const [markGroupedNotificationsRead] = useMutation<
    never,
    MarkGroupedNotificationReadInput
  >(MARK_GROUPED_NOTIFICATIONS_READ, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      getUnseenNotificationCount();
      decideFilterAndRefetch();
    },
    onError: (err) => {
      console.log('error while mark read notification --- ', err);
    },
  });

  const notificationFilters = ({
    pageIndex = 1,
    sort = '_ID_DESC',
    assignedToMeFilter,
    isReadFilter,
    isUnreadFilter,
  }: NotificationFiltersParams) => {
    let filter: {
      isSeen?: boolean;
      searchQuery?: string;
      subCategory?: string[];
      userId?: string;
      category?: string[];
    } = {
      searchQuery: '',
    };
    if (selectedModules?.length > 0) {
      filter.category = selectedModules?.map((item) => item?.value);
    }

    if (assignedToMeFilter) {
      filter.subCategory = [
        'taskAssigned',
        'trainingAssignment',
        'auditCreated',
      ];
    }
    if (isReadFilter) {
      filter.isSeen = true;
    }

    if (isUnreadFilter) {
      filter.isSeen = false;
    }

    return {
      page: pageIndex,
      perPage: 10,
      filter: filter,
      sort: sort,
    };
  };

  const handleModuleSelect = (e: any, item: FilterItem) => {
    if (e?.target?.checked) {
      const newSelectedModules = [
        ...selectedModules,
        { ...item, checked: true },
      ];
      setSelectedModules(newSelectedModules);
    }

    if (!e?.target?.checked) {
      const newSelectedModules = selectedModules?.filter(
        (i) => i?.value !== item?.value
      );
      setSelectedModules(newSelectedModules);
    }

    setCheckedModules((prevState) => {
      const newObj = prevState?.map((i) => {
        if (item?.value === i?.value) {
          return { ...i, checked: e?.target?.checked };
        } else {
          return { ...i };
        }
      });

      return newObj;
    });
  };
  const handleUpdateNotificationReadStatus = (eid: string, status: boolean) => {
    updateNotificationReadState({
      variables: {
        eid: eid,
        isSeen: status,
      },
    });
  };

  const handleRemoveFilter = (item: FilterItem) => {
    setSelectedModules((prev) => {
      return prev.filter((prevState) => prevState.value !== item?.value);
    });

    setCheckedModules((prevState) => {
      const newObj = prevState?.map((i) => {
        if (item?.value === i?.value) {
          return { ...i, checked: false };
        } else {
          return { ...i };
        }
      });

      return newObj;
    });
  };

  const resetAllFilters = () => {
    setSelectedModules([]);
    setCheckedModules((prevState) => {
      const newObj = prevState?.map((i) => {
        return { ...i, checked: false };
      });
      return newObj;
    });
  };

  const onCloseSummary = () => {
    closeSummaryDrawer();
  };

  useEffect(() => {
    notificationHistoryData({ variables: notificationFilters({}) });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      decideFilterAndRefetch();
    }, 500);
    return () => clearTimeout(timer);
  }, [selectedModules]);

  return (
    <Drawer isOpen={isSummaryDrawerOpen} onClose={onCloseSummary} size='lg'>
      <DrawerContent
        maxW='50vw'
        boxShadow={'-2px 0px 6.4px rgba(0, 0, 0, 0.16)'}
      >
        <DrawerHeader>
          <Flex align='center' justify='space-between'>
            <BoxHeader color='#FFBC99' title={t('notifications:heading')} />
            <Flex
              justify='flex-end'
              gap='8px'
              display={
                locations.pathname === AppRoute.NOTIFICATIONS_SETTINGS
                  ? 'none'
                  : 'flex'
              }
            >
              <FontAwesomeIcon
                icon={faCog as IconProp}
                color={'#6F767E'}
                onClick={() => {
                  history.push(AppRoute.NOTIFICATIONS_SETTINGS);
                }}
                cursor={'pointer'}
              />
            </Flex>
          </Flex>
        </DrawerHeader>
        <DrawerBody
          display='flex'
          flexDir='column'
          h='calc(100vh - 80px)'
          overflow='hidden'
        >
          <Box overflowY='scroll' flex='1'>
            <SummaryWrapper
              resetHandler={resetAllFilters}
              checkedModules={checkedModules}
              handleRemoveFilter={handleRemoveFilter}
              handleModuleSelect={handleModuleSelect}
              selectedModules={selectedModules}
              setSelectedModules={setSelectedModules}
              notificationFilters={notificationFilters}
              notificationData={notificationData}
              notificationLoading={notificationLoading}
              setTabName={setTabName}
              isCompactView={true}
              notificationHistoryData={notificationHistoryData}
              notificationData={notificationData}
              handleUpdateNotificationReadStatus={
                handleUpdateNotificationReadStatus
              }
              tabName={tabName}
              markAllNotificationRead={markAllNotificationRead}
              markGroupedNotificationsRead={markGroupedNotificationsRead}
            />
          </Box>
        </DrawerBody>
        <DrawerFooter
          boxShadow={'-2px 0px 6.4px rgba(0, 0, 0, 0.16)'}
          h={'80px'}
          p={'12px'}
          gap={'8px'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Button
            variant={'solid'}
            border={'2px solid #EFEFEF'}
            borderRadius={'8px'}
            p={'8px 16px'}
            bg={'#FCFCFC'}
            color={'#1A1D1F'}
            onClick={() => {
              history.push(AppRoute.NOTIFICATIONS);
              closeSummaryDrawer();
            }}
          >
            View All
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
NotificationsSummaryDrawer.displayName =
  'sc/NC/S/NotificationsSummaryDrawer.tsx';
export default React.memo(NotificationsSummaryDrawer);
