import { Flex } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import React, { FC, useMemo } from 'react';
import { TaskType } from 'sub-components/Launcher/dashboard/dashboard-grapghql';
import {
  LocationPhaseDetails,
  PhaseTask,
} from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import { ILocationLaunchTask } from 'sub-components/Launcher/location-owner/types';
import { BoxHeader } from 'ui-components';
import { GroupedTasksData } from '../types';
import TaskContent from './TaskContent';

interface IProps {
  categories: TaskType[];
  data: GroupedTasksData[];
  refetch?: (
    resData?: ILocationLaunchTask,
    phaseDetails?: LocationPhaseDetails[],
    task?: PhaseTask
  ) => void;
}

const ByLocation: FC<IProps> = ({ categories, data, refetch }) => {
  const sortedData = useMemo(() => {
    // Define status order
    const statusOrder = [
      'overdue',
      'duesoon',
      'inProgress',
      'locked',
      'completed',
    ];

    return data?.map((locationData) => {
      // For each location, collect all tasks across phases
      const allLocationTasks = locationData?.groupedPhases?.flatMap(
        (phase) => phase?.tasks
      );

      // Sort tasks by status
      const sortedTasks = [...allLocationTasks]?.sort((a, b) => {
        const statusA = statusOrder?.indexOf(a?.status);
        const statusB = statusOrder?.indexOf(b?.status);
        return statusA - statusB;
      });

      return {
        locationName: locationData?.locationName,
        locationId: locationData?.locationId,
        tasks: sortedTasks,
      };
    });
  }, [data]);

  return (
    <Flex flexDir='column' gap={4} mt={4}>
      {sortedData?.map((locationData, dIndex) => {
        return (
          <Flex key={dIndex} flexDir='column' gap={4}>
            <BoxHeader
              color={HeaderColors.Purple}
              title={`${locationData?.locationName || 'No Location'}`}
            />
            <Flex flexDir='column' gap={4}>
              {locationData?.tasks?.map((task, tIndex) => (
                <TaskContent
                  key={tIndex}
                  categories={categories}
                  locationName={locationData.locationName}
                  task={task}
                  refetch={refetch}
                />
              ))}
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};

ByLocation.displayName =
  'displayName:sub-components/Launcher/tasks/components/ByLocation';

export default ByLocation;
