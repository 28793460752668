import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { Box, Center, Flex } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { HeaderColors } from 'shared/headerColors';
import { Loader } from 'sub-components';
import { AuditSessionEntity } from 'sub-components/audits/audit-tab/supervised/types';
import { auditStatus } from 'sub-components/audits/audit-tab/supervised/utils';
import { getAuditStatus } from 'sub-components/audits/common/getAuditStatus';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { usersEntityObj } from 'sub-components/Header';
import { formatNumber } from 'utils';
import { useAuditDetailContext } from '../../store/context';
import { AnalyticsGraph, NoData } from './components';
import { PERFORMANCE_ANALYTICS } from './performance-analytics.graphql';
import {
  CalculatedData,
  PercentChange,
  PerformanceAnalyticsResponse,
  PerformanceAnalyticsVariable,
} from './types/performance-analytics.types';

const PerformanceAnalytics: React.FC<{
  auditData: AuditSessionEntity | undefined;
}> = ({ auditData }) => {
  const entityData = useReactiveVar(usersEntityObj);
  const { auditId } = useParams<{ auditId: string; sessionId: string }>();
  const selectedTab = useAuditDetailContext((state) => state.selectedTab);

  const [getPerformanceData, { data, loading }] = useLazyQuery<
    PerformanceAnalyticsResponse,
    PerformanceAnalyticsVariable
  >(PERFORMANCE_ANALYTICS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (auditId && selectedTab === 'overview') {
      getPerformanceData({
        variables: {
          eid: auditId,
        },
      });
    }
  }, [selectedTab]);

  const _auditStatus = getAuditStatus(auditData);

  const calculatePerformanceData = useCallback(():
    | CalculatedData
    | undefined => {
    if (!data?.AuditAnalyticsOverview) return;
    let response = data?.AuditAnalyticsOverview;
    const months = Object.keys(response?.overall)?.sort(
      (a, b) => Number(a) - Number(b)
    );

    const currentMonth = months?.[months?.length - 1];
    const previousMonth = months?.[months?.length - 2];

    const locationData = Object.keys(response?.locationWise)
      ?.map((locationId) => {
        const scores = response?.locationWise?.[locationId];

        const currentMonthScore = scores?.find(
          (score) =>
            new Date(score?.start)?.getMonth() + 1 === Number(currentMonth)
        );
        const previousMonthScore = scores?.find(
          (score) =>
            new Date(score?.start)?.getMonth() + 1 === Number(previousMonth)
        );

        const currentScoreSum = currentMonthScore?.totalScoreSum || 0;
        const previousScoreSum = previousMonthScore?.totalScoreSum || 0;
        const scoreChange = currentScoreSum - previousScoreSum;

        const locationName =
          entityData.find((loc) => loc?.eid === locationId)?.name ||
          'Unknown Location';

        return {
          id: locationId,
          name: locationName,
          currentScore: currentScoreSum,
          scoreChange: scoreChange,
          locationTookAudit: !!currentMonthScore?.maxScoreSum,
        };
      })
      ?.filter((location) => location?.locationTookAudit);

    const sortedLocations = [...locationData]?.sort(
      (a, b) => b?.currentScore - a?.currentScore
    );
    const bestLocation = sortedLocations?.[0];
    const worstLocation =
      sortedLocations?.length > 1
        ? sortedLocations?.[sortedLocations?.length - 1]
        : null;

    // const bestLocation = locationData?.reduce((prev, current) =>
    //   current?.currentScore > prev?.currentScore ? current : prev
    // );
    // const worstLocation = locationData.reduce((prev, current) =>
    //   current?.currentScore < prev?.currentScore ? current : prev
    // );

    const bestLocationPerformanceImprovement =
      bestLocation?.scoreChange > 0 ? bestLocation?.scoreChange : 0;

    const worstLocationPerformanceDecline = worstLocation
      ? worstLocation?.scoreChange < 0
        ? worstLocation?.scoreChange
        : 0
      : 0;

    const previousAvg = response?.overall?.[previousMonth]?.scoreAvg;
    const currentAvg = response?.overall?.[currentMonth]?.scoreAvg;
    const hasOverallAvg = previousAvg > 0 || currentAvg > 0;
    let percentageChange: string;
    let changeType: 'increase' | 'decrease' | 'no-change';
    if (previousAvg === 0) {
      if (currentAvg === 0) {
        percentageChange = '0';
        changeType = 'no-change';
      } else {
        percentageChange = '100';
        changeType = 'increase';
      }
    } else {
      const change = ((currentAvg - previousAvg) / previousAvg) * 100;
      percentageChange = change?.toFixed(2);
      if (change > 0) {
        changeType = 'increase';
      } else if (change < 0) {
        changeType = 'decrease';
      } else {
        changeType = 'no-change';
      }
    }

    let hasMaxScore = false;

    for (const month of months) {
      let totalMaxScoreForMonth = 0;
      for (const locationId of Object.keys(response?.locationWise)) {
        const locationScores = response?.locationWise?.[locationId];
        const monthScore = locationScores?.find(
          (score) => new Date(score?.start).getMonth() + 1 === Number(month)
        );
        if (monthScore && monthScore?.maxScoreSum) {
          totalMaxScoreForMonth += monthScore?.maxScoreSum;
        }
      }
      if (totalMaxScoreForMonth > 0) {
        hasMaxScore = true;
        break;
      }
    }

    return {
      overallAverageScore: (
        response?.overall?.[currentMonth]?.scoreAvg * 100
      ).toFixed(2),
      percentageChange: {
        changeType,
        value: percentageChange,
      },
      bestLocation: {
        name: bestLocation?.name,
        currentScore: bestLocation?.currentScore,
        performanceImprovement: bestLocationPerformanceImprovement,
        tookAudit: bestLocation?.locationTookAudit,
      },
      worstLocation: worstLocation
        ? {
            name: worstLocation?.name,
            currentScore: worstLocation?.currentScore,
            performanceDecline: Math.abs(worstLocationPerformanceDecline),
            tookAudit: worstLocation?.locationTookAudit,
          }
        : undefined,
      hasMaxScore: hasMaxScore,
      hasOverallAvg: hasOverallAvg,
    };
  }, [data?.AuditAnalyticsOverview, entityData]);

  return (
    <Flex
      flexDir='column'
      border='2px solid #EFEFEF'
      borderRadius='10px'
      p='16px 20px'
      gap={3}
      w='full'
    >
      {loading ? (
        <Center h='316px'>
          <Loader />
        </Center>
      ) : (
        <AnalyticsGraph
          _auditStatus={_auditStatus}
          calculatedData={calculatePerformanceData()}
        />
      )}
    </Flex>
  );
};

PerformanceAnalytics.displayName =
  'pages/Audits/Detail/components/PerformanceAnalytics/PerformanceAnalytics';

export default PerformanceAnalytics;
