import React, { FC } from 'react';
import {
  Modal,
  Button,
  Center,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Text,
  Flex,
  Checkbox,
} from '@chakra-ui/react';
import { ReactComponent as KnowMoreIcon } from 'assets/images/notifications/knowMore.svg';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const KnowMore: FC<IProps> = ({ isOpen, onClose }) => {
  return (
    <Center>
      <Modal
        closeOnOverlayClick={true}
        isOpen={isOpen}
        onClose={onClose}
        size={'3xl'}
        isCentered
      >
        <ModalOverlay />
        <ModalContent borderRadius={'12px'} p={'16px'}>
          <ModalHeader
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Flex
              w={'fit-content'}
              gap={'16px'}
              align={'center'}
              justify={'center'}
            >
              <KnowMoreIcon />
              <Text>Notification settings</Text>
            </Flex>
            <ModalCloseButton
              style={{
                marginTop: 24,
                marginRight: 20,
                outline: 'none',
                boxShadow: 'none',
                borderRadius: '50%',
                backgroundColor: '#efefef',
                color: '#6f767e',
              }}
            />
          </ModalHeader>
          <ModalBody p={'16px'}>
            <Stack
              borderRadius={'12px'}
              padding={'20px 24px'}
              gap={'16px'}
              bg={'#F6F2FF'}
            >
              <Text color={'#6F767E'} fontWeight={500} fontSize={'14px'}>
                What do colors indicate?
              </Text>
              <Stack minW={'900px'}>
                <Flex gap={2}>
                  <Checkbox
                    isChecked
                    borderRadius={'12px'}
                    colorScheme={'green'}
                    size={'lg'}
                  />
                  <Text fontWeight={500} fontSize={'14px'}>
                    Green checkboxes indicate <strong>all</strong> the
                    subcategories of this setting type are activated
                  </Text>
                </Flex>
                <Flex gap={2}>
                  <Checkbox borderRadius={'12px'} size={'lg'} isChecked>
                    <Text fontWeight={500} fontSize={'14px'}>
                      Blue checkboxes indicate only <strong>a few</strong>{' '}
                      subcategories of this setting type are activated
                    </Text>
                  </Checkbox>
                </Flex>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Center>
  );
};

export default KnowMore;
