import React, { FC } from 'react';
import {
  Box,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  useModalContext,
} from '@chakra-ui/react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { cloneDeep } from '@apollo/client/utilities';

import { Button, Input } from '../../../../../atoms';
import { useLatest } from '../../../../../hooks';
import TitleHeader from '../../../../CardEditor/TitleHeader';

import { LauncherAssignee, LauncherTrigger } from '../launcher-config.fragment';
import TriggerSetting from './TriggerSetting';
import PhaseAssignee from './PhaseAssignee';
import SaveChangeButton from './SaveChangeButton';
import { LauncherTaskInput } from '../launcher-config.store';
import { isDependencyError, useTaskBeforeSave } from '../useTaskBeforeSave';
import { useLauncherAlert } from '../useLauncherAlert';

export interface PhaseDetails
  extends Partial<LauncherAssignee>,
    LauncherTrigger {
  eid: string;
  category: string;
  tasks?: LauncherTaskInput[];
  isOpen?: boolean;
}

interface IProps {
  defaultValue: PhaseDetails;
  onSaveChanges: (data: PhaseDetails) => void;
  tasks?: LauncherTaskInput[];
  triggerAssignment?: 'phase' | 'task';
}

const PhaseSettings: FC<IProps> = ({
  defaultValue,
  tasks,
  triggerAssignment,
  onSaveChanges,
}) => {
  const { onClose } = useModalContext();
  const beforeSave = useTaskBeforeSave();
  const launcherAlert = useLauncherAlert();

  const methods = useForm({
    defaultValues: defaultValue,
  });

  const triggerRef = useLatest(() => {
    const trigger = methods.getValues('trigger');

    switch (trigger?.triggerChoice) {
      case 'activation':
        methods.setFocus('trigger.activationTrigger.itemId');
        break;
      case 'deadline':
        methods.setFocus('trigger.deadlineTrigger.itemId');
        break;
    }
  });

  const handleSubmit = async (values: PhaseDetails) => {
    const input = cloneDeep(values);

    try {
      await beforeSave?.(input as never);
    } catch (err) {
      if (isDependencyError(err as Error)) {
        launcherAlert({
          title: 'Please note',
          description:
            'This configuration creates a circular dependency, which could lead to process deadlocks. Please revise your selection to proceed.',
          buttonText: 'Edit trigger settings',
          cancelText: null,
          onConfirm: triggerRef.current,
        });
      }
      return Promise.reject(err);
    }

    onSaveChanges(values);
  };

  const onResetClick = () => {
    const trigger = methods.getValues('trigger');

    if (!trigger?.triggerChoice || trigger?.triggerChoice === 'default') {
      return methods.setValue('isOpen', false);
    }

    launcherAlert({
      title: 'Please note',
      description: (
        <div>
          All your trigger settings for this phase will be{' '}
          <strong>reset to default activation date or deadline.</strong>
        </div>
      ),
      buttonText: 'Erase phase triggers',
      onConfirm: () => {
        methods.setValue(
          'trigger',
          {
            triggerChoice: 'default',
            activationTrigger: null,
            deadlineTrigger: null,
          },
          {
            shouldDirty: true,
          }
        );
        methods.setValue('isOpen', false);
      },
    });
  };

  const [value, isOpen] = useWatch({
    control: methods.control,
    name: ['trigger.triggerChoice', 'isOpen'],
  });

  const isDefault = !value || value === 'default';

  return (
    <Flex
      width='50%'
      gap='20px'
      flexDir='column'
      borderRightRadius='12px'
      padding='24px 32px'
    >
      <CloseButton
        alignSelf='flex-end'
        size='sm'
        colorScheme='blue'
        borderRadius='full'
        onClick={(event) => {
          event.stopPropagation();
          onClose();
        }}
      />

      <FormProvider {...methods}>
        <Flex flexDir='column' gap={2}>
          <TitleHeader title='Phase title' isRequired />

          <Controller
            name='category'
            // rules={{ required: 'Field is required' }}
            defaultValue=''
            render={({ field, fieldState }) => {
              return (
                <FormControl isInvalid={fieldState.invalid}>
                  <Input size='lg' variant='auditOutline' {...field} />
                  <FormErrorMessage>
                    {fieldState?.error?.message}
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />
        </Flex>

        <Flex flexDir='column' gap={3}>
          <Box>
            <TitleHeader
              title='Phase assignee'
              desc='Note: The phase assignee will override task assignees.'
            />
          </Box>

          <PhaseAssignee type='phase' />
        </Flex>

        <Flex justify='space-between' align='center'>
          <Box>
            <TitleHeader
              title='Activation and Deadline details'
              desc='Specify when the assignee can access this phase and its completion deadline'
            />
          </Box>
          <Button
            size='sm'
            variant='outline'
            fontSize='12px'
            onClick={onResetClick}
            isDisabled={isDefault && !isOpen}
          >
            Reset to default
          </Button>
        </Flex>

        <TriggerSetting triggerAssignment={triggerAssignment} tasks={tasks} />

        <SaveChangeButton
          onDiscardClick={() => methods.reset()}
          onClick={methods.handleSubmit(handleSubmit)}
        />
      </FormProvider>
    </Flex>
  );
};

PhaseSettings.displayName = 'sc/L/lc/c/pd/PhaseSettings';

export default PhaseSettings;
