import React, { FC, ReactNode } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { ReactComponent as TaskIcon } from '../../../../../assets/images/sidebar/task.svg';

export interface TInfoItem {
  title: string;
  caption: ReactNode;
}

interface IProps extends TInfoItem {}

const TriggerInfoItem: FC<IProps> = ({ title, caption }) => {
  return (
    <Flex gap='12px' align='center'>
      <TaskIcon
        width='22'
        height='22'
        color='#83BF6E'
        style={{ minWidth: '22px' }}
      />
      <Flex flexDir='column' fontSize='12px' fontWeight='600'>
        <Box color='#1A1D1F'>{title}</Box>
        <Box color='#6F767E'>{caption}</Box>
      </Flex>
    </Flex>
  );
};

TriggerInfoItem.displayName = 'sc/L/lc/c/t/TriggerInfoItem';

export default TriggerInfoItem;
