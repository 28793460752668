import {
  Box,
  Center,
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { getImage } from 'utils';
import { Heading, DefaultEmptyState, LauncherTable } from './';
import { ReactComponent as DocumentIcon } from 'assets/images/nexus/compliance/document.svg';
import { getDocNameWithExtension } from '../helper';
import moment from 'moment';
import { faArrowDownShortWide } from '@fortawesome/pro-solid-svg-icons';
import { Icon, IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteDocumentModal from 'sub-components/nexus/Compliance/Listing/components/DeleteDocumentModal';
import ComplianceUpdate from 'sub-components/nexus/Compliance/Listing/components/ComplianceUpdate';
import { VersionHistoryModal } from 'sub-components/nexus/Compliance/Listing/components';
import { useLatest, useUserDataSelector } from 'hooks';
import { AuthRole } from '../../../../authorization';
import SearchInput from '../../../../atoms/SearchInput';
import { useLazyQuery } from '@apollo/client';
import { GET_COMPLIANCE_LISTING } from 'sub-components/nexus/Compliance/query/compliance.graphql';
import { generateComplianceTableData } from '../helper';
import { TableDataType } from 'sub-components/nexus/Compliance/types/compliance.types';
import { faBell } from '@fortawesome/pro-light-svg-icons';
import { useComplianceUpload } from 'sub-components/nexus/Compliance';
import { eventBus } from 'shared/eventEmit';
import Loader from 'sub-components/Loader';
import { useParams } from 'react-router-dom';

interface IProps {
  locId: string;
}

const ListContainer: FC<IProps> = ({ locId }) => {
  const [selectedExpiryFilter, setSelectedExpiryFilter] = useState<string>('');
  const [searchFieldText, setSearchFieldText] = useState<string>('');
  const [tableData, setTableData] = useState<TableDataType[]>([]);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(0);

  const complianceUpload = useComplianceUpload();
  const params = useParams();

  const { userAuthRole, loggedInUserLocations, loggedInUserId } =
    useUserDataSelector((state) => ({
      userAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations,
      loggedInUserId: state?.eid,
    }));
  let loggedInUserLocationIds = loggedInUserLocations?.map((loc) => loc?.eid);

  const [getComplianceListing, { loading: complianceLoader }] = useLazyQuery(
    GET_COMPLIANCE_LISTING,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setTotalRowsCount(data?.EntityCompliancePagination?.count);
        const complianceTableData = generateComplianceTableData(
          data?.EntityCompliancePagination?.items
        );
        setTableData(complianceTableData);
      },
    }
  );

  const getComplianceFilters = (
    pageIndex: number = 1,
    categoryId: string = '',
    sort: string = '_ID_DESC',
    locationId = locId
  ) => {
    let filter: {
      query?: string;
      locationId?: string;
      categoryId?: string;
      expiryDate?: string;
      createdBy?: string[];
      locationIds?: string[];
    } = {
      query: '',
    };

    filter.locationIds = [locId];

    if (searchFieldText) {
      filter.query = searchFieldText;
    }

    return {
      filter: filter,
      sort: sort,
      page: pageIndex,
      perPage: 10,
    };
  };

  const handleExpirySort = (filterType: string) => {
    getComplianceListing({
      variables: getComplianceFilters(1, '', filterType),
    });
  };

  const columns = [
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Task Name
        </Text>
      ),
      dataIndex: 'taskDetails',
      key: 'taskDetails',
      render: (_, value) => {
        return (
          <Flex justifyContent={'center'} flexDir={'column'}>
            <Tooltip label={value?.taskDetails?.taskName}>
              <Text isTruncated maxWidth={'250px'}>
                {value?.taskDetails?.taskName}
              </Text>
            </Tooltip>
            <Tooltip label={value?.taskDetails?.taskPhase}>
              <Text
                isTruncated
                width={'250px'}
                lineHeight={'12px !important'}
                color={'#6F767E'}
                fontSize={'12px'}
              >
                {value?.taskDetails?.taskPhase}
              </Text>
            </Tooltip>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Doc Type
        </Text>
      ),
      dataIndex: 'docType',
      key: 'docType',
      render: (value) => {
        return (
          <Tooltip label={value}>
            <Flex>
              <Text isTruncated maxWidth={'150px'}>
                {value}
              </Text>
            </Flex>
          </Tooltip>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Uploaded by
        </Text>
      ),
      dataIndex: 'uploadedBy',
      key: 'uploadedBy',
      render: (value) => {
        return (
          <Flex gap={2}>
            <Image
              borderRadius={'5px'}
              fit={'cover'}
              w={'24px'}
              h={'24px'}
              src={getImage(value?.profilePic, value?.name)}
            />
            <Flex>
              <Tooltip label={value?.name}>
                <Text maxWidth={'120px'} isTruncated fontWeight={600}>
                  {value?.name}, &nbsp;
                </Text>
              </Tooltip>
              <Text color={'#6F767E'}> {value?.role}</Text>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={500} color={'#6F767E'}>
            Expiry date
          </Text>
          <Popover placement='bottom'>
            <PopoverTrigger>
              <Box>
                <FontAwesomeIcon
                  color={selectedExpiryFilter ? '#2A85FF' : '#6F767E'}
                  cursor={'pointer'}
                  icon={faArrowDownShortWide as IconProp}
                />
              </Box>
            </PopoverTrigger>
            <PopoverContent borderRadius={'10px'} w={'220px'}>
              <PopoverArrow />
              <PopoverBody>
                <Flex gap={2} flexDir={'column'}>
                  <Text
                    onClick={() => {
                      setSelectedExpiryFilter('');
                      handleExpirySort('_ID_DESC');
                    }}
                    cursor={'pointer'}
                    borderRadius='12px'
                    padding='6px 12px'
                    _hover={{
                      bg: '#EFEFEF',
                      color: 'black',
                    }}
                    color={'#6F767E'}
                  >
                    No sort
                  </Text>
                  <Text
                    backgroundColor={
                      selectedExpiryFilter === 'older' ? '#EFEFEF' : 'white'
                    }
                    color={
                      selectedExpiryFilter === 'older' ? 'black' : '#6F767E'
                    }
                    onClick={() => {
                      setSelectedExpiryFilter('older');
                      handleExpirySort('EXPIRYDATE_ASC');
                    }}
                    cursor={'pointer'}
                    borderRadius='12px'
                    padding='6px 12px'
                    _hover={{
                      bg: '#EFEFEF',
                      color: 'black',
                    }}
                  >
                    Oldest expiry date first
                  </Text>
                  <Text
                    backgroundColor={
                      selectedExpiryFilter === 'newest' ? '#EFEFEF' : 'white'
                    }
                    color={
                      selectedExpiryFilter === 'newest' ? 'black' : '#6F767E'
                    }
                    onClick={() => {
                      setSelectedExpiryFilter('newest');
                      handleExpirySort('EXPIRYDATE_DESC');
                    }}
                    cursor={'pointer'}
                    borderRadius='12px'
                    padding='6px 12px'
                    _hover={{
                      bg: '#EFEFEF',
                      color: 'black',
                    }}
                  >
                    Newest expiry date first
                  </Text>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
      ),
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (_, value) => {
        return (
          <Flex flexDir={'column'}>
            <Text
              fontWeight={value?.isExpiring && 600}
              color={value?.isExpiring ? '#FF2047' : '#33383F'}
            >
              {value?.expiryDate
                ? moment(value?.expiryDate).format('DD MMM YYYY')
                : '--'}
            </Text>
            <Text color={'#6F767E'} fontSize={'12px'}>
              {value?.expiryDate
                ? moment(value?.expiryDate).format('hh:mm A')
                : '--'}
            </Text>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Actions
        </Text>
      ),
      key: 'action',
      render: (values) => {
        return (
          <Flex alignItems={'center'} gap={2}>
            {userAuthRole === AuthRole?.SUPER_ADMIN ? (
              <>
                {(values?.reminder?.remindBefore ||
                  values?.reminder?.remindDate) && (
                  <FontAwesomeIcon
                    onClick={() => {
                      complianceUpload({
                        complianceId: values?.eid,
                        type: 'reminder',
                      });
                    }}
                    cursor={'pointer'}
                    icon={faBell as Icon}
                  />
                )}
                <VersionHistoryModal complianceId={values?.key} />
                <ComplianceUpdate
                  preDefinedLocationId={params?.locationId}
                  complianceId={values.eid}
                />
                <DeleteDocumentModal
                  getComplianceFilters={getComplianceFilters}
                  getComplianceListing={getComplianceListing}
                  eid={values?.key}
                />
              </>
            ) : userAuthRole === AuthRole?.ADMIN &&
              loggedInUserId === values?.createdBy ? (
              <>
                <VersionHistoryModal complianceId={values?.key} />
                <ComplianceUpdate
                  preDefinedLocationId={params?.locationId}
                  complianceId={values.eid}
                />
                <DeleteDocumentModal
                  getComplianceFilters={getComplianceFilters}
                  getComplianceListing={getComplianceListing}
                  eid={values?.key}
                />
              </>
            ) : (
              <>
                <VersionHistoryModal complianceId={values?.key} />
                <ComplianceUpdate disabled={true} complianceId={values.eid} />
                <DeleteDocumentModal
                  disabled={true}
                  getComplianceFilters={getComplianceFilters}
                  getComplianceListing={getComplianceListing}
                  eid={values?.key}
                />
              </>
            )}
          </Flex>
        );
      },
    },
  ];

  const locationOwnerColumns = [
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Task Name
        </Text>
      ),
      dataIndex: 'taskName',
      key: 'taskName',
      render: (_, value) => {
        return (
          <Flex justifyContent={'center'} flexDir={'column'}>
            <Tooltip label={value?.taskDetails?.taskName}>
              <Text isTruncated width={'200px'}>
                {value?.taskDetails?.taskName}
              </Text>
            </Tooltip>
            <Tooltip label={value?.taskDetails?.taskPhase}>
              <Text
                isTruncated
                width={'200px'}
                lineHeight={'12px !important'}
                color={'#6F767E'}
                fontSize={'12px'}
              >
                {value?.taskDetails?.taskPhase}
              </Text>
            </Tooltip>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text align={'center'} fontWeight={500} color={'#6F767E'}>
          Doc Type
        </Text>
      ),
      dataIndex: 'docType',
      key: 'docType',
      render: (value) => {
        return (
          <Tooltip label={value}>
            <Flex justifyContent={'center'}>
              <Text isTruncated width={'150px'} align={'center'}>
                {value}
              </Text>
            </Flex>
          </Tooltip>
        );
      },
    },
    {
      title: (
        <Text align={'center'} fontWeight={500} color={'#6F767E'}>
          Date Uploaded
        </Text>
      ),
      dataIndex: 'dateUploaded',
      key: 'dateUploaded',
      render: (_, value) => {
        return (
          <Flex alignItems={'center'} flexDir={'column'}>
            <Text>{moment(value?.createdAt).format('DD MMMM')}</Text>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text align={'center'} fontWeight={500} color={'#6F767E'}>
          Doc Name
        </Text>
      ),
      dataIndex: 'docName',
      key: 'docName',
      render: (_, value) => {
        return (
          <Flex justifyContent={'center'} gap={2} alignItems={'center'}>
            <Flex
              borderRadius={'5px'}
              p={'10px 10px'}
              border={'1px solid #DBDBDB'}
            >
              <DocumentIcon />
            </Flex>
            <Flex flexDir={'column'}>
              <Tooltip label={value?.name}>
                <Text isTruncated width={'150px'}>
                  {getDocNameWithExtension(value?.docDetails?.name)}
                </Text>
              </Tooltip>
              <Text
                color={'#6F767E'}
                fontSize={'12px'}
                lineHeight={'12px !important'}
              >
                {value?.docDetails?.size}
              </Text>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text align={'center'} fontWeight={500} color={'#6F767E'}>
          Uploaded by
        </Text>
      ),
      dataIndex: 'uploadedBy',
      key: 'uploadedBy',
      render: (value) => {
        return (
          <Flex justifyContent={'center'} alignItems={'center'}>
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              borderRadius={'5px'}
              p={'4px 10px'}
              gap={2}
              backgroundColor={'#B1E5FC80'}
            >
              <Image
                borderRadius={'5px'}
                width={'22px'}
                height={'22px'}
                src={getImage(value?.profilePic, value?.name)}
              />
              <Tooltip label={value?.name}>
                <Text align={'center'} isTruncated width={'100px'}>
                  {value?.name}
                </Text>
              </Tooltip>
            </Flex>
          </Flex>
        );
      },
    },
  ];

  useEffect(() => {
    getComplianceListing({
      variables: getComplianceFilters(),
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      getComplianceListing({
        variables: getComplianceFilters(),
      });
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchFieldText]);

  const filterRef = useLatest(getComplianceFilters);

  useEffect(() => {
    // const catListener = eventBus.on('refetchCategory', () => {
    //   getComplianceCategories();
    // });
    const listener = eventBus.on('refetchCompliance', () => {
      getComplianceListing({
        variables: filterRef.current(),
      });
    });
    return () => {
      listener();
      // catListener();
    };
  }, []);

  if (complianceLoader && tableData?.length === 0) {
    return (
      <Flex justifyContent={'center'} height={'80vh'}>
        <Center>
          <Loader />
        </Center>
      </Flex>
    );
  }

  if (tableData?.length === 0 && !searchFieldText) {
    return <DefaultEmptyState />;
  }

  return (
    <Box>
      <Heading />
      <Flex mt={'24px'} width={'100%'}>
        <SearchInput
          disabled={true}
          size='md'
          hideShortcuts={true}
          placeholder={'Search document by name'}
          onChange={(e) => setSearchFieldText(e.target.value)}
          value={searchFieldText}
        />
      </Flex>
      <Box mt={'24px'}>
        {tableData?.length !== 0 ? (
          <LauncherTable
            columns={
              userAuthRole === AuthRole?.LOCATION_OWNER
                ? locationOwnerColumns
                : columns
            }
            tableData={tableData}
            totalRowsCount={totalRowsCount}
            getComplianceListing={getComplianceListing}
            getComplianceFilters={getComplianceFilters}
            complianceLoader={complianceLoader}
          />
        ) : (
          <DefaultEmptyState searchFieldText={searchFieldText} />
        )}
      </Box>
    </Box>
  );
};

ListContainer.displayName =
  'sub-components/Launcher/document-listing/components/ListContainer';
export default ListContainer;
