import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useLauncherConfigContext } from '../../common';

interface IProps {
  sectionIndex: number;
}

const AddTaskButton: FC<IProps> = ({ sectionIndex }) => {
  const openTaskForm = useLauncherConfigContext((state) => state.openTaskForm);
  const category = useLauncherConfigContext((state) => {
    return state.config.contents?.[sectionIndex]?.category;
  });

  return (
    <Flex
      gap={3}
      padding='20px 16px'
      align='center'
      cursor='pointer'
      _active={{
        boxShadow: 'md',
      }}
      userSelect='none'
      onClick={() => openTaskForm({ sectionIndex, taskIndex: -1 })}
    >
      <FontAwesomeIcon size='lg' icon={faPlus as IconProp} color='#2A85FF' />
      <Box fontSize='14px' fontWeight='600' color='#2A85FF' isTruncated>
        Add Task to {category}
      </Box>
    </Flex>
  );
};

AddTaskButton.displayName = 'sc/L/lc/ev/s/AddTaskButton';

export default React.memo(AddTaskButton);
