import React, { FC } from 'react';
import { faPuzzle } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

import { ActionMenu } from '../../../ui-components';
import { AppRoute, useHistory, useRouteState } from '../../../routes';

interface IProps {}

const CreateAuditButton: FC<IProps> = ({ children }) => {
  const { t } = useTranslation('form');
  const history = useHistory();

  const { updateState } = useRouteState({
    tabIndex: 0,
  });

  const actionHandler = (action: string) => {
    switch (action) {
      case 'scratch':
        return history.push({
          pathname: AppRoute.CREATE_AUDIT_TEMPLATE,
          state: {
            backToTitle: 'Back to Audits',
            pageTitle: 'Create audit',
          },
        });
      case 'template':
        sessionStorage.setItem('auditDraftTab', 'library');
        return updateState({
          tabIndex: 1,
        });
      default:
        break;
    }
  };

  return (
    <ActionMenu
      menuData={[
        {
          name: t('create_scratch'),
          value: 'scratch',
          icon: faPlus,
          normalIcon: 'fontAwesome',
        },
        {
          name: t('create_template'),
          value: 'template',
          icon: faPuzzle,
          normalIcon: 'fontAwesome',
        },
      ]}
      arrowSize={10}
      closeOnBlur={true}
      offset={[0, 10]}
      clickedItem={(_, clickedItem) => actionHandler(clickedItem)}
    >
      {children}
    </ActionMenu>
  );
};

export default CreateAuditButton;
