import { gql, useQuery } from '@apollo/client';

export interface BaseBoardItemEntity {
  eid: string;
  title: string;
  locationLaunches: Array<{
    isLive?: boolean;
    details: {
      locationName: string;
    };
  }>;
}

export interface BoardItemEntity extends BaseBoardItemEntity {
  createdBy: string;
  updatedBy?: string;
  updatedAt: string;
  contents: Array<{ eid: string }>;
  locations: number;
}

export interface Response {
  BusinessLaunchers: {
    items: BoardItemEntity[];
  };
}

export const BUSINESS_LAUNCHER = gql`
  query BusinessLaunchers {
    BusinessLaunchers {
      items {
        eid
        title
        createdBy
        updatedBy
        updatedAt
        contents {
          eid
        }
        locations
        locationLaunches {
          isLive
          details {
            locationName
          }
        }
      }
    }
  }
`;

interface IBusinessBoards {
  loading?: boolean;
  data: BoardItemEntity[];
}

interface IProps {
  fetchPolicy?: 'network-only';
}

export const useBusinessBoards = (props?: IProps): IBusinessBoards => {
  const { loading, data } = useQuery<Response>(BUSINESS_LAUNCHER, {
    ...props,
  });

  const boardList = data?.BusinessLaunchers?.items || [];

  return { loading, data: boardList };
};
