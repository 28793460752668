import React, { FC } from 'react';
import {
  Modal,
  ModalContent,
  ModalBody,
  Progress,
  Box,
  Flex,
  Text,
  ModalOverlay,
} from '@chakra-ui/react';
import { FaRegFileLines } from 'react-icons/fa6';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  fileName: string;
  progress: number;
}

const ProgressModal: FC<IProps> = ({ isOpen, onClose, fileName, progress }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        borderRadius={'12px'}
        py={'24px'}
        width={'490px'}
        height={'145px'}
      >
        <ModalBody>
          <Text fontSize={'17.52px'} fontWeight={600} mb={'20px'}>
            Uploaded Files
          </Text>
          <Flex direction={'row'} alignItems={'center'} gap={'18px'}>
            <FaRegFileLines
              color='#6F767E'
              stroke='4'
              style={{ height: '40px', width: '40px' }}
            />
            <Flex direction={'column'} gap={'10px'} width={'full'}>
              <Box
                fontSize='15px'
                fontWeight={600}
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Text isTruncated width={'10rem'}>
                  {fileName}
                </Text>
                <Text fontSize='12px' fontWeight={500} color={'#6F767E'}>
                  {progress}%
                </Text>
              </Box>
              <Progress
                value={progress}
                size='md'
                colorScheme='blue'
                rounded={'full'}
                height={'6px'}
              />
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProgressModal;
