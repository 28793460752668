import { Flex } from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import DetailsCard from './DetailsCard';
import LongTasks from './LongTasks';
import ProgressDetails from 'pages/TasksDashboard/MyTasks/component/MyTaskDetails/sub-components/ProgressDetails';
import StepListing from 'pages/TasksDashboard/MyTasks/component/MyTaskDetails/sub-components/StepListing';
import TitleHeading from 'pages/TasksDashboard/MyTasks/component/MyTaskDetails/sub-components/TitleHeading';
import { CommonAmplitudeEvents } from 'sop-commons/Events/CommonEvents';
import Details from 'pages/TasksDashboard/MyTasks/component/MyTaskDetails/sub-components/Details';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import { useHistory, useParams } from 'react-router-dom';
import Heading from 'pages/TasksDashboard/MyTasks/component/MyTaskDetails/sub-components/Heading';
import { ISessionByIdForMyTasks } from 'pages/TasksDashboard/MyTasks/services/my-tasks-types';
import { SESSION_BY_ID_FOR_MY_TASK_DETAILS } from 'pages/TasksDashboard/MyTasks/services/my-tasks.graphql';
import { useTerminatedTaskModal } from 'pages/TasksDashboard/Components/sub-components/TerminatedTaskModal';
type Props = {};

const TaskDetails: FC<Props> = () => {
  const userData = useReactiveVar(userObj);
  const history = useHistory();
  const terminatedTaskModal = useTerminatedTaskModal();
  const [isLoading, setIsLoading] = useState(false);
  const [shouldTriggerRefetch, setShouldTriggerRefetch] = useState(false);
  const [eventCaptured, setEventCaptured] = useState(false);
  const { taskId, sessionId } = useParams<{
    taskId: string;
    sessionId: string;
  }>();

  const [execute, { data, refetch }] = useLazyQuery<
    ISessionByIdForMyTasks,
    { eid: string }
  >(SESSION_BY_ID_FOR_MY_TASK_DETAILS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      setIsLoading(false);
      setShouldTriggerRefetch(false);

      // if (!eventCaptured) {
      //   captureEvent(data);
      // }
    },
    onError: () => {
      setIsLoading(false);
      setShouldTriggerRefetch(false);
    },
  });

  const renderComponent = () => {
    return (
      <Flex id='my-task-details-1' flexDir='column' gap={4}>
        <TitleHeading data={data} />
        <Flex id='my-task-details-2' gap='10px' w='full'>
          <Flex
            id='my-task-details-3'
            bg='white'
            w='60%'
            flexDir='column'
            py={6}
            borderRadius='8px'
            gap={4}
          >
            <Heading data={data} />
            <Flex id='my-task-details-4' flexDir='column' gap={2}>
              <ProgressDetails
                data={data}
                userData={{
                  name: userData?.name,
                  profilePic: userData?.profilePic,
                }}
              />
              <StepListing
                data={data}
                id={taskId}
                refetch={refetch}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                shouldTriggerRefetch={shouldTriggerRefetch}
                setShouldTriggerRefetch={setShouldTriggerRefetch}
                event={CommonAmplitudeEvents.VIEW_MY_TASK_STEP_FORM}
              />
            </Flex>
          </Flex>
          <Details data={data} userData={userData} />
        </Flex>
      </Flex>
    );
  };
  return (
    // <Flex gap={'16px'}>
    //   <Flex w={'70%'}>
    //     <LongTasks />
    //   </Flex>
    //   <Flex w={'30%'}>
    //     <DetailsCard />
    //   </Flex>
    // </Flex>
    renderComponent()
  );
};

TaskDetails.displayName = 'sc/L/t/td/TaskDetails';

export default TaskDetails;
