import { Button, Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { ReactComponent as MarkRead } from 'assets/images/notifications/markRead.svg';
import { INotificationItem } from 'sub-components/NotificationCenter/types/service-types';

interface IProps {
  markAllNotificationRead: any;
  isCompactView: boolean;
  timeLineKey?: 'today' | 'yesterday' | 'week' | 'month';
  filteredAndGroupedData?: () => Record<
    'today' | 'yesterday' | 'week' | 'month',
    INotificationItem[]
  >;
  markGroupedNotificationsRead?: any;
}

const MarkReadBtn: FC<IProps> = ({
  markAllNotificationRead,
  isCompactView,
  timeLineKey,
  filteredAndGroupedData,
  markGroupedNotificationsRead,
}) => {
  const decideVarsBasedOnTimeline = () => {
    const timeLineData = filteredAndGroupedData?.();

    const data = Object.entries(timeLineData)
      ?.flatMap(([key, value]) => {
        // console.log('key, value --- ', key, value);
        if (key === timeLineKey) {
          console.log('value ---- ', value);
          return value?.map((item) => {
            return item?.eid;
          });
        }
      })
      ?.filter((item) => item !== undefined);

    return { isSeen: true, eids: data };
  };

  const readAction = () => {
    if (isCompactView) {
      const vars = decideVarsBasedOnTimeline();
      markGroupedNotificationsRead?.({
        variables: vars,
      });
    } else {
      markAllNotificationRead();
    }
  };
  return (
    <Button
      right={0}
      position={'absolute'}
      p={'8px 12px'}
      h={'30px'}
      leftIcon={<MarkRead />}
      backgroundColor={'transparent'}
      // _hover={'transparent'}
      // _active={'transparent'}
      outlineColor={'#EFEFEF'}
      onClick={readAction}
    >
      Mark all as read
    </Button>
  );
};

export default MarkReadBtn;
