import React, { FC } from 'react';
import { Box, HStack, ListItem, VStack } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Image, RoleAndAuthRole } from '../../../../ui-components';

import { IRoleMemberEntity } from '../roles-list.types';

interface ItemProps {
  item: IRoleMemberEntity;
  selectedMembers: string[];
  onCheckChange: (eid: string) => void;
}

const MoveToItem: FC<ItemProps> = ({
  item,
  selectedMembers,
  onCheckChange,
}) => {
  const isSelected = selectedMembers.includes(item?.eid);

  return (
    <ListItem
      borderRadius='8px'
      aria-selected={isSelected}
      _hover={{ bg: '#EEEEEE' }}
      _selected={{ bg: '#2A85FF1F' }}
    >
      <HStack
        spacing={3}
        padding={2}
        cursor='pointer'
        onClick={() => onCheckChange(item?.eid)}
      >
        <HStack flex={1} overflow='hidden' userSelect='none'>
          <Box>
            <Box flexGrow={0} width={'40px'}>
              <Image
                src={item?.profilePic}
                width={40}
                height={40}
                style={{
                  width: 40,
                  height: 40,
                  objectFit: 'cover',
                  borderRadius: 6,
                }}
              />
            </Box>
          </Box>

          <VStack align={'stretch'} flex={'1'} overflow={'hidden'}>
            <Box w={'max-content'}>
              <Box
                noOfLines={1}
                fontSize={'15px'}
                fontWeight={'700'}
                color={'#1A1D1F'}
                whiteSpace={'nowrap'}
                overflow={'hidden'}
                textOverflow={'ellipsis'}
              >
                {item?.name ? item?.name : 'N/A'}
              </Box>
              <RoleAndAuthRole
                role={item?.role}
                authRole={item?.authRole}
                roleProps={{
                  fontWeight: '500',
                }}
                authRoleProps={{
                  fontWeight: '500',
                }}
                disableToolTip
              />
            </Box>
          </VStack>
        </HStack>
        <Box>
          {isSelected ? (
            <FontAwesomeIcon
              icon={faSquareCheck as IconProp}
              color='#2A85FF'
              size='lg'
            />
          ) : (
            <FontAwesomeIcon
              icon={faSquare as IconProp}
              color='#999999'
              size='lg'
            />
          )}
          {/* <Checkbox
            isChecked={item?.checked}
            onChange={() => onCheckChange(item?.eid)}
          /> */}
        </Box>
      </HStack>
    </ListItem>
  );
};

export default MoveToItem;
