import { toArray } from '../../../../utils';
import { useLauncherConfigContext } from './context';
import { getDuration, IDuration } from './trigger/duration.helper';
import { LauncherContentInput } from './launcher-config.store';

export interface EDuration extends IDuration {
  isExpected?: boolean;
}

export const convertToDays = (duration: IDuration): number => {
  switch (duration?.durationUnit) {
    case 'days':
      return duration?.durationValue!;
    case 'weeks':
      return duration?.durationValue! * 7;
    case 'months':
      return duration?.durationValue! * 30;
  }
  return 2;
};

export const convertToDuration = (value: number): EDuration => {
  // if (value > 300) {
  //   return {
  //     durationValue: +formatNumber(value / 365),
  //     durationUnit: 'days',
  //     isExpected: true,
  //   };
  // }

  if (value > 28) {
    return {
      durationValue: Math.ceil(value / 30),
      durationUnit: 'months',
      isExpected: true,
    };
  }

  if (value > 6) {
    return {
      durationValue: Math.ceil(value / 7),
      durationUnit: 'weeks',
      isExpected: true,
    };
  }

  return {
    durationValue: value,
    durationUnit: 'days',
    isExpected: true,
  };
};

const getPhaseDuration = (
  section?: LauncherContentInput
): EDuration | undefined => {
  if (section?.triggerAssignment === 'phase') {
    return getDuration(section.trigger);
  }

  const durationValue = toArray(section?.tasks).reduce((acc, nextTask) => {
    return acc + convertToDays(getDuration(nextTask.trigger));
  }, 0);

  if (durationValue) {
    return convertToDuration(durationValue);
  }
};

export const useSectionDuration = (sectionIndex: number) => {
  return useLauncherConfigContext((state) => {
    return getPhaseDuration(state.config.contents?.[sectionIndex]);
  });
};
