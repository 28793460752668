import React, { FC } from 'react';
import { Box, Center, Flex, Radio } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';

interface IProps {
  title: string;
  caption: string;
  icon: IconDefinition;
  isActive?: boolean;
  onClick?: () => void;
}

const TemplateTypeButton: FC<IProps> = ({
  icon,
  title,
  caption,
  isActive,
  onClick,
}) => {
  return (
    <Center
      position='relative'
      onClick={onClick}
      flexDir='column'
      border='1px solid'
      borderColor={isActive ? '#2A85FF' : '#DDDDDD'}
      width='280px'
      height='165px'
      borderRadius='20px'
      paddingX='32px'
      paddingY='20px'
      gap='12px'
      cursor='pointer'
      _hover={{
        boxShadow: 'md',
      }}
    >
      <Box
        position='absolute'
        top='16px'
        right='14px'
        sx={{
          '.chakra-radio__label': {
            color: '#6F767E',
            fontSize: 14,
            fontWeight: 500,
            _checked: {
              color: '#33383F',
            },
          },
          '.chakra-radio__control': {
            _checked: {
              borderColor: 'blue.500',
              background: 'white',
              color: 'blue.500',
              _hover: {
                background: 'white',
              },
            },
          },
        }}
      >
        <Radio size='lg' isReadOnly cursor='initial' isChecked={isActive} />
      </Box>

      <FontAwesomeIcon
        icon={icon as IconProp}
        color={isActive ? '#2A85FF' : '#6F767E'}
        size='3x'
      />

      <Flex flexDir='column' align='center' textAlign='center'>
        <Box fontSize='18px' fontWeight='700' color='#000000'>
          {title}
        </Box>
        <Box fontSize='12px' fontWeight='400' color='#6F767E'>
          {caption}
        </Box>
      </Flex>
    </Center>
  );
};

export default TemplateTypeButton;
