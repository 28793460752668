import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '../../../../../ui-components';

import { InfoFormValues } from '../ProfileInfoForm';
import { useDeleteConfirm } from '../../../../Teams/MemberDetails';
import {
  DELETE_MEMBER,
  SOFT_DELETE_MEMBER,
  TRANSFER_AND_DELETE_MEMBER,
} from '../../../../Teams/InvitedMember/update-invite.graphql';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import {
  FormControl,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'atoms';
import { AuthRole } from 'authorization';
import { usersEntityObj } from 'sub-components/Header';
import { roleObj } from 'ui-components/DashboardMenu';
import TransferMemberList from '../../TransferMemberList';

interface IProps {
  userId: string;
  close?: Function;
  onDeleted?: (...args: any[]) => any | PromiseLike<any>;
  authRole?: AuthRole;
  status?: string;
}

const DeleteAccount: FC<IProps> = ({
  userId,
  onDeleted,
  close,
  authRole,
  status,
}) => {
  const { t } = useTranslation(['common', 'profile', 'invite']);
  const toast = useToast({
    duration: 2000,
    position: 'top-right',
    isClosable: true,
  });

  const { getValues: defaultFormValues } = useFormContext<InfoFormValues>();

  const methods = useForm<InfoFormValues>({
    defaultValues: {
      eid: userId,
      requestedBy: null,
    },
  });

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = methods;

  const deleteConfirm = useDeleteConfirm();

  const { onClose, isOpen, onOpen } = useDisclosure();
  const [option, setOption] = useState('transfer');
  const roles = useReactiveVar<Record<string, string>>(roleObj);
  const entityObjData = useReactiveVar(usersEntityObj);

  const roleList = useMemo(() => {
    return Object.keys(roles || {}).map((key) => {
      return { label: key, value: key };
    });
  }, [roles]);

  const memberList = useMemo(() => {
    if (!authRole || authRole === AuthRole.WORKER || !entityObjData) return [];

    const roleHierarchy = {
      [AuthRole.SUPER_ADMIN]: [AuthRole.SUPER_ADMIN],
      [AuthRole.ADMIN]: [AuthRole.SUPER_ADMIN, AuthRole.ADMIN],
      [AuthRole.LOCATION_OWNER]: [
        AuthRole.SUPER_ADMIN,
        AuthRole.ADMIN,
        AuthRole.LOCATION_OWNER,
      ],
    };

    // Filter the base list based on the current user's authRole
    return entityObjData
      ?.filter(
        (userData) =>
          roleHierarchy[authRole].includes(userData.authRole) &&
          userData.type === 'user'
      )
      .map((user) => {
        return { label: user.name, value: user.eid, role: user.role };
      });
  }, [entityObjData, userId, authRole]);

  /**
   * For hard delete: `deleteUserPermanently`
   * For soft delete: `SoftDeleteUser`
   */
  const [hardDeleteUser] = useMutation(DELETE_MEMBER, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: t('common:success'),
        description: 'Member will be deleted in some time',
      });
      onDeleted?.();
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common:error'),
        description: t('profile:memberDeleteError'),
      });
    },
  });

  const [softDeleteUser] = useMutation(SOFT_DELETE_MEMBER, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: t('common:success'),
        description: t('profile:memberDeleteSuccess'),
      });
      onDeleted?.();
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common:error'),
        description: t('profile:memberDeleteError'),
      });
    },
  });

  const [transferAndDelete] = useMutation(TRANSFER_AND_DELETE_MEMBER, {
    onCompleted: () => {
      if (option === 'transfer') {
        toast({
          status: 'success',
          title: t('common:success'),
          description: t('invite:memberDeleteWithTransfer'),
        });
      } else {
        toast({
          status: 'success',
          title: t('common:success'),
          description: t('invite:memberDeleteWithoutTransfer'),
        });
      }
      onClose();
      onDeleted?.();
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common:error'),
        description: t('profile:memberDeleteError'),
      });
    },
  });

  const _onDeleteClick = async () => {
    return new Promise((resolve, reject) => {
      deleteConfirm({
        name: defaultFormValues('firstName')
          .concat(' ', defaultFormValues('lastName'))
          .trim(),
        onOkPress: async () => {
          deployEvent(AmplitudeEventNames.DELETE_MEMBER);
          const res =
            defaultFormValues('status') === 'active'
              ? await softDeleteUser({
                  variables: {
                    eid: userId,
                  },
                })
              : await hardDeleteUser({
                  variables: {
                    eid: userId,
                  },
                });
          if (res.errors) {
            return Promise.reject('Member deletion failed');
          }
          return resolve(true);
        },
        onCancelPress: () => reject(null),
      });
    });
  };

  const handleDeleteClick = async () => {
    deployEvent(AmplitudeEventNames.DELETE_MEMBER);
    if (option === 'transfer') {
      await transferAndDelete({
        variables: {
          eid: userId,
          requestedBy: getValues('requestedBy'),
        },
      });
    } else {
      await transferAndDelete({
        variables: {
          eid: userId,
        },
      });
    }
  };

  return (
    <>
      <ActionButton
        minW='140px'
        mt={6}
        colorScheme='red'
        fontSize='15px'
        fontWeight='600'
        px={4}
        actionFn={() => {
          if (
            (!authRole && !status) ||
            authRole === AuthRole.WORKER ||
            status === 'pending'
          ) {
            _onDeleteClick();
          } else {
            onOpen();
          }
        }}
        close={close}
      >
        {t('common:delete')}
      </ActionButton>

      <Modal
        isOpen={isOpen}
        isCentered={true}
        onClose={() => {
          onClose();
          reset();
        }}
        size='3xl'
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent
          paddingY='16px'
          paddingX='8px'
          borderRadius='16px'
          zIndex='1500'
        >
          <ModalHeader>
            <Flex gap='12px' justify='space-between'>
              <Flex gap={4} alignItems='center'>
                <FontAwesomeIcon
                  icon={faTrashCan as IconProp}
                  color='#FF6A55'
                  fontSize='24px'
                />
                <Box color='#111315' fontWeight={600} fontSize='20px'>
                  {t('invite:memberDeleteHeaderText')}
                </Box>
              </Flex>
              <AlertDialogCloseButton
                pos='relative'
                borderRadius='full'
                top='0'
                right='0'
                onClick={onClose}
              />
            </Flex>
          </ModalHeader>
          <FormProvider {...methods}>
            <ModalBody mt={4}>
              <RadioGroup onChange={setOption} value={option}>
                <Stack spacing={6}>
                  <Box>
                    <Radio value='transfer' alignItems='start' gap={2}>
                      <Text
                        mt='-6px'
                        color='#1A1D1F'
                        fontWeight={600}
                        fontSize='15px'
                      >
                        Transfer Ownership
                      </Text>
                      <Text fontSize='sm' color='#6F767E' fontWeight={500}>
                        Select a new owner for their files and responsibilities.
                      </Text>
                      {option === 'transfer' && (
                        <FormControl mt={3}>
                          <TransferMemberList
                            memberList={memberList}
                            rolesList={roleList}
                            control={control}
                            trigger={trigger}
                            errors={errors}
                          />
                        </FormControl>
                      )}
                    </Radio>
                  </Box>
                  <Box>
                    <Radio value='delete' alignItems='start' gap={2}>
                      <Text
                        mt='-6px'
                        color='#1A1D1F'
                        fontWeight={600}
                        fontSize='15px'
                      >
                        Delete Without Transfer
                      </Text>
                      <Text fontSize='sm' color='#6F767E' fontWeight={500}>
                        Files and people will remain linked to the deleted
                        member, but their name will appear as ‘Delightree User’.
                      </Text>
                    </Radio>
                  </Box>
                </Stack>
              </RadioGroup>
            </ModalBody>
            <ModalFooter>
              <Flex gap={2} width='100%'>
                <Button
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                  flex={1}
                  variant='outline'
                  padding='12px 20px'
                  borderRadius='12px'
                >
                  Cancel
                </Button>

                {option === 'transfer' && (
                  <ActionButton
                    minW='140px'
                    colorScheme='blue'
                    fontSize='15px'
                    fontWeight='600'
                    px={4}
                    actionFn={handleSubmit(handleDeleteClick)}
                    close={close}
                    flex={1}
                    padding='12px 20px'
                    borderRadius='12px'
                  >
                    {t('invite:memberTransferText')}
                  </ActionButton>
                )}

                {option === 'delete' && (
                  <ActionButton
                    minW='140px'
                    colorScheme='blue'
                    fontSize='15px'
                    fontWeight='600'
                    px={4}
                    actionFn={handleSubmit(handleDeleteClick)}
                    close={close}
                    flex={1}
                    padding='12px 20px'
                    borderRadius='12px'
                  >
                    {t('invite:memberDeleteText')}
                  </ActionButton>
                )}
              </Flex>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteAccount;
