import { Box } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import React, { FC } from 'react';
import { Image } from 'ui-components';

type ProgressBarProps = {
  value: number;
  max: number;
  profileUrl?: string;
};

const CustomProgress: FC<ProgressBarProps> = ({ value, max, profileUrl }) => {
  const progress = max === 0 ? 0 : (value / max) * 100;

  return (
    <Box position='relative' width='100%' height='4px'>
      {/* Background Track */}
      <Box
        position='absolute'
        width='100%'
        height='100%'
        bg='#E2E8F0'
        borderRadius='full'
      />

      {/* Filled Track */}
      <Box
        position='absolute'
        width={`${progress}%`}
        height='100%'
        bg={HeaderColors.DarkBlue}
        borderRadius='full'
      />

      {/* Profile Picture Thumb */}
      <Image
        height={25}
        width={25}
        style={{
          position: 'absolute',
          left: `${progress}%`,
          minWidth: '25px',
          minHeight: '25px',
          maxWidth: '25px',
          maxHeight: '25px',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '50%',
          border: '2px solid white',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
        }}
        src={profileUrl || ''}
        alt='Profile'
      />
    </Box>
  );
};

CustomProgress.displayName =
  'displayName:sub-components/Launcher/tasks/components/task-details/components/CustomProgress';

export default CustomProgress;
