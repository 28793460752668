import { create } from 'zustand';
// import { devtools } from 'zustand/middleware';
import { knowledgeBaseSlice } from './slices/useKnowledgeBase';
import { chapterEditorSlice } from './slices/useChapterEditor';
import { searchSlice } from './slices/useSearch';
import { visibilitySlice } from './slices/useVisibility';
import { homeSlice } from './slices/useHome';
import { editChapterFileSlice } from './slices/useEditChapterFiles';
import { launcherTaskSlice } from './slices/useLauncherTask';
import { emitterSlice } from './slices/emitter/useEmitter';
import { combinedLoadingStateSlice } from './slices/useCombinedLoading';
import { headerSlice } from './slices/useHeader';
import { stateDrawerSlice } from './slices/useStateDrawer';

const useCombinedStore = create<
  ReturnType<typeof knowledgeBaseSlice> &
    ReturnType<typeof chapterEditorSlice> &
    ReturnType<typeof visibilitySlice> &
    ReturnType<typeof searchSlice> &
    ReturnType<typeof homeSlice> &
    ReturnType<typeof editChapterFileSlice> &
    ReturnType<typeof launcherTaskSlice> &
    ReturnType<typeof emitterSlice> &
    ReturnType<typeof combinedLoadingStateSlice> &
    ReturnType<typeof headerSlice> &
    ReturnType<typeof stateDrawerSlice>
>((set, get, store) => ({
  ...knowledgeBaseSlice(set, get, store),
  ...chapterEditorSlice(set, get, store),
  ...visibilitySlice(set, get, store),
  ...searchSlice(set, get, store),
  ...homeSlice(set, get, store),
  ...editChapterFileSlice(set, get, store),
  ...launcherTaskSlice(set, get, store),
  ...emitterSlice(set, get, store),
  ...combinedLoadingStateSlice(set, get, store),
  ...headerSlice(set, get, store),
  ...stateDrawerSlice(set, get, store),
}));

export default useCombinedStore;
