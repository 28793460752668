import React, { FC } from 'react';
import { Box, CloseButton, Flex } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { SelectOption } from '../../../../../../../../atoms';
import { IconImage } from '../../../../../../../../ui-components';

interface IProps {
  data?: SelectOption;
  onRemoveClick?: () => void;
}

const UserItem: FC<IProps> = ({ data, onRemoveClick }) => {
  if (!data) {
    return null;
  }

  const authRole = data?.authRole?.split(/(?=[A-Z])/).join(' ');

  return (
    <Flex
      borderRadius='10px'
      border='1px solid #EFEFEF'
      bg='#F4F4F4'
      padding='6px 8px'
      gap={2}
      alignItems='center'
      cursor='pointer'
      _hover={{
        borderColor: '#6F767E33',
        bg: '#EFEFEF',
        button: {
          display: 'flex',
        },
      }}
    >
      <IconImage
        name={data.label}
        thumbnail={data.profilePic}
        boxSize={24}
        borderRadius='20px'
      />
      <Box fontWeight='600' color='#33383F'>
        {data.label},{' '}
        <Box
          as='span'
          fontSize='13px'
          fontWeight='500'
          color='#6F767E'
          textTransform='capitalize'
        >
          {authRole}
        </Box>
      </Box>
      <CloseButton
        boxSize='20px'
        display='none'
        size='sm'
        onClick={onRemoveClick}
      >
        <CloseIcon width='8px' height='8px' />
      </CloseButton>
    </Flex>
  );
};

UserItem.displayName =
  'displayName:packages/sop-web/src/sub-components/nexus/Compliance/Create/components/common/document-reminder/remind-to-user/UserItem';
export default UserItem;
