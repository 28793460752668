import { IAssigneeUsersEntity } from 'pages/TasksDashboard/tasks.types';
import { LocationsEntity } from 'sop-commons/src/client';

export interface IAssigneeUsers {
  authRole: string;
  eid: string;
  name: string;
  profilePic: string;
  role: string;
  type: string;
  locations: Array<{
    eid: string;
    name: string;
  }>;
  status: string;
}

export const getLOFilteredUsersList = (
  descendantLocations: LocationsEntity[],
  assigneeUsers: IAssigneeUsersEntity[]
) => {
  const filteredAssigneeUsers = assigneeUsers?.filter((user: any) => {
    return user?.locations?.some((location: any) => {
      return descendantLocations?.some((descendant) => {
        let flag = descendant?.eid === location?.eid;
        return flag;
      });
    });
  });
  return filteredAssigneeUsers || [];
};
