import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { CollapseTableProps } from './types';

const CollapseTable = <T extends { key: string | number }>({
  data,
  columns,
  hideIcon = false,
  preventCollapse = false,
  renderPanel,
  renderHeader,
}: CollapseTableProps<T>) => {
  return (
    <Flex
      borderRadius='12px'
      border='1px solid rgba(239, 239, 239, 1)'
      w='full'
      flexDir='column'
    >
      <Flex
        bg='rgba(239, 239, 239, 1)'
        w='full'
        p='13px'
        borderTopLeftRadius='12px'
        borderTopRightRadius='12px'
      >
        {columns.map((col, index) => (
          <Flex
            key={index}
            color='rgba(111, 118, 126, 1)'
            fontSize='12px'
            fontWeight={500}
            w={col.width || 'auto'}
            paddingLeft={col?.paddingLeft || 'auto'}
            justify={col.justify || 'flex-start'}
          >
            {col.header}
          </Flex>
        ))}
      </Flex>

      <Accordion
        allowMultiple={!preventCollapse}
        allowToggle={!preventCollapse}
        defaultIndex={data.map((_, index) => index)}
      >
        {data.map((item) => (
          <AccordionItem key={item.key} onClick={() => undefined}>
            {({ isExpanded }) => (
              <>
                <AccordionButton p={0} bg='rgba(252, 252, 252, 1)'>
                  {renderHeader ? (
                    renderHeader(item, isExpanded)
                  ) : (
                    <Flex
                      align='center'
                      p='24px'
                      justify='space-between'
                      w='full'
                    >
                      <Flex align='center' color='rgba(111, 118, 126, 1)'>
                        <AccordionIcon />
                        <Box textAlign='left' ml={2} fontWeight={600}>
                          {typeof columns[0].accessor === 'function'
                            ? columns[0].accessor(item)
                            : item[columns[0].accessor]}
                        </Box>
                      </Flex>
                      <Flex
                        color='rgba(42, 133, 255, 1)'
                        fontSize='12px'
                        fontWeight={500}
                      >
                        {isExpanded ? 'Hide Details' : 'View Details'}
                      </Flex>
                    </Flex>
                  )}
                </AccordionButton>
                <AccordionPanel p={0}>
                  {renderPanel ? renderPanel(item) : null}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </Flex>
  );
};

CollapseTable.displayName =
  'sub-components/audits/audit-tab/my-audits/components/CollapseTable';

export default CollapseTable;
