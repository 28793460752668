import React, { FC } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '../../../../../atoms';
import { ActionButton } from '../../../../../ui-components';
import TitleHeader from '../../../../CardEditor/TitleHeader';

interface IProps {
  onClose?: () => void;
  onSubmit?: (value: string) => void | Promise<void>;
}

const AddSectionForm: FC<IProps> = ({ onClose, onSubmit }) => {
  const { t } = useTranslation(['common', 'launcher', 'task']);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      title: '',
    },
  });

  const _onSubmit = async (values: { title?: string }) => {
    await onSubmit?.(values.title!);
    onClose?.();
  };

  return (
    <Flex flexDir='column' py='8px' gap={3}>
      <TitleHeader title={t('launcher:addAPhaseName')} isRequired />

      <Controller
        control={control}
        name={'title'}
        defaultValue=''
        rules={{
          required: t('task:validation.thisFieldRequired'),
          validate: (value) => {
            if (value?.trim()?.length === 0) {
              return t('common:validation.enter_title');
            }
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={fieldState?.invalid}>
              <Input variant='filled' size='lg' autoFocus {...field} />
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <Flex gap={3}>
        <Button flex={1} size='lg' variant='outline' onClick={onClose}>
          {t('common:close')}
        </Button>
        <ActionButton
          flex={1}
          size='lg'
          colorScheme='blue'
          actionFn={handleSubmit(_onSubmit)}
        >
          {t('common:add')}
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default AddSectionForm;
