import React, { FC, useEffect } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { SessionDetailEntity } from '../../../../../../../sub-components/audits/take-audit/types';
import { QuestionResponseSectionEntity } from '../../types/audit-question.types';
import { useControlLayer } from './useControlLayer';
import TaskPopover from './TaskPopover';
import TableWrapper from './TableWrapper';
import { useHistory } from 'react-router-dom';

interface QuestionResponseTableProps {
  auditData: SessionDetailEntity | undefined;
  questionSectionData: QuestionResponseSectionEntity[];
  selectedQuestionId: string;
  refetchHandler?: () => void;
}

const QuestionResponseSection: FC<QuestionResponseTableProps> = ({
  auditData,
  questionSectionData,
  selectedQuestionId,
  refetchHandler,
}) => {
  const history = useHistory();
  const { LocationDrawerComponent, createTaskHandler } = useControlLayer(
    auditData,
    refetchHandler
  );

  const redirectTask = (taskId: string, sessionId: string) => {
    history.push(`/tasks/supervised/details/${taskId}/${sessionId}`, {
      backToTitle: 'Back to Question wise',
    });
  };

  useEffect(() => {
    if (selectedQuestionId) {
      const el = document.getElementById(`question_${selectedQuestionId}`);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [selectedQuestionId]);

  return (
    <Flex width='full' flexDir='column' gap={4}>
      {questionSectionData?.map((_question, index) => (
        <Flex
          id={`question_${_question.questionId}`}
          border='.5px solid #C7C7C7'
          borderRadius='10px'
          p='26px 20px'
          flexDir='column'
          gap={3}
          key={_question.questionId}
        >
          <Flex w='full' justify='space-between' align='center'>
            <Box fontWeight={600} fontSize='18px'>
              Question {index + 1}
            </Box>
            <TaskPopover
              _question={_question}
              createTaskHandler={createTaskHandler}
              redirectTask={redirectTask}
            />
          </Flex>
          <Box fontSize='15px' fontWeight='500'>
            {_question?.questionText}
          </Box>
          <TableWrapper tableData={_question} />
        </Flex>
      ))}
      <LocationDrawerComponent />
    </Flex>
  );
};

QuestionResponseSection.displayName =
  'displayName:pages/Audits/Detail/components/QuestionSection/components/QuestionResponseSection/QuestionResponseSection';

export default QuestionResponseSection;
