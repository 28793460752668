import { Box, Checkbox, Flex } from '@chakra-ui/react';
import { useUserDataSelector } from 'hooks';
import { FC } from 'react';
import { AuthRole } from 'sop-commons/src/client';
import { filterFilterCopiesByAuthRole } from 'sub-components/NotificationCenter/helper';
import { IFilters } from '../../types';

interface IProps extends IFilters {}

const ModuleFilterContent: FC<IProps> = ({
  checkedModules,
  handleModuleSelect,
}) => {
  const { loggedinUserAuthRole } = useUserDataSelector((state) => ({
    loggedinUserAuthRole: state.authRole,
  }));
  return (
    <Box
      padding={'12px 16px'}
      borderRadius={'12px'}
      backgroundColor={'#FCFCFC'}
      border={'1px solid #EFEFEF'}
    >
      <Flex flexDir={'column'} columnGap={2}>
        {filterFilterCopiesByAuthRole(loggedinUserAuthRole, checkedModules).map(
          (item) => {
            return (
              <Flex
                key={item?.eid}
                _hover={{ bgColor: '#F4F4F4', color: '#1A1D1F' }}
                cursor={'pointer'}
                borderRadius={'12px'}
                fontSize={'16px'}
                p={'12px'}
                color={'#6F767E'}
                fontWeight={600}
                justify={'space-between'}
              >
                {item.label}
                <Checkbox
                  isChecked={item?.checked}
                  size={'lg'}
                  borderRadius={'lg'}
                  value={item.value}
                  onChange={(e) => handleModuleSelect(e, item)}
                />
              </Flex>
            );
          }
        )}
      </Flex>
    </Box>
  );
};
ModuleFilterContent.displayName = 'sc/NC/D/c/f/m/ModuleFilterContent.tsx';
export default ModuleFilterContent;
