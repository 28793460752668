// import scss
import './Header.scss';
import { FC, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';

import {
  gql,
  makeVar,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import { useHistory } from 'react-router';
import { Box, Flex, useDisclosure, useToast } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import SearchModel from '../SearchModal/index';
import PrimaryButton from 'atoms/PrimaryButton';

import { ReactComponent as HomeIcon } from '../../assets/images/homeIcon.svg';
import { ReactComponent as MessageIcon } from '../../assets/images/messageIcon.svg';
import { ReactComponent as HelpIcon } from '../../assets/images/helpIcon.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/searchIcon.svg';
import { ReactComponent as ToolIcon } from '../../assets/images/toolIcon.svg';
import { ReactComponent as NotificationIcon } from '../../assets/images/NotificationIcon.svg';

import { roleObj } from '../../ui-components/DashboardMenu';

import { userObj } from 'sop-commons/src/client/clientFactory';

import Responsive from '../../responsive/index';

import InviteUserModal from '../../ui-components/InviteUserModal';
import { CHAT_URL, HOME_URL, SETTINGS_URL } from 'appRoutes';
import NotificationCount from 'ui-components/NotificationCount';
import TrainingPathModal from '../AddNewPathModal/TrainingModal';

import ProfilePopup from '../ProfilePopup';
import {
  useChatMessageCount,
  useLogout,
  useUserDataSelector,
  useUserEntity,
} from '../../hooks';
import { GET_USER } from '../../pages/Login/login.graphql';
import { deployEvent } from 'shared/amplitudeEvents/AmplitudeEvents';
import { AmplitudeEventNames } from 'shared/amplitudeEvents/amplitude-events-types';
import { Authorize, AuthRole } from '../../authorization';
import GetDelightreeAccount from './GetDelightreeAccount';
import { getAvatar, shallowEqual } from 'utils';
import { useTranslation } from 'react-i18next';
import LanguageDropdown from 'sub-components/LanguageDropdown';
import { ILocaleEntity } from 'ui-components/LanguageSetting/language-setting.types';
import {
  UpdateResponse,
  UPDATE_LANGUAGE_PREFERENCE,
} from 'ui-components/LanguageSetting/language-setting.graphql';
import i18next from 'i18next';
import { useConfirm } from 'ui-components/Confirm';
import LocalizationLoadingIcon from '../../assets/images/localization loading.svg';
import PreviewHeader from 'sub-components/forms/component/PreviewHeader';
import {
  confirmObject,
  loadingObject,
} from 'ui-components/LanguageSetting/language-object';
import { localizationEnabled } from '../../utils/constants';
import { useInviteUser } from 'ui-components/InviteUserNew/useInviteUser';
import { GET_USERS } from 'pages/TasksDashboard/SupervisedTasks/services/supervised-tasks.graphql';
import {
  getLoggedInUserDataHandler,
  getUsersEntityHandler,
} from 'shared/graphql/SharedGraphql';
import {
  UnreadNotificationsCount,
  UserEntityData,
} from 'shared/graphql/shared-types';
import { LocationsSelect } from 'pages/Home/components';
import HeaderSearchInput from './search/HeaderSearchInput';
import { faBell } from '@fortawesome/pro-light-svg-icons';
import { BellIcon } from '@chakra-ui/icons';
import { IntroToNotificationConfigFeature } from 'sub-components/NotificationCenter/States';
import { NotificationsSummaryDrawer } from 'sub-components/NotificationCenter/Summary';
import {
  checkFirstTimeFlag,
  setFirstTimeFlag,
} from 'sub-components/NotificationCenter/helper';
import useCombinedStore from 'zustandStore/store';

export const GET_BRANCH = gql`
  query S_C_H_Branch {
    branch {
      descendantUsers {
        eid
        name
      }
      descendantBranches {
        name
        eid
        type
        role
        authRole
        email
        createdAt
        status
        isRoot
      }
      root {
        name
        eid
      }
    }
  }
`;

export const GET_UNREAD_NOTIFICATIONS = gql`
  query Query {
    UnseenNotificationCount
  }
`;

export const loginUserBranchObj = makeVar([]);
export const loginUserBranchRootObj = makeVar([]);
export const usersEntityObj = makeVar([] as UserEntityData[]); //setting value in the file "SharedGraphql.tsx"

interface IProps {
  refetchUsers?: () => void;
  refetchLocations?: () => void;
}

const Header: FC<IProps> = ({ refetchUsers, refetchLocations }) => {
  const { t } = useTranslation(['common', 'header', 'setting']);
  const {
    isOpen: isFirstTimeStateOpen,
    onOpen: openFirstTimeState,
    onClose: closeFirstTimeState,
  } = useDisclosure();
  const {
    isOpen: isSummaryDrawerOpen,
    onClose: closeSummaryDrawer,
    onOpen: openSummaryDrawer,
  } = useDisclosure();
  const { unseenCount, updateUnseenCount } = useCombinedStore();
  const toast = useToast();
  // const { execute: executeGetUserData, data: loggedInUserData } =
  //   getLoggedInUserDataHandler();
  // const { execute: executeGetEntityUsersData, data: entityUsersData } =
  //   getUsersEntityHandler();
  const logoutCurrentUser = useLogout();
  const chatMessageCount = useChatMessageCount();
  const pathname = useLocation().pathname;
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const [isTrainingOpen, setIsTrainingOpen] = useState(false);
  const [openNotificationDrawer, setOpenNotificationDrawer] =
    useState<boolean>(false);
  const confirm = useConfirm();
  const params = useRouteMatch();
  const confirmRef = useRef<ReturnType<typeof confirm>>();
  const loadingRef = useRef<ReturnType<typeof confirm>>();

  const isChatEnabled = useUserEntity((entity) => entity?.features?.chat);

  const [isSearchModalOpened, setIsSearchModalOpened] = useState(false);

  const history = useHistory();
  const userObject = useReactiveVar(userObj);
  const loggedInUserLocations = useUserDataSelector(
    (state) => state.locations || [],
    shallowEqual
  );
  const loggedInUserProfilePic = useUserDataSelector(
    (state) => state.profilePic
  );

  const { data } = useQuery(GET_BRANCH, {
    nextFetchPolicy: 'cache-only',
  });

  const [getUnseenNotificationCount] = useLazyQuery(GET_UNREAD_NOTIFICATIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      updateUnseenCount(data?.UnseenNotificationCount);
    },
    onError: (err) => {
      console.log('Error while fetching notification read count --- ', err);
    },
  });

  useEffect(() => {
    if (pathname === '/') {
      getUnseenNotificationCount();
    }
  }, [pathname]);

  // useEffect(() => {
  //   executeGetUserData();
  // }, []);

  // useEffect(() => {
  //   if (loggedInUserData?.user?.entityId) {
  //     // userObj({ ...userObject, ...loggedInUserData?.user });
  //     // const roles = loggedInUserData?.user?.entity?.roles;
  //     // if (roles && roles.length) {
  //     //   let roleWithColor: any = {};
  //     //   roles.forEach((role: any) => {
  //     //     roleWithColor[role.name] = role.color;
  //     //   });

  //     //   roleObj(roleWithColor);
  //     // }
  //     executeGetEntityUsersData({
  //       variables: {
  //         entityId: loggedInUserData?.user?.entityId,
  //       },
  //     });
  //   }
  // }, [loggedInUserData]);

  // useEffect(() => {
  //   if (entityUsersData && entityUsersData?.EntityUser?.length > 0) {
  //     usersEntityObj(entityUsersData?.EntityUser);
  //   }
  // }, [entityUsersData]);

  // const [getUserDataHandler, { data: getUserData }] = useLazyQuery(GET_USER, {
  //   fetchPolicy: 'network-only',
  //   onCompleted: () => {
  //     userObj({ ...userObject, ...getUserData?.user });
  //     const roles = getUserData?.user?.entity?.roles;
  //     if (roles && roles.length) {
  //       let roleWithColor: any = {};
  //       roles.forEach((role: any) => {
  //         roleWithColor[role.name] = role.color;
  //       });

  //       roleObj(roleWithColor);
  //     }
  //     if (getUserData && getUserData.user && getUserData?.user?.entityId) {
  //       getUsersEntityHandler({
  //         variables: {
  //           entityId: getUserData?.user?.entityId,
  //         },
  //       });
  //     }
  //   },
  // });

  const onActionCompleted = (pathName: string, tabSelected: string) => {
    history.push({ pathname: pathName, state: { tabSelected } });
  };

  const { openInviteUserModal } = useInviteUser(onActionCompleted);

  const [updateLanguagePreference] = useMutation<UpdateResponse>(
    UPDATE_LANGUAGE_PREFERENCE,
    {
      onCompleted: async (data) => {
        toast({
          title: t('setting:languageUpdateSuccessToast'),
          status: 'success',
          position: 'top-right',
          duration: 3000,
        });
        await i18next.changeLanguage(data.updateUser.preferredLanguage);
        loadingRef?.current?.destroy?.();
      },
      onError: (error) => {
        toast({
          title: t('setting:languageUpdateErrorToast'),
          status: 'error',
          position: 'top-right',
          duration: 3000,
        });
        loadingRef?.current?.destroy?.();
      },
    }
  );

  useEffect(() => {
    if (data?.branch?.descendantBranches?.length) {
      loginUserBranchObj(data?.branch?.descendantBranches);
    }
    if (data?.branch) {
      loginUserBranchRootObj(data?.branch);
    }
  }, [data?.branch?.descendantBranches]);

  const onClearData = async () => {
    await logoutCurrentUser();
    history.replace('/');
    window.location.reload();
  };

  const onYesHandler = async (code = 'en') => {
    confirmRef?.current?.destroy?.();
    loadingModalHandler();
    await updateLanguagePreference({
      variables: {
        input: {
          preferredLanguage: code,
        },
      },
    });
  };

  const onCloseBtnClickHandler = () => {
    confirmRef.current?.destroy?.();
  };

  const onApplyChanges = (languageCode: string = 'en') => {
    confirmRef.current = confirm(
      confirmObject(
        onYesHandler,
        onCloseBtnClickHandler,
        t('setting:languageChangeModalHeading'),
        t('setting:updateLanguageAgree'),
        t('common:no'),
        t('common:yes'),
        languageCode
      )
    );
  };

  const loadingModalHandler = () => {
    loadingRef.current = confirm(
      loadingObject(t('setting:updatingLanguageProgress'))
    );
  };

  const onOpenInviteModal = () => {
    setIsInviteOpen(true);
    deployEvent(AmplitudeEventNames.HOME_INVITE_BUTTON_CLICK);
  };

  const onCloseInviteModal = () => {
    setIsInviteOpen(false);
  };

  const onOpenTrainingModal = () => {
    setIsTrainingOpen(true);
  };

  const onCloseTrainingModal = () => {
    setIsTrainingOpen(false);
  };

  const onOpenSearchModal = () => {
    setIsSearchModalOpened(true);
  };

  const onCloseSearchModal = () => {
    setIsSearchModalOpened(false);
  };

  const getClassName = (url: string) => {
    if (pathname === url) {
      return 'header-icon-container selected';
    } else {
      return 'header-icon-container';
    }
  };

  const languageUpdateHandler = (prefer: ILocaleEntity) => {
    console.log('LANGUAGE UPDATE HANDLER : ', prefer);
    onApplyChanges(prefer?.languageCode);
    // onYesHandler(prefer?.languageCode);
  };
  const firstTimeFeatureHandler = () => {
    //permanent solution ???
    if (!checkFirstTimeFlag()) {
      setOpenNotificationDrawer(true);
      openFirstTimeState();
      setFirstTimeFlag(true);
    } else {
      setOpenNotificationDrawer(true);
      openSummaryDrawer();
    }
  };

  return (
    <div className='desktop-header-container'>
      <Responsive isBigScreen isDesktop isSmallDesktop isTablet>
        <div style={{ flex: '1 1 auto' }}>
          <Responsive isBigScreen isDesktop isSmallDesktop>
            <HeaderSearchInput
              onClick={onOpenSearchModal}
              placeholder={t('header:search_placeholder')}
            />
          </Responsive>
        </div>

        <Flex align='center'>
          <Flex align='center' gap='12px' px='12px'>
            <Authorize canAccess={(authUser) => authUser?.entity?.isDemo}>
              <GetDelightreeAccount />
            </Authorize>
            {loggedInUserLocations?.length > 0 &&
              params?.path === '/launcher' &&
              userObject?.entity?.features?.launcher &&
              loggedInUserLocations?.some((loc) => !loc?.onboarded) && (
                <Authorize
                  permittedFor={'user'}
                  permittedRoles={[AuthRole.LOCATION_OWNER]}
                >
                  <Flex gap='20px'>
                    <LocationsSelect />
                    <Box h='30px' w='.5px' bg='#C9D0D8' />
                  </Flex>
                </Authorize>
              )}
            <Authorize permittedFor='user' restrictedRoles={AuthRole.WORKER}>
              <PrimaryButton
                leftIcon={
                  <FontAwesomeIcon
                    icon={faPlus as IconProp}
                    style={{ fontSize: 16 }}
                    color={'#fff'}
                  />
                }
                width='auto'
                title={t('invite')}
                type='button'
                variant='solid'
                colorScheme='blue'
                onClick={openInviteUserModal}
              />
              {/* <PrimaryButton
                leftIcon={
                  <FontAwesomeIcon
                    icon={faPlus as IconProp}
                    style={{ fontSize: 16 }}
                    color={'#fff'}
                  />
                }
                width='auto'
                title={t('invite')}
                type='button'
                variant='solid'
                colorScheme='blue'
                onClick={onOpenInviteModal}
              /> */}
            </Authorize>
          </Flex>
          <div className='header-icon-container'>
            <NotificationIcon
              onClick={firstTimeFeatureHandler}
              cursor={'pointer'}
            />

            {!!unseenCount && <div className='has-message' />}
          </div>
          {
            <>
              {openNotificationDrawer && (
                <IntroToNotificationConfigFeature
                  isFirstTimeStateOpen={isFirstTimeStateOpen}
                  closeFirstTimeState={closeFirstTimeState}
                  openSummaryDrawer={openSummaryDrawer}
                  isSummaryDrawerOpen={isSummaryDrawerOpen}
                  closeSummaryDrawer={closeSummaryDrawer}
                  openNotificationDrawer={openNotificationDrawer}
                />
              )}
              {openNotificationDrawer && (
                <NotificationsSummaryDrawer
                  isSummaryDrawerOpen={isSummaryDrawerOpen}
                  closeSummaryDrawer={closeSummaryDrawer}
                />
              )}
            </>
          }
          <Authorize canAccess={() => localizationEnabled}>
            <LanguageDropdown
              highlightSelectedText={false}
              showCircle={false}
              onClickHandler={(prefer: ILocaleEntity) =>
                languageUpdateHandler(prefer)
              }
            />
          </Authorize>

          {((isChatEnabled &&
            userObject?.entity?.chatConfig?.restrictions &&
            [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(
              userObject?.authRole
            )) ||
            !userObject?.entity?.chatConfig?.restrictions) && (
            <Link to={CHAT_URL}>
              <div className='header-icon-container'>
                <MessageIcon />
                {!!chatMessageCount && <div className='has-message' />}
              </div>
            </Link>
          )}

          {/* <div className='header-icon-container'>
            <HelpIcon style={{ cursor: 'pointer' }} id='my_custom_link' />
          </div> */}
          <Responsive isTablet>
            <div className='header-icon-container'>
              <SearchIcon />
            </div>
          </Responsive>
          <ProfilePopup logout={onClearData}>
            <div
              className='eventclass-header-profile-pic'
              style={{
                width: 48,
                height: 48,
                marginLeft: '10px',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              // onClick={onClearData}
            >
              <img
                src={getAvatar({
                  name: 'auth-user-profile',
                  url: loggedInUserProfilePic,
                })}
                className='profile-pic'
                width={48}
                height={48}
                // style={{ maxHeight: '48px', minWidth: '48px' }}
                alt='profile-image'
              />
            </div>
          </ProfilePopup>
        </Flex>
      </Responsive>
      <Responsive isMobile>
        <Link to={HOME_URL}>
          <div className={getClassName(HOME_URL)}>
            <HomeIcon />
          </div>
        </Link>

        <div className={getClassName('')} onClick={onOpenSearchModal}>
          <SearchIcon />
        </div>
        {isChatEnabled && (
          <Link to={CHAT_URL}>
            <div className={getClassName(CHAT_URL)}>
              <MessageIcon />
              {!!chatMessageCount && (
                <NotificationCount notificationNumber={chatMessageCount} />
              )}
            </div>
          </Link>
        )}

        <Link to={SETTINGS_URL}>
          <div className={getClassName(SETTINGS_URL)}>
            <ToolIcon />
          </div>
        </Link>
        <ProfilePopup logout={onClearData}>
          <div
            className='eventclass-header-profile-pic'
            style={{
              width: 40,
              height: 40,
              marginLeft: '10px',
              alignItems: 'center',
            }}
          >
            <img
              src={getAvatar({
                name: 'auth-user-profile',
                url: loggedInUserProfilePic,
              })}
              className='profile-pic'
              width={40}
              height={40}
              style={{ maxHeight: '40px', minWidth: '40px' }}
              alt=''
            />
          </div>
        </ProfilePopup>
      </Responsive>

      {userObject?.authRole !== AuthRole.WORKER && (
        <>
          {isInviteOpen && (
            <InviteUserModal
              open={isInviteOpen}
              onClose={onCloseInviteModal}
              pendingUserRefetch={refetchUsers}
              refetchLocations={refetchLocations}
            />
          )}
          {isTrainingOpen && (
            <TrainingPathModal
              open={isTrainingOpen}
              onClose={onCloseTrainingModal}
            />
          )}
        </>
      )}
      {isSearchModalOpened && (
        <SearchModel
          isOpen={isSearchModalOpened}
          onClose={onCloseSearchModal}
        />
      )}
    </div>
  );
};

export default Header;
Header.displayName = 'sub-components/Header/index';
