import { FC } from 'react';
import { Box, Button, Text, Flex, Badge, Tooltip } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { formatDateToCustom } from 'sub-components/Launcher/dashboard/helper';
import { useHistory } from 'react-router-dom';

interface CardProps {
  card: {
    id: number;
    title: string;
    description?: string;
    status: 'Ready to Go Live' | 'Lagging' | 'Last Phase';
    date: string;
    badge: string;
    phaseName: string;
    days: string;
    liveDate: string;
    pendingTasksCount?: string;
    launchId: string;
  };
  locMakeLiveHandler: () => void;
}

const Card: FC<CardProps> = ({ card, locMakeLiveHandler }) => {
  const history = useHistory();
  // console.log("card", card);

  //card type rendering
  const renderContent = () => {
    switch (card.status) {
      case 'Ready to Go Live':
        return (
          <>
            {/* @ts-ignore  */}
            <Flex justifyContent='space-between' alignItems='center'>
              <Flex direction='column'>
                <Tooltip
                  label={card.title}
                  isDisabled={card.title.length <= 20}
                >
                  <Text
                    fontSize='15px'
                    fontWeight='700'
                    mb='4px'
                    isTruncated
                    maxWidth='150px'
                  >
                    {card.title}
                  </Text>
                </Tooltip>
                <Text fontSize='12px' color='#6F7E7C'>
                  Completed on {card.date ? formatDateToCustom(card.date) : '-'}
                </Text>
              </Flex>
              <Button
                aria-label='Make live'
                colorScheme='blue'
                size='sm'
                onClick={() => locMakeLiveHandler()}
              >
                Make live
              </Button>
            </Flex>
          </>
        );

      case 'Lagging':
        return (
          <>
            <Flex justifyContent='space-between'>
              <Flex direction='column' gap={2} flex='1'>
                <Text fontSize='15px' fontWeight='700' mb='4px'>
                  {card.title}
                </Text>
                <Flex
                  fontSize='12px'
                  fontWeight='500'
                  gap={2}
                  align='center'
                  wrap='nowrap'
                >
                  <Badge
                    size='lg'
                    variant='solid'
                    borderRadius='6px'
                    bgColor={HeaderColors.Red.Mid}
                    textTransform='none'
                    py={1}
                    px={2}
                    fontWeight='500'
                    flexShrink={0}
                  >
                    {card.badge}
                  </Badge>
                  <Text
                    color={HeaderColors.Red.Mid}
                    fontSize='12px'
                    fontWeight={600}
                    flex='1'
                    whiteSpace='nowrap'
                    overflow='hidden'
                    textOverflow='ellipsis'
                  >
                    Late by {card.days} days
                  </Text>
                </Flex>
                <Text
                  fontSize='14px'
                  fontWeight='600'
                  color={HeaderColors.Black}
                >
                  {card?.phaseName}
                </Text>
              </Flex>
              <Button
                aria-label='Action button'
                bg={HeaderColors.White}
                size='xs'
                shadow='sm'
                borderRadius='4px'
                onClick={() =>
                  history.push(`/launcher/location/${card.launchId}`)
                }
              >
                <ChevronRightIcon />
              </Button>
            </Flex>
          </>
        );

      case 'Last Phase':
        return (
          <>
            <Flex justifyContent='space-between'>
              <Flex direction='column' gap={'1'} flex='1'>
                <Text fontSize='15px' fontWeight='700' mb='4px'>
                  {card.title}
                </Text>
                <Flex gap={2} align='center' wrap='nowrap'>
                  <Badge
                    size='lg'
                    variant='solid'
                    borderRadius='6px'
                    bgColor={HeaderColors.GreenDark}
                    textTransform='none'
                    py={1}
                    px={2}
                    fontWeight='500'
                    textColor={HeaderColors.Black}
                    flexShrink={0}
                  >
                    {card.badge}
                  </Badge>
                  <Text
                    color={HeaderColors.Red.Mid}
                    fontSize='12px'
                    fontWeight={600}
                    flex='1'
                    whiteSpace='nowrap'
                    overflow='hidden'
                    textOverflow='ellipsis'
                  >
                    {card.pendingTasksCount &&
                    Number(card?.pendingTasksCount) > 1
                      ? `${card.pendingTasksCount} tasks left!`
                      : ``}
                  </Text>
                </Flex>
                <Text
                  fontSize='14px'
                  fontWeight='600'
                  color={HeaderColors.Black}
                >
                  {card.phaseName}
                </Text>
                <Text
                  fontSize='13px'
                  fontWeight='500'
                  color={HeaderColors.DarkBlue}
                >
                  {`Expected Go-Live: ${formatDateToCustom(card.liveDate)}` ||
                    ''}
                </Text>
              </Flex>
              <Button
                aria-label='Action button'
                bg={HeaderColors.White}
                size='xs'
                shadow='sm'
                borderRadius='4px'
                onClick={() =>
                  history.push(`/launcher/location/${card.launchId}`)
                }
              >
                <ChevronRightIcon />
              </Button>
            </Flex>
          </>
        );

      default:
        return <Text>Unknown status</Text>;
    }
  };

  return (
    <Box
      p='20px'
      borderRadius='12px'
      bg='#EFEFEF'
      width='314px'
      minWidth='314px'
      boxShadow='sm'
      cursor={'pointer'}
    >
      {renderContent()}
    </Box>
  );
};

export default Card;
