import { AddIcon } from '@chakra-ui/icons';
import { Image, Stack, Box, Text, Flex } from '@chakra-ui/react';
import { Authorize, AuthRole } from 'authorization';
import React, { FC } from 'react';
import { ActionButton } from 'ui-components';
import EmptyCompliance from '../../../assets/images/empty-state/empty-compliance-state.svg';
import { useComplianceUpload } from './Create/components';

interface IProps {
  userAuthRole: string;
}

const EmptyStateCompliance: FC<IProps> = ({ userAuthRole }) => {
  const complianceUpload = useComplianceUpload();

  let title = 'Add your documents';
  let description = 'No documents available for any location';

  if (
    userAuthRole === AuthRole?.LOCATION_OWNER ||
    userAuthRole === AuthRole?.WORKER
  ) {
    title = 'No document added for your location(s)';
    description = 'You will see the added documents here';
  }

  return (
    <Flex
      minH={'80vh'}
      flexDir={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Stack gap={2} align={'center'}>
        <Box justifyContent={'center'} alignItems={'center'}>
          <Image src={EmptyCompliance} gap='10px' w={'310px'} h={'190px'} />
        </Box>
        <Stack gap={'15px'} alignItems={'center'}>
          <Stack textAlign={'center'} gap={'4px'}>
            <Text
              fontWeight={600}
              fontSize={'20px'}
              color={'#303030'}
              lineHeight={'32px'}
              letterSpacing={'wide'}
            >
              {title}
            </Text>
            <Text
              fontWeight={500}
              fontSize={'12px'}
              color={'#6F767E'}
              lineHeight={'20px'}
              letterSpacing={'normal'}
            >
              {description}
            </Text>
          </Stack>
          <Authorize
            permittedFor={'user'}
            permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
          >
            <ActionButton
              colorScheme='blue'
              leftIcon={<AddIcon />}
              actionFn={() => complianceUpload()}
              borderRadius={'12px'}
              p={'8px 16px'}
            >
              Add doc
            </ActionButton>
          </Authorize>
        </Stack>
      </Stack>
    </Flex>
  );
};

EmptyStateCompliance.displayName =
  'sub-components/nexus/Compliance/EmptyStateCompliance';

export default EmptyStateCompliance;
