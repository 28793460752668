import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiagramSubtask } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Trans, useTranslation } from 'react-i18next';
import { TriggerEntity } from '../../../common/launcher-config.fragment';
import { getDuration } from '../../../common/trigger/duration.helper';

interface IProps {
  stepsCount: number;
  category?: string;
  trigger?: TriggerEntity | null;
}

const LauncherTaskFooter: FC<IProps> = ({ stepsCount, category, trigger }) => {
  const { t } = useTranslation(['launcher']);
  const { durationValue, durationUnit } = getDuration(trigger);

  return (
    <Flex fontSize='12px' userSelect='none' align='center' gap={2} minH='28px'>
      <Box fontWeight='600' color='#8E59FF'>
        {category}
      </Box>

      {/*<Box boxSize='4px' borderRadius='full' bg='#DBD2FE' />*/}
      {/*<Box fontWeight='600' color='#8E59FF' textTransform='capitalize'>*/}
      {/*  <Trans*/}
      {/*    t={t}*/}
      {/*    i18nKey='launcher:noOfDay'*/}
      {/*    count={duration}*/}
      {/*    values={{ value: duration }}*/}
      {/*  />*/}
      {/*</Box>*/}

      {durationUnit ? (
        <>
          <Box boxSize='4px' borderRadius='full' bg='#DBD2FE' />
          <Box fontWeight='600' color='#8E59FF' textTransform='capitalize'>
            <Trans
              t={t}
              i18nKey='launcher:configDuration'
              count={durationValue}
              context={durationUnit}
              values={{ value: durationValue }}
            />
          </Box>
        </>
      ) : null}

      {stepsCount ? (
        <>
          <Box boxSize='4px' borderRadius='full' bg='#DBD2FE' />
          <Flex align='center' gap={2} color='#33383F'>
            <FontAwesomeIcon icon={faDiagramSubtask as IconProp} />
            <span>{stepsCount}</span>
          </Flex>
        </>
      ) : null}
    </Flex>
  );
};

export default LauncherTaskFooter;
