/* eslint-disable react/no-unescaped-entities */
// @ts-ignore
import React, { FC, useState } from 'react';
import {
  FormControl,
  PinInput,
  PinInputField,
  HStack,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';
// import scss
import './OtpResetPassword.scss';

//import components
import AuthContainer from '../../sub-components/AuthContainer';
import PrimaryButton from '../../atoms/PrimaryButton';
import { useRouter, EmailEncoder } from '../../utils';

const FORGOT_PASSWORD_REQUEST = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email) {
      succeed
    }
  }
`;

const VERIFY_RESET_PASSWORD_OTP = gql`
  mutation VerifyResetPasswordOtp($otp: String!, $email: String!) {
    verifyResetPasswordOtp(otp: $otp, email: $email) {
      token
    }
  }
`;

const OtpResetPassword: FC = () => {
  const { t } = useTranslation(['common', 'auth']);
  const history = useHistory();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isCodeResent, setCodeResent] = useState(false);
  const [error, setError] = useState<any>();
  const [pinValue, setPinValue] = useState('');
  const router = useRouter();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email') || '';
  const encodedEmail = EmailEncoder.encode(email);
  const decodedEmail = EmailEncoder.decode(encodedEmail);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const [resetPassword] = useMutation(FORGOT_PASSWORD_REQUEST, {
    onError: (error) => {
      setIsLoading(false);
      setError(error);
    },
  });

  const [verifyResetPasswordOtp] = useMutation(VERIFY_RESET_PASSWORD_OTP, {
    onCompleted: (response) => {
      if (response?.verifyResetPasswordOtp?.token) {
        setIsLoading(false);
        const token = response?.verifyResetPasswordOtp?.token;
        history.push(`/reset-password?auth=${token}`);
      }
    },
    onError: (error) => {
      setIsLoading(false);
      setError(error);
    },
  });
  const getErrorMessage = () => {
    if (error?.message) {
      return decodeURIComponent(error?.message);
    }
    return '';
  };

  const onFinish = (values: any) => {
    setError(null);

    setIsLoading(true);
    verifyResetPasswordOtp({
      variables: {
        otp: pinValue,
        email: decodedEmail,
      },
    });
  };

  const onResendCode = () => {
    if (decodedEmail) {
      resetPassword({
        variables: {
          email: decodedEmail,
        },
      }).then((data) => {
        setCodeResent(true);
        toast({
          title: t('auth:code_send'),
          position: 'top',
          isClosable: true,
        });
      });
    }
  };

  const getInputClassname = () => {
    if (error?.message) {
      return `otp-input-container error`;
    }
    return `otp-input-container`;
  };
  return (
    <AuthContainer>
      <div>
        <div className='login-heading-container'>
          {t('auth:forgot_password')}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            className='login-sub-heading-container'
            style={{ width: '302px', marginBottom: '20px' }}
          >
            {t('auth:verification_sent')}
          </div>
        </div>
        <div className='login-form-parent-container'>
          <form onSubmit={handleSubmit(onFinish)}>
            <FormControl isInvalid={!!errors?.email?.message}>
              <HStack>
                <PinInput
                  size='lg'
                  placeholder=''
                  value={pinValue}
                  onChange={(value) => {
                    setError(null);
                    setPinValue(value);
                  }}
                  onComplete={() => {}}
                >
                  <PinInputField className={getInputClassname()} />
                  <PinInputField className={getInputClassname()} />
                  <PinInputField className={getInputClassname()} />
                  <PinInputField className={getInputClassname()} />
                </PinInput>
              </HStack>
            </FormControl>
            <div className='login-error-message'>{getErrorMessage()}</div>
            <PrimaryButton
              mt={4}
              type='submit'
              size='lg'
              isLoading={isLoading}
              disabled={pinValue.length !== 4}
              // @ts-ignore
              title={t('continue', {
                defaultValue: 'Continue',
              })}
              variant='solid'
              colorScheme='blue'
            />
          </form>
          <div className='otp-resend-container '>
            <Trans
              t={t}
              i18nKey='auth:reset_not_received'
              components={[
                <span
                  key='resend'
                  style={{ color: '#2a85ff', cursor: 'pointer' }}
                  onClick={onResendCode}
                />,
              ]}
            />
          </div>

          {isCodeResent && (
            <div className='otp-sent-container' style={{ marginTop: '5px' }}>
              <Trans
                t={t}
                i18nKey='auth:resent_code'
                values={{ value: decodedEmail }}
              />
            </div>
          )}
        </div>
      </div>
      <div className='invite-already-member' />
    </AuthContainer>
  );
};

OtpResetPassword.displayName =
  'displayName:packages/pages/OtpResetPassword/index';

export default OtpResetPassword;
