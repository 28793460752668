import React, { FC, useMemo, useState } from 'react';
import { Center, Flex, List } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

import { AuthRole } from 'authorization';
import { PrimaryButton, SearchInput } from 'atoms';
import { toArray } from 'utils/utils';
import { BoxHeader } from 'ui-components';

import EmptyState from 'sub-components/EmptyState';

import { ILocationDetails } from 'pages/LocationDetails/utils/location-details.types';
import OwnerMemberItem from './OwnerMemberItem';
import SingleUserChatModal from 'ui-components/SingleUserChatModal';
import InviteUserModal from 'ui-components/InviteUserNew/InviteUserModal';
import { useUserDetailModal } from '../../../../UserDetailModal/useUserDetailModal';
import LocationOwnerMenu from './components/LocationOwnerMenu';
import { ApolloQueryResult, useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';

interface IProps {
  locationMembers?: ILocationDetails['members'];
  locationId?: string;
  locationName?: string;
  locationStatus?: string;
  refetch:
    | ((
        variables?:
          | Partial<{
              eid: string;
            }>
          | undefined
      ) => Promise<
        ApolloQueryResult<{
          userById: ILocationDetails;
        }>
      >)
    | undefined;
}

const OwnersMembers: FC<IProps> = ({
  locationMembers = [],
  locationId,
  locationName,
  refetch,
  locationStatus,
}) => {
  const { t } = useTranslation(['location']);
  const userData = useReactiveVar(userObj);
  const [locationMembersQuery, setLocationMembersQuery] = useState('');
  const [selectedMemberId, setSelectedMemberId] = useState('');
  const [openInvite, setOpenInvite] = useState(false);
  const [inviteType, setInviteType] = useState<
    'locationOwner' | 'member' | undefined
  >(undefined);

  const membersLength = toArray(locationMembers)?.length;

  const { owners, members } = useMemo(() => {
    return toArray(locationMembers).reduce<
      Record<'owners' | 'members', ILocationDetails['members']>
    >(
      (previousValue, currentValue) => {
        if (
          currentValue?.status === 'active' ||
          currentValue?.status === 'pending'
        ) {
          if (currentValue.authRole === AuthRole.LOCATION_OWNER) {
            previousValue['owners'].push(currentValue);
          } else {
            previousValue['members'].push(currentValue);
          }
        }

        return previousValue;
      },
      { owners: [], members: [] }
    );
  }, [locationMembers]);

  const locationMemberItems = useMemo(() => {
    if (!locationMembersQuery) {
      return members;
    } else {
      return members.filter((member) =>
        member?.name
          ?.trim()
          ?.toLowerCase()
          ?.includes(locationMembersQuery?.trim()?.toLowerCase())
      );
    }
  }, [members, locationMembersQuery]);

  const userDetailModal = useUserDetailModal();

  const onUserClick = (value: ILocationDetails['members'][number]) => {
    userDetailModal({
      data: value,
      onSendMessage: (it) => {
        setSelectedMemberId(it.eid);
      },
    });
  };

  const onAddLOClick = () => {
    setInviteType('locationOwner');
    setOpenInvite(true);
  };

  const addButtonRender = () => {
    if (userData?.type === 'branch') {
      return false;
    }
    if ([AuthRole.WORKER]?.includes(userData?.authRole)) {
      return false;
    }
    return true;
  };

  return (
    <Flex flexDir='column' gap={4} p={2}>
      {membersLength > 0 ? (
        <Flex flexDir='column' gap={8}>
          {/**Owners panel */}
          <Flex flexDir='column' gap={4}>
            <Flex height='40px' justify='space-between' align='center'>
              <BoxHeader
                fontSize='16px'
                title={t('location:locationOwners')}
                color='#CABDFF'
              />
              {addButtonRender() && (
                <LocationOwnerMenu
                  eid={locationId}
                  onAddLOClick={onAddLOClick}
                  owners={owners}
                  refetch={refetch}
                />
              )}
            </Flex>
            {owners?.length > 0 ? (
              <List spacing={4}>
                {owners.map((member, index) => (
                  <OwnerMemberItem
                    key={index}
                    member={member}
                    onSendMessage={setSelectedMemberId}
                    onClick={onUserClick}
                  />
                ))}
              </List>
            ) : (
              <Center>
                <EmptyState
                  image='NoLocationOwner'
                  title={t('location:locationNoOwner')}
                  description={t('location:locationNoOwnerDescription')}
                />
              </Center>
            )}
          </Flex>
          {/** Members panel */}
          {locationStatus !== 'preLaunch' && (
            <Flex flexDir='column' gap={4}>
              <Flex height='40px' justify='space-between' align='center'>
                <BoxHeader
                  fontSize='16px'
                  title={t('location:locationMembers')}
                  color='#B1E5FC'
                />
                {addButtonRender() && (
                  <PrimaryButton
                    title='Add'
                    size='sm'
                    width='fit-content'
                    variant='outline'
                    colorScheme='blue'
                    leftIcon={<AddIcon />}
                    onClick={() => {
                      setInviteType('member');
                      setOpenInvite(true);
                    }}
                  />
                )}
              </Flex>
              <SearchInput
                placeholder='Search members'
                hideShortcuts
                value={locationMembersQuery}
                onChange={(e) => setLocationMembersQuery(e?.target?.value)}
              />
              {locationMemberItems?.length > 0 ? (
                <List spacing={4}>
                  {locationMemberItems.map((member, index) => (
                    <OwnerMemberItem
                      key={index}
                      member={member}
                      onSendMessage={setSelectedMemberId}
                      onClick={onUserClick}
                    />
                  ))}
                </List>
              ) : (
                <Center>
                  <EmptyState
                    image='NoLocationMember'
                    title={t('location:locationNoMember')}
                    description={t('location:locationNoMemberDescription')}
                  />
                </Center>
              )}
            </Flex>
          )}
        </Flex>
      ) : (
        <Center>
          {/* <EmptyState
            image='Search'
            title={t('location:locationHaveNoMembers')}
          /> */}
          <Flex flexDir='column' gap={4} p={2} width={'45vw'}>
            <Flex flexDir='column' gap={8}>
              {/** Empty owners */}
              <Flex flexDir='column' gap={4}>
                <Flex height='40px' justify='space-between' align='center'>
                  <BoxHeader
                    fontSize='16px'
                    title={t('location:locationOwners')}
                    color='#CABDFF'
                  />
                  {addButtonRender() && (
                    <LocationOwnerMenu
                      eid={locationId}
                      onAddLOClick={onAddLOClick}
                      owners={owners}
                      refetch={refetch}
                    />
                  )}
                </Flex>
                {owners?.length > 0 ? (
                  <List spacing={4}>
                    {owners.map((member, index) => (
                      <OwnerMemberItem
                        key={index}
                        member={member}
                        onSendMessage={setSelectedMemberId}
                        onClick={onUserClick}
                      />
                    ))}
                  </List>
                ) : (
                  <Center>
                    <EmptyState
                      image='NoLocationOwner'
                      title={t('location:locationNoOwner')}
                      description={t('location:locationNoOwnerDescription')}
                    />
                  </Center>
                )}
              </Flex>
              {/** Empty members */}
              {locationStatus !== 'preLaunch' && (
                <Flex flexDir='column' gap={4}>
                  <Flex height='40px' justify='space-between' align='center'>
                    <BoxHeader
                      fontSize='16px'
                      title={t('location:locationMembers')}
                      color='#B1E5FC'
                    />
                    {addButtonRender() && (
                      <PrimaryButton
                        title='Add'
                        size='sm'
                        width='fit-content'
                        variant='outline'
                        colorScheme='blue'
                        leftIcon={<AddIcon />}
                        onClick={() => {
                          setInviteType('member');
                          setOpenInvite(true);
                        }}
                      />
                    )}
                  </Flex>
                  {locationMemberItems?.length > 0 && (
                    <SearchInput
                      placeholder='Search members'
                      hideShortcuts
                      value={locationMembersQuery}
                      onChange={(e) =>
                        setLocationMembersQuery(e?.target?.value)
                      }
                    />
                  )}

                  {locationMemberItems?.length > 0 ? (
                    <List spacing={4}>
                      {locationMemberItems.map((member, index) => (
                        <OwnerMemberItem
                          key={index}
                          member={member}
                          onSendMessage={setSelectedMemberId}
                          onClick={onUserClick}
                        />
                      ))}
                    </List>
                  ) : (
                    <Center>
                      <EmptyState
                        image='NoLocationMember'
                        title={t('location:locationNoMember')}
                        description={t('location:locationNoMemberDescription')}
                      />
                    </Center>
                  )}
                </Flex>
              )}
            </Flex>
          </Flex>
        </Center>
      )}
      {selectedMemberId && (
        <SingleUserChatModal
          selectedUser={selectedMemberId}
          onClose={() => setSelectedMemberId('')}
        />
      )}
      {openInvite && (
        <InviteUserModal
          isOpen={openInvite}
          onClose={() => setOpenInvite(false)}
          onActionCompleted={() => {}}
          shouldRefetch={() => {
            setTimeout(() => {
              refetch && refetch();
            }, 5000);
          }}
          disableAllPermissions
          inviteOnlyFor={
            inviteType === 'locationOwner'
              ? AuthRole.LOCATION_OWNER
              : inviteType === 'member'
              ? AuthRole.WORKER
              : undefined
          }
          defaultValues={{
            location: [
              {
                id: locationId,
                value: locationName!,
                label: locationName!,
              },
            ],
          }}
          shouldDisableLocationSelect
          shouldPreventLocationRemoval
        />
      )}
    </Flex>
  );
};

export default OwnersMembers;
