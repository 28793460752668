import {
  Avatar,
  Box,
  Button,
  Collapse,
  Flex,
  IconProps,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarCheck,
  faClockEight,
} from '@fortawesome/pro-light-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { FC, useMemo } from 'react';
import { BoxHeader, Image } from 'ui-components';
import { ReactComponent as CheveroniconIcon } from 'assets/images/cheveronIcon.svg';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { ReactComponent as Avatar18Icon } from 'assets/sampleAvatar/Avatar-18.svg';
import { PhaseTask } from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import { HeaderColors } from 'configs';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import { getImage } from 'utils';
import { faLock } from '@fortawesome/pro-regular-svg-icons';

type DetailsCardProps = {
  // status?: string;
  // dueDate?: Date;
  // publishedOn?: Date;
  // lastUpdated?: Date;
  // createdBy?: {
  //   name: string;
  //   avatarUrl?: string;
  // };
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  taskData: PhaseTask | undefined;
};

const DetailsCard: FC<DetailsCardProps> = ({
  createdAt,
  createdBy,
  taskData,
  updatedAt,
}) => {
  const { isOpen, onToggle } = useDisclosure();

  const statusRender = () => {
    switch (taskData?.status) {
      case 'completed':
        return (
          <Flex
            bg='rgba(181, 228, 202, 0.3)'
            color={HeaderColors.GreenDark}
            gap='5px'
            p='5px 10px'
            borderRadius='8px'
            align='center'
          >
            <FontAwesomeIcon icon={faClockEight as IconProp} />
            <Box as='span' fontWeight={700}>
              Completed
            </Box>
          </Flex>
        );
      case 'dueToday':
        return (
          <Flex
            bg={HeaderColors.Blue}
            color={HeaderColors.DarkBlue}
            gap='5px'
            p='5px 10px'
            borderRadius='8px'
            align='center'
          >
            <FontAwesomeIcon icon={faClockEight as IconProp} />
            Due today
          </Flex>
        );
      case 'duesoon':
        return (
          <Flex
            bg={HeaderColors.Blue}
            color={HeaderColors.DarkBlue}
            gap='5px'
            p='5px 10px'
            borderRadius='8px'
            align='center'
          >
            <FontAwesomeIcon icon={faClockEight as IconProp} />
            Due soon
          </Flex>
        );
      case 'inProgress':
        return (
          <Flex
            bg='rgba(42, 133, 255, 0.1)'
            color={HeaderColors.DarkBlue}
            gap='5px'
            p='5px 10px'
            borderRadius='8px'
            align='center'
          >
            <FontAwesomeIcon icon={faClockEight as IconProp} />
            <Box fontWeight={700}>In progress</Box>
          </Flex>
        );
      case 'locked':
      case 'yetToStart':
      case 'yetToStart/locked':
        return (
          <Flex
            bg={'rgba(239, 239, 239, 1)'}
            color={'black'}
            gap='5px'
            p='5px 10px'
            borderRadius='8px'
            align='center'
          >
            <FontAwesomeIcon icon={faLock as IconProp} />
            <Box as='span' fontWeight={700}>
              Locked
            </Box>
          </Flex>
        );
      case 'overdue':
        return (
          <Flex
            bg={HeaderColors.Red.Light}
            color={HeaderColors.Red.Mid}
            gap='5px'
            p='5px 10px'
            borderRadius='8px'
            align='center'
          >
            <FontAwesomeIcon icon={faClockEight as IconProp} />
            Overdue
          </Flex>
        );
      default:
        return '-';
    }
  };

  return (
    <Flex
      flexDir={'column'}
      p={'20px 24px'}
      borderRadius={'12px'}
      bg={'#ffff'}
      w={'full'}
      h={isOpen ? '500px' : '250px'}
    >
      <Stack>
        <BoxHeader title='Details' color={'#B1E5FC'} />
      </Stack>
      <Stack mt={'16px'} gap={6}>
        <Flex justifyContent={'flex-start'} wrap={'nowrap'}>
          <Flex w='120px'>
            <Text fontWeight={600} fontSize={'14px'} lineHeight={'32px'}>
              Status:{' '}
            </Text>
          </Flex>
          <Flex>{statusRender()}</Flex>
        </Flex>
        <Flex
          alignItems={'center'}
          wrap={'nowrap'}
          justifyContent={'flex-start'}
        >
          <Flex w='120px'>
            <Text fontWeight={600} fontSize={'14px'} lineHeight={'32px'}>
              Due Date:{' '}
            </Text>
          </Flex>
          <Flex gap={2} align={'flex-start'}>
            <FontAwesomeIcon icon={faCalendarCheck as IconProp} size={'lg'} />
            <Text as='span'>
              {taskData?.dueDate && moment(taskData?.dueDate)?.isValid()
                ? moment(taskData?.dueDate)?.format('DD MMM YYYY, hh:mm A')
                : '-'}{' '}
            </Text>
          </Flex>
        </Flex>
        <Flex
          color={'#2A85FF'}
          cursor='pointer'
          justifyContent='space-between'
          align={'center'}
          fontWeight={600}
          lineHeight={'32px'}
          fontSize={'14px'}
          onClick={onToggle}
        >
          {isOpen ? <>View Less</> : <>View More</>}
          <FontAwesomeIcon
            icon={
              isOpen ? (faChevronUp as IconProp) : (faChevronDown as IconProp)
            }
            style={{ height: 22, width: 22 }}
          />
        </Flex>
        <ViewMore
          isOpen={isOpen}
          createdAt={createdAt}
          createdBy={createdBy}
          updatedAt={updatedAt}
        />
      </Stack>
    </Flex>
  );
};
type ViewMoreProps = {
  isOpen: boolean;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
};
const ViewMore: FC<ViewMoreProps> = ({
  isOpen,
  createdAt,
  createdBy,
  updatedAt,
}) => {
  const allEntityData = useReactiveVar(usersEntityObj);

  const createdByDetails = useMemo(() => {
    if (!createdBy) {
      return undefined;
    }
    return allEntityData
      ?.filter((en) => en?.eid === createdBy)
      ?.map((user) => ({
        eid: user?.eid,
        profilePic: user?.profilePic,
        name: user?.name,
      }))?.[0];
  }, [createdBy, isOpen]);

  return (
    <Collapse in={isOpen} animateOpacity>
      <Stack gap={4}>
        <Flex alignItems={'center'} justifyContent={'flex-start'}>
          <Flex w='120px'>
            <Text fontWeight={600} fontSize={'14px'} lineHeight={'32px'}>
              Published on:{' '}
            </Text>
          </Flex>
          <Flex gap={2} align={'flex-start'}>
            <FontAwesomeIcon icon={faCalendarCheck as IconProp} size={'lg'} />
            <Text as='span'>
              {createdAt && moment(createdAt)?.isValid()
                ? moment(createdAt)?.format('DD MMM YYYY, hh:mm A')
                : '-'}
            </Text>
          </Flex>
        </Flex>
        <Flex alignItems={'center'}>
          <Flex w='120px'>
            <Text fontWeight={600} fontSize={'14px'} lineHeight={'32px'}>
              Last Updated:{' '}
            </Text>
          </Flex>
          <Flex gap={2} align={'center'}>
            <FontAwesomeIcon icon={faClockEight as IconProp} size={'lg'} />
            <Text as='span'>
              {updatedAt && moment(updatedAt)?.isValid()
                ? moment(updatedAt)?.format('DD MMM, YYYY')
                : '-'}
            </Text>
          </Flex>
        </Flex>
        <Flex alignItems={'center'}>
          <Flex w='120px'>
            <Text fontWeight={600} fontSize={'14px'} lineHeight={'32px'}>
              Created by:{' '}
            </Text>
          </Flex>
          {createdByDetails ? (
            <Flex gap={2} align={'center'}>
              {/* <Avatar
                name={createdByDetails?.name}
                size={'xs'}
                src={getImage(
                  createdByDetails?.profilePic,
                  createdByDetails?.name
                )}
              /> */}
              <Image
                src={getImage(
                  createdByDetails?.profilePic,
                  createdByDetails?.name
                )}
                width={25}
                height={25}
                style={{ borderRadius: '50%' }}
              />
              &nbsp;{createdByDetails?.name}
            </Flex>
          ) : (
            '-'
          )}
        </Flex>
      </Stack>
    </Collapse>
  );
};

DetailsCard.displayName =
  'sub-components/Launcher/tasks/components/task-details/components/DetailsCard';

export default DetailsCard;
