import React, { FC } from 'react';
import { MetaComplianceData } from './add-document.graphql';
import { Input } from './compliance.helper';
import NewDocumentContent from './NewDocumentContent';
import UpdateCompliance from './UpdateCompliance';

interface IProps {
  complianceId?: string;
  type?: 'update' | 'reminder';
  localSave?: boolean;
  preDefinedLicense?: string;
  preDefinedLocationName?: string;
  preDefinedLocationId?: string;
  localSaveCb?: (data: Input) => void;
  successCb?: (data: Input | undefined) => void;
  initialFile?: File;
  metaComplianceData?: MetaComplianceData;
}

const BaseContent: FC<IProps> = ({
  complianceId,
  type,
  localSave,
  preDefinedLicense,
  preDefinedLocationName,
  preDefinedLocationId,
  initialFile,
  metaComplianceData,
  localSaveCb,
  successCb,
}) => {
  if (type === 'reminder') {
    return <UpdateCompliance type={type} complianceId={complianceId} />;
  }

  return complianceId ? (
    <UpdateCompliance
      preDefinedLocationId={preDefinedLocationId}
      complianceId={complianceId}
    />
  ) : (
    <NewDocumentContent
      localSave={localSave}
      predefinedLicense={preDefinedLicense}
      preDefinedLocationName={preDefinedLocationName}
      preDefinedLocationId={preDefinedLocationId}
      localSaveCb={localSaveCb}
      successCb={successCb}
      initialFile={initialFile}
      metaComplianceData={metaComplianceData}
    />
  );
};

BaseContent.displayName =
  'displayName:sub-components/nexus/Compliance/Create/components/BaseContent';

export default BaseContent;
