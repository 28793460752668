import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';

import MakeLocLive from '../MakeLocLive';
import { IMakeLocLive } from '../MakeLocLive/MakeLocLive';
import { IReadyLiveCard } from '../ReadyLive/Singleton/ReadyLiveCard';
import {
  LauncherLocation,
  LOCATION_TO_LAUNCH,
} from '../../../pages/launcher/dashboard/dashboard.graphql';
import { LocationLiveResp, MAKE_LOCATION_LIVE } from './live-modal.graphql';
import { toArray } from '../../../utils/utils';
import { useLocationLoginDetailModal } from '../LocationLoginDetails/useLocationLoginDetail';
import { useHistory } from 'react-router-dom';
import { LOCATIONS_PAGE } from 'appRoutes';
import { getLoggedInUserDataHandler } from 'shared/graphql/SharedGraphql';

/**
 * Type definition for the modal's data, extracting 'locName' from IReadyLiveCard.
 */
export type ILiveModalData = Pick<IReadyLiveCard, 'locationName'>;

/**
 * Interface for the props of LiveModal component.
 * @param isOpen Boolean to control the visibility of the modal.
 * @param data Data object containing information like location name.
 * @param onClose Function to call when the modal is requested to be closed.
 */
interface ILiveModalProps {
  isOpen: boolean;
  data?: LauncherLocation;
  onClose: () => void;
  refetchHandler: () => void;
}

/**
 * LiveModal is a modal component used to display location live status and manage it.
 * It includes a MakeLocLive component that handles the status and actions related to the location's live state.
 *
 * @param props The props for the LiveModal component, including isOpen, data, and onClose.
 * @returns A modal component with custom content and functionality.
 */
const LiveModal: FC<ILiveModalProps> = (props) => {
  const { isOpen, data, onClose, refetchHandler } = props;
  const history = useHistory();
  const toast = useToast({
    isClosable: true,
    duration: 3000,
    position: 'top-right',
  });
  const locationLoginDetails = useLocationLoginDetailModal();
  const [status, setStatus] = useState<IMakeLocLive['isLive']>(
    typeof data?.isLive === 'boolean' ? data.isLive : false
  );

  useEffect(() => {
    if (isOpen) {
      refetchHandler?.();
    }
  }, [isOpen]);

  const [makeLive] = useMutation<LocationLiveResp>(MAKE_LOCATION_LIVE, {
    onCompleted: (data) => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Location was made live',
      });
      setStatus(true);
      refetchHandler();
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Location could not be made live',
      });
      setStatus(false);
      onClose();
      refetchHandler();
    },
  });

  const setupLocation = () => {
    locationLoginDetails({
      locationData: data,
      locationName: data?.location?.name,
      type: 'developmentToOpen',
      onClose: () => {
        setStatus(false);
        refetchHandler?.();
      },
      // afterSuccess: () => history.push(LOCATIONS_PAGE),
      afterSuccess: () => {
        setStatus(false);
        onClose();
        history.push(LOCATIONS_PAGE);
      },
    });
  };

  const onSwitchClick = async () => {
    // setStatus(true);
    await makeLive({
      variables: {
        locationId: data?.locationId,
      },
    });
  };

  const phaseList = useMemo(() => {
    return toArray(data?.tasks)
      .reduce<string[]>((acc, value) => {
        if (!acc.includes(value.phase)) {
          acc.push(value.phase);
        }
        return acc;
      }, [])
      .map((it) => ({ title: it }));
  }, [data?.tasks]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='5xl' isCentered>
      <ModalOverlay />
      <ModalContent p='60px' borderRadius='20px'>
        <ModalCloseButton m={'50px'} />
        <ModalBody>
          <MakeLocLive
            locationId={data?.locationId!}
            locationName={data?.location?.name}
            isLive={status}
            onSwitchClick={onSwitchClick}
            setupLocation={setupLocation}
            phaseList={phaseList}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LiveModal;
