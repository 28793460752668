import { Center, Flex } from '@chakra-ui/react';
import { Button } from 'atoms';
import React, { FC } from 'react';
import EmptyState from 'sub-components/EmptyState/EmptyState';

interface IProps {}

const NoTask: FC<IProps> = () => {
  return (
    <Flex
      borderRadius={12}
      background='#ffffff'
      padding='20px 24px'
      flexDirection='column'
      gap={6}
    >
      <Center display='flex' flexDirection='column'>
        <EmptyState
          image='NoTask'
          imageSize='240px'
          title='No tasks added yet'
          description='Locations can start completing tasks as you add them'
        />
        <Button colorScheme='blue' marginTop={4}>
          Add tasks now
        </Button>
      </Center>
    </Flex>
  );
};

NoTask.displayName = 'sub-components/Launcher/dashboard/Location/NoTask';

export default NoTask;
