import { Center, Flex } from '@chakra-ui/react';
import React, { FC, useEffect } from 'react';
import DashboardContainer from 'sub-components/DashboardContainer';
import Loader from 'sub-components/Loader';
import LeftSection from './components/LeftSection';
import RightSection from './components/RightSection';
import { NexusBoardProvider } from './store/context';
import { useControl } from './layers';
import { useSafeState } from 'hooks';

const NexusBaseRoot: FC = () => {
  const [loading, setLoading] = useSafeState(true);
  const { exportRef, leaderboardLoading, locationDetailsLoading } =
    useControl();

  const isLoading = leaderboardLoading || locationDetailsLoading || loading;

  // initially the isLoading state was being set to false, which was causing to load the components and thus calling of APIs. This useEffect is a work around to set the initial state of isLoading to true so that we show the loader instead of loading the component
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <Center h='70vh'>
          <Loader />
        </Center>
      ) : (
        <Flex id='NDB' w='full' gap={4} ref={exportRef}>
          <Flex w='70%'>
            <LeftSection exportRef={exportRef} />
          </Flex>
          <Flex w='30%'>
            <RightSection />
          </Flex>
        </Flex>
      )}
    </>
  );
};

const NexusBase: FC = () => {
  return (
    <NexusBoardProvider>
      <NexusBaseRoot />
    </NexusBoardProvider>
  );
};

NexusBase.displayName = 'sub-components/nexus/NexusBase';
NexusBaseRoot.displayName = 'sub-components/nexus/NexusBase/NexusBaseRoot';

export default NexusBase;
