import React, { FC } from 'react';
import { Flex, IconButton, Image, Link, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo.webp';
import { ReactComponent as IosIcon } from '../../assets/images/sidebar/ios-icon.svg';
import { ReactComponent as AndroidIcon } from '../../assets/images/sidebar/android-icon.svg';

const SidebarFooter: FC = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Flex gap='16px' mt='20px'>
        <IconButton
          as={Link}
          flex={1}
          variant='outline'
          borderWidth='2px'
          borderColor='#efefef'
          aria-label='Android'
          icon={<AndroidIcon />}
          href='https://play.google.com/store/apps/details?id=com.delightree'
          isExternal
        />
        <IconButton
          as={Link}
          flex={1}
          variant='outline'
          borderWidth='2px'
          borderColor='#efefef'
          aria-label='iOS'
          icon={<IosIcon />}
          href='https://apps.apple.com/in/app/delightree/id1505988671'
          isExternal
        />
      </Flex>
      <Flex justify='center' align='center' gap='8px' mt='12px'>
        <Image width='16px' height='16px' src={logo} />
        <Text fontSize='12px' fontWeight='500' color='#6f767e'>
          {t('powered_by')}
        </Text>
      </Flex>
    </div>
  );
};

SidebarFooter.displayName = 'sub-components/Sidebar/SidebarFooter';

export default SidebarFooter;
