import React, { FC, useMemo } from 'react';
import { Flex, Image, Tooltip } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useReactiveVar } from '@apollo/client';

import { IconImage, IconsPlaceholder } from '../../../../../ui-components';
import { usersEntityObj } from '../../../../Header';
import { LauncherAssignee } from '../../common/launcher-config.fragment';
import SectionAssigneeList from './SectionAssigneeList';

interface AProps {
  userIds?: string[];
  isBoardView?: boolean;
}

const AssigneeList: FC<AProps> = ({ userIds = [], isBoardView }) => {
  const entityObjData = useReactiveVar(usersEntityObj);

  const users = useMemo(() => {
    const _userId = userIds.slice().splice(0, 2);
    return entityObjData?.filter((u) => _userId.includes(u.eid));
  }, [entityObjData, userIds]);

  if (isBoardView && users.length === 1) {
    const user = users[0];
    return (
      <Flex
        borderRadius='50px'
        border='2px solid #EEEEEE'
        bg='#FFFFFF'
        minH='28px'
        align='center'
        paddingLeft='12px'
        fontSize='11px'
        fontWeight='500'
        color='#33383F'
        gap={2}
        sx={{
          img: {
            marginBlock: '-2px',
            marginRight: '-2px',
          },
        }}
      >
        {user.name}
        <IconImage
          name={user.name}
          thumbnail={user.profilePic}
          borderRadius='100%'
          boxSize={28}
        />
      </Flex>
    );
  }

  return (
    <React.Fragment>
      {users?.map((user) => {
        return (
          <IconImage
            key={user.eid}
            name={user.name}
            thumbnail={user.profilePic}
            borderRadius='100%'
            boxSize={28}
          />
        );
      })}
    </React.Fragment>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: end;

  > img + img,
  > img + div {
    margin-left: -12px;
  }
`;

interface IProps extends LauncherAssignee {
  isBoardView?: boolean;
}

const SectionAssignee: FC<IProps> = ({
  assignedType,
  assignedUser = [],
  isBoardView,
}) => {
  if (!assignedType) {
    return null;
  }

  if (assignedType === 'user') {
    const remaining = assignedUser?.length - 2;
    return (
      <SectionAssigneeList userIds={assignedUser}>
        <Wrapper>
          <AssigneeList userIds={assignedUser} isBoardView={isBoardView} />

          {remaining > 0 ? (
            <Flex
              justify='center'
              align='center'
              boxSize='28px'
              borderRadius='full'
              color='#696974'
              fontSize='12px'
              border='1px solid #E2E2EA'
              cursor='pointer'
              bg='#FFFFFF'
            >
              +{remaining}
            </Flex>
          ) : null}
        </Wrapper>
      </SectionAssigneeList>
    );
  }

  return (
    <Flex justify='end'>
      <Tooltip
        label='Assign to all location owners'
        hasArrow
        borderRadius='6px'
        padding='10px'
        placement='bottom-end'
        maxW='200px'
        bg='#000000CC'
        fontSize='12px'
      >
        <Image
          src={IconsPlaceholder['location']}
          borderRadius='full'
          boxSize='28px'
          cursor='pointer'
        />
      </Tooltip>
    </Flex>
  );
};

AssigneeList.displayName = 'sc/L/lc/bv/s/AssigneeList';
SectionAssignee.displayName = 'sc/L/lc/bv/s/SectionAssignee';

export default SectionAssignee;
