import { FC, useMemo } from 'react';
import {
  Box,
  Button,
  ChakraProvider,
  Flex,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarLines,
  faCircle,
  faCircleInfo,
  faClockEight,
  faClockTenThirty,
  faDiagramSubtask,
  faEllipsis,
  faLockKeyhole,
} from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ActionMenu, IActionMenuData } from 'ui-components';
import { useTranslation } from 'react-i18next';

import OpenIcon from '../../../../../assets/images/open.png';
import { useHistory } from 'react-router-dom';
import NotRelevant from '../../components/my-tasks/menu/NotRelevant';
import { Progress } from '@chakra-ui/react';
import { faLocationDot, faRefresh } from '@fortawesome/pro-solid-svg-icons';
import { ReactComponent as CheckboxIcon } from 'assets/images/form/Checkbox.svg';
import { extendTheme } from '@chakra-ui/react';
import TaskStatusIcon from 'ui-components/TaskStatusIcon';
interface TaskCardProps {
  task: {
    eid: number;
    status: string;
    title: string;
    location: string;
    steps: number;
    completedSteps: number;
    taskType: string;
    subTasks: number;
    expectedCompletionDate: string;
    recurringDays: string;
    recurringTrigger: string;
    lockedDependencyOn: string;
    timeRemaining: string;
  };
}

const customProgressBarTheme = extendTheme({
  components: {
    Progress: {
      baseStyle: {
        track: {
          bg: 'gray.200',
          borderRadius: 'lg',
        },
        filledTrack: {
          bg: '#3DD598',
          borderRadius: 'lg',
        },
      },
    },
  },
});

const TaskCard: FC<TaskCardProps> = ({ task }) => {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation(['common']);

  const menuData = useMemo(() => {
    const _menuData: IActionMenuData[] = [
      {
        name: t('common:open'),
        value: 'open',
        icon: OpenIcon,
      },
      // {
      //   name: 'Not relevant?',
      //   value: 'not-relevant',
      //   icon: faCircleInfo,
      // },
    ];
    return _menuData;
  }, [t]);
  const redirectHandler = (type: 'open' | 'not-relevant') => {
    if (type === 'not-relevant') {
      onOpen();
    } else {
      history.push(`/launcher/tasks/details/${task.eid}`);
    }
  };

  const clickedItemsHandler = (_: string, clickedName: string) => {
    switch (clickedName) {
      case 'open':
        return redirectHandler('open');
      case 'not-relevant':
        return redirectHandler('not-relevant');
    }
  };

  const renderTaskDetails = () => {
    //TODO: convert this to
    switch (task.status) {
      case 'overdue':
        return (
          <Flex gap={4} alignItems={'flex-start'} color={'#D1D8E0'}>
            <FontAwesomeIcon
              icon={faClockTenThirty as IconProp}
              size={'lg'}
              color={'red'}
              style={{ backgroundColor: '#FF6A551A' }}
            />
            <Box>
              <Box
                as={'span'}
                color={'#272B30'}
                lineHeight={'17px'}
                fontSize={'16px'}
              >
                {task.title}
              </Box>
              <Flex align={'center'} gap={2}>
                <Flex as={'span'} color={'#FF6A55'} gap={1} align={'center'}>
                  <FontAwesomeIcon icon={faCalendarLines as IconProp} />
                  {task.expectedCompletionDate}
                </Flex>
                &bull;
                <Box as={'span'} color={'#FF6A55'}>
                  Overdue by 5 days
                </Box>
                &bull;
                <Box as={'span'} color={'#FF6A55'}>
                  {task.taskType}
                </Box>
                &bull;
                <Flex as={'span'} color={'#FF6A55'} gap={1} align={'center'}>
                  <FontAwesomeIcon icon={faDiagramSubtask as IconProp} />
                  {task.subTasks} subtasks
                </Flex>
              </Flex>
            </Box>
          </Flex>
        );
      case 'inProgress':
        return (
          <Flex gap={4} alignItems={'flex-start'} color={'#2A85FF'}>
            <FontAwesomeIcon
              icon={faCircle as IconProp}
              size={'lg'}
              color={'blue'}
              style={{ backgroundColor: '#B1E5FC33' }}
            />
            <Box>
              <Box lineHeight={'16.94px'} fontSize={'16px'} color={'#272B30'}>
                {task.title}
              </Box>
              <Flex align={'center'} gap={2}>
                <Flex as={'span'} gap={1} align={'center'}>
                  <FontAwesomeIcon icon={faCalendarLines as IconProp} />
                  {task.expectedCompletionDate}
                </Flex>
                <Text color={'#BFC6CE'}>&bull;</Text>
                <Flex gap={2} align={'center'}>
                  <FontAwesomeIcon icon={faRefresh as IconProp} />
                  {task.recurringTrigger}
                </Flex>
                <Text color={'#BFC6CE'}>&bull;</Text>
                <Box as={'span'}>{task.taskType}</Box>
              </Flex>
            </Box>
          </Flex>
        );
      case 'yetToStart':
        return (
          <Flex gap={4} alignItems={'flex-start'} color={'#6F767E'}>
            <FontAwesomeIcon
              icon={faLockKeyhole as IconProp}
              size={'lg'}
              color={'black'}
            />
            <Box>
              <Box lineHeight={'16.94px'} fontSize={'16px'} color={'#272B30'}>
                {task.title}
              </Box>
              <Flex align={'center'} gap={2}>
                <Flex as={'span'} gap={1} align={'center'}>
                  <FontAwesomeIcon icon={faCalendarLines as IconProp} />
                  Will be unlocked: After{' '}
                  <Text
                    as={'span'}
                    fontWeight={700}
                  >{`\`${task.lockedDependencyOn.substring(
                    0,
                    20
                  )}...\``}</Text>{' '}
                  is completed
                </Flex>
                <Text color={'#BFC6CE'}>&bull;</Text>
                <Flex gap={2} align={'center'}>
                  <FontAwesomeIcon icon={faDiagramSubtask as IconProp} />
                  {task.subTasks} subtasks
                </Flex>
              </Flex>
            </Box>
          </Flex>
        );
      case 'today':
        return (
          <Flex gap={4} alignItems={'flex-start'} color={'#2A85FF'}>
            <FontAwesomeIcon
              icon={faCircle as IconProp}
              size={'lg'}
              color={'blue'}
              style={{ backgroundColor: '#B1E5FC33' }}
            />
            <Box>
              <Box lineHeight={'16.94px'} fontSize={'16px'} color={'#272B30'}>
                {task.title}
              </Box>
              <Flex align={'center'} gap={2}>
                <Flex as={'span'} gap={1} align={'center'}>
                  <FontAwesomeIcon icon={faCalendarLines as IconProp} />
                  {task.expectedCompletionDate}
                </Flex>
                <Text color={'#BFC6CE'}>&bull;</Text>
                <Flex gap={2} align={'center'}>
                  <FontAwesomeIcon icon={faRefresh as IconProp} />
                  {task.recurringDays}
                </Flex>
                <Text color={'#BFC6CE'}>&bull;</Text>
                <Box as={'span'}>{task.taskType}</Box>
              </Flex>
            </Box>
          </Flex>
        );
      default:
        return null;
    }
  };

  return (
    <Flex p={'18px 21px'} justify={'space-between'} align={'center'}>
      {/**left section */}
      {/**Task details */}
      {renderTaskDetails()}

      {/**right section */}
      <Flex justify={'space-between'} align={'center'} gap={10}>
        {(task.status === 'today' || task.status === 'inProgress') && (
          <Box
            bg={'#83BF6E1A'}
            p={'11px 7px'}
            color={'#83BF6E'}
            sx={{ borderRadius: '5px' }}
          >
            <Flex
              gap={2}
              align={'center'}
              justify={'center'}
              fontSize={'14px'}
              lineHeight={'16.94px'}
            >
              <FontAwesomeIcon icon={faClockEight as IconProp} />
              {task.timeRemaining}
            </Flex>
          </Box>
        )}
        <Flex gap={4}>
          <Button
            p={'5px 20px'}
            leftIcon={<FontAwesomeIcon icon={faLocationDot as IconProp} />}
          >
            {task.location}
          </Button>

          {task.status !== 'yetToStart' && (
            <ChakraProvider theme={customProgressBarTheme}>
              <Stack
                minWidth={'120px'}
                fontSize={'16px'}
                lineHeight={'16.94px'}
              >
                <Flex color={'#92929D'} align={'center'} gap={2}>
                  <CheckboxIcon
                    style={{
                      borderRadius: '2px',
                      height: '16px',
                      width: '16px',
                      color: '#92929D',
                    }}
                  />
                  {task.completedSteps}/{task.steps}
                </Flex>
                <Progress
                  value={(task.completedSteps / task.steps) * 100}
                  borderRadius='lg'
                  h='1'
                />
              </Stack>
            </ChakraProvider>
          )}
        </Flex>

        {/** three dots menu */}
        <ActionMenu
          menuData={menuData}
          arrowSize={10}
          closeOnBlur={true}
          offset={[0, 0]}
          clickedItem={clickedItemsHandler}
        >
          <Box cursor='pointer'>
            <FontAwesomeIcon icon={faEllipsis as IconProp} fontSize='20px' />
          </Box>
        </ActionMenu>
      </Flex>
      {isOpen && <NotRelevant isOpen={isOpen} onToggle={onClose} />}
    </Flex>
  );
};

TaskCard.displayName =
  'displayName:sub-components/Launcher/tasks/components/common/TaskCard';

export default TaskCard;
