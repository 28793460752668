import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDownAZ,
  faArrowDownZA,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';
import { IListSortBy } from '../../FolderList';

interface IProps {
  listSortBy: IListSortBy;
  setListSortBy: React.Dispatch<React.SetStateAction<IListSortBy>>;
}

const Heading: FC<IProps> = ({ listSortBy, setListSortBy }) => {
  return (
    <Flex
      bg='#F4F4F4'
      p='10px 16px'
      borderRadius='5px'
      justify='space-between'
      align='center'
      h='fit-content'
      w='full'
    >
      <Text color='#33383F' fontWeight={600} fontSize='12px'>
        FOLDERS
      </Text>
      {/* <Tooltip
        label={
          listSortBy === 'asc'
            ? 'Sort in descending order'
            : 'Sort in ascending order'
        }
        hasArrow
        placement='top'
      >
        <Box>
          <FontAwesomeIcon
            icon={
              (listSortBy === 'asc' ? faArrowDownAZ : faArrowDownZA) as IconProp
            }
            size='lg'
            cursor='pointer'
            onClick={() => {
              let _sortBy: IListSortBy = 'asc';
              if (listSortBy === 'asc') {
                _sortBy = 'desc';
                deployEvent(AmplitudeEventNames.FOLDER_SORT_DESC);
              } else {
                _sortBy = 'asc';
                deployEvent(AmplitudeEventNames.FOLDER_SORT_ASC);
              }
              setListSortBy(_sortBy);
            }}
          />
        </Box>
      </Tooltip> */}
    </Flex>
  );
};

Heading.displayName = 'p/C/FL/c/Heading';

export default Heading;
