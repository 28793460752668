import { useLauncherConfigContext } from '../../common';
import { getDuration } from '../../common/trigger/duration.helper';

export const useSectionFilterCheck = (sectionIndex: number): boolean => {
  return useLauncherConfigContext((state) => {
    const other = state.filterBy?.other || {};
    const phase = state.config.contents?.[sectionIndex];
    const assignees = state?.filterBy?.assignees || [];
    const taskTypes = state?.filterBy?.taskTypes || [];
    if (taskTypes?.length) {
      if (phase?.tasks?.some((task) => taskTypes.includes(task.category))) {
        return false;
      }
    }

    if (other?.deadline || other?.nonDeadline) {
      if (assignees?.length) {
        if (
          phase?.tasks?.some((task) =>
            task.assignedUser?.some((assignee) => assignees.includes(assignee))
          )
        ) {
          return false;
        }
      }

      const { durationUnit } = getDuration(phase?.trigger);
      if (other?.deadline && durationUnit) {
        return false;
      }
      return !(other?.nonDeadline && !durationUnit);
    } else {
      if (assignees?.length) {
        if (
          phase?.tasks?.some((task) =>
            task.assignedUser?.some((assignee) => assignees.includes(assignee))
          )
        ) {
          return false;
        }
      }
    }

    if (assignees?.length) {
      return !phase.assignedUser?.some((assignee) =>
        assignees.includes(assignee)
      );
    }

    return false;
  });
};
