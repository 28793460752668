import React, { FC, useMemo } from 'react';
import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Radio,
  RadioGroup,
  useModalContext,
} from '@chakra-ui/react';
import { useForm, Controller, ControllerRenderProps } from 'react-hook-form';
import { ActionButton } from '../../../../../ui-components';

import { MenuContainer } from '../../../../CustomDropdowns';
import { LauncherAssignee } from '../launcher-config.fragment';
import { useAssigneeUsers } from './useAssigneeUsers';

interface IFormInput extends LauncherAssignee {}

interface IProps {
  value?: IFormInput;
  onChange?: (value: IFormInput) => void | Promise<void>;
}

const AddAssigneeContent: FC<IProps> = ({ value, onChange }) => {
  const { onClose } = useModalContext();
  const {
    control,
    watch,
    handleSubmit,
    formState: { isDirty },
  } = useForm<IFormInput>({
    defaultValues: value,
  });
  const assignedType = watch('assignedType');

  const options = useAssigneeUsers();

  async function _onAddClick(values: IFormInput) {
    if (values.assignedType === 'location') {
      await onChange?.({
        assignedType: values.assignedType,
        assignedUser: [],
      });
    } else {
      await onChange?.({
        assignedType: values.assignedType,
        assignedUser: values.assignedUser,
      });
    }
    setTimeout(onClose, 1);
  }

  async function _onRemoveClick() {
    await onChange?.({
      assignedType: null as never,
      assignedUser: [],
    });
    setTimeout(onClose, 1);
  }

  function handleOptionClick(
    this: ControllerRenderProps<IFormInput, 'assignedUser'>,
    eid: string
  ) {
    let newSelectedValues = [];

    if (this.value?.includes(eid)) {
      newSelectedValues = this.value?.filter((val) => val !== eid);
    } else {
      newSelectedValues = [...(this.value || []), eid];
    }
    this.onChange(newSelectedValues);
  }

  return (
    <Flex flexDir='column'>
      <Controller
        control={control}
        name='assignedType'
        render={({ field }) => {
          return (
            <RadioGroup
              {...field}
              display='flex'
              flexDir='column'
              gap={3}
              px={2}
              sx={{
                '.chakra-radio__label': {
                  color: '#33383F',
                  fontSize: 15,
                  fontWeight: 500,
                },
              }}
            >
              <Radio size='lg' value='location' spacing={4}>
                Location Owners of launching location
              </Radio>

              <Divider />

              <Radio size='lg' value='user' spacing={4}>
                <Box>
                  <span>Others</span>
                  <Box color='#6F767E' fontSize='13px' fontWeight='400'>
                    Tasks assigned to internal people will not be visible to
                    location owners.
                  </Box>
                </Box>
              </Radio>
            </RadioGroup>
          );
        }}
      />

      <Flex flexDir='column'>
        <Controller
          control={control}
          name='assignedUser'
          rules={{
            required: {
              value: assignedType === 'user',
              message: 'Superadmin assignee is required',
            },
          }}
          render={({ field, fieldState }) => {
            if (assignedType !== 'user') {
              return <Box height='36px' />;
            }

            return (
              <FormControl isInvalid={fieldState.invalid} paddingLeft={8}>
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
                <MenuContainer
                  showSearchField
                  showDivider
                  showSelectAll
                  searchFieldPlaceholder='Search by name or role'
                  options={options}
                  handleOptionClick={handleOptionClick.bind(field)}
                  selectedValues={field.value || []}
                  setSelectedValues={field.onChange}
                />
              </FormControl>
            );
          }}
        />

        <Flex paddingX={1} gap={4}>
          <ActionButton
            size='lg'
            flex={1}
            variant='outline'
            actionFn={_onRemoveClick}
            isDisabled={!value?.assignedType}
          >
            Remove Assignee
          </ActionButton>
          <ActionButton
            size='lg'
            flex={1}
            colorScheme='blue'
            actionFn={handleSubmit(_onAddClick)}
            isDisabled={!isDirty}
          >
            Add assignee
          </ActionButton>
        </Flex>
      </Flex>
    </Flex>
  );
};

AddAssigneeContent.displayName = 'sc/L/lc/c/as/AddAssigneeContent';

export default AddAssigneeContent;
