import React, { FC, useCallback, useMemo, useState } from 'react';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ActionType, Row } from 'react-table';
import styled from '@emotion/styled';
import { match } from 'ts-pattern';

import { toArray } from 'utils/utils';
import { TSortBy } from 'types';
import { NullShorting, SortByLastActivity } from 'utils/sorting';

import {
  Column,
  SortingTable,
} from '../../../sub-components/ChakraTable/SortingTable';
import { DateCell } from '../../Training/Training/TrainingComponent';
import EmptyMembers from './EmptyMembers';
import { TeamItem } from './team.types';
import {
  LastActivityCell,
  LocationCell,
  MemberAction,
  MemberName,
  MemberStatus,
} from './component';
import { IndeterminateCheckbox } from '../common';
import MemberExportButton from './MemberExportButton';
import { createSheetsHandler } from '../../Settings/CreateReport/CreateReport';
import EmptyState from 'sub-components/EmptyState';

import { EntityUserSort, MemberSort } from './member.graphql';
import moment from 'moment';
import { useUserDataSelector } from 'hooks';

const Wrapper = styled.div`
  padding-bottom: 40px;

  table > thead > tr > th {
    height: 44px;
  }
`;

interface IProps {
  dataList: TeamItem[];
  onPageChange: (newPage: number) => void;
  loading: boolean;
  currentPage: number;
  itemCount?: number;
  isSearchQuery?: boolean;
  shouldRefetch?: () => void;
  onClickedHandler?: (clickedItem: string, data: TeamItem) => void;
  onSortByChange?: (value: EntityUserSort) => void;
}

const MemberList: FC<IProps> = ({
  dataList,
  onPageChange,
  loading,
  itemCount,
  currentPage,
  isSearchQuery,
  shouldRefetch,
  onClickedHandler,
  onSortByChange,
}) => {
  const { t, i18n } = useTranslation([
    'common',
    'handbook',
    'header',
    'card',
    'training',
    'chapter',
    'team',
  ]);

  const [selectedFlatRows, setSelectedFlatRows] = useState<
    Array<Row<TeamItem>>
  >([]);

  const loggedInUserDetails = useUserDataSelector((state) => {
    return {
      authRole: state.authRole,
      loggedInUserLocation: state.locations,
    };
  });

  const columns = useMemo((): Column<TeamItem>[] => {
    return [
      {
        accessor: 'eid',
        id: 'checkbox',
        width: '7%',
        // @ts-ignore
        Header: ({ getToggleAllRowsSelectedProps }) => {
          return (
            <IndeterminateCheckbox
              borderRadius='5px'
              ml='12px'
              {...getToggleAllRowsSelectedProps?.()}
            />
          );
        },
        Cell: ({ row }) => {
          return (
            <IndeterminateCheckbox
              borderRadius='5px'
              ml='12px'
              // @ts-ignore
              {...row.getToggleRowSelectedProps?.()}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: t('common:name'),
        accessor: 'name',
        width: '23%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <MemberName
              name={value}
              profilePic={row.original.profilePic}
              email={row.original.email}
              authRole={row.original.authRole}
              status={row?.original?.status}
              onClick={() => onClickedHandler?.('open', row.original)}
            />
          );
        },
        sortType: NullShorting,
      },
      {
        Header: t('team:table.job'),
        accessor: 'role',
        width: '10%',
        Cell: ({ cell: { value } }) => {
          return (
            <Tooltip label={value} hasArrow borderRadius='4px'>
              <Text
                mb='25px'
                fontSize='15px'
                fontWeight='600'
                lineHeight='24px'
                color='#1A1D1F'
                whiteSpace='pre-wrap'
                mr='10px'
                maxWidth='80px'
                isTruncated
              >
                {value}
              </Text>
            </Tooltip>
          );
        },
        sortType: NullShorting,
      },
      {
        Header: t('team:table.addedOn'),
        accessor: 'createdAt',
        width: '10%',
        Cell: ({ cell: { value } }) => {
          return <DateCell date={value} status='active' format='DD MMM YYYY' />;
        },
        sortType: NullShorting,
      },
      {
        Header: t('common:location'),
        accessor: 'locations',
        width: '20%',
        disableSortBy: true,
        Cell: ({ cell: { value, row } }) => {
          return <LocationCell value={value} memberName={row.original.name} />;
        },
      },
      {
        Header: t('common:status'),
        accessor: 'status',
        width: '10%',
        Cell: ({ cell: { value } }) => {
          return <MemberStatus status={value} />;
        },
        disableSortBy: true,
      },
      {
        Header: t('common:last_activity'),
        accessor: 'lastActivity',
        width: '10%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <LastActivityCell
              lastActivity={value}
              loggedInUserDetails={loggedInUserDetails}
              memberLocation={row.original.locations}
            />
          );
        },
        sortType: NullShorting,
      },
      {
        Header: '',
        accessor: 'eid',
        width: '10%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <Box id={value} mb='25px'>
              <MemberAction
                status={row.original.status}
                locations={row.original.locations}
                authRole={row.original.authRole}
                role={row.original.role}
                eid={row.original.eid}
                onClick={(action) => {
                  return onClickedHandler?.(action, row.original);
                }}
              />
            </Box>
          );
        },
        disableSortBy: true,
      },
    ];
  }, [t, i18n.language, onClickedHandler]);

  const onRowSelectChange = useCallback((_values: Array<Row<TeamItem>>) => {
    setSelectedFlatRows(_values || []);
  }, []);

  const onExportClick = useCallback(async () => {
    const exportableData = selectedFlatRows.map((it) => it.original);
    await createSheetsHandler(exportableData, { value: 'Members' });
  }, [selectedFlatRows]);

  const updateSortBy = (values: TSortBy) => {
    const newValue = match<TSortBy, EntityUserSort>(values)
      .with({ id: 'name' }, (sel) => (sel.desc ? 'NAME_ASC' : 'NAME_DESC'))
      .with({ id: 'createdAt' }, (sel) =>
        sel.desc ? 'CREATEDAT_ASC' : 'CREATEDAT_DESC'
      )
      .with({ id: 'status' }, (sel) =>
        sel.desc ? 'STATUS_ASC' : 'STATUS_DESC'
      )
      .with({ id: 'role' }, (sel) => (sel.desc ? 'ROLE_ASC' : 'ROLE_DESC'))
      .with({ id: 'inviteDetails' }, (sel) =>
        sel.desc ? 'CREATEDAT_ASC' : 'CREATEDAT_DESC'
      )
      .with({ id: 'lastActivity' }, (sel) =>
        sel.desc
          ? 'LASTACTIVITY__ACTIVITYTIME_ASC'
          : 'LASTACTIVITY__ACTIVITYTIME_DESC'
      )
      .otherwise(() => '_ID_DESC');
    if (values) onSortByChange?.(newValue);
  };

  const stateReducer = <T extends unknown>(newState: T, action: ActionType) => {
    switch (action?.type) {
      case 'toggleSortBy':
        // @ts-ignore
        updateSortBy(toArray<TSortBy>(newState?.sortBy)[0] || null);
        break;
    }

    return newState;
  };

  return (
    <Wrapper>
      <SortingTable
        colorScheme='blue'
        customWrapperStyles={{ marginTop: '1rem' }}
        emptyData={{
          content: isSearchQuery ? (
            <EmptyState
              image='Search'
              title={t('header:result_not_found')}
              description={t('header:not_found_desc')}
            />
          ) : (
            <EmptyMembers shouldRefetch={shouldRefetch} />
          ),
        }}
        page={currentPage || 1}
        onPageChange={onPageChange}
        columns={columns}
        data={dataList}
        isLoading={loading}
        totalRegisters={itemCount}
        stateReducer={stateReducer}
        options={{
          autoResetSortBy: false,
        }}
        isResponsive
        isHidePagination={!(currentPage > 1 || (itemCount || 0) > 10)}
        onRowSelectChange={onRowSelectChange}
      />

      <MemberExportButton
        selectedFlatRows={selectedFlatRows}
        onExportClick={onExportClick}
        transKey='setting:member_selected'
      />
    </Wrapper>
  );
};

MemberList.displayName = 'displayName:pages/Teams/members/MemberList';

export default MemberList;
