import React, { VFC } from 'react';
import { Box, Center, CenterProps, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { AccessibilityType, AccessibleTransKeys } from 'types';

import formLockIcon from '../../assets/images/form-lock-icon.svg';

interface IProps extends Pick<CenterProps, 'minHeight' | 'paddingBottom'> {
  type: AccessibilityType;
  titleKey: AccessibleTransKeys;
  descKey: AccessibleTransKeys;
}

const NotAccessibleBase: VFC<IProps> = ({
  type,
  titleKey,
  descKey,
  ...rest
}) => {
  const { t } = useTranslation(['form', 'chapter', 'training']);

  return (
    <Center
      flexDir='column'
      gap='10px'
      color='#000000'
      minH='calc(50vh - 60px)'
      {...rest}
    >
      {type !== 'NOT_FOUND' ? (
        <Image src={formLockIcon} boxSize='128px' />
      ) : (
        <Box boxSize='60px' />
      )}

      <Box fontWeight='600' fontSize='16px'>
        {t(titleKey)}
      </Box>
      {descKey && <Box fontSize='13px'>{t(descKey)}</Box>}
    </Center>
  );
};

export default NotAccessibleBase;
