import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { useLauncherConfigContext } from '../../common';
import SectionAction from './SectionAction';
import AddSectionAssignee from './AddSectionAssignee';
import SectionDependDetails from './SectionDependDetails';
import SectionDuration from './SectionDuration';

interface IProps {
  sectionIndex: number;
}

const SectionHeader: FC<IProps> = ({ sectionIndex }) => {
  const { t } = useTranslation(['launcher']);
  const data = useLauncherConfigContext((state) => {
    const value = state.config.contents?.[sectionIndex];

    return {
      eid: value?.eid,
      title: value?.category,
      totalPhase: state.config.contents?.length,
      totalTasks: value?.tasks?.length,
      isLocked: value?.trigger?.triggerChoice === 'activation',
    };
  });

  return (
    <Flex flexDir='column' gap='6px'>
      <Flex gap='6px'>
        <Flex flex={1} flexDir='column' overflow='hidden'>
          <Flex flex={1} fontSize='16px' fontWeight='600'>
            <Box as='span' isTruncated>
              {data?.title}
            </Box>
            &nbsp;-&nbsp;
            <Box
              as='span'
              fontWeight='400'
              color='#6F767E'
              textTransform='capitalize'
              whiteSpace='nowrap'
            >
              <Trans
                t={t}
                i18nKey='launcher:noOfTask'
                count={data.totalTasks}
                values={{ count: data.totalTasks }}
              />
            </Box>
          </Flex>
          {data.isLocked ? (
            <SectionDependDetails sectionIndex={sectionIndex} />
          ) : null}
        </Flex>
        <SectionAction
          sectionIndex={sectionIndex}
          isLocked={data.isLocked}
          isLastSection={data?.totalPhase === sectionIndex + 1}
        />
      </Flex>

      <Flex>
        <SectionDuration sectionIndex={sectionIndex} />

        <AddSectionAssignee sectionIndex={sectionIndex} />
      </Flex>
    </Flex>
  );
};

export default SectionHeader;
