import React, { ChangeEvent, FC, ReactNode } from 'react';
import { Box, Center, Checkbox, Flex } from '@chakra-ui/react';

interface IProps {
  title: string;
  value: string;
  isChecked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  icon: ReactNode;
}

const FilterCheckItem: FC<IProps> = ({
  title,
  value,
  icon,
  onChange,
  isChecked,
}) => {
  return (
    <Flex
      align='center'
      gap='10px'
      color='#6F767E'
      padding={3}
      borderRadius='10px'
      aria-selected={isChecked}
      _hover={{ bg: '#F4F4F4' }}
      _selected={{ color: '#1A1D1F' }}
    >
      <Center boxSize='24px'>{icon}</Center>
      <Box flex={1} fontSize='15px' fontWeight='600' isTruncated>
        {title}
      </Box>
      <Checkbox
        isChecked={isChecked}
        value={value}
        size='lg'
        onChange={onChange}
      />
    </Flex>
  );
};

export default FilterCheckItem;
