import React, { FC, useState } from 'react';
import { Box, Checkbox, Flex, IconButton, Spacer } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { IconImage, IconsPlaceholder } from '../../../../../../ui-components';
import { LauncherTaskStep } from '../../launcher-config.fragment';

import EditStepForm from './EditStepForm';

interface IProps {
  index: number;
  data: LauncherTaskStep;
  isDisabled?: boolean;
  onDelete?: (value: LauncherTaskStep, index: number) => void;
  onUpdate?: (value: LauncherTaskStep, index: number) => void;
}

const StepItem: FC<IProps> = ({
  data,
  index,
  isDisabled,
  onDelete,
  onUpdate,
}) => {
  const [editing, setEditing] = useState(false);

  if (editing) {
    return (
      <EditStepForm
        data={data}
        onUpdateClick={(newValue) => {
          onUpdate?.(newValue, index);
          setEditing(false);
        }}
      />
    );
  }

  // @ts-ignore
  return (
    <Flex
      pos='relative'
      gap={4}
      align='center'
      _hover={{
        '.step-action': {
          display: 'flex',
          opacity: 1,
        },
      }}
    >
      <Checkbox isReadOnly />

      <Box fontSize='15px' fontWeight='400' color='#272B30'>
        {data.title}
      </Box>

      <Spacer />

      {data?.type === 'text' ? (
        <Box boxSize='30px' minW='30px' />
      ) : (
        <IconImage
          thumbnail={IconsPlaceholder[data.type]}
          name={data.title}
          boxSize={30}
          borderRadius='4px'
        />
      )}

      {!isDisabled && (
        <Flex
          display='none'
          opacity='0'
          className='step-action'
          gap={4}
          pos='absolute'
          top='0'
          right='0'
          boxSize='full'
          justify='flex-end'
          bg='linear-gradient(270deg, #FFFFFF 16%, rgba(255, 255, 255, 0) 86%)'
          transition='opacity 200ms, display 200ms'
        >
          {data?.type === 'text' && (
            <IconButton
              aria-label='edit'
              borderRadius='full'
              bg='#BBBBBB'
              size='xs'
              alignSelf='center'
              icon={<FontAwesomeIcon icon={faPen as IconProp} color='white' />}
              onClick={() => setEditing(true)}
            />
          )}

          <IconButton
            aria-label='action'
            borderRadius='full'
            bg='#BBBBBB'
            size='xs'
            alignSelf='center'
            icon={
              <FontAwesomeIcon
                size='lg'
                icon={faClose as IconProp}
                color='white'
              />
            }
            onClick={() => onDelete?.(data, index)}
          />
        </Flex>
      )}
    </Flex>
  );
};

StepItem.displayName =
  'sub-components/Launcher/launcher-config/common/config-task-form/task-steps/StepItem';

export default StepItem;
