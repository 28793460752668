import React, { FC } from 'react';
import { EmptyState } from './components/my-tasks/EmptyState';
import { TaskNavbar } from './components/my-tasks/TaskNavbar';
import { GrouByFilter } from '../dashboard/Filters';
import { useControl } from './layers';
import { dashboardFilters } from './static-data';
import { Center } from '@chakra-ui/react';
import Loader from 'sub-components/Loader';
import { LauncherTasksContainer } from './components/common';

interface IProps {}

const TaskContainerConfig: FC<IProps> = () => {
  const control = useControl();
  if (control?.loading) {
    return (
      <Center h='80vh'>
        <Loader />
      </Center>
    );
  }

  return (
    <>
      <TaskNavbar
        tasks={control?.tasks}
        taskFilters={control?.taskFilters}
        setTaskFilters={control?.setTaskFilters}
      />
      <GrouByFilter
        filters={dashboardFilters}
        handler={control?.setAppliedFiltersForContainer}
        initialFilter={control?.appliedFiltersForContainer}
      />
      {control?.filteredTasks.length > 0 ? (
        <LauncherTasksContainer
          categories={control?.categories}
          data={control?.filteredTasks}
          activeFilterLabel={control?.appliedFiltersForContainer}
          refetch={control?.refetchHandler}
        />
      ) : (
        <EmptyState
          image='MyTask'
          title='No task is assigned to you'
          description='Your tasks will appear here, once it is assigned to you'
        />
      )}
    </>
  );
};

TaskContainerConfig.displayName =
  'displayName:sub-components/Launcher/tasks/TaskContainerConfig';

export default TaskContainerConfig;
