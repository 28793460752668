import React, { FC } from 'react';
import {
  CHAT_URL,
  HOME_URL,
  TASKS_PAGE,
  TASKS_URL,
  LAUNCHER_URL,
} from 'appRoutes';
import { useChatMessageCount, useUserEntity } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useLocation, useRouteMatch } from 'react-router-dom';
import SidebarItem from 'sub-components/Sidebar/SidebarItem';
import SidebarSection from 'sub-components/Sidebar/SidebarSection';
import NotificationCount from 'ui-components/NotificationCount';

import { ReactComponent as HomeIcon } from '../../../../assets/images/sidebar/home.svg';
import { ReactComponent as MessageIcon } from '../../../../assets/images/sidebar/chat.svg';
import { ReactComponent as CheckCircleIcon } from '../../../../assets/images/sidebar/task.svg';
import { ReactComponent as LauncherIcon } from '../../../../assets/images/sidebar/launcher.svg';

import { Authorize, AuthRole } from 'authorization';
import SidebarTasks from 'sub-components/Sidebar/tasks/SidebarTasks';
import { useRenderFlag } from '../helper';
import SidebarLauncher from './SidebarLauncher';
import { toArray } from '../../../../utils';
import { SidebarBaseItem } from '../../common';

interface IProps {}

const MainSection: FC<IProps> = () => {
  const match = useRouteMatch();
  // const flag = useRenderFlag();
  const { t } = useTranslation('sidebar');
  const chatMessageCount = useChatMessageCount();
  const pathname = useLocation().pathname;

  const isLauncherEnabled = useUserEntity(
    (entity) => entity?.features?.launcher
  );

  return (
    <SidebarSection title={t('mainSection')}>
      <SidebarItem
        icon={HomeIcon}
        title={t('home')}
        isSelected={pathname === HOME_URL}
        link={HOME_URL}
      />
      <Authorize
        permittedFor='user'
        permittedRoles={[
          AuthRole.SUPER_ADMIN,
          AuthRole.ADMIN,
          AuthRole.LOCATION_OWNER,
        ]}
        renderNoAccess={() => {
          return (
            <SidebarItem
              icon={CheckCircleIcon}
              title={t('tasks')}
              isSelected={pathname === TASKS_URL}
              link={TASKS_URL}
            />
          );
        }}
      >
        <SidebarTasks
          isSelected={match?.path === TASKS_PAGE}
          isExpand={match?.path === TASKS_PAGE}
        />
      </Authorize>
      {/* {flag && (
      )} */}
      <Authorize
        canAccess={(authUser) => {
          const chatConfig = authUser?.entity?.chatConfig;

          if (chatConfig?.restrictions) {
            return (
              [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(
                toArray(authUser?.authRole)[0]
              ) && authUser.type === 'user'
            );
          }

          return !chatConfig?.restrictions;
        }}
      >
        <SidebarItem
          icon={MessageIcon}
          title={t('chat')}
          isSelected={pathname === CHAT_URL}
          link={CHAT_URL}
          isChat
          chatMessageCount={chatMessageCount}
          countElement={
            !!chatMessageCount && (
              <NotificationCount notificationNumber={chatMessageCount} />
            )
          }
        />
      </Authorize>
      {isLauncherEnabled && (
        <>
          <Authorize
            permittedFor='user'
            permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
          >
            <SidebarLauncher
              isSelected={pathname?.includes(LAUNCHER_URL)}
              isExpand={pathname?.includes(LAUNCHER_URL)}
            />
          </Authorize>
          <Authorize
            permittedFor='user'
            permittedRoles={[AuthRole.LOCATION_OWNER]}
          >
            <SidebarBaseItem
              icon={LauncherIcon}
              title={t('locationLauncher')}
              link={LAUNCHER_URL}
              isSelected={pathname === LAUNCHER_URL}
            />
          </Authorize>
        </>
      )}
      {/* {flag && isLauncherEnabled && (
      )} */}
    </SidebarSection>
  );
};

MainSection.displayName = 'sub-components/Sidebar/sections/main/MainSection';

export default MainSection;
