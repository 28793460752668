import React, { FC } from 'react';
import {
  AlertDialogCloseButton,
  Box,
  Flex,
  useModalContext,
} from '@chakra-ui/react';
import { ActionButton, BoxHeader } from '../../../../../../ui-components';
import { HeaderColors } from '../../../../../../configs';
import { BaseBoardProps } from './delete-board.types';

interface IProps extends BaseBoardProps {}

const DeleteBoard: FC<IProps> = ({ deleteProps }) => {
  const { onClose } = useModalContext();

  const _onSaveClick = async () => {
    if (deleteProps?.onDeleteClick) {
      await deleteProps.onDeleteClick?.(true);
      onClose();
    }
  };

  return (
    <Flex flexDir='column' gap={5}>
      <Flex paddingTop={3} justify='space-between'>
        <BoxHeader
          color={HeaderColors.Red.Dark}
          title={`Delete “${deleteProps?.title}” ?`}
          fontSize='18px'
          boxWidth={3}
        />

        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
        />
      </Flex>

      <Flex flexDir='column'>
        <Box fontSize='16px' fontWeight={700} color='#FF6A55'>
          Are you sure you want to delete “{deleteProps?.title}” ?
        </Box>
        <Box fontSize='16px' fontWeight={500} color='#6F767E'>
          This action will permanently delete this board from the organization.
          This action can’t be undone.
        </Box>
      </Flex>

      <Flex gap={4}>
        <ActionButton flex={1} size='lg' variant='outline' actionFn={onClose}>
          Cancel
        </ActionButton>
        <ActionButton
          flex={1}
          size='lg'
          colorScheme='red'
          actionFn={_onSaveClick}
        >
          Delete
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default DeleteBoard;
