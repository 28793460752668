import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faClockRotateLeft,
} from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight, faSortDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PrimaryButton from 'atoms/PrimaryButton';
import { IAllSessionsForTaskItemsEntity } from 'pages/TasksDashboard/tasks.types';
import React, { FC, useContext, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import SupervisedTaskDetailsDataContext from '../store/supervised-task-details-context';
import moment from 'moment-timezone';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import { ReactComponent as DarkEditIcon } from '../../../../assets/images/edit-dark.svg';
import { AmplitudeEvent, deployEvent } from '../../../../shared';

interface IProps {
  historyData: IAllSessionsForTaskItemsEntity[];
  isMore: boolean;
  selectedDateString: string;
  fetchSpecificSession: (sessionId: string) => void;
  setShowTimeline: React.Dispatch<React.SetStateAction<boolean>>;
  setSessionId: React.Dispatch<React.SetStateAction<string>>;
  setSelectedDateString: React.Dispatch<React.SetStateAction<string>>;
}

interface ILocationState {
  backToTitle: string;
}

const TaskDetailsHeader: FC<IProps> = ({
  historyData,
  isMore,
  selectedDateString,
  fetchSpecificSession,
  setShowTimeline,
  setSessionId,
  setSelectedDateString,
}) => {
  const { t } = useTranslation(['common', 'task']);
  const supervisedCtx = useContext(SupervisedTaskDetailsDataContext);
  const history = useHistory();
  const params = useParams<{ id: string; sessionId: string }>();
  const locState = useLocation<ILocationState>()?.state;
  const userData = useReactiveVar(userObj);
  let _now = moment()?.tz(userData?.timezone);
  let now = moment.utc(
    _now?.format('DD-MMM-YYYY hh:mm a'),
    'DD-MMM-YYYY hh:mm a'
  );

  const editHandler = () => {
    if (!params?.id) return;
    deployEvent(AmplitudeEvent.TASK_EDIT);
    history.push(`/tasks/edit/${params?.id}`);
  };

  return (
    <Flex justify='space-between' align='center'>
      <Flex
        gap='15px'
        cursor='pointer'
        onClick={() => {
          if (locState?.backToTitle) {
            history.goBack();
          } else {
            history.push('/tasks/supervised');
          }
        }}
      >
        <FontAwesomeIcon icon={faArrowLeft as IconProp} fontSize='30px' />
        <Flex gap='10px' flexDir='column'>
          <Text fontWeight={700} fontSize='26px' p={0} m={0}>
            {supervisedCtx?.taskDetails?.title}
          </Text>
          <Text fontWeight={400} color='#6F767E' p={0} m={0}>
            {locState?.backToTitle || t('task:backToTasks')}
          </Text>
        </Flex>
      </Flex>
      <Flex gap='10px'>
        <PrimaryButton
          title={t('common:edit')}
          variant='solid'
          style={{ backgroundColor: 'white' }}
          leftIcon={<DarkEditIcon style={{ width: '20px' }} />}
          onClick={editHandler}
        />
        {supervisedCtx?.taskDetails?.repeatCycle && (
          <Box>
            <Menu>
              <MenuButton>
                <PrimaryButton
                  title={selectedDateString}
                  variant='solid'
                  style={{ backgroundColor: 'white' }}
                  leftIcon={
                    <FontAwesomeIcon icon={faClockRotateLeft as IconProp} />
                  }
                  rightIcon={
                    <FontAwesomeIcon
                      icon={faSortDown as IconProp}
                      color='#6F767E'
                      style={{ marginBottom: '5px' }}
                    />
                  }
                />
              </MenuButton>
              <MenuList p={0} w='200px'>
                {/* <MenuItem
                  px={6}
                  py={2}
                  flexDirection='column'
                  alignItems='baseline'
                  onClick={() => {
                    setSelectedDateString('Today');
                    fetchTodaySession();
                    setSessionId(undefined);
                  }}
                >
                  <Text fontWeight={600} fontSize='14px'>
                    Today ({now?.format('DD MMM')})
                  </Text>
                  <Text fontWeight={500} fontSize='12px' color='#92929D'>
                    {now?.format('ddd')}
                  </Text>
                </MenuItem> */}
                {historyData?.length > 0 ? (
                  <>
                    {historyData?.map((history) => (
                      <MenuItem
                        key={history?.eid}
                        px={6}
                        py={2}
                        onClick={() => {
                          fetchSpecificSession(history?.eid);
                          setSelectedDateString(
                            moment(history?.dueDate)?.utc()?.format('DD MMM')
                          );
                        }}
                      >
                        <Flex
                          flexDir='column'
                          alignItems='flex-start'
                          justifyContent='center'
                        >
                          <Text fontWeight={600} fontSize='14px'>
                            {moment(history?.dueDate)?.utc()?.format('DD MMM')}
                          </Text>
                          <Text
                            fontWeight={500}
                            fontSize='12px'
                            color='#92929D'
                          >
                            {moment(history?.dueDate)?.utc()?.format('ddd')}
                          </Text>
                        </Flex>
                      </MenuItem>
                    ))}
                    {isMore && (
                      <Flex
                        color='#2A85FF'
                        fontWeight={500}
                        fontSize='14px'
                        justify='space-between'
                        align='center'
                        px={6}
                        py={2}
                        cursor='pointer'
                        onClick={() => setShowTimeline(true)}
                      >
                        <Text>View more</Text>
                        <FontAwesomeIcon icon={faChevronRight as IconProp} />
                      </Flex>
                    )}
                  </>
                ) : (
                  <Text p={4} textAlign='center' fontWeight='bold'>
                    No Previous history found
                  </Text>
                )}
              </MenuList>
            </Menu>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default TaskDetailsHeader;
