import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  useToast,
} from '@chakra-ui/react';
import { Controller, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ActionButton, BoxHeader } from '../../../../ui-components';
import TitleHeader from '../../../../sub-components/CardEditor/TitleHeader';
import { FormInput, PrimaryButton, SelectOption } from '../../../../atoms';
import Dropdown from '../../../../atoms/Dropdown';
import { toArray } from '../../../../utils/utils';
import { useUserDataSelector } from '../../../../hooks';
import { shallowEqual } from '../../../../utils';
import {
  EMAIL_REGEX,
  EMAIL_REGEX_V2,
  PHONE_NUMBER_REGEX,
} from '../../../../utils/constants';

import OwnerAutocomplete, { IEntityUser } from './OwnerAutocomplete';
import { EntityUser } from '../../../../sub-components/tasks/performance/common';
import InputButton from './InputButton';
import CustomInput from './CustomInput';
import { useMutation } from '@apollo/client';
import {
  InviteResponse,
  InviteVariable,
  INVITE_USER_QUERY,
  SendInviteResponse,
  SendInviteVariable,
  SEND_INVITE_QUERY,
} from 'ui-components/InviteUserNew/invite.graphql';
import { AuthRole } from 'authorization';
import { getUsersEntityHandler } from '../../../../shared/graphql/SharedGraphql';
import { IFormInput } from 'pages/LocationsNew/AddLocation/add-location.types';

interface InputProps {
  eid: string;
  isExistingMember?: boolean;
  name: string;
  job: SelectOption | null;
  email?: string;
  phone: string;
}

interface IProps {
  title: string;
  selectOwnerHandler: (id: string) => void;
  locationId: string;
  isRequired?: boolean;
  ownerPath: 'locationOwnerOne' | 'locationOwnerTwo';
}

const LocationOwner: FC<IProps> = ({
  title,
  selectOwnerHandler,
  locationId,
  isRequired,
  ownerPath,
}) => {
  const entityRoles = useUserDataSelector(
    (state) => state?.entity?.roles,
    shallowEqual
  );

  const { execute: refetchUsersEntity } = getUsersEntityHandler();

  /** Used to distinguish between user selected from dropdown (existing user) or newly added user (invited on the go) */
  const [isUserSelectedFromDropdown, setIsUserSelectedFromDropdown] =
    useState(false);

  /** Used to show Phone Number and Email fields only after the newly added user is success and now can be invited through phone number or email or both. */
  const [userInviteSuccess, setUserInviteSuccess] = useState(false);

  const [invitedUserId, setInvitedUserId] = useState<string | undefined>(
    undefined
  );

  const [emailSent, setEmailSent] = useState(false);
  const [smsSent, setSmsSent] = useState(false);

  const { t } = useTranslation([
    'common',
    'profile',
    'role',
    'location',
    'invite',
    'setting',
  ]);

  const { control, getValues, setValue, reset } = useFormContext<IFormInput>();
  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });

  const jobOptions = useMemo(() => {
    return toArray(entityRoles).map((location) => {
      return {
        label: location.name,
        value: location.name,
        type: 'job',
      };
    });
  }, [entityRoles]);

  const isExistingMember = useWatch({
    control: control,
    name: `${ownerPath}.isExistingMember`,
  });

  const loOneWatch = useWatch({
    control: control,
    name: 'locationOwnerOne',
  });

  console.log('is existing member : ', isExistingMember);

  const disableHandler = () => {
    if (ownerPath === 'locationOwnerOne') {
      return false;
    } else {
      if (
        !loOneWatch.name &&
        !loOneWatch?.job?.value &&
        (!loOneWatch?.phone || !loOneWatch?.email)
      ) {
        return true;
      }
      return false;
    }
  };

  // const smsSent: boolean = false;
  // const emailSent: boolean = false;

  const [sendSmsInvite] = useMutation<SendInviteResponse, SendInviteVariable>(
    SEND_INVITE_QUERY,
    {
      onCompleted: () => {
        toast({
          status: 'success',
          title: t('success'),
          description: t('invite:invite_send_to_success'),
        });
        setSmsSent(true);
      },
      onError: () => {
        toast({
          status: 'error',
          title: t('error'),
        });
      },
    }
  );

  const [sendEmailInvite] = useMutation<SendInviteResponse, SendInviteVariable>(
    SEND_INVITE_QUERY,
    {
      onCompleted: () => {
        toast({
          status: 'success',
          title: t('success'),
          description: t('invite:invite_send_to_success'),
        });
        setEmailSent(true);
      },
      onError: () => {
        toast({
          status: 'error',
          title: t('error'),
        });
      },
    }
  );

  const [inviteUser, { loading }] = useMutation<InviteResponse, InviteVariable>(
    INVITE_USER_QUERY,
    {
      onCompleted: (response) => {
        toast({
          status: 'success',
          title: 'Location Owner added successfully',
        });
        setValue('isExistingMember', false);
        setIsUserSelectedFromDropdown(false);
        setUserInviteSuccess(true);
        setInvitedUserId(response?.inviteUser?.eid);
        selectOwnerHandler(response?.inviteUser?.eid);
        refetchUsersEntity();
      },
      onError: (error) => {
        toast({
          status: 'error',
          title: t('error'),
          description: error?.message,
        });
      },
    }
  );

  const onSendEmailInvite = async () => {
    await sendEmailInvite({
      variables: {
        input: {
          eid: invitedUserId!,
          contact: getValues(`${ownerPath}.email`)!,
          type: 'email',
        },
      },
    });
  };

  const onSendPhoneInvite = async () => {
    await sendSmsInvite({
      variables: {
        input: {
          eid: invitedUserId!,
          contact: getValues(`${ownerPath}.phone`)?.includes('+')
            ? getValues(`${ownerPath}.phone`)
            : `+1${getValues(`${ownerPath}.phone`)}`,
          type: 'sms',
        },
      },
    });
  };

  // const onSendInvite = () => {
  //   console.log('asdkjashdjasdjk');
  // };

  const onOwnerSelect = (data: EntityUser) => {
    console.log('ON OWNER SELECT : : : : : ', data);
    setValue(`${ownerPath}.name`, data.name || '');
    setValue(`${ownerPath}.eid`, data.eid || '');
    setValue(
      `${ownerPath}.job`,
      data.role
        ? {
            value: data.role,
            label: data.role,
          }
        : null
    );
    setValue(`${ownerPath}.email`, data.email || '');
    // @ts-ignore
    setValue(`${ownerPath}.phone`, data.phone || '');
    setValue(`${ownerPath}.isExistingMember`, true);
    selectOwnerHandler(data.eid);
    setIsUserSelectedFromDropdown(true);
    setUserInviteSuccess(false);
    setSmsSent(false);
    setEmailSent(false);
  };

  const noMemberFoundFn = () => {
    setValue(`${ownerPath}.isExistingMember`, false);
    setValue(`${ownerPath}.phone`, '');
    setValue(`${ownerPath}.email`, '');
    setIsUserSelectedFromDropdown(false);
  };

  const emptyFieldFn = () => {
    // reset();
    setValue(`${ownerPath}.isExistingMember`, false);
    setValue(`${ownerPath}.phone`, '');
    setValue(`${ownerPath}.email`, '');
    setValue(`${ownerPath}.job`, null);
    setIsUserSelectedFromDropdown(false);
    setSmsSent(false);
    setEmailSent(false);
  };

  // const inviteUserHandler = async () => {
  //   await inviteUser({
  //     variables: {
  //       input: {
  //         name: getValues('name')?.trim(),
  //         authRole: AuthRole.LOCATION_OWNER,
  //         role: getValues('job')?.value!,
  //         branchIds: toArray(locationId)
  //           .map((it) => it)
  //           .filter(Boolean),
  //       },
  //     },
  //   });
  // };

  const getNotShowOwnerData = (): IEntityUser | undefined => {
    let ownerData =
      ownerPath === 'locationOwnerOne'
        ? getValues('locationOwnerTwo')
        : getValues('locationOwnerOne');
    if (ownerData?.eid) {
      return {
        authRole: AuthRole.LOCATION_OWNER,
        eid: ownerData?.eid,
        name: ownerData?.name,
        role: ownerData?.job?.value!,
        type: 'user',
        status: 'active',
        locations: [],
        createdAt: '',
      };
    }
    return undefined;
  };

  return (
    <Flex bg='white' borderRadius='8px' p={8} gap='20px' flexDir='column'>
      <BoxHeader
        fontSize='16px'
        color='#CABDFF'
        // title={t('location:projectStartAndSupervisor')}
        title={title}
        isRequired={isRequired}
      />

      <Grid templateColumns='repeat(2, 1fr)' gap='20px'>
        <Controller
          control={control}
          name={`${ownerPath}.name`}
          defaultValue=''
          rules={{
            // required: {
            //   value: !isDisabled,
            //   message: t('location:validation.entityNameRequired'),
            // },
            validate: (value) => {
              if (value && value?.trim()?.length === 0) {
                return t('location:validation.entityNameInvalid');
              }
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl
                // isDisabled={isDisabled}
                isInvalid={!!fieldState.error}
                isDisabled={disableHandler()}
              >
                <TitleHeader title={t('common:name')} />

                <Box h={2} />
                <OwnerAutocomplete
                  placeholder={t('invite:enter_name')}
                  onSelect={onOwnerSelect}
                  noMemberFoundFn={noMemberFoundFn}
                  emptyFieldFn={emptyFieldFn}
                  notShowOwner={getNotShowOwnerData()}
                  {...field}
                />

                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Box>
          <TitleHeader title={t('role:job')} />

          <Controller
            control={control}
            name={`${ownerPath}.job`}
            rules={{
              validate: (value) => {
                if (
                  !getValues(`${ownerPath}.job.value`)?.trim() &&
                  (getValues(`${ownerPath}.name`)?.trim() ||
                    getValues(`${ownerPath}.job.value`))
                ) {
                  return 'Job is required';
                }
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <FormControl
                  mt={2}
                  isInvalid={!!fieldState.error}
                  isReadOnly={isExistingMember || isUserSelectedFromDropdown}
                >
                  <Dropdown
                    isDisabled={
                      isExistingMember ||
                      isUserSelectedFromDropdown ||
                      disableHandler()
                    }
                    placeholder={t('role:selectJob')}
                    size='lg'
                    options={jobOptions}
                    {...field}
                    selectStyles={{
                      container: {
                        backgroundColor: 'white',
                      },
                    }}
                  />

                  <FormErrorMessage>
                    <span>{fieldState.error?.message}</span>
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />
        </Box>

        <>
          <Box>
            <TitleHeader title={t('common:phone_number')} />

            <Controller
              name={`${ownerPath}.phone`}
              control={control}
              rules={{
                pattern: {
                  // value: /^[0-9]{10}$/,
                  value: PHONE_NUMBER_REGEX,
                  message: t('location:validation.phone_invalid'),
                },
                validate: (value) => {
                  if (
                    !getValues(`${ownerPath}.email`)?.trim() &&
                    !value?.trim() &&
                    (getValues(`${ownerPath}.name`)?.trim() ||
                      getValues(`${ownerPath}.job.value`))
                  ) {
                    return 'Phone number or email is required';
                  }
                },
              }}
              defaultValue=''
              render={({ field, fieldState }) => {
                return (
                  <FormControl
                    mt={2}
                    isInvalid={!!fieldState.error}
                    isDisabled={disableHandler()}
                    isReadOnly={isExistingMember || isUserSelectedFromDropdown}
                  >
                    <CustomInput
                      size='lg'
                      placeholder='556 456 3456'
                      variant='outline'
                      {...field}
                      buttonProps={{
                        alreadyUser:
                          isExistingMember || isUserSelectedFromDropdown,
                        inviteSent: smsSent,
                        actionFn: onSendPhoneInvite,
                        isDisabled:
                          !field.value ||
                          isExistingMember ||
                          isUserSelectedFromDropdown,
                      }}
                    />

                    <FormErrorMessage>
                      <span>{fieldState.error?.message}</span>
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Box>

          <Box>
            <TitleHeader title={t('setting:email')} />

            <Controller
              name={`${ownerPath}.email`}
              control={control}
              rules={{
                pattern: {
                  value: EMAIL_REGEX_V2,
                  message: t('common:validation.email_invalid'),
                },
                validate: (value) => {
                  if (
                    !getValues(`${ownerPath}.phone`)?.trim() &&
                    !value?.trim() &&
                    (getValues(`${ownerPath}.name`)?.trim() ||
                      getValues(`${ownerPath}.job.value`))
                  ) {
                    return 'Phone number or email is required';
                  }
                },
              }}
              defaultValue=''
              render={({ field, fieldState }) => {
                return (
                  <FormControl
                    mt={2}
                    isInvalid={!!fieldState.error}
                    isDisabled={disableHandler()}
                    isReadOnly={isExistingMember || isUserSelectedFromDropdown}
                  >
                    <CustomInput
                      size='lg'
                      placeholder='joey@gmail.com'
                      variant='outline'
                      {...field}
                      buttonProps={{
                        alreadyUser:
                          isExistingMember || isUserSelectedFromDropdown,
                        inviteSent: emailSent,
                        actionFn: onSendEmailInvite,
                        isDisabled:
                          !field.value ||
                          isExistingMember ||
                          isUserSelectedFromDropdown,
                      }}
                    />
                    <FormErrorMessage>
                      <span>{fieldState.error?.message}</span>
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Box>
        </>
      </Grid>
      {/* {!isExistingMember && !userInviteSuccess && (
        <PrimaryButton
          title={`Invite ${title}`}
          disabled={!nameWatch || loading || !jobWatch?.value}
          isLoading={loading}
          variant='solid'
          colorScheme='blue'
          width='fit-content'
          onClick={inviteUserHandler}
        />
      )} */}
    </Flex>
  );
};

export default LocationOwner;
