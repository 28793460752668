import React, { FC } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { VStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { ILocationDetails } from 'pages/LocationDetails/utils/location-details.types';
import { DocumentListing, Heading } from './components';
import { useDocumentsUpload } from './hooks/useDocumentsUpload';

interface IProps {
  documents?: ILocationDetails['files'];
  refetch:
    | ((
        variables?:
          | Partial<{
              eid: string;
            }>
          | undefined
      ) => Promise<
        ApolloQueryResult<{
          userById: ILocationDetails;
        }>
      >)
    | undefined;
}

const Documents: FC<IProps> = ({ documents, refetch }) => {
  const { locationId } = useParams<{ locationId: string }>();
  const documentsUpload = useDocumentsUpload();

  const onClickHandler = () => {
    documentsUpload({
      uploadedFiles: documents || [],
      refetchHandler,
      locationId,
      type: 'add',
    });
  };

  const refetchHandler = () => {
    refetch && refetch();
  };

  const onEditFileClickHandler = (index: number) => {
    documentsUpload({
      uploadedFiles: documents || [],
      refetchHandler,
      locationId,
      type: 'edit',
      index,
    });
  };

  return (
    <VStack align='baseline' gap={4}>
      <Heading onClickHandler={onClickHandler} />
      <DocumentListing
        documents={documents}
        refetchHandler={refetchHandler}
        locationId={locationId}
        onEditFileClickHandler={onEditFileClickHandler}
      />
    </VStack>
  );
};

Documents.displayName =
  'pages/LocationDetails/components/LocationAssetsCard/components/Documents/Documents';

export default Documents;
