import React, { FC } from 'react';
import {
  Modal,
  Button,
  useDisclosure,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  Text,
  Flex,
  Center,
} from '@chakra-ui/react';
import { ReactComponent as WarningIcon } from 'assets/images/notifications/warning.svg';

interface IProps {
  onOkPress?: () => void;
  onCancelPress?: () => void;
}

const ModalContent: FC<IProps> = ({ onOkPress, onCancelPress }) => {
  return (
    <Flex flexDir='column'>
      <Center>
        <WarningIcon height={'60px'} width={'60px'} />
      </Center>
      <Text mt={'25px'} textAlign={'center'} color={'#33383F'}>
        You have chosen <strong>not to be notified</strong> of anything. This
        means you will <strong>not be updated</strong> of any changes and
        updates. You can always change this in the notification settings!
      </Text>
      <Stack mt={8}>
        <Button
          isFullWidth
          colorScheme={'blue'}
          variant={'solid'}
          onClick={onOkPress}
        >
          Continue
        </Button>
        <Button
          isFullWidth
          border={'1px solid #6F767E'}
          variant={'solid'}
          bg={'#FFFFFF'}
          _hover={{ backgroundColor: '#FFFFFF' }}
          onClick={onCancelPress}
        >
          Cancel
        </Button>
      </Stack>
    </Flex>
  );
};

export default ModalContent;
