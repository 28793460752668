import React, { FC, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';

import { ReactComponent as LauncherIcon } from '../../../../assets/images/sidebar/launcher.svg';
import {
  LAUNCHER_BOARDS,
  LAUNCHER_DASHBOARD,
  LAUNCHER_LOCATION_DETAIL,
  LAUNCHER_OPENING_TASKS,
  LAUNCHER_SETUP,
  LAUNCHER_TASKS,
} from '../../../../appRoutes';
import { useUserDataSelector, useUserEntity } from '../../../../hooks';
import { SidebarBaseItem, SubItem } from '../../common';
import { Authorize, AuthRole } from '../../../../authorization';
import { isLauncherPublished } from '../../../../configs';

const QUERY = gql`
  query LocationLaunchSupervisorTasks {
    LocationLaunchSupervisorTasks {
      launchId
    }
  }
`;

interface Response {
  LocationLaunchSupervisorTasks: never[];
}

interface IProps {
  isSelected: boolean;
  isExpand: boolean;
}

interface LProps extends IProps {
  onClick: () => void;
}

const Launcher: FC<LProps> = ({
  isSelected,
  isExpand: isExpanded,
  onClick,
}) => {
  const { t } = useTranslation(['sidebar']);
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const isSuperAdmin = useUserDataSelector(
    (state) => state.type === 'user' && state.authRole === AuthRole.SUPER_ADMIN
  );

  const selectedType = history.location.pathname;

  const isTaskDetailsPath = (path: string) => {
    /**
     * Regex to match `/launcher/my-tasks/` followed by exactly 3 path segments
     */
    const pattern = /^\/launcher\/my-tasks\/[^/]+\/[^/]+\/[^/]+$/;
    return pattern.test(path);
  };

  // const { data } = useQuery<Response>(QUERY, {
  //   skip: isSuperAdmin,
  // });

  // const haveLocation = !!data?.LocationLaunchSupervisorTasks?.length;

  // if (!(isSuperAdmin || haveLocation)) {
  //   return null;
  // }

  return (
    <>
      <SidebarBaseItem
        icon={LauncherIcon}
        title={t('sidebar:locationLauncher')}
        link={LAUNCHER_DASHBOARD}
        isSelected={isSelected}
        onClick={onClick}
      />

      {isExpanded && (
        <div
          className='sidebar-item-list-container'
          style={{ marginBottom: '20px' }}
        >
          <Authorize
            permittedFor={['user']}
            permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
          >
            <Link to={LAUNCHER_DASHBOARD}>
              <SubItem
                isSelected={[
                  LAUNCHER_DASHBOARD,
                  LAUNCHER_LOCATION_DETAIL,
                ].includes(routeMatch?.path)}
                title={t('sidebar:dashboard')}
                hideIcon
              />
            </Link>
          </Authorize>

          <Authorize
            permittedFor={['user']}
            permittedRoles={[AuthRole.SUPER_ADMIN]}
          >
            <Link to={LAUNCHER_BOARDS}>
              <SubItem
                isSelected={selectedType.includes(LAUNCHER_BOARDS)}
                title={t('sidebar:myBoards')}
                hideIcon
              />
            </Link>
          </Authorize>

          <Link to={LAUNCHER_TASKS}>
            <SubItem
              isSelected={
                selectedType === LAUNCHER_TASKS ||
                isTaskDetailsPath(selectedType)
              }
              title={t('sidebar:launcherTasks')}
              hideIcon
            />
          </Link>
        </div>
      )}
    </>
  );
};

const SidebarLauncher: FC<IProps> = ({ isSelected, isExpand }) => {
  const [isExpanded, setIsExpanded] = useState(isExpand);

  const onExpandStateChange = () => {
    setIsExpanded((s) => !s);
  };

  // if (!launcherPublished) {
  //   return (
  //     <Authorize
  //       permittedFor={['user']}
  //       permittedRoles={[AuthRole.SUPER_ADMIN]}
  //     >
  //       <SidebarBaseItem
  //         icon={LauncherIcon}
  //         title={t('locationLauncher')}
  //         link={haveLauncher ? LAUNCHER_OPENING_TASKS : LAUNCHER_SETUP}
  //         isSelected={
  //           haveLauncher
  //             ? selectedType === LAUNCHER_OPENING_TASKS
  //             : selectedType === LAUNCHER_SETUP
  //         }
  //       />
  //     </Authorize>
  //   );
  // }

  return (
    <Launcher
      isSelected={isSelected}
      isExpand={isExpanded}
      onClick={onExpandStateChange}
    />
  );
};

Launcher.displayName = 'sub-components/Sidebar/sections/main/SidebarLauncher';
SidebarLauncher.displayName =
  'sub-components/Sidebar/sections/main/SidebarLauncher';

export default SidebarLauncher;
