import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Drawer,
  DrawerHeader,
  DrawerContent,
  IconButton,
  Text,
  Box,
  Flex,
  Image,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  DrawerBody,
  Center,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Subtasks } from '../Subtasks';
import { HeaderColors } from 'configs';
import check from 'assets/images/dashboard/check.svg';
import reload from 'assets/images/dashboard/reload.svg';

import {
  LocationPhaseDetails,
  PhaseTask,
} from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import {
  faClock,
  faPaperclip,
  faStopwatch,
  faTag,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { useMarkAsComplete } from '../Subtasks/useMarkAsComplete';
import { useAddLauncherTaskFiles } from './useAddLauncherTaskFiles';
import { useUserDataSelector } from 'hooks';
import useCombinedStore from 'zustandStore/store';
import FileDropzone from './FileDropzone';
import { useComplianceUpload } from 'sub-components/nexus/Compliance';
import Attachments from './Attachments/Attachments';
import moment from 'moment';
import { Button } from 'atoms';
import { useMarkAsUndone } from '../Subtasks/useMarkAsUndone';
import { BoxHeader } from 'ui-components';
import {
  getTaskCategoryLabel,
  getTaskLabelByStatus,
  onPhaseTaskCompleted,
} from 'sub-components/Launcher/dashboard/Location/helper';
import { LauncherLocationTask } from 'sub-components/Launcher/dashboard/Location';
import { LTaskCategoryEntity } from 'sub-components/Launcher/launcher-config/common/launcher-config.graphql';
import Loader from 'sub-components/Loader';
import { ITaskStatus } from 'ui-components/TaskStatusIcon/types';
import { Tooltip } from '@chakra-ui/react';
import { useLazyQuery } from '@apollo/client';
import { LOCATION_LAUNCH_TASK_BY_ID } from 'sub-components/Launcher/tasks/components/task-details/api';
import {
  LocationLaunchTaskByIdResponse,
  LocationLaunchTaskByIdVariable,
} from 'sub-components/Launcher/tasks/components/task-details/types';
import { UserData } from 'sop-commons/src/client';
import { ILocationLaunchTask } from '../../types';
import { ReactComponent as FormiconIcon } from 'assets/images/formIcon.svg';

interface IProps {
  status: 'overdue' | 'locked' | 'yetToStart' | 'inProgress' | string;
  launchId?: string;
  locationId: string;
  task: PhaseTask;
  isOpen: boolean;
  toggleDrawer: () => void;
  refetchData?: (
    resData?: ILocationLaunchTask,
    phaseDetails?: LocationPhaseDetails[],
    task?: PhaseTask
  ) => void;
  phaseDetails?: LocationPhaseDetails[];
  categories: LTaskCategoryEntity[];
}

const StateDrawer: FC<IProps> = ({
  status,
  task,
  launchId,
  locationId,
  isOpen,
  refetchData,
  toggleDrawer,
  phaseDetails,
  categories,
}) => {
  const {
    stateDrawer: { loading, updateLoading },
  } = useCombinedStore();
  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });
  const { selectedInProgressLocation } = useCombinedStore();
  const {
    onClose: onUnlockedTaskModalClose,
    onOpen: onUnlockedTaskModalOpen,
    isOpen: isUnlockedTaskModalOpen,
  } = useDisclosure();

  const complianceUpload = useComplianceUpload();
  const { markAsComplete, markAsCompleteLoading } = useMarkAsComplete();
  const { markAsUndone, markAsUndoneLoading } = useMarkAsUndone();

  const [newUnlockedTasks, setNewUnlockedTasks] = useState<PhaseTask[]>([]);

  const isTaskCompleted = !!(
    task?.completedAt && task?.steps?.every((step) => step?.completedAt)
  );

  const [
    getLocationLaunchTaskById,
    {
      loading: gettingLocationLaunchTaskById,
      data: locationLaunchTaskByIdData,
      refetch: locationLaunchTaskByIdRefetch,
    },
  ] = useLazyQuery<
    LocationLaunchTaskByIdResponse,
    LocationLaunchTaskByIdVariable
  >(LOCATION_LAUNCH_TASK_BY_ID, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (isOpen && launchId && task?.eid) {
      getLocationLaunchTaskByIdHandler();
    }
  }, [launchId, task?.eid, isOpen]);

  const getLocationLaunchTaskByIdHandler = () => {
    if (launchId && task?.eid) {
      getLocationLaunchTaskById({
        variables: {
          launchId,
          taskId: task?.eid,
        },
      });
    }
  };

  const addLauncherTaskFiles = useAddLauncherTaskFiles();

  const _refetch = async () => {
    if (locationLaunchTaskByIdRefetch) {
      try {
        const { data } = await locationLaunchTaskByIdRefetch();
        refetchData?.(data?.LocationLaunchTaskById?.task, phaseDetails, task);
      } catch (err) {}
    }
  };

  const attachmentUrls =
    locationLaunchTaskByIdData?.LocationLaunchTaskById?.complianceFiles
      ?.filter((f) => f?.status === 'active' && f?.locationId === locationId)
      ?.flatMap((f) => f?.files?.map((file) => file?.url)) || [];

  const fields = useMemo(() => {
    const fields = [
      {
        label: 'Status',
        icon: <FontAwesomeIcon icon={faClock as IconProp} />,
        value: (
          <Box
            as='span'
            fontSize='12px'
            wordBreak='break-word'
            fontWeight={500}
            textTransform={'capitalize'}
          >
            {getTaskLabelByStatus(status as ITaskStatus)}
          </Box>
        ),
      },
      {
        label: 'Tags',
        icon: <FontAwesomeIcon icon={faTag as IconProp} />,
        value: task.category ? (
          <Box
            as='span'
            fontSize='12px'
            wordBreak='break-word'
            fontWeight={500}
            background='#F4F4F4'
            padding='4px 8px'
            borderRadius='6px'
          >
            {getTaskCategoryLabel(categories, task?.category as string)}
          </Box>
        ) : null,
      },
      {
        label: 'Description',
        icon: <FontAwesomeIcon icon={faClock as IconProp} />,
        value: (
          <Box
            as='span'
            fontSize='12px'
            wordBreak='break-word'
            fontWeight={500}
            textTransform={'capitalize'}
          >
            {task?.description || '-'}
          </Box>
        ),
      },
    ];

    const taskDuration = task.triggerDetails?.deadlineOffset
      ? moment.duration(task.triggerDetails.deadlineOffset).asDays()
      : 0;

    if (task.status !== 'locked') {
      fields.splice(1, 0, {
        label: 'Due date',
        icon: <FontAwesomeIcon icon={faCalendar as IconProp} />,
        value: (
          <Box
            as='span'
            fontSize='12px'
            wordBreak='break-word'
            fontWeight={500}
          >
            {moment(task?.dueDate)?.isValid() && task?.dueDate
              ? moment(task?.dueDate)?.format('DD MMMM YYYY')
              : '-'}
          </Box>
        ),
      });

      fields.splice(2, 0, {
        label: 'Task duration',
        icon: <FontAwesomeIcon icon={faStopwatch as IconProp} />,
        value: !task.triggerDetails?.deadlineOffset ? (
          <Box
            as='span'
            fontSize='12px'
            wordBreak='break-word'
            fontWeight={500}
            textTransform={'capitalize'}
          >
            {'-'}
          </Box>
        ) : (
          <Box
            as='span'
            fontSize='12px'
            wordBreak='break-word'
            fontWeight={500}
            textTransform={'capitalize'}
          >
            {`${taskDuration} Day${taskDuration > 1 ? 's' : ''} `}
          </Box>
        ),
      });

      fields.push({
        label: 'Attachments',
        icon: <FontAwesomeIcon icon={faPaperclip as IconProp} />,
        value: (
          <Attachments
            files={
              locationLaunchTaskByIdData?.LocationLaunchTaskById?.complianceFiles?.filter(
                (f) => f?.status === 'active'
              ) || []
            }
            launchId={launchId || selectedInProgressLocation?.launchId!}
            locationId={locationId}
            taskId={task?.eid}
            status={task?.status}
            refetchData={() => {
              locationLaunchTaskByIdRefetch?.();
              _refetch?.();
            }}
          />
        ),
      });

      fields.push({
        label: 'Others',
        icon: <FontAwesomeIcon icon={faPaperclip as IconProp} />,
        value: (
          <Flex gap={2} flexWrap='wrap'>
            {task?.files?.length
              ? task?.files
                  ?.filter((file) => !attachmentUrls?.includes(file?.url))
                  .map((file) => {
                    return (
                      <Flex
                        gap={1}
                        alignItems='center'
                        onClick={() => {
                          window.open && window.open(file.url);
                        }}
                      >
                        {file.type === 'image' ? (
                          <Image
                            src={file.url}
                            height={25}
                            width={25}
                            style={{ borderRadius: '50%' }}
                          />
                        ) : (
                          <Center
                            boxSize='25px'
                            border='1px solid rgba(111, 118, 126, 1)'
                            borderRadius='50%'
                            p='3px'
                          >
                            <FormiconIcon />
                          </Center>
                        )}
                        <Tooltip hasArrow label={file?.name}>
                          <Text
                            as='span'
                            isTruncated
                            maxW='140px'
                            cursor='pointer'
                          >
                            {file?.name}
                          </Text>
                        </Tooltip>
                      </Flex>
                    );
                  })
              : '-'}
          </Flex>
        ),
      });
    }

    return fields;
  }, [task, locationLaunchTaskByIdData?.LocationLaunchTaskById]);

  const handleMarkAsComplete = () => {
    if (task?.steps?.every((step) => step?.completedAt) && task?.completedAt) {
      markAsUndone({
        stepId: '',
        launchId: launchId || selectedInProgressLocation?.launchId!,
        taskId: task?.eid,
        formResponseId: '',
      })
        ?.then((res) => {
          toast({
            status: 'success',
            title: 'Success',
            description: 'Successfully marked task as undone',
          });
          toggleDrawer?.();
          locationLaunchTaskByIdRefetch?.();
          _refetch?.();
        })
        ?.catch(() => {
          toast({
            status: 'error',
            title: 'Error',
            description: 'Failed to mark task as undone',
          });
          toggleDrawer?.();
          locationLaunchTaskByIdRefetch?.();
          _refetch?.();
        });
    } else {
      markAsComplete({
        stepId: '',
        launchId: launchId || selectedInProgressLocation?.launchId!,
        taskId: task?.eid,
        formResponseId: '',
      })
        ?.then((res) => {
          toast({
            status: 'success',
            title: 'Success',
            description: 'Successfully marked task as complete',
          });
          const taskUnlocked = onPhaseTaskCompleted(task, phaseDetails);

          if (taskUnlocked) {
            setNewUnlockedTasks(taskUnlocked);
          }
          toggleDrawer?.();
          locationLaunchTaskByIdRefetch?.();
          _refetch?.();
        })
        ?.catch(() => {
          toast({
            status: 'error',
            title: 'Error',
            description: 'Failed to mark task as complete',
          });
          locationLaunchTaskByIdRefetch?.();
          _refetch?.();
        });
    }
  };

  const handleFileUpload = (files: File[]) => {
    if (files?.length === 0) return;
    complianceUpload({
      initialFile: files?.[0],
      metaComplianceData: {
        launchId: launchId || selectedInProgressLocation?.launchId!,
        taskId: task?.eid,
        phaseId: task?.phaseEid,
        phase: task?.phaseName,
        task: task?.title,
      },
      preDefinedLocationId: locationId,
      successCb: () => {
        locationLaunchTaskByIdRefetch?.();
        _refetch?.();
        // if (!data) return;
        // let filesObj = task?.files?.map((file) => ({
        //   fileSize: file?.fileSize,
        //   mimetype: file?.mimetype || '',
        //   name: file?.name,
        //   type: file?.type,
        //   url: file?.url,
        //   createdBy: { eid: file?.createdBy } as UserData,
        // }));
        // addLauncherTaskFiles({
        //   file: [
        //     ...filesObj,
        //     {
        //       fileSize: data?.file?.fileSize,
        //       mimetype: data?.file?.mimetype || '',
        //       name: data?.file?.name,
        //       type: data?.file?.type,
        //       url: data?.file?.url,
        //       createdBy: { eid: data?.file?.createdBy } as UserData,
        //     },
        //   ],
        //   launchId: launchId || selectedInProgressLocation?.launchId!,
        //   taskId: task?.eid,
        //   onSuccess: () => {
        //     toast({
        //       status: 'success',
        //       title: 'Success',
        //       description: 'File added successfully',
        //     });
        //     locationLaunchTaskByIdRefetch?.();
        //     _refetch?.();
        //   },
        //   onError: () => {
        //     toast({
        //       status: 'error',
        //       title: 'Error',
        //       description: 'File could not be added',
        //     });
        //   },
        // });
      },
    });
  };

  useEffect(() => {
    if (newUnlockedTasks.length) {
      onUnlockedTaskModalOpen();
    }
  }, [newUnlockedTasks]);

  const isDisabledFn = () => {
    if (task?.completedAt) {
      return markAsCompleteLoading || markAsUndoneLoading;
    }

    const locFiles =
      locationLaunchTaskByIdData?.LocationLaunchTaskById?.complianceFiles?.filter(
        (f) => f?.status === 'active' && f?.locationId === locationId
      ) || [];

    return (
      markAsCompleteLoading ||
      markAsUndoneLoading ||
      task?.steps?.some((step) => !step?.completedAt) ||
      (task?.docRequired && locFiles.length === 0)
    );
  };

  const isLoading =
    markAsCompleteLoading ||
    markAsUndoneLoading ||
    loading ||
    gettingLocationLaunchTaskById;

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={toggleDrawer}
        size='lg'
      >
        <DrawerContent p='47px'>
          {isLoading && (
            <Box
              position='absolute'
              top='0'
              left='0'
              right='0'
              bottom='0'
              bg='rgba(255, 255, 255, 0.8)'
              zIndex={9999}
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <Loader
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </Box>
          )}
          <DrawerHeader p={0} mb='16px'>
            <Flex justifyContent='space-between' alignItems='center'>
              <Text fontSize='18px' fontWeight={600}>
                {task?.title}
              </Text>
              <IconButton
                aria-label='close'
                variant='ghost'
                onClick={toggleDrawer}
                icon={<CloseIcon h='15px' w='15px' />}
              />
            </Flex>
          </DrawerHeader>
          <DrawerBody p={0} m={0}>
            <Flex flexDir='column' gap={4}>
              {fields.map((field, index) => (
                <Flex
                  key={index}
                  justifyContent='space-between'
                  alignItems='flex-start'
                  mb={2}
                  gap='12px'
                >
                  <Flex align='center' gap={2} color='#6F767E'>
                    {field?.icon}
                    <Text
                      fontSize='12px'
                      fontWeight={400}
                      wordBreak='break-word'
                    >
                      {field.label}
                    </Text>
                  </Flex>
                  <Flex flex='1' maxWidth='70%'>
                    {field.value}
                  </Flex>
                </Flex>
              ))}

              {status !== 'locked' && task?.steps?.length > 0 && (
                <Subtasks
                  isTaskCompleted={isTaskCompleted}
                  launchId={launchId}
                  taskDetails={task}
                  refetchData={_refetch}
                />
              )}

              {status !== 'completed' && status !== 'locked' && (
                <FileDropzone
                  required={task?.docRequired}
                  onFileUpload={handleFileUpload}
                />
              )}

              {status !== 'locked' && (
                <Tooltip
                  hasArrow
                  label='Button will get enabled once all tasks are completed and mandatory files are added if any'
                  fontSize={15}
                  fontWeight={500}
                  padding='8px 16px'
                  background='#000000C7'
                  placement='bottom-end'
                  isDisabled={
                    !(
                      markAsCompleteLoading ||
                      markAsUndoneLoading ||
                      task?.steps?.some((step) => !step?.completedAt) ||
                      status !== 'completed'
                    )
                  }
                >
                  <Box display='inline-block' w='full'>
                    <Button
                      w={'full'}
                      bg={
                        status !== 'completed'
                          ? HeaderColors.DarkBlue
                          : HeaderColors.Red.Dark
                      }
                      colorScheme={status !== 'completed' ? 'blue' : 'red'}
                      variant='solid'
                      rightIcon={
                        <Image
                          width='20px'
                          src={status === 'completed' ? reload : check}
                        />
                      }
                      disabled={isDisabledFn()}
                      my={'12px'}
                      fontSize={'13px'}
                      fontWeight={700}
                      py={'8px'}
                      px={'16px'}
                      _hover={{
                        bg:
                          status !== 'completed'
                            ? HeaderColors.DarkBlue
                            : HeaderColors.Red.Dark,
                      }}
                      onClick={handleMarkAsComplete}
                    >
                      {status !== 'completed'
                        ? 'Mark task as completed'
                        : 'Mark as unfinished'}
                    </Button>
                  </Box>
                </Tooltip>
              )}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Modal
        isOpen={isUnlockedTaskModalOpen}
        isCentered={true}
        onClose={() => {
          onUnlockedTaskModalClose();
          refetchData?.();
          toggleDrawer();
        }}
        size='5xl'
      >
        <ModalOverlay />
        <ModalContent paddingY='8px' borderRadius='12px' paddingBottom='16px'>
          <ModalHeader position='relative'>
            <BoxHeader
              title={`${newUnlockedTasks.length} New tasks unlocked`}
              color={HeaderColors.Blue}
              fontSize='18px'
            />
            <ModalCloseButton position='absolute' top={4} right={5} />
          </ModalHeader>
          <ModalBody>
            <Flex gap={4} flexDir='column'>
              {newUnlockedTasks.map((task, index) => {
                return (
                  <LauncherLocationTask
                    key={index}
                    task={task}
                    locationId={locationId}
                    categories={categories}
                    refetchData={() => {
                      refetchData?.();
                      locationLaunchTaskByIdRefetch?.();
                    }}
                    phaseDetails={phaseDetails || []}
                  />
                );
              })}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

StateDrawer.displayName =
  'displayName:sub-components/Launcher/location-owner/StatesDrawer/StateDrawer/StateDrawer';

export default StateDrawer;
