import React, { FC } from 'react';
import {
  Modal,
  Button,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  Text,
  Flex,
  Center,
} from '@chakra-ui/react';
import { BoxHeader } from '../../../ui-components';
import { ReactComponent as WarningIcon } from 'assets/images/notifications/warning.svg';
interface IProps {
  isAllTypesSelectedWarningOpen: boolean;
  onOkPress?: () => void;
  onCancelPress?: () => void;
  closeAllTypesSelectedWarning: () => void;
}

const AllConfigPreferencesDeselectedWarning: FC<IProps> = ({
  isAllTypesSelectedWarningOpen,
  onOkPress,
  onCancelPress,
  closeAllTypesSelectedWarning,
}) => {
  return (
    <>
      <Center>
        <Modal
          closeOnOverlayClick={true}
          isOpen={isAllTypesSelectedWarningOpen}
          onClose={closeAllTypesSelectedWarning}
          size={'3xl'}
        >
          <ModalOverlay />
          <ModalContent borderRadius={'16px'}>
            <ModalHeader
              pt={'32px'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <BoxHeader
                title='Please note'
                color={'#FFBC99'}
                fontSize={'20px'}
              />
              <ModalCloseButton
                style={{
                  marginTop: 24,
                  marginRight: 20,
                  outline: 'none',
                  boxShadow: 'none',
                  borderRadius: '50%',
                  backgroundColor: '#efefef',
                  color: '#6f767e',
                }}
              />
            </ModalHeader>
            <ModalBody p={'50px 40px'}>
              <Center>
                <WarningIcon height={'60px'} width={'60px'} />
              </Center>
              <Text mt={'25px'} textAlign={'center'} color={'#33383F'}>
                You have chosen <strong>not to be notified</strong> of anything.
                This means you will <strong>not be updated</strong> of any
                changes and updates. You can always change this in the
                notification settings!
              </Text>
              <Stack mt={8}>
                <Button
                  isFullWidth
                  colorScheme={'blue'}
                  variant={'solid'}
                  onClick={onOkPress}
                >
                  Continue
                </Button>
                <Button
                  isFullWidth
                  border={'1px solid #6F767E'}
                  variant={'solid'}
                  bg={'#FFFFFF'}
                  _hover={{ backgroundColor: '#FFFFFF' }}
                  onClick={onCancelPress}
                >
                  Cancel
                </Button>
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Center>
    </>
  );
};
AllConfigPreferencesDeselectedWarning.displayName =
  'sc/NC/S/AllDeselectedWarning.tsx';
export default AllConfigPreferencesDeselectedWarning;
