import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { Loader } from '../../../sub-components';

interface IProps {
  isLoading?: boolean;
}

const BoardLoader: FC<IProps> = ({ isLoading, children }) => {
  if (isLoading) {
    return (
      <Flex flex={1} align='center' justify='center'>
        <Loader size='xl' />
      </Flex>
    );
  }

  return <>{children}</>;
};

export default BoardLoader;
