import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import TitleHeader from '../../../../CardEditor/TitleHeader';
import PhaseAssignee from '../phase-details/PhaseAssignee';
import { StepDetailsForm } from './task-steps';

interface IProps {}

const StepAssigneeDetails: FC<IProps> = () => {
  return (
    <Flex flexDir='column' gap={5}>
      <Flex flexDir='column' gap={3}>
        <div>
          <TitleHeader
            title='Assignees'
            desc='Note: The phase assignee will override these assignees.'
            // isRequired
          />
        </div>

        <PhaseAssignee type='task' />
      </Flex>

      <div>
        <TitleHeader
          title='Steps'
          desc='Add steps to your task'
          // isRequired
        />
      </div>

      <StepDetailsForm />
    </Flex>
  );
};

StepAssigneeDetails.displayName =
  'sub-components/Launcher/launcher-config/common/config-task-form/StepAssigneeDetails';

export default StepAssigneeDetails;
