import moment from 'moment';
import { getTaskStatus } from 'sub-components/Launcher/dashboard/Location/helper';
import { ILocationLaunchTask } from 'sub-components/Launcher/location-owner/types';
import { GroupedPhase } from '../../types';
import { convertTaskData } from '../convertTaskData';
import { defaultConvert } from './defaultConvert';

export const deadlineTriggerProcessing = (
  task: ILocationLaunchTask,
  phase: GroupedPhase[]
): ILocationLaunchTask => {
  // If task is completed, return as 'completed'
  if (task?.completedAt && task?.steps?.every((step) => step?.completedAt)) {
    return {
      ...task,
      status: 'completed',
      unlockMessage: '',
    };
  }

  if (task?.steps?.some((step) => step?.completedAt)) {
    let allTasks = phase?.map((p) => p?.tasks)?.flat();
    let convertedAllTasks = allTasks?.map((all) => convertTaskData(all));
    let { status } = getTaskStatus(
      convertTaskData(task),
      convertedAllTasks,
      task?.liveDate as unknown as Date,
      [],
      true
    );
    return {
      ...task,
      status,
      unlockMessage: '',
    };
  }

  /** For tasks having deadline trigger, the start date will always be project's start date */

  if (task?.triggerDetails?.deadlineOrder === 'before') {
    let dueDate = moment(task?.liveDate)
      ?.clone()
      ?.subtract(task?.triggerDetails?.deadlineOffset, 'milliseconds')
      ?.utc()
      ?.startOf('day')
      ?.toISOString();
    let allTasks = phase?.map((p) => p?.tasks)?.flat();
    let convertedAllTasks = allTasks?.map((all) => convertTaskData(all));
    let { status, unlockMessage } = getTaskStatus(
      convertTaskData(task),
      convertedAllTasks,
      task?.liveDate as unknown as Date,
      [],
      true
    );
    return {
      ...task,
      ...(dueDate ? { dueDate } : {}),
      status,
      unlockMessage,
    };
  }

  if (task?.triggerDetails?.dependantOn === 'phase') {
    let dependentPhase = phase?.find(
      (p) => p?.phaseEid === task?.triggerDetails?.triggerItemId
    );
    // let isDependentItemVisible = phase?.some(
    //   (p) => task?.triggerDetails?.triggerItemId === p?.phaseEid
    // );
    if (dependentPhase) {
      // Dependent phase is found, due date will be based on the deadlineOffset
      let dueDate = moment(task?.triggerDetails?.startDate)
        ?.clone()
        ?.add(task?.triggerDetails?.deadlineOffset, 'milliseconds')
        ?.utc()
        ?.startOf('day')
        ?.toISOString();
      let defaultTask = defaultConvert(phase, task);
      return {
        ...defaultTask,
        dueDate,
      };
    } else {
      let defaultTask = defaultConvert(phase, task);
      return {
        ...defaultTask,
        dueDate: task?.liveDate,
      };
    }
  } else if (task?.triggerDetails?.dependantOn === 'task') {
    let dependentTask = phase
      ?.map((p) => p?.tasks)
      ?.flat()
      ?.find((t) => t?.eid === task?.triggerDetails?.triggerItemId);
    // let isDependentItemVisible = phase
    //   ?.map((p) => p?.tasks)
    //   ?.flat()
    //   ?.some((t) => task?.triggerDetails?.triggerItemId === t?.eid);
    if (dependentTask) {
      let dueDate = moment(task?.triggerDetails?.startDate)
        ?.clone()
        ?.add(task?.triggerDetails?.deadlineOffset, 'milliseconds')
        ?.utc()
        ?.startOf('day')
        ?.toISOString();
      let defaultTask = defaultConvert(phase, task);
      return {
        ...defaultTask,
        dueDate,
      };
    } else {
      let defaultTask = defaultConvert(phase, task);
      return {
        ...defaultTask,
        dueDate: task?.liveDate,
      };
    }
  } else if (task?.triggerDetails?.dependantOn === 'startDate') {
    if (task?.triggerDetails?.deadlineOrder === 'after') {
      let dueDate = moment(task?.triggerDetails?.startDate)
        ?.clone()
        ?.add(task?.triggerDetails?.deadlineOffset, 'milliseconds')
        ?.utc()
        ?.startOf('day')
        ?.toISOString();
      let allTasks = phase?.map((p) => p?.tasks)?.flat();
      let convertedAllTasks = allTasks?.map((all) => convertTaskData(all));
      let { status, unlockMessage } = getTaskStatus(
        convertTaskData(task),
        convertedAllTasks,
        task?.liveDate as unknown as Date,
        [],
        true
      );
      return {
        ...task,
        ...(dueDate ? { dueDate } : {}),
        status,
        unlockMessage,
      };
    }
  }

  return defaultConvert(phase, task);
};
