import React, { createContext, FC, useContext, useRef } from 'react';
import { useStore } from '../../../../../zustandStore';
import {
  createProjectDetailPhaseStore,
  ProjectDetailPhaseAction,
} from './project-detail-phase.store';

type ProjectDetailPhaseStore = ReturnType<typeof createProjectDetailPhaseStore>;

const ProjectDetailPhaseContext = createContext<ProjectDetailPhaseStore | null>(
  null
);

const ProjectDetailPhaseProvider: FC = ({ children }) => {
  const storeRef = useRef<ProjectDetailPhaseStore>();
  if (!storeRef.current) {
    storeRef.current = createProjectDetailPhaseStore();
  }
  return (
    <ProjectDetailPhaseContext.Provider value={storeRef.current}>
      {children}
    </ProjectDetailPhaseContext.Provider>
  );
};

function useProjectDetailPhaseContext<T>(
  selector: (state: ProjectDetailPhaseAction) => T
): T {
  const store = useContext(ProjectDetailPhaseContext);
  if (!store)
    throw new Error('Missing ProjectDetailPhaseContext.Provider in the tree');
  return useStore(store, selector);
}

export { ProjectDetailPhaseProvider, useProjectDetailPhaseContext };
