import React, { useCallback, useRef } from 'react';
import { UseConfirm, useConfirm } from '../../../../../../ui-components';
import DeleteBoard from './DeleteBoard';
import BeforeDeleteBoard from './BeforeDeleteBoard';
import { DAction, DeleteProps } from './delete-board.types';
import ConfirmDeleteBoard from './ConfirmDeleteBoard';
import ConfirmMoveBoard from './ConfirmMoveBoard';

interface IProps extends DeleteProps {}

type IDeleteBoard = (props: IProps) => void;

const useDeleteBoard = (): IDeleteBoard => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  const actionHandler = (action: DAction, props: DeleteProps) => {
    switch (action) {
      case 'back':
        return confirmRef.current?.update({
          content: (
            <BeforeDeleteBoard
              deleteProps={props}
              actionHandler={actionHandler}
            />
          ),
        });
      case 'delete-confirm':
        return confirmRef.current?.update({
          content: (
            <ConfirmDeleteBoard
              deleteProps={props}
              actionHandler={actionHandler}
            />
          ),
        });
      case 'move-confirm':
        return confirmRef.current?.update({
          content: (
            <ConfirmMoveBoard
              deleteProps={props}
              actionHandler={actionHandler}
            />
          ),
        });
      default:
        return;
    }
  };

  return useCallback((props) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    const Content = props.locations?.length ? BeforeDeleteBoard : DeleteBoard;

    confirmRef.current = confirm({
      content: <Content deleteProps={props} actionHandler={actionHandler} />,
      isCentered: true,
      size: '2xl',
      footer: null,
      contentProps: {
        paddingTop: '8px',
        paddingBottom: '24px',
        paddingInline: '8px',
        borderRadius: '12px',
      },
    });
  }, []);
};

export default useDeleteBoard;
