import React, { FC } from 'react';
import { SelectOption } from '../../../../../atoms';
import SectionDependDetails from '../sections/SectionDependDetails';
import SectionAction from '../sections/SectionAction';
import SectionDuration from '../sections/SectionDuration';
import AddSectionAssignee from '../sections/AddSectionAssignee';
import { Box, Flex } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

interface IProps {
  category: SelectOption;
  taskCount?: number;
}

const CategoryHeader: FC<IProps> = ({ category, taskCount }) => {
  const { t } = useTranslation(['launcher']);

  return (
    <Flex flexDir='column' gap='6px'>
      <Flex gap='6px'>
        <Flex flex={1} flexDir='column' overflow='hidden'>
          <Flex flex={1} fontSize='16px' fontWeight='600'>
            <Box as='span' isTruncated>
              {category?.label}
            </Box>
            &nbsp;-&nbsp;
            <Box
              as='span'
              fontWeight='400'
              color='#6F767E'
              textTransform='capitalize'
              whiteSpace='nowrap'
            >
              <Trans
                t={t}
                i18nKey='launcher:noOfTask'
                count={taskCount}
                values={{ count: taskCount }}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CategoryHeader;
