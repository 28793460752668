import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Trans, useTranslation } from 'react-i18next';
import { ActionButton, ActionMenu } from '../../../../../ui-components';

import ClipboardIcon from '../../../../../assets/images/dashboard/clipboard.svg';
import MultiSelectIcon from '../../../../../assets/images/multiselect.svg';
import { useLauncherConfigContext } from '../../common';

interface IProps {}

const BoardViewGroup: FC<IProps> = () => {
  const { t } = useTranslation('launcher');
  const { groupBy, onChange } = useLauncherConfigContext((state) => ({
    groupBy: state.groupBy,
    onChange: state.changeGroupBy,
  }));

  return (
    <ActionMenu
      menuData={[
        {
          name: t('phase'),
          value: 'phase',
          icon: ClipboardIcon,
        },
        {
          name: t('taskType'),
          value: 'category',
          icon: MultiSelectIcon,
        },
      ]}
      clickedItem={(_, action) => onChange(action as never)}
    >
      <div>
        <ActionButton
          size='lg'
          variant='outline'
          bg='#FFFFFF'
          color='#6F767E'
          maxWidth='300px'
          rightIcon={
            <FontAwesomeIcon fontSize={16} icon={faChevronDown as IconProp} />
          }
        >
          <Trans t={t} i18nKey='groupBy' context={groupBy} />
        </ActionButton>
      </div>
    </ActionMenu>
  );
};

export default BoardViewGroup;
