import React, { FC } from 'react';
import { Radio } from '@chakra-ui/react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TitleHeader from '../../../../../CardEditor/TitleHeader';
import { TriggerInput } from '../../trigger';
import { useLauncherAlert } from '../../useLauncherAlert';
import DeadlineTrigger from './DeadlineTrigger';
import TriggerContainer from './TriggerContainer';

interface IProps {
  type: 'phase' | 'task';
}

const DynamicDeadlineTrigger: FC<IProps> = ({ type }) => {
  const VALUE = 'deadline';
  const changeAlert = useLauncherAlert();
  const { t } = useTranslation('launcher');

  const { field, fieldState } = useController<
    TriggerInput,
    'trigger.triggerChoice'
  >({
    name: 'trigger.triggerChoice',
  });

  let isChecked = false;
  // eslint-disable-next-line eqeqeq
  if (field?.value != null) {
    isChecked = field.value === VALUE;
  }

  if (!field.value || field.value === 'default') {
    return (
      <TriggerContainer>
        <Radio
          size='lg'
          spacing={4}
          isChecked={false}
          onChange={() => field.onChange(VALUE)}
        >
          <TitleHeader
            title={t('trigger.setDynDeadTrigger')}
            desc={t('trigger.setDynDeadTriggerDesc')}
            titleStyle={{ fontSize: '13px' }}
            descStyle={{ color: '#999999' }}
          />
        </Radio>
      </TriggerContainer>
    );
  }

  return (
    <TriggerContainer>
      <Radio
        size='lg'
        isChecked={isChecked}
        spacing={4}
        onChange={() => {
          changeAlert({
            description: t('trigger.activationAlert'),
            buttonText: t('trigger.activationAlertBtn'),
            onConfirm: () => field.onChange(VALUE),
          });
        }}
      >
        <TitleHeader
          title={
            isChecked
              ? t('trigger.setDynDeadTrigger')
              : t('trigger.eraseActSetDeadTrigger')
          }
          desc={t('trigger.setDynDeadTriggerDesc')}
          titleStyle={{ fontSize: '13px' }}
          descStyle={{ color: '#999999' }}
        />
      </Radio>

      {isChecked ? <DeadlineTrigger type={type} /> : null}
    </TriggerContainer>
  );
};

DynamicDeadlineTrigger.displayName = 'sc/L/lc/c/ctf/tts/DynamicDeadlineTrigger';

export default React.memo(DynamicDeadlineTrigger);
