import React, { FC, useEffect } from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { cloneDeep } from '@apollo/client/utilities';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ActionButton } from 'ui-components';

import DashboardContainer from 'sub-components/DashboardContainer';
import {
  Address,
  BasicDetails,
  EntityDetails,
  LoginDetails,
  OtherDetails,
} from '../Components';
import AddLocationHeader from './AddLocationHeader';
import { IFormInput } from './add-location.types';
import {
  addLocationFormat,
  addPreLaunchLocationFormat,
} from './formatSubmitData';
import {
  ADD_LOCATION_QUERY,
  ADD_PRE_LAUNCH_LOCATION_QUERY,
  AddLocationVariable,
  AddLocationResponse,
} from './add-location.graphql';
import { getDefaultFormData } from './default-form-data';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import {
  getLoggedInUserDataHandler,
  getTimezones,
} from 'shared/graphql/SharedGraphql';
import { ADD_PRE_LAUNCH_LOCATION, LOCATIONS_PAGE } from 'appRoutes';
import {
  InviteResponse,
  InviteVariable,
  INVITE_USER_QUERY,
} from 'ui-components/InviteUserNew/invite.graphql';
import { toArray } from 'utils';
import { AuthRole } from 'authorization';

interface IProps {}

const AddLocation: FC<IProps> = () => {
  const [preLaunchLocationId, setPreLaunchLocationId] =
    React.useState<string>('');
  const [locationId, setLocationId] = React.useState<string>('');
  const { t } = useTranslation(['common', 'location']);
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });
  const history = useHistory();
  const location = useLocation();
  console.log('location : ', location);
  const isPreLaunch = location?.pathname === ADD_PRE_LAUNCH_LOCATION;

  const methods = useForm<IFormInput>({
    defaultValues: getDefaultFormData(),
  });

  const { execute: getTimezonesData } = getTimezones((timezone) => {
    methods?.setValue('timezonesData', timezone);
  });

  useEffect(() => {
    getTimezonesData();
  }, []);

  const onGetUserSuccess = () => {
    history.push(LOCATIONS_PAGE);
  };

  const onGetUserError = () => {
    history.push(LOCATIONS_PAGE);
  };

  const { execute, loading: gettingLoggedInUserDetails } =
    getLoggedInUserDataHandler(onGetUserSuccess, onGetUserError);

  const [addLocation, { loading }] = useMutation<
    AddLocationResponse,
    AddLocationVariable
  >(ADD_LOCATION_QUERY, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: t('common:success'),
        description: t('location:location_created'),
      });
      // execute?.();
    },
    onError: (error) => {
      if (error?.message?.includes('Username')) {
        methods.setError(
          'username',
          {
            type: 'custom',
            message: error.message,
          },
          {
            shouldFocus: true,
          }
        );
      } else {
        toast({
          status: 'error',
          title: t('common:error'),
          description: t('location:location_error'),
        });
      }
    },
  });

  const [addPreLaunchLocation, { loading: addingPreLaunchLocation }] =
    useMutation(ADD_PRE_LAUNCH_LOCATION_QUERY, {
      onCompleted: (response) => {
        toast({
          status: 'success',
          title: t('common:success'),
          description: t('location:location_created'),
        });
        // execute?.();
      },
      onError: (error) => {
        if (error?.message?.includes('Username')) {
          methods.setError(
            'username',
            {
              type: 'custom',
              message: error.message,
            },
            {
              shouldFocus: true,
            }
          );
        } else {
          toast({
            status: 'error',
            title: t('common:error'),
            description: t('location:location_error'),
          });
        }
      },
    });

  const [inviteUser, { loading: invitingLO }] = useMutation<
    InviteResponse,
    InviteVariable
  >(INVITE_USER_QUERY);

  const onSubmit = async (data: IFormInput) => {
    try {
      let mutationResponse: any;
      if (isPreLaunch) {
        const inputData = addPreLaunchLocationFormat(cloneDeep(data));
        mutationResponse = await addPreLaunchLocation({
          variables: {
            input: inputData,
          },
        });
      } else {
        const inputData = addLocationFormat(cloneDeep(data));
        mutationResponse = await addLocation({
          variables: {
            input: inputData,
          },
        });
      }
      const locationEid = isPreLaunch
        ? mutationResponse?.data?.AddPrelaunchLocation?.eid
        : mutationResponse?.data?.addLocation?.eid;

      if (!locationEid) {
        return;
      }
      const temporaryUsers = toArray(
        data.locationOwners?.filter((owner) =>
          owner?.owner?.value?.includes('create-temp-')
        )
      ).filter(Boolean);

      if (temporaryUsers.length > 0) {
        const invitePromises = temporaryUsers.map((user) =>
          inviteUser({
            variables: {
              input: {
                authRole: AuthRole.LOCATION_OWNER,
                branchIds: [locationEid],
                name: user?.owner?.label,
                role: user?.owner?.role,
              },
            },
          }).then(
            (response) => ({
              status: 'fulfilled' as const,
              value: response.data,
              user,
            }),
            (error) => ({
              status: 'rejected' as const,
              reason: error,
              user,
            })
          )
        );

        const results = await Promise.all(invitePromises);

        results.forEach((result) => {
          if (result.status === 'rejected') {
            toast({
              status: 'error',
              title: t('common:error'),
              description: `Failed to invite ${result?.user?.owner?.label}`,
            });
          }
        });

        const failedInvitations = results.filter(
          (result) => result.status === 'rejected'
        ).length;

        if (failedInvitations === 0) {
          toast({
            status: 'success',
            title: t('common:success'),
            description: 'Location owner(s) added successfully',
          });
        }
      }
      deployEvent(AmplitudeEventNames.LOCATION_ADD_BUTTON, {
        add_location_funnel_id: 5,
        location_id: locationEid,
        location_type: data?.locationType,
        location_name: data?.locationName || '',
        status: data?.locationStatus?.value,
        email_address: data?.locationEmail || '',
        owner: data?.locationOwners || [],
        location_user_name: data?.username || [],
        job_edit_funnel_id: 5,
        Address: data?.address || '',
        State: data?.state || '',
        City: data?.city || '',
        Zipcode: data?.zipCode || '',
        entity_name: data?.entityName || '',
        entity_type: data?.entityType || '',
        country_of_formation: data?.countryOfFormation || '',
        state_of_formation: data?.stateOfFormation || '',
        location_Type: data?.locationType || '',
        location_edit_funnel_id: 5,
        source: data?.locationStatus?.label || '',
      });
      execute();
    } catch (error) {
      toast({
        status: 'error',
        title: t('common:error'),
        description: 'Something went wrong',
      });
      return;
    }
  };

  return (
    <DashboardContainer>
      <FormProvider {...methods}>
        <Flex flexDir='column' gap={4} pb={5}>
          <AddLocationHeader
            title={
              isPreLaunch ? 'Add pre-launch location' : 'Add open location'
            }
          />

          <BasicDetails />

          <LoginDetails />

          <Address />

          <EntityDetails />

          <OtherDetails />

          <ActionButton
            size='lg'
            type='submit'
            colorScheme='blue'
            width='fit-content'
            minW='170px'
            alignSelf='flex-end'
            fontSize='15px'
            fontWeight='600'
            borderRadius='7px'
            isLoading={
              loading ||
              addingPreLaunchLocation ||
              gettingLoggedInUserDetails ||
              invitingLO
            }
            actionFn={methods.handleSubmit(onSubmit)}
          >
            {t('common:add')}
          </ActionButton>
        </Flex>
      </FormProvider>
    </DashboardContainer>
  );
};

AddLocation.displayName = 'pages/Locations/AddLocation';

export default AddLocation;
