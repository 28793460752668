import React, { FC, useEffect } from 'react';
import { Box, Flex, keyframes, Tooltip } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Control, useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Button } from '../../../../../atoms';
import { useSafeState } from '../../../../../hooks';
import { useMediaIsUploading } from '../useMediaIsUploading';
import { IFormInput } from '../../types';

import { ReactComponent as SyncIcon } from '../../../../../assets/images/sync-sharp.svg';

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

interface IProps {
  control: Control<IFormInput>;
  onBackClick?: () => void;
}

const RightSectionHeader: FC<IProps> = ({ control, onBackClick }) => {
  const { t } = useTranslation('audit');
  const isSubmitting = useFormState().isSubmitting;
  const lastUpdated = useWatch<IFormInput, 'lastUpdated'>({
    control,
    name: 'lastUpdated',
  });

  const [agoString, update] = useSafeState<string>();

  useEffect(() => {
    if (!lastUpdated) {
      return;
    }
    const x = setInterval(() => {
      update(moment(lastUpdated).fromNow());
    }, 60000);

    return () => clearInterval(x);
  }, [lastUpdated]);

  const mediaUploading = useMediaIsUploading();

  return (
    <Flex flexDir='column' gap={4}>
      <Tooltip
        placement='end'
        hasArrow
        label={t('uploadInProgressWarning')}
        offset={[0, 16]}
        isDisabled={!mediaUploading}
      >
        <Box alignSelf='start'>
          <Button
            fontSize='14px'
            fontWeight={500}
            width='max-content'
            variant='link'
            colorScheme='blue'
            leftIcon={<ArrowBackIcon width='16px' height='16px' />}
            _hover={{
              textDecoration: 'none',
            }}
            onClick={onBackClick}
            isDisabled={mediaUploading}
          >
            Go back to Overview
          </Button>
        </Box>
      </Tooltip>

      <Flex fontWeight='600' align='center' justify='space-between' gap={4}>
        <Box color='#6F767E'>Sections</Box>

        <Flex
          hidden={!isSubmitting}
          align='center'
          gap='6px'
          fontSize='12px'
          color='#6F767E'
        >
          <Box animation={`${spin} 1s linear infinite`} boxSize={4}>
            <SyncIcon />
          </Box>
          Syncing Changes
        </Flex>

        <Flex
          hidden={isSubmitting}
          fontSize='12px'
          color='#33383F'
          bg='#EFEFEF'
          borderRadius='4px'
          padding='2px 8px'
          align='center'
          gap='6px'
        >
          <SyncIcon />
          <span>Updated {agoString}</span>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RightSectionHeader;
