import moment from 'moment';
import { UserData } from 'sop-commons/src/client';
import { ILocationLaunchTask } from 'sub-components/Launcher/location-owner/types';
import { groupTasksByPhase } from '..';
import { GroupedLocation, GroupedPhase } from '../../types';
import { activationTriggerProcessing } from './activationTriggerProcessing';
import { deadlineTriggerProcessing } from './deadlineTriggerProcessing';
import { defaultNoTrigger } from './defaultNoTrigger';

export function processLocationTasks(
  tasks: GroupedLocation['data'][number][],
  loggedInUser?: UserData,
  currentDate?: moment.Moment
): GroupedPhase[] {
  const groupedPhases = groupTasksByPhase(tasks);
  return groupedPhases?.map((phase) => {
    let _tasks: ILocationLaunchTask[] = [];
    phase?.tasks?.map((task) => {
      switch (task?.triggerDetails?.triggerChoice) {
        case 'default':
          _tasks?.push(defaultNoTrigger(task, phase?.tasks));
          break;
        case 'activation':
          _tasks?.push(activationTriggerProcessing(task, groupedPhases));
          break;
        case 'deadline':
          _tasks?.push(deadlineTriggerProcessing(task, groupedPhases));
          break;
        default:
          _tasks?.push(defaultNoTrigger(task, phase?.tasks));
      }
    });
    return {
      ...phase,
      tasks: _tasks,
    };
  });
}
