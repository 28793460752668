import moment from 'moment';
import { getTaskStatus } from 'sub-components/Launcher/dashboard/Location/helper';
import { ILocationLaunchTask } from 'sub-components/Launcher/location-owner/types';
import { convertTaskData } from '../convertTaskData';

export const defaultNoTrigger = (
  task: ILocationLaunchTask,
  tasks: ILocationLaunchTask[]
): ILocationLaunchTask => {
  const { unlockMessage, status } = getTaskStatus(
    convertTaskData(task),
    tasks?.map((task) => convertTaskData(task)),
    task.liveDate as unknown as Date,
    [],
    true
  );
  let dueDate: string = '';
  if (task?.triggerDetails?.dependantOn === 'startDate') {
    if (task?.triggerDetails?.deadlineOrder === 'after') {
      dueDate = moment(task?.triggerDetails?.startDate)
        ?.clone()
        ?.add(task?.triggerDetails?.deadlineOffset, 'milliseconds')
        ?.utc()
        ?.startOf('day')
        ?.toISOString();
    }
  }
  return {
    ...task,
    ...(dueDate ? { dueDate } : {}),
    status,
    unlockMessage,
  };
};
