import React, { FC } from 'react';
import { IconButton } from '@chakra-ui/react';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';
import { ActionMenu } from '../../../../../ui-components';

interface IProps {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  iconSize?: 'lg' | 'xl';
  iconColor?: string;
  onActionClick?: (action: string) => void;
}

const BoardActionButton: FC<IProps> = ({
  size = 'xs',
  iconSize = 'lg',
  iconColor,
  onActionClick,
}) => {
  const { t } = useTranslation('launcher');

  return (
    <ActionMenu
      minWidth='198px'
      menuData={[
        {
          name: t('renameBoard'),
          value: 'rename',
        },
        {
          name: t('duplicateBoard'),
          value: 'duplicate',
        },
        {
          name: t('deleteBoard'),
          value: 'delete',
          textColor: '#FF6A55',
        },
      ]}
      clickedItem={(_, action) => onActionClick?.(action)}
    >
      <IconButton
        aria-label='action'
        size={size}
        variant='ghost'
        onClick={(e) => e.stopPropagation()}
      >
        <FontAwesomeIcon
          icon={faEllipsisVertical as IconProp}
          // @ts-ignore
          size={iconSize}
          color={iconColor}
        />
      </IconButton>
    </ActionMenu>
  );
};

export default BoardActionButton;
