import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { FilterItem, SummaryData } from '../types';
import SummaryTabs from './SummaryTabs';
import FilterByModule from '../../Common/FilterByModule';
import {
  IFilters,
  INotificationItem,
  INotificationResponse,
} from 'sub-components/NotificationCenter/types/service-types';
import { NotificationFilters } from 'sub-components/NotificationCenter/types/types';

interface IProps {
  isCompactView?: boolean;
  customStyles?: any;
  resetHandler: () => void;
  checkedModules?: any;
  handleRemoveFilter?: any;
  handleModuleSelect?: any;
  setSelectedModules: any;
  selectedModules: any;
  notifications?: INotificationItem[];
  notificationFilters: NotificationFilters;
  notificationHistoryData?: any;
  notificationData: INotificationItem[];
  notificationLoading?: any;
  handleUpdateNotificationReadStatus: (eid: string, status: string) => void;
  setTabName?: any;
  markAllNotificationRead?: () => void;
  searchFieldText?: string;
  notificationCount: number;
  decideFilterAndRefetch: (pageIndex?: number) => void;
  tabName: string;
  markGroupedNotificationsRead?: () => any;
}

const SummaryFilterContainer: FC<IProps> = ({
  isCompactView,
  customStyles,
  resetHandler,
  checkedModules,
  handleRemoveFilter,
  handleModuleSelect,
  selectedModules,
  setSelectedModules,
  notifications,
  notificationFilters,
  notificationData,
  notificationHistoryData,
  notificationLoading,
  handleUpdateNotificationReadStatus,
  setTabName,
  markAllNotificationRead,
  searchFieldText,
  notificationCount,
  decideFilterAndRefetch,
  tabName,
  markGroupedNotificationsRead,
}) => {
  return (
    <Box>
      <SummaryTabs
        selectedModules={selectedModules}
        setSelectedModules={setSelectedModules}
        customStyles={customStyles}
        isCompactView={isCompactView}
        resetHandler={resetHandler}
        checkedModules={checkedModules}
        handleRemoveFilter={handleRemoveFilter}
        handleModuleSelect={handleModuleSelect}
        notifications={notifications}
        notificationFilters={notificationFilters}
        notificationHistoryData={notificationHistoryData}
        notificationData={notificationData}
        notificationLoading={notificationLoading}
        handleUpdateNotificationReadStatus={handleUpdateNotificationReadStatus}
        setTabName={setTabName}
        markAllNotificationRead={markAllNotificationRead}
        searchFieldText={searchFieldText}
        notificationCount={notificationCount}
        decideFilterAndRefetch={decideFilterAndRefetch}
        tabName={tabName}
        markGroupedNotificationsRead={markGroupedNotificationsRead}
      />
      <Box
        position={isCompactView ? 'absolute' : 'relative'}
        right={5}
        zIndex={15}
        top={'4.4rem'}
      >
        <FilterByModule
          isCompactView={isCompactView}
          setSelectedModules={setSelectedModules}
          resetHandler={resetHandler}
          checkedModules={checkedModules}
          handleRemoveFilter={handleRemoveFilter}
          handleModuleSelect={handleModuleSelect}
          selectedModules={selectedModules}
        />
      </Box>
    </Box>
  );
};
SummaryFilterContainer.displayName = 'sc/NC/S/c/SummaryFilterContainer.tsx';
export default React.memo(SummaryFilterContainer);
