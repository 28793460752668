import React, { FC } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import TaskDetails from './TaskDetails';
import StepAssigneeDetails from './StepAssigneeDetails';
import { ActivationDetails } from './activation-details';
import TaskFormContainer from './TaskFormContainer';
import TaskFormItem from './TaskFormItem';
import { ITaskInput } from './task-form.types';
import TaskSaveButton from './TaskSaveButton';

interface IProps {
  bg?: FlexProps['bg'];
  sectionIndex: number;
  taskIndex: number;
  defaultValues?: ITaskInput;
  beforeSave?: (task: ITaskInput) => Promise<void>;
  onSave?: (task: ITaskInput) => void | Promise<void>;
  phaseSelectDisabled?: boolean;
}

const ConfigTaskForm: FC<IProps> = ({
  bg = '#F5F5F5',
  sectionIndex,
  taskIndex,
  defaultValues,
  beforeSave,
  onSave,
  phaseSelectDisabled,
}) => {
  const methods = useForm<ITaskInput>({
    defaultValues: defaultValues,
  });

  const onSubmit = async (values: ITaskInput) => {
    await onSave?.(values);
  };

  return (
    <Flex flexDir='column' gap={5} paddingBottom='48px'>
      <FormProvider {...methods}>
        <TaskFormContainer>
          <TaskFormItem
            bg={bg}
            title='Task details'
            subTitle='Task name, description, Mandatory toggle'
            fieldKey={['title', 'phase', 'category']}
          >
            <TaskDetails phaseSelectDisabled={phaseSelectDisabled} />
          </TaskFormItem>

          <TaskFormItem
            bg={bg}
            title='Steps and Assignee details'
            subTitle='Assignee name, steps included in task'
          >
            <StepAssigneeDetails />
          </TaskFormItem>

          <TaskFormItem
            bg={bg}
            title='Activation details'
            subTitle='Specify when the assignee can access this task and its completion deadline'
          >
            <ActivationDetails
              taskIndex={taskIndex}
              sectionIndex={sectionIndex}
            />
          </TaskFormItem>
        </TaskFormContainer>

        <TaskSaveButton
          sectionIndex={sectionIndex}
          taskIndex={taskIndex}
          defaultEid={defaultValues?.eid}
          beforeSave={beforeSave}
          onSubmit={onSubmit}
          onDiscardClick={() => methods.reset()}
        />
      </FormProvider>
    </Flex>
  );
};

ConfigTaskForm.displayName =
  'sub-components/Launcher/launcher-config/common/config-task-form/ConfigTaskForm';

export default ConfigTaskForm;
