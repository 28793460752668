import React, { FC, useState } from 'react';
import {
  Box,
  Flex,
  PopoverTrigger,
  PopoverBody,
  PopoverContent,
  Popover,
  Button,
  Input,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { GoPencil } from 'react-icons/go';
import { LuDownload } from 'react-icons/lu';

import { Authorize, AuthRole } from 'authorization';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';

import DashboardContainer from '../../../sub-components/DashboardContainer';
import TrainingContainer from './TrainingContainer';
import { useUploadImage, useUserDataSelector } from 'hooks';
import { ConfirmationModal, ProgressModal } from './Component/UploadModal';
import { gql, useMutation } from '@apollo/client';
import { getAssetTypeFromMimeType } from './helper';

export const CREATE_ENTITY_ASSET = gql`
  mutation CreateEntityAsset($input: EntityAssetAddInputInput) {
    CreateEntityAsset(input: $input) {
      assetType
      createdAt
      createdBy
      eid
      entityId
      fileSize
      mimetype
      resource
      title
      updatedAt
      url
    }
  }
`;

const TrainingListHeader = () => {
  const { t } = useTranslation(['common', 'training']);
  const history = useHistory();
  const uploadImage = useUploadImage();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure();

  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState('');
  const [createEntityAsset] = useMutation(CREATE_ENTITY_ASSET);

  const scormToggle = useUserDataSelector(
    (state) => state?.entity?.features?.scorm
  );

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      try {
        onOpen();
        const res = await uploadImage(
          selectedFile,
          selectedFile.name,
          (uploadedProgress) => setProgress(uploadedProgress)
        );
        await createEntityAsset({
          variables: {
            input: {
              resource: 'scorm',
              title: selectedFile.name,
              mimetype: selectedFile.type,
              fileSize: selectedFile.size,
              url: res,
              assetType: getAssetTypeFromMimeType(selectedFile.type),
            },
          },
        });
        onConfirmationOpen();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('File upload failed:', error);
      }
      onClose();
    }
  };

  return (
    <Flex align='center' mb='20px'>
      <Box fontSize='28px' fontWeight='700' flex='1'>
        {t('common:training')}
      </Box>
      <Flex>
        <Authorize
          permittedRoles={[
            AuthRole.SUPER_ADMIN,
            AuthRole.ADMIN,
            AuthRole.LOCATION_OWNER,
          ]}
          permittedFor={'user'}
        >
          {/* <PrimaryButton
            leftIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
            type='button'
            size='md'
            title={t('training:add_new_path')!}
            colorScheme='blue'
            variant='outline'
            onClick={() => {
              deployEvent(
                AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_CLICK
              );

              deployEvent(AmplitudeEventNames.ADD_NEW_PATH_CLICK, {
                add_new_path_funnel_id: 4,
              });

              history.push('/create-path');
            }}
          /> */}

          {scormToggle ? (
            <Popover placement='bottom-end' isLazy>
              <PopoverTrigger>
                <Button
                  leftIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
                  type='button'
                  size='md'
                  colorScheme='blue'
                  variant='outline'
                  backgroundColor={'white'}
                >
                  {t('training:add_new_path')!}
                </Button>
              </PopoverTrigger>
              <PopoverContent borderRadius='16px' zIndex={401}>
                <PopoverBody p='12px'>
                  <Flex
                    direction='column'
                    gap='12px'
                    alignItems='flex-start'
                    fontSize='15px'
                    fontWeight={600}
                    color='#6F767E'
                  >
                    <Button
                      variant='default'
                      gap={4}
                      onClick={() => {
                        deployEvent(
                          AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_CLICK
                        );
                        deployEvent(AmplitudeEventNames.ADD_NEW_PATH_CLICK, {
                          add_new_path_funnel_id: 4,
                        });
                        history.push('/create-path');
                      }}
                    >
                      <GoPencil height='24px' width='24px' strokeWidth='1' />
                      Create from scratch
                    </Button>
                    <Button
                      variant='default'
                      gap={4}
                      onClick={() =>
                        document.getElementById('fileInput')?.click()
                      }
                    >
                      <LuDownload height='24px' width='24px' strokeWidth='3' />
                      Import XAPI File
                    </Button>
                    <Input
                      type='file'
                      id='fileInput'
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ) : (
            <Button
              leftIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
              backgroundColor={'white'}
              type='button'
              size='md'
              colorScheme='blue'
              variant='outline'
              onClick={() => {
                deployEvent(
                  AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_CLICK
                );
                deployEvent(AmplitudeEventNames.ADD_NEW_PATH_CLICK, {
                  add_new_path_funnel_id: 4,
                });
                history.push('/create-path');
              }}
            >
              Create new path
            </Button>
          )}
        </Authorize>
      </Flex>

      {/* Modal for Progress Bar */}
      <ProgressModal
        isOpen={isOpen}
        onClose={onClose}
        progress={progress}
        fileName={fileName}
      />

      {/* Confirmation Modal */}
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={onConfirmationClose}
      />
    </Flex>
  );
};

interface IProps {}

const TrainingList: FC<IProps> = () => {
  return (
    <DashboardContainer>
      <TrainingListHeader />
      <TrainingContainer />
    </DashboardContainer>
  );
};

export default TrainingList;
