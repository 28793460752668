import { Menu, MenuButton, Button } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FC } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type AnswerType = {
  title: string | undefined;
};

interface IProps {
  answer?: AnswerType[];
}

const DropdownQuestions: FC<IProps> = ({ answer }) => {
  return (
    <Menu>
      {answer?.[0]?.title && (
        <MenuButton
          //@ts-ignore
          _hover={'none'}
          //@ts-ignore
          _active={'none'}
          cursor={'default'}
          alignSelf={'flex-end'}
          as={Button}
          backgroundColor={'#DCF5FF'}
        >
          {answer?.[0]?.title}
        </MenuButton>
      )}
    </Menu>
  );
};

export default DropdownQuestions;
