import { AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useRouteState } from '../../../../../routes';
import { useAuditTabContext } from '../../store/context';
import { AuditSessionPaginateVariables } from '../types';
import { processData } from '../utils';
import { useServiceLayer } from './useServiceLayer';

export const useControlLayer = () => {
  const {
    auditsSessionList: {
      getAuditSessionList,
      getAuditSessionListResult: { data, loading },
    },
  } = useServiceLayer();
  const loggedInUserAuthRole = useUserDataSelector((state) => state.authRole);
  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>(
    undefined
  );

  const updateSupervisedByMeData = useAuditTabContext(
    (state) => state.updateSupervisedByMeData
  );
  const { state } = useRouteState('audit', {
    selectedTab: 'supervisedByMe',
  });

  const supervisedSearchQuery = useAuditTabContext(
    (state) => state.supervisedSearchQuery
  );

  useEffect(() => {
    if ((data?.AuditSessionPagination?.items || [])?.length > 0) {
      let processedData = processData(
        data?.AuditSessionPagination?.items || []
      );
      updateSupervisedByMeData(processedData);
    } else {
      updateSupervisedByMeData([]);
    }
  }, [data]);

  const filterVariables = useMemo(() => {
    const variables: AuditSessionPaginateVariables = {
      page: 1,
      perPage: 1000,
      sort: '_ID_DESC',
    };

    const filter: AuditSessionPaginateVariables['filter'] = {};

    if (supervisedSearchQuery) {
      filter.query = supervisedSearchQuery;
    }

    if (loggedInUserAuthRole === AuthRole.ADMIN) {
      filter.isAssigned = true;
    }

    if (selectedRange?.from && selectedRange?.to) {
      filter.startDate = moment(selectedRange.from)
        .utcOffset(0, true)
        .startOf('day')
        .toISOString();
      filter.endDate = moment(selectedRange.to)
        .utcOffset(0, true)
        .endOf('day')
        .toISOString();
    }

    if (Object.keys(filter).length > 0) {
      variables.filter = filter;
    }

    return variables;
  }, [supervisedSearchQuery, selectedRange, loggedInUserAuthRole]);

  const getAuditsListFn = useCallback(() => {
    if (!getAuditSessionList) {
      console.warn('Audit service is not available');
      return;
    }

    getAuditSessionList({
      variables: filterVariables,
    });
  }, [filterVariables]);

  useEffect(() => {
    if (state?.selectedTab === 'supervisedByMe') {
      getAuditsListFn();
    }
  }, [
    supervisedSearchQuery,
    selectedRange,
    state?.selectedTab,
    getAuditsListFn,
  ]);

  const refetchHandler = () => {
    getAuditsListFn();
  };

  return {
    data: data?.AuditSessionPagination?.items || [],
    loading: loading,
    supervisedSearchQuery,
    selectedRange,
    refetchHandler,
    setSelectedRange,
  };
};
