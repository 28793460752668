import { gql } from '@apollo/client';
import { InviteDetails, UserStatus, UserType } from '../../../types';
import { AuthRole } from '../../../authorization';
import { LocationsEntity } from '../members/team.types';
import { InviteTrainingItem } from '../../../ui-components/InviteUserNew/TrainingPaths';
import { InvitedChatGroup } from '../../../ui-components/InviteUserNew/invite.types';

export interface InvitationMeta {
  chatGroups: InvitedChatGroup[];
  trainings: InviteTrainingItem[];
}

export interface InvitedUser {
  eid: string;
  name: string;
  status: UserStatus;
  type: UserType;
  role: string;
  authRole: AuthRole;
  email: string;
  phone: string;
  locations?: LocationsEntity[];
  inviteDetails: InviteDetails;
  userInviteMeta: InvitationMeta;
}

export interface InvitedUserResponse {
  FetchInviteDetails: {
    user: InvitedUser;
  };
}

export const INVITED_USER_DETAILS = gql`
  query InvitedUserDetails($eid: String!) {
    FetchInviteDetails(eid: $eid) {
      user {
        eid
        name
        status
        type
        role
        authRole
        email
        phone
        locations {
          eid
          name
        }
        inviteDetails {
          authCode
          invitedBy
        }
        userInviteMeta {
          chatGroups
          trainings {
            eid
            title
            thumbnail
            trainingContentTiny
          }
        }
      }
    }
  }
`;

export const UPDATE_INVITED_USER = gql`
  mutation UpdateInvitedUser($input: UserUpdateIdInput) {
    updateUserById(input: $input) {
      eid
      name
      status
      type
      role
      authRole
      email
      phone
      locations {
        eid
        name
      }
      inviteDetails {
        authCode
        invitedBy
      }
      userInviteMeta {
        chatGroups
        trainings {
          eid
          title
          thumbnail
          trainingContentTiny
        }
      }
    }
  }
`;

export const DELETE_MEMBER = gql`
  mutation DeleteUserPermanently($eid: String!) {
    deleteUserPermanently(eid: $eid) {
      succeed
    }
  }
`;

export const SOFT_DELETE_MEMBER = gql`
  mutation SoftDeleteUser($eid: String!) {
    SoftDeleteUser(eid: $eid) {
      succeed
    }
  }
`;

export const TRANSFER_AND_DELETE_MEMBER = gql`
  mutation DeleteUserPermanently($eid: String!, $requestedBy: String) {
    deleteUserPermanently(eid: $eid, requestedBy: $requestedBy) {
      succeed
    }
  }
`;
