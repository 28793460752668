import { Box } from '@chakra-ui/react';
import {
  ExtendedLauncherContent,
  PhaseData,
} from 'pages/LocationsNew/Components/ProjectDetailPhase/store/project-detail-phase.types';
import React, { FC } from 'react';

interface IProps {
  data: ExtendedLauncherContent;
}

const SelectionChip: FC<IProps> = ({ data }) => {
  switch (data?.selectedChoice) {
    case 'markComplete':
      return (
        <Box
          bg='#FFC4C4'
          borderRadius='8px'
          p='4px 10px'
          fontSize='12px'
          minW='fit-content'
        >
          Marked as Completed
        </Box>
      );
    case 'phaseNotRequired':
      return (
        <Box
          bg='#FFC4C4'
          borderRadius='8px'
          p='4px 10px'
          fontSize='12px'
          minW='fit-content'
        >
          Marked as Not Required
        </Box>
      );
    default:
      return null;
  }
};

SelectionChip.displayName =
  'displayName:pages/LocationsNew/Components/ProjectDetailPhase/components/PhaseAccordionButton/phase-button-upper/components/SelectionChip';

export default SelectionChip;
