import React, { FC, useEffect, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { gql, useLazyQuery } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';

import { Authorize, AuthRole } from '../../../authorization';

import { AuditDetailProvider } from './store/context';
import DashboardContainer from '../../../sub-components/DashboardContainer';
import HeadingSection from './components/HeadingSection';
import Container from './components/Container';
import { SessionDetailVariables } from '../../../sub-components/audits/take-audit/types';
import LocationOwnerAuditDetail from './components/LocationOwnerAuditDetail';
import AuditDetailsActions from './AuditDetailsActions';
import {
  Any,
  AuditQuestionEntity,
  AuditRepeatCycle,
  AuditRepeatEntity,
  AuditStatus,
} from '../../../types';

interface AuditDetailResponse {
  AuditSessionById: {
    title: string;
    repeatCycle?: AuditRepeatCycle;
    repeatDetails: AuditRepeatEntity<string>;
    isCompleted: boolean;
    questions: AuditQuestionEntity[];
    audit: {
      status: AuditStatus;
    };
  };
}

export const AUDIT_SESSION_DETAILS = gql`
  query AuditBasicDetails($eid: String!) {
    AuditSessionById(eid: $eid) {
      title
      repeatCycle
      repeatDetails
      questions
      isCompleted
      audit {
        status
      }
    }
  }
`;

const AuditDetail: FC = () => {
  const { auditId, sessionId } = useParams<{
    auditId: string;
    sessionId: string;
  }>();

  const locationState = useLocation().state as Any;

  const [getSessionDetails, { data, loading }] = useLazyQuery<
    AuditDetailResponse,
    SessionDetailVariables
  >(AUDIT_SESSION_DETAILS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getSessionDetails({
      variables: {
        eid: sessionId,
      },
    });
  }, []);

  const auditData = useMemo(() => {
    return data?.AuditSessionById;
  }, [data]);

  const isAuditScored = useMemo(() => {
    return !!data?.AuditSessionById?.questions?.some((que) => que?.hasScoring);
  }, [data]);

  return (
    <DashboardContainer>
      <Authorize
        permittedFor='user'
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
        renderNoAccess={() => (
          <LocationOwnerAuditDetail
            auditTitle={auditData?.title || ''}
            isAuditScored={isAuditScored}
            loading={loading}
          />
        )}
      >
        <AuditDetailProvider>
          <Flex w='full' flexDir='column' gap={4}>
            <HeadingSection
              auditTitle={auditData?.title || ''}
              loading={loading}
              customNavigateText={locationState?.backToTitle}
            >
              <AuditDetailsActions
                loading={loading}
                auditId={auditId}
                status={auditData?.audit?.status}
                isCompleted={auditData?.isCompleted}
              />
            </HeadingSection>
            <Container
              isAuditRepeating={
                auditData?.repeatDetails?.auditFrequency !== 'oneTime'
              }
            />
          </Flex>
        </AuditDetailProvider>
      </Authorize>
    </DashboardContainer>
  );
};

export default AuditDetail;
