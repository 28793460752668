import { useEffect } from 'react';
import { toArray } from '../../../../../../utils';
import { SelectOption } from '../../../../../../atoms';
import { eventBus } from '../../../../../../shared/eventEmit';
import { useSafeState } from '../../../../../../hooks';

export const useConfigPhaseOptions = (): SelectOption[] => {
  const [phases, updateState] = useSafeState<SelectOption[]>([]);

  useEffect(() => {
    eventBus.emit('GET_CONFIG', (state: any) => {
      updateState(
        toArray(state?.config?.contents).map((sec) => ({
          label: sec?.category,
          value: sec?.eid,
        }))
      );
    });
  }, []);

  return phases;
};
