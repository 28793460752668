import { gql } from '@apollo/client';

export const SINGLE_TASK_DETAILS = gql`
  query SINGLE_TASK_DETAILS($eid: String!) {
    LocationLaunchById(eid: $eid) {
      contents {
        tasks
      }
    }
  }
`;

export const LOCATION_LAUNCH_TASK_BY_ID = gql`
  query LocationLaunchTaskById($launchId: String!, $taskId: String!) {
    LocationLaunchTaskById(launchId: $launchId, taskId: $taskId) {
      forms {
        eid
        title
      }
      sops {
        eid
        title
        thumbnail
      }
      task {
        eid
        completedAt
        completedBy
        isCompleted
        steps {
          stepId
          isCompleted
          completedAt
          completedBy
        }
      }
      complianceFiles {
        entityId
        createdBy
        categoryId
        locationId
        files {
          name
          url
          type
          mimetype
          fileSize
          createdBy {
            eid
            name
          }
          createdAt
          updatedAt
        }
        title
        status
        expiryDate
        metadata {
          launchId
          phaseId
          taskId
          launcherName
          phase
          task
        }
        lastUpdateBy
        createdAt
        updatedAt
        eid
      }
    }
  }
`;
