import {
  AccordionPanel,
  Box,
  Flex,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import { LauncherTask } from 'sub-components/Launcher/launcher-config/common/launcher-config.fragment';
import { AssignmentType } from '..';
import { useProjectDetailPhaseContext } from '../../store/context';
import OperationBtn from './OperationBtn';

interface IProps {
  eid: string;
  index: number;
}

const PhaseAccordionPanel: FC<IProps> = ({ eid, index }) => {
  const { mode, phaseData } = useProjectDetailPhaseContext((state) => ({
    mode: state?.view,
    phaseData: state?.launcherData,
    updatePhaseData: state?.updateLauncherData,
  }));

  const data = useMemo(() => {
    return phaseData?.contents?.filter((content) => content?.eid === eid)?.[0]!;
  }, [eid, phaseData]);

  const render = (task: LauncherTask) => {
    if (mode === 'view') {
      return (
        <Flex minW='fit-content'>
          <AssignmentType
            assignedIds={task?.assignedUser}
            assignedType={task?.assignedType}
          />
        </Flex>
      );
    } else {
      return (
        <Flex align='center' gap='5px' minW='fit-content'>
          <AssignmentType
            assignedIds={task?.assignedUser}
            assignedType={task?.assignedType}
          />
          <OperationBtn data={data} eid={eid} task={task} />
        </Flex>
      );
    }
  };

  return (
    <AccordionPanel pb={4}>
      <UnorderedList spacing={6}>
        {data?.tasks?.map((task) => (
          <ListItem key={task?.eid}>
            <Flex align='center' justify='space-between' gap='10px'>
              <Box as='span'>{task?.title}</Box>
              {render(task)}
            </Flex>
          </ListItem>
        ))}
      </UnorderedList>
    </AccordionPanel>
  );
};

PhaseAccordionPanel.displayName =
  'displayName:pages/LocationsNew/Components/ProjectDetailPhase/components/PhaseAccordionPanel/PhaseAccordionPanel';

export default PhaseAccordionPanel;
