import React, { FC, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

import { FlexCard } from '../../../atoms';
import { IconImage } from '../../../ui-components';
import { usersEntityObj } from '../../../sub-components/Header';
import {
  BoardActionButton,
  BoardItemEntity,
} from '../../../sub-components/Launcher/launcher-config';

import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar.svg';
import { ReactComponent as LocationIcon } from '../../../assets/images/location.svg';
import { toArray } from '../../../utils';

interface IProps {
  data: BoardItemEntity;
  onClick?: (data: BoardItemEntity) => void;
  onActionClick?: (action: string, data: BoardItemEntity) => void;
}

const BoardCard: FC<IProps> = ({ data, onClick, onActionClick }) => {
  const { t } = useTranslation('launcher');
  const userList = useReactiveVar(usersEntityObj);

  const user = userList?.find(
    (u) => u.eid === data.updatedBy || data.createdBy
  );

  const locLength = useMemo(() => {
    return toArray(data.locationLaunches).filter((l) => !l.isLive).length;
  }, data.locationLaunches);

  return (
    <FlexCard
      minWidth={0}
      flexDir='column'
      bg='#EFEBFF99'
      border='1px solid #EFEFEF'
      p={4}
      gap={6}
      cursor='pointer'
      _hover={{ boxShadow: 'sm' }}
      onClick={() => onClick?.(data)}
    >
      <Flex>
        <Flex flex={1} flexDir='column' overflow='hidden'>
          <Box fontSize='15px' fontWeight={600} color='#33383F' isTruncated>
            {data.title}
          </Box>
          <Box fontSize='12px' fontWeight={600} color='#6F767E'>
            <Trans
              t={t}
              i18nKey='noOfPhase'
              count={data.contents?.length || 0}
              values={{
                count: data.contents?.length || 0,
              }}
            />
          </Box>
        </Flex>

        <BoardActionButton
          onActionClick={(action) => onActionClick?.(action, data)}
        />
      </Flex>

      <Flex align='center' gap={2}>
        <Flex gap={1} align='center' overflow='hidden'>
          <IconImage
            boxSize={16}
            borderRadius='16px'
            name={user?.name!}
            thumbnail={user?.profilePic}
          />
          <Box fontSize='12px' fontWeight={600} color='#6F767E' isTruncated>
            {user?.name}
          </Box>
        </Flex>

        <Box boxSize={1} minW={1} bg='#D9D9D9' borderRadius='full' />

        <Flex gap={1} align='center' whiteSpace='nowrap'>
          <CalendarIcon width='16' height='16' />
          <Box fontSize='12px' fontWeight={600} color='#6F767E'>
            {moment(data.updatedAt).fromNow()}
          </Box>
        </Flex>

        {locLength ? (
          <>
            <Box boxSize={1} minW={1} bg='#D9D9D9' borderRadius='full' />

            <Flex gap={1} align='center' whiteSpace='nowrap'>
              <LocationIcon width='16' height='16' />
              <Box fontSize='12px' fontWeight={600} color='#6F767E'>
                {locLength}
              </Box>
            </Flex>
          </>
        ) : null}
      </Flex>
    </FlexCard>
  );
};

export default BoardCard;
