import { useReactiveVar } from '@apollo/client';
import { Box, Center, Flex, Image, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HeaderColors } from 'configs';
import { LauncherAssignee } from 'pages/launcher/config-editor/common';
import React, { FC } from 'react';
import { UserEntityData } from 'shared/graphql/shared-types';
import { usersEntityObj } from 'sub-components/Header';
import { getImage } from 'utils';

interface IProps {
  assignedType: LauncherAssignee['assignedType'];
  assignedIds: string[];
}

const AssignmentType: FC<IProps> = ({ assignedIds, assignedType }) => {
  const businessUsers = useReactiveVar(usersEntityObj);

  if (assignedType === 'location') {
    return (
      <Center
        bg={HeaderColors.Yellow}
        boxSize='30px'
        minW='30px'
        minH='30px'
        borderRadius='50%'
        p='4px'
      >
        <FontAwesomeIcon icon={faLocationDot as IconProp} color='white' />
      </Center>
    );
  }

  const assignees = assignedIds
    ?.map((assignee) => businessUsers?.find((bus) => bus?.eid === assignee))
    ?.filter((member): member is UserEntityData => member !== undefined);

  const displayedAssignees = assignees?.slice(0, 3);
  const remainingAssignees = assignees?.slice(3);

  return (
    <Flex align='center' gap='4px'>
      {displayedAssignees.map((foundMember, index) => (
        <Flex
          key={index}
          bg={HeaderColors.Blue}
          borderRadius='5px'
          w='fit-content'
          p='4px'
          align='center'
          gap='4px'
        >
          <Image
            src={getImage(foundMember?.profilePic, foundMember?.name)}
            minW='18px'
            minH='18px'
            boxSize='18px'
            borderRadius='5px'
          />
          <Box as='span' fontSize='12px' fontWeight={600}>
            {foundMember?.name}
          </Box>
        </Flex>
      ))}
      {remainingAssignees.length > 0 && (
        <Tooltip
          label={remainingAssignees.map((member) => member?.name).join(', ')}
          aria-label='Remaining members'
        >
          <Flex
            bg={HeaderColors.Gray}
            borderRadius='5px'
            w='fit-content'
            p='4px'
            align='center'
            gap='4px'
          >
            <Box as='span' fontSize='12px' fontWeight={600}>
              +{remainingAssignees.length}
            </Box>
          </Flex>
        </Tooltip>
      )}
    </Flex>
  );
};

AssignmentType.displayName =
  'pages/LocationsNew/Components/ProjectDetailPhase/components/AssignmentType';

export default AssignmentType;
