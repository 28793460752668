import React, { FC } from 'react';
import { Center, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { EmptyState } from 'sub-components';
import { Button } from 'atoms';
import { useControlLayer } from './control';
import { GridView, SearchFilter } from './components';
import CreateAuditButton from '../CreateAuditButton';

const SupervisedTab: FC = () => {
  const { t } = useTranslation(['audit']);
  const {
    data,
    loading,
    selectedRange,
    supervisedSearchQuery,
    refetchHandler,
    setSelectedRange,
  } = useControlLayer();

  if (
    data.length === 0 &&
    !supervisedSearchQuery &&
    !loading &&
    (!selectedRange || !selectedRange?.from || !selectedRange?.to)
  ) {
    return (
      <Center>
        <EmptyState
          image='Form'
          title='No audits scheduled'
          description="Your audits will be shown here once it's been scheduled"
          showButton
          buttonProps={
            <CreateAuditButton>
              <Button variant='solid' colorScheme='blue'>
                {t('audit:createAudit')}
              </Button>
            </CreateAuditButton>
          }
        />
      </Center>
    );
  }

  return (
    <Flex flexDir='column' gap={4}>
      <SearchFilter
        loading={loading}
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
      />
      <GridView
        loading={loading}
        selectedRange={selectedRange}
        refetchHandler={refetchHandler}
      />
    </Flex>
  );
};

SupervisedTab.displayName =
  'sub-components/audits/audit-tab/supervised/SupervisedTab';

export default SupervisedTab;
