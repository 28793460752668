import { FC } from 'react';
import { Flex, Text, Img, SimpleGrid, Box } from '@chakra-ui/react';
import { generateValidId } from './helper';
import { MediaSummaryResponseType } from './types/report.types';
import { IKImage } from 'imagekitio-react';
import { Image } from 'ui-components';

type MediaSummaryProps = {
  response?: MediaSummaryResponseType[];
};

const MediaSummary: FC<MediaSummaryProps> = ({ response }) => {
  const handleScroll = (section: string, item: string) => {
    if (section === '') {
      section = 'audit-responses';
    }

    item.replaceAll('?', '');
    item = `${section}-${item}`;

    const element = document.getElementById(`${generateValidId(item)}`);
    element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <Box>
      <SimpleGrid spacingX={20} spacingY={10}>
        {response?.map((item, index) => {
          return (
            <Flex px={'1.5rem'} flexDir={'column'} key={index}>
              <Flex flexDir={'column'} gap={2}>
                {item?.url?.length > 0 && (
                  <Text
                    lineHeight={'48px !important'}
                    fontWeight={500}
                    fontSize={'20px'}
                  >
                    {`Q${item?.questionIndex}. ${item?.question}`}
                  </Text>
                )}
                <Flex gap={10} wrap={'wrap'}>
                  {item?.url?.map((img, i) => {
                    return (
                      <Box key={i}>
                        <Image
                          key={i}
                          style={{ height: 'auto', width: '34vw' }}
                          src={img}
                          onClick={() =>
                            handleScroll(item?.section, item?.question)
                          }
                        />
                      </Box>
                    );
                  })}
                </Flex>
              </Flex>
            </Flex>
          );
        })}
      </SimpleGrid>
    </Box>
  );
};

export default MediaSummary;
