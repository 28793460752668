import React, { FC, useMemo } from 'react';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { toArray } from '../../../../../../utils';
import { usersEntityObj } from '../../../../../Header';
import { useLauncherConfigContext } from '../../../common';
import FilterViewItem, { FilterBaseItem } from './FilterViewItem';

interface TItem {
  label: string;
  value?: string;
  actionKey: string;
}

interface IProps {}

const FilterView: FC<IProps> = () => {
  const { t, i18n } = useTranslation('launcher');

  const ObjectData = useMemo(() => {
    return {
      deadline: t('filter.phaseWithDeadline'),
      nonDeadline: t('filter.phaseWithoutDeadline'),
    };
  }, [t, i18n.language]);

  const { filterBy, changeFilterBy } = useLauncherConfigContext((state) => ({
    filterBy: state.filterBy,
    changeFilterBy: state.changeFilterBy,
  }));

  const entityObjData = useReactiveVar(usersEntityObj);

  const users = useMemo(() => {
    return entityObjData?.reduce((acc, nextValue) => {
      acc[nextValue.eid] = nextValue.name;
      return acc;
    }, {} as Record<string, string>);
  }, [entityObjData]);

  const data = useLauncherConfigContext((state) => {
    const categories = state.taskCategory;
    return Object.entries(state.filterBy || {}).reduce((acc, [key, value]) => {
      switch (key) {
        case 'taskTypes':
          return [
            ...acc,
            ...toArray(value).map((taskType) => {
              return {
                label: categories[taskType],
                value: taskType,
                actionKey: 'taskTypes',
              };
            }),
          ];
        case 'assignees':
          return [
            ...acc,
            ...toArray(value).map((assignee) => {
              return {
                label: users[assignee],
                value: assignee,
                actionKey: 'assignees',
              };
            }),
          ];
        case 'other':
          Object.entries(value || {}).forEach(([oKey, OValue]) => {
            if (OValue) {
              acc.push({
                label: ObjectData[oKey as never],
                // value: OValue as boolean,
                actionKey: `other.${oKey}`,
              });
            }
          });
      }
      return acc;
    }, [] as TItem[]);
  });

  const [renderList, remainList] = useMemo(() => {
    return [data.slice(0, 3), data.slice(3)];
  }, [data]);

  const onRemoveClick = (item: TItem) => {
    switch (item.actionKey) {
      case 'taskTypes':
        changeFilterBy(
          'taskTypes',
          toArray(filterBy?.taskTypes).filter((t) => t !== item.value)
        );
        break;
      case 'assignees':
        changeFilterBy(
          'assignees',
          toArray(filterBy?.assignees).filter((t) => t !== item.value)
        );
        break;
      default:
        changeFilterBy(item.actionKey, false);
    }
  };

  if (!renderList?.length) {
    return null;
  }

  return (
    <Flex align='center' gap={2}>
      <Box fontSize='15px' fontWeight='600' color='#33383F' whiteSpace='nowrap'>
        {t('filterApplied')}
      </Box>

      {renderList?.map((item, index) => (
        <FilterViewItem
          title={item?.label}
          key={index}
          onRemoveClick={() => onRemoveClick(item)}
        />
      ))}

      {remainList?.length ? (
        <Tooltip
          hasArrow
          paddingX='8px'
          paddingY='6px'
          borderRadius='4px'
          placement='bottom-end'
          label={remainList.map((item) => item.label).join(', ')}
          shouldWrapChildren
        >
          <FilterBaseItem title={`+ ${remainList?.length}`} />
        </Tooltip>
      ) : null}
    </Flex>
  );
};

export default FilterView;
