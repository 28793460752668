import { IconButton, Image } from '@chakra-ui/react';
import Dark_editIcon from 'assets/images/dark_edit.png';
import { AddIcon } from '@chakra-ui/icons';
import { LauncherAssignee } from 'pages/launcher/config-editor/common';
import { LauncherTask } from 'sub-components/Launcher/launcher-config/common/launcher-config.fragment';
import React, { FC, useCallback, useMemo } from 'react';
import { useAddTaskAssigneeInfo, useAddTaskToAssignee } from '../modals';
import { useAddAssignee } from 'sub-components/Launcher/launcher-config/common/add-assignee';
import { ExtendedLauncherContent } from '../../store/project-detail-phase.types';
import { useChangeWarning } from './useChangeWarning';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';

interface IProps {
  data: ExtendedLauncherContent;
  eid: string;
  task: LauncherTask;
}

const OperationBtn: FC<IProps> = ({ data, eid, task }) => {
  const entityData = useReactiveVar(usersEntityObj);
  const addTaskAssigneeInfo = useAddTaskAssigneeInfo();
  const addTaskToAssignee = useAddTaskToAssignee();
  const addAssignee = useAddAssignee();
  const changeWarning = useChangeWarning();

  const userNames = useCallback(
    (userIds: string[]) => {
      return entityData
        ?.filter((en) => userIds?.includes(en?.eid))
        ?.map((user) => user?.name);
    },
    [entityData]
  );

  const actionModal = (task: LauncherTask) => {
    addAssignee({
      title: `Add ${data?.category} Assignee`,
      description: 'Task assignee will be conducting the selected task',
      value: {
        assignedType: task?.assignedType,
        assignedUser: task?.assignedUser,
      },
      onChange: (value: LauncherAssignee) =>
        changeWarning(data, eid, value, task),
    });
  };

  const onClickHandler = () => {
    if (!data?.assignedType) {
      addTaskAssigneeInfo({
        names:
          data?.assignedType === 'location'
            ? ['Location owner']
            : userNames(data?.assignedUser || []),
        onOkPress: () => actionModal(task),
      });
    } else {
      addAssignee({
        title: `Add ${data?.category} Assignee`,
        description:
          'Phase assignee will be conducting all the tasks within the mentioned phase',
        value: {
          assignedType: task?.assignedType,
          assignedUser: task?.assignedUser,
        },
        onChange: (value: LauncherAssignee) =>
          changeWarning(data, eid, value, task),
      });
    }
  };

  return (
    <IconButton
      icon={
        task?.assignedType ? (
          <Image src={Dark_editIcon} boxSize='20px' />
        ) : (
          <AddIcon />
        )
      }
      aria-label='Edit'
      size='sm'
      variant='outline'
      border='none'
      outline={'2px solid #B1E5FC'}
      onClick={(e) => {
        e?.preventDefault();
        e?.stopPropagation();
        onClickHandler();
      }}
    />
  );
};

OperationBtn.displayName =
  'displayName:pages/LocationsNew/Components/ProjectDetailPhase/components/PhaseAccordionPanel/OperationBtn';

export default OperationBtn;
