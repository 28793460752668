import { Box, Flex, Text } from '@chakra-ui/react';
import { Authorize, AuthRole } from 'authorization';
import { ActionButton } from 'ui-components';
import { AddIcon } from '@chakra-ui/icons';
import { useComplianceUpload } from 'sub-components/nexus/Compliance';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

interface IParams {
  locationId: string;
}

const Heading: FC = () => {
  const complianceUpload = useComplianceUpload();
  const { locationId } = useParams<IParams>();

  return (
    <Flex justifyContent={'space-between'} alignItems={'center'}>
      <Flex gap={3} alignItems={'center'}>
        <Box
          borderRadius={'4px'}
          width={'16px'}
          height={'32px'}
          backgroundColor={'#CABDFF'}
        ></Box>
        <Text fontSize={'16px'} fontWeight={600}>
          Documents
        </Text>
      </Flex>
      <Flex alignItems={'center'}>
        <Authorize
          permittedFor={'user'}
          permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
        >
          <ActionButton
            color={'#2A85FF'}
            leftIcon={<AddIcon />}
            backgroundColor={'white'}
            border={'1px solid #EFEFEF'}
            actionFn={() =>
              complianceUpload({
                preDefinedLocationId: locationId,
              })
            }
          >
            Add document
          </ActionButton>
        </Authorize>
      </Flex>
    </Flex>
  );
};

export default Heading;
