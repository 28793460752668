import React, { FC } from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  useRadioGroupContext,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { TFuncKey } from 'i18next';
import { useTranslation } from 'react-i18next';

import { Dropdown, Input, SelectOption } from '../../../../../../atoms';

import { TriggerInput } from '../../trigger';

interface IOption {
  label: TFuncKey<'audit', undefined>;
  value: string;
}

const options: IOption[] = [
  { label: 'days', value: 'days' },
  { label: 'weeks', value: 'weeks' },
  { label: 'months', value: 'months' },
];

function isNumber(value: string) {
  return value.match(/[0-9]+/);
}

const toValue = (value?: string): SelectOption | undefined =>
  value ? { value: value, label: value } : undefined;

interface IProps {
  value?: string;
}

const Deadline: FC<IProps> = ({ value: valueProp }) => {
  const group = useRadioGroupContext();
  const { t } = useTranslation('audit');

  let isChecked = false;
  // eslint-disable-next-line eqeqeq
  if (group?.value != null && valueProp != null) {
    isChecked = group.value === valueProp;
  }

  if (!isChecked) {
    return null;
  }

  return (
    <Flex align='start' gap={3}>
      <Controller<TriggerInput, 'trigger.activationTrigger.deadlineValue'>
        name='trigger.activationTrigger.deadlineValue'
        rules={{
          required: 'Deadline value is required',
        }}
        defaultValue={2}
        render={({ field, fieldState }) => {
          return (
            <FormControl width='unset' isInvalid={fieldState.invalid}>
              <Input
                variant='auditOutline'
                width='64px'
                isInvalid={!!fieldState.error}
                // isReadOnly={isReadOnly}
                textAlign='center'
                color='#2A85FF'
                fontSize='14px'
                fontWeight='600'
                {...field}
                onKeyPressCapture={(event) => {
                  if (!isNumber(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </FormControl>
          );
        }}
      />

      <Controller<TriggerInput, 'trigger.activationTrigger.deadlineUnit'>
        name='trigger.activationTrigger.deadlineUnit'
        rules={{
          required: 'Repeat cycle required',
        }}
        defaultValue='days'
        render={({ field, fieldState }) => {
          return (
            <FormControl width='unset' isInvalid={fieldState.invalid}>
              <Flex gap={4} align='center'>
                <Dropdown
                  options={options}
                  isSearchable={false}
                  value={toValue(field.value)}
                  isDisabled={field.disabled}
                  onChange={(newValue) => {
                    return field.onChange(newValue.value);
                    // return callAll(field.onChange, resetFields)(newValue.value);
                  }}
                  getOptionLabel={(option) => t(option.label)}
                  selectStyles={{
                    container: { minWidth: '150px' },
                    menuList: {
                      minWidth: '100%',
                    },
                    singleValue: {
                      fontSize: '14px',
                      fontWeight: '600',
                      color: '#2A85FF',
                    },
                    placeholder: {
                      fontSize: '14px',
                    },
                    dropdownIndicator: {
                      color: '#2A85FF',
                    },
                  }}
                  inputStyle={{
                    borderWidth: '2px',
                    borderRadius: '12px',
                    outline: 'none',
                    backgroundColor: 'white',
                  }}
                />
              </Flex>
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </Flex>
  );
};

Deadline.displayName = 'sc/L/lc/c/ctf/tts/Deadline';

export default Deadline;
