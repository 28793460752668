export const dropdownStyles = {
  selectStyles: {
    menuList: {
      minWidth: '100%',
    },
    singleValue: {
      fontSize: '14px',
      fontWeight: '600',
      color: '#2A85FF',
    },
    placeholder: {
      fontSize: '14px',
    },
    dropdownIndicator: {
      color: '#2A85FF',
    },
  },
  inputStyle: {
    borderWidth: '2px',
    borderRadius: '12px',
    outline: 'none',
    backgroundColor: 'white',
  },
};
