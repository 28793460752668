import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useUserEntity } from '../../../hooks';
import { getLauncher } from '../../../configs';

import { CheckListDetails, ProjectStartAndSupervisor } from '../Components';
import { ActionButton } from '../../../ui-components';

import { IFormInput } from './add-location.types';

import {
  getDateFromDuration,
  getPhaseTasksDuration,
} from 'utils/phaseTaskDuration';
import { useGoLiveDate } from 'ui-components/GoLiveDateModal/useGoLiveDate';
import { deployProjectDetails } from './add-location.events';
import ProjectDetailPhase from 'pages/LocationsNew/Components/ProjectDetailPhase';
import { ExtendedLauncherConfig } from '../Components/ProjectDetailPhase/store/project-detail-phase.types';
import {
  ProjectDetailPhaseProvider,
  useProjectDetailPhaseContext,
} from '../Components/ProjectDetailPhase/store/context';
import { useInsufficientTimeInfo } from '../Components/ProjectDetailPhase/components/modals';

interface IProps {}

const ProjectDetails: FC<IProps> = () => {
  const { launcherData, updateView } = useProjectDetailPhaseContext(
    (state) => ({
      launcherData: state?.launcherData,
      updateView: state?.updateView,
    })
  );

  const insufficientTimeInfo = useInsufficientTimeInfo();

  const { control, handleSubmit, getValues, setValue } =
    useFormContext<IFormInput>();

  const endDate = moment(getValues('startDate'));

  const phaseContents = useUserEntity(
    (entity) => getLauncher(entity)?.contents || []
  );

  const { t } = useTranslation(['common', 'home']);

  const loading = useWatch<IFormInput, 'loading'>({
    control: control,
    name: 'loading',
  });

  // const tasksTotalDuration = useMemo(() => {
  //   return getPhaseTasksDuration(phaseContents);
  // }, [phaseContents]);

  // let nextDayTasksTotalDuration = tasksTotalDuration + 1;

  // const endDate = useMemo(() => {
  //   return getDateFromDuration(nextDayTasksTotalDuration); // adding extra 2 to consider the current day making it "TOTAL DAYS OF TASKS + CURRENT DAY" and making it live by next day of total count
  // }, [nextDayTasksTotalDuration]);

  const goLiveDate = useGoLiveDate();

  const startDateWatch = useWatch<IFormInput, 'startDate'>({
    control: control,
    name: 'startDate',
  });

  const estimatedGoLiveDateWatch = useWatch<IFormInput, 'estimatedGoLiveDate'>({
    control: control,
    name: 'estimatedGoLiveDate',
  });

  const isGoLiveDateBeforeTaskDueDate = useCallback(() => {
    let startDateStartOfDay = moment.utc(startDateWatch)?.startOf('day');
    let flag = startDateStartOfDay.isBefore(
      moment(estimatedGoLiveDateWatch).utc()?.startOf('day')
    );
    return flag;
  }, [startDateWatch, estimatedGoLiveDateWatch]);

  useEffect(() => {
    let startDateValue = startDateWatch;
    if (!startDateValue) {
      setValue('startDate', endDate?.toDate());
    }
  }, [endDate, startDateWatch]);

  useEffect(() => {
    updateView('view');
  }, []);

  const updateTab = () => {
    handleSubmit((values) => {
      deployProjectDetails(values);
      return setValue('currentTab', 3);
    })();
  };

  const nextClickHandler = () => {
    if (isGoLiveDateBeforeTaskDueDate()) {
      insufficientTimeInfo({
        onOkPress: () => {
          setValue('selectedBoardData', launcherData?.contents || []);
          updateTab();
        },
        onCancelPress: () => {
          setValue('selectedBoardData', launcherData?.contents || []);
          setValue('startDate', estimatedGoLiveDateWatch);
        },
      });
    } else {
      setValue('selectedBoardData', launcherData?.contents || []);
      updateTab();
    }
    // if (isGoLiveDateBeforeTaskDueDate()) {
    //   goLiveDate({
    //     onProceed: () => {
    //       setValue('selectedBoardData', launcherData?.contents || []);
    //       updateTab();
    //     },
    //     onCancel: () => setValue('startDate', endDate?.toDate()),
    //   });
    // } else {
    //   setValue('selectedBoardData', launcherData?.contents || []);
    //   updateTab();
    // }
  };

  return (
    <Flex flexDir='column' gap={4}>
      <Box fontSize='32px' fontWeight='600' color='#111315' textAlign='center'>
        Project Details
      </Box>

      {/* <CheckListDetails /> */}
      <ProjectDetailPhase />

      <ProjectStartAndSupervisor endDate={endDate} />

      <ActionButton
        size='lg'
        type='submit'
        colorScheme='blue'
        width='fit-content'
        minW='170px'
        alignSelf='flex-end'
        fontSize='15px'
        fontWeight='600'
        // actionFn={handleSubmit}
        // actionFn={handleSubmit(() => setValue('currentTab', 3))}
        actionFn={nextClickHandler}
        isLoading={loading}
        disabled={loading}
      >
        {/* {t('home:addCard.location')} */}
        Next
      </ActionButton>
    </Flex>
  );
};

ProjectDetails.displayName =
  'displayName:pages/LocationsNew/AddLocation/ProjectDetails';

export default ProjectDetails;
