import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import get from 'lodash/get';

import TaskAssignee from '../../../board-view/sections/launcher-tasks/TaskAssignee'; // TODO

import { useLauncherConfigContext } from '../../../common';
import { TaskItemWrapper } from './task-item.styles';
import LauncherTaskFooter from './LauncherTaskFooter';

interface IProps {
  sectionIndex: number;
  taskIndex: number;
}

const LauncherTaskItem: FC<IProps> = ({ sectionIndex, taskIndex }) => {
  const task = useLauncherConfigContext((state) => {
    return get(state.config.contents, [sectionIndex, 'tasks', taskIndex]);
  });
  const categories = useLauncherConfigContext((state) => state.taskCategory);
  const isSelected = useLauncherConfigContext((state) => {
    return !!(
      state.taskForm?.sectionIndex === sectionIndex &&
      state.taskForm?.taskIndex === taskIndex &&
      state.taskForm?.taskEid
    );
  });

  const openTaskForm = useLauncherConfigContext((state) => state.openTaskForm);

  const actionHandler = (action: string) => {
    switch (action) {
      case 'edit':
        openTaskForm({
          sectionIndex,
          taskIndex,
          taskEid: task.eid,
        });
        break;
      case 'add-above':
        openTaskForm({
          sectionIndex,
          taskIndex: taskIndex,
        });
        break;
      case 'add-below':
        openTaskForm({
          sectionIndex,
          taskIndex: taskIndex + 1,
        });
        break;
      default:
        // eslint-disable-next-line no-console
        console.log(action);
    }
  };

  return (
    <TaskItemWrapper
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: 'easeInOut', duration: 0.2 }}
      aria-selected={isSelected}
      onClick={() => {
        openTaskForm({ sectionIndex, taskIndex, taskEid: task.eid });
      }}
    >
      <Flex flex={1} flexDir='column' gap='6px'>
        <Box fontSize='15px' fontWeight='600' color='#33383F'>
          {task.title}
        </Box>
        <Flex justify='space-between'>
          <LauncherTaskFooter
            category={categories[task.category]}
            stepsCount={task?.steps?.length}
            trigger={task.trigger}
          />
          <TaskAssignee
            assignedType={task.assignedType!}
            assignedUser={task.assignedUser || []}
          />
        </Flex>
      </Flex>
    </TaskItemWrapper>
  );
};

LauncherTaskItem.displayName = 'sc/L/lc/ev/s/lt/LauncherTaskItem';

export default LauncherTaskItem;
