import { gql } from '@apollo/client';

const LAUNCHER_TASK_FIELDS = gql`
  fragment LauncherTaskFields on LocationLaunchContentsTask {
    eid
    phase
    phaseEid
    taskCategoryEid
    completedAt
    completedBy
    isPhaseSelected
    isRequired
    locationStartDate
    startDate
    dueDate
    assignedUsers
    assignedType
    liveDate
    isCompleted
    title
    description
    duration
    buffer
    dependency
    docRequired
    launchId
    steps {
      stepId
      title
      isCompleted
      type
      formId
      sopId
      trainingAssignee
      trainingId
      formResponseId
      completedAt
      completedBy
    }
    locationId
    files {
      name
      url
      type
      mimetype
      fileSize
      createdBy {
        eid
        name
      }
      createdAt
      updatedAt
    }
    triggerDetails {
      triggerChoice
      activationOffset
      deadlineOffset
      triggerItemId
      activationOrder
      deadlineOrder
      dependantOn
      deadlineType
      startDate
      dueDate
    }
  }
`;

export const COMPLETE_LAUNCHER_TASK = gql`
  mutation CompleteLauncherTask($input: CompleteLauncherTaskInputInput) {
    CompleteLauncherTask(input: $input) {
      ...LauncherTaskFields
    }
  }
  ${LAUNCHER_TASK_FIELDS}
`;

export const MARK_LAUNCHER_TASK_UNDONE = gql`
  mutation MarkLauncherTaskUndone(
    $launchId: String!
    $taskId: String!
    $stepId: String
  ) {
    MarkLauncherTaskUndone(
      launchId: $launchId
      taskId: $taskId
      stepId: $stepId
    ) {
      ...LauncherTaskFields
    }
  }
  ${LAUNCHER_TASK_FIELDS}
`;
