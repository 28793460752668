import React, { FC } from 'react';
import { Button } from 'atoms';
import { useUserEntity } from 'hooks';
// import useCombinedStore from 'zustandStore/store';

interface IProps {
  launchHandler: () => void;
}

const ActionButton: FC<IProps> = ({ launchHandler }) => {
  // const { emitEvent } = useCombinedStore();
  const isLauncherEnabled = useUserEntity(
    (entity) => entity?.features?.launcher
  );

  return (
    <Button
      variant='solid'
      colorScheme='blue'
      size='lg'
      w='full'
      isDisabled={!isLauncherEnabled}
      onClick={() =>
        // emitEvent('preLaunchLocationModal', 'goToLauncher', { msg: 'redirect' })
        launchHandler()
      }
    >
      Launch via launcher
    </Button>
  );
};

export default ActionButton;
