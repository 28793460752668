import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Box,
  BoxProps,
  Flex,
  Icon,
  Text,
  ThemeTypings,
} from '@chakra-ui/react';
import {
  useTable,
  useSortBy,
  UseTableOptions,
  useRowSelect,
  Row,
} from 'react-table';

import { usePagination } from '../usePagination';
import { NoContentProps } from '../NoContent';
import { Pagination } from '../Pagination';
import Loader from '../../../ui-components/Loader';
import TableHeader from './TableHeader';
import { Column } from './SortingTable.types';
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { css, CSSObject } from '@emotion/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';

interface TableProps<T extends object = {}>
  extends Pick<BoxProps, 'background'> {
  columns: Column<T>[];
  data: T[];
  page: number;
  totalRegisters?: number;
  onPageChange?: (page: number) => void;
  colorScheme?: ThemeTypings['colorSchemes'];
  emptyData?: {
    text?: string;
    content?: ReactNode;
  };
  isLoading?: boolean;
  isHidePagination?: boolean;
  stateReducer?: UseTableOptions<T>['stateReducer'];
  isResponsive?: boolean;
  tableFrom?: string;
  options?: Record<string, any>;
  onRowSelectChange?: (data: Row<T>[]) => void;
  registersPerPage?: number;
  tableLayout?: 'auto' | 'fixed';
  showDivider?: boolean;
  customWrapperStyles?: CSSObject;
}

type WrapperProps = {
  customStyles?: CSSObject;
  showDivider?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  table {
    background-color: white;
    thead {
      background-color: #f4f4f4;
      height: 44px !important;
      tr {
        border-radius: 5px;
        th:first-of-type {
          padding: 0 20px !important;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        th:last-child {
          padding: 0 20px !important;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
    tbody {
      tr {
        ${(props) =>
          props.showDivider &&
          `
          &:not(:last-child) {
            border-bottom: 1px solid #efefef;
          }
        `}
      }
    }
  }
  ${(props) => props.customStyles};
`;
const AccordionRow = ({ data, title, columns }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <Box as='tbody'>
        <Box as='td' colSpan={columns?.length}>
          <Flex
            align='center'
            p={4}
            cursor='pointer'
            onClick={() => setIsOpen(!isOpen)}
          >
            <Icon as={isOpen ? ChevronDownIcon : ChevronRightIcon} mr={2} />
            <Text fontWeight='500' fontSize={'14px'}>
              {title}
            </Text>
          </Flex>
        </Box>
      </Box>
      {isOpen &&
        data?.map((item, index) => (
          <Box as='tr' key={index} className='accordion-item'>
            {columns?.map((column, cellIndex) => (
              <Box as='td' key={cellIndex} p={4}>
                {column.Cell
                  ? column.Cell({
                      cell: {
                        value: item[column.accessor],
                        row: { original: item },
                      },
                    })
                  : item[column.accessor]}
              </Box>
            ))}
          </Box>
        ))}
    </>
  );
};

function AccordionSortingTable<T extends object = {}>({
  page,
  onPageChange,
  totalRegisters,
  data,
  columns,
  colorScheme = 'teal',
  emptyData,
  isLoading,
  isHidePagination = false,
  stateReducer,
  isResponsive,
  tableFrom,
  options,
  onRowSelectChange,
  registersPerPage,
  tableLayout = 'fixed',
  background,
  customWrapperStyles,
  showDivider,
}: TableProps<T>): React.ReactElement {
  const pagination = usePagination({
    totalRegisters: totalRegisters as number,
    registersPerPage: registersPerPage,
    page,
    items: data,
  });

  const groupedData = useMemo(() => {
    const subFolders = data.filter((item) => item.type === 'folder');
    const chapters = data.filter((item) => item.type === 'chapter');

    return {
      subFolders,
      chapters,
    };
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    selectedFlatRows,
  } = useTable(
    { columns, data: pagination.pageItems, stateReducer, ...options },
    useSortBy,
    useRowSelect
  );

  useEffect(() => {
    onRowSelectChange?.(selectedFlatRows);
  }, [selectedFlatRows, onRowSelectChange]);

  if (isLoading) {
    return (
      <div className='sop-list-loader'>
        <Loader />
      </div>
    );
  }

  if (!isLoading && data.length === 0) {
    return (
      <div className='sop-list-loader'>
        {emptyData?.content || emptyData?.text}
      </div>
    );
  }

  return (
    <Wrapper customStyles={customWrapperStyles} showDivider={showDivider}>
      <Box
        as='table'
        {...getTableProps()}
        borderRadius='8'
        w='full'
        h='100%'
        backgroundColor={background || '#fcfcfc'}
        style={{ tableLayout: tableLayout }}
      >
        <TableHeader headerGroups={headerGroups} />

        <Box as='tbody' {...getTableBodyProps()} className='table-body'>
          {groupedData.subFolders.length > 0 && (
            <AccordionRow
              title='Subfolders'
              data={groupedData.subFolders}
              columns={columns}
            />
          )}

          {groupedData.chapters.length > 0 && (
            <AccordionRow
              title='Chapters'
              data={groupedData.chapters}
              columns={columns}
            />
          )}
        </Box>
      </Box>
      {!isHidePagination && (
        <div
          className={
            isResponsive
              ? 'table-responsive-pagination-pagination'
              : 'fixed-pagination-btn'
          }
        >
          <Pagination
            {...pagination}
            colorScheme={colorScheme}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </Wrapper>
  );
}

export default AccordionSortingTable;
