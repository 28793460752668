import React, { FC } from 'react';
import { Flex, Skeleton } from '@chakra-ui/react';
import { useFormState } from 'react-hook-form';

const data = Array.from({ length: 5 });

interface IProps {}

const UploadSkeleton: FC<IProps> = ({ children }) => {
  const isLoading = useFormState().isLoading;

  if (isLoading) {
    return (
      <React.Fragment>
        {data.map((_, i) => (
          <Flex flexDir='column' gap={3} pt='6px' key={i}>
            <Skeleton width='45%' height='16px' borderRadius='4px' />
            <Skeleton width='full' height='46px' borderRadius='12px' />
          </Flex>
        ))}
      </React.Fragment>
    );
  }

  return <>{children}</>;
};

UploadSkeleton.displayName =
  'displayName:sub-components/nexus/Compliance/Create/components/UploadSkeleton';

export default React.memo(UploadSkeleton);
