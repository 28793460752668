import { Checkbox, Flex, Radio, RadioGroup } from '@chakra-ui/react';
import { useProjectDetailPhaseContext } from 'pages/LocationsNew/Components/ProjectDetailPhase/store/context';
import {
  ExtendedLauncherConfig,
  SelectedChoice,
} from 'pages/LocationsNew/Components/ProjectDetailPhase/store/project-detail-phase.types';
import React, { FC, useMemo } from 'react';
import { usePhaseNotRequiredInfo } from '../../../modals';
import { cloneDeep } from '@apollo/client/utilities';
import { CloseIcon } from '@chakra-ui/icons';
import { analyzePhaseDependencies } from 'pages/LocationsNew/Components/ProjectDetailPhase/utils';

interface IProps {
  eid: string;
}

const Selection: FC<IProps> = ({ eid }) => {
  const { initData, mode, updateData } = useProjectDetailPhaseContext(
    (state) => ({
      initData: state?.launcherData,
      mode: state?.view,
      updateData: state?.updateLauncherData,
    })
  );
  let allDependencies = analyzePhaseDependencies(initData?.contents || []);

  const phaseNotRequiredInfo = usePhaseNotRequiredInfo();

  const data = useMemo(() => {
    return initData?.contents?.find((content) => content?.eid === eid);
  }, [eid, initData]);

  /**
   * Handles the change event for radio buttons.
   * Updates the selected choice for the current phase.
   */
  const onRadioChange = (value: string) => {
    const _initData = cloneDeep(initData);
    const currentPhase = _initData?.contents?.find(
      (init) => init?.eid === data?.eid
    );
    if (currentPhase) {
      currentPhase.selectedChoice = value as SelectedChoice;
      currentPhase.showChoice = true; // Keep showing radio buttons after selection
    }
    updateData(_initData);
  };

  /**
   * Handles the close action for the choice selection.
   * Resets the selected choice to 'phaseRequired' and hides the choice UI.
   */
  const onClose = () => {
    const _initData = cloneDeep(initData);
    const currentPhase = _initData?.contents?.find(
      (init) => init?.eid === data?.eid
    );
    if (currentPhase) {
      currentPhase.selectedChoice = 'phaseRequired';
      currentPhase.showChoice = false;
    }
    updateData(_initData);
  };

  /**
   * Initial warning handler when the checkbox state changes.
   * If there are dependencies, show a confirmation modal before proceeding.
   */
  const initialWarning = (event: React.ChangeEvent<HTMLInputElement>) => {
    let foundDep = allDependencies?.find((dep) => dep?.phaseId === eid);
    if (foundDep?.dependentPhases?.length === 0) {
      onCheckboxChange(event);
    } else {
      phaseNotRequiredInfo({
        onOkPress: () => onCheckboxChange(event),
      });
    }
  };

  /**
   * Handles the checkbox change event.
   * Updates the current phase and dependent phases based on the deselection.
   */
  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const _initData = cloneDeep(initData);
    const deselectedPhaseEid = data?.eid;

    if (!deselectedPhaseEid) return; // Safety check

    // Step 1: Update the deselected phase
    const deselectedPhase = _initData?.contents?.find(
      (phase) => phase?.eid === deselectedPhaseEid
    );
    if (deselectedPhase) {
      deselectedPhase.selectedChoice = 'phaseNotRequired';
      deselectedPhase.showChoice = true;
    }

    let foundDep = allDependencies?.find((dep) => dep?.phaseId === eid);
    _initData?.contents?.map((content) => {
      foundDep?.dependentPhases?.map((dep) => {
        if (content?.eid === dep?.id) {
          content.selectedChoice = 'phaseNotRequired';
          content.showChoice = true;
        }
      });
    });

    updateData(_initData);
  };

  if (mode === 'view') {
    return null;
  }

  if (
    data?.showChoice ||
    data?.selectedChoice === 'markComplete' ||
    data?.selectedChoice === 'phaseNotRequired'
  ) {
    return (
      <Flex
        bg='#EFEFEF'
        borderRadius='9px'
        p='10px 12px'
        align='center'
        minW='fit-content'
        gap='10px'
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <RadioGroup value={data?.selectedChoice} onChange={onRadioChange}>
          <Flex gap='10px'>
            <Flex bg='white' borderRadius='8px' p='5px'>
              <Radio value='markComplete'>Mark as Completed</Radio>
            </Flex>
            <Flex bg='white' borderRadius='8px' p='5px'>
              <Radio value='phaseNotRequired'>Phase not required</Radio>
            </Flex>
          </Flex>
        </RadioGroup>
        <CloseIcon
          color='#6F767E'
          fontSize='12px'
          cursor='pointer'
          onClick={(e) => {
            e?.preventDefault();
            e?.stopPropagation();
            onClose();
          }}
        />
      </Flex>
    );
  }

  return (
    <Checkbox
      isChecked={data?.selectedChoice === 'phaseRequired'}
      size='lg'
      onChange={initialWarning}
      onClick={(e) => e.stopPropagation()}
    />
  );
};

Selection.displayName =
  'displayName:pages/LocationsNew/Components/ProjectDetailPhase/components/PhaseAccordionButton/phase-button-upper/components/Selection';

export default Selection;
