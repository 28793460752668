import { gql } from '@apollo/client';
import {
  LauncherConfig,
  LauncherConfigFragment,
} from './launcher-config.fragment';

export interface LauncherConfigResponse {
  LauncherById: LauncherConfig;
}

export interface LTaskCategoryEntity {
  eid: string;
  name: string;
}

export interface TaskCategoryResponse {
  EntityLauncherTaskCategories: LTaskCategoryEntity[];
}

export const LAUNCHER_TASK_CATEGORY = gql`
  query EntityLauncherTaskCategories {
    EntityLauncherTaskCategories {
      eid
      name
    }
  }
`;

export const LAUNCHER_CONFIG = gql`
  ${LauncherConfigFragment}
  query LauncherById($eid: String!) {
    LauncherById(eid: $eid) {
      locationLaunches {
        isLive
        details {
          locationName
        }
      }
      ...LauncherConfig
    }
  }
`;

export const ADD_LAUNCHER_CONFIG = gql`
  mutation AddLauncher($input: NewLauncherAddInput) {
    AddLauncher(input: $input) {
      eid
    }
  }
`;

export const UPDATE_LAUNCHER_CONFIG = gql`
  mutation UpdateLauncherTasks($input: UpdateLauncherInputInput) {
    UpdateLauncherTasks(input: $input) {
      eid
    }
  }
`;
