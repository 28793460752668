import { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
// import { useWatch } from 'react-hook-form';
import { toArray } from 'utils';
import { AuthRole } from 'authorization';
import { SelectOption } from 'atoms';
import { usersEntityObj } from '../../../../Header';
// import { IFormInput } from '../../audit-schedule.types';

interface Options {
  auditors: SelectOption[];
  locations: SelectOption[];
}

export const useAuditorOptions = (fieldIndex: number): Options => {
  const userEntities = useReactiveVar(usersEntityObj);

  // const _auditors = useWatch<IFormInput, 'auditors'>({
  //   name: 'auditors',
  // });

  // const _users = useMemo(() => {
  //   return _auditors.reduce<string[]>((acc, a, index) => {
  //     if (index === fieldIndex) {
  //       return acc;
  //     }
  //
  //     if (a.userId) {
  //       acc.push(a.userId);
  //     }
  //
  //     return acc;
  //   }, []);
  // }, [_auditors, fieldIndex]);

  const _users = useMemo((): string[] => [], [fieldIndex]);

  return useMemo(() => {
    return toArray(userEntities).reduce<Options>(
      (acc, user) => {
        switch (user.type) {
          case 'user':
            if (
              user.status === 'active' &&
              (user.authRole === AuthRole.SUPER_ADMIN ||
                user.authRole === AuthRole.ADMIN) &&
              !_users.includes(user.eid)
            ) {
              acc.auditors.push({
                label: user.name,
                value: user.eid,
              });
            }
            break;
          case 'branch':
            if (
              user.locationStatus === 'open' ||
              user.locationStatus === 'development'
            ) {
              acc.locations.push({ label: user.name, value: user.eid });
            }
            break;
          default:
            break;
        }

        return acc;
      },
      { auditors: [], locations: [] }
    );
  }, [userEntities, _users]);
};
