import { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from '../../../../../../../Header';
import { SelectOption } from '../../../../../../../../atoms';
import { AuthRole } from '../../../../../../../../authorization';

interface LocationOwners {
  options: string[];
  ownerObj: Record<string, SelectOption>;
}

export const useLocationOwners = (locationId?: string): LocationOwners => {
  const entityObjData = useReactiveVar(usersEntityObj);

  return useMemo(() => {
    if (entityObjData && locationId) {
      return entityObjData.reduce<LocationOwners>(
        (acc, user) => {
          if (
            [AuthRole.LOCATION_OWNER].includes(user.authRole) &&
            ['active'].includes(user.status) &&
            user.type === 'user' &&
            user?.locations?.some((loc) => loc.eid === locationId)
          ) {
            acc.ownerObj[user.eid] = {
              label: user.name,
              value: user.eid,
              authRole: user.authRole,
              profilePic: user?.profilePic,
              locations: user?.locations,
              role: user?.role,
            };

            acc.options.push(user.eid);
          }

          return acc;
        },
        {
          options: [],
          ownerObj: {},
        }
      );
    }
    return {
      options: [],
      ownerObj: {},
    };
  }, [entityObjData, locationId]);
};
