import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useActiveTeamTab } from './useActiveTeamTab';
import { Authorization, AuthRole } from 'authorization';

interface ItemProps {
  label: string;
  routePath: string;
}
const TabItem: FC<ItemProps> = ({ label, routePath }) => {
  const history = useHistory();
  const teamTab = useActiveTeamTab();
  const isSelected = routePath?.split('/')[2] === teamTab;

  return (
    <Box
      fontSize='15px'
      fontWeight='600'
      color={isSelected ? '#33383F' : '#6F7E7C'}
      pb='12px'
      px='8px'
      borderBottom='3px solid #2A85FF'
      borderBottomColor={isSelected ? '#2A85FF' : 'transparent'}
      cursor='pointer'
      onClick={() => history.push(routePath)}
    >
      {label}
    </Box>
  );
};

interface IProps {}

const TeamTabs: FC<IProps> = () => {
  const { t } = useTranslation(['team']);
  return (
    <Flex gap='24px' borderBottom='1px solid #F5F5F5'>
      <TabItem label={t('team:members')} routePath='/teams/members' />
      <Authorization
        permittedRoles={[
          AuthRole.SUPER_ADMIN,
          AuthRole.ADMIN,
          AuthRole.LOCATION_OWNER,
        ]}
        permittedFor={['user']}
      >
        <TabItem label={t('team:jobs')} routePath='/teams/jobs' />
      </Authorization>
      {/*<TabItem label={t('team:locations')} routePath='/teams/locations' />*/}
    </Flex>
  );
};

TeamTabs.displayName = 'displayName:pages/Teams/TeamTabs';

export default TeamTabs;
