import React, { FC } from 'react';
import {
  NotificationSettingsContainer,
  NotificationSettingsHeader,
} from './components';

interface INotificationSettingsProps {}

const NotificationSettings: FC<INotificationSettingsProps> = () => {
  return (
    <>
      <NotificationSettingsHeader />
      <NotificationSettingsContainer />
    </>
  );
};
NotificationSettings.displayName = 'sc/Nc/S/NotificationsSettings.tsx';
export default NotificationSettings;
