import React, { useCallback } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';

import { BoxHeader, useConfirm } from '../../../../ui-components';
import { HeaderColors } from '../../../../configs';

interface IProps {
  header: string;
  title?: string;
  description: string;
  buttonText: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

type LauncherWarning = (props: IProps) => void;

export const useLauncherWarning = (): LauncherWarning => {
  const confirm = useConfirm();

  return useCallback(
    ({ header, title, description, buttonText, onConfirm, onCancel }) => {
      confirm({
        title: (
          <Flex justify='space-between'>
            <BoxHeader color={HeaderColors.Red.Dark} title={header} />
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        isCentered: true,
        content: (
          <Flex flexDir='column' gap='2px' pt={1} color='#6F767E'>
            {title ? (
              <Flex fontWeight='700' color='#FF6A55'>
                {title}
              </Flex>
            ) : null}

            <Flex fontWeight='500'>{description}</Flex>
          </Flex>
        ),

        contentProps: {
          minWidth: '600px',
          borderRadius: '12px',
          paddingY: '12px',
        },

        okText: buttonText,
        onOK: onConfirm,
        onCancel: onCancel,

        footerProps: {
          // flexDir: 'column-reverse',
          gap: 2,
        },
        okButtonProps: {
          width: 'full',
          size: 'lg',
          marginLeft: 0,
          colorScheme: 'red',
        },
        cancelButtonProps: { width: 'full', size: 'lg', variant: 'outline' },
      });
    },
    []
  );
};
