import { FC } from 'react';
import { Box, ButtonGroup, Flex } from '@chakra-ui/react';
import { Button } from '../../../../atoms';
import { ReactComponent as WebView } from '../../../../assets/images/audits/webView.svg';
import { ReactComponent as PdfView } from '../../../../assets/images/audits/pdfView.svg';

export type ReportViewType = 'web' | 'pdf';

export type SelectViewButtonProps = {
  setSelectedViewType: (value: ReportViewType) => void;
  selectedViewType: ReportViewType;
};

const SelectViewButton: FC<SelectViewButtonProps> = ({
  selectedViewType,
  setSelectedViewType,
}) => {
  return (
    <Flex borderRadius={'12px'} gap={2} p={'5px'} border={'2px solid #EFEFEF'}>
      <Flex gap={2} alignItems={'center'}>
        <Button
          leftIcon={<WebView />}
          size={'sm'}
          backgroundColor={
            selectedViewType === 'web' ? '#EFEFEF' : 'transparent'
          }
          color='#6F767E'
          aria-checked={selectedViewType === 'web'}
          onClick={() => setSelectedViewType('web')}
          _checked={{ color: 'blue.500' }}
        >
          Web View
        </Button>
      </Flex>
      <Flex alignItems={'center'}>
        <Box backgroundColor={'#EFEFEF'} w={'1px'} h={'20px'}></Box>
      </Flex>
      <Flex gap={2} alignItems={'center'}>
        <Button
          leftIcon={<PdfView />}
          size={'sm'}
          backgroundColor={
            selectedViewType === 'pdf' ? '#EFEFEF' : 'transparent'
          }
          color='#6F767E'
          aria-checked={selectedViewType === 'pdf'}
          onClick={() => setSelectedViewType('pdf')}
          _checked={{ color: 'blue.500' }}
        >
          Pdf View
        </Button>
      </Flex>
    </Flex>
  );
};

export default SelectViewButton;
