import moment from 'moment';
import { LocationPhaseDetails, PhaseTask } from './LauncherLocation.graphql';
import { getDependentPhase, getDependentTask } from './helper';

export function getUnlockMessage(
  task: PhaseTask,
  allTasks: PhaseTask[],
  goLiveDate: Date
): string {
  const { triggerDetails } = task;

  if (!triggerDetails) {
    return '';
  }

  const formatDuration = (milliseconds: number): string => {
    const days = milliseconds / (24 * 60 * 60 * 1000);
    if (days >= 30) {
      const months = Math.floor(days / 30);
      return `${months} ${months === 1 ? 'month' : 'months'}`;
    } else if (days >= 7) {
      const weeks = Math.floor(days / 7);
      return `${weeks} ${weeks === 1 ? 'week' : 'weeks'}`;
    } else {
      return `${Math.floor(days)} ${Math.floor(days) === 1 ? 'day' : 'days'}`;
    }
  };

  switch (triggerDetails.dependantOn) {
    case 'phase':
    case 'task': {
      const dependentItem = allTasks.find((t) =>
        triggerDetails.dependantOn === 'phase'
          ? t.phaseEid === triggerDetails.triggerItemId
          : t.eid === triggerDetails.triggerItemId
      );

      if (!dependentItem) {
        if (moment(task.startDate).isSameOrAfter(moment().utc())) {
          return `Will be unlocked on ${moment(task.startDate).format(
            'D MMM'
          )}`;
        }
        return '';
      }

      const dependentItemName =
        triggerDetails.dependantOn === 'phase'
          ? dependentItem.phaseName
          : dependentItem.title;

      if (triggerDetails.triggerChoice === 'activation') {
        if (
          triggerDetails.activationOffset === null ||
          triggerDetails.activationOffset === undefined ||
          !triggerDetails.activationOrder
        )
          return '';

        return triggerDetails.activationOffset === 0
          ? `Will be activated ${
              triggerDetails.activationOrder === 'after' ? 'after' : 'before'
            } "${dependentItemName}"`
          : triggerDetails.activationOrder === 'after'
          ? `Will be unlocked ${formatDuration(
              triggerDetails.activationOffset
            )} after "${dependentItemName}" is completed`
          : `Will be unlocked ${formatDuration(
              triggerDetails.activationOffset
            )} before "${dependentItemName}" is completed`;
      }

      if (triggerDetails.triggerChoice === 'deadline') {
        if (
          triggerDetails.deadlineOffset === null ||
          triggerDetails.deadlineOffset === undefined ||
          !triggerDetails.deadlineOrder
        )
          return '';

        return triggerDetails.deadlineOrder === 'after'
          ? `Will be unlocked ${formatDuration(
              triggerDetails.deadlineOffset
            )} after "${dependentItemName}" is completed`
          : `Will be unlocked ${formatDuration(
              triggerDetails.deadlineOffset
            )} before "${dependentItemName}" is completed`;
      }
      break;
    }

    case 'startDate': {
      if (moment().utc().isSameOrBefore(task.startDate)) {
        return `Will be unlocked after ${moment(task.startDate).format(
          'D MMM'
        )}`;
      }

      if (
        !task.startDate ||
        !(triggerDetails.activationOffset !== null) ||
        !(triggerDetails.activationOffset !== undefined) ||
        !triggerDetails.activationOrder
      ) {
        return '';
      }

      return triggerDetails.activationOrder === 'after'
        ? `Will be unlocked ${formatDuration(
            triggerDetails.activationOffset
          )} after project start date`
        : `Will be unlocked ${formatDuration(
            triggerDetails.activationOffset
          )} before project start date`;
    }

    case 'goLive': {
      if (!goLiveDate) return '';

      if (triggerDetails.triggerChoice === 'activation') {
        if (
          triggerDetails.activationOffset === null ||
          triggerDetails.activationOffset === undefined ||
          !triggerDetails.activationOrder
        )
          return '';

        return triggerDetails.activationOrder === 'after'
          ? `Will be unlocked ${formatDuration(
              triggerDetails.activationOffset
            )} after go live date`
          : `Will be unlocked ${formatDuration(
              triggerDetails.activationOffset
            )} before go live date`;
      }

      if (triggerDetails.triggerChoice === 'deadline') {
        if (
          triggerDetails.deadlineType === 'goLive' &&
          triggerDetails.dueDate
        ) {
          const dueDate = moment
            ?.utc(triggerDetails.dueDate)
            .format('MMM D, YYYY');
          return `Will be unlocked on ${dueDate}`;
        }

        if (
          triggerDetails.deadlineOffset === null ||
          triggerDetails.deadlineOffset === undefined ||
          !triggerDetails.deadlineOrder
        )
          return '';

        return triggerDetails.deadlineOrder === 'after'
          ? `Will be unlocked ${formatDuration(
              triggerDetails.deadlineOffset
            )} after go live date`
          : `Will be unlocked ${formatDuration(
              triggerDetails.deadlineOffset
            )} before go live date`;
      }
      break;
    }
  }

  if (moment().utc().isSameOrBefore(task.startDate)) {
    return `Will be unlocked after ${moment(task.startDate).format('D MMM')}`;
  }

  return '';
}

export function isTaskUnlocked(
  task: PhaseTask,
  allTasks: PhaseTask[],
  currentDate: moment.Moment,
  goLiveDate: Date,
  phaseDetails: LocationPhaseDetails[]
): boolean {
  const { triggerDetails } = task;
  if (!triggerDetails || triggerDetails.triggerChoice === 'default') {
    return true;
  }

  let dependentItem;

  if (task.steps.some((step) => step.isCompleted)) {
    return true;
  }

  if (triggerDetails.dependantOn === 'phase') {
    dependentItem = getDependentPhase(
      phaseDetails,
      triggerDetails.triggerItemId
    );
  }

  if (triggerDetails.dependantOn === 'task') {
    dependentItem = getDependentTask(
      phaseDetails,
      triggerDetails.triggerItemId
    );
  }

  if (triggerDetails.dependantOn === 'goLive') {
    if (!goLiveDate) {
      return false;
    }

    const goLiveMoment = moment(goLiveDate);

    if (triggerDetails.triggerChoice === 'activation') {
      if (
        triggerDetails.activationOffset === null ||
        triggerDetails.activationOffset === undefined ||
        !triggerDetails.activationOrder
      ) {
        return false;
      }

      const activationMoment =
        triggerDetails.activationOrder === 'after'
          ? goLiveMoment
              .clone()
              .add(triggerDetails.activationOffset, 'milliseconds')
          : goLiveMoment
              .clone()
              .subtract(triggerDetails.activationOffset, 'milliseconds');

      return currentDate.isSameOrAfter(activationMoment, 'day');
    }

    if (triggerDetails.triggerChoice === 'deadline') {
      if (triggerDetails.deadlineType === 'goLive' && triggerDetails.dueDate) {
        const dueMoment = moment(triggerDetails.dueDate);
        return currentDate.isSameOrAfter(dueMoment, 'day');
      }

      if (
        triggerDetails.deadlineOffset == null ||
        triggerDetails.deadlineOffset === undefined ||
        !triggerDetails.deadlineOrder
      ) {
        return false;
      }

      const deadlineMoment =
        triggerDetails.deadlineOrder === 'after'
          ? goLiveMoment
              .clone()
              .add(triggerDetails.deadlineOffset, 'milliseconds')
          : goLiveMoment
              .clone()
              .subtract(triggerDetails.deadlineOffset, 'milliseconds');

      return !currentDate.isSameOrAfter(deadlineMoment, 'day');
    }
  }

  if (triggerDetails.dependantOn === 'startDate') {
    if (!task.startDate) {
      return false;
    }

    const startMoment = moment?.utc(task.startDate);
    if (triggerDetails.triggerChoice === 'activation') {
      if (
        triggerDetails.activationOffset === null ||
        triggerDetails.activationOffset === undefined ||
        !triggerDetails.activationOrder
      ) {
        return false;
      }

      const activationMoment =
        triggerDetails.activationOrder === 'after'
          ? startMoment
              .clone()
              .add(triggerDetails.activationOffset, 'milliseconds')
          : startMoment
              .clone()
              .subtract(triggerDetails.activationOffset, 'milliseconds');
      return currentDate.isSameOrAfter(activationMoment, 'day');
    }

    if (
      triggerDetails.triggerChoice === 'deadline' &&
      moment(task.startDate).isSameOrAfter(moment().utc())
    ) {
      return false;
    }
  }

  if (!dependentItem) return true;
  switch (triggerDetails.dependantOn) {
    case 'phase':
    case 'task': {
      if (!dependentItem.startDate || !task.startDate) {
        return false;
      }
      if (
        !triggerDetails.startDate ||
        (triggerDetails.startDate &&
          moment()
            .utc()
            .startOf('day')
            .isSameOrAfter(moment(triggerDetails.startDate).startOf('day')))
      ) {
        return true;
      }

      const completedAtMoment = moment(dependentItem.completedAt);

      if (triggerDetails.triggerChoice === 'activation') {
        if (
          triggerDetails.activationOffset === null ||
          triggerDetails.activationOffset === undefined ||
          !triggerDetails.activationOrder
        ) {
          return true;
        }

        const activationMoment =
          triggerDetails.activationOrder === 'after'
            ? completedAtMoment
                .clone()
                .add(triggerDetails.activationOffset, 'milliseconds')
            : completedAtMoment
                .clone()
                .subtract(triggerDetails.activationOffset, 'milliseconds');

        return currentDate.isSameOrAfter(activationMoment, 'day');
      }

      if (triggerDetails.triggerChoice === 'deadline') {
        if (
          triggerDetails.deadlineOffset === null ||
          triggerDetails.deadlineOffset === undefined ||
          !triggerDetails.deadlineOrder
        ) {
          return true;
        }

        const deadlineMoment =
          triggerDetails.deadlineOrder === 'after'
            ? completedAtMoment
                .clone()
                .add(triggerDetails.deadlineOffset, 'milliseconds')
            : completedAtMoment
                .clone()
                .subtract(triggerDetails.deadlineOffset, 'milliseconds');

        return currentDate.isSameOrAfter(deadlineMoment, 'day');
      }
      break;
    }
  }

  return false;
}
