import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import moment from 'moment/moment';

import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import { AlertBox } from '../../../../../../../../atoms';
import { IFormInput } from '../../../../../../../tasks/create-task';

import { FooterComponent, TrainingItem } from '../common';
import TrainingListContainer, {
  TrainingResource,
} from './TrainingListContainer';

interface IProps {
  onAddClick?: (values: IFormInput['contents']) => void;
  values: TrainingResource[];
}

const TrainingContainer: FC<IProps> = ({ values, onAddClick }) => {
  const methods = useForm({
    defaultValues: async () => {
      await Promise.resolve();
      return {
        taskStepSelection: values.reduce<Record<string, TrainingItem>>(
          (acc, value) => {
            acc[value.eid] = value.trainingData!;
            return acc;
          },
          {}
        ),
      };
    },
  });

  const _onAddClick = () => {
    const taskStepSelection: Record<string, TrainingItem> =
      methods.getValues('taskStepSelection');

    const values = Object.values(taskStepSelection).reduce((acc, value) => {
      if (value) {
        acc.push({
          type: 'training',
          eid: value.eid,
          tempEid: value.eid,
          title: value.title,
          thumbnail: value.thumbnail,
          dateAdded: moment().format('D MMMM'),
          trainingData: value,
        });
      }
      return acc;
    }, [] as IFormInput['contents']);

    return onAddClick?.(values);
  };

  return (
    <Flex width='full' flex={1} gap='32px'>
      <FormProvider {...methods}>
        <Flex flexDir='column' overflow='hidden' flex={1} gap={4}>
          <AlertBox
            bg='#FFD88D80'
            icon={faTriangleExclamation}
            iconProps={{ color: '#F5A100' }}
          >
            Trainings you have selected do not have{' '}
            <strong>in-development locations</strong> assigned to them. So, when
            member starts a training path, they will be automatically assigned
            to the training path.
          </AlertBox>

          <TrainingListContainer values={values} />

          <FooterComponent onAddClick={_onAddClick} />
        </Flex>
      </FormProvider>
    </Flex>
  );
};

export default TrainingContainer;
