import LauncherPublishAlert from './LauncherPublishAlert';
import AuditScheduleAlert from './AuditScheduleAlert';
import UnsavedConfigAlert from './UnsavedConfigAlert';
export { AsyncAlertEvent, PromptAction } from './async-alert-event';

const _RouteAlerts = {
  LauncherPublishAlert: LauncherPublishAlert,
  AuditScheduleAlert: AuditScheduleAlert,
  UnsavedConfigAlert: UnsavedConfigAlert,
} as const;

export type AlertType = keyof typeof _RouteAlerts;

export const RouteAlerts = new Map(Object.entries(_RouteAlerts));
