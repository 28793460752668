import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IFormInput } from '../AddLocation/add-location.types';
import { UploadDocumentInput } from './UploadDocument';

const LicenseDoc: FC = () => {
  const { control } = useFormContext<IFormInput>();
  return (
    <Flex bg='white' borderRadius='8px' p={8} gap='20px' flexDir='column'>
      <Flex>
        <Box mr={4} w={4} minW={4} borderRadius={4} bg='#CABDFF' />
        <Box>
          <Box fontWeight='600' fontSize='14px' lineHeight='24px'>
            Document details
          </Box>
          <Box
            fontWeight='400'
            fontSize='11px'
            lineHeight='18px'
            color='#6F767E'
          >
            Upload the License agreement here
          </Box>
        </Box>
      </Flex>
      <Controller
        control={control}
        name='documentData'
        // rules={{
        //   required: {
        //     value: true,
        //     message: `Licence document is required`,
        //   },
        // }}
        render={({ field, fieldState }) => {
          return (
            <FormControl
              // isDisabled={isDisabled}
              mt={2}
              isInvalid={!!fieldState.error}
              w='450px'
            >
              <UploadDocumentInput
                // isDisabled={context.isDisabled}
                {...field}
              />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </Flex>
  );
};

export default LicenseDoc;
