import React, { forwardRef, useImperativeHandle } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Switch,
} from '@chakra-ui/react';
import { cloneDeep } from '@apollo/client/utilities';
import {
  Controller,
  useFieldArray,
  useForm,
  useFormState,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormInput, PrimaryButton } from '../../../../../atoms';
import { TASK_DEPENDENCY } from '../../../../../types';
import { ActionButton } from '../../../../../ui-components';

import TitleHeader from '../../../../../sub-components/CardEditor/TitleHeader';

import AddStepForm from './AddStepForm';
import StepItem from './StepItem';
import TaskDependSelect from './TaskDependSelect';
import { ITaskItem } from '../editor.types';

import { LauncherAssignee, LauncherTaskStep } from '../../common';
import { getAssignedType, getAssignedUser } from '../../common/helpers';

import EmptyComponent from './EmptyComponent';
import TaskMoreAction from './TaskMoreAction';
import {
  ResourceAction,
  useChapterResource,
  useFormResource,
  useTrainingResource,
} from './resource';
import TaskAssignee from './TaskAssignee';

const UNDEFINED = undefined as unknown as string;

export interface ITaskInput extends LauncherAssignee {
  eid?: string;
  title: string;
  description?: string;
  duration: number;
  dependency: TASK_DEPENDENCY;
  docRequired: boolean;
  selected?: boolean;

  steps: LauncherTaskStep[];
}

// Do not export this interface
interface IFormInput extends ITaskInput {
  assignee: LauncherAssignee;
}

const mapId = (data: ITaskInput['steps'][number]) => {
  return data.stepId;
};

const transformDefaultValue = (
  initialValue: ITaskItem | undefined
): IFormInput | undefined => {
  if (initialValue) {
    return {
      ...initialValue,
      assignee: {
        assignedType: initialValue.assignedType,
        assignedUser: initialValue.assignedUser,
      },
    };
  }

  return initialValue;
};

export interface BaseTaskFormRef {
  validate: () => Promise<boolean>;
  focusForm: () => void;
}

interface IProps {
  isFirstTask?: boolean;
  isEdit?: boolean;
  initialValue?: ITaskItem;
  layout?: 'vertical';
  onSubmit: (values: ITaskInput) => void | Promise<void>;
  onCollapse?: (values: ITaskInput, valueChanged?: boolean) => void;
  actionHandler?: (name: string, value: string) => void;
  phaseComponent?: React.ReactNode;
  closeForm?: Function;
}

const BaseTaskForm = forwardRef<BaseTaskFormRef, IProps>(
  (
    {
      isEdit,
      isFirstTask,
      initialValue,
      layout,
      onCollapse,
      onSubmit,
      actionHandler,
      phaseComponent,
      closeForm,
    },
    ref
  ) => {
    const { t } = useTranslation(['common', 'task']);
    const openFormModal = useFormResource();
    const openChapterModal = useChapterResource();
    const openTrainingModal = useTrainingResource();

    const { control, handleSubmit, getValues, trigger, setFocus } =
      useForm<IFormInput>({
        defaultValues: transformDefaultValue(initialValue),
      });

    const isDirty = useFormState({
      control,
    }).isDirty;

    useImperativeHandle(
      ref,
      () => ({
        validate: async () => {
          const status = await trigger(undefined, {
            shouldFocus: true,
          });

          if (status) {
            setFocus('title');
          }

          return status;
        },
        focusForm: () => setFocus('title'),
      }),
      [trigger, setFocus]
    );

    const { fields, append, remove, update } = useFieldArray<
      IFormInput,
      'steps'
    >({
      control: control,
      name: 'steps',
      // rules: {
      //   required: t('task:validation.taskStepRequired'),
      // },
    });

    const contentError = useFormState<IFormInput>({
      control: control,
      name: 'steps',
      exact: true,
    }).errors?.steps;

    const onFormAddClick = () => {
      openFormModal({
        values: getValues('steps').map(mapId),
        onAddClick: (values) => {
          append(
            values.map((value) => ({
              type: 'form',
              formId: value.eid,
              title: value.title,
              stepId: value.eid,
            }))
          );
        },
      });
    };

    const onChapterAddClick = () => {
      openChapterModal({
        values: getValues('steps').map(mapId),
        onAddClick: (values) => {
          append(
            values.map((value) => ({
              type: 'sop',
              sopId: value.eid,
              title: value.title,
              stepId: value.eid,
            }))
          );
        },
      });
    };

    const onTrainingAddClick = () => {
      openTrainingModal({
        values: getValues('steps').map(mapId),
        onAddClick: (values) => {
          append(
            values.map((value) => ({
              type: 'training',
              trainingId: value.eid,
              title: value.title,
              stepId: value.eid,
            }))
          );
        },
      });
    };

    const resourceHandler = (action: ResourceAction) => {
      switch (action) {
        case ResourceAction.ADD_FORM:
          return onFormAddClick();
        case ResourceAction.ADD_CHAPTER:
          return onChapterAddClick();
        case ResourceAction.ADD_TRAINING:
          return onTrainingAddClick();
      }
    };

    const onUpdateClick = (value: LauncherTaskStep, index: number) => {
      update(index, value);
    };

    const onRemoveClick = (_: unknown, index: number) => {
      remove(index);
    };

    const isVertical = layout === 'vertical';

    const flex2 = isVertical ? undefined : 2;
    const flex3 = isVertical ? undefined : 3;
    const flex5 = isVertical ? undefined : 5;

    const updateButtonWidth = isVertical ? 'full' : 'auto';

    const flexDir = isVertical ? 'column' : 'row';

    const _submitProxy = async (values: IFormInput) => {
      const { assignee, ..._values } = cloneDeep(values);

      _values.assignedType = getAssignedType(assignee);
      _values.assignedUser = getAssignedUser(assignee);

      await onSubmit?.(_values);
      closeForm?.();
    };

    const TaskPhase = phaseComponent || EmptyComponent;

    return (
      <React.Fragment>
        <Flex flexDir={flexDir} gap={3}>
          <Box flex={flex2}>
            <TitleHeader
              title={'Task title'}
              desc={'Explain what needs to be done'}
              isRequired
            />
          </Box>
          <Box flex={flex5}>
            <Controller
              control={control}
              name='title'
              defaultValue=''
              rules={{
                required: t('task:validation.titleRequired'),
                maxLength: {
                  value: 256,
                  message: 'Task title is too long (maximum is 256 character)',
                },
                validate: (value) => {
                  if (value?.trim()?.length === 0) {
                    return t('task:validation.titleEnter');
                  }
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState?.error}>
                    <FormInput
                      id='title'
                      size='lg'
                      variant='outline'
                      // placeholder={t('task:placeholder.title')}
                      {...field}
                    />
                    <FormErrorMessage>
                      <span>{fieldState?.error?.message}</span>
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />

            <Controller
              control={control}
              name='description'
              defaultValue=''
              rules={{
                maxLength: {
                  value: 512,
                  message:
                    'Task description is too long (maximum is 512 character)',
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl mt={3} isInvalid={!!fieldState?.error}>
                    <FormInput
                      id='description'
                      size='lg'
                      // placeholder={t('task:placeholder.description')}
                      {...field}
                    />
                    <FormErrorMessage>
                      <span>{fieldState?.error?.message}</span>
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Box>
        </Flex>

        <Flex flexDir={flexDir} gap={3}>
          <Box flex={flex2}>
            <TitleHeader title={'Steps'} desc={'Add steps to your tasks'} />
          </Box>
          <Box flex={flex5}>
            <AddStepForm
              onAddClick={(newValue) => {
                append({
                  type: 'text',
                  stepId: UNDEFINED,
                  title: newValue,
                });
              }}
              resourceHandler={resourceHandler}
            />

            <FormControl isInvalid={!!contentError}>
              <Flex flexDir='column' gap='6px' pt={fields?.length ? 2 : 0}>
                {fields?.map((value, index) => (
                  <StepItem
                    key={value.id}
                    index={index}
                    data={value}
                    onDelete={onRemoveClick}
                    onUpdate={onUpdateClick}
                  />
                ))}
              </Flex>

              <FormErrorMessage>
                {contentError?.root?.message || contentError?.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </Flex>

        <Flex flexDir={flexDir} gap={3}>
          <Box flex={flex2}>
            <TitleHeader
              title='Add assignee'
              desc='Who is responsible for this task?'
            />
          </Box>
          <Box flex={flex5}>
            <Controller
              control={control}
              name='assignee'
              rules={{
                required: t('task:validation.thisFieldRequired'),
              }}
              defaultValue={{
                assignedType: 'location',
                assignedUser: [],
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState.error}>
                    <TaskAssignee
                      assignee={field.value}
                      onChange={field.onChange}
                    />

                    <FormErrorMessage>
                      {fieldState?.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Box>
        </Flex>

        {/*// @ts-ignore */}
        <TaskPhase control={control} />

        <Flex flexDir={flexDir} gap={3}>
          <Box flex={flex2}>
            <TitleHeader
              title={'Time duration'}
              desc={'No of days to finish this task'}
              isRequired
            />
          </Box>
          <Box flex={flex5}>
            <Controller
              control={control}
              name='duration'
              rules={{
                required: t('task:validation.thisFieldRequired'),
                pattern: {
                  value: /^[0-9]+$/,
                  message: t('task:validation.onlyNumberAccepted'),
                },
                min: {
                  value: 1,
                  // @ts-ignore
                  message: t('task:validation.minValues', {
                    value: 1,
                  }),
                },
              }}
              defaultValue={'' as never}
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState.error}>
                    <FormInput
                      // min={minValue}
                      id='time-duration'
                      size='lg'
                      variant='outline'
                      // placeholder={placeholder || t('task:enterNumberGreater')}
                      {...field}
                      rightIcon={
                        <Box fontSize='14px' color='#BFBFBF' pr={2}>
                          days
                        </Box>
                      }
                    />

                    <FormErrorMessage>
                      {fieldState?.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Box>
        </Flex>

        <Flex flexDir={flexDir} gap={3}>
          <Box flex={flex2}>
            <TitleHeader
              title={'Triggers after'}
              desc={'If a task should trigger after previous'}
            />
          </Box>

          <Flex align='center' flex={flex5}>
            <Controller
              control={control}
              name='dependency'
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState.error}>
                    <TaskDependSelect
                      isFirstTask={isFirstTask}
                      value={field.value}
                      onChange={field.onChange}
                    />
                    <FormErrorMessage>
                      {fieldState?.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Flex>
        </Flex>

        <Flex flexDir={flexDir} gap={3}>
          <Box flex={flex2}>
            <TitleHeader
              title={'Document mandatory'}
              desc={'Task can’t be completed without it'}
            />
          </Box>
          <Flex align='center' flex={flex5}>
            <Controller
              control={control}
              name='docRequired'
              defaultValue={false}
              render={({ field }) => {
                return (
                  <FormControl>
                    <Switch isChecked={field.value} onChange={field.onChange} />
                  </FormControl>
                );
              }}
            />
          </Flex>
        </Flex>

        <Flex flexDir={flexDir} gap={3}>
          <Flex flex={flex2} align='center'>
            {actionHandler && (
              <TaskMoreAction isEdit={isEdit} clickedItem={actionHandler}>
                <Box fontSize='15px' color='#6F767E' py={2} cursor='pointer'>
                  More options
                </Box>
              </TaskMoreAction>
            )}
          </Flex>
          <Flex flexDir={flexDir} align='center' flex={flex5} gap={3}>
            {onCollapse ? (
              <PrimaryButton
                size='lg'
                width='auto'
                title='Collapse'
                variant='outline'
                style={{
                  flex: flex3,
                }}
                disabled={!isEdit}
                onClick={
                  onCollapse
                    ? handleSubmit((it) => onCollapse(it, isDirty))
                    : undefined
                }
              />
            ) : (
              <Box flex={flex3} />
            )}

            <ActionButton
              size='lg'
              colorScheme='blue'
              width={updateButtonWidth}
              flex={flex5}
              fontSize='15px'
              isDisabled={!isDirty}
              actionFn={handleSubmit(_submitProxy)}
            >
              {t(isEdit ? 'common:update' : 'common:add')}
            </ActionButton>
          </Flex>
        </Flex>
      </React.Fragment>
    );
  }
);

BaseTaskForm.displayName = 'BaseTaskForm';

export default BaseTaskForm;
