import { gql } from '@apollo/client';
import { TeamItem } from './team.types';
import { AuthRole, AuthRoles } from '../../../authorization';

export interface MemberListResponse {
  teams: {
    items: TeamItem[];

    hasMore: boolean;
    count: number;
    pageCount: number;
    currentPage: number;
  };
}

export type MemberSort =
  | '_ID_ASC'
  | '_ID_DESC'
  | 'EMAIL_ASC'
  | 'EMAIL_DESC'
  | 'STATUS_ASC'
  | 'STATUS_DESC'
  | 'ROLE_ASC'
  | 'ROLE_DESC'
  | 'NAME_ASC'
  | 'NAME_DESC'
  | 'createdAt_asc'
  | 'createdAt_desc';

export interface MemberListVariable {
  page: number;
  type: 'all' | 'user' | 'branch';
  query?: string;
  sort?: MemberSort;
  authRoles?: AuthRole[];
}

export const MEMBER_LIST_QUERY = gql`
  query Teams(
    $page: Int!
    $type: teamsType!
    $query: String
    $sort: teamsSort
    $locations: [String!]
    $authRoles: [String!]
  ) {
    teams(
      page: $page
      type: $type
      query: $query
      sort: $sort
      locations: $locations
      authRoles: $authRoles
    ) {
      items {
        name
        eid
        type
        role
        authRole
        email
        phone
        createdAt
        status
        inviteDetails
        isInvited
        profilePic
        lastActivity {
          activityTime
        }
        locations {
          name
          eid
          profilePic
        }
      }
      hasMore
      count
      pageCount
      currentPage
    }
  }
`;

export type EntityUserSort =
  | '_ID_ASC'
  | '_ID_DESC'
  | 'CREATEDAT_ASC'
  | 'CREATEDAT_DESC'
  | 'EMAIL_ASC'
  | 'EMAIL_DESC'
  | 'STATUS_ASC'
  | 'STATUS_DESC'
  | 'ROLE_ASC'
  | 'ROLE_DESC'
  | 'NAME_ASC'
  | 'NAME_DESC'
  | 'LOCATIONTYPE_ASC'
  | 'LOCATIONTYPE_DESC'
  | 'LASTACTIVITY__ACTIVITYTIME_ASC'
  | 'LASTACTIVITY__ACTIVITYTIME_DESC';

export interface EntityUsersVariable {
  page: number;
  perPage: number;
  sort?: EntityUserSort;
  filter?: {
    query?: string;
    authRole?: AuthRoles[];
    statuses?: string[];
    locationId?: string[];
  };
}

export interface EntityUsersResponse {
  EntityUserPagination: {
    items: TeamItem[];
    hasMore: boolean;
    count: number;
    pageCount: number;
    currentPage: number;
  };
}

export const ENTITY_USERS_LIST = gql`
  query ENTITY_USERS_LIST(
    $page: Int
    $perPage: Int
    $sort: SortFindManyUserInput
    $filter: FilterFindManyUserInput
  ) {
    EntityUserPagination(
      page: $page
      perPage: $perPage
      sort: $sort
      filter: $filter
    ) {
      count
      items {
        name
        eid
        type
        role
        authRole
        email
        phone
        createdAt
        status
        inviteDetails {
          invitedBy
          invitedAt
          joinedAt
          status
          authCode
          comment
          inviteNode
        }
        isInvited
        profilePic
        lastActivity {
          activityTime
        }
        locations {
          name
          eid
          profilePic
        }
      }
    }
  }
`;
