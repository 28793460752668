import { default as Button } from './Button';
import { default as Checkbox } from './Checkbox';
import { default as Input } from './Input';
import { default as Tooltip } from './Tooltip';
import { default as Textarea } from './textarea';

export default {
  Button,
  Input,
  Tooltip,
  Checkbox,
  Textarea,
};
