import React, { FC, ReactNode } from 'react';
import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { Wrapper } from './wrapper.styles';
import PickerButton from 'sub-components/tasks/create-task/Scheduler/CustomDatePicker/PickerButton';
import CustomHeader from 'sub-components/tasks/create-task/Scheduler/CustomDatePicker/CustomHeader';

interface IProps {
  readOnly?: boolean;
  selectedValue: Date | null;
  onChange: (date: Date | null) => void;
  onSelect?: (date: Date | null) => void;
  onCalendarOpen?: () => void;
  onCalendarClose?: () => void;
  children?: any;
  isSelected?: boolean;
  transKey?: 'task:customDateValue';
  placeholderText: string;
  dateFormat?: string;
  minDate?: Date | null;
  maxDate?: Date | null;
  customInput?: React.ReactElement;
  disabled?: boolean;
  renderCustomHeader?: (
    params: ReactDatePickerCustomHeaderProps
  ) => React.ReactNode;
  hideDays?: boolean;
  shouldCloseOnSelect?: boolean;
  open?: boolean;
}

const SimpleDateFilter: FC<IProps> = ({
  readOnly,
  selectedValue,
  onChange,
  onSelect,
  onCalendarOpen,
  onCalendarClose,
  children,
  isSelected,
  transKey,
  placeholderText,
  dateFormat,
  minDate,
  maxDate,
  customInput,
  disabled,
  renderCustomHeader = CustomHeader,
  hideDays,
  shouldCloseOnSelect = false,
}) => {
  const effectiveCustomInput = customInput ?? (
    <PickerButton
      isSelected={isSelected && !!selectedValue}
      transKey={transKey}
    />
  );

  return (
    <Wrapper hideDays={hideDays}>
      <DatePicker
        disabled={disabled}
        readOnly={readOnly}
        selected={selectedValue}
        onCalendarOpen={onCalendarOpen}
        onCalendarClose={onCalendarClose}
        onChange={onChange}
        onSelect={onSelect}
        customInput={effectiveCustomInput}
        renderCustomHeader={renderCustomHeader}
        formatWeekDay={(day) => day.substring(0, 3)}
        calendarStartDay={1}
        placeholderText={placeholderText}
        dateFormat={dateFormat || 'd MMMM yyyy'}
        minDate={minDate}
        maxDate={maxDate}
        shouldCloseOnSelect={shouldCloseOnSelect}
      >
        {children}
      </DatePicker>
    </Wrapper>
  );
};

export default SimpleDateFilter;
