import React, { FC } from 'react';
import BaseTriggerIntro, { IntroItem } from '../trigger/BaseTriggerIntro';

interface IProps {
  phaseName: string;
}

const PhaseTriggerIntro: FC<IProps> = ({ phaseName = '' }) => {
  return (
    <BaseTriggerIntro
      title={`Edit ${phaseName.trim()} Phase`}
      infoText='This phase will be unlocked once a location is added, allowing assignees to complete tasks at any time before the go-live date, in any order.'
    >
      <IntroItem>Set when the phase is visible to the assignee</IntroItem>
      <IntroItem>Set by when the assignee needs to complete it</IntroItem>
      <IntroItem>
        Set phase dependencies <br />
        Ex: “Unlock this phase after phase 3 is completed”
      </IntroItem>
      <IntroItem>
        Add Phase durations <br />
        i.e “complete this phase within 15 days of activation”
      </IntroItem>
    </BaseTriggerIntro>
  );
};

PhaseTriggerIntro.displayName = 'sc/L/lc/c/pd/PhaseTriggerIntro';

export default PhaseTriggerIntro;
