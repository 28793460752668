import { Box, Flex, Image, useAccordionItemState } from '@chakra-ui/react';
import { Button } from 'atoms';
import { useProjectDetailPhaseContext } from 'pages/LocationsNew/Components/ProjectDetailPhase/store/context';
import Dark_editIcon from 'assets/images/dark_edit.png';
import React, { FC, useMemo } from 'react';
import { useAddAssignee } from 'sub-components/Launcher/launcher-config/common/add-assignee';
import { AddIcon, ChevronRightIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { AssignmentType } from '../../..';
import { LauncherAssignee } from 'pages/launcher/config-editor/common';
import { cloneDeep } from '@apollo/client/utilities';
import {
  useRemovePhaseAssignee,
  useAddPhaseAssigneeInfo,
  useAddPhaseToLocationOwner,
  useAddTaskAssigneeInfo,
  useAddTaskToAssignee,
  usePhaseNotRequiredInfo,
  useDeselectAllPhasesInfo,
  useInsufficientTimeInfo,
  useAddPhaseToAssignee,
} from '../../../modals';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';

interface IProps {
  index: number;
  eid: string;
}

const RightSection: FC<IProps> = ({ eid, index }) => {
  const { isOpen } = useAccordionItemState();
  const { mode, phaseData, updatePhaseData } = useProjectDetailPhaseContext(
    (state) => ({
      mode: state?.view,
      phaseData: state?.launcherData,
      updatePhaseData: state?.updateLauncherData,
    })
  );
  const addAssignee = useAddAssignee();
  const removePhaseAssignee = useRemovePhaseAssignee();
  const addPhaseAssigneeInfo = useAddPhaseAssigneeInfo();
  const addPhaseToLocationOwner = useAddPhaseToLocationOwner();
  const adTaskAssigneeInfo = useAddTaskAssigneeInfo();
  const addTaskToAssignee = useAddTaskToAssignee();
  const phaseNotRequiredInfo = usePhaseNotRequiredInfo();
  const deselectAllPhasesInfo = useDeselectAllPhasesInfo();
  const insufficientTimeInfo = useInsufficientTimeInfo();
  const addPhaseToAssignee = useAddPhaseToAssignee();
  const entityData = useReactiveVar(usersEntityObj);
  const data = useMemo(() => {
    return phaseData?.contents?.filter((content) => content?.eid === eid)?.[0]!;
  }, [eid, phaseData]);

  const initialWarning = (value: LauncherAssignee) => {
    if (!data?.assignedType) {
      addPhaseAssigneeInfo({
        onOkPress: () => actionModal(value),
      });
    }
  };

  const actionModal = (value: LauncherAssignee) => {
    addAssignee({
      title: `Add ${data?.category} Assignee`,
      description:
        'Phase assignee will be conducting all the tasks within the mentioned phase',
      value: {
        assignedType: data?.assignedType,
        assignedUser: data?.assignedUser,
      },
      onChange: (_value) => changeWarning(_value),
    });
  };

  const changeWarning = (value: LauncherAssignee) => {
    if (value?.assignedType === 'location') {
      addPhaseToLocationOwner({
        onOkPress: () => {
          let _phaseData = cloneDeep(phaseData);
          _phaseData?.contents?.forEach((content) => {
            if (content?.eid === eid) {
              content.assignedType = 'location';
              content.assignedUser = [];
              content.tasks?.forEach((task) => {
                task.assignedType = 'location';
                task.assignedUser = [];
              });
            }
          });
          updatePhaseData(_phaseData);
        },
      });
    } else if (value?.assignedType === 'user') {
      let _phaseData = cloneDeep(phaseData);
      _phaseData?.contents?.forEach((content) => {
        if (content?.eid === eid) {
          content.assignedType = 'user';
          content.assignedUser = value.assignedUser || [];
          content.tasks?.forEach((task) => {
            task.assignedType = 'user';
            task.assignedUser = value?.assignedUser || [];
          });
        }
      });
      updatePhaseData(_phaseData);
    } else {
      removePhaseAssignee({
        onOkPress: () => {
          let _phaseData = cloneDeep(phaseData);
          _phaseData?.contents?.forEach((content) => {
            if (content?.eid === eid) {
              content.assignedType = undefined;
              content.assignedUser = [];
              content.tasks?.forEach((task) => {
                task.assignedType = 'location';
                task.assignedUser = [];
              });
            }
          });
          updatePhaseData(_phaseData);
        },
      });
    }
  };

  const onAssigneeChange = (value: LauncherAssignee) => {
    let _phaseData = cloneDeep(phaseData);
    _phaseData?.contents?.forEach((content) => {
      if (content?.eid === eid) {
        content.assignedType = value.assignedType || 'location';
        content.assignedUser = value.assignedUser || [];
      }
    });
    updatePhaseData(_phaseData);
  };

  const render = () => {
    if (mode === 'view') {
      return (
        <AssignmentType
          assignedType={data?.assignedType}
          assignedIds={data?.assignedUser}
        />
      );
    } else if (mode === 'edit') {
      if (data?.assignedType) {
        return (
          <Flex gap={2} align='center'>
            {isOpen && (
              <Box color='rgba(51, 56, 63, 1)' fontSize='14px' fontWeight={400}>
                Phase assigned to:
              </Box>
            )}
            <AssignmentType
              assignedType={data?.assignedType}
              assignedIds={data?.assignedUser}
            />
            <Button
              size='sm'
              variant='outline'
              border='none'
              outline={'2px solid #B1E5FC'}
              rightIcon={<Image src={Dark_editIcon} boxSize='20px' />}
              onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();
                // addAssignee({
                //   title: `Add ${data?.category} Assignee`,
                //   description:
                //     'Phase assignee will be conducting all the tasks within the mentioned phase',
                //   value: {
                //     assignedType: data?.assignedType,
                //     assignedUser: data?.assignedUser,
                //   },
                //   onChange: changeWarning,
                // });
                addPhaseAssigneeInfo({
                  onOkPress: () => actionModal(data),
                });
              }}
            >
              Edit
            </Button>
          </Flex>
        );
      } else {
        return (
          <Button
            size='sm'
            variant='outline'
            border='none'
            outline={'2px solid #B1E5FC'}
            leftIcon={<AddIcon />}
            onClick={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
              initialWarning(data);
            }}
          >
            Assign phase assignee
          </Button>
        );
      }
    }
  };

  return (
    <Flex align='center' gap={2} minW='fit-content'>
      {render()}
      {isOpen ? (
        <ChevronUpIcon fontSize='30px' color='#6F767E' />
      ) : (
        <ChevronRightIcon fontSize='30px' color='#6F767E' />
      )}
    </Flex>
  );
};

RightSection.displayName =
  'displayName:pages/LocationsNew/Components/ProjectDetailPhase/components/PhaseAccordionButtion/phase-button-upper/components/RightSection';

export default RightSection;
