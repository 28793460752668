import { Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSquareCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

type AnswerType = {
  title: string;
  status: boolean;
};
interface IProps {
  answer?: AnswerType[];
}

const CheckboxQuestions: FC<IProps> = ({ answer }) => {
  return (
    <Flex direction={'column'} justifyContent={'center'} gap={2}>
      {answer?.map((item: AnswerType, index: number) => {
        return (
          <Flex
            marginLeft={'10px'}
            alignItems={'center'}
            padding={'2px 8px'}
            minHeight={'28px'}
            borderRadius={'6px'}
            key={index}
            gap={2}
            alignSelf={'flex-end'}
          >
            <Flex gap={2}>
              <FontAwesomeIcon
                fontSize={'20px'}
                color='#2A85FF'
                icon={faSquareCheck as IconProp}
              />
              <Text
                lineHeight={'24px !important'}
                fontSize={15}
                fontWeight={500}
              >
                {item?.title}
              </Text>
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default CheckboxQuestions;
