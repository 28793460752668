import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';

import PhaseTriggerIntro from './PhaseTriggerIntro';
import PhaseSettings, { PhaseDetails } from './PhaseSettings';

interface IProps extends PhaseDetails {
  onSaveChanges: (data: PhaseDetails) => void;
  triggerAssignment?: 'phase' | 'task';
}

const PhaseSettingContainer: FC<IProps> = ({
  eid,
  category,
  trigger,
  assignedType,
  assignedUser,
  tasks,
  triggerAssignment,
  onSaveChanges,
}) => {
  return (
    <Flex>
      <PhaseTriggerIntro phaseName={category} />

      <PhaseSettings
        tasks={tasks}
        triggerAssignment={triggerAssignment}
        defaultValue={{
          eid,
          category,
          trigger,
          assignedType,
          assignedUser,
        }}
        onSaveChanges={onSaveChanges}
      />
    </Flex>
  );
};

PhaseSettingContainer.displayName = 'sc/L/lc/c/pd/PhaseSettingContainer';

export default PhaseSettingContainer;
