import { gql } from '@apollo/client';

export const LOCATION_LAUNCH_LIST = gql`
  query LocationLaunchList {
    BusinessLaunchers {
      count
      items {
        eid
      }
    }
    LocationLaunchList {
      liveDate
      startDate
      launcherId
      launchId
      phases
      tasks {
        triggerDetails {
          triggerChoice
          activationOffset
          deadlineOffset
          triggerItemId
          activationOrder
          deadlineOrder
          dependantOn
          deadlineType
          startDate
          dueDate
        }
        isRequired
        isPhaseSelected
        startDate
        title
        dueDate
        isCompleted
        taskCategoryEid
        phase
        phaseEid
        completedAt
        steps {
          title
          completedBy
          isCompleted
          completedAt
        }
      }
      isLive
      locationId
      createdAt
      locationDetails
      location {
        name
        username
      }
    }
  }
`;

export const TASK_TYPES = gql`
  query EntityLauncherTaskCategories {
    EntityLauncherTaskCategories {
      name
      entityId
      eid
    }
  }
`;

export interface Step {
  title: string;
  completedBy?: string;
  isCompleted: boolean;
  completedAt?: string;
}

export interface Task {
  startDate: string;
  title: string;
  dueDate: string;
  isCompleted: boolean;
  phase: string;
  phaseEid: string;
  steps: Step[];
}

export interface Location {
  liveDate: string;
  startDate: string;
  launcherId: string;
  tasks: Task[];
  locationDetails: string;
  isLive: boolean;
}

export interface LocationLaunchListResponse {
  LocationLaunchList: Location[];
  BusinessLaunchers: {
    count: number;
  };
}

export interface Card {
  id: number;
  title: string;
  description: string;
  badge?: string;
  startDate: string;
  tasks: { id: number; completed: boolean }[];
}
export interface LocationFilterProps {
  locMakeLiveHandler: Function;
  cardsData: Card[];
}

export interface TableData {
  name: string; // Location name
  rate: number; // Completion percentage
  task: string; // Current task description
  startDate: string; // Start date of the task or project
  goLiveDate: string; // Go-live date of the task or project
  status: string; // Status of the task or project
  phase?: string; // Phase (optional, based on tab selection)
  taskType?: string; // Task type (optional, based on tab selection)
  locationId: string; // Unique identifier for the location
  taskCategoryEid: string;
  launchId: string;
  createdAt: string;
}

export interface TaskType {
  eid: string;
  name: string;
  entityId: string;
  __typename: string;
}

export interface TaskTypeArray {
  EntityLauncherTaskCategories: TaskType[];
}
export interface StatusResult {
  status: 'lastPhase' | 'readytogolive' | 'lagging' | 'ontrack';
  remainingTasks: number;
  isLagging: boolean;
  lagDays?: number;
}

export interface CardData {
  id: string;
  title: string;
  description: string;
  status: string;
  badge: string;
  date: string | null;
  phaseName: string;
  days: number;
  liveDate: string | null;
}

export interface LocationData {
  locationId: string;
  locationName: string;
  locationCity: string;
  locationState: string;
  isLive: boolean;
  liveDate: string | null;
  tasks: {
    completedAt: string | null;
    phase: string;
    dueDate: string;
  }[];
  phases: string[];
  startDate: string;
}
