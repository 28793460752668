import { cloneDeep } from '@apollo/client/utilities';
import moment from 'moment-timezone';
import {
  ILastSessionAssigneesEntity,
  ISupervisedTasksEntity,
} from 'pages/TasksDashboard/tasks.types';
import { defaultCSTTimezone } from 'sub-components/tasks/create-task/validation/helper';

export function getTaskStatusUpdated(
  item: ISupervisedTasksEntity,
  userTimezone: string,
  userData: any[]
) {
  /**
   * Convert now to UTC
   */
  const now = moment.utc(
    moment().tz(userTimezone).format('DD-MMM-YYYY hh:mm a'),
    'DD-MMM-YYYY hh:mm a'
  );
  let lastSession = item?.lastActiveSession;
  const taskDueDate = moment.utc(lastSession?.dueDate);
  // const taskStartDate = moment.utc(item?.startDate);
  /**
   * Convert task start date to user's timezone
   */
  let _taskStartDate = moment(
    moment.utc(item?.startDate),
    'DD-MMM-YYYY hh:mm a'
  )?.tz(userTimezone);
  let taskStartDate = moment.utc(
    _taskStartDate?.format('DD-MMM-YYYY hh:mm a'),
    'DD-MMM-YYYY hh:mm a'
  );
  const maxTaskDueDate = moment.utc(lastSession?.maxDueDate);

  const completedTasks = lastSession?.assignees?.every(
    (_progress: any) => _progress?.completedPercent === 100
  );

  let _combinedUsersList = [...(lastSession?.assignees || [])];
  // let _combinedUsersList = [
  //   ...(lastSession?.assigneeUsers || []),
  //   ...(lastSession?.assigneeUsersLocations || []),
  // ];

  let _lastSessionAssignees = cloneDeep(lastSession?.assignees);
  let _assigneesList = (_lastSessionAssignees || []).map(
    (assignee: ILastSessionAssigneesEntity) => {
      _combinedUsersList.forEach((combine) => {
        if (combine?.userId === assignee?.userId) {
          assignee.timezone = combine.timezone;
        }
      });
      return assignee;
    }
  );
  _assigneesList?.forEach((_assignee) => {
    if (!_assignee?.timezone) {
      _assignee.timezone = defaultCSTTimezone;
    }
  });

  // console.log({
  //   title: item.title,
  //   now: now.format('LLL'),
  //   nowUTC: moment.utc(now),
  //   nowUTCReadable: moment.utc(now).format('LLL'),
  //   taskDueDate: taskDueDate.format('LLL'),
  //   taskStartDate: taskStartDate.format('LLL'),
  //   taskStartDateBackend: item?.startDate,
  //   taskStartDateBackendReadable: moment.utc(item?.startDate)?.format('LLL'),
  //   nowBeforeTaskDueDate: now.isBefore(taskDueDate),
  // });

  /**
   * SCHEDULED TASK : Task will be in 'Scheduled' section if from backend the 'lastSession' object is falsy meaning session has not been created at so not published yet.
   */
  if (!lastSession || now.isBefore(taskStartDate)) {
    return 'scheduled';
  }

  /*
   * - If task is terminated and status is 'TASK_TERMINATED'
   */
  if (item?.status === 'TASK_TERMINATED') {
    return 'terminated';
  }

  if (!lastSession.isRepeating) {
    /** One time task */
    /**
     * OVERDUE TASK :
     * ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
     * Overdue task will always be for one time task as repeating tasks can never be in overdue section due to their repeat cycles and will automatically move to 'Pending' section. After the max
     * due date is over for repeating task, it will be in completed section.
     *
     * Atleast 1 assigness has not completed the task and due date has passed in every assignee's timezone.
     */
    let taskDueDatePassedForAll = false;
    _assigneesList?.forEach((_assignee) => {
      let timezoneBasedNow = moment.utc(
        moment().tz(_assignee?.timezone!).format('DD-MMM-YYYY hh:mm a'),
        'DD-MMM-YYYY hh:mm a'
      );
      if (timezoneBasedNow.isAfter(taskDueDate)) {
        taskDueDatePassedForAll = true;
      }
    });
    if (taskDueDatePassedForAll && !completedTasks) {
      return 'overdue';
    }

    /**
     * PARTIALlY ENDED:
     * ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
     * Partially ended task will always be for one time task as for repeating tasks, they will be moved to 'Pending' upon cycle refresh
     * Atleast 1 assignee hasn't completed the task but due date has passed in supervisor's timezone (logged in user), but it has not passed for that assignee's timezone.
     */
    let supervisorTimezoneNow = moment.utc(
      moment().tz(userTimezone).format('DD-MMM-YYYY hh:mm a'),
      'DD-MMM-YYYY hh:mm a'
    );
    let supervisorTimezoneDueDatePassed =
      supervisorTimezoneNow.isAfter(taskDueDate);
    let dueDateNotPassed = false;
    _assigneesList?.forEach((_assignee) => {
      let timezoneBasedNow = moment.utc(
        moment().tz(_assignee?.timezone!).format('DD-MMM-YYYY hh:mm a'),
        'DD-MMM-YYYY hh:mm a'
      );
      if (timezoneBasedNow.isBefore(taskDueDate)) {
        dueDateNotPassed = true;
      }
    });
    if (
      supervisorTimezoneDueDatePassed &&
      dueDateNotPassed &&
      !completedTasks
    ) {
      return 'partiallyDone';
    }

    /**
     * PENDING:
     * ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
     * Atleast 1 assignee hasn't completed the task and due date has not passed for supervisor's timezone and assignee's timezone
     */
    let dueDateForAssigneeNotPassed = false;
    let dueDateForSupervisorNotPassed = taskDueDate.isBefore(
      supervisorTimezoneNow
    );
    _assigneesList?.forEach((_assignee) => {
      let timezoneBasedNow = moment.utc(
        moment().tz(_assignee?.timezone!).format('DD-MMM-YYYY hh:mm a'),
        'DD-MMM-YYYY hh:mm a'
      );
      if (timezoneBasedNow.isBefore(taskDueDate)) {
        dueDateForAssigneeNotPassed = true;
      }
    });
    if (
      dueDateForSupervisorNotPassed &&
      dueDateForAssigneeNotPassed &&
      !completedTasks
    ) {
      return 'pending';
    }

    /**
     * COMPLETED TASK :
     * ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
     * - One-time task : When all assignees complete the task before the task's due date in assignee's timezone. (NEED TO HANDLE THE CASE IN ASSIGNEE'S TIMEZONE), so need to convert task's due date
     * based on his/her timezone
     * - Repeating task : When end date has passed (in assignee's timezone) regardless of whether assigness completed the task on time
     */
    /** One time task */
    let allAssigneesCompleteTaskBeforeDueDateFlag = true;
    _assigneesList?.forEach((_assignee) => {
      let timezoneBasedNow = moment.utc(
        moment().tz(_assignee?.timezone!).format('DD-MMM-YYYY hh:mm a'),
        'DD-MMM-YYYY hh:mm a'
      );
      if (
        timezoneBasedNow.isAfter(taskDueDate) &&
        _assignee?.completedPercent !== 100
      ) {
        allAssigneesCompleteTaskBeforeDueDateFlag = false;
      }
    });
    if (completedTasks && allAssigneesCompleteTaskBeforeDueDateFlag) {
      return 'completed';
    }
  } else {
    /** Repeating task */
    /**
     * COMPLETED TASK
     */
    let maxDueDateExceededFlag = true;
    _assigneesList?.forEach((_assignee) => {
      let timezoneBasedNow = moment.utc(
        moment().tz(_assignee?.timezone!).format('DD-MMM-YYYY hh:mm a'),
        'DD-MMM-YYYY hh:mm a'
      );
      if (maxTaskDueDate.isBefore(timezoneBasedNow)) {
        maxDueDateExceededFlag = false;
      }
    });
    if (!maxDueDateExceededFlag) {
      return 'completed';
    }
  }
  return 'pending';
}
