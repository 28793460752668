import { ILocationLaunchTask } from 'sub-components/Launcher/location-owner/types';
import { getTaskStatus } from 'sub-components/Launcher/dashboard/Location/helper';
import { convertTaskData } from '..';
import { GroupedPhase } from '../../types';
import moment from 'moment';
import { getUnlockMessage } from 'sub-components/Launcher/dashboard/Location/isTaskUnlocked';
import { defaultConvert } from './defaultConvert';

export const activationTriggerProcessing = (
  task: ILocationLaunchTask,
  phase: GroupedPhase[]
): ILocationLaunchTask => {
  if (task?.completedAt && task?.steps?.every((step) => step?.completedAt)) {
    return {
      ...task,
      status: 'completed',
      unlockMessage: '',
    };
  }

  if (task?.steps?.some((step) => step?.completedAt)) {
    let allTasks = phase?.map((p) => p?.tasks)?.flat();
    let convertedAllTasks = allTasks?.map((all) => convertTaskData(all));
    let { status } = getTaskStatus(
      convertTaskData(task),
      convertedAllTasks,
      task?.liveDate as unknown as Date,
      [],
      true
    );
    return {
      ...task,
      status,
      unlockMessage: '',
    };
  }

  if (
    task?.triggerDetails?.dependantOn === 'phase' ||
    task?.triggerDetails?.dependantOn === 'task'
  ) {
    if (
      !task?.triggerDetails?.startDate ||
      (task?.triggerDetails?.startDate &&
        moment
          ?.utc()
          ?.startOf('day')
          ?.isBefore(
            moment(task?.triggerDetails?.startDate)?.utc()?.startOf('day')
          ))
    ) {
      let allTasks = phase?.map((p) => p?.tasks)?.flat();
      let convertedAllTasks = allTasks?.map((all) => convertTaskData(all));
      return {
        ...task,
        status: 'locked',
        unlockMessage: getUnlockMessage(
          convertTaskData(task),
          convertedAllTasks,
          task?.liveDate as unknown as Date
        ),
      };
    } else {
      let allTasks = phase?.map((p) => p?.tasks)?.flat();
      let convertedAllTasks = allTasks?.map((all) => convertTaskData(all));
      let { status, unlockMessage } = getTaskStatus(
        convertTaskData(task),
        convertedAllTasks,
        task?.liveDate as unknown as Date,
        [],
        true
      );
      return {
        ...task,
        status,
        unlockMessage,
      };
    }
  } else if (task?.triggerDetails?.dependantOn === 'goLive') {
    if (task?.triggerDetails?.activationOrder === 'before') {
      const currentDate = moment?.utc()?.startOf('day');
      const goLiveMoment = moment(task?.liveDate)?.utc()?.startOf('day');
      const activationMoment = goLiveMoment
        .clone()
        .subtract(task?.triggerDetails?.activationOffset, 'milliseconds');
      if (currentDate?.isSameOrAfter(activationMoment, 'day')) {
        // Immediately unlock the task
        let allTasks = phase?.map((p) => p?.tasks)?.flat();
        let convertedAllTasks = allTasks?.map((all) => convertTaskData(all));
        let { status, unlockMessage } = getTaskStatus(
          convertTaskData(task),
          convertedAllTasks,
          task?.liveDate as unknown as Date,
          [],
          true
        );
        return {
          ...task,
          status,
          unlockMessage,
        };
      } else {
        // There is some delay, calculate the delay and show appropriate message
        let unlockMessage = `Will be unlocked ${goLiveMoment.diff(
          activationMoment,
          'days'
        )} days before go live date`;
        return {
          ...task,
          status: 'locked',
          unlockMessage,
        };
      }
    }
  } else if (task?.triggerDetails?.dependantOn === 'startDate') {
    if (task?.triggerDetails?.activationOrder === 'after') {
      const currentDate = moment?.utc()?.startOf('day');
      const startDateMoment = moment(task?.locationStartDate)
        ?.utc()
        ?.startOf('day');
      const activationMoment = startDateMoment
        .clone()
        .add(task?.triggerDetails?.activationOffset, 'milliseconds')
        ?.utc()
        ?.startOf('day');
      if (currentDate?.isSameOrAfter(activationMoment, 'day')) {
        // Immediately unlock the task
        let allTasks = phase?.map((p) => p?.tasks)?.flat();
        let convertedAllTasks = allTasks?.map((all) => convertTaskData(all));
        let { status, unlockMessage } = getTaskStatus(
          convertTaskData(task),
          convertedAllTasks,
          task?.liveDate as unknown as Date,
          [],
          true
        );
        return {
          ...task,
          status,
          unlockMessage,
        };
      } else {
        // There is some delay, calculate the delay and show appropriate message
        let unlockMessage = `Will be unlocked ${activationMoment.diff(
          startDateMoment,
          'days'
        )} days after project start date`;
        return {
          ...task,
          status: 'locked',
          unlockMessage,
        };
      }
    }
  }
  return defaultConvert(phase, task);
  // // If task is completed, return as 'completed'
  // if (task?.completedAt && task?.steps?.every((step) => step?.completedAt)) {
  //   return {
  //     ...task,
  //     status: 'completed',
  //     unlockMessage: '',
  //   };
  // }
  // // else if (task?.triggerDetails?.startDate && task?.triggerDetails?.dueDate) {
  // //   // Do not lock this task as we have start date and due date for it
  // //   let allTasks = phase?.map((p) => p?.tasks)?.flat();
  // //   let convertedAllTasks = allTasks?.map((all) => convertTaskData(all));
  // //   let { status, unlockMessage } = getTaskStatus(
  // //     convertTaskData(task),
  // //     convertedAllTasks,
  // //     task?.liveDate as unknown as Date,
  // //     true
  // //   );
  // //   return {
  // //     ...task,
  // //     status,
  // //     unlockMessage,
  // //   };
  // // }

  // if (task?.triggerDetails?.dependantOn === 'phase') {
  //   /**
  //    * Dependency is on 'phase', so:
  //    * - Check if the dependent id is visible to logged in user or not
  //    * - If dependent item is not visible, make the task as unlocked immediately
  //    * - Else proceed
  //    * - Need to check if all the tasks visible to logged in user in that phase are completed or not
  //    * - If all tasks are completed, that means the phase is completed else the phase is not completed
  //    * - If phase is not completed, then the task will be locked.
  //    * - If phase is completed, then need to check for the offset, if activation will be immediate or there is delay in activation
  //    */
  //   // let isDependentItemVisible = phase?.some(
  //   //   (p) => task?.triggerDetails?.triggerItemId === p?.phaseEid
  //   // );

  //   let dependentPhase = phase?.find(
  //     (p) => p?.phaseEid === task?.triggerDetails?.triggerItemId
  //   );
  //   if (dependentPhase) {
  //     let isPhaseCompleted = dependentPhase?.tasks?.every(
  //       (task) => task?.completedAt
  //     );
  //     if (isPhaseCompleted) {
  //       // Dependent phase is completed
  //       let allTaskCompleteDates = dependentPhase?.tasks
  //         ?.filter((task) => task?.completedAt)
  //         ?.map((task) => moment(task?.completedAt));
  //       let maxCompletedDate = moment?.max(allTaskCompleteDates);
  //       if (
  //         task?.triggerDetails?.activationOrder === 'after' ||
  //         task?.triggerDetails?.activationOrder === 'before'
  //       ) {
  //         const currentDate = moment?.utc()?.startOf('day');
  //         const completedAtMoment = moment(maxCompletedDate)
  //           ?.utc()
  //           ?.startOf('day');
  //         const activationMoment =
  //           task?.triggerDetails?.activationOrder === 'after'
  //             ? completedAtMoment
  //                 .clone()
  //                 .add(task?.triggerDetails.activationOffset, 'milliseconds')
  //             : completedAtMoment
  //                 .clone()
  //                 .subtract(
  //                   task?.triggerDetails?.activationOffset,
  //                   'milliseconds'
  //                 );
  //         const deadlineMoment =
  //           task?.triggerDetails?.deadlineType === 'duration'
  //             ? completedAtMoment
  //                 ?.clone()
  //                 ?.add(task?.triggerDetails?.deadlineOffset, 'milliseconds')
  //             : moment?.utc(task?.liveDate);
  //         if (currentDate?.isSameOrAfter(activationMoment, 'day')) {
  //           // Immediately unlock the task
  //           let allTasks = phase?.map((p) => p?.tasks)?.flat();
  //           let convertedAllTasks = allTasks?.map((all) =>
  //             convertTaskData(all)
  //           );
  //           let { status, unlockMessage } = getTaskStatus(
  //             convertTaskData(task),
  //             convertedAllTasks,
  //             task?.liveDate as unknown as Date,
  //             [],
  //             true
  //           );
  //           return {
  //             ...task,
  //             dueDate: task?.dueDate || deadlineMoment?.utc()?.toISOString(),
  //             status,
  //             unlockMessage,
  //           };
  //         } else {
  //           // There is some delay, calculate the delay and show appropriate message
  //           let allTasks = phase?.map((p) => p?.tasks)?.flat();
  //           let convertedAllTasks = allTasks?.map((all) =>
  //             convertTaskData(all)
  //           );
  //           let unlockMessage = getUnlockMessage(
  //             convertTaskData(task),
  //             convertedAllTasks,
  //             task?.liveDate as unknown as Date
  //           );
  //           return {
  //             ...task,
  //             status: 'locked',
  //             unlockMessage,
  //           };
  //         }
  //       } else {
  //         return defaultConvert(phase, task);
  //       }
  //     } else {
  //       // Dependent phase is not completed
  //       let allTasks = phase?.map((p) => p?.tasks)?.flat();
  //       let convertedAllTasks = allTasks?.map((all) => convertTaskData(all));
  //       return {
  //         ...task,
  //         status: 'locked',
  //         unlockMessage: getUnlockMessage(
  //           convertTaskData(task),
  //           convertedAllTasks,
  //           task?.liveDate as unknown as Date
  //         ),
  //       };
  //     }
  //   } else {
  //     return defaultConvert(phase, task);
  //   }
  // } else if (task?.triggerDetails?.dependantOn === 'task') {
  //   /**
  //    * Dependency is on 'task', so:
  //    * - Check if the dependent id is visible to logged in user or not
  //    * - If dependent item is not visible, make the task as unlocked immediately
  //    * - Else proceed
  //    * - Need to check if the dependent task is completed or not
  //    * - If the dependent task is not completed, then tha task will be locked.
  //    * - Else, the dependent task is completed, so need to check for the offset, if activation will be immediate or there is delay in activation
  //    */

  //   // let isDependentItemVisible = phase
  //   //   ?.map((p) => p?.tasks)
  //   //   ?.flat()
  //   //   ?.some((t) => task?.triggerDetails?.triggerItemId === t?.eid);
  //   let dependentTask = phase
  //     ?.map((p) => p?.tasks)
  //     ?.flat()
  //     ?.find((t) => t?.eid === task?.triggerDetails?.triggerItemId);
  //   if (dependentTask) {
  //     let isDependentTaskCompleted = dependentTask?.completedAt;
  //     if (isDependentTaskCompleted) {
  //       // Dependent task is completed
  //       if (
  //         task?.triggerDetails?.activationOrder === 'after' ||
  //         task?.triggerDetails?.activationOrder === 'before'
  //       ) {
  //         const currentDate = moment?.utc()?.startOf('day');
  //         const completedAtMoment = moment(dependentTask?.completedAt)
  //           ?.utc()
  //           ?.startOf('day');
  //         const activationMoment =
  //           task?.triggerDetails?.activationOrder === 'after'
  //             ? completedAtMoment
  //                 .clone()
  //                 .add(task?.triggerDetails.activationOffset, 'milliseconds')
  //             : completedAtMoment
  //                 .clone()
  //                 .subtract(
  //                   task?.triggerDetails?.activationOffset,
  //                   'milliseconds'
  //                 );
  //         const deadlineMoment =
  //           task?.triggerDetails?.deadlineType === 'duration'
  //             ? completedAtMoment
  //                 ?.clone()
  //                 ?.add(task?.triggerDetails?.deadlineOffset, 'milliseconds')
  //             : moment?.utc(task?.liveDate);
  //         if (currentDate?.isSameOrAfter(activationMoment, 'day')) {
  //           // Immediately unlock the task
  //           let allTasks = phase?.map((p) => p?.tasks)?.flat();
  //           let convertedAllTasks = allTasks?.map((all) =>
  //             convertTaskData(all)
  //           );
  //           let { status, unlockMessage } = getTaskStatus(
  //             convertTaskData(task),
  //             convertedAllTasks,
  //             task?.liveDate as unknown as Date,
  //             [],
  //             true
  //           );
  //           return {
  //             ...task,
  //             dueDate: task?.dueDate || deadlineMoment?.utc()?.toISOString(),
  //             status,
  //             unlockMessage,
  //           };
  //         } else {
  //           // There is some delay, calculate the delay and show appropriate message
  //           let allTasks = phase?.map((p) => p?.tasks)?.flat();
  //           let convertedAllTasks = allTasks?.map((all) =>
  //             convertTaskData(all)
  //           );
  //           let unlockMessage = getUnlockMessage(
  //             convertTaskData(task),
  //             convertedAllTasks,
  //             task?.liveDate as unknown as Date
  //           );
  //           return {
  //             ...task,
  //             status: 'locked',
  //             unlockMessage,
  //           };
  //         }
  //       } else {
  //         return defaultConvert(phase, task);
  //       }
  //     } else {
  //       // Dependent task is not completed
  //       let allTasks = phase?.map((p) => p?.tasks)?.flat();
  //       let convertedAllTasks = allTasks?.map((all) => convertTaskData(all));
  //       return {
  //         ...task,
  //         status: 'locked',
  //         unlockMessage: getUnlockMessage(
  //           convertTaskData(task),
  //           convertedAllTasks,
  //           task?.liveDate as unknown as Date
  //         ),
  //       };
  //     }
  //   } else {
  //     return defaultConvert(phase, task);
  //   }
  // }
  // else if (task?.triggerDetails?.dependantOn === 'goLive') {
  //   if (task?.triggerDetails?.activationOrder === 'before') {
  //     const currentDate = moment?.utc()?.startOf('day');
  //     const goLiveMoment = moment(task?.liveDate)?.utc()?.startOf('day');
  //     const activationMoment = goLiveMoment
  //       .clone()
  //       .subtract(task?.triggerDetails?.activationOffset, 'milliseconds');
  //     if (currentDate?.isSameOrAfter(activationMoment, 'day')) {
  //       // Immediately unlock the task
  //       let allTasks = phase?.map((p) => p?.tasks)?.flat();
  //       let convertedAllTasks = allTasks?.map((all) => convertTaskData(all));
  //       let { status, unlockMessage } = getTaskStatus(
  //         convertTaskData(task),
  //         convertedAllTasks,
  //         task?.liveDate as unknown as Date,
  //         [],
  //         true
  //       );
  //       return {
  //         ...task,
  //         status,
  //         unlockMessage,
  //       };
  //     } else {
  //       // There is some delay, calculate the delay and show appropriate message
  //       let unlockMessage = `Will be unlocked ${goLiveMoment.diff(
  //         activationMoment,
  //         'days'
  //       )} days before go live date`;
  //       return {
  //         ...task,
  //         status: 'locked',
  //         unlockMessage,
  //       };
  //     }
  //   }
  // } else if (task?.triggerDetails?.dependantOn === 'startDate') {
  //   if (task?.triggerDetails?.activationOrder === 'after') {
  //     const currentDate = moment?.utc()?.startOf('day');
  //     const startDateMoment = moment(task?.triggerDetails?.startDate)
  //       ?.utc()
  //       ?.startOf('day');
  //     const activationMoment = startDateMoment
  //       .clone()
  //       .add(task?.triggerDetails?.activationOffset, 'milliseconds')
  //       ?.utc()
  //       ?.startOf('day');
  //     if (currentDate?.isSameOrAfter(activationMoment, 'day')) {
  //       // Immediately unlock the task
  //       let allTasks = phase?.map((p) => p?.tasks)?.flat();
  //       let convertedAllTasks = allTasks?.map((all) => convertTaskData(all));
  //       let { status, unlockMessage } = getTaskStatus(
  //         convertTaskData(task),
  //         convertedAllTasks,
  //         task?.liveDate as unknown as Date,
  //         [],
  //         true
  //       );
  //       return {
  //         ...task,
  //         status,
  //         unlockMessage,
  //       };
  //     } else {
  //       // There is some delay, calculate the delay and show appropriate message
  //       let unlockMessage = `Will be unlocked ${activationMoment.diff(
  //         startDateMoment,
  //         'days'
  //       )} days after project start date`;
  //       return {
  //         ...task,
  //         status: 'locked',
  //         unlockMessage,
  //       };
  //     }
  //   }
  //   return defaultConvert(phase, task);
  // }
  // return defaultConvert(phase, task);
};
