import { useUserEntity } from 'hooks';
import { useCallback, useMemo } from 'react';
import { AuditSessionEntity } from 'sub-components/audits/audit-tab/supervised/types';

/**
 * Custom hook to retrieve location names associated with a specific auditor.
 *
 * @param auditData - The audit data containing auditors information.
 * @returns A callback function that takes an `auditorId` and returns an array of location names.
 */

const useAuditorLocations = (
  auditData: AuditSessionEntity | undefined
): ((auditorId: string) => string[]) => {
  const businessLocations = useUserEntity((entity) => entity?.locations);
  /**
   * Precompute a Map for faster EID to name lookup.
   * This memoization ensures that the map is only recalculated when `businessLocations` changes.
   */
  const locationsMap = useMemo(() => {
    if (!businessLocations) return new Map<string, string>();

    return new Map(
      businessLocations
        .filter((location) => location.eid && location.name)
        .map((location) => [location.eid, location.name] as [string, string])
    );
  }, [businessLocations]);

  /**
   * Callback to retrieve location names for a given auditor ID.
   *
   * @param auditorId - The ID of the auditor whose locations are to be retrieved.
   * @returns An array of location names associated with the auditor.
   */
  const auditorLocations = useCallback(
    (auditorId: string): string[] => {
      // Early return if auditors or business locations data is unavailable
      if (!auditData?.audit?.auditors || !businessLocations) return [];

      /**
       * Filter auditors matching the provided `auditorId`.
       */
      const filteredAuditors = auditData.audit.auditors.filter((auditor) =>
        auditor?.userId?.includes(auditorId)
      );

      // Return empty array if no auditors match the `auditorId`
      if (filteredAuditors.length === 0) return [];

      /**
       * Collect all location EIDs from the filtered auditors.
       * Using `flatMap` efficiently flattens the array of location EIDs.
       */
      const locationEids = filteredAuditors.flatMap(
        (auditor) => auditor.locations || []
      );

      // Return empty array if no location EIDs are found
      if (locationEids.length === 0) return [];

      /**
       * Map EIDs to location names using the precomputed `locationsMap`.
       * The `filter` ensures only valid and non-empty names are returned.
       */
      return locationEids
        .map((eid) => locationsMap.get(eid))
        .filter((name): name is string => Boolean(name));
    },
    [auditData, locationsMap]
  );

  return auditorLocations;
};

export default useAuditorLocations;
