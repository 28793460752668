import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { toArray } from '../../../../utils';
import { useLauncherConfigContext } from '../common';
import { BoardSectionItem } from './sections';

import AddSection from './sections/AddSection';

interface IProps {}

const BoardSectionLists: FC<IProps> = () => {
  const contents = useLauncherConfigContext((state) => {
    return toArray(state.config.contents).map((con) => con.eid);
  });

  return (
    <Flex flex={1} gap={5} overflow='auto' id='board-view-sections'>
      {contents.map((_, index) => {
        return <BoardSectionItem key={index} sectionIndex={index} />;
      })}
      <AddSection />
    </Flex>
  );
};

export default BoardSectionLists;
