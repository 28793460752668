import React, { FC } from 'react';
import { Flex, Stack } from '@chakra-ui/react';
import { FilterItem, SummaryData } from '../types';
import SummaryFilterContainer from './SummaryFilterContainer';
import FilterByModule from '../../Common/FilterByModule';
import {
  IFilters,
  INotificationItem,
} from 'sub-components/NotificationCenter/types/service-types';
import {
  NotificationFilters,
  NotificationFiltersParams,
} from 'sub-components/NotificationCenter/types/types';

interface IProps {
  isCompactView?: boolean;
  customStyles?: any;
  resetHandler: () => void;
  checkedModules: FilterItem[];
  handleRemoveFilter?: any;
  handleModuleSelect?: any;
  setSelectedModules: any;
  selectedModules: any;
  notifications?: INotificationItem[];
  notificationFilters: NotificationFilters;
  notificationHistoryData?: any;
  notificationData: INotificationItem[];
  notificationLoading?: any;
  handleUpdateNotificationReadStatus?: (eid: string, status: string) => void;
  setTabName?: any;
  markAllNotificationRead?: () => void;
  searchFieldText?: string;
  notificationCount: number;
  decideFilterAndRefetch: (pageIndex?: number) => void;
  tabName: string;
  markGroupedNotificationsRead?: () => any;
}

const SummaryWrapper: FC<IProps> = ({
  isCompactView,
  customStyles,
  resetHandler,
  checkedModules,
  handleRemoveFilter,
  handleModuleSelect,
  selectedModules,
  setSelectedModules,
  notifications,
  notificationFilters,
  notificationData,
  notificationHistoryData,
  notificationLoading,
  handleUpdateNotificationReadStatus,
  setTabName,
  markAllNotificationRead,
  searchFieldText,
  notificationCount,
  decideFilterAndRefetch,
  tabName,
  markGroupedNotificationsRead,
}) => {
  return (
    <SummaryFilterContainer
      isCompactView={isCompactView}
      customStyles={customStyles}
      resetHandler={resetHandler}
      checkedModules={checkedModules}
      handleRemoveFilter={handleRemoveFilter}
      handleModuleSelect={handleModuleSelect}
      selectedModules={selectedModules}
      setSelectedModules={setSelectedModules}
      notifications={notifications}
      notificationFilters={notificationFilters}
      notificationHistoryData={notificationHistoryData}
      notificationData={notificationData}
      notificationLoading={notificationLoading}
      handleUpdateNotificationReadStatus={handleUpdateNotificationReadStatus}
      setTabName={setTabName}
      markAllNotificationRead={markAllNotificationRead}
      searchFieldText={searchFieldText}
      notificationCount={notificationCount}
      decideFilterAndRefetch={decideFilterAndRefetch}
      tabName={tabName}
      markGroupedNotificationsRead={markGroupedNotificationsRead}
    />
  );
};
SummaryWrapper.displayName = 'sc/NC/S/c/SummaryWrapper.tsx';
export default React.memo(SummaryWrapper);
