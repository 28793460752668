import { useRef, useState, FC } from 'react';
import { Box, Button, Flex, Text, useToast } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faXmark } from '@fortawesome/pro-light-svg-icons';
import { usersEntityObj } from 'sub-components/Header';
import { shareAuditReportViaEmail } from 'pages/Audits/services/template-listing.graphql';
import { Input } from 'atoms';
import { ActionButton } from '../../../../ui-components';
import { Any } from '../../../../types';
import { AuthRole } from '../../../../authorization';
import { FormInput } from 'atoms';

interface IProps {
  assignment: any;
  auditId: string;
  finalUrl: string;
}

const ShareReportViaEmail: FC<IProps> = ({ auditId, assignment, finalUrl }) => {
  const [currentUrl, setCurrentUrl] = useState(window.location.pathname);
  const [emailList, setEmailList] = useState<string[]>([]);
  const [inputFieldText, setInputFieldText] = useState('');
  const [showErrorText, setShowErrorText] = useState(false);
  const emailFieldRef = useRef(null);

  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const [shareReportViaEmail] = useMutation(shareAuditReportViaEmail, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.ShareAuditReport?.succeed) {
        toast({
          title: 'Report successfully shared via email',
          status: 'success',
        });
        setEmailList([]);
      } else {
        toast({
          title: 'Oops! Something went wrong!',
          status: 'error',
        });
      }
    },
  });

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleAddEmail = (e: Any, action: string) => {
    if (action === 'enter') {
      if (e.keyCode === 13) {
        if (!validateEmail(e?.target?.value?.trim())) {
          setShowErrorText(true);
          return;
        }
        setEmailList((prevState) => {
          return [...prevState, e.target.value.trim()];
        });
        setInputFieldText('');
      }
    } else if (action === 'blur') {
      if (!validateEmail(e?.target?.value?.trim())) {
        setShowErrorText(true);
        return;
      }
      setEmailList((prevState) => {
        return [...prevState, e.target.value];
      });
      setInputFieldText('');
    } else if (action === 'addBtn') {
      if (!validateEmail(inputFieldText?.trim())) {
        setShowErrorText(true);
        return;
      }
      setEmailList((prevState) => {
        return [...prevState, inputFieldText?.trim()];
      });
      setInputFieldText('');
    }
  };

  const handleRemoveEmail = (email: string) => {
    const filteredEmailList = emailList?.filter((item) => item !== email);
    setEmailList(filteredEmailList);
  };

  const handleEmailSend = () => {
    if (emailList?.length > 0) {
      return shareReportViaEmail({
        variables: {
          emails: emailList,
          url: assignment?.reportsUrl ? assignment?.reportsUrl : finalUrl,
          auditId: auditId,
        },
      });
    }
  };

  return (
    <>
      <Flex
        gap={4}
        flexDir='column'
        paddingX={5}
        paddingY={4}
        borderRadius='8px'
        border='1px solid #C7C7C7'
      >
        <Box fontWeight='600' fontSize='15px'>
          Send via mail
        </Box>
        <Flex flexDir={'column'}>
          {/* <Input
            size='lg'
            ref={emailFieldRef}
            value={inputFieldText}
            variant='filled'
            placeholder={'Enter recipients email ID'}
            onChange={(e) => {
              setInputFieldText(e.target.value);
              setShowErrorText(false);
            }}
            onKeyUp={(e) =>
              inputFieldText?.length > 0 && handleAddEmail(e, 'enter')
            }
            onBlur={(e) =>
              inputFieldText?.length > 0 && handleAddEmail(e, 'blur')
            }
          /> */}
          <FormInput
            size='lg'
            ref={emailFieldRef}
            value={inputFieldText}
            variant='filled'
            placeholder={'Enter recipients email ID'}
            rightIcon={
              inputFieldText?.length > 0 && (
                <Button
                  fontSize='14px'
                  onClick={(e) => handleAddEmail(e, 'addBtn')}
                  colorScheme='blue'
                  variant='ghost'
                  paddingX='16px'
                  borderRightRadius='12px'
                >
                  Add
                </Button>
              )
            }
            onChange={(e) => {
              setInputFieldText(e.target.value);
              setShowErrorText(false);
            }}
            onKeyUp={(e) =>
              inputFieldText?.length > 0 && handleAddEmail(e, 'enter')
            }
            // onBlur={(e) =>
            //   inputFieldText?.length > 0 && handleAddEmail(e, 'blur')
            // }
          />
          {showErrorText && (
            <Text color={'red'} pl={'5px'}>
              Please enter a valid email
            </Text>
          )}
        </Flex>
        <Flex
          flexWrap={'wrap'}
          gap={2}
          _empty={{
            display: 'none',
          }}
        >
          {emailList?.map((email, index) => {
            return (
              <Flex
                gap={2}
                alignItems={'center'}
                borderRadius={'8px'}
                padding={'5px 10px'}
                backgroundColor={'#B5E4CA'}
                key={index}
              >
                <Text
                  lineHeight={'24px !important'}
                  fontWeight={500}
                  fontSize='14px'
                >
                  {email}
                </Text>
                <FontAwesomeIcon
                  onClick={() => handleRemoveEmail(email)}
                  fontSize={'16px'}
                  icon={faXmark as IconProp}
                  cursor={'pointer'}
                />
              </Flex>
            );
          })}
        </Flex>

        <Flex justifyContent={'flex-end'}>
          <ActionButton colorScheme='blue' actionFn={handleEmailSend}>
            Send
          </ActionButton>
        </Flex>
      </Flex>
    </>
  );
};

ShareReportViaEmail.displayName =
  'pages/Audits/Report/components/ShareReportViaEmail';

export default ShareReportViaEmail;
