import React, { MouseEventHandler } from 'react';
import { Box, Flex, forwardRef, Image, Radio } from '@chakra-ui/react';
import { SelectOption } from '../../../../../../../atoms';
import { IconsPlaceholder } from '../../../../../../../ui-components';

interface IProps {
  option: SelectOption;
  onClick?: MouseEventHandler<HTMLDivElement>;
  isSelected?: boolean;
}

const LocationItem = forwardRef<IProps, 'div'>(
  ({ option, onClick, isSelected }, ref) => {
    return (
      <Flex
        ref={ref}
        key={option.value}
        minH='42px'
        alignItems='center'
        paddingInline='12px'
        borderRadius='5px'
        cursor='pointer'
        color='#6F767E'
        role='listitem'
        tabIndex={0}
        aria-selected={isSelected}
        _hover={{ bg: '#F4F4F3' }}
        onClick={onClick}
        gap={3}
      >
        <Image
          src={IconsPlaceholder['location']}
          borderRadius='full'
          boxSize='30px'
        />
        <Box flex={1} fontSize='15px' fontWeight='600' isTruncated>
          {option.label}
        </Box>
        <Radio size='lg' isChecked={isSelected} />
      </Flex>
    );
  }
);

LocationItem.displayName =
  'displayName:sub-components/nexus/Compliance/Create/components/common/location-select/LocationItem';

export default LocationItem;
