import React, { FC, Fragment } from 'react';
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';

import FilterContents from './FilterContents';

interface IProps {}

const BoardFilterPopup: FC<IProps> = ({ children }) => {
  return (
    <Popover
      placement='bottom-start'
      isLazy
      // arrowSize={arrowSize}
      // closeOnBlur={closeOnBlur}
      // offset={offset}
      // strategy={strategy}
    >
      {/*// @ts-ignore*/}
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        borderRadius='8px'
        width='unset'
        minW='300px'
        maxWidth='300px'
      >
        <PopoverArrow />
        <PopoverBody
          display='flex'
          borderRadius='8px'
          boxShadow='lg'
          maxHeight='500px'
          px='12px'
        >
          <FilterContents />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default BoardFilterPopup;
