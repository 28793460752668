import { Dispatch, SetStateAction } from 'react';
import { QueryLazyOptions, useLazyQuery } from '@apollo/client';

import { useUserDataSelector } from '../../../../hooks';
import { toArray } from '../../../../utils/utils';

import {
  getSupervisors,
  SupervisorVariable,
  TaskSupervisorResponse,
} from './supervisors.graphql';
import { SelectOption } from '../common';

interface Returned {
  getUsersData: (options: QueryLazyOptions<SupervisorVariable>) => void;
  loading: boolean;
}

export const useMembersData = (
  setMembers: Dispatch<SetStateAction<SelectOption[]>>
): Returned => {
  const authUserId = useUserDataSelector((state) => state.eid);

  const [getUsersData, { loading }] = useLazyQuery<
    TaskSupervisorResponse,
    SupervisorVariable
  >(getSupervisors, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const _supervisors = toArray(data?.EntityUser).reduce((acc, user) => {
        if (user.eid !== authUserId && ['active']?.includes(user.status)) {
          acc.push({
            label: user.name,
            value: user.eid,
            authRole: user.authRole,
            url: user?.profilePic,
            locations: user?.locations,
            role: user?.role,
            status: user?.status,
          });
        }

        return acc;
      }, [] as SelectOption[]);
      setMembers(_supervisors);
    },
  });

  return {
    getUsersData,
    loading,
  };
};
