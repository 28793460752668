import React, { FC } from 'react';
import { AuthRole, withAuthorization } from '../../authorization';
import { AppRoute } from '../../routes';
import { DashboardContainer } from '../../sub-components';
import { LauncherBoardConfig } from '../../sub-components/Launcher';

interface IProps {}

const LauncherConfigPage: FC<IProps> = () => {
  return (
    <DashboardContainer>
      <LauncherBoardConfig />
    </DashboardContainer>
  );
};

export default withAuthorization({
  permittedFor: 'user',
  permittedRoles: [AuthRole.SUPER_ADMIN],
  redirectTo: AppRoute.LAUNCHER_URL,
})(LauncherConfigPage);
