import { gql } from '@apollo/client';

export const LOCATION_LAUNCH_TASKS_V2 = gql`
  query LocationLaunchTasks($locationIds: [String]) {
    LocationLaunchTasks(locationIds: $locationIds) {
      eid
      phase
      phaseEid
      liveDate
      taskCategoryEid
      completedAt
      completedBy
      isPhaseSelected
      isRequired
      startDate
      dueDate
      assignedUsers
      assignedType
      isCompleted
      title
      description
      duration
      buffer
      dependency
      docRequired
      launchId
      steps {
        stepId
        title
        isCompleted
        type
        formId
        sopId
        trainingAssignee
        trainingId
        formResponseId
        completedAt
        completedBy
      }
      locationId
      locationStartDate
      files {
        name
        url
        type
        mimetype
        fileSize
        createdBy {
          eid
          name
        }
        createdAt
        updatedAt
      }
      triggerDetails {
        triggerChoice
        activationOffset
        deadlineOffset
        triggerItemId
        activationOrder
        deadlineOrder
        dependantOn
        deadlineType
        startDate
        dueDate
      }
    }
  }
`;

export const GET_LAUNCHING_LOCATION_DETAILS_BY_LAUNCHER_ID = gql`
  query GetLaunchingLocationDetailsByLauncherId($launcherId: String!) {
    LocationLaunchById(eid: $launcherId) {
      details {
        locationName
      }
      liveDate
    }
  }
`;
