import { Flex } from '@chakra-ui/react';
import {
  ExtendedLauncherContent,
  PhaseData,
} from 'pages/LocationsNew/Components/ProjectDetailPhase/store/project-detail-phase.types';
import React, { FC } from 'react';
import PhaseName from '../../PhaseName';
import ToBeUnlockedText from '../../ToBeUnlockedText';

interface IProps {
  index: number;
  eid: string;
}

const PhaseInfo: FC<IProps> = ({ index, eid }) => {
  return (
    <Flex flexDir='column'>
      <PhaseName index={index} eid={eid} />
      <ToBeUnlockedText index={index} eid={eid} />
      {/* {data?.toBeUnlocked && <ToBeUnlockedText text={data?.toBeUnlocked} />} */}
    </Flex>
  );
};

PhaseInfo.displayName =
  'displayName:pages/LocationsNew/Components/ProjectDetailPhase/components/PhaseAccordionButton/phase-button-upper/components/PhaseInfo';

export default PhaseInfo;
