import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import EditorSectionLists from './EditorSectionLists';
import { EditorTaskForm } from './editor-task-form';

interface IProps {}

const ConfigEditorContainer: FC<IProps> = () => {
  return (
    <Flex flex={1} overflow='inherit' gap={4}>
      <EditorSectionLists />
      <EditorTaskForm />
    </Flex>
  );
};

ConfigEditorContainer.displayName = 'sc/L/lc/ev/ConfigEditorContainer';

export default ConfigEditorContainer;
