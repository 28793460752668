import React, { FC } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import ExpiryDateSelectField from './ExpiryDateSelectField';
import CategorySelectField from './CategorySelectField';
import LocationSelectField from './LocationSelectField';
import { DocumentReminder } from './document-reminder';

interface IProps {
  complianceId?: string;
  type?: 'update' | 'reminder';
  preDefinedLocationId?: string;
}

const UpdateDocumentForm: FC<IProps> = ({
  complianceId,
  type,
  preDefinedLocationId,
}) => {
  return (
    <>
      {type !== 'reminder' && <CategorySelectField />}

      {type !== 'reminder' && (
        <LocationSelectField preDefinedLocationId={preDefinedLocationId} />
      )}

      <ExpiryDateSelectField isEditing />

      <DocumentReminder />
    </>
  );
};

UpdateDocumentForm.displayName =
  'displayName:sub-components/nexus/Compliance/Create/components/common/UpdateDocumentForm';

export default UpdateDocumentForm;
