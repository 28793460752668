import React, { FC } from 'react';
import { Flex, ListItem, Text } from '@chakra-ui/react';
import RoundProgress from '../../Components/sub-components/RoundProgress';
import { UserProgressEntity } from '../../tasks.types';

interface IProps {
  data: UserProgressEntity;
}

const AssigneeItem: FC<IProps> = ({ data }) => {
  return (
    <ListItem>
      <Flex gap='15px' align='center'>
        <RoundProgress
          img={data?.profilePic}
          value={+data?.progressPercent?.toFixed(0)}
          thickness={6}
        />
        <Flex flexDir='column' gap={2}>
          <Text fontWeight={600} m={0}>
            {data?.name}
          </Text>
          <Flex color='#92929D' mt='-12px' gap='5px'>
            <Text m={0}>{data?.role}</Text>
            <Text m={0}>&bull;</Text>
            <Text m={0}>{data?.location}</Text>
          </Flex>
        </Flex>
      </Flex>
    </ListItem>
  );
};
AssigneeItem.displayName =
  'displayName:pages/TasksDasboard/SupervisedTasks/component/AssigneeItem.tsx';
export default AssigneeItem;
