import React, { FC, useMemo } from 'react';
import {
  Box,
  Divider,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';

import { IconImage, RoleAndAuthRole } from '../../../../../../ui-components';
import { usersEntityObj } from '../../../../../Header';
import { UserEntityData } from '../../../../../../shared/graphql/shared-types';

interface ItemProps {
  user: UserEntityData;
}

const AssigneeItem: FC<ItemProps> = ({ user }) => {
  return (
    <Flex gap={3} align='center'>
      <IconImage
        name={user.name}
        thumbnail={user.profilePic}
        boxSize={40}
        borderRadius='40px'
      />
      <Flex flex={1} flexDir='column'>
        <Box fontWeight='600' color='#171725'>
          {user.name}
        </Box>
        <RoleAndAuthRole
          role={user.role}
          authRole={user?.locations?.[0]?.name}
          defaultValue='-'
          disableAuthRoleSplit
          disableToolTip
          roleProps={{
            color: 'inherit',
          }}
        />
      </Flex>
    </Flex>
  );
};

interface IProps {
  userIds?: string[];
}

const AssigneeList: FC<IProps> = ({ userIds = [] }) => {
  const entityObjData = useReactiveVar(usersEntityObj);

  const users = useMemo(() => {
    const _userId = userIds.slice();
    return entityObjData?.filter((u) => _userId.includes(u.eid));
  }, [entityObjData, userIds]);

  return (
    <Flex flexDir='column' gap={3} maxHeight='310px' overflowY='scroll'>
      {users.map((user, i) => (
        <React.Fragment key={i}>
          {i !== 0 && <Divider />}
          <AssigneeItem user={user} />
        </React.Fragment>
      ))}
    </Flex>
  );
};

const TaskAssigneeList: FC<IProps> = ({ children, userIds }) => {
  return (
    <Popover
      isLazy
      lazyBehavior='unmount'
      placement='right-start'
      trigger='hover'
    >
      {/*// @ts-ignore */}
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody padding='16px 16px'>
          <AssigneeList userIds={userIds} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

AssigneeItem.displayName = 'sc/L/lc/bb/s/lt/AssigneeItem';
AssigneeList.displayName = 'sc/L/lc/bv/s/lt/AssigneeList';
TaskAssigneeList.displayName = 'sc/L/lc/bv/s/lt/TaskAssigneeList';

export default TaskAssigneeList;
