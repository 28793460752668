import React, { FC } from 'react';
import { Box, Center, Image } from '@chakra-ui/react';
import { TFuncKey } from 'i18next';
import { useTranslation } from 'react-i18next';

import barGraph from '../../../../../assets/images/empty-state/bar-graph-empty-state.svg';

const images = {
  bar: barGraph,
};

export interface EmptyGraphProps {
  minHeight?: number | string;
  graphType?: keyof typeof images;
  emptyI18Key?: TFuncKey<('task' | 'launcher')[], undefined>;
}

const EmptyGraph: FC<EmptyGraphProps> = ({
  minHeight,
  graphType = 'bar',
  emptyI18Key,
}) => {
  const { t } = useTranslation(['task', 'launcher']);

  const imageSrc = images[graphType];

  return (
    <Center flexDir='column' gap={2} minHeight={minHeight}>
      <Image maxW='100%' src={imageSrc} />
      {emptyI18Key && (
        <Box fontSize='12px' color='#999999'>
          {t(emptyI18Key)}
        </Box>
      )}
    </Center>
  );
};

EmptyGraph.displayName = 'sc/t/p/c/g/EmptyGraph';

export default EmptyGraph;
