import React from 'react';
import { forwardRef } from '@chakra-ui/react';
import { Dropdown, SelectOption } from '../../../../../../atoms';
import { useConfigCategoryList } from './useConfigCategoryList';

const toValue = (value: string): SelectOption | null => {
  if (value) {
    return { value: value, label: '' };
  }
  return null;
};

const TaskCategorySelect = forwardRef((props, ref) => {
  const { loading, categories } = useConfigCategoryList();

  return (
    <Dropdown
      size='lg'
      options={categories}
      placeholder='Select category'
      {...props}
      value={toValue(props.value)}
      onChange={(newValue) => props.onChange?.(newValue.value)}
      getOptionLabel={(option) => {
        if (!option?.label && option?.value) {
          return categories.find((o) => o.value === option?.value)?.label;
        }
        return option?.label;
      }}
      ref={ref}
      isLoading={loading}
      isDisabled={loading}
      selectStyles={{
        container: { flex: 2 },
        singleValue: {
          fontSize: '14px',
          fontWeight: '600',
          color: '#6F767E',
        },
        dropdownIndicator: {
          color: '#6F767E',
        },
      }}
      inputStyle={{
        borderWidth: '2px',
        borderRadius: '12px',
        outline: 'none',
        backgroundColor: 'white',
      }}
    />
  );
});

TaskCategorySelect.displayName = 'sc/L/lc/c/ctf/c/TaskCategorySelect';

export default TaskCategorySelect;
