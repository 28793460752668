import { cloneDeep } from '@apollo/client/utilities';
import { v4 as uuidv4 } from 'uuid';
import { toArray } from '../../../../utils';
import { LauncherAssignee, LauncherTaskStep } from './fragment.graphql';
import {
  ContentsVariable,
  TaskVariable,
  UpdateLauncherInput,
} from './update-launcher.graphql';
import { BaseFormInput } from './base-launcher.types';

export const stepsMapWithId = (
  steps?: LauncherTaskStep[]
): LauncherTaskStep[] => {
  return toArray(steps).map((value) => {
    return {
      stepId: value.stepId || uuidv4(),
      title: value.title,
      type: value.type,
      formId: value.formId || undefined,
      sopId: value.sopId || undefined,
      trainingId: value.trainingId || undefined,
    };
  });
};

type ILauncherTask = BaseFormInput['contents'][number]['tasks'][number];

export const getAssignedType = (
  task?: LauncherAssignee
): LauncherAssignee['assignedType'] => {
  return task?.assignedType || 'location';
};

export const getAssignedUser = (task?: LauncherAssignee): string[] => {
  if (task?.assignedType === 'user') {
    return task?.assignedUser || [];
  }
  return [];
};

export const transformTask = (
  task: ILauncherTask,
  index: number,
  isNotLocalSave?: boolean
): TaskVariable => {
  const _object = {
    eid: task.eid,
    title: task.title,
    description: task.description,
    duration: Number(task.duration) || undefined!,
    docRequired: task.docRequired,

    dependency: index === 0 ? 'INDEPENDENT' : task.dependency!,

    steps: stepsMapWithId(task.steps),

    completed: task.completed,

    selected: task.selected,

    assignedType: getAssignedType(task),
    assignedUser: getAssignedUser(task),
  };

  if (isNotLocalSave) {
    if (task.isLocal) {
      _object.eid = undefined;
    }
    return _object;
  }

  // @ts-ignore
  _object.isLocal = task.isLocal;

  return _object;
};

const mapTaskContents = (
  contents?: BaseFormInput['contents'],
  skipCheck?: boolean,
  isNotLocalSave?: boolean
): ContentsVariable[] => {
  return toArray(contents).map((content) => {
    const _content = {
      eid: content.eid!, // Auto generate on backend
      category: content.category,
      selected: content.selected,
      assignedType: getAssignedType(content),
      assignedUser: getAssignedUser(content),
      tasks: toArray(content.tasks).reduce<ContentsVariable['tasks']>(
        (tasks, task, index) => {
          if (task.eid || skipCheck) {
            tasks.push(transformTask(task, index, isNotLocalSave));
          }

          return tasks;
        },
        []
      ),
    };

    if (isNotLocalSave) {
      return _content;
    }

    // @ts-ignore
    _content.isLocal = content.isLocal;

    return _content;
  });
};

interface UpdateConfig {
  skipCheck?: boolean;
  isNotLocalSave?: boolean;
}

export function transformUpdateData<T extends BaseFormInput>(
  rawValues: T,
  config?: UpdateConfig
): UpdateLauncherInput {
  const values = cloneDeep(rawValues);

  return {
    eid: values.eid!,
    published: values.published,
    contents: mapTaskContents(
      values.contents,
      config?.skipCheck ?? !rawValues.eid,
      config?.isNotLocalSave
    ),
  };
}

function generateEid(length = 24) {
  const characters =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let eid = '';
  for (let i = 0; i < length; i++) {
    eid += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return eid;
}

export function convertContent<T extends BaseFormInput>(
  contents: T['contents']
): T['contents'] {
  contents.forEach((content) => {
    if (!content.eid) {
      content.eid = generateEid();
      content.isLocal = true;
      content.selected = true;
    }
    content.tasks.forEach((_task) => {
      if (!_task.eid) {
        _task.eid = generateEid();
        _task.isLocal = true;
        _task.selected = true;
      }
    });
  });
  return contents;
}
