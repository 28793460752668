import React, { FC } from 'react';
import { Center, Flex } from '@chakra-ui/react';

interface IProps {
  label: string;
  value: string;
}

const DefaultTriggerItem: FC<IProps> = ({ label, value }) => {
  return (
    <Flex fontWeight='600'>
      <Flex flex={1} minHeight='48px' align='center' color='#33383F'>
        {label}
      </Flex>
      <Center
        flex={2}
        border='2px solid #EFEFEF'
        borderRadius='12px'
        minHeight='48px'
        color='#6F767E'
      >
        {value}
      </Center>
    </Flex>
  );
};

DefaultTriggerItem.displayName = 'sc/L/lc/c/t/DefaultTriggerItem';

export default DefaultTriggerItem;
