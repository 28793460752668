import React, { useCallback, useRef } from 'react';
import {
  BoxHeader,
  UseConfirm,
  useConfirm,
} from '../../../../../../ui-components';
import RenameBoard, { RenameProps } from './RenameBoard';

interface IProps extends RenameProps {}

type IRenameBoard = (props: IProps) => void;

const useRenameBoard = (): IRenameBoard => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  return useCallback((props) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      title: <BoxHeader title='Rename board' fontSize='18px' boxWidth={3} />,
      content: <RenameBoard {...props} />,
      isCentered: true,
      size: '2xl',
      footer: null,
      contentProps: {
        paddingTop: '8px',
        paddingBottom: '24px',
        paddingInline: '8px',
        borderRadius: '12px',
      },
    });
  }, []);
};

export default useRenameBoard;
