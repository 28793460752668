import React, { FC } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from 'atoms';
import { BoxHeader } from 'ui-components';

interface IProps {
  onClickHandler: () => void;
}

const Heading: FC<IProps> = ({ onClickHandler }) => {
  const { t } = useTranslation(['location']);
  return (
    <Flex justify='space-between' align='center' w='full'>
      <BoxHeader
        fontSize='16px'
        title={t('location:documents')}
        color='#CABDFF'
      />
      {/* <PrimaryButton
        variant='outline'
        width='fit-content'
        colorScheme='blue'
        title={t('location:addDocuments')}
        leftIcon={<AddIcon />}
        onClick={onClickHandler}
      /> */}
    </Flex>
  );
};

export default Heading;
