import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthRole } from '../../authorization';
import { useUserDataSelector } from '../../hooks';
import { HOME_URL, LAUNCHER_DASHBOARD, LAUNCHER_TASKS } from '../../appRoutes';
import { DashboardContainer } from '../../sub-components';
import { LocationOwnerContainer } from 'sub-components/Launcher/location-owner';

interface IProps {}

const LauncherPage: FC<IProps> = () => {
  const { type, authRole } = useUserDataSelector((state) => ({
    authRole: state.authRole,
    type: state.type,
  }));

  if (type === 'branch') {
    return <Redirect to={HOME_URL} />;
  }

  switch (authRole) {
    case AuthRole.LOCATION_OWNER:
      return (
        <DashboardContainer>
          <LocationOwnerContainer />
        </DashboardContainer>
      );
    case AuthRole.SUPER_ADMIN:
    case AuthRole.ADMIN:
      return <Redirect to={LAUNCHER_DASHBOARD} />;
    default:
      return <Redirect to={HOME_URL} />;
  }
};

export default LauncherPage;
