import { chakra } from '@chakra-ui/react';

export const TriggerContainer = chakra('div', {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    padding: 4,
    gap: 4,
    borderRadius: '12px',
    backgroundColor: '#EBF9FF',
  },
});

TriggerContainer.displayName = 'sc/L/lc/c/ctf/tts/TriggerContainer';

export default TriggerContainer;
