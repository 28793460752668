import React, { forwardRef, useImperativeHandle } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  SpaceProps,
} from '@chakra-ui/react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../atoms';

import { ILauncherSetupInput } from './launcher-setup.types';
import ContentContainer from './ContentContainer';

export interface SetupFormRef {
  setTitle: (title: string) => void;
}

interface IProps {
  paddingX?: SpaceProps['paddingX'];
}

const LauncherSetupForm = forwardRef<SetupFormRef, IProps>(
  ({ paddingX = '40px' }, ref) => {
    const { t } = useTranslation('launcher');
    const methods = useForm<ILauncherSetupInput>();

    useImperativeHandle(
      ref,
      () => {
        return {
          setTitle: (value) => {
            methods.setValue('title', value, {
              shouldDirty: true,
            });
            methods.setFocus('title');
          },
        };
      },
      []
    );

    return (
      <FormProvider {...methods}>
        <Flex flexDir='column' gap={6}>
          <Flex
            flexDir='column'
            flex={1}
            borderRadius='12px'
            bg='#FFFFFF'
            paddingY='20px'
            paddingX={paddingX}
            gap='12px'
          >
            <Box>
              <Box fontSize='18px' fontWeight='700' color='#000000'>
                {t('nameYourBoard')}
              </Box>
              <Box fontSize='12px' fontWeight='400' color='#6F767E'>
                {t('nameYourBoardDesc')}
              </Box>
            </Box>

            <Controller<ILauncherSetupInput, 'title'>
              name='title'
              rules={{
                required: 'Add a name to your board!',
                validate: (value) => {
                  if (value && value?.trim()?.length === 0) {
                    return 'Invalid board name';
                  }
                },
              }}
              defaultValue=''
              render={({ field, fieldState }) => (
                <FormControl isInvalid={fieldState.invalid}>
                  <Input size='lg' placeholder='Enter board name' {...field} />
                  <FormErrorMessage>
                    {fieldState.error?.message}
                  </FormErrorMessage>
                </FormControl>
              )}
            />
          </Flex>

          <Flex
            flex={1}
            borderRadius='12px'
            bg='#FFFFFF'
            padding='40px 24px 40px 40px'
            paddingLeft={paddingX}
          >
            <ContentContainer />
          </Flex>
        </Flex>
      </FormProvider>
    );
  }
);

LauncherSetupForm.displayName = 'LauncherSetupForm';

export default LauncherSetupForm;
