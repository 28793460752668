import { isComplianceDocumentExpiring } from 'sub-components/nexus/Compliance/Listing/helper';

type getDocNameWithExtensionType = (docName: string) => string;

export const getDocNameWithExtension: getDocNameWithExtensionType = (
  docName
) => {
  let formattedDocName = '';
  let extension = '';

  const tempArray = docName?.split('.');
  extension = tempArray[tempArray?.length - 1];

  for (let i = 0; i < tempArray?.length; i++) {
    formattedDocName += tempArray[i];
  }

  if (formattedDocName?.length > 15) {
    formattedDocName = `${formattedDocName?.slice(0, 13)}...${'.' + extension}`;
  } else {
    formattedDocName = docName;
  }

  return formattedDocName;
};

export const generateComplianceTableData = (arr) => {
  const complianceTableData = arr?.map((item) => {
    let tempObj = Object?.assign({
      eid: item?.eid,
      key: item?.eid,
      uploadedBy: {
        name: item?.createdByUser?.name,
        role: item?.createdByUser?.role,
        profilePic: item?.createdByUser?.profilePic,
      },
      docType: item?.category?.category,
      title: item?.title,
      location: item?.location?.name,
      expiryDate: item?.expiryDate,
      fileVersions: item?.files,
      createdAt: item?.createdAt,
      createdBy: item?.createdBy,
      isExpiring: isComplianceDocumentExpiring(
        item?.expiryDate,
        item?.reminder,
        'expired'
      ),
      taskDetails: {
        taskName: item?.metadata?.task ? item?.metadata?.task : '--',
        taskPhase: item?.metadata?.phase ? item?.metadata?.phase : '--',
      },
      docDetails: {
        name: item?.files?.[item?.files?.length - 1]?.name,
        size: item?.files?.[item?.files?.length - 1]?.fileSize
          ? formatBytes(item?.files?.[item?.files?.length - 1]?.fileSize)
          : '--',
      },
      reminder: item?.reminder,
    });

    return tempObj;
  });

  return complianceTableData;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
