import { gql } from '@apollo/client';
import { LauncherTaskStep } from '../common';
import { TASK_DEPENDENCY } from '../../../../types';

interface TemplateTask {
  eid: string;
  title: string;
  description: string;
  category: string;
  steps: LauncherTaskStep[];
  duration: number;
  docRequired: boolean;
  dependency: TASK_DEPENDENCY;
}

export interface TemplateContent {
  eid: string;
  category: string;
  tasks: TemplateTask[];
}

interface LauncherTemplate {
  name: 'big-leap' | 'quick-launch';
  contents: TemplateContent[];
}

export interface TemplateResponse {
  LauncherTemplatePagination: {
    items: Array<LauncherTemplate>;
  };
}

export const LAUNCHER_TEMPLATE = gql`
  query LauncherTemplate {
    LauncherTemplatePagination {
      items {
        name
        contents {
          eid
          category
          selected
          assignedType
          assignedUser
          meta
          trigger {
            triggerChoice
            activationTrigger {
              value
              unit
              order
              itemId
              dependentOn
              deadlineType
              deadlineValue
              deadlineUnit
            }
            deadlineTrigger {
              value
              unit
              order
              itemId
              dependentOn
            }
          }
          tasks {
            eid
            title
            description
            category
            duration
            completed
            dependency
            docRequired
            selected
            assignedType
            assignedUser
            meta
            steps {
              formId
              stepId
              title
              type
              sopId
              trainingId
            }
            trigger {
              triggerChoice
              activationTrigger {
                value
                unit
                order
                itemId
                dependentOn
                deadlineType
                deadlineValue
                deadlineUnit
              }
              deadlineTrigger {
                value
                unit
                order
                itemId
                dependentOn
              }
            }
            triggerTask
          }
        }
      }
    }
  }
`;
