import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';

import { FlexCard } from '../../../atoms';
import {
  useBusinessBoards,
  BUSINESS_LAUNCHER,
  Response,
} from '../../../sub-components/Launcher/launcher-config/common';
import { LauncherSetupForm } from '../config-editor/launcher-setup';
import BoardHeader from './BoardHeader';
import BoardsList from './BoardsList';
import AddNewBoard from './AddNewBoard';
import BoardLoader from './BoardLoader';

export { BUSINESS_LAUNCHER };
export type { Response };

interface IProps {}

const LauncherBoardContainer: FC<IProps> = () => {
  const { loading, data } = useBusinessBoards({
    fetchPolicy: 'network-only',
  });

  return (
    <Flex flex={1} flexDir='column' gap={6}>
      <BoardHeader emptyBoard={!!data?.length} />

      <BoardLoader isLoading={loading}>
        {data.length ? (
          <FlexCard flexDir='column' gap={6}>
            <BoardsList boardList={data} />

            <AddNewBoard />
          </FlexCard>
        ) : (
          <LauncherSetupForm />
        )}
      </BoardLoader>
    </Flex>
  );
};

export default LauncherBoardContainer;
