import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import { useLazyQuery, useMutation } from '@apollo/client';
import debounce from 'lodash.debounce';

import { SearchInput, SorterResult, Table, useChangeHandler } from 'atoms';
import { useSafeState, useUserDataSelector } from 'hooks';
import { toArray } from 'utils';

import { useArchivedColumns } from './useArchivedColumns';
import EmptyArchivedTemplates from './EmptyArchivedTemplates';
import {
  AuditTemplateItem,
  GET_AUDIT_TEMPLATES,
  TEMPLATE_OPERATION,
  TemplateOperation,
  TemplateResponse,
  TemplateVariable,
} from '../query/audits-listing.graphql';
import { getTemplateSortBy } from '../common/sorting.helper';
import { useRestoreConfirm } from './useRestoreConfirm';
import { useTemplatePreview } from '../template-preview';

const ArchivedTemplates: FC = () => {
  const toast = useToast({
    isClosable: true,
    position: 'top-right',
    duration: 3000,
  });
  const mounted = useRef(false);
  const entityId = useUserDataSelector((state) => state?.entityId);
  const [templateData, updateData] =
    useSafeState<TemplateResponse['AuditTemplatePagination']>();
  const [selectedRowKeys, setSelectedRowKeys] = useSafeState<React.Key[]>();
  const [sorter, setSorter] = useSafeState<SorterResult<AuditTemplateItem>>();
  const [currentPage, setCurrentPage] = useSafeState(1);
  const [searchQuery, setSearchQuery] = useSafeState('');

  const [auditTemplates, { loading }] = useLazyQuery<
    TemplateResponse,
    TemplateVariable
  >(GET_AUDIT_TEMPLATES, {
    fetchPolicy: 'network-only',
    onCompleted: (templates) => updateData(templates?.AuditTemplatePagination),
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    },
  });

  const inputVariables = useMemo(() => {
    const inputs: TemplateVariable = {
      page: currentPage,
      perPage: 10,
      sort: getTemplateSortBy(sorter),
      filter: {
        status: 'archived',
        entityId: entityId,
        templateType: 'business',
      },
    };

    if (searchQuery?.trim() && inputs['filter']) {
      inputs['filter']['query'] = searchQuery.trim();
    }

    return inputs;
  }, [searchQuery]);

  const [restore] = useMutation<never, TemplateOperation>(TEMPLATE_OPERATION, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Template has been successfully restored.',
      });
      auditTemplates({
        variables: inputVariables,
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Template Restoration Failed!',
      });
    },
  });

  const debouncedFetch = debounce(async (variables) => {
    auditTemplates({
      variables: variables,
    });
  }, 300);

  useEffect(() => {
    if (!mounted.current) {
      auditTemplates({
        variables: {
          page: 1,
          perPage: 10,
          sort: getTemplateSortBy(sorter),
          filter: {
            status: 'archived',
            entityId: entityId,
            templateType: 'business',
          },
        },
      });
      setTimeout(() => {
        mounted.current = true;
      });
    }
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      return undefined;
    }

    debouncedFetch(inputVariables);

    return () => debouncedFetch.cancel();
  }, [inputVariables]);

  const restoreConfirm = useRestoreConfirm();
  const templatePreview = useTemplatePreview();

  const actionHandler = (clickedItem: string, data: AuditTemplateItem) => {
    switch (clickedItem) {
      case 'viewTemplate':
        templatePreview({
          templateId: data.eid,
        });
        return void 0;
      case 'restore':
        return restoreConfirm({
          onRestore: async () => {
            await restore({
              variables: {
                templateIds: toArray(data.eid),
                operation: 'activate',
              },
            });
          },
        });
      default:
        // eslint-disable-next-line no-console
        console.log(clickedItem, data);
    }
  };

  const columns = useArchivedColumns({
    actionHandler: actionHandler,
  });

  const onChange = useChangeHandler<AuditTemplateItem>(
    (pagination, filters, _sorter, extra) => {
      switch (extra.action) {
        case 'paginate':
          setCurrentPage(pagination.current!);
          auditTemplates({
            variables: {
              ...inputVariables,
              page: pagination.current,
            },
          });
          break;
        case 'sort':
          setSorter(toArray(_sorter)[0]);
          auditTemplates({
            variables: {
              ...inputVariables,
              sort: getTemplateSortBy(_sorter),
            },
          });
          break;
        default:
          // eslint-disable-next-line no-console
          console.log(pagination, filters, _sorter, extra);
      }
    }
  );

  const onRowSection = (_selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(_selectedRowKeys);
  };

  return (
    <Flex flexDir='column' gap={4}>
      <SearchInput
        placeholder='Search'
        hideShortcuts
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <Table
        rowKey='eid'
        isLoading={loading}
        columns={columns}
        dataSource={templateData?.items}
        onChange={onChange}
        searchActive={!!searchQuery}
        pagination={{
          pageSize: 10,
          total: templateData?.count,
        }}
        emptyState={<EmptyArchivedTemplates />}
        // rowSelection={{
        //   selectedRowKeys,
        //   onChange: onRowSection,
        // }}
      />
    </Flex>
  );
};

export default ArchivedTemplates;
