import React, { FC, ReactNode } from 'react';
import {
  Box,
  Center,
  CloseButton,
  Flex,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useController, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Input } from '../../../../../../atoms';
import { useUserDataSelector } from '../../../../../../hooks';
import { ReactComponent as UploadIcon } from 'assets/images/upload.svg';
import { ReactComponent as FormIcon } from 'assets/images/sidebar/form.svg';
import { IDocumentInput } from './compliance.input';

interface IProps {
  subTitle?: ReactNode;
  accept: DropzoneOptions['accept'];
  type?: 'update' | 'reminder';
}

const DragDropComponent: FC<IProps> = ({ subTitle, accept, type }) => {
  const { t } = useTranslation(['common', 'chapter', 'setting', 'nexus']);

  const userId = useUserDataSelector((state) => state.eid);

  const { setValue } = useFormContext<IDocumentInput>();

  const { field, fieldState } = useController<IDocumentInput, 'file'>({
    name: 'file',
    rules: {
      required: 'Please select a file',
    },
  });

  const onFileDropped = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const rawFile = acceptedFiles[0];

      field.onChange({
        type: rawFile.type,
        name: rawFile.name,
        fileSize: rawFile.size,
        mimetype: rawFile.type,
        rawFile: rawFile,
        createdBy: userId,
      });
    }
  };
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onFileDropped,
    accept: accept,
    multiple: false, //discuss
    noClick: true,
    noKeyboard: true,
  });

  const isUploaded = !field.value?.rawFile;

  if (field.value) {
    return (
      <Flex gap={2}>
        <InputGroup width='unset' flex={1} size='lg' variant='auditOutline'>
          <InputLeftElement pointerEvents='none' zIndex={1}>
            <FormIcon />
          </InputLeftElement>
          <Input value={field?.value?.name} fontWeight='500' isReadOnly />

          {isUploaded ? null : (
            <InputRightElement>
              <CloseButton
                size='sm'
                onClick={() => setValue('file', null as never)}
              />
            </InputRightElement>
          )}
        </InputGroup>
        {isUploaded && type !== 'reminder' ? (
          <Button
            size='lg'
            variant='outline'
            paddingInline='20px'
            onClick={() => setValue('file', null as never)}
          >
            Upload new doc
          </Button>
        ) : null}
      </Flex>
    );
  }

  return (
    <Flex
      flexDir='column'
      gap={2}
      p={4}
      border='1px dashed #9A9FA5'
      borderRadius='12px'
      aria-invalid={fieldState.invalid}
      _invalid={{
        bg: 'rgba(255, 188, 153, 0.3)',
        color: 'red.500',
        borderWidth: 0,
        boxShadow: '0 0 0 1px #FF6A55',
      }}
    >
      <Box {...getRootProps()}>
        <input {...getInputProps()} />
        <Center h='400px' flexDirection='column' gap={1}>
          <UploadIcon width='30' height='30' />

          <Text mt={2} fontWeight={500} fontSize='15px' color='#212121'>
            <Trans
              t={t}
              i18nKey='nexus:documents.drapDropDocumentHere'
              components={{
                span: (
                  <Button
                    variant='link'
                    colorScheme='blue'
                    fontSize='15px'
                    fontWeight='600'
                    onClick={open}
                  />
                ),
              }}
            />
          </Text>
          <Text fontWeight={400} fontSize='12px' color='#212121B2'>
            {subTitle}
          </Text>
        </Center>
      </Box>
    </Flex>
  );
};

DragDropComponent.displayName =
  'displayName:sub-components/nexus/Compliance/Create/components/common/DragDropComponent';

export default DragDropComponent;
