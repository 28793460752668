import React, { FC } from 'react';
import { Button } from '@chakra-ui/react';
import { AddIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { ActionMenu } from '../../../../../../../ui-components';

import { ReactComponent as HandbookIcon } from '../../../../../../../assets/images/sidebar/handbook.svg';
import { ReactComponent as FormIcon } from '../../../../../../../assets/images/sidebar/form.svg';
import { ReactComponent as TrainingIcon } from '../../../../../../../assets/images/sidebar/training.svg';

export enum ResourceAction {
  ADD_FORM = 'add-form',
  ADD_CHAPTER = 'Add-chapter',
  ADD_TRAINING = 'add-training',
}

interface IProps {
  isDisabled?: boolean;
  haveValue?: boolean;
  handleClick?: (actions: ResourceAction) => void;
}

const AddResourceButton: FC<IProps> = ({
  isDisabled,
  haveValue,
  handleClick,
}) => {
  const { t } = useTranslation(['common', 'task', 'launcher']);

  return (
    <>
      <ActionMenu
        menuData={[
          {
            name: 'Add form',
            value: ResourceAction.ADD_FORM,
            icon: <FormIcon color='#272B30' />,
            normalIcon: 'chakraUIIcon',
          },
          {
            name: 'Add chapters',
            value: ResourceAction.ADD_CHAPTER,
            icon: <HandbookIcon color='#272B30' />,
            normalIcon: 'chakraUIIcon',
          },
          {
            name: 'Add training paths',
            value: ResourceAction.ADD_TRAINING,
            icon: <TrainingIcon color='#272B30' />,
            normalIcon: 'chakraUIIcon',
          },
        ]}
        clickedItem={(_, value) => handleClick?.(value as never)}
      >
        <Button
          size='lg'
          fontSize='14px'
          colorScheme='blue'
          minW='unset'
          isDisabled={!haveValue || isDisabled}
          borderRadius='12px'
          leftIcon={<AddIcon />}
          rightIcon={<ChevronDownIcon boxSize='22px' />}
        >
          {t('launcher:addFromResources')}
        </Button>
      </ActionMenu>
    </>
  );
};

export default AddResourceButton;
