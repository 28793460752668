import React, { FC } from 'react';
import {
  AlertDialogCloseButton,
  Center,
  Flex,
  useModalContext,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import { ActionButton, BoxHeader, ConfirmConfig } from '../../../ui-components';
import AsyncAlertEvent, { PromptAction } from './async-alert-event';
import { HeaderColors } from '../../../shared/headerColors';

interface IProps {
  callback: (ok: boolean) => void;
}

const Content: FC<IProps> = ({ callback }) => {
  const { onClose } = useModalContext();

  const onSaveClick = async () => {
    await AsyncAlertEvent.sendData({
      action: PromptAction.SAVE_CONFIG,
    });
    callback(false);
  };

  return (
    <Center flexDir='column' gap={5} pt={4}>
      <Center boxSize='56px' borderRadius='full' bg='#FFD88D80'>
        <FontAwesomeIcon
          icon={faTriangleExclamation as IconProp}
          color='#F4A305'
          fontSize='1.5em'
        />
      </Center>

      <Flex textAlign='center' color='#33383F'>
        Unsaved changes will be lost if you leave this page. Please save before
        exiting.
      </Flex>

      <Flex flexDir='column' w='full' gap={3}>
        <ActionButton
          size='lg'
          fontSize='15px'
          actionFn={onSaveClick}
          close={onClose}
          colorScheme='blue'
        >
          Save changes
        </ActionButton>

        <ActionButton
          size='lg'
          fontSize='15px'
          actionFn={() => callback(true)}
          close={onClose}
          variant='outline'
        >
          Leave without saving
        </ActionButton>
      </Flex>
    </Center>
  );
};

export default function UnsavedConfigAlert(
  callback: (ok: boolean) => void
): Partial<ConfirmConfig> {
  return {
    title: (
      <Flex justify='space-between' gap={3}>
        <BoxHeader
          color={HeaderColors.Orange}
          title={'Please note'}
          fontSize='18px'
        />
        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
          onClick={() => callback(false)}
        />
      </Flex>
    ),

    content: <Content callback={callback} />,

    isCentered: true,

    contentProps: {
      minWidth: '600px',
      borderRadius: '12px',
      paddingY: '12px',
    },
  };
}
