import { useLazyQuery } from '@apollo/client';
import {
  GET_AUDITS_LIST,
  GET_AUDIT_SESSIONS_LIST,
} from '../../audit-tab.graphql';
import {
  AuditPaginateResponse,
  AuditPaginateVariables,
  AuditSessionPaginateResponse,
  AuditSessionPaginateVariables,
  ServiceLayer,
} from '../types';

export const useServiceLayer = (): ServiceLayer => {
  const [getAuditsList, getAuditsListResult] = useLazyQuery<
    AuditPaginateResponse,
    AuditPaginateVariables
  >(GET_AUDITS_LIST, {
    fetchPolicy: 'network-only',
  });

  const [getAuditSessionList, getAuditSessionListResult] = useLazyQuery<
    AuditSessionPaginateResponse,
    AuditSessionPaginateVariables
  >(GET_AUDIT_SESSIONS_LIST, {
    fetchPolicy: 'network-only',
  });

  return {
    auditsList: { getAuditsList, getAuditsListResult },
    auditsSessionList: { getAuditSessionList, getAuditSessionListResult },
  };
};
