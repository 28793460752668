import { Box, Flex, Text } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { LTaskCategoryEntity } from 'sub-components/Launcher/launcher-config/common/launcher-config.graphql';
import LauncherLocationTask from '../LauncherLocationTask';
import { GroupedTask, reorderTasks } from './GroupByPhase';
import { LocationPhaseDetails } from '../LauncherLocation.graphql';

interface IProps {
  keyName: string;
  getAssigneeDetailsById: (userId: string) => {
    name: string;
    role: string;
  };
  groupedTasks: GroupedTask;
  launchId?: string;
  locationId: string;
  categories: LTaskCategoryEntity[];
  refetchData?: () => void;
  phaseDetails: LocationPhaseDetails[];
}

const GroupByAssignee: FC<IProps> = ({
  categories,
  getAssigneeDetailsById,
  groupedTasks,
  launchId,
  locationId,
  keyName,
  refetchData,
  phaseDetails,
}) => {
  return (
    <Flex flexDirection='column' gap={4}>
      <Flex justifyContent='space-between' alignItems='center'>
        <Flex gap={3}>
          <Flex flexDirection='column' gap={1}>
            <Flex alignItems='center' gap={3}>
              <Text
                color='#111315'
                fontSize={16}
                fontWeight={600}
                lineHeight='24px'
              >
                {getAssigneeDetailsById(keyName).name}
              </Text>
            </Flex>

            {getAssigneeDetailsById(keyName).name !==
              getAssigneeDetailsById(keyName).role && (
              <Box
                as='span'
                color='#111315'
                fontSize={12}
                fontWeight={500}
                lineHeight='12px'
              >
                {getAssigneeDetailsById(keyName).role}
              </Box>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDirection='column' gap={2}>
        {reorderTasks(groupedTasks[keyName]?.tasks || []).map((task, index) => (
          <LauncherLocationTask
            key={index}
            task={task}
            launchId={launchId}
            locationId={locationId}
            categories={categories}
            refetchData={refetchData}
            phaseDetails={phaseDetails}
            goLiveDate={groupedTasks[keyName].goLiveDate}
          />
        ))}
      </Flex>
    </Flex>
  );
};

GroupByAssignee.displayName =
  'displayName:sub-components/Launcher/dashboard/Location/GroupBy/GroupByAssignee';

export default GroupByAssignee;
