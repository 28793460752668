import { StateCreator } from 'zustand';

export interface StateDrawer {
  stateDrawer: {
    loading: boolean;
    updateLoading: (value: boolean) => void;
  };
}

export const stateDrawerSlice: StateCreator<StateDrawer> = (set) => ({
  stateDrawer: {
    loading: false as boolean,
    updateLoading: (value) =>
      set((state) => ({
        stateDrawer: { ...state?.stateDrawer, loading: value },
      })),
  },
});
