import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Button,
  Text,
  Flex,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import { ActionButton } from 'ui-components';

interface IProps {
  handleNotificationSort: any;
}

const SortByFilter: FC<IProps> = ({ handleNotificationSort }) => {
  const [selectedSort, setSelectedSort] = useState('');
  return (
    <Box>
      <Popover>
        <PopoverTrigger>
          <Button
            fontSize={'14px'}
            color={'#6F767E'}
            _active={'trasnparent'}
            _hover={'trasnparent'}
            height={'30px'}
            backgroundColor={'transparent'}
            outlineColor={'#EFEFEF'}
            rightIcon={<FontAwesomeIcon icon={faChevronDown as IconProp} />}
          >
            Sort By {selectedSort && ':'} &nbsp;
            <Text>{selectedSort === 'newest' ? 'Latest First' : ''}</Text>
            <Text>{selectedSort === 'oldest' ? 'Oldest First' : ''}</Text>
          </Button>
        </PopoverTrigger>
        <PopoverContent borderRadius={'16px'} w={'220px'}>
          <PopoverArrow />
          {/* <PopoverCloseButton /> */}
          {/* <PopoverHeader>Confirmation!</PopoverHeader> */}
          <PopoverBody>
            <Flex gap={2} flexDir={'column'}>
              <Text
                backgroundColor={
                  selectedSort === 'newest' ? '#EFEFEF' : 'white'
                }
                color={selectedSort === 'newest' ? 'black' : '#6F767E'}
                onClick={() => {
                  setSelectedSort('newest');
                  handleNotificationSort('_ID_DESC');
                }}
                cursor={'pointer'}
                borderRadius='12px'
                padding='6px 12px'
                _hover={{
                  bg: '#EFEFEF',
                  color: 'black',
                }}
                fontWeight={600}
              >
                Latest first
              </Text>
              <Text
                backgroundColor={
                  selectedSort === 'oldest' ? '#EFEFEF' : 'white'
                }
                color={selectedSort === 'oldest' ? 'black' : '#6F767E'}
                onClick={() => {
                  setSelectedSort('oldest');
                  handleNotificationSort('_ID_ASC');
                }}
                cursor={'pointer'}
                borderRadius='12px'
                padding='6px 12px'
                _hover={{
                  bg: '#EFEFEF',
                  color: 'black',
                }}
                fontWeight={600}
              >
                Oldest first
              </Text>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default SortByFilter;
