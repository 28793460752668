import React, { FC, useMemo } from 'react';
import {
  Center,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faEllipsisStroke,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { ReactComponent as EditDarkIcon } from '../../../../../assets/images/edit-dark.svg';
import { ReactComponent as UnlockIcon } from '../../../../../assets/images/unlock.svg';
import { ReactComponent as TrashRedIcon } from '../../../../../assets/images/trash-red.svg';

import { MenuItemWrapper } from '../../../../../ui-components';
import {
  useLauncherConfigContext,
  useLauncherConfirm,
  useLauncherWarning,
} from '../../common';
import { usePhaseSetting } from '../../common/phase-details';

interface IPItem {
  menu: {
    name: string;
    icon: any;
    value: string;
    textColor?: string;
    isDisabled?: boolean;
  };
}

const ActionItem: FC<IPItem> = ({ menu }) => {
  const isFaIcon = !!menu?.icon?.iconName;
  const SvgIcon = isFaIcon ? React.Fragment : menu.icon || React.Fragment;

  return (
    <>
      <Center width='fit-content' boxSize='22px'>
        {isFaIcon ? (
          <FontAwesomeIcon
            icon={menu?.icon}
            fontSize='18px'
            color={menu?.textColor}
          />
        ) : (
          <SvgIcon width='22' height='22' color={menu?.textColor} />
        )}
      </Center>

      <span
        style={{
          fontSize: '15px',
          fontWeight: '500',
          lineHeight: '24px',
          color: menu?.textColor || '#1A1D1F',
        }}
      >
        {menu.name}
      </span>
    </>
  );
};

interface IProps {
  sectionIndex: number;
  isLastSection?: boolean;
  isLocked?: boolean;
}

const SectionAction: FC<IProps> = ({
  sectionIndex,
  isLastSection,
  isLocked,
}) => {
  const phaseSetting = usePhaseSetting();
  const unblockConfirm = useLauncherConfirm();
  const warning = useLauncherWarning();
  const action = useLauncherConfigContext((state) => {
    return {
      openAddPhase: state.openAddPhase,
      deletePhase: state.deletePhase,
      movePhase: state.movePhase,
      unlockPhase: state.unlockPhase,
      getSectionData: state.getSectionData,
      haveLocation: !!state.config?.locations,
    };
  });

  const menuData = useMemo(() => {
    return [
      {
        name: 'Edit phase details',
        icon: EditDarkIcon,
        value: 'edit',
      },
      ...(isLocked
        ? [
            {
              name: 'Unlock phase',
              icon: UnlockIcon,
              value: 'unlock',
            },
          ]
        : []),
      {
        name: 'Create new phase',
        icon: faPlus,
        value: 'create',
      },
      {
        name: 'Move to left',
        icon: faChevronLeft,
        value: 'move-left',
        isDisabled: sectionIndex === 0,
      },
      {
        name: 'Move to right',
        icon: faChevronRight,
        value: 'move-right',
        isDisabled: isLastSection,
      },
      {
        name: 'Delete phase',
        icon: TrashRedIcon,
        value: 'delete',
        textColor: '#FF6A55',
      },
    ];
  }, [sectionIndex, isLastSection, isLocked]);

  const itemClickHandler = async (
    menu: IPItem['menu'],
    callback: () => void
  ) => {
    if (menu.isDisabled) {
      return;
    }
    switch (menu.value) {
      case 'edit':
        phaseSetting({
          sectionIndex: sectionIndex,
        });
        break;
      case 'unlock':
        unblockConfirm({
          title: 'This phase has trigger dependencies!',
          description:
            'Deleting it will unlock this phase for all active locations and will remove all triggers associated with it.',
          buttonText: 'Yes, unlock phase for all locations',
          onConfirm: () => action.unlockPhase(sectionIndex),
        });
        break;
      case 'create':
        action.openAddPhase(true);
        document.querySelector('#add-launcher-section-btn')?.scrollIntoView({
          block: 'nearest',
        });
        break;
      case 'move-left':
        action.movePhase(sectionIndex, sectionIndex - 1);
        break;
      case 'move-right':
        action.movePhase(sectionIndex, sectionIndex + 1);
        break;
      case 'delete':
        {
          const section = action.getSectionData(sectionIndex);
          const isDefault =
            !section?.trigger || section?.trigger?.triggerChoice === 'default';

          if (action?.haveLocation) {
            warning({
              header: 'Active locations in chosen phase',
              title:
                'There are active locations currently working on this phase',
              description:
                'If this phase is deleted, the active locations will lose their progress. ',
              buttonText: 'Delete phase',
              onConfirm: () => action.deletePhase(sectionIndex),
            });
          } else if (isDefault) {
            unblockConfirm({
              description:
                'Deleting this phase will result it complete removal of the phase and its progress.',
              buttonText: 'Yes, delete',
              onConfirm: () => action.deletePhase(sectionIndex),
            });
          } else {
            unblockConfirm({
              title: 'This phase has trigger dependencies!',
              description:
                'Deleting it will remove all triggers associated with it.',
              buttonText: 'Yes, delete',
              onConfirm: () => action.deletePhase(sectionIndex),
            });
          }
        }
        break;
      default:
        console.log(menu);
    }
    // clickedItem?.(menu.name, menu.value);
    callback();
  };

  return (
    <React.Fragment>
      <Popover strategy='fixed' placement='bottom-start'>
        {({ onClose }) => (
          <>
            {/*// @ts-ignore */}
            <PopoverTrigger>
              <Flex alignSelf='start' pt={0.5} cursor='pointer' mr={1}>
                <FontAwesomeIcon
                  fontSize='1.5rem'
                  icon={faEllipsisStroke as IconProp}
                  color='#6F767E'
                />
              </Flex>
            </PopoverTrigger>
            <PopoverContent
              boxShadow='md'
              width='282px'
              borderRadius='12px'
              _focus={{
                outline: '2px solid transparent',
                outlineOffset: '2px',
              }}
            >
              <PopoverArrow />
              <PopoverBody px={4}>
                <Flex flexDir='column' py='8px' gap={1}>
                  {menuData.map((menu, index) => (
                    <MenuItemWrapper
                      key={index}
                      menu={menu}
                      onClick={(e) => {
                        e.stopPropagation();
                        return itemClickHandler(menu, onClose);
                      }}
                      aria-disabled={!!menu.isDisabled}
                      _disabled={{
                        cursor: 'not-allowed',
                        opacity: 0.4,
                      }}
                    >
                      <ActionItem menu={menu} />
                    </MenuItemWrapper>
                  ))}
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </React.Fragment>
  );
};

export default SectionAction;
