import { PhaseTask } from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import { ILocationLaunchTask } from 'sub-components/Launcher/location-owner/types';

export const convertTaskData = (task: ILocationLaunchTask): PhaseTask => {
  let phaseTaskData: PhaseTask = {
    assignedType: task?.assignedType as 'location' | 'user' | undefined,
    assignedUser: task?.assignedUsers,
    category: task?.taskCategoryEid,
    completedAt: task?.completedAt as unknown as Date,
    description: task?.description,
    dueDate: task?.dueDate as unknown as Date,
    eid: task?.eid,
    files: task?.files?.map((file) => ({
      createdAt: file?.createdAt,
      createdBy: file?.createdBy?.eid,
      fileSize: file?.fileSize,
      mimetype: file?.mimetype,
      name: file?.name,
      type: file?.type,
      updatedAt: file?.updatedAt,
      url: file?.url,
    })),
    isCompleted: task?.isCompleted,
    phaseName: task?.phase,
    startDate: task?.startDate as unknown as Date,
    status: task?.status,
    steps: task?.steps?.map((step) => ({
      formId: step?.formId || '',
      sopId: step?.sopId || '',
      stepId: step?.stepId || '',
      title: step?.title || '',
      trainingId: step?.trainingId || '',
      type: step?.type,
      completedAt: step?.completedAt || '',
      completedBy: step?.completedBy || '',
    })),
    title: task?.title,
    trigger: task?.triggerDetails,
    triggerDetails: {
      ...task?.triggerDetails,
      activationOffset: task?.triggerDetails?.activationOffset || 0,
      deadlineOffset: task?.triggerDetails?.deadlineOffset || 0,
      triggerItemId: task?.triggerDetails?.triggerItemId || '',
      activationOrder: task?.triggerDetails?.activationOrder || '',
      deadlineOrder: task?.triggerDetails?.deadlineOrder || '',
      deadlineType: task?.triggerDetails?.deadlineType || '',
      dependantOn: task?.triggerDetails?.dependantOn || '',
      startDate: task?.triggerDetails?.startDate || '',
      dueDate: task?.triggerDetails?.dueDate || '',
    },
    docRequired: task?.docRequired,
    isRequired: task?.isRequired,
    launcherId: task?.launchId,
    phase: task?.phase,
    phaseEid: task?.phaseEid,
    taskScheduleMessage: '',
    unlockMessage: '',
  };
  return phaseTaskData;
};
