import { useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';
// import { CometChat } from '@cometchat-pro/chat';
import {
  authTokenVar,
  chatStatus,
  client,
  UserData,
  userObj,
} from 'sop-commons/src/client/clientFactory';
import { bundleObj, roleObj } from '../ui-components/DashboardMenu';
import { GET_USER } from '../pages/Login/login.graphql';
import { clearAvatarCache } from '../utils/avatar';
import { checkFirstTimeFlag } from 'sub-components/NotificationCenter/helper';

type useLogoutT = () => () => Promise<void>;

const useLogout: useLogoutT = () => {
  const { shutdown } = useIntercom();

  return useCallback(async () => {
    const delightreeEmail = localStorage.getItem('delightree-email');
    const archiveValueForNotificationIntroFlag = checkFirstTimeFlag();
    localStorage.clear();
    localStorage.setItem(
      //maintaining the firstTimeStateForNotificationsIntoModal!
      // Edge case-> in the same browser, if different user logins again then the flag should also be cleared,
      //  but i canot store username in localStorage right?
      'firstTimeFeatureIntro',
      archiveValueForNotificationIntroFlag
    );
    if (delightreeEmail) {
      setTimeout(() => {
        localStorage.setItem('delightree-email', delightreeEmail);
      });
    }

    authTokenVar('');
    userObj({} as UserData);
    chatStatus(false);
    bundleObj({});
    roleObj({});
    shutdown();
    // userflow.reset();
    client.writeQuery({
      query: GET_USER,
      data: {
        user: {},
      },
    });
    await client.clearStore();
    // try {
    //   if (CometChat.isInitialized() && CometChat.) {
    //     await CometChat.logout().then(
    //       () => console.log('Logout completed successfully'),
    //       (error) => console.log('Logout failed with exception: ', error)
    //     );
    //   }
    // } catch (e) {
    //   console.log('cometchat logout error ', e);
    // }
    clearAvatarCache();
  }, [shutdown]);
};

export default useLogout;
