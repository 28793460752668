import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  useMergeRefs,
} from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { gql, useApolloClient } from '@apollo/client';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';

import { Dropdown, FormInput } from 'atoms';
import { BoxHeader } from 'ui-components';
import { Loader } from 'sub-components';
import { GOOGLE_MAPS_API_KEY } from 'utils/constants';
import { toArray } from 'utils/utils';

import TitleHeader from 'sub-components/CardEditor/TitleHeader';

import { IFormInput } from '../AddLocation/add-location.types';
import {
  getLocationByZipCode,
  getTimezoneForLocation,
} from 'shared/graphql/SharedGraphql';

const getAddress = (data: Record<string, string>) => {
  const address: string[] = [];
  if (data.street_number) {
    address.push(data.street_number);
  }
  if (data.route) {
    address.push(data.route);
  }
  if (data.sublocality) {
    address.push(data.sublocality);
  }
  return address.join(', ');
};

interface TimeZoneData {
  dstOffset: number;
  rawOffset: number;
  status: string;
  timeZone: string;
  timeZoneId: string;
  timeZoneName: string;
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface IProps {
  isDisabled?: boolean;
}

const Address: FC<IProps> = ({ isDisabled }) => {
  const { t } = useTranslation(['location']);

  const client = useApolloClient();

  const [isFetching, setIsFetching] = useState(false);

  const { control, setValue, watch } = useFormContext<IFormInput>();

  const zipCode = watch('zipCode');

  const timezonesDataWatch = useWatch<IFormInput, 'timezonesData'>({
    control,
    name: 'timezonesData',
  });

  const timezoneOptions = useMemo(() => {
    return timezonesDataWatch?.map((t) => ({ label: t, value: t }));
  }, [timezonesDataWatch]);

  const fetchLocationDataFromZipCode = async (zipCode: string) => {
    setIsFetching(true);
    try {
      const locationData = await getLocationByZipCode(client, zipCode);

      if (locationData) {
        setValue('country', locationData?.country || '');
        setValue('city', locationData?.city || '');
        setValue('state', locationData?.state || '');

        const timezoneData = await getTimezoneForLocation(
          client,
          locationData.coordinates
        );
        if (timezoneData) {
          setValue('timezone', timezoneData);
        }
      }
    } catch (error) {
      console.error('Error fetching location data:', error);
    } finally {
      setIsFetching(false);
    }
  };

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_MAPS_API_KEY,
    onPlaceSelected: async (place) => {
      const newAddress = toArray<AddressComponent>(
        place?.address_components
      ).reduce<Record<string, string>>((previousValue, currentValue) => {
        (currentValue.types || []).forEach((value) => {
          if (value !== 'political' && !previousValue[value]) {
            previousValue[value] = currentValue.long_name;
          }
        });

        return previousValue;
      }, {});
      setValue('state', newAddress.administrative_area_level_1);
      setValue('city', newAddress.locality);
      setValue('zipCode', newAddress.postal_code);
      setValue('country', newAddress.country);
      setValue('address', getAddress(newAddress));

      const location = place?.geometry?.location;
      setIsFetching(true);
      try {
        const timezoneData = await getTimezoneForLocation(client, {
          lat: location?.lat()?.toString(),
          lng: location?.lng()?.toString(),
        });
        if (timezoneData) {
          setValue('timezone', timezoneData);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsFetching(false);
      }
    },
    options: {
      // types: ['(regions)'],
      types: ['address'],
    },
  });

  return (
    <Flex bg='white' borderRadius='8px' p={8} gap='20px' flexDir='column'>
      <BoxHeader
        fontSize='16px'
        color='#CABDFF'
        title={t('location:address')}
      />

      <Grid templateColumns='repeat(3, 1fr)' gap='20px'>
        <Controller
          control={control}
          name='address'
          // rules={{
          //   required: t('location:validation.addressRequired'),
          //   validate: (value) => {
          //     if (value?.trim()?.length === 0) {
          //       return t('location:validation.addressInvalid');
          //     }
          //   },
          // }}
          render={({ field, fieldState }) => (
            <FormControl
              isDisabled={isDisabled}
              gridColumn='1/3'
              isInvalid={!!fieldState.error}
              isReadOnly={isFetching}
            >
              <TitleHeader title={t('location:address')} />
              <Flex height={2} />
              <FormInput
                size='lg'
                variant='outline'
                placeholder={t('location:placeholder.address')}
                {...field}
                ref={useMergeRefs(ref, field.ref)}
                rightIcon={
                  isFetching ? <Loader size='sm' thickness='2px' /> : undefined
                }
              />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name='zipCode'
          rules={{
            required: {
              value: !isDisabled,
              message: 'Which area is the location in?',
            },
            validate: (value) => {
              if (value && value?.trim()?.length === 0) {
                return t('location:validation.zipCodeInvalid');
              }
            },
          }}
          render={({ field, fieldState }) => (
            <FormControl isDisabled={isDisabled} isInvalid={!!fieldState.error}>
              <TitleHeader title={t('location:zipCode')} isRequired />
              <FormInput
                mt={2}
                size='lg'
                variant='outline'
                placeholder={t('location:placeholder.addressZipCode')}
                {...field}
                onBlur={async (e) => {
                  field.onBlur();
                  if (e.target.value) {
                    await fetchLocationDataFromZipCode(e.target.value);
                  }
                }}
              />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name='country'
          // rules={{
          //   required: {
          //     value: !isDisabled,
          //     message: t('location:validation.countryRequired'),
          //   },
          // }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={!!fieldState.error}>
              <TitleHeader title={t('location:country')} />
              <FormInput
                mt={2}
                size='lg'
                variant='outline'
                placeholder={t('location:placeholder.addressCountry')}
                {...field}
              />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name='city'
          rules={{
            required: {
              value: !isDisabled,
              message: 'Which city will the franchise be in?',
            },
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={!!fieldState.error}>
              <TitleHeader title={t('location:city')} isRequired />
              <FormInput
                mt={2}
                size='lg'
                variant='outline'
                placeholder={t('location:placeholder.addressCity')}
                {...field}
              />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name='state'
          rules={{
            required: {
              value: !isDisabled,
              message: 'Which state will the franchise be in?',
            },
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={!!fieldState.error}>
              <TitleHeader title={t('location:state')} isRequired />
              <FormInput
                mt={2}
                size='lg'
                variant='outline'
                placeholder={t('location:placeholder.addressState')}
                {...field}
              />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name='timezone'
          rules={{
            required: {
              value: !isDisabled,
              message: 'Timezone is required',
            },
          }}
          render={({ field, fieldState }) => (
            <FormControl gridColumn='1/4' isInvalid={!!fieldState.error}>
              <TitleHeader title={t('location:timezone')} isRequired />
              <Dropdown
                size='lg'
                isLoading={isFetching}
                isDisabled={isFetching || !zipCode}
                value={field?.value}
                placeholder='Select timezone'
                onChange={(value) => field.onChange(value)}
                options={timezoneOptions.map((option) => ({
                  label: option?.label,
                  value: option?.value,
                }))}
              />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          )}
        />
      </Grid>
    </Flex>
  );
};

Address.displayName = 'displayName:pages/LocationsNew/Components/Address';

export default Address;
