import React, { FC, useEffect, useRef } from 'react';
import { Box, CloseButton, Flex, useBoolean } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useLocation } from 'react-router-dom';
import { Button, FlexCard } from '../../../atoms';
import {
  LauncherSetupForm,
  SetupFormRef,
} from '../config-editor/launcher-setup';
import { AppRoute, useHistory } from '../../../routes';

interface IProps {}

const AddNewBoard: FC<IProps> = () => {
  const [isOpen, action] = useBoolean(false);
  const setupRef = useRef<SetupFormRef>(null);
  const state = useLocation().state;
  const history = useHistory();

  useEffect(() => {
    if (state) {
      action.on();
      setTimeout((value) => setupRef.current?.setTitle(value), 1000, state);
      history.replace({
        pathname: AppRoute.LAUNCHER_BOARDS,
      });
    }
  }, []);

  if (!isOpen) {
    return (
      <Button
        size='lg'
        variant='outline'
        leftIcon={<AddIcon />}
        borderRadius='12px'
        onClick={action.on}
      >
        Create new board
      </Button>
    );
  }

  return (
    <FlexCard
      flexDir='column'
      bg='#FCFCFC'
      border='1px solid #EFEFEF'
      paddingX={5}
      gap={6}
    >
      <Flex align='center' justify='space-between' paddingX={3}>
        <Box fontSize='15px' fontWeight={600}>
          Create new board
        </Box>

        <CloseButton boxSize={6} onClick={action.off} />
      </Flex>

      <LauncherSetupForm ref={setupRef} paddingX={4} />
    </FlexCard>
  );
};

export default AddNewBoard;
