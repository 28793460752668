import { SystemStyleInterpolation } from '@chakra-ui/theme-tools';
import Input from './Input';

const variants: Record<string, SystemStyleInterpolation> = {
  auditOutline: (props) => Input.variants.auditOutline(props).field || {},
};

export default {
  variants,
};
