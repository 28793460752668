import React, { FC } from 'react';
import { ReactComponent as NotFound } from 'assets/images/nexus/compliance/not_found.svg';
import { ReactComponent as EmptyTable } from 'assets/images/nexus/compliance/table_empty_state.svg';
import { ReactComponent as EmptyStateLO } from 'assets/images/empty-state/dashboard-single-location.svg';
import { Flex, Text } from '@chakra-ui/react';
import { ActionButton } from 'ui-components';
import { useComplianceUpload } from 'sub-components/nexus/Compliance';
import { AddIcon } from '@chakra-ui/icons';
import { Authorize, AuthRole } from 'authorization';
import { useParams } from 'react-router-dom';
import { useUserDataSelector } from 'hooks';

interface IProps {
  searchFieldText?: string;
}

interface IParams {
  locationId: string;
}

const DefaultEmptyState: FC<IProps> = ({ searchFieldText }) => {
  const complianceUpload = useComplianceUpload();
  const { locationId } = useParams<IParams>();

  const { userAuthRole } = useUserDataSelector((state) => ({
    userAuthRole: state?.authRole,
  }));

  let title, description;
  title = 'Add your documents';
  description = 'Documents will be displayed here as soon as you upload them';

  if (searchFieldText) {
    title = `No results found for '${searchFieldText}'`;
    description = 'Please check the spelling and try again.';
  } else if (!searchFieldText && userAuthRole === AuthRole?.LOCATION_OWNER) {
    title = `No documents added`;
    description =
      'Documents will be displayed here as soon as your superadmin adds them.';
  } else {
    title = 'Add your documents';
    description = 'No documents available for any location';
  }

  const renderEmptyStateIcon = () => {
    if (searchFieldText) {
      return <NotFound />;
    } else if (!searchFieldText && userAuthRole === AuthRole?.LOCATION_OWNER) {
      return <EmptyStateLO />;
    }
    return <EmptyTable />;
  };

  return (
    <Flex alignItems={'center'} justifyContent={'center'} h={'70vh'}>
      <Flex alignItems={'center'} flexDir={'column'}>
        {renderEmptyStateIcon()}
        <Text mt={'20px'} fontSize={'20px'} fontWeight={600} color={'#33383F'}>
          {title}
        </Text>
        <Text mt={'8px'} fontSize={'14px'} fontWeight={500} color={'#6F767E'}>
          {description}
        </Text>
        <Authorize
          permittedFor={'user'}
          permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
        >
          {!searchFieldText &&
            (userAuthRole === AuthRole?.SUPER_ADMIN ||
              userAuthRole === AuthRole?.ADMIN) && (
              <ActionButton
                mt={'20px'}
                colorScheme='blue'
                leftIcon={<AddIcon />}
                actionFn={() =>
                  complianceUpload({
                    preDefinedLocationId: locationId,
                  })
                }
              >
                Add doc
              </ActionButton>
            )}
        </Authorize>
      </Flex>
    </Flex>
  );
};

export default DefaultEmptyState;
