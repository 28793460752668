import React, { FC } from 'react';
import { Box, Flex, keyframes, Text, Tooltip } from '@chakra-ui/react';
import { Control, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from '../../../../routes';
import { Button } from '../../../../atoms';
import { headerConfig } from '../../common';
import { useMediaIsUploading } from './useMediaIsUploading';
import ErrorContent from '../summary/ErrorContent';

import { ReactComponent as BackIcon } from '../../../../assets/images/chevronBackward.svg';
import { ReactComponent as SyncIcon } from '../../../../assets/images/sync-sharp.svg';
import { IFormInput } from '../types';

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

interface IProps {
  title: string;
  control: Control<IFormInput>;
}

const QuesSectionHeader: FC<IProps> = ({ title, control }) => {
  const { t } = useTranslation('audit');
  const history = useHistory();
  const isSubmitting = useFormState().isSubmitting;
  const errors = useFormState({
    control: control,
  }).errors;
  const mediaUploading = useMediaIsUploading();

  return (
    <Flex flexDir='column' gap={2} {...headerConfig}>
      <Tooltip
        placement='end'
        hasArrow
        label={t('uploadInProgressWarning')}
        offset={[0, 16]}
        isDisabled={!mediaUploading}
      >
        <Box alignSelf='start' height='17px'>
          <Button
            fontSize='14px'
            fontWeight={500}
            width='max-content'
            variant='link'
            leftIcon={<BackIcon width='16' height='16' />}
            _hover={{
              textDecoration: 'none',
            }}
            onClick={history.goBack}
            isDisabled={mediaUploading}
          >
            Back to My Audits
          </Button>
        </Box>
      </Tooltip>

      <Flex align='center' justify='space-between' minHeight='40px'>
        <Text fontWeight={600} fontSize='28px'>
          {title}
        </Text>
        <Flex flex={1} paddingX={4}>
          <ErrorContent errors={errors} />
        </Flex>
        <Flex align='center' gap={3}>
          <Flex
            hidden={!mediaUploading || isSubmitting}
            align='center'
            gap='6px'
            fontSize='12px'
            color='#6F767E'
          >
            <Box animation={`${spin} 1s linear infinite`} boxSize={4}>
              <SyncIcon />
            </Box>
            Uploading assets
          </Flex>

          <Flex
            hidden={!isSubmitting}
            align='center'
            gap='6px'
            fontSize='12px'
            color='#6F767E'
          >
            <Box animation={`${spin} 1s linear infinite`} boxSize={4}>
              <SyncIcon />
            </Box>
            Syncing Changes
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default React.memo(QuesSectionHeader);
