import React, { FC } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { ReactComponent as RocketIcon } from '../../../../assets/images/rocket.svg';

const pseudoStyles = {
  content: "''",
  height: '4px',
  background: '#B5E4CA',
  position: 'absolute',
  // width: 'calc(50% - 24px)',
  width: 'calc(100% - 28px)',
  // top: '22px',
  top: '12px',
};

export const GoLive: FC = () => {
  return (
    <Flex
      flexDir='column'
      align='end'
      gap={2}
      position='relative'
      flex={1}
      _notFirst={{
        ':before': {
          ...pseudoStyles,
          left: 0,
        },
      }}
    >
      <Center
        bg='#8E59FF'
        boxSize='28px'
        borderRadius='full'
        border='4px solid #CABDFF'
        fontSize='15px'
        fontWeight={600}
        color='#FFFFFF'
      >
        <RocketIcon />
      </Center>

      <Box fontSize='15px' fontWeight='500' whiteSpace='nowrap'>
        Go- live!
      </Box>
    </Flex>
  );
};

interface IProps {
  label: string;
  index: number | string;
  isSelected?: boolean;
  flex?: number;
}

const PhaseName: FC<IProps> = ({ label, index, isSelected, ...rest }) => {
  return (
    <Flex
      flexDir='column'
      gap={2}
      position='relative'
      {...rest}
      _notLast={{
        ':after': {
          ...pseudoStyles,
          right: 0,
        },
      }}
    >
      <Center
        bg='#83BF6E'
        boxSize='28px'
        borderRadius='full'
        border='4px solid #B5E4CA'
        fontSize='15px'
        fontWeight={600}
        color='#FFFFFF'
        aria-selected={isSelected}
        _selected={{
          bg: '#2A85FF',
          borderColor: '#B1E5FC',
        }}
      >
        {index}
      </Center>

      <Box
        minWidth='78px'
        fontSize='15px'
        fontWeight='500'
        paddingRight={5}
        isTruncated
      >
        {label}
      </Box>
    </Flex>
  );
};

export default PhaseName;
