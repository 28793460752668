import React, { FC } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import { Button } from '../../../../atoms';
import { useLauncherConfigContext } from '../common';
import { AddPhasePopup } from '../common/add-phase';

interface IProps {}

const AddPhaseInEditor: FC<IProps> = () => {
  const {
    addNewSection,
    openAddPhase,
    getConfig,
    selectSection,
    openTaskForm,
  } = useLauncherConfigContext((state) => {
    return {
      addNewSection: state.addNewSection,
      openAddPhase: state.openAddPhase,
      getConfig: state.getConfig,
      openTaskForm: state.openTaskForm,
      selectSection: state.selectSection,
    };
  });

  const onNewPhaseAdded = () => {
    const sectionIndex = getConfig().contents.length - 1;
    selectSection(sectionIndex);
    openTaskForm({ sectionIndex: sectionIndex, taskIndex: -1 });

    setTimeout(() => {
      const elm = document.getElementById('editor-new-section-scroll');
      elm?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  if (!addNewSection) {
    return (
      <Button
        size='lg'
        colorScheme='blue'
        variant='ghost'
        bg='white'
        leftIcon={<AddIcon />}
        onClick={() => openAddPhase(true)}
      >
        Add Phase
      </Button>
    );
  }

  return <AddPhasePopup onNewPhaseAdded={onNewPhaseAdded} />;
};

export default AddPhaseInEditor;
