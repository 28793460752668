import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'atoms';
import { ActionButton } from '../../../ui-components';
import { headerConfig } from '../common';

import { ReactComponent as BackIcon } from '../../../assets/images/chevronBackward.svg';

interface IProps {
  onClick?: () => void;
}

const ScheduleHeader: FC<IProps> = ({ onClick }) => {
  const { t } = useTranslation(['common', 'audit']);

  const history = useHistory();

  return (
    <Flex flexDir='column' gap={2} {...headerConfig}>
      <Button
        fontSize='14px'
        fontWeight={500}
        width='max-content'
        variant='link'
        leftIcon={<BackIcon width='16' height='16' />}
        _hover={{
          textDecoration: 'none',
        }}
        onClick={history.goBack}
      >
        {t('back')}
      </Button>
      <Flex align='center' justify='space-between'>
        <Text fontWeight={600} fontSize='28px'>
          {t('audit:schedule')}
        </Text>
        <ActionButton variant='solid' colorScheme='blue' actionFn={onClick}>
          {t('audit:launchAudit')}
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default ScheduleHeader;
