import { AccordionItem } from '@chakra-ui/react';
import React, { FC } from 'react';
import PhaseAccordionButton from './PhaseAccordionButton/PhaseAccordionButton';
import PhaseAccordionPanel from './PhaseAccordionPanel/PhaseAccordionPanel';

interface IProps {
  eid: string;
  index: number;
}

const PhaseAccordionItem: FC<IProps> = ({ eid, index }) => {
  return (
    <AccordionItem border='none'>
      <PhaseAccordionButton eid={eid} index={index} />
      <PhaseAccordionPanel eid={eid} index={index} />
    </AccordionItem>
  );
};

PhaseAccordionItem.displayName =
  'displayName:pages/LocationsNew/Components/ProjectDetailPhase/components/PhaseAccordionItem';

export default PhaseAccordionItem;
