import { getTaskStatus } from 'sub-components/Launcher/dashboard/Location/helper';
import { ILocationLaunchTask } from 'sub-components/Launcher/location-owner/types';
import { GroupedPhase } from '../../types';
import { convertTaskData } from '../convertTaskData';

export const defaultConvert = (
  phase: GroupedPhase[],
  task: ILocationLaunchTask
) => {
  let allTasks = phase?.map((p) => p?.tasks)?.flat();
  let convertedAllTasks = allTasks?.map((all) => convertTaskData(all));
  let { status, unlockMessage } = getTaskStatus(
    convertTaskData(task),
    convertedAllTasks,
    task?.liveDate as unknown as Date,
    [],
    true
  );
  return {
    ...task,
    triggerDetails: {
      ...task.triggerDetails,
      triggerChoice:
        'default' as ILocationLaunchTask['triggerDetails']['triggerChoice'],
    },
    status,
    unlockMessage,
  };
};
