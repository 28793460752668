import React, { FC, useMemo } from 'react';
import { Box, Checkbox, Flex } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

import { toArray } from '../../../../../../../../utils';
import { TrainingContType } from '../../../../../../../../types';
import { IconImage } from '../../../../../../../../ui-components';

import { TrainingItem } from '../common';

function chapterFilter<T extends TrainingItem['contents'][number]>(value: T) {
  return value.type === TrainingContType.SOP;
}

interface IProps {
  data: TrainingItem;
}

const TrainingListItem: FC<IProps> = ({ data }) => {
  const { control } = useFormContext();
  const { t } = useTranslation(['common', 'task']);

  const count = useMemo(() => {
    return toArray(data.contents).filter(chapterFilter).length;
  }, [data.contents]);

  return (
    <Controller
      name={`taskStepSelection.${data.eid}`}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <Flex px={3}>
            <Flex py={3} flex={1} gap={3}>
              <Checkbox
                size='lg'
                value={data.eid}
                isChecked={value?.eid === data.eid}
                onChange={() => onChange(value ? undefined : data)}
                onBlur={onBlur}
              />
              <Flex flex={1} overflow='hidden'>
                <Flex mr='1rem' maxWidth='100%' gap='16px'>
                  <IconImage
                    thumbnail={data.thumbnail}
                    name={data.title}
                    boxSize={60}
                    borderRadius='6px'
                  />
                  <Flex flexDir='column' overflow='hidden' justify='center'>
                    <Box fontSize='12px' fontWeight='500' color='#6F767E'>
                      <Trans
                        t={t}
                        i18nKey='task:noOfChapter'
                        count={count}
                        values={{ value: count }}
                      />
                    </Box>
                    <Box fontWeight='700' whiteSpace='pre-wrap'>
                      {data?.title}
                    </Box>
                    <Box
                      fontSize='12px'
                      fontWeight='400'
                      color='#999999'
                      isTruncated
                    >
                      Last updated: {moment(data.updatedAt).format('DD MMMM')}{' '}
                      by {data.lastUpdatedBy?.name}
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    />
  );
};

export default TrainingListItem;
