import { Box, Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICompareVisibilityType } from 'pages/Chapters/CreateEditSubFolder/components/helper/visibilityHelper';
import { faCircleCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import React, { FC } from 'react';
import { IDetailedVisibility } from '../helper/useVisibilityStatusInfoLogic';
import { IVisibilityStatusInfoType } from '../VisibilityStatusInfo';
import SubFolderWRTChapter from './SubFolderWRTChapter';
import SubFolderWRTFolder from './SubFolderWRTFolder';

interface IProps {
  status: ICompareVisibilityType;
  childDetails: IDetailedVisibility | undefined;
  parentDetails: IDetailedVisibility | undefined;
  folderName: string;
  parentFolderName?: string;
  withReferenceTo?: IVisibilityStatusInfoType;
}

const TypeSubFolder: FC<IProps> = ({
  status,
  childDetails,
  parentDetails,
  folderName = 'Sub Folder',
  parentFolderName = 'folder',
  withReferenceTo = 'folder',
}) => {
  if (withReferenceTo === 'folder') {
    return (
      <Flex flexDir='column' gap={2}>
        <SubFolderWRTFolder
          status={status}
          childDetails={childDetails}
          parentDetails={parentDetails}
          folderName={folderName}
          parentFolderName={parentFolderName}
        />
      </Flex>
    );
  } else {
    return (
      <SubFolderWRTChapter
        status={status}
        parentDetails={parentDetails}
        folderName={folderName}
      />
    );
  }
};

TypeSubFolder.displayName =
  'displayName:ui-components/VisibilityStatusInfo/components/TypeSubFolder';

export default TypeSubFolder;
