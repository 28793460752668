import styled from '@emotion/styled';

export const TriggerSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .chakra-radio__control {
    border-color: rgba(26, 29, 31, 0.4);
    &[data-disabled],
    &[data-readonly] {
      border-color: rgba(26, 29, 31, 0.1);
    }
    &[data-checked] {
      border-color: var(--chakra-colors-blue-500);
      &[data-disabled] {
        border-color: var(--chakra-colors-gray-200);
      }
    }
  }
`;
