import { cloneDeep } from '@apollo/client/utilities';
import { Box, Center, Flex, List, Switch } from '@chakra-ui/react';
import { PrimaryButton } from 'atoms';
import { IThirdStepData } from 'pages/Training/CreateTrainingPath/CreateContainer/AssignPublish/assign-publish-types.ts/AssignPublishTypes';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddAssigneeModal from 'ui-components/AssigneeModal/AddAssigneeModal';
import AddSupervisorModal from 'ui-components/SupervisorModal/AddSupervisorModal';
import MemberItem from '../../atoms/MemberItem';
import RoleLocationEditModal from './RoleLocationEditModal';

interface IProps {
  trainingData: any;
  setTrainingData: React.Dispatch<any>;
  createdUser: any;
  thirdStepFinalSelections: IThirdStepData;
  setThirdStepSelections: React.Dispatch<React.SetStateAction<IThirdStepData>>;
  thirdStepSelections: IThirdStepData;
  setThirdStepFinalSelections: React.Dispatch<
    React.SetStateAction<IThirdStepData>
  >;
  setThirdStepData: React.Dispatch<React.SetStateAction<IThirdStepData>>;
  thirdStepData: IThirdStepData;
}

const EditTrainingRightSide: FC<IProps> = ({
  trainingData,
  setTrainingData,
  createdUser,
  thirdStepFinalSelections,
  setThirdStepSelections,
  thirdStepSelections,
  setThirdStepFinalSelections,
  setThirdStepData,
  thirdStepData,
}) => {
  const [isEditSupervisorOpen, setIsEditSupervisorOpen] = useState(false);
  const [isEditAssigneeOpen, setIsEditAssigneeOpen] = useState(false);
  const [isRoleLocationEditModalOpen, setIsRoleLocationEditModalOpen] =
    useState(false);
  const { t } = useTranslation(['training', 'common']);
  const onSwitchClick = (e: ChangeEvent<HTMLInputElement>) => {
    let _trainingData = cloneDeep(trainingData);
    _trainingData.status = e.target.checked ? 'active' : 'inactive';
    setTrainingData(_trainingData);
  };
  const onOpenRoleLocationEditModal = () => {
    setIsRoleLocationEditModalOpen(true);
  };
  const onCloseSupervisorEditModal = (type: any) => {
    if (type === 'close') {
      setIsEditSupervisorOpen(false);
    } else {
      setIsEditSupervisorOpen(false);
      // trainingByIdData({
      //   variables: {
      //     eid: router.query.id,
      //   },
      // });
    }
  };
  const checkedDataHandler = (data: any, type: 'supervisor' | 'assignee') => {
    if (type === 'supervisor') {
      updatedSupervisorListHandler(data);
    } else {
      updatedAssigneeListHandler(data);
    }
  };
  const onOpenSupervisorModal = () => {
    setIsEditSupervisorOpen(true);
  };

  const onOpenAssigneeModal = () => {
    setIsEditAssigneeOpen(true);
  };
  const onCloseAssigneeEditModal = (type: any) => {
    if (type === 'close') {
      setIsEditAssigneeOpen(false);
    } else {
      setIsEditAssigneeOpen(false);
      // trainingByIdData({
      //   variables: {
      //     eid: router.query.id,
      //   },
      // });
    }
  };
  const updatedSupervisorListHandler = (updatedList: any) => {
    setIsEditSupervisorOpen(false);
    let _trainingData = cloneDeep(trainingData);
    let _updatedList = [...updatedList];
    let _updatedListIds = _updatedList.map((list: any) => list.eid);
    _trainingData.supervisorsUsers = _updatedList;
    _trainingData.supervisors = _updatedListIds;
    setTrainingData(_trainingData);
  };

  const updatedAssigneeListHandler = (updatedList: any) => {
    setIsEditSupervisorOpen(false);
    let _trainingData = cloneDeep(trainingData);
    let _updatedList = [...updatedList];
    _trainingData.assignedToUsers = _updatedList;
    setTrainingData(_trainingData);
  };
  const roleLocationEditModalCloseHandler = (type: 'close' | 'save') => {
    if (type === 'close') {
      let _thirdStepFinalSelections = JSON.parse(
        JSON.stringify(thirdStepFinalSelections)
      );
      setThirdStepSelections(_thirdStepFinalSelections);
    } else {
      let _thirdStepSelections = JSON.parse(
        JSON.stringify(thirdStepSelections)
      );
      setThirdStepFinalSelections(_thirdStepSelections);
    }
    setIsRoleLocationEditModalOpen(false);
  };
  const combinedSelections = [
    ...(thirdStepFinalSelections?.members || []),
    ...(thirdStepFinalSelections?.roles || []),
    ...(thirdStepFinalSelections?.locations || []),
    ...(thirdStepFinalSelections?.supervisors || []),
  ];

  const uniqueSelections = Array.from(
    combinedSelections
      .reduce((map, item) => map.set(item.id, item), new Map())
      .values()
  );
  return (
    <div id='edit-training-right-side' className='edit-training-right-side'>
      <Box className='switch-cover' mb='1.5rem' mt='10px'>
        <p>{t('common:active')}</p>
        <Switch
          size='lg'
          isChecked={trainingData?.status === 'active'}
          onChange={onSwitchClick}
        />
      </Box>
      <div className='edit-training-right-side-title'>
        {t('training:view_created_by')}
      </div>
      <List spacing={3} width='100%' ml='2px' mt='10px'>
        <MemberItem
          name={createdUser?.name}
          role={createdUser?.role}
          authRole={createdUser?.locations?.[0]?.name}
          thumbnail={createdUser?.profilePic}
        />
      </List>

      {/* <Box className='edit-training-right-side-title' mt='30px'>
        {t('training:view_supervisors')}
      </Box>
      <List spacing={3} style={{ marginTop: '10px', marginLeft: '2px' }}>
        {thirdStepFinalSelections?.supervisors?.map((supervisor) => {
          return (
            <MemberItem
              key={supervisor?.id}
              name={supervisor?.value}
              role={supervisor?.role}
              authRole={supervisor?.location}
              thumbnail={supervisor?.url}
            />
          );
        })}
      </List> */}

      <div style={{ marginTop: '15px' }}>
        <PrimaryButton
          type='button'
          size='md'
          title={t('common:edit')}
          onClick={() => onOpenRoleLocationEditModal()}
          style={{
            padding: '0px 37px',
            marginRight: 20,
          }}
          variant='outline'
          className='profile-button-update-container'
        />
      </div>

      <Box className='edit-training-right-side-title' mt='30px'>
        {t('training:view_assignees')}
      </Box>
      <List spacing={3} style={{ marginTop: '10px', marginLeft: '2px' }}>
        <Flex wrap='wrap' gap='10px' mt='10px' ml='2px'>
          {uniqueSelections?.map((assignedTo) => {
            return (
              <span
                key={assignedTo?.id}
                style={{
                  padding: '5px 10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#daf2e4',
                  width: 'fit-content',
                  borderRadius: '7px',
                  fontWeight: '600',
                  fontSize: '14px',
                }}
              >
                {assignedTo?.value}
              </span>
            );
            // return (
            //   <MemberItem
            //     key={assignedTo?.id}
            //     name={assignedTo?.value}
            //     role={assignedTo?.role}
            //     authRole={assignedTo?.location}
            //     thumbnail={assignedTo?.url}
            //   />
            // );
          })}
        </Flex>
      </List>

      <div style={{ marginTop: '15px' }}>
        <PrimaryButton
          type='button'
          size='md'
          title={t('common:edit')}
          onClick={() => {
            // onOpenAssigneeModal();
            onOpenRoleLocationEditModal();
          }}
          style={{
            padding: '0px 37px',
            marginRight: 20,
          }}
          variant='outline'
          className='profile-button-update-container'
        />
      </div>

      {/* {thirdStepFinalSelections && thirdStepFinalSelections?.roles?.length > 0 && (
        <>
          <Box className='edit-training-right-side-title' mt='30px'>
            {t('training:view_assigned_to_roles')}
          </Box>
          <Flex wrap='wrap' gap='10px' mt='10px' ml='2px'>
            {thirdStepFinalSelections?.roles?.map((role) => {
              return (
                <span
                  key={role?.id}
                  style={{
                    padding: '5px 10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#daf2e4',
                    borderRadius: '7px',
                    fontWeight: '600',
                    fontSize: '14px',
                  }}
                >
                  {role?.value}
                </span>
              );
            })}
          </Flex>

          <div style={{ marginTop: '15px' }}>
            <PrimaryButton
              type='button'
              size='md'
              title={t('common:edit')}
              onClick={() => {
                // onOpenAssigneeModal();
                onOpenRoleLocationEditModal();
              }}
              style={{
                padding: '0px 37px',
                marginRight: 20,
              }}
              variant='outline'
              className='profile-button-update-container'
            />
          </div>
        </>
      )} */}
      {/* {thirdStepFinalSelections &&
        thirdStepFinalSelections?.locations?.length > 0 && (
          <>
            <Box className='edit-training-right-side-title' mt='30px'>
              {t('training:view_assigned_to_locations')}
            </Box>
            <Flex wrap='wrap' gap='10px' mt='10px' ml='2px'>
              {thirdStepFinalSelections?.locations?.map((role) => {
                return (
                  <Center
                    as='span'
                    key={role?.id}
                    style={{
                      padding: '5px 10px',

                      backgroundColor: '#daf2e4',
                      borderRadius: '7px',
                      fontWeight: '600',
                      fontSize: '14px',
                    }}
                  >
                    {role?.value}
                  </Center>
                );
              })}
            </Flex>

            <div style={{ marginTop: '15px' }}>
              <PrimaryButton
                type='button'
                size='md'
                title={t('common:edit')}
                onClick={() => onOpenRoleLocationEditModal()}
                style={{
                  padding: '0px 37px',
                  marginRight: 20,
                }}
                variant='outline'
                className='profile-button-update-container'
              />
            </div>
          </>
        )} */}
      {isEditSupervisorOpen && (
        <AddSupervisorModal
          open={isEditSupervisorOpen}
          onClose={(type: any) => onCloseSupervisorEditModal(type)}
          currentSupervisorsList={trainingData?.supervisors}
          updatedList={updatedSupervisorListHandler}
          trainingData={trainingData}
          isAdd={false}
          selectedSupervisorData={trainingData?.supervisorsUsers}
          checkedData={(data: any) => checkedDataHandler(data, 'supervisor')}
        />
      )}
      {isEditAssigneeOpen && (
        <AddAssigneeModal
          open={isEditAssigneeOpen}
          onClose={(type: any) => onCloseAssigneeEditModal(type)}
          trainingData={trainingData}
          isAdd={false}
          selectedAssigneeData={trainingData?.assignedToUsers}
          checkedData={(data: any) => checkedDataHandler(data, 'assignee')}
        />
      )}
      {isRoleLocationEditModalOpen && (
        <RoleLocationEditModal
          isOpen={isRoleLocationEditModalOpen}
          onClose={roleLocationEditModalCloseHandler}
          setThirdStepData={setThirdStepData}
          thirdStepData={thirdStepData}
          setThirdStepSelections={setThirdStepSelections}
          thirdStepSelections={thirdStepSelections}
        />
      )}
    </div>
  );
};

EditTrainingRightSide.displayName =
  'displayName:pages/EditTraining/EditTrainingRightSide';

export default EditTrainingRightSide;
