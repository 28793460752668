import React, { FC } from 'react';
import { Box, Flex, Progress, Tooltip } from '@chakra-ui/react';
import { IconImage } from '../../../../../../../ui-components';
import { HeaderColors } from 'shared/headerColors';
import { useSendReminder } from './SendReminder/useSendReminder';
import { useUserDataSelector } from 'hooks';
import { AuditStatusFunctionEntity } from 'sub-components/audits/audit-tab/supervised/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  eid: string;
  name: string;
  profilePic?: string;
  auditStatus: AuditStatusFunctionEntity;
  auditorLocations: string[];
  isAuditArchived: boolean;
  // totalLocation: number;
  // completed: number;
}

const AuditorItem: FC<IProps> = ({
  eid,
  name,
  profilePic,
  auditStatus,
  auditorLocations,
  isAuditArchived,
  // totalLocation,
  // completed,
}) => {
  const loggedInUserEid = useUserDataSelector((state) => state?.eid);
  const sendReminder = useSendReminder();

  const sendRemindHandler = () => {
    sendReminder({
      auditorDetails: {
        eid,
        name,
      },
    });
  };

  const displayedLocations = auditorLocations?.slice(0, 2);
  const remainingLocations = auditorLocations?.slice(2);

  return (
    <Flex
      flexDir='column'
      border='1px solid #EFEFEF'
      borderRadius='8px'
      p='12px'
    >
      <Flex align='center' justify='space-between'>
        <Flex align='center' gap='8px'>
          <IconImage
            name={name}
            thumbnail={profilePic}
            boxSize={24}
            borderRadius='50%'
            objectFit='cover'
          />
          <Box fontWeight={600}>{name}</Box>
        </Flex>
        {eid !== loggedInUserEid &&
          auditStatus !== 'completed' &&
          !isAuditArchived && (
            <Box
              as='span'
              cursor='pointer'
              textDecor='underline'
              color='rgba(42, 133, 255, 1)'
              fontSize='12px'
              fontWeight={600}
              onClick={sendRemindHandler}
            >
              Send Reminder
            </Box>
          )}
      </Flex>
      <Flex align='flex-start' pl={8} color='rgba(111, 118, 126, 1)' gap={1}>
        <FontAwesomeIcon
          icon={faLocationDot as IconProp}
          style={{ marginTop: '3px' }}
          fontSize='12px'
        />
        <Box as='span' fontWeight={500} fontSize='12px'>
          {displayedLocations?.join(', ')}
          {remainingLocations?.length > 0 && (
            <>
              ,{' '}
              <Tooltip
                label={remainingLocations?.join(', ')}
                aria-label='Remaining locations'
                hasArrow
                placement='top'
              >
                <Box as='span' cursor='pointer' textDecor='underline'>
                  +{remainingLocations?.length}{' '}
                  {remainingLocations?.length === 1 ? 'location' : 'locations'}
                </Box>
              </Tooltip>
            </>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

AuditorItem.displayName = 'sc/a/d/c/O/c/AS/AuditorItem';

export default AuditorItem;
