import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../atoms';
import { ActionButton } from 'ui-components';
import { useComplianceUpload } from 'sub-components/nexus/Compliance';

import { ReactComponent as BackIcon } from '../../../assets/images/chevronBackward.svg';
import { Authorize, AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface RouteParams {
  locationId: string;
}

const LocationDocumentHeading: FC = () => {
  const { t } = useTranslation(['home']);
  const history = useHistory();
  const { locationId } = useParams<RouteParams>();

  // const locationList = useUserDataSelector((state) => state?.entity?.locations);
  const { userAuthRole, locationList } = useUserDataSelector((state) => ({
    userAuthRole: state?.authRole,
    locationList: state?.entity?.locations,
  }));
  const location = locationList?.find((item) => item?.eid === locationId);

  const complianceUpload = useComplianceUpload();

  return (
    <Flex w='full' justify='space-between' align='center'>
      <Flex flexDir='column' gap={2}>
        <Text fontWeight={600} fontSize='28px' minH={8}>
          <FontAwesomeIcon
            cursor={'pointer'}
            onClick={history.goBack}
            style={{ marginRight: '15px' }}
            icon={faArrowLeft as IconProp}
          />
          {userAuthRole === AuthRole?.LOCATION_OWNER
            ? `${location?.name} Documents`
            : t('home:documentListing')}
        </Text>
        {userAuthRole !== AuthRole?.LOCATION_OWNER && (
          <Button
            fontSize='14px'
            fontWeight={500}
            width='max-content'
            variant='link'
            leftIcon={<BackIcon width='16' height='16' />}
            _hover={{
              textDecoration: 'none',
            }}
            onClick={history.goBack}
          >
            Back to {location?.name}
          </Button>
        )}
      </Flex>

      {/* <Authorize
        permittedFor={'user'}
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
      >
        <ActionButton
          colorScheme='blue'
          leftIcon={<AddIcon />}
            actionFn={() => complianceUpload()}
        >
          Add document
        </ActionButton>
      </Authorize> */}
    </Flex>
  );
};

export default LocationDocumentHeading;
