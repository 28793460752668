import {
  Center,
  Divider,
  Flex,
  List,
  ListItem,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import { PrimaryButton, SearchInput, SelectOption } from 'atoms';
import React, { FC, useMemo, useState } from 'react';
import { faFolder, faFolderUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import EmptyState from 'sub-components/EmptyState';
import { useTranslation } from 'react-i18next';
import Dropdown from 'atoms/Dropdown';
import { chakraComponents } from 'chakra-react-select';
import { CustomCategoryEntity } from 'pages/Chapters/ChapterHeader/components/BulkMoveContainer';

interface IProps {
  allFolderSubFolderList: any[];
  anySelectionMade?: boolean;
  loading: boolean;
  showOnly?: 'folder' | 'subFolder';
  value: SelectOption<string> | undefined;
  onMove: () => void;
  setValue: React.Dispatch<
    React.SetStateAction<SelectOption<string> | undefined>
  >;
}

const MoveFolderSubFolderSelect: FC<IProps> = ({
  allFolderSubFolderList,
  anySelectionMade,
  loading,
  showOnly,
  value,
  onMove,
  setValue,
}) => {
  const { t } = useTranslation(['handbook']);
  const [query, setQuery] = useState('');
  const [typeFilter, setTypeFilter] = useState<SelectOption | null>(null);
  const [showDropdownOptions, setShowDropdownOptions] = useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: ref,
    handler: () => onCloseHandler(),
  });
  const onCloseHandler = () => {
    setShowDropdownOptions(false);
  };

  const foldersList = useMemo(() => {
    // let allFolderSubFolders: CategoryEntity[] =
    //   getAllFolderSubFolderList();
    let allFolderSubFolders: any[] = allFolderSubFolderList;

    let filteredFolderSubFolderList = allFolderSubFolders.filter(
      (all) => all.eid !== value?.value
    );

    if (typeFilter) {
      filteredFolderSubFolderList = filteredFolderSubFolderList.filter(
        (item) => {
          if (typeFilter.value === 'all') return true;
          if (typeFilter.value === 'subFolders')
            return item.type === 'Subfolder';
          if (typeFilter.value === 'folders') return item.type === 'Folder';
          return false;
        }
      );
    }

    if (query) {
      filteredFolderSubFolderList = filteredFolderSubFolderList.filter((item) =>
        item.name.toLowerCase().trim().includes(query.toLowerCase().trim())
      );
    }

    return filteredFolderSubFolderList.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }, [allFolderSubFolderList, typeFilter, query]);

  const onTypeFilterChange = (value: SelectOption<string> | null) => {
    setTypeFilter(value);
  };

  const getLabel = () => {
    if (!value) {
      if (!showOnly) {
        return 'Search folder or subfolder';
      } else if (showOnly === 'folder') {
        return 'Search folder';
      } else {
        return 'Search subfolder';
      }
    } else {
      return value?.label;
    }
  };

  return (
    <Flex ref={ref} mb={2} pos='relative'>
      <Flex
        bg='#2A85FF'
        w='full'
        borderRadius='12px'
        p={3}
        align='center'
        justify='space-between'
        cursor='pointer'
        onClick={() => setShowDropdownOptions(true)}
      >
        <Text color='white' fontWeight={700} fontSize='15px'>
          {getLabel()}
        </Text>
        <FontAwesomeIcon icon={faChevronDown as IconProp} color='white' />
      </Flex>
      {showDropdownOptions && (
        <Flex
          pos='absolute'
          flexDir='column'
          bg='white'
          borderRadius='12px'
          p={4}
          w='full'
          top='60px'
          zIndex='1'
          border='1px solid #f6f6f6'
          gap={4}
        >
          <Flex w='full' gap={2}>
            <Flex w={showOnly ? 'full' : '70%'}>
              <SearchInput
                placeholder={
                  !showOnly
                    ? 'Search folder or subfolder'
                    : showOnly === 'folder'
                    ? 'Search folder'
                    : 'Search subfolder'
                }
                hideShortcuts
                value={query}
                onChange={(e) => setQuery(e?.target?.value)}
              />
            </Flex>
            {!showOnly && (
              <Flex w='30%'>
                <Dropdown
                  inputStyle={{ width: '130px' }}
                  placeholder={t('handbook:type')}
                  value={typeFilter}
                  onChange={onTypeFilterChange}
                  options={[
                    {
                      label: 'All',
                      value: 'all',
                    },
                    {
                      label: 'Folders',
                      value: 'folders',
                    },
                    {
                      label: 'Subfolders',
                      value: 'subFolders',
                    },
                  ]}
                  selectStyles={{
                    singleValue: {
                      color: '#6f767e',
                      fontWeight: '600',
                    },
                  }}
                  components={{
                    SingleValue: (valProps) => {
                      return (
                        <chakraComponents.SingleValue {...valProps}>
                          {/* @ts-ignore */}
                          {t('handbook:type_by', {
                            value: valProps.children,
                          })}
                        </chakraComponents.SingleValue>
                      );
                    },
                  }}
                />
              </Flex>
            )}
          </Flex>
          {foldersList?.length === 0 && query && (
            <Center>
              <EmptyState title='No data found' image='Search' />
            </Center>
          )}
          {foldersList?.length > 0 && (
            <Flex flexDir='column' gap={4}>
              <List maxH='30vh' overflowY='auto' spacing={2}>
                {foldersList?.map((list) => (
                  <ListItem
                    key={list?.eid}
                    cursor='pointer'
                    onClick={() => setValue(list)}
                  >
                    <Flex align='center' justify='space-between'>
                      <Flex gap='5px' align='center'>
                        <Center
                          boxSize='32px'
                          border='1px solid #DDDDDD'
                          borderRadius='5px'
                        >
                          <FontAwesomeIcon
                            icon={
                              (list?.visibility === 'private'
                                ? faFolderUser
                                : faFolder) as IconProp
                            }
                            color='#6F767E'
                            fontSize='15px'
                          />
                        </Center>
                        <Text
                          flex={1}
                          fontSize='15px'
                          noOfLines={1}
                          pr='8px'
                          color='#272b30'
                          fontWeight='500'
                        >
                          {list?.name}
                        </Text>
                      </Flex>
                      <Flex align='center'>
                        {value?.value !== list?.eid && (
                          <Text
                            fontWeight={500}
                            fontSize='12px'
                            color='#979EA6'
                          >
                            {list?.type}
                          </Text>
                        )}
                        {value?.value === list?.eid && (
                          <FontAwesomeIcon
                            icon={faCircleCheck as IconProp}
                            color='#83bf6e'
                          />
                        )}
                      </Flex>
                    </Flex>
                    <Divider mt={2} />
                  </ListItem>
                ))}
              </List>
              <PrimaryButton
                title='Move'
                colorScheme='green'
                variant='solid'
                size='lg'
                isLoading={loading}
                disabled={!value || !anySelectionMade || loading}
                onClick={onMove}
              />
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};

MoveFolderSubFolderSelect.displayName =
  'displayName:pages/Chapters/CreateEditSubFolder/components/MoveFolderSubFolderSelect';

export default MoveFolderSubFolderSelect;
