import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useRouteState } from '../../../routes';
import ArchiveTabs from './ArchiveTabs';
import ArchivesRender from './ArchivesRender';

interface IProps {}

const ArchivesContainer: FC<IProps> = () => {
  const { state, updateState } = useRouteState('archives', {
    selectedTab: 'audit',
  });

  function updateSelectedTab(name: string) {
    updateState({
      selectedTab: name,
    });
  }

  return (
    <Flex flexDir='column' gap={4}>
      <ArchiveTabs
        selectedTab={state.selectedTab}
        onChange={updateSelectedTab}
      />
      <ArchivesRender selectedTab={state.selectedTab} />
    </Flex>
  );
};

export default ArchivesContainer;
