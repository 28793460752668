import {
  Box,
  Center,
  Flex,
  Image,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import React, { FC } from 'react';
import { BoxHeader } from 'ui-components';
import { LocationLaunchTaskByIdResponse } from '../types';
import { ReactComponent as FormiconIcon } from 'assets/images/formIcon.svg';
import { CloseIcon } from '@chakra-ui/icons';
import { ITaskStatus } from 'ui-components/TaskStatusIcon/types';
import FileDropzone from 'sub-components/Launcher/location-owner/StatesDrawer/StateDrawer/FileDropzone';
import { useComplianceUpload } from 'sub-components/nexus/Compliance';
import { useParams } from 'react-router-dom';
import { PhaseTask } from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import { UserData } from 'sop-commons/src/client';
import { useAddLauncherTaskFiles } from 'sub-components/Launcher/location-owner/StatesDrawer/StateDrawer/useAddLauncherTaskFiles';
import { FileTypeEntity } from 'types';
import { useService } from 'sub-components/Launcher/location-owner/StatesDrawer/StateDrawer/Attachments/layers';

interface IProps {
  fileAttachments: LocationLaunchTaskByIdResponse['LocationLaunchTaskById']['complianceFiles'];
  taskData: PhaseTask | undefined;
  isTaskLoading: boolean;
  isAnyStepLoading: boolean;
  refetchHandler?: () => void;
}

const AttachmentSection: FC<IProps> = ({
  fileAttachments,
  isTaskLoading,
  isAnyStepLoading,
  taskData,
  refetchHandler,
}) => {
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });
  const { locationId, launchId, taskId } = useParams<{
    locationId: string;
    launchId: string;
    taskId: string;
  }>();
  const {
    deleteCompliance: { deleteComplianceDocument, deleteComplianceData },
  } = useService();
  const complianceUpload = useComplianceUpload();
  const addLauncherTaskFiles = useAddLauncherTaskFiles();

  const status = taskData?.status;
  const docRequired = taskData?.docRequired;

  const handleFileUpload = (files: File[]) => {
    if (files?.length === 0 || !taskId || !launchId) return;
    complianceUpload({
      initialFile: files?.[0],
      metaComplianceData: {
        launchId: launchId,
        taskId: taskId,
        phaseId: taskData?.phaseEid,
        phase: taskData?.phaseName,
        task: taskData?.title,
      },
      preDefinedLocationId: locationId,
      successCb: () => {
        refetchHandler?.();
        // if (!data) return;
        // let filesObj =
        //   taskData?.files?.map((file) => ({
        //     fileSize: file?.fileSize,
        //     mimetype: file?.mimetype || '',
        //     name: file?.name,
        //     type: file?.type,
        //     url: file?.url,
        //     createdBy: { eid: file?.createdBy } as UserData,
        //   })) || [];
        // addLauncherTaskFiles({
        //   file: [
        //     ...filesObj,
        //     {
        //       fileSize: data?.file?.fileSize,
        //       mimetype: data?.file?.mimetype || '',
        //       name: data?.file?.name,
        //       type: data?.file?.type,
        //       url: data?.file?.url,
        //       createdBy: { eid: data?.file?.createdBy } as UserData,
        //     },
        //   ],
        //   launchId: launchId,
        //   taskId: taskId,
        //   onSuccess: () => {
        //     toast({
        //       status: 'success',
        //       title: 'Success',
        //       description: 'File added successfully',
        //     });
        //     refetchHandler?.();
        //   },
        //   onError: () => {
        //     toast({
        //       status: 'error',
        //       title: 'Error',
        //       description: 'File could not be added',
        //     });
        //     refetchHandler?.();
        //   },
        // });
      },
    });
  };

  const removeFile = (
    file: FileTypeEntity,
    files: FileTypeEntity[],
    launchId: string,
    taskId: string,
    id: string
  ) => {
    let filteredFiles = files?.filter((_file) => _file?.url !== file?.url);
    addLauncherTaskFiles({
      file: filteredFiles,
      launchId,
      taskId,
      onSuccess: () => {
        deleteComplianceDocument({
          variables: {
            eid: id,
          },
        })
          ?.then((res) => {
            toast({
              status: 'success',
              title: 'Success',
              description: 'File removed successfully',
            });
            refetchHandler?.();
          })
          ?.catch((err) => {
            toast({
              status: 'error',
              title: 'Error',
              description: 'File could not be removed',
            });
            refetchHandler?.();
          });
      },
      onError: () => {
        toast({
          status: 'error',
          title: 'Error',
          description: 'File could not be removed',
        });
        refetchHandler?.();
      },
    });
  };

  if (
    taskData?.status === 'locked' ||
    taskData?.status === 'yetToStart' ||
    taskData?.status === 'yetToStart/locked'
  ) {
    return null;
  }

  return (
    <Flex flexDir='column' gap={4}>
      {fileAttachments?.length ? (
        <>
          <BoxHeader color={HeaderColors.Blue} title='Attachments' />
          <Box id='attachments-1'>
            <Flex id='attachments-2' flexWrap='wrap' gap='10px'>
              {fileAttachments?.map((file, index) => {
                const lastFile = file?.files?.[file?.files?.length - 1];
                return lastFile ? (
                  <Flex
                    key={index}
                    id={`attachments-flex-${index}`}
                    align='center'
                    gap='5px'
                  >
                    {lastFile?.type?.includes('image') ? (
                      <Image
                        src={lastFile?.url}
                        height={25}
                        width={25}
                        style={{ borderRadius: '50%' }}
                      />
                    ) : (
                      <Center
                        boxSize='25px'
                        border='1px solid rgba(111, 118, 126, 1)'
                        borderRadius='50%'
                        p='3px'
                      >
                        <FormiconIcon />
                      </Center>
                    )}
                    <Tooltip hasArrow label={lastFile?.name}>
                      <Text as='span' isTruncated maxW='140px' cursor='pointer'>
                        {lastFile?.name}
                      </Text>
                    </Tooltip>
                    {status !== 'completed' &&
                      status !== 'locked' &&
                      status !== 'yetToStart' &&
                      status !== 'yetToStart/locked' && (
                        <CloseIcon
                          cursor='pointer'
                          fontSize='8px'
                          boxSizing='content-box'
                          background='#EFEFEF'
                          borderRadius='100px'
                          padding='4px'
                          color='#6F767E'
                          onClick={() =>
                            removeFile(
                              lastFile,
                              file?.files,
                              launchId,
                              taskId,
                              file?.eid
                            )
                          }
                        />
                      )}
                  </Flex>
                ) : null;
              })}
            </Flex>
          </Box>
        </>
      ) : null}

      {status !== 'completed' && (
        <FileDropzone
          disabled={isTaskLoading || isAnyStepLoading}
          required={docRequired}
          onFileUpload={handleFileUpload}
        />
      )}
    </Flex>
  );
};

AttachmentSection.displayName =
  'displayName:sub-components/Launcher/tasks/components/task-details/components/AttachmentSection';

export default AttachmentSection;
