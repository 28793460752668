import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiagramSubtask } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Trans, useTranslation } from 'react-i18next';
import { getDuration } from '../../../common/trigger/duration.helper';
import { TriggerEntity } from '../../../common/launcher-config.fragment';

interface IProps {
  stepsCount: number;
  category?: string;
  trigger?: TriggerEntity | null;
}

const LauncherTaskFooter: FC<IProps> = ({ stepsCount, category, trigger }) => {
  const { t } = useTranslation(['launcher']);
  const { durationValue, durationUnit } = getDuration(trigger);

  return (
    <Flex fontSize='13px' userSelect='none' align='center' gap={2} minH='28px'>
      <Box fontWeight='600' color='#8E59FF'>
        {category}
      </Box>

      {stepsCount ? (
        <>
          <Box boxSize='4px' borderRadius='full' bg='#D9D9D9' />
          <Flex align='center' gap={2} color='#33383F'>
            <FontAwesomeIcon icon={faDiagramSubtask as IconProp} />
            <span>
              <Trans
                t={t}
                i18nKey='launcher:noOfSubtask'
                count={stepsCount}
                values={{ value: stepsCount }}
              />
            </span>
          </Flex>
        </>
      ) : null}

      {durationUnit ? (
        <>
          <Box boxSize='4px' borderRadius='full' bg='#D9D9D9' />
          <Box color='#33383F'>
            <Trans
              t={t}
              i18nKey='launcher:configDuration'
              count={durationValue}
              context={durationUnit}
              values={{ value: durationValue }}
            />
          </Box>
        </>
      ) : null}
    </Flex>
  );
};

LauncherTaskFooter.displayName = 'sc/L/lc/dv/s/lt/LauncherTaskFooter';

export default LauncherTaskFooter;
