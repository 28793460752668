import React, { FC, useContext } from 'react';
import { Box, Container, Flex, Text } from '@chakra-ui/react';
import addIcon from '../../../../../assets/images/add.png';
import SetSeeDataContext from '../SetSeeModalStore/set-see-data-context';
import { useTranslation } from 'react-i18next';
import useCombinedStore from 'zustandStore/store';
import MultiCustomDropdown from './MultiCustomDropdown';
import ConditionalButtons from './ConditionalButtons';
import { ISecondDropdownData } from '../set-see-modal-types';

interface IProps {
  setNewRole: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConditionalSelection: FC<IProps> = ({ setNewRole }) => {
  const { t } = useTranslation('deck');
  const setSeeCtx = useContext(SetSeeDataContext);
  const { visibilitySelection, updateVisibilitySelection } = useCombinedStore();
  console.log('condition : ', visibilitySelection?.condition);

  const dropdownRender = () => {
    if (setSeeCtx?.selectedFirstDropdown?.label === 'Location') {
      return (
        <MultiCustomDropdown
          options={setSeeCtx?.rolesList}
          value={visibilitySelection?.roles || []}
          type='role'
          onClick={(selection: ISecondDropdownData) => {
            if (visibilitySelection) {
              let foundRoleName = visibilitySelection?.roles?.find(
                (loc) => loc === selection?.name
              );
              if (foundRoleName) {
                let filteredRoleNames = visibilitySelection?.roles?.filter(
                  (loc) => loc !== selection?.name
                );
                updateVisibilitySelection({
                  ...visibilitySelection,
                  roles: filteredRoleNames,
                });
              } else {
                updateVisibilitySelection({
                  ...visibilitySelection,
                  roles: [...visibilitySelection.roles, selection?.name],
                });
              }
            }
          }}
          createRole={() => setNewRole(true)}
        />
      );
    } else {
      return (
        <MultiCustomDropdown
          options={setSeeCtx?.locationsList}
          value={visibilitySelection?.locations || []}
          type='location'
          onClick={(selection: ISecondDropdownData) => {
            if (visibilitySelection) {
              let foundEid = visibilitySelection?.locations?.find(
                (loc) => loc === selection?.eid
              );
              if (foundEid) {
                let filteredIds = visibilitySelection?.locations?.filter(
                  (loc) => loc !== selection?.eid
                );
                updateVisibilitySelection({
                  ...visibilitySelection,
                  locations: filteredIds,
                });
              } else {
                updateVisibilitySelection({
                  ...visibilitySelection,
                  locations: [...visibilitySelection.locations, selection?.eid],
                });
              }
            }
          }}
          createRole={() => setNewRole(true)}
        />
      );
    }
  };

  if (!visibilitySelection?.condition) {
    return (
      <Flex
        w='232px'
        h='48px'
        justify='center'
        align='center'
        gap='10px'
        m='10px 409.9px 0 0'
        p='2px 0 2px 16px'
        borderRadius='7px'
        border='solid 1px #ccc'
        cursor='pointer'
        onClick={() => {
          setSeeCtx?.updateShowCondition(true);
          if (visibilitySelection) {
            updateVisibilitySelection({
              ...visibilitySelection,
              condition: 'or',
            });
          }
        }}
      >
        <img src={addIcon} />
        {t('visibility.add_another_condition')}
      </Flex>
    );
  } else {
    return (
      <>
        <ConditionalButtons />
        <Flex alignItems='center' gap={4}>
          <Box w='45%'>
            <Flex
              justify='flex-start'
              align='center'
              gap='10px'
              m='0 34px 0 0'
              p='12px 12px 12px 16px'
              borderRadius='12px'
              bg='#ffffff'
              w='full'
            >
              {setSeeCtx?.selectedFirstDropdown?.label === 'Location'
                ? 'Job'
                : 'Location'}
            </Flex>
          </Box>
          <Text as='b'>{t('visibility.is_text')}</Text>
          <Container w='45%' p='0' m='0'>
            {dropdownRender()}
          </Container>
        </Flex>
      </>
    );
  }
};

ConditionalSelection.displayName =
  'displayName:pages/Handbook/setVisibilityModal/SetSeeModal/CustomFilterAccordion/ConditionalSelection';

export default ConditionalSelection;
