import { Box, Container, Flex, Text } from '@chakra-ui/react';
import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { CustomSelectComponent } from 'sub-components/CustomDropdowns';
import useCombinedStore from 'zustandStore/store';
import { IUserData } from '../set-see-modal-types';
import SetSeeDataContext from '../SetSeeModalStore/set-see-data-context';

const MembersSection: FC = () => {
  const { t } = useTranslation('deck');
  const setSeeCtx = useContext(SetSeeDataContext);
  const { visibilitySelection, updateVisibilitySelection } = useCombinedStore();

  const getMemberOptions = useMemo(() => {
    // Deep clone the members list to avoid mutating the original state
    let _members: IUserData[] = JSON.parse(
      JSON.stringify(setSeeCtx?.membersList)
    );

    // Filter members to include only those with an active status
    _members = _members.filter((member) =>
      ['active', 'pending']?.includes(member.status)
    );

    // Map over the filtered members to set the value property
    _members.forEach((member) => {
      member.value = member.eid;
    });

    return _members || [];
  }, [setSeeCtx?.membersList]);

  const getMemberIds = useCallback(() => {
    return visibilitySelection?.users?.map((member) => member);
  }, [setSeeCtx?.selectedMembersList]);

  const memberOnChangeHandler = (values: string[]) => {
    let filteredMembers = setSeeCtx?.membersList?.filter((member) =>
      values?.includes(member?.eid)
    );
    setSeeCtx?.updateSelectedMembersList(filteredMembers);
    let filteredMembersIds =
      filteredMembers?.map((member) => member?.eid) || [];
    if (visibilitySelection) {
      updateVisibilitySelection({
        ...visibilitySelection,
        users: filteredMembersIds,
      });
    }
  };

  return (
    <Flex flexDir='column'>
      <Container w='100%' p='0' m='0' maxW='container.sm' mt='3'>
        <Text color='#6f767e' fontSize='14px'>
          {t('visibility.specify_individuals_text_job_changed')}
        </Text>
      </Container>
      <Box>
        <Flex
          flexDir='column'
          mt={4}
          w='full'
          bg='#f7f7f7'
          p={8}
          borderRadius='12px'
        >
          <Text fontSize='16px' fontWeight={600} m='0 0 1rem'>
            {t('visibility.members_heading')}
          </Text>
          <Flex justify='center' align='center'>
            <Box w='45%'>
              <Flex
                justify='flex-start'
                align='center'
                gap='10px'
                m='0 34px 0 0'
                p='12px 12px 12px 16px'
                borderRadius='12px'
                bg='#ffffff'
                w='full'
              >
                {t('visibility.members_heading')}
              </Flex>
            </Box>
            <Text as='span' m='0 1rem' fontWeight={600}>
              {t('visibility.is_text')}
            </Text>
            <Box w='45%'>
              <CustomSelectComponent
                options={getMemberOptions}
                value={getMemberIds()}
                placeholder='Select...'
                showHeader
                headerTitle='Select member(s)'
                headerBarColor={HeaderColors.Purple}
                showSelectAll
                showHeaderCloseIcon
                showSearchField
                showDivider
                bottomOutsideBtn={{
                  show: true,
                  title: 'Add selected members',
                }}
                showFilter
                onChange={(value: string[]) => memberOnChangeHandler(value)}
                showChips={false}
                customWidth='600px'
                selectInputBg='white'
              />
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

MembersSection.displayName =
  'displayName:pages/Handbook/setVisibilityModal/SetSeeModal/CustomFilterAccordion/MembersSection';

export default MembersSection;
