import { useMutation } from '@apollo/client';
import { AddIcon } from '@chakra-ui/icons';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import {
  ConfigTaskForm,
  mapTrigger,
} from 'sub-components/Launcher/launcher-config/common';
import { ITaskInput } from 'sub-components/Launcher/launcher-config/common/config-task-form/task-form.types';
import { BoxHeader } from 'ui-components';
import {
  ADD_TASK_TO_LAUNCHER,
  LocationPhaseDetails,
} from './LauncherLocation.graphql';
import { cloneDeep } from '@apollo/client/utilities';

interface IProps {
  refetch?: () => void;
  phaseDetails: LocationPhaseDetails[];
}

const AddLocationTask: FC<IProps> = ({ refetch, phaseDetails }) => {
  const { onOpen, isOpen, onClose } = useDisclosure();

  const { locationId } = useParams<{ locationId: string }>();

  const [addTaskToLauncher] = useMutation(ADD_TASK_TO_LAUNCHER);

  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });

  const cleanTaskSteps = (task: any) => {
    return {
      ...task,
      steps: task.steps.map((step: any) =>
        Object.entries(step).reduce((acc, [key, value]) => {
          if (value !== null) {
            acc[key] = value;
          }
          return acc;
        }, {})
      ),
    };
  };

  const onSave = async (task: ITaskInput) => {
    const taskPayload = cloneDeep(task) as Record<string, any>;

    delete taskPayload.phaseEid;
    delete taskPayload.isRequired;
    delete taskPayload.sectionIndex;
    delete taskPayload.phase;

    const cleanedTask = cleanTaskSteps(taskPayload);

    const currentPhase = phaseDetails.find(
      (phase) => phase.eid === task.phaseEid
    );

    // if there is a phase assignee and no task assignee
    if (
      currentPhase &&
      currentPhase.assignedType &&
      !taskPayload.assignedType
    ) {
      cleanedTask.assignedType = currentPhase.assignedType;
      cleanedTask.assignedUser = currentPhase.assignedUser || [];
    }

    // if no phase assignee and task assignee then assign to location

    if (
      currentPhase &&
      !currentPhase.assignedType &&
      !taskPayload.assignedType
    ) {
      cleanedTask.assignedType = 'location';
    }

    cleanedTask.trigger = mapTrigger(cleanedTask.trigger) || undefined;

    await addTaskToLauncher({
      variables: {
        input: {
          launchId: locationId,
          phaseId: task.phaseEid,
          task: cleanedTask,
        },
      },
    })
      .then((res) => {
        if (res.data?.AddNewTaskToLauncher?.eid) {
          toast({
            status: 'success',
            title: 'Success',
            description: 'Task created successfully',
          });

          onClose();
          if (refetch) refetch();
        }
      })
      .catch(() => {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Failed to create task',
        });
      });
  };

  return (
    <div>
      <Button
        fontSize={15}
        fontWeight={600}
        leftIcon={<AddIcon />}
        color='#2A85FF'
        variant='outline'
        border='1px solid #DDDDDD'
        background='#FFFFFF'
        width='fit-content'
        onClick={onOpen}
        mr={4}
      >
        Add task
      </Button>

      <Drawer onClose={onClose} isOpen={isOpen} size='lg'>
        <DrawerOverlay />
        <DrawerContent padding='1rem'>
          <DrawerCloseButton
            position='absolute'
            top='2rem'
            right='2.5rem'
            borderRadius='full'
          />
          <DrawerHeader>
            <BoxHeader
              title='Add custom task'
              color={HeaderColors.Purple}
              fontSize='18px'
            />
          </DrawerHeader>
          <DrawerBody>
            <ConfigTaskForm onSave={onSave} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

AddLocationTask.displayName =
  'sub-components/Launcher/dashboard/Location/AddLocationTask';

export default AddLocationTask;
