import { Box, Center, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'atoms';
import React, { FC } from 'react';

interface IProps {
  onOkPress?: (...args: any[]) => any | PromiseLike<any>;
  onCancelPress?: (...args: any[]) => any | PromiseLike<any>;
}

const ModalContent: FC<IProps> = ({ onOkPress, onCancelPress }) => {
  return (
    <Center flexDir='column' gap={4}>
      <Center boxSize='56px' borderRadius='50%' bg='rgba(255, 216, 141, 0.5)'>
        <FontAwesomeIcon
          icon={faTriangleExclamation as IconProp}
          color='rgba(244, 163, 5, 1)'
          fontSize='24px'
        />
      </Center>
      <Flex gap={4} flexDir='column'>
        <Box textAlign='center'>
          <Box as='span'>
            Adding a phase assignee entails all the tasks in this phase will be
          </Box>
          &nbsp;
          <Box as='span' fontWeight={700} fontSize='14px'>
            removed from the current assignees task list
          </Box>
          &nbsp;
          <Box as='span'>
            and will be added to the phase assignees task list
          </Box>
        </Box>
        <Flex flexDir='column' gap={2} w='full'>
          <Button
            size='lg'
            variant='solid'
            colorScheme='blue'
            onClick={onOkPress}
          >
            Continue
          </Button>
          <Button size='lg' variant='outline' onClick={onCancelPress}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Center>
  );
};

ModalContent.displayName =
  'displayName:pages/LocationsNew/Components/ProjectDetailPhase/components/modals/addPhaseAssigneeInfo/ModalContent';

export default ModalContent;
