import { LauncherConfig } from 'sub-components/Launcher/launcher-config/common/launcher-config.fragment';
import { ExtendedLauncherConfig } from '../store/project-detail-phase.types';

export const toStoreData = (
  data: LauncherConfig
): ExtendedLauncherConfig | undefined => {
  return {
    ...data,
    contents: data.contents.map((content) => ({
      ...content,
      toBeUnlocked: '',
      duration: '',
      phaseCompleted: false,
      showChoice: false,
      selectedChoice: undefined,
      contentCopy: content,
    })),
  };
};
