import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Badge,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';
import { ColumnsType, FlexCard, Table } from '../../../../atoms';
import { BoxHeader } from '../../../../ui-components';
import { HeaderColors } from '../../../../configs';
import { AppRoute, useHistory } from '../../../../routes';
import { TabItem } from '../LocationAttention/components/TabItem';
import { PiMapPinFill } from 'react-icons/pi';
import { TableData } from '../dashboard-grapghql';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { formatDateToCustom } from '../helper';

interface TaskType {
  eid: string;
  name: string;
  entityId: string;
  __typename: string;
}

interface IProps {
  tableData: TableData[];
  taskTypeOptions: TaskType[];
}

const AllLocationTable: FC<IProps> = ({
  tableData: initialTableData,
  taskTypeOptions,
}) => {
  const [activeTabList, setActiveTabList] = useState<
    'byStatus' | 'byPhases' | 'byTasktype'
  >('byStatus');
  const [selectedTab, setSelectedTab] = useState<any>('');
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();

  // Sort table data by start date
  const sortedTableData = useMemo(() => {
    return [...initialTableData].sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  }, [initialTableData]);

  // Generate tab lists dynamically
  const TabLists = useMemo(() => {
    const byStatus = Array.from(
      new Set(sortedTableData.map((item) => item.status))
    ).map((status) => ({
      label: status,
      filterKey: 'status',
      filterValue: status,
    }));
    const byPhases = Array.from(
      new Set(sortedTableData.map((item) => item.phase))
    ).map((phase) => ({
      label: phase,
      filterKey: 'phase',
      filterValue: phase,
    }));

    // Map taskTypeOptions to byTasktype tabs
    const byTasktype = taskTypeOptions.map((taskType) => ({
      label: taskType.name,
      filterKey: 'taskCategoryEid',
      filterValue: taskType.eid,
    }));

    return { byStatus, byPhases, byTasktype };
  }, [sortedTableData, taskTypeOptions]);

  // Dynamically fetch tabs based on activeTabList
  const tabs = TabLists[activeTabList];

  // Set default selectedTab to the first tab of activeTabList
  useEffect(() => {
    if (tabs.length > 0) {
      // Select the first sub-task that has items in it for "byTasktype"
      if (activeTabList === 'byTasktype') {
        const firstSubtaskWithItems = tabs.find((tab) =>
          sortedTableData.some(
            (item) => item.taskCategoryEid === tab.filterValue
          )
        );
        if (firstSubtaskWithItems) {
          setSelectedTab(firstSubtaskWithItems.filterValue);
        }
      } else {
        setSelectedTab(tabs[0].filterValue); // Select the first tab for other categories
      }
    }
  }, [tabs, activeTabList]);

  // Filter data based on selected tab
  const filteredData = useMemo(() => {
    if (!selectedTab) return sortedTableData;

    return activeTabList === 'byTasktype'
      ? sortedTableData.filter((item) => item.taskCategoryEid === selectedTab)
      : sortedTableData.filter(
          (item) => item[tabs[0].filterKey as keyof TableData] === selectedTab
        );
  }, [tabs, selectedTab, sortedTableData, activeTabList]);

  // console.log(filteredData);

  // Define columns for the table
  const columns = useMemo<ColumnsType<TableData>>(
    () => [
      {
        title: (
          <Text fontSize='14px' fontWeight={600} mx='10px'>
            Location
          </Text>
        ),
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text: string, record: TableData) => (
          <Badge
            bgColor={
              record.status === 'On-Track' ? HeaderColors.Green : '#FFE0E5'
            }
            variant='solid'
            textColor={HeaderColors.Black}
            fontSize='14px'
            borderRadius='7px'
            fontWeight={400}
            px='10px'
            py='5px'
            mx='10px'
          >
            <Tooltip label={text} isDisabled={text.length <= 20}>
              <Flex align='center' justify='center' gap='8px'>
                <Flex
                  bgColor={
                    record.status === 'On-Track'
                      ? HeaderColors.GreenDark
                      : '#FF4766'
                  }
                  p='1'
                  borderRadius='5px'
                  align='center'
                  justify='center'
                >
                  <PiMapPinFill color='white' />
                </Flex>
                <Text isTruncated maxWidth='180px'>
                  {text}
                </Text>
              </Flex>
            </Tooltip>
          </Badge>
        ),
      },
      {
        title: (
          <Text fontSize='14px' fontWeight={600} mx='15px'>
            Completion%
          </Text>
        ),
        dataIndex: 'rate',
        render: (text: string) => (
          <Text fontSize='14px' fontWeight={500} mx='10px'>
            {text}%
          </Text>
        ),
      },
      {
        title: (
          <Text fontSize='14px' fontWeight={600} mx='10px'>
            {' '}
            Current task{' '}
          </Text>
        ),
        dataIndex: 'task',
        render: (text: string) => (
          <Text
            fontSize='14px'
            fontWeight={500}
            mx='10px'
            whiteSpace='pre-line'
            w={'250px'}
          >
            {text}
          </Text>
        ),
      },
      {
        title: (
          <Text fontSize='14px' fontWeight={600} mx='10px'>
            Start date
          </Text>
        ),
        dataIndex: 'startDate',
        render: (text: string) => (
          <Text fontSize='14px' fontWeight={500} mx='10px'>
            {formatDateToCustom(text)}
          </Text>
        ),
      },
      {
        title: (
          <Text fontSize='14px' fontWeight={600} mx='10px'>
            {' '}
            Go-Live date{' '}
          </Text>
        ),
        dataIndex: 'goLiveDate',
        render: (text: string) => (
          <Text fontSize='14px' fontWeight={500} mx='10px'>
            {formatDateToCustom(text)}
          </Text>
        ),
      },
      {
        title: (
          <Text fontSize='14px' fontWeight={600} mx='10px'>
            {' '}
            Status{' '}
          </Text>
        ),
        dataIndex: 'status',
        render: (status: string) => (
          <Badge
            bgColor={status === 'On-Track' ? HeaderColors.Green : '#FF6A5526'}
            textColor={
              status === 'On-Track' ? HeaderColors.GreenDark : '#FF6A55'
            }
            variant='solid'
            fontSize={'12px'}
            borderRadius={'7px'}
            fontWeight={600}
            px={2}
            py={1}
            mx={'10px'}
          >
            {status}
          </Badge>
        ),
      },
    ],
    []
  );

  // Function to handle scrolling
  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  // Monitor the scroll position to show or hide chevrons
  useEffect(() => {
    const checkScrollAvailability = () => {
      if (scrollRef.current) {
        setCanScrollLeft(scrollRef.current.scrollLeft > 0);
        setCanScrollRight(
          scrollRef.current.scrollLeft <
            scrollRef.current.scrollWidth - scrollRef.current.clientWidth
        );
      }
    };

    checkScrollAvailability();

    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', checkScrollAvailability);
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener(
          'scroll',
          checkScrollAvailability
        );
      }
    };
  }, [scrollRef]);

  return (
    <FlexCard flexDir='column' borderRadius='9px' gap={4} mt='26px'>
      <BoxHeader title='All Locations' color={HeaderColors.Orange} />
      <Tabs
        variant='unstyled'
        w='full'
        my={'16px'}
        onChange={(index) => {
          const newActiveTabList = Object.keys(TabLists)[
            index
          ] as keyof typeof TabLists;
          setActiveTabList(newActiveTabList);
          if (newActiveTabList === 'byTasktype') {
            const firstSubtaskWithItems = TabLists[newActiveTabList].find(
              (tab) =>
                sortedTableData.some(
                  (item) => item.taskCategoryEid === tab.filterValue
                )
            );
            if (firstSubtaskWithItems) {
              setSelectedTab(firstSubtaskWithItems.filterValue);
            } else {
              setSelectedTab('');
            }
          } else {
            setSelectedTab(TabLists[newActiveTabList][0].filterValue);
          }
        }}
      >
        <Flex gap={'8px'} alignItems={'center'} py={2}>
          <Text
            fontSize={'15px'}
            fontWeight={600}
            color={'#6F767E'}
            mr={'20px'}
            ml={'30px'}
          >
            Group by:
          </Text>
          <TabList gap={'20px'}>
            {Object.keys(TabLists).map((key, index) => (
              <Tab
                key={index}
                isSelected={activeTabList === key}
                borderRadius='8px'
                py='8px'
                px='16px'
                bg={activeTabList === key ? '#EFEFEF' : ''}
                color={activeTabList === key ? '#1A1D1F' : '#6F767E'}
                fontSize='15px'
                fontWeight='600'
                display='flex'
                alignItems='center'
                justifyContent='center'
                whiteSpace='nowrap'
              >
                <Text fontSize='14px' fontWeight={600}>
                  {key?.replace('by', 'By ')}
                </Text>
              </Tab>
            ))}
          </TabList>
        </Flex>

        <TabPanels>
          {Object.values(TabLists).map((tabOptions, tabIndex) => (
            <TabPanel key={tabIndex}>
              <Flex align='center' position='relative' ref={scrollRef}>
                {canScrollLeft && (
                  <IconButton
                    aria-label='Scroll Left'
                    icon={<ChevronLeftIcon />}
                    onClick={scrollLeft}
                    position='absolute'
                    left='0'
                    zIndex='1'
                    size='md'
                    bg='white'
                    variant='ghost'
                  />
                )}
                <Flex
                  gap='20px'
                  borderBottom='1px solid #E2E8F0'
                  overflowX='auto'
                  padding='0'
                  css={{
                    scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': { display: 'none' },
                  }}
                >
                  {tabOptions.map((tab) => (
                    <TabItem
                      key={tab.filterValue}
                      label={tab.label}
                      count={
                        activeTabList === 'byTasktype'
                          ? sortedTableData.filter(
                              (item) => item.taskCategoryEid === tab.filterValue
                            ).length
                          : sortedTableData.filter(
                              (item) =>
                                item[tab.filterKey as keyof TableData] ===
                                tab.filterValue
                            ).length
                      }
                      isSelected={selectedTab === tab.filterValue}
                      onClick={() => setSelectedTab(tab.filterValue)}
                      status={tab.filterValue}
                      countColor={
                        (tab.filterValue === 'Lagging' ||
                          tab.filterValue === 'On-Track') &&
                        selectedTab === tab.filterValue
                          ? '#33383F'
                          : tab.filterValue === 'Lagging'
                          ? HeaderColors.Red.Mid
                          : tab.filterValue === 'On-Track'
                          ? HeaderColors.GreenDark
                          : '#33383F'
                      }
                    />
                  ))}
                </Flex>
                {canScrollRight && (
                  <IconButton
                    aria-label='Scroll Right'
                    icon={<ChevronRightIcon />}
                    onClick={scrollRight}
                    position='absolute'
                    right='0'
                    zIndex='1'
                    size='md'
                    bg='white'
                    variant='ghost'
                  />
                )}
              </Flex>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      <Table<TableData>
        columns={columns}
        dataSource={filteredData}
        onRow={(record) => ({
          onClick: () =>
            history.push({
              pathname: AppRoute.LAUNCHER_LOCATION_DETAIL,
              params: { locationId: record.launchId },
            }),
        })}
        bordered
      />
    </FlexCard>
  );
};

export default AllLocationTable;
