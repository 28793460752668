import React, { FC } from 'react';
import { Center, Flex } from '@chakra-ui/react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { AuthRole } from '../../../authorization';
import AuditTabs from './AuditTabs';
import TabsContent from './TabsContent';
import { AuditTabProvider } from './store/context';
import { useUserDataSelector } from 'hooks';
import EmptyState from 'sub-components/EmptyState';
import Loader from 'sub-components/Loader';

const QUERY = gql`
  query AuditAssignedCount($filter: FilterFindManyAuditSessionInput) {
    AuditSessionPagination(filter: $filter) {
      count
    }
  }
`;

interface Response {
  AuditSessionPagination: { count: number };
}

const AuditTabContainer: FC = () => {
  const { t } = useTranslation(['audit']);
  const isAdmin = useUserDataSelector(
    (state) => state.type === 'user' && state.authRole === AuthRole.ADMIN
  );

  const { data, loading } = useQuery<Response>(QUERY, {
    skip: !isAdmin,
    variables: {
      filter: {
        isAssigned: true,
      },
    },
  });

  if (loading) {
    return (
      <Center h='70vh'>
        <Loader />
      </Center>
    );
  }

  if (isAdmin && !data?.AuditSessionPagination?.count) {
    return (
      <Center h='70vh'>
        <EmptyState image='MyTask' title='You are not supervising any audits' />
      </Center>
    );
  }

  return (
    <AuditTabProvider>
      <Flex flexDir='column' gap={4}>
        <AuditTabs />
        <TabsContent />
      </Flex>
    </AuditTabProvider>
  );
};

AuditTabContainer.displayName =
  'sub-components/audits/audit-tab/AuditTabContainer';

export default AuditTabContainer;
