import React, { FC } from 'react';
import { Center, Flex } from '@chakra-ui/react';

interface IProps {
  selectedTab: string;
  onChange: (name: string) => void;
}

const ArchiveTabs: FC<IProps> = ({ selectedTab, onChange }) => {
  return (
    <Flex gap={4} fontSize='15px' fontWeight='600'>
      <Center
        h='40px'
        borderRadius='8px'
        cursor='pointer'
        aria-selected={selectedTab === 'audit'}
        p='8px 16px'
        bg='#FCFCFC'
        color='#6F767E'
        _selected={{
          bg: '#EFEFEF',
          color: 'black',
        }}
        onClick={() => onChange('audit')}
      >
        Archived Audits
      </Center>
      <Center
        h='40px'
        borderRadius='8px'
        cursor='pointer'
        aria-selected={selectedTab === 'draft'}
        p='8px 16px'
        bg='#FCFCFC'
        color='#6F767E'
        _selected={{
          bg: '#EFEFEF',
          color: 'black',
        }}
        onClick={() => onChange('draft')}
      >
        Archived Drafts
      </Center>
    </Flex>
  );
};

export default ArchiveTabs;
