import React, { FC } from 'react';
import { Box, Tooltip } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { useSchedulerInfo } from './useSchedulerInfo';

interface IProps {}

const ScheduleStepperDescription: FC<IProps> = () => {
  const { t } = useTranslation('task');

  const transObject = useSchedulerInfo();
  console.log({ transObject });
  return (
    <Tooltip
      label={<Trans t={t} {...transObject} />}
      hasArrow
      borderRadius='6px'
      padding='4px 8px'
    >
      <Box gap={1} isTruncated>
        <Trans t={t} {...transObject} />
      </Box>
    </Tooltip>
  );
};

export default ScheduleStepperDescription;
