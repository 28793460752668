import React, { FC } from 'react';

import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';

interface IProps
  extends Pick<BoxProps, 'noOfLines' | 'fontSize' | 'isTruncated'> {
  title: string;
  desc?: string;
  color?: BoxProps['bg'];
  isRequired?: boolean;
  boxWidth?: BoxProps['w'];
  margintop?: string;
  fontWeight?: string | number;
  textColor?: string;
}

const BoxHeader: FC<IProps> = ({
  title,
  color = '#b1e5fc',
  noOfLines,
  isTruncated,
  fontSize = '20px',
  isRequired,
  boxWidth = 4,
  margintop = 0,
  fontWeight = 600,
  textColor = '#1a1d1f',
}) => {
  return (
    <Flex
      align='center'
      overflow={isTruncated ? 'hidden' : undefined}
      mt={margintop}
    >
      <Box
        mr={boxWidth}
        w={boxWidth}
        minW={boxWidth}
        borderRadius={4}
        bg={color}
        style={{ aspectRatio: '1/2' }}
      />
      <Box
        fontWeight={fontWeight}
        fontSize={fontSize}
        lineHeight='32px'
        color='#1a1d1f'
        noOfLines={noOfLines}
        isTruncated={isTruncated}
        textColor={textColor}
      >
        {title}
      </Box>
      {isRequired && (
        <Text color='#FF6A55' fontWeight={600} fontSize='16px'>
          *
        </Text>
      )}
    </Flex>
  );
};

export default BoxHeader;
