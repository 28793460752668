import React, { FC, useContext, useMemo, useState } from 'react';
import { Center, Flex, Text } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRectangleVertical,
  faSquareList,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import SupervisedTaskDetailsDataContext from '../../store/supervised-task-details-context';

import StepDetails from './StepDetails';
import TaskStepItem from './TaskStepItem';
import { TaskItemEntity } from './task-item.types';
import { deployEvent, CommonAmplitudeEvents } from 'shared/amplitudeEvents';

export interface IChapterDataResponse {
  eid: string;
  name: string;
  profilePic: string;
  authRole: string;
  role: string;
  locations: {
    name: string;
  }[];
  completed: boolean;
  completedDate: string;
}

interface IProps {
  sessionId: string;
}

const TaskOverviewSteps: FC<IProps> = ({ sessionId }) => {
  const supervisedCtx = useContext(SupervisedTaskDetailsDataContext);
  const [stepDetailsOpen, setStepDetailsModal] = useState({
    show: false,
    id: '',
    type: '',
    selectedData: undefined,
  });

  const createdBy = supervisedCtx?.taskDetails?.createdByUser;

  const getChapterData: IChapterDataResponse[] = useMemo(() => {
    let allAssignees =
      supervisedCtx?.taskDetails?.lastSession?.assigneeUsers?.filter((user) =>
        ['active', 'pending']?.includes(user?.status)
      );
    let _chapterData: IChapterDataResponse[] = [];
    let assigneesCompletionData = allAssignees?.map((assignee) => {
      let completed = supervisedCtx?.taskDetails?.lastSession?.progress.some(
        (prog) =>
          prog.completedTasks.some(
            (task) =>
              task.contentId === stepDetailsOpen?.id &&
              prog?.userId === assignee?.eid
          )
      );
      let completedDate = '';
      supervisedCtx?.taskDetails?.lastSession?.progress.map((prog) =>
        prog.completedTasks.map((task) => {
          if (
            task.contentId === stepDetailsOpen?.id &&
            prog?.userId === assignee?.eid
          ) {
            completedDate = task?.completedAt;
          }
        })
      );
      return {
        ...assignee,
        completed,
        completedDate,
      };
    });
    return assigneesCompletionData;
  }, [stepDetailsOpen?.id]);

  const taskItems: TaskItemEntity[] =
    supervisedCtx?.taskDetails?.lastSession?.taskItems ||
    supervisedCtx?.taskDetails?.taskItems ||
    [];

  return (
    <Flex flexDir='column' gap='10px'>
      <Flex gap='10px' mb='10px'>
        <Text fontWeight={600} fontSize='14px' color='#979EA6'>
          STEPS ({taskItems?.length}):
        </Text>
        <Flex gap='5px'>
          <Center bg='#2A85FF' borderRadius='full' p='6px' w='25px'>
            <FontAwesomeIcon
              icon={faSquareList as IconProp}
              color='white'
              height='12px'
              width='12px'
            />
          </Center>
          <Text fontWeight={500}>
            {taskItems?.filter((item) => item?.type === 'form')?.length}{' '}
            {taskItems?.filter((item) => item?.type === 'form')?.length === 1
              ? 'Form'
              : 'Forms'}
          </Text>
        </Flex>
        <Flex gap='5px'>
          <Center bg='#83BF6E' borderRadius='full' p='6px' w='25px'>
            <FontAwesomeIcon
              icon={faRectangleVertical as IconProp}
              color='white'
              height='12px'
              width='12px'
            />
          </Center>
          <Text fontWeight={500}>
            {taskItems?.filter((item) => item?.type === 'sop')?.length}{' '}
            {taskItems?.filter((item) => item?.type === 'sop')?.length === 1
              ? 'Chapter'
              : 'Chapters'}
          </Text>
        </Flex>
      </Flex>
      <Flex
        id='step-task-list-flex'
        flexDir='column'
        overflowY='scroll'
        h='calc(100vh - 350px)'
      >
        {taskItems?.map((item) => {
          return (
            <TaskStepItem
              key={item.eid}
              item={item}
              createdBy={createdBy}
              onClick={() => {
                deployEvent(
                  CommonAmplitudeEvents.VIEW_SUPERVISED_BY_ME_TASK_STEP
                );

                setStepDetailsModal({
                  show: true,
                  id: item?.eid,
                  type: item?.type,
                  selectedData: item as never,
                });
              }}
            />
          );
        })}
      </Flex>
      {stepDetailsOpen && (
        <StepDetails
          isOpen={stepDetailsOpen?.show}
          onClose={() =>
            setStepDetailsModal({
              show: false,
              id: '',
              type: '',
              selectedData: undefined,
            })
          }
          formId={stepDetailsOpen?.id}
          type={stepDetailsOpen?.type}
          selectedChapterDetail={stepDetailsOpen?.selectedData}
          chapterData={getChapterData}
          sessionId={sessionId}
        />
      )}
    </Flex>
  );
};

export default TaskOverviewSteps;
