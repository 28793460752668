import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { BoxHeader } from '../../../../../ui-components';
import { HeaderColors } from '../../../../../configs';

import { ReactComponent as TriggerIcon } from '../../../../../assets/images/trigger-intro.svg';
import { ReactComponent as TaskIcon } from '../../../../../assets/images/sidebar/task.svg';

export const IntroItem: FC = ({ children }) => {
  return (
    <Flex
      gap={3}
      color='#6F767E'
      fontSize='14px'
      fontWeight='600'
      alignItems='center'
    >
      <TaskIcon
        color='#83BF6E'
        width='20'
        height='20'
        style={{ minWidth: '20px' }}
      />
      {children}
    </Flex>
  );
};

interface IProps {
  title: string;
  infoText?: string;
}

const BaseTriggerIntro: FC<IProps> = ({ title, infoText, children }) => {
  return (
    <Flex
      width='50%'
      gap='40px'
      flexDir='column'
      bg='#F7F7F7'
      borderLeftRadius='12px'
      padding='24px 32px'
    >
      <BoxHeader title={title} color={HeaderColors.Purple} />

      <Flex
        flexDir='column'
        maxW='390px'
        gap='52px'
        alignSelf='center'
        paddingTop='24px'
      >
        <Flex justify='center'>
          <TriggerIcon />
        </Flex>

        <Box fontSize='32px' fontWeight={600} textAlign='center' paddingX={5}>
          Introducing Triggers on Tasks or Phases
        </Box>

        <Flex flexDir='column' gap={4}>
          {children}
        </Flex>
      </Flex>

      <Flex
        flexDir='column'
        padding={4}
        gap={2}
        bgColor='#FFBC993B'
        border='1px solid #FFBC99'
        borderRadius='12px'
        textAlign='center'
      >
        <Box fontSize='13px' fontWeight='600' color='#33383F'>
          If a trigger is not applied?
        </Box>
        <Box fontSize='12px' fontWeight='700' color='#999999'>
          {infoText}
        </Box>
      </Flex>
    </Flex>
  );
};

BaseTriggerIntro.displayName = 'sc/L/lc/c/t/BaseTriggerIntro';

export default BaseTriggerIntro;
