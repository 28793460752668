import React, { FC } from 'react';
import { DashboardContainer } from './../../sub-components';
import { NotificationContainer } from '../../sub-components/NotificationCenter';
import NotificationHeading from './NotificationHeading';

const NotificationCenter: FC = () => {
  return (
    <DashboardContainer>
      <NotificationHeading />
      <NotificationContainer />
    </DashboardContainer>
  );
};
NotificationCenter.displayName = 'p/NC/NotificationCenter.tsx';
export default NotificationCenter;
