import {
  Box,
  Center,
  Flex,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Progress,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FC, useState } from 'react';

import caution from 'assets/images/dashboard/caution.svg';
import goLiveBg from 'assets/images/dashboard/go-live.svg';
import thumb from 'assets/images/dashboard/location-progress-thumb.svg';

import { useMutation } from '@apollo/client';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from 'atoms';
import { AuthRole, Authorization } from 'authorization';
import { HeaderColors } from 'configs';
import moment from 'moment';
import { BoxHeader } from 'ui-components';
import { SimpleDatePicker } from 'ui-components/DateFilter/components';
import { LocationPhaseDetails } from './LauncherLocation.graphql';
import TaskViewCard from './TaskViewCard';
import { formatDate, getTasksSummary } from './helper';
import { UPDATE_GO_LIVE_DATE } from 'pages/launcher/details/graphql/location-launch-details.graphql';

interface IProps {
  phaseDetails: LocationPhaseDetails[];
  goLiveDate: Date;
  locationId?: string;
  refetchData?: () => void;
  name: string;
}

const LauncherLocationTaskProgress: FC<IProps> = ({
  phaseDetails,
  goLiveDate,
  locationId,
  refetchData,
  name,
}) => {
  const {
    progress,
    totalTasks,
    completedTasks,
    currentTask,
    lastTask,
    upcomingTask,
  } = getTasksSummary(phaseDetails, goLiveDate);
  const toast = useToast({
    duration: 3000,
    position: 'top-right',
    isClosable: true,
  });

  const { isOpen, onClose, onOpen } = useDisclosure();

  const [updatedGoLive, setUpdatedGoLiveDate] = useState(
    moment()?.toISOString()
  );

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const [updateGoLiveDate, { loading }] = useMutation(UPDATE_GO_LIVE_DATE, {
    onCompleted: () => {
      refetchData?.();
      toast({
        status: 'success',
        title: 'Go live date updated',
      });
      onClose();
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Go live date could not be updated',
      });
      onClose();
    },
  });

  const updateGoLiveDateHandler = () => {
    updateGoLiveDate({
      variables: {
        locationId: locationId,
        liveDate: updatedGoLive,
      },
    });
  };

  return (
    <Box
      width='100%'
      px='24px'
      py='20px'
      borderRadius='xl'
      backgroundColor='white'
      display='flex'
      gap='1rem'
      flexDir='column'
    >
      {/* @ts-ignore */}
      <Text fontSize={15} fontWeight={600}>
        Total tasks done
      </Text>

      <Box position='relative'>
        <Box
          display='flex'
          gap='.5rem'
          flexDir='column'
          width={`calc(100% - 100px)`}
        >
          <Box position='relative'>
            <Progress
              value={progress}
              size='sm'
              backgroundColor='#F4F4F4'
              borderRadius='md'
              height='12px'
              border='2px solid #F4F4F4'
              transition='left 0.3s ease'
              sx={{
                '& > div': {
                  backgroundColor: progress >= 100 ? '#83BF6E' : '#FFD88D',
                },
              }}
            />

            <Box
              as='img'
              src={thumb}
              alt='Progress Thumb'
              height='32px'
              width='32px'
              position='absolute'
              top='-10px'
              left={`calc(${progress}% - 16px)`}
              transition='left 0.3s ease'
              zIndex={1}
            />
          </Box>
          <Box
            as='img'
            src={goLiveBg}
            alt='Go live date'
            height='100px'
            width='100px'
            position='absolute'
            top='-45px'
            right='0%'
          />
          <Box
            position='absolute'
            top='-18px'
            right='25px'
            width='50px'
            textAlign='center'
            display='flex'
            flexDirection='column'
            gap={1}
          >
            <Box as='span' fontSize={12} color='#FFA800' lineHeight='12px'>
              Go-live!
            </Box>
            <Box
              as='span'
              fontSize={13}
              fontWeight={600}
              color='#33383F'
              lineHeight='16px'
            >
              {formatDate(goLiveDate)}
            </Box>
          </Box>
          <Box as='span' fontSize={14} fontWeight={600} color={'#A6B1BC'}>
            {progress}
            {''}% complete ({completedTasks}/{totalTasks})
          </Box>
        </Box>
        <Box position='absolute' top={9} right='2.4rem'>
          <Authorization
            permittedFor={['user']}
            permittedRoles={[AuthRole.SUPER_ADMIN]}
          >
            <SimpleDatePicker
              onChange={(value) => {
                value && setUpdatedGoLiveDate(value?.toISOString());
              }}
              selectedValue={moment(goLiveDate)?.toDate()}
              minDate={moment(goLiveDate)?.toDate()}
              placeholderText=''
              customInput={
                <Flex
                  width='fit-content'
                  cursor='pointer'
                  border='2px solid #FFD88D'
                  borderRadius='4px'
                  padding='2px'
                  fontSize='14px'
                  background='#FCFCFC'
                  onClick={() => setIsDatePickerOpen(true)}
                >
                  <FontAwesomeIcon icon={faPen as IconProp} />
                </Flex>
              }
            >
              <PrimaryButton
                title='Update Go-live date'
                colorScheme='blue'
                size='lg'
                style={{ marginTop: '15px' }}
                onClick={onOpen}
                isLoading={loading}
                disabled={loading}
              />
            </SimpleDatePicker>
          </Authorization>
        </Box>
      </Box>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size='xl'
        isCentered
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent padding='30px' w='full' borderRadius='16px'>
          <BoxHeader color={HeaderColors.Red.Light} title={'Please note'} />

          <Center marginTop='2rem' flexDirection='column' gap={4}>
            <Image src={caution} />
            <Box
              as='span'
              maxWidth='30rem'
              fontSize='14px'
              color='#33383F'
              textAlign='center'
            >
              {` You are updating the Go-live date for ${name} from `}
              <Box as='span' fontWeight={700}>
                {moment(goLiveDate).format('D MMMM YYYY')}
              </Box>

              {` to `}
              <Box as='span' fontWeight={700}>
                {moment(updatedGoLive).format('D MMMM YYYY')}
              </Box>

              {` Deadlines will be changed for certain tasks
              with deadline as Go-live date. Are you sure?`}
            </Box>
            <Flex flexDirection='column' gap={2} width='full'>
              <PrimaryButton
                colorScheme='blue'
                onClick={updateGoLiveDateHandler}
                isLoading={loading}
                disabled={loading}
              >
                Update Go-live date
              </PrimaryButton>
              <PrimaryButton variant='outline'>Cancel</PrimaryButton>
            </Flex>
          </Center>

          <ModalCloseButton position='absolute' top='1rem' right='1rem' />
        </ModalContent>
      </Modal>

      <Authorization
        permittedRoles={[AuthRole.SUPER_ADMIN]}
        permittedFor='user'
      >
        <Box display='flex' gap='2rem' marginTop={5}>
          {currentTask && <TaskViewCard status='current' task={currentTask} />}
          {lastTask && <TaskViewCard status='last' task={lastTask} />}
          {upcomingTask && (
            <TaskViewCard status='upcoming' task={upcomingTask} />
          )}
        </Box>
      </Authorization>
    </Box>
  );
};

LauncherLocationTaskProgress.displayName =
  'sub-components/Launcher/dashboard/Location/LauncherLocationTaskProgress';

export default LauncherLocationTaskProgress;
