import { Box, Center, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'atoms';
import React, { FC } from 'react';
import { useProjectDetailPhaseContext } from '../store/context';
import { ViewMode } from '../types';

interface IProps {}

const PhaseAccordionFooter: FC<IProps> = () => {
  const { mode, updateMode } = useProjectDetailPhaseContext((state) => ({
    mode: state?.view,
    updateMode: state?.updateView,
  }));

  if (mode === 'view') {
    return null;
  }

  return (
    <Flex flexDir='column' gap={2}>
      <Center
        color='#FFA43D'
        bg='#FCF1E5'
        border='1px solid #FFAE52'
        borderRadius='7px'
        p='11px 12px'
        gap='5px'
      >
        <FontAwesomeIcon icon={faCircleExclamation as IconProp} size='lg' />
        <Box as='span'>
          You can add any custom tasks apart from the selected phases/tasks once
          the location is in the launcher!
        </Box>
      </Center>
      <Button
        variant='solid'
        colorScheme='blue'
        size='lg'
        onClick={() => updateMode('view')}
      >
        Save changes
      </Button>
    </Flex>
  );
};

PhaseAccordionFooter.displayName = 'p/LN/C/PDP/c/PhaseAccordionFooter';

export default PhaseAccordionFooter;
