import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { HeaderColors } from '../../../../configs';
import { AppRoute, useHistory } from '../../../../routes';
import FormInput from '../../../../atoms/FormInput';
import { ActionButton, BoxHeader } from '../../../../ui-components';
import TitleHeader from '../../../../sub-components/CardEditor/TitleHeader';

import { transformTemplate } from './transform-template';
import StepInfo from './StepInfo';
import { ILauncherSetupInput } from './launcher-setup.types';
import { useIdGenerator } from '../../../../sub-components/Launcher/launcher-config/common/useIdGenerator';

interface IProps {}

const StartFromScratch: FC<IProps> = () => {
  const { t } = useTranslation(['launcher']);
  const history = useHistory();

  const {
    formState: { isDirty },
    handleSubmit,
  } = useFormContext<ILauncherSetupInput>();

  const generateId = useIdGenerator();

  const onSubmit = async (values: ILauncherSetupInput) => {
    const eid = await generateId();
    const response = transformTemplate(
      [
        {
          eid: eid,
          category: values.category,
          tasks: [],
        },
      ],
      values.title
    );

    history.push({
      pathname: AppRoute.LAUNCHER_BOARD_CONFIG,
      search: '?viewMode=editor',
      state: {
        templatedData: response,
      },
    });
  };

  return (
    <Flex flexDir='column' gap='20px'>
      <Box fontSize='18px' fontWeight={600}>
        Set up your launcher in 3 easy steps!
      </Box>

      <Flex width='500px' pb={4}>
        <StepInfo label='Create Phases' index={1} />
        <StepInfo label='Add Tasks' index={2} flex={1} />
        <StepInfo label='Add your locations!' index={3} />
      </Flex>

      <BoxHeader
        fontSize='18px'
        title={t('launcher:addFirstPhase')}
        color={HeaderColors.Orange}
      />

      <Flex flexDir='column' mt={2}>
        <Box>
          <TitleHeader
            title='Phase title'
            desc='Phases are the groups in which tasks are organised in launcher'
            isRequired
          />
        </Box>

        <Controller<ILauncherSetupInput, 'category'>
          name='category'
          rules={{
            required: 'Phase title is required',
            validate: (value) => {
              const len = value.trim().length;

              if (len === 0) {
                return 'Please enter valid phase title';
              }
            },
          }}
          shouldUnregister
          defaultValue=''
          render={({ field, fieldState }) => (
            <FormControl mt={3} isInvalid={fieldState.invalid}>
              <FormInput
                placeholder='Name the phase'
                size='lg'
                variant='outline'
                {...field}
              />
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />
      </Flex>

      <ActionButton
        w='full'
        fontSize='15px'
        fontWeight='700'
        colorScheme='blue'
        actionFn={handleSubmit(onSubmit)}
        isDisabled={!isDirty}
      >
        {t('launcher:saveAndAddFirstTask')}
      </ActionButton>
    </Flex>
  );
};

export default StartFromScratch;
