import React, { FC } from 'react';
import { Flex, useModalContext, useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useApolloClient, useMutation } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import omit from 'lodash/omit';
import { useUploadImage } from '../../../../../hooks';
import { ActionButton } from '../../../../../ui-components';
import { updateObject } from '../../../../../utils/objectHelper';

import UpdateDocument from './common/UpdateDocument';
import { IDocumentInput } from './common/compliance.input';
import {
  ComplianceResponse,
  FETCH_COMPLIANCE,
  UPDATE_COMPLIANCE,
  UpdateComplianceInput,
  UpdateComplianceResponse,
} from './add-document.graphql';
import { toUpdateComplianceInput } from './compliance.helper';
import { eventBus } from '../../../../../shared/eventEmit';

import { getComplianceData } from './compliance-update.helper';
import UploadSkeleton from './UploadSkeleton';

interface IProps {
  complianceId: string | undefined;
  type?: 'update' | 'reminder';
  preDefinedLocationId?: string;
}

const UpdateCompliance: FC<IProps> = ({
  complianceId,
  type = 'update',
  preDefinedLocationId,
}) => {
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const client = useApolloClient();

  const { onClose } = useModalContext();

  const methods = useForm<IDocumentInput>({
    defaultValues: async () => {
      const response = await client.query<ComplianceResponse>({
        query: FETCH_COMPLIANCE,
        fetchPolicy: 'network-only',
        variables: {
          eid: complianceId,
        },
      });

      let complianceData = getComplianceData(
        response.data?.EntityComplianceById
      );

      if (preDefinedLocationId) {
        complianceData.preDefinedLocationId = preDefinedLocationId;
      }

      return complianceData;
    },
  });

  const [updateCompliance] = useMutation<
    UpdateComplianceResponse,
    UpdateComplianceInput
  >(UPDATE_COMPLIANCE, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Compliance document successfully updated.',
      });

      eventBus.emit('refetchCompliance');
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Compliance document updating failed!',
      });
    },
  });

  const uploadImage = useUploadImage();

  const onSubmit = async (values: IDocumentInput) => {
    const input = toUpdateComplianceInput(cloneDeep(values));
    if (values.file?.rawFile) {
      const fileUrl = await uploadImage(values.file?.rawFile!);
      const file = omit(values.file, 'rawFile');
      file.url = fileUrl;
      updateObject(input, ['file'], file);
    }

    const response = await updateCompliance({ variables: { input: input } });

    if (response.errors) {
      return Promise.reject(response.errors);
    }

    setTimeout(onClose);
  };

  return (
    <FormProvider {...methods}>
      <Flex flexDir='column' gap={4}>
        <UploadSkeleton>
          <UpdateDocument
            preDefinedLocationId={preDefinedLocationId}
            type={type}
            complianceId={complianceId}
          />
        </UploadSkeleton>
        <ActionButton
          isFullWidth
          colorScheme='blue'
          mt={2}
          actionFn={methods.handleSubmit(onSubmit)}
          isDisabled={!methods.formState.isDirty}
        >
          Update document
        </ActionButton>
      </Flex>
    </FormProvider>
  );
};

UpdateCompliance.displayName =
  'displayName:sub-components/nexus/Compliance/Create/components/UpdateCompliance';

export default UpdateCompliance;
