import React, { FC } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../atoms';
import { useAddAssignee } from '../add-assignee';
import { LauncherAssignee } from '../launcher-config.fragment';
import PhaseSettingAssignee from './PhaseSettingAssignee';

interface IProps {
  type: 'task' | 'phase';
}

const PhaseAssignee: FC<IProps> = ({ type }) => {
  const { t } = useTranslation('launcher');
  const { getValues, setValue } = useFormContext<LauncherAssignee>();
  const assignedType = useWatch<LauncherAssignee, 'assignedType'>({
    name: 'assignedType',
  });

  const editAssignee = useAddAssignee();

  const onEditAssignee = () => {
    editAssignee({
      value: {
        assignedType: getValues('assignedType'),
        assignedUser: getValues('assignedUser'),
      },
      onChange: async (value) => {
        setValue('assignedType', value.assignedType, {
          shouldDirty: true,
        });
        await new Promise((resolve) => setTimeout(resolve, 10));
        setValue('assignedUser', value.assignedUser, {
          shouldDirty: true,
        });
      },
    });
  };

  if (!assignedType) {
    return (
      <Button
        width='max-content'
        leftIcon={<AddIcon />}
        variant='outline'
        fontSize='12px'
        fontWeight='500'
        onClick={onEditAssignee}
      >
        {t('addAssignee', { context: type })}
      </Button>
    );
  }

  return <PhaseSettingAssignee onEditClick={onEditAssignee} />;
};

PhaseAssignee.displayName = 'sc/L/lc/c/pd/PhaseAssignee';

export default PhaseAssignee;
