import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { BoxHeader, useConfirm, UseConfirm } from 'ui-components';
import NewDocumentContent from './NewDocumentContent';
import UpdateCompliance from './UpdateCompliance';
import BaseTitle from './BaseTitle';
import BaseContent from './BaseContent';
import { Input } from './compliance.helper';
import { MetaComplianceData } from './add-document.graphql';

interface IProps {
  complianceId?: string;
  type?: 'update' | 'reminder';

  /**`localSave` is used to prevent api hit*/
  localSave?: boolean;

  /** Used to show read-only input field for license and will show instead of License dropdown */
  preDefinedLicense?: string;

  /** Used to show read-only input field for location name and will show instead of location dropdown */
  preDefinedLocationName?: string;

  /** Used to show pre selected location in the dropdown which will be read-only */
  preDefinedLocationId?: string;

  /** Used to return the data as callback that had to be used otherwise for api hit */
  localSaveCb?: (data: Input) => void;

  /** If file is selected from elsewhere and only the form has to be shown  */
  initialFile?: File;

  /** Successfully returned data callback */
  successCb?: (data: Input | undefined) => void;

  /** Meta Compliance Data: `MetaComplianceData` */
  metaComplianceData?: MetaComplianceData;
}

type ComplianceUpload = (props?: IProps) => void;

export const useComplianceUpload = (): ComplianceUpload => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(
    ({
      complianceId,
      type = 'update',
      localSave = false,
      preDefinedLicense = '',
      preDefinedLocationName = '',
      preDefinedLocationId = '',
      initialFile,
      metaComplianceData,
      localSaveCb,
      successCb,
    } = {}) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }

      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        // title: (
        //   <Flex justify='space-between' gap={3}>
        //     <BoxHeader
        //       title={complianceId ? 'Update document' : 'Add Document'}
        //       color={HeaderColors.Green}
        //     />
        //     <AlertDialogCloseButton
        //       pos='relative'
        //       borderRadius='full'
        //       top='0'
        //       right='0'
        //     />
        //   </Flex>
        // ),
        title: <BaseTitle type={type} complianceId={complianceId} />,
        // content: complianceId ? (
        //   <UpdateCompliance complianceId={complianceId} />
        // ) : (
        //   <NewDocumentContent />
        // ),
        content: (
          <BaseContent
            type={type}
            complianceId={complianceId}
            localSave={localSave}
            preDefinedLicense={preDefinedLicense}
            preDefinedLocationName={preDefinedLocationName}
            preDefinedLocationId={preDefinedLocationId}
            localSaveCb={localSaveCb}
            successCb={successCb}
            initialFile={initialFile}
            metaComplianceData={metaComplianceData}
          />
        ),
        isCentered: false,
        contentProps: {
          py: '12px',
          px: '8px',
          borderRadius: '12px',
          minW: '660px',
        },
        footer: null,
      });
    },
    []
  );
};
