import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import moment from 'moment/moment';

import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import { AlertBox } from '../../../../../../../../atoms';
import { IFormInput } from '../../../../../../../tasks/create-task';

import { FooterComponent } from '../common';
import ChapterListContainer, { ChapterResource } from './ChapterListContainer';

interface IProps {
  onAddClick?: (values: IFormInput['contents']) => void;
  values: ChapterResource[];
}

const ChapterContainer: FC<IProps> = ({ values, onAddClick }) => {
  const methods = useForm({
    defaultValues: async () => {
      await Promise.resolve();
      return {
        taskStepSelection: values.reduce<
          Record<string, ChapterResource['sopData']>
        >((acc, value) => {
          acc[value.eid] = value.sopData;
          return acc;
        }, {}),
      };
    },
  });

  const _onAddClick = () => {
    const taskStepSelection: Record<string, ChapterResource['sopData']> =
      methods.getValues('taskStepSelection');

    const values = Object.values(taskStepSelection).reduce((acc, value) => {
      if (value) {
        acc.push({
          type: 'sop',
          eid: value.eid,
          tempEid: value.eid,
          title: value.title,
          thumbnail: value.thumbnail,
          dateAdded: moment().format('D MMMM'),
          sopData: value,
        });
      }
      return acc;
    }, [] as IFormInput['contents']);

    return onAddClick?.(values);
  };

  return (
    <Flex width='full' flex={1} gap='32px'>
      <FormProvider {...methods}>
        <Flex flexDir='column' overflow='hidden' flex={1} gap={4}>
          <AlertBox
            bg='#FFD88D80'
            icon={faTriangleExclamation}
            iconProps={{ color: '#F5A100' }}
          >
            Adding chapters to launcher task will automatically assign them to
            the owners of launcher locations or those in development.
            <div>
              However, the visibility settings of these chapters will remain
              unchanged
            </div>
          </AlertBox>

          <ChapterListContainer values={values} />

          <FooterComponent onAddClick={_onAddClick} />
        </Flex>
      </FormProvider>
    </Flex>
  );
};

ChapterContainer.displayName =
  'sub-components/Launcher/launcher-config/common/config-task-form/task-steps/resource/chapter/ChapterContainer';

export default ChapterContainer;
