import { Flex, useToast } from '@chakra-ui/react';
import { PrimaryButton } from 'atoms';
import { ChapterSort } from 'pages/Chapters/chapters.graphql';
import React, { FC } from 'react';
import { ISelectedFolderData } from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import { useMoveSubFolderFooterLogic } from '../hooks';

interface IProps {
  folderDetails: ISelectedFolderData;
  selectedFolder: string;
  getVariables?: () => {
    page: number;
    perPage: number;
    filter: Record<string, any>;
    sort: ChapterSort;
  };
  onMoveSubFolderCompleteHandler: () => void;
}

const MoveSubFolderFooter: FC<IProps> = ({
  folderDetails,
  selectedFolder,
  getVariables,
  onMoveSubFolderCompleteHandler,
}) => {
  const _p = useMoveSubFolderFooterLogic({
    getVariables,
    onMoveSubFolderCompleteHandler,
  });

  return (
    <Flex w='full' justify='space-between' gap={4} px={8} py={6}>
      <PrimaryButton
        title={`Make '${_p.trimString(folderDetails?.name!, 6)}' a main folder`}
        variant='outline'
        colorScheme='blue'
        disabled={
          _p.makingSubFolderMainLoading ||
          _p.movingSubFolderLoading ||
          _p.gettingChaptersList ||
          _p.gettingFoldersList
        }
        isLoading={
          _p.makingSubFolderMainLoading ||
          _p.gettingChaptersList ||
          _p.gettingFoldersList
        }
        onClick={() => _p.makeSubFolderMain(folderDetails?.eid!)}
      />
      <PrimaryButton
        title='Move'
        variant='solid'
        colorScheme='green'
        isLoading={
          _p.movingSubFolderLoading ||
          _p.gettingChaptersList ||
          _p.gettingFoldersList
        }
        disabled={
          !selectedFolder ||
          _p.makingSubFolderMainLoading ||
          _p.movingSubFolderLoading ||
          _p.gettingChaptersList ||
          _p.gettingFoldersList
        }
        onClick={() =>
          _p.moveSubFolderHandler(selectedFolder, folderDetails?.eid!)
        }
      />
    </Flex>
  );
};

MoveSubFolderFooter.displayName =
  'displayName:pages/Chapters/CreateEditSubFolder/components/MoveSubFolderFooter';

export default MoveSubFolderFooter;
