import { Flex, Image } from '@chakra-ui/react';
import React, { FC } from 'react';
import closeIcon from '../../../../assets/images/closeIcon.png';
import GenericColorHeader from 'sub-components/GenericColorHeader';

interface IProps {
  title: string;
  onClose: () => void;
}

const Heading: FC<IProps> = ({ title, onClose }) => {
  return (
    <Flex justifyContent={'space-between'}>
      <GenericColorHeader title={title} color='#cabdff' />
      <Image
        onClick={onClose}
        src={closeIcon}
        alt='close'
        title='close'
        cursor='pointer'
        bg='#efefef'
        borderRadius='36px'
        padding='6px'
      />
    </Flex>
  );
};

Heading.displayName =
  'displayName:pages/Handbook/setVisibilityModal/SetSeeModal/Heading';

export default Heading;
