import { Flex, Text, Box } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import {
  AssignmentType,
  AuditorSectionResponseType,
} from './types/report.types';
import { FC } from 'react';
import { formatDateTime } from '../listing/helper';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Signature from './Signature';
import { generateValidId } from './helper';
import { Checkbox } from 'antd';

type AuditorSignatureProps = {
  response: AuditorSectionResponseType;
  type?: string;
  additionalData: any;
  assignment: AssignmentType;
};

const AuditorSignature: FC<AuditorSignatureProps> = ({
  response,
  type = 'web',
  additionalData,
}) => {
  return (
    <Flex flexDir={'column'} fontWeight={500}>
      <Box
        id={`${generateValidId('Auditor Section')}`}
        style={{
          padding: '0px 1.5rem',
          border: '1px solid #EFEFEF',
          borderRadius: '12px',
          margin: '2rem 0px',
          scrollMargin: '8vh',
        }}
      >
        <Flex gap={2} mt={'28px'} alignItems={'center'}>
          <Box
            borderRadius={'4px'}
            height={'32px'}
            width={'16px'}
            backgroundColor={'#CABDFF'}
          ></Box>
          <Text style={{ fontWeight: '600', fontSize: '24px' }}>
            Auditor Section
          </Text>
        </Flex>
        <Text
          mt={'32px'}
          lineHeight={'32px !important'}
          fontWeight={600}
          fontSize={'18px'}
        >
          Closing comments
        </Text>
        <Flex
          mb={'28px'}
          borderRadius={'16px'}
          padding={'12px 16px'}
          mt={'10px'}
          backgroundColor={
            response?.responses?.[0]?.closingComment !== '--'
              ? '#CABDFF66'
              : null
          }
        >
          <Text
            whiteSpace={'pre-line'}
            lineHeight={'24px !important'}
            fontWeight={500}
            fontSize={'14px'}
          >
            {response?.responses?.[0]?.closingComment
              ? response?.responses?.[0]?.closingComment
              : '--'}
          </Text>
        </Flex>
      </Box>

      {type === 'web' ? (
        <Flex
          id={`${generateValidId('Auditor Signature')}`}
          style={{
            padding: '0px 1.5rem',
            border: '1px solid #EFEFEF',
            borderRadius: '12px',
            margin: '2rem 0px',
            scrollMargin: '8vh',
          }}
        >
          <Box my={'28px'}>
            <Flex alignItems={'center'} gap={2}>
              <Box
                borderRadius={'4px'}
                height={'32px'}
                width={'16px'}
                backgroundColor={'#B5E4CA'}
              ></Box>
              <Text style={{ fontWeight: '600', fontSize: '24px' }}>
                Auditor Signature
              </Text>
            </Flex>
            <Flex gap={4} mt={'24px'}>
              <FontAwesomeIcon
                fontSize={'24px'}
                icon={faSquareCheck as IconProp}
              />
              <Text lineHeight={'24px !important'} fontSize={'14px'}>
                I have completed this assessment to the best of my knowledge.
                This audit is not intended to identify all safety and health
                hazards, but to identify all visible/known hazards as of the
                date printed on the completed assessment.
              </Text>
            </Flex>

            {response?.responses?.[0]?.auditorSign?.signUrl?.text ? (
              <Signature
                view={'web'}
                value={response?.responses?.[0]?.auditorSign?.signUrl}
              />
            ) : (
              '--'
            )}

            <Text mt={'14px'} fontWeight={500} fontSize={'14px'}>
              {response?.responses?.[0]?.auditorSign?.name
                ? response?.responses?.[0]?.auditorSign?.name
                : '--'}
            </Text>

            <Text fontWeight={500} fontSize={'14px'}>
              {`${
                response?.responses?.[0]?.auditorSign?.dateTime
                  ? formatDateTime(
                      response?.responses?.[0]?.auditorSign?.dateTime,
                      additionalData?.timeZone
                    ).dateString
                  : '--'
              } ${
                response?.responses?.[0]?.auditorSign?.dateTime
                  ? formatDateTime(
                      response?.responses?.[0]?.auditorSign?.dateTime,
                      additionalData?.timeZone
                    ).timeString
                  : '--'
              }`}
            </Text>
          </Box>
        </Flex>
      ) : (
        <Flex id={`${generateValidId('Auditor Signature')}`}>
          <Box mx={'1.5rem'} my={'28px'}>
            <Flex gap={4} mt={'24px'}>
              <FontAwesomeIcon
                fontSize={'24px'}
                icon={faSquareCheck as IconProp}
              />
              <Text lineHeight={'24px !important'} fontSize={'14px'}>
                I have completed this assessment to the best of my knowledge.
                This audit is not intended to identify all safety and health
                hazards, but to identify all visible/known hazards as of the
                date printed on the completed assessment.
              </Text>
            </Flex>

            {response?.responses?.[0]?.auditorSign?.signUrl?.text ? (
              <Signature
                view={type}
                value={response?.responses?.[0]?.auditorSign?.signUrl}
              />
            ) : (
              '--'
            )}

            <Text
              align={type === 'web' ? 'left' : 'right'}
              mt={'14px'}
              fontWeight={500}
              fontSize={'14px'}
            >
              {response?.responses?.[0]?.auditorSign?.name
                ? response?.responses?.[0]?.auditorSign?.name
                : '--'}
            </Text>

            <Text
              align={type === 'web' ? 'left' : 'right'}
              fontWeight={500}
              fontSize={'14px'}
            >
              {`${
                response?.responses?.[0]?.auditorSign?.dateTime
                  ? formatDateTime(
                      response?.responses?.[0]?.auditorSign?.dateTime,
                      additionalData?.timeZone
                    ).dateString
                  : '--'
              } ${
                response?.responses?.[0]?.auditorSign?.dateTime
                  ? formatDateTime(
                      response?.responses?.[0]?.auditorSign?.dateTime,
                      additionalData?.timeZone
                    ).timeString
                  : '--'
              }`}
            </Text>
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default AuditorSignature;
