import { Box, Flex, Text } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { LTaskCategoryEntity } from 'sub-components/Launcher/launcher-config/common/launcher-config.graphql';
import LauncherLocationTask from '../LauncherLocationTask';
import { getTaskCategoryLabel } from '../helper';
import { GroupedTask, reorderTasks } from './GroupByPhase';
import { LocationPhaseDetails } from '../LauncherLocation.graphql';
import { AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';

interface IProps {
  keyName: string;
  groupedTasks: GroupedTask;
  categories: LTaskCategoryEntity[];
  launchId?: string;
  locationId: string;
  refetchData?: () => void;
  phaseDetails: LocationPhaseDetails[];
}

const GroupByTaskType: FC<IProps> = ({
  keyName,
  groupedTasks,
  categories,
  launchId,
  locationId,
  refetchData,
  phaseDetails,
}) => {
  const categoryLabel = getTaskCategoryLabel(categories, keyName as string);
  const { currentUserId, currentUserAuthRole } = useUserDataSelector(
    (state) => ({
      currentUserId: state.eid,
      currentUserAuthRole: state.authRole,
    })
  );

  let categoryStatusGroup = {
    overdue: 0,
    inProgress: 0,
    yetToStart: 0,
    completed: 0,
    dueSoon: 0,
  };

  const overdueCount = groupedTasks[keyName].tasks.filter(
    (task) => task.status === 'overdue'
  ).length;
  const inProgressCount = groupedTasks[keyName].tasks.filter(
    (task) => task.status === 'inProgress'
  ).length;
  const yetToStartCount = groupedTasks[keyName].tasks.filter(
    (task) => task.status === 'yetToStart' || task.status === 'locked'
  ).length;
  const completedCount = groupedTasks[keyName].tasks.filter(
    (task) => task.status === 'completed'
  ).length;
  const dueSoonCount = groupedTasks[keyName].tasks.filter(
    (task) => task.status === 'duesoon'
  ).length;

  categoryStatusGroup.overdue = overdueCount;
  categoryStatusGroup.inProgress = inProgressCount;
  categoryStatusGroup.yetToStart = yetToStartCount;
  categoryStatusGroup.completed = completedCount;
  categoryStatusGroup.dueSoon = dueSoonCount;

  const shouldShowGroup = () => {
    if (
      currentUserAuthRole === AuthRole.SUPER_ADMIN ||
      currentUserAuthRole === AuthRole.ADMIN
    ) {
      return true;
    }

    const currentUserTasks = groupedTasks[keyName].tasks.filter(
      (task) =>
        task.assignedUser?.includes(currentUserId) ||
        (task.assignedType === 'location' &&
          currentUserAuthRole === AuthRole.LOCATION_OWNER)
    );

    if (currentUserTasks.length === 0) return false;

    return true;
  };

  return shouldShowGroup() ? (
    <Flex flexDirection='column' gap={4}>
      <Flex justifyContent='space-between' alignItems='center'>
        <Flex gap={3}>
          <Flex flexDirection='column' gap={1}>
            <Flex alignItems='center' gap={3}>
              <Text
                color='#111315'
                fontSize={16}
                fontWeight={600}
                lineHeight='24px'
              >
                {categoryLabel}
              </Text>
            </Flex>

            <Flex fontSize='12px' gap={2}>
              {categoryStatusGroup.completed ? (
                <Flex gap={1}>
                  <Box as='span' color='#83BF6E' fontWeight={500}>
                    {categoryStatusGroup.completed}
                  </Box>
                  <Box as='span'>Completed</Box>
                </Flex>
              ) : null}
              {categoryStatusGroup.dueSoon ? (
                <Flex gap={1}>
                  <Box as='span' color='#8E59FF' fontWeight={500}>
                    {categoryStatusGroup.dueSoon}
                  </Box>
                  <Box as='span'>Due soon</Box>
                </Flex>
              ) : null}
              {categoryStatusGroup.overdue ? (
                <Flex gap={1}>
                  <Box as='span' color='#FF6A55' fontWeight={500}>
                    {categoryStatusGroup.overdue}
                  </Box>
                  <Box as='span'>Overdue</Box>
                </Flex>
              ) : null}
              {categoryStatusGroup.inProgress ? (
                <Flex gap={1}>
                  <Box as='span' color='#2A85FF' fontWeight={500}>
                    {categoryStatusGroup.inProgress}
                  </Box>
                  <Box as='span'>In progress</Box>
                </Flex>
              ) : null}
              {categoryStatusGroup.yetToStart ? (
                <Flex gap={1}>
                  <Box as='span' color='#6F767E' fontWeight={500}>
                    {categoryStatusGroup.yetToStart}
                  </Box>
                  <Box as='span'>Yet to start</Box>
                </Flex>
              ) : null}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDirection='column' gap={2}>
        {reorderTasks(groupedTasks[keyName]?.tasks || []).map((task, index) => (
          <LauncherLocationTask
            key={index}
            task={task}
            categories={categories}
            launchId={launchId}
            locationId={locationId}
            refetchData={refetchData}
            phaseDetails={phaseDetails}
            goLiveDate={groupedTasks[keyName].goLiveDate}
          />
        ))}
      </Flex>
    </Flex>
  ) : null;
};

GroupByTaskType.displayName =
  'displayName:sub-components/Launcher/dashboard/Location/GroupBy/GroupByTaskType';

export default GroupByTaskType;
