import React, { FC } from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Radio,
} from '@chakra-ui/react';

export interface PhaseSelectItem {
  eid: string;
  title: string;
  tasks: Array<{
    eid: string;
    title: string;
  }>;
}

interface IProps {
  taskPhaseId: string;
  phaseIndex: number;
  phase: PhaseSelectItem;
  type: 'phase' | 'task';
}

const PhaseSelect: FC<IProps> = ({ taskPhaseId, phase, phaseIndex, type }) => {
  return (
    <AccordionItem
      borderTopWidth={0}
      borderRadius='12px'
      _last={{
        borderBottomWidth: 0,
      }}
      sx={{
        '&:has(button[aria-expanded="true"])': {
          background: '#F4F4F4',
        },
      }}
    >
      <AccordionButton borderRadius='12px' padding={3}>
        <Flex flex={1} align='center' gap={3} overflow='hidden'>
          <Radio
            size='lg'
            value={`${phase.eid}-phase`}
            isDisabled={phase.eid === taskPhaseId}
          />
          <Box fontSize='15px' fontWeight='600' color='#1A1D1F' isTruncated>
            Phase {phaseIndex + 1} - {phase.title}
          </Box>
        </Flex>
        {phase.tasks?.length ? (
          <AccordionIcon />
        ) : (
          <Box boxSize='20px' minW='20px' />
        )}
      </AccordionButton>

      <AccordionPanel
        display='flex'
        flexDir='column'
        paddingLeft={6}
        gap='12px'
        _empty={{ display: 'none' }}
        sx={{
          '.chakra-radio__label': {
            color: '#1A1D1F',
            fontSize: 13,
            fontWeight: 500,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
      >
        {phase.tasks?.map((task, index) => (
          <Radio
            size='lg'
            key={index}
            value={`${task.eid}-task`}
            isReadOnly={type === 'phase'}
            isDisabled={task.eid === taskPhaseId}
          >
            Task {index + 1} - {task.title}
          </Radio>
        ))}
      </AccordionPanel>
    </AccordionItem>
  );
};

PhaseSelect.displayName = 'sc/L/lc/c/t/PhaseSelect';

export default PhaseSelect;
