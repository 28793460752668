import React, { FC } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { ActionButton, BoxHeader } from '../../../../../../ui-components';
import { HeaderColors } from '../../../../../../configs';
import { BaseBoardProps } from './delete-board.types';

interface IProps extends BaseBoardProps {}

const BeforeDeleteBoard: FC<IProps> = ({ deleteProps, actionHandler }) => {
  const { t } = useTranslation('launcher');
  return (
    <Flex flexDir='column' gap={5}>
      <Flex paddingTop={3} justify='space-between'>
        <BoxHeader
          color={HeaderColors.Red.Dark}
          title={`Before you delete “${deleteProps?.title}”`}
          fontSize='18px'
          boxWidth={3}
        />

        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
        />
      </Flex>

      <Flex flexDir='column'>
        <Box fontSize='16px' fontWeight={700} color='#FF6A55'>
          <Trans
            t={t}
            i18nKey='boardHasActiveLocation'
            count={deleteProps?.locations?.length}
            values={{ count: deleteProps?.locations?.length }}
          />
        </Box>
        <Box fontSize='16px' fontWeight={500} color='#6F767E'>
          <Trans
            t={t}
            i18nKey='locationNames'
            values={{ locations: deleteProps?.locations }}
          />
        </Box>
      </Flex>

      <Flex gap={4}>
        <ActionButton
          flex={1}
          size='lg'
          colorScheme='blue'
          actionFn={() => actionHandler('move-confirm', deleteProps)}
        >
          Move location/s to Pre-launch
        </ActionButton>
        <ActionButton
          flex={1}
          size='lg'
          colorScheme='red'
          actionFn={() => actionHandler('delete-confirm', deleteProps)}
        >
          Delete location/s
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default BeforeDeleteBoard;
