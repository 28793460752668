import React, { FC, useMemo, useRef, useState } from 'react';
import {
  Box,
  Center,
  Checkbox,
  Divider,
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarLines,
  faCalendarCheck,
  faEllipsisStroke,
  faPen,
} from '@fortawesome/pro-light-svg-icons';
import CommonLabel from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/CommonLabel';
import CommonContainer from 'ui-components/CommonContainer';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { TaskDetailDrawer } from '../TaskDetailDrawer';
import {
  ILauncherTaskEntity,
  ITaskStatus,
} from 'sub-components/LocationLaunchDetail/types/types';
import moment from 'moment';
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { useUserDataSelector, useUserEntity } from 'hooks';
import { SelectOption } from 'sub-components/CustomDropdowns';
import { AuthRole } from 'sop-commons/src/client';
import { getImage } from 'utils';
import { getLauncher } from '../../../../../configs';
import { useSupervisorModal } from '../SupervisorModal/useSupervisorModal';
import { LauncherLocationDetailTask } from 'pages/launcher/details/types/location-launch-details.types';
import {
  faLockKeyhole,
  faCircleUser,
} from '@fortawesome/pro-regular-svg-icons';
import TaskStatusIcon from 'ui-components/TaskStatusIcon';
import DateFilter from 'ui-components/DateFilter';
import { IDateFilterOptions } from 'ui-components/DateFilter/types';
import { SimpleDateFilterWithOptions } from 'ui-components/DateFilter/components';
import { ITaskDueDateFilterOptions } from 'ui-components/DateFilter/data/taskDueDateOptions';
import { UPDATE_LOCATION_LAUNCH } from 'pages/launcher/details/graphql/location-launch-details.graphql';
import EditDueDateDrawer, {
  BaseTaskFormRef,
} from 'pages/launcher/details/components/EditDueDateDrawer/EditDueDateDrawer';
import { ITaskItem } from 'pages/launcher/config-editor/editor-form/editor.types';
import SelectTaskPhase from 'pages/launcher/config-editor/post-setup/task-form/SelectTaskPhase';
import { useEditDueDateDrawer } from 'pages/launcher/details/components/EditDueDateDrawer/useEditDueDateDrawer';
import DateBadge from './DateBadge';

const UPDATE_LOCATION_LAUNCH_TASK_DUE_DATE = gql`
  mutation UpdateLocationLaunch(
    $launchId: String!
    $dueDate: Date
    $taskId: String!
  ) {
    UpdateLocationLaunchTask(
      launchId: $launchId
      dueDate: $dueDate
      taskId: $taskId
    ) {
      succeed
    }
  }
`;

/**
 * Sub-component to render the task status icon.
 *
 * @param {{ status: ITaskStatus }} props - The props of the sub-component.
 * @returns {React.ReactElement} The component with the task status icon.
 */
// const TaskStatusIcon: FC<{ status?: ITaskStatus }> = ({ status }) => {
//   if (!status) return <></>;
//   const icon = status === 'complete' ? faCalendarCheck : faCalendarLines;
//   const color = getColor(status);

//   return (
//     <FontAwesomeIcon icon={icon as IconProp} fontSize='12px' color={color} />
//   );
// };

/**
 * Sub-component to render the date label.
 *
 * @param {{ status: ITaskStatus; dueDate: string; completedDate?: string }} props - The props of the sub-component.
 * @returns {React.ReactElement} The component with the date label.
 */
const DateLabel: FC<{
  status?: ITaskStatus;
  dueDate: string;
  completedDate?: string;
}> = ({ status, dueDate, completedDate }) => {
  if (!status) return <></>;
  let formattedDueDate = moment(dueDate)?.format('DD MMM');
  let formattedCompleteDate = moment(completedDate)?.format('DD MMM');
  const color = getColor(status);
  const label =
    status === 'complete'
      ? `Marked Completed on ${formattedCompleteDate} by location`
      : formattedDueDate;
  // @ts-ignore
  return <CommonLabel label={label} fontSize='12px' color={color} />;
};

/**
 * Sub-component to render the separator bullet point.
 *
 * @param {{ status: ITaskStatus }} props - The props of the sub-component.
 * @returns {React.ReactElement|null} The bullet separator or null if status is 'complete'.
 */
const Separator: FC<{ status?: ITaskStatus }> = ({ status }) => {
  return status !== 'complete' ? <Text color='#D1D8E0'>&bull;</Text> : null;
};

/**
 * Sub-component to render the due or overdue label.
 *
 * @param {{ status: ITaskStatus }} props - The props of the sub-component.
 * @returns {React.ReactElement|null} The due or overdue label or null if status is 'complete'.
 */
const DueLabel: FC<{ status?: ITaskStatus }> = ({ status }) => {
  if (!status) return <></>;
  const color = getColor(status);
  const dayCount = 'X'; // Replace X with actual calculation if needed
  const label =
    status === 'overdue'
      ? `Overdue in ${dayCount} days`
      : `Due in ${dayCount} days`;

  return status !== 'complete' ? (
    <CommonLabel label={label} fontSize='12px' color={color} />
  ) : null;
};

/**
 * Utility function to get the color based on the task's status.
 *
 * @param {ITaskStatus} status - The current status of the task.
 * @returns {string} The color associated with the status.
 */
const getColor = (status: ITaskStatus): string => {
  switch (status) {
    case 'overdue':
      return HeaderColors.Red.Dark;
    case 'nextWeek':
      return HeaderColors.DarkBlue;
    case 'complete':
      return HeaderColors.GreenDark;
    default:
      return 'black';
  }
};

/**
 * TaskListItem - A component that renders a single task item with its status and dates.
 *
 * @param {ILauncherTaskEntity} props - The props for the TaskListItem component.
 * @returns {React.ReactElement} The component representing a task list item.
 */
const TaskListItem: FC<ILauncherTaskEntity> = ({
  phaseAssignedToInternalTeam,
  isFirstTask,
  launchId,
  phaseName,
  selectedFilter,
  task,
  taskClickHandler,
}) => {
  const entityUserData: any = useReactiveVar(usersEntityObj);
  const editDueDateDrawerRef = useRef<BaseTaskFormRef>();
  const loggedInUserEid = useUserDataSelector((state) => state?.eid);
  const launcherContents = useUserEntity(
    (entity) => getLauncher(entity)?.contents || []
  );
  const editDueDateDrawer = useEditDueDateDrawer();
  const supervisorModal = useSupervisorModal();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editDueDateData, setEditDueDateData] = useState({
    showPopover: false,
    showCalendar: false,
    isLoading: false,
  });
  const toast = useToast({
    isClosable: true,
    duration: 3000,
    position: 'top-right',
  });
  const [selectedDateFilter, setSelectedDateFilter] = useState<
    ITaskDueDateFilterOptions | undefined
  >(undefined);

  const isCompleted = status === 'complete';

  const entityData = useReactiveVar(usersEntityObj);

  const filteredUsers = useMemo((): SelectOption[] => {
    let _users = entityData?.filter(
      (en) =>
        [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(en?.authRole) &&
        en?.type === 'user'
    );
    return _users?.map((user) => {
      return {
        ...user,
        label: user?.name,
        value: user?.eid,
      };
    });
  }, [entityData]);

  const getUserName = (userId: string) => {
    if (userId === loggedInUserEid) {
      return 'You';
    }
    return (
      entityUserData?.find((userData: any) => userData?.eid === userId)?.name ||
      ''
    );
  };

  const taskTimeDetails = () => {
    switch (task?.taskStatus) {
      case 'overdue':
        return (
          <Flex align='center' gap='5px'>
            <Flex align='center' gap='5px'>
              <FontAwesomeIcon
                icon={faCalendarLines as IconProp}
                color='#FF6A55'
              />
              <Text color='#FF6A55'>
                {moment(task?.formattedDueDate)?.format('MMM D')}
              </Text>
            </Flex>
            <Text color='#D1D8E0'>&bull;</Text>
            <Text color='#FF6A55'>{task?.formattedDuration}</Text>
          </Flex>
        );
      case 'inProgress':
        return (
          <Flex align='center' gap='5px'>
            <Flex align='center' gap='5px'>
              <FontAwesomeIcon
                icon={faCalendarLines as IconProp}
                color='#2A85FF'
              />
              <Text color='#2A85FF'>
                {moment(task?.formattedDueDate)?.format('MMM D')}
              </Text>
            </Flex>
            <Text color='#D1D8E0'>&bull;</Text>
            <Text color='#2A85FF'> Due in {task?.formattedDuration}</Text>
          </Flex>
        );
      case 'complete':
        return (
          <Flex align='center' gap='5px'>
            <Flex align='center' gap='5px'>
              <FontAwesomeIcon
                icon={faCalendarCheck as IconProp}
                color='#83BF6E'
              />
              <Text color='#83BF6E'>
                Marked completed on{' '}
                {moment(task?.formattedCompleteDate)?.format('MMM D')}
              </Text>
              <Text as='span' color='#83BF6E'>
                by {getUserName(task?.completedBy!)}
              </Text>
            </Flex>
          </Flex>
        );
      default:
        return null;
    }
  };

  const [updateTaskDueDate, { loading }] = useMutation(
    UPDATE_LOCATION_LAUNCH_TASK_DUE_DATE,
    {
      onCompleted: () => {
        toast({
          status: 'success',
          title: 'Task due date updated',
        });
      },
      onError: () => {
        toast({
          status: 'error',
          title: 'Task due date could not be updated',
        });
      },
    }
  );

  const updateDueDateHandler = () => {
    console.log('selected date : ', selectedDateFilter?.date?.format('LLL'));
    updateTaskDueDate({
      variables: {
        input: {
          launchId: launchId,
          taskId: task?.eid,
          dueDate: selectedDateFilter?.date?.toISOString(),
        },
      },
    });
  };

  const actionMenu = () => {
    return task?.taskStatus === 'complete' ? (
      <Box w='30px' />
    ) : (
      <Popover
        placement='bottom-start'
        isOpen={editDueDateData?.showPopover}
        onClose={() =>
          setEditDueDateData({
            ...editDueDateData,
            showPopover: false,
            showCalendar: false,
          })
        }
      >
        {/* @ts-ignore */}
        <PopoverTrigger>
          <Center
            onClick={(e) => {
              e?.stopPropagation();
              setEditDueDateData({
                ...editDueDateData,
                showPopover: true,
                showCalendar: false,
              });
            }}
            padding='3px 5px'
            cursor='pointer'
          >
            <FontAwesomeIcon
              icon={faEllipsisStroke as IconProp}
              fontSize='20px'
            />
          </Center>
        </PopoverTrigger>
        <PopoverContent borderRadius='16px' p={4} w='200px'>
          <PopoverArrow />
          <PopoverBody>
            <Flex>
              <Flex
                gap='10px'
                align='center'
                cursor='pointer'
                onClick={(e) => {
                  e?.stopPropagation();
                  setEditDueDateData({
                    ...editDueDateData,
                    showCalendar: true,
                    showPopover: false,
                  });
                  editDueDateDrawer({
                    initialValue: {
                      ...task,
                      categories: launcherContents.map((ct) => ({
                        eid: ct.eid!,
                        category: ct.category,
                      })),
                      oldCategory: task?.categoryId,
                      newCategory: task?.categoryId,
                    } as unknown as ITaskItem,
                    isFirstTask: isFirstTask,
                    launchId: launchId,
                  });
                }}
              >
                <FontAwesomeIcon icon={faPen as IconProp} fontSize='16px' />
                <Text fontSize='15px' fontWeight={500}>
                  Edit due date
                </Text>
              </Flex>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  };

  const dateBadge = () => {
    return (
      <DateBadge
        taskStatus={task?.taskStatus}
        formattedDueDate={task?.formattedDueDate}
        formattedCompleteDate={task?.formattedCompleteDate}
      />
    );
  };

  const assignedUsersRender = (task: LauncherLocationDetailTask) => {
    let userIds = task?.assignedUsers;
    let foundUsers = filteredUsers?.filter((user) =>
      userIds?.some((val) => val === user?.eid)
    );
    const numberOfUsersDisplayed = 2;
    const displayedUsers = foundUsers?.slice(0, numberOfUsersDisplayed);
    const remainingUsers = foundUsers?.slice(numberOfUsersDisplayed);
    let remainingUsersNames = remainingUsers
      ?.map((user) => user?.label || 'Unknown')
      .join(', ');
    return (
      <Flex align='center' gap='6px'>
        <Popover placement='bottom-start'>
          {/* @ts-ignore */}
          <PopoverTrigger>
            <Flex
              align='center'
              gap='6px'
              onClick={(e) => e?.stopPropagation()}
            >
              {displayedUsers?.slice(0, 2)?.map((user) => {
                return (
                  <Box key={user?.eid} cursor='pointer'>
                    <Tooltip label={user?.label} hasArrow placement='top'>
                      <Image
                        src={getImage(user?.profilePic, user?.label)}
                        boxSize='40px'
                        minW='40px'
                        minH='40px'
                        borderRadius='50%'
                      />
                    </Tooltip>
                  </Box>
                );
              })}
              {remainingUsers?.length > 0 && (
                <Tooltip label={remainingUsersNames} hasArrow placement='top'>
                  <Center
                    cursor='pointer'
                    boxSize='40px'
                    color='rgba(105, 105, 116, 1)'
                    borderRadius='50%'
                    border='1px solid rgba(226, 226, 234, 1)'
                  >
                    +{remainingUsers?.length}
                  </Center>
                </Tooltip>
              )}
            </Flex>
          </PopoverTrigger>
          <PopoverContent p={2} borderRadius='16px'>
            <PopoverArrow />
            <PopoverBody>
              <VStack
                align='flex-start'
                flexDir='column'
                overflow='scroll'
                maxH='30vh'
                divider={<Divider />}
              >
                {foundUsers?.map((user, index) => (
                  <Flex key={user?.eid} flexDir='column' gap='5px'>
                    <Flex align='center' gap='5px'>
                      <Image
                        src={getImage(user?.profilePic, user?.label)}
                        boxSize='40px'
                        borderRadius='50%'
                      />
                      <Flex flexDir='column'>
                        <Text fontWeight={600}>{user?.label}</Text>
                        <Text
                          fontWeight={500}
                          color='rgba(146, 146, 157, 1)'
                          fontSize='12px'
                        >
                          {user?.role}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                ))}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        {dateBadge()}
        {actionMenu()}
      </Flex>
    );
  };

  /**
   * // <DateFilter
                  //   hideDropdown
                  //   showCalendar
                  //   customCalendarContainerStyles={{
                  //     top: '-25px',
                  //     right: '-173px',
                  //   }}
                  //   selectedDateFilter={selectedDateFilter}
                  //   onFilterSelection={() => {}}
                  //   setSelectedDateFilter={setSelectedDateFilter}
                  // />
                  // <SimpleDateFilterWithOptions
                  //   selectedDateFilter={selectedDateFilter}
                  //   closeHandler={() => {
                  //     setEditDueDateData({
                  //       ...editDueDateData,
                  //       showPopover: false,
                  //       showCalendar: false,
                  //     });
                  //     setSelectedDateFilter(undefined);
                  //   }}
                  //   setSelectedDateFilter={setSelectedDateFilter}
                  //   updateDueDateHandler={updateDueDateHandler}
                  // />
   */

  const _taskStatus = useMemo(() => {
    if (task?.taskStatus === 'inProgress' || task?.taskStatus === 'overdue') {
      return task?.taskStatus;
    } else if (task?.taskStatus === 'complete') {
      return 'completed';
    }
  }, [task?.taskStatus]);

  return (
    <CommonContainer
      bg='white'
      borderRadius='8px'
      p='12px 16px'
      onClick={() => task?.taskStatus !== 'locked' && taskClickHandler(task)}
    >
      <Flex align='center' gap='10px'>
        <Flex gap='10px' align='flex-start' w='full'>
          <Box>
            {task?.taskStatus === 'locked' ? (
              <FontAwesomeIcon icon={faLockKeyhole as IconProp} />
            ) : (
              // <Checkbox readOnly isChecked={task?.taskStatus === 'complete'} />
              <TaskStatusIcon
                status={_taskStatus}
                iconStyles={{ marginTop: '4px' }}
              />
            )}
          </Box>
          <Flex
            flexDir='column'
            onClick={onOpen}
            cursor={task?.taskStatus !== 'locked' ? 'pointer' : 'no-drop'}
          >
            <Box gap='5px'>
              <Text as={task?.taskStatus === 'complete' ? 's' : 'span'}>
                {task?.title}
              </Text>
              {task?.description && (
                <Text
                  as={task?.taskStatus === 'complete' ? 's' : 'span'}
                  color='#6F767E'
                >
                  : {task?.description}
                </Text>
              )}
            </Box>
            {task?.taskStatus === 'locked' ? (
              <Box>
                <Text
                  as='span'
                  color='#6F767E'
                  fontSize='12px'
                  fontWeight={400}
                  mr='5px'
                >
                  Unlocks when the above task is completed
                </Text>
              </Box>
            ) : (
              <Flex>{taskTimeDetails()}</Flex>
            )}
            {/* <CommonLabel
              label={`${task?.title} ${
                task?.description ? ': ' + task?.description : ''
              }`}
              fontSize='14px'
              color={isCompleted ? '#6F767E' : '#272B30'}
              as={isCompleted ? 's' : undefined}
              noOfLines={1} 
            />*/}
            {/*<Flex gap='5px'>*/}
            {/*  <Flex align='center' gap='4px'>*/}
            {/*    <TaskStatusIcon status={status} />*/}
            {/*    <DateLabel*/}
            {/*      status={status}*/}
            {/*      dueDate={taskDueDate}*/}
            {/*      completedDate={completedDate}*/}
            {/*    />*/}
            {/*  </Flex>*/}
            {/*  <Separator status={status} />*/}
            {/*  <DueLabel status={status} />*/}
            {/*</Flex>*/}
          </Flex>
        </Flex>
        <Flex align='center' gap='5px'>
          {!task?.isPhaseSelected && selectedFilter === 'status' && (
            <Flex
              bg='#2A85FF1A'
              p='7px 11px'
              borderRadius='7px'
              h='31px'
              align='center'
              justify='center'
              minW='192px'
            >
              <Text color='#2A85FF'>Only for supervising team</Text>
            </Flex>
          )}
          {task?.isPhaseSelected ? (
            <Flex align='center' gap='6px'>
              <Tooltip
                label='Assigned to all location owners'
                hasArrow
                placement='auto'
              >
                <Center
                  cursor='pointer'
                  bg='rgba(255, 216, 141, 1)'
                  boxSize='40px'
                  borderRadius='50%'
                >
                  <FontAwesomeIcon
                    icon={faLocationDot as IconProp}
                    color='white'
                  />
                </Center>
              </Tooltip>
              {dateBadge()}
              {actionMenu()}
            </Flex>
          ) : (
            assignedUsersRender(task)
          )}
        </Flex>
      </Flex>
      {/* <EditDueDateDrawer
        initialValue={
          {
            ...task,
            categories: launcherContents.map((ct) => ({
              eid: ct.eid!,
              category: ct.category,
            })),
            oldCategory: task?.categoryId,
            newCategory: task?.categoryId,
          } as unknown as ITaskItem
        }
        isEdit
        layout='vertical'
        isOpen={editDueDateData?.showCalendar}
        phaseComponent={SelectTaskPhase}
        onClose={() =>
          setEditDueDateData({
            ...editDueDateData,
            showCalendar: false,
            showPopover: false,
          })
        }
      /> */}
    </CommonContainer>
  );
};

export default TaskListItem;
