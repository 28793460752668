import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IDeleteSubFolderProps } from 'pages/Chapters/ChapterContainer/hooks/useChapterContainerMisc';
import React, { FC } from 'react';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { BoxHeader } from 'ui-components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { PrimaryButton, SelectOption } from 'atoms';
import { useMutation } from '@apollo/client';
import {
  RemoveCategoryQuery,
  RemoveCategoryResponse,
  RemoveCategoryVariable,
} from 'sub-components/Sidebar/category/add-category.graphql';
import { useTranslation } from 'react-i18next';
import { useUserData } from 'hooks';
import useCombinedStore from 'zustandStore/store';
import {
  GET_CHAPTERS,
  GET_FOLDERS_LIST,
} from 'pages/Chapters/chapters.graphql';
import { getFoldersList } from 'shared/graphql/SharedGraphql';

const ConfirmModal: FC<{
  open: boolean;
  onClose: () => void;
  onDeleteSuccess: () => void;
  onDeleteError: () => void;
  selectedFolderData: SelectOption<string> | undefined;
}> = ({ open, selectedFolderData, onClose, onDeleteSuccess }) => {
  const { t } = useTranslation(['chapter']);
  const { deleteSubFolderData, updateDeleteSubFolderData } = useCombinedStore();
  const entityId = useUserData()?.entityId;
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });
  const onSuccess = () => {
    onDeleteSuccess();
  };
  const { execute } = getFoldersList(onSuccess);
  const [deleteCategory, { loading }] = useMutation<
    RemoveCategoryResponse,
    RemoveCategoryVariable
  >(RemoveCategoryQuery, {
    onCompleted: (response) => {
      if (response?.removeCategoryAnyway) {
        toast({
          status: 'success',
          title: t('success'),
          description: 'Sub folder deleted successfully',
        });
        execute();
      }
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('error'),
        description: 'Sub Folder could not be deleted',
      });
    },
    refetchQueries: [{ query: GET_CHAPTERS }],
    awaitRefetchQueries: true,
  });
  const deleteAnywayHandler = () => {
    if (!selectedFolderData) return;
    deleteCategory({
      variables: {
        eid: entityId,
        // category: data.name,
        categoryId: selectedFolderData.value,
      },
    });
  };
  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size='lg'
    >
      <ModalOverlay />
      <ModalContent p={3}>
        <ModalHeader>
          <Flex gap='12px'>
            <Box flex={1}>
              <BoxHeader title='Delete subfolder' color='#B5E4CA' />
            </Box>
            <ModalCloseButton m={3} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Flex flexDir='column' gap={4}>
            <Flex
              bg='#B1E5FC66'
              p='16px 24px'
              borderRadius='10px'
              align='center'
              gap='10px'
            >
              <FontAwesomeIcon icon={faTrashCan as IconProp} color='#2A85FF' />
              <Text>Deleting this subfolder will delete all chapters.</Text>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex w='full' gap={2} justify='space-between'>
            <PrimaryButton
              title='Move chapters'
              variant='solid'
              colorScheme='blue'
              disabled={loading}
              style={{ width: '60%' }}
              onClick={() => {
                updateDeleteSubFolderData({
                  ...deleteSubFolderData,
                  showMoveChaptersModal: true,
                });
                // setDeleteSubFolderData((prev) => {
                //   return {
                //     ...prev,
                //     showMoveChaptersModal: true,
                //   };
                // });
              }}
            />
            <PrimaryButton
              title='Delete anyway'
              variant='solid'
              colorScheme='red'
              style={{ width: '40%' }}
              disabled={loading}
              isLoading={loading}
              onClick={deleteAnywayHandler}
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ConfirmModal.displayName =
  'displayName:pages/Chapters/CreateEditSubFolder/components/ConfirmModal';

export default ConfirmModal;
