import { Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import CircularProgressIcon from 'sub-components/CircularProgressIcon';
import { faBullseyeArrow } from '@fortawesome/pro-regular-svg-icons';
import { ITasksForToday } from 'pages/Home/Home';
import { useUserData } from 'hooks';
import { AuthRole } from 'authorization';
import { useRenderFlag } from 'sub-components/Sidebar/sections/helper';

interface IProps {
  tasksForTodayData: ITasksForToday;
}

const TasksForToday: FC<IProps> = ({ tasksForTodayData }) => {
  const history = useHistory();
  const userData = useUserData();
  // const flag = useRenderFlag();
  return (
    <Flex
      gap='10px'
      // cursor={!flag ? 'default' : 'pointer'}
      onClick={() => {
        // if (!flag) return;
        userData?.authRole === AuthRole.WORKER || userData?.type === 'branch'
          ? history.push('/tasks/my-tasks')
          : history.push('/tasks/supervised');
      }}
    >
      <CircularProgressIcon
        progressRate={75}
        icon={
          <FontAwesomeIcon icon={faBullseyeArrow as IconProp} color='#0062FF' />
        }
      />
      <Flex flexDirection='column' gap='10px'>
        <Text fontWeight={600} fontSize='16px'>
          Tasks for today
        </Text>
        <Text fontWeight={700} fontSize='32px'>
          {tasksForTodayData?.total}
        </Text>
        {/* <Text fontWeight={400} fontSize='14px' color='#696974'>
          5 tasks completed
        </Text> */}
      </Flex>
    </Flex>
  );
};

TasksForToday.displayName =
  'sub-components/AnalyticsBoard/components/TasksForToday/TasksForToday';

export default TasksForToday;
