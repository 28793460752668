import omit from 'lodash/omit';
import { IDocumentInput } from './common/compliance.input';
import {
  AddComplianceInput,
  MetaComplianceData,
  UpdateComplianceInput,
} from './add-document.graphql';

export type Input = AddComplianceInput['input'];

const getReminderData = (
  value: IDocumentInput['reminder']
): Input['reminder'] => {
  if (!value?.remindUserType?.length) {
    return {
      remindAdmins: [],
      remindOwners: [],
    };
  }

  const data: Input['reminder'] = {
    remindAdmins: [],
    remindOwners: [],
    remindUserType: value.remindUserType,
    remindChannels: value.remindChannels,
  };

  if (value?.remindUserType?.includes('users')) {
    data.remindAdmins = value.remindAdmins;
    data.remindOwners = value.remindOwners;
  }

  switch (value.reminderType) {
    case 'before':
      data.remindType = value.remindType;
      data.remindBefore = +value.remindBefore;
      break;
    case 'on':
      data.remindType = 'date';
      data.remindDate = value.remindDate;
      break;
    default:
      break;
  }

  return data;
};

export const toComplianceInput = (
  values: IDocumentInput,
  metaComplianceData?: MetaComplianceData
): Input => {
  return {
    title: values.title,
    categoryId: values.category?.value,
    expiryDate: values.expiryDate,
    file: omit(values.file, 'rawFile'),
    locationId: values.location?.value,
    reminder: getReminderData(values.reminder),
    metadata: metaComplianceData,
  };
};

type UpdateInput = UpdateComplianceInput['input'];

export const toUpdateComplianceInput = (
  values: IDocumentInput
): UpdateInput => {
  return {
    eid: values.eid!,
    categoryId: values.category?.value,
    expiryDate: values.expiryDate,
    locationId: values.location?.value,
    reminder: getReminderData(values.reminder),
  };
};
