import React, { FC } from 'react';
import { ArchivedTemplates } from '../listing/archived-templates';
import { ArchivedAudits } from '../audit-tab';

interface IProps {
  selectedTab: string;
}

const ArchivesRender: FC<IProps> = ({ selectedTab }) => {
  if (selectedTab === 'audit') {
    return <ArchivedAudits />;
  }

  return <ArchivedTemplates />;
};

export default ArchivesRender;
