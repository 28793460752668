import {
  Flex,
  Text,
  PopoverTrigger as OrigPopoverTrigger,
  useToast,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  List,
  ListItem,
} from '@chakra-ui/react';
import React, { useState, useRef, FC, useEffect } from 'react';
import { SortingTable } from 'sub-components/ChakraTable/SortingTable';
import FilledHorizontal from '../../../assets/images/filled_horizontal.png';
import { useConfirm } from 'ui-components/Confirm';
// import { useMoveItemTo } from './useMoveItemTo';
import { useMoveItemTo } from 'sub-components/SettingDataCenter/RolesList/useMoveItemTo';
import PreviewHeader from 'sub-components/forms/component/PreviewHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
// import { IRoleData, IRoleMemberEntity } from './roles-list.types';
import {
  IRoleData,
  IRoleMemberEntity,
} from 'sub-components/SettingDataCenter/RolesList/roles-list.types';
// import { DELETE_ROLE_ANYWAY } from './roles.graphql';
// import { getColumns, getTableData } from './roles-list-functions';
// import { getColumns, getTableData } from 'sub-components/SettingDataCenter/RolesList/roles-list-functions';
import { getColumns, getTableData } from './jobs-helper-functions';
import GenericModal from 'ui-components/GenericModal';
import closeIcon from '../../../assets/images/closeIcon.png';
// import MoveMembersToAnotherRoleModal from './MoveMembersToAnotherRoleModal';
import { faPenToSquare, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { GET_USER } from 'pages/Login/login.graphql';
// import AddRole from './AddRole';
// import NoRoleModal from './NoRoleModal';
import { useTranslation } from 'react-i18next';
import EmptyState from 'sub-components/EmptyState';
import { DELETE_ROLE_ANYWAY } from 'sub-components/SettingDataCenter/RolesList/roles.graphql';
import MoveMembersToAnotherRoleModal from 'sub-components/SettingDataCenter/RolesList/MoveMembersToAnotherRoleModal';
import AddRole from 'sub-components/SettingDataCenter/RolesList/AddRole';
import NoRoleModal from 'sub-components/SettingDataCenter/RolesList/NoRoleModal';
import JobsHeading from './JobsHeading';
import { SearchFilterHeader } from '../common';
import eventBus from '../locations/AddLocationModal/eventBus';
import AddJobModal from './AddJobModal';
import { AuthRole } from 'authorization';
import PrimaryButton from 'atoms/PrimaryButton';
import { IMembersDataEntity } from './JobMembersComponent';
import SearchInput from 'atoms/SearchInput';
import SingleUserChatModal from 'ui-components/SingleUserChatModal';
import { useHistory } from 'react-router-dom';

import { getImage, shallowEqual } from '../../../utils';
import { MessageIconButton } from '../../../atoms';
import { IconImage, RoleAndAuthRole } from '../../../ui-components';
import { CHAT_URL } from '../../../appRoutes';
import { useUserDataSelector, useUserEntity } from '../../../hooks';
import { roleObj } from 'ui-components/DashboardMenu';
import { useHasChatAccess } from 'hooks/useChatRestriction';

export const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
  OrigPopoverTrigger;

export const GET_ALL_USERS = gql`
  query JOBS_LIST_CONTAINER_GET_ALL_USERS($entityId: String!) {
    EntityUser(entityId: $entityId) {
      eid
      name
      type
      role
      authRole
      status
      profilePic
      locations {
        name
      }
    }
  }
`;

const JobMembersListModal: FC<{
  isOpen: boolean;
  title: string;
  _membersData: IMembersDataEntity[];
  onClose: () => void;
  onUserChatClick: (userId: string) => void;
}> = ({ isOpen, _membersData, title, onClose, onUserChatClick }) => {
  const [initialMembersData, setInitialMembersData] = useState<
    IMembersDataEntity[]
  >([]);
  const [membersData, setMembersData] = useState<IMembersDataEntity[]>([]);
  const hasChatAccess = useHasChatAccess();

  useEffect(() => {
    if (isOpen) {
      setInitialMembersData(_membersData);
      setMembersData(_membersData);
    }
  }, [_membersData, isOpen]);

  const searchHandler = (e) => {
    if (!e?.target?.value) {
      setMembersData(initialMembersData);
    } else {
      let filteredData = initialMembersData?.filter((member) =>
        member?.name
          ?.toLowerCase()
          ?.trim()
          ?.includes(e?.target?.value?.toLowerCase()?.trim())
      );
      setMembersData(filteredData);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>{title} members</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <SearchInput
            placeholder='Search'
            hideShortcuts
            onChange={searchHandler}
          />
          {membersData?.length > 0 ? (
            <List minH='40vh' maxH='40vh' overflowY='scroll'>
              {membersData?.map((member, index) => (
                <ListItem key={member?.eid}>
                  <Flex
                    flexDirection='row'
                    justifyContent='space-between'
                    align='center'
                  >
                    <Flex gap={'10px'} align='center' mt={4}>
                      <IconImage
                        name={member?.name}
                        thumbnail={member?.profilePic}
                        boxSize={40}
                        borderRadius='5px'
                      />

                      <Flex flexDir='column'>
                        <Text
                          m={0}
                          fontWeight={700}
                          fontSize='14px'
                          color='#1A1D1F'
                        >
                          {member?.name}
                        </Text>

                        <Box
                          sx={{
                            '> div': {
                              flexDirection: 'row-reverse',
                            },
                          }}
                        >
                          <RoleAndAuthRole
                            role={member?.role}
                            authRole={member?.authRole}
                            disableToolTip
                          />
                        </Box>
                      </Flex>
                    </Flex>
                    <MessageIconButton
                      hasChatAccess={hasChatAccess({
                        eid: member?.eid,
                        role: member?.role,
                        authRole: member?.authRole as AuthRole,
                      })}
                      onClick={() => onUserChatClick(member?.eid)}
                    />
                  </Flex>
                </ListItem>
              ))}
            </List>
          ) : (
            <Box mb={4}>
              <EmptyState image='Search' title='Searched member not found' />
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const JobsListContainer: FC = () => {
  const { t } = useTranslation([
    'common',
    'header',
    'role',
    'setting',
    'training',
    'category',
  ]);
  const confirm = useConfirm();
  const history = useHistory();
  const toast = useToast();

  const userData = useUserDataSelector(
    (state) => ({
      authRole: state.authRole,
      type: state.type,
      entityId: state.entityId,
    }),
    shallowEqual
  );

  const roles = useUserEntity((entity) => entity?.roles || [], shallowEqual);

  const moveItemTo = useMoveItemTo();
  const confirmRef = useRef<ReturnType<typeof confirm>>();
  const [step, setStep] = useState<0 | 1>(0);
  const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(true);
  const [showMoveModal, setShowMoveModal] = useState(true);
  const [initialRolesListData, setInitialRolesListData] = useState<
    Array<IRoleData>
  >([]);
  const [rolesListData, setRolesListData] = useState<Array<IRoleData>>([]);
  const [newRole, setNewRole] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [jobModalType, setJobModalType] = useState<'add' | 'edit'>('add');
  const [jobMembersListModalData, setJobListModalData] = useState<{
    show: boolean;
    title: string;
    membersData: IMembersDataEntity[];
  }>({ show: false, title: '', membersData: [] });
  const [showUserChatModal, setShowUserChatModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [showData, setShowData] = useState({
    initialModal: false,
    itemsModal: false,
    noDataModal: false,
    selectedRole: {
      name: '',
      status: '',
      color: '',
      eid: '',
      updatedAt: '',
      createdBy: { image: '', name: '' },
      members: [],
    } as IRoleData,
  });

  useEffect(() => {
    eventBus.on('openAddJobModal', jobModalHandler);
    return () => {
      eventBus.removeListener('openAddJobModal', jobModalHandler);
    };
  }, []);

  useEffect(() => {
    if (Array.isArray(roles) && roles?.length > 0) {
      getAllUsersHandler();
    }
  }, [roles]);

  const jobModalHandler = () => {
    setNewRole(true);
    setJobModalType('add');
  };

  const getAllUsersHandler = () => {
    getAllUsers({
      variables: {
        entityId: userData?.entityId,
      },
    });
  };

  const [getAllUsers, { loading: getAllUsesLoading }] = useLazyQuery(
    GET_ALL_USERS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        let users: Array<IRoleMemberEntity> = data?.EntityUser?.filter(
          (entity) => entity?.type === 'user' && entity?.status === 'active'
        );
        let _users: IRoleMemberEntity[] = JSON.parse(JSON.stringify(users));
        _users?.forEach((user) => {
          user.checked = false;
          user.profilePic = getImage(user?.profilePic, user?.name);
        });

        if (Array.isArray(_users) && _users?.length > 0) {
          let _rolesData: Array<IRoleData> = [];
          roles?.map((role) => {
            _rolesData?.push({
              color: role?.color,
              eid: role?.eid,
              name: role?.name,
              status: role?.status,
              createdAt: role?.createdAt as unknown as string,
              createdBy: _users
                ?.filter((_user) => _user?.eid === role?.createdBy)
                ?.map((_user) => {
                  return {
                    image: _user?.profilePic,
                    name: _user?.name,
                  };
                })?.[0],
              updatedAt: role?.updatedAt,
              members: _users?.filter((_user) => {
                return _user?.role === role?.name;
              }),
            });
          });

          setInitialRolesListData(_rolesData);
          setRolesListData(_rolesData);
          // setInitialRolesListData([]);
          // setRolesListData([]);
        }
      },
    }
  );

  const [getUser] = useLazyQuery(GET_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      userObj(data?.user || {});
      const roles = data?.user?.entity?.roles;
      if (roles && roles.length) {
        let roleWithColor: any = {};
        roles.forEach((role: any) => {
          roleWithColor[role.name] = role.color;
        });

        roleObj(roleWithColor);
      }
    },
  });

  const [deleteRole, { loading: deleteAnwayLoading }] = useMutation(
    DELETE_ROLE_ANYWAY,
    {
      onCompleted: (response) => {
        toast({
          title: t('role:jobDeleteSuccess'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        setShowData({
          initialModal: false,
          itemsModal: false,
          noDataModal: false,
          selectedRole: {
            color: '',
            eid: '',
            updatedAt: '',
            createdBy: { image: '', name: '' },
            members: [],
            name: '',
            status: '',
          },
        });
        getUser({ variables: {} });
      },
    }
  );

  const onCancelPress = (data: IRoleData) => {
    // moveItemTo({
    //   roleData: data,
    //   onBackPress: () => onDeletePress(data),
    //   onDeletePress: () => { },
    // });
  };

  const clickedItemsHandler = (clickedItemName: string, item: IRoleData) => {
    if (clickedItemName === 'delete') {
      setShowData({
        ...showData,
        initialModal: true,
        itemsModal: false,
        noDataModal: false,
        selectedRole: item,
      });
    } else {
      setShowData({
        ...showData,
        initialModal: false,
        itemsModal: false,
        noDataModal: false,
        selectedRole: item,
      });
      setNewRole(true);
      setJobModalType('edit');
    }
    // confirmRef.current = confirm({
    //   title: (
    //     <Flex gap={'12px'}>
    //       <Box flex={1}>
    //         <PreviewHeader title={'Delete'} color={'#B5E4CA'} />
    //       </Box>
    //       <AlertDialogCloseButton
    //         pos={'relative'}
    //         borderRadius={'full'}
    //         top={'0'}
    //         right={'0'}
    //         onClick={(event) => {
    //           event.preventDefault();
    //           confirmRef.current?.destroy?.();
    //         }}
    //       />
    //     </Flex>
    //   ),
    //   content: (
    //     <Flex
    //       fontSize={'14px'}
    //       fontWeight={400}
    //       color='#33383F'
    //       bg='rgba(177, 229, 252, 0.4)'
    //       py='12px'
    //       px='16px'
    //       borderRadius='10px'
    //       gap='12px'
    //       align='center'
    //     >
    //       <FontAwesomeIcon icon={faTrashCan as IconProp} color={'#2A85FF'} />
    //       Deleting this role will deactivate all the members for the role.
    //     </Flex>
    //   ),
    //   isCentered: true,
    //   contentProps: {
    //     paddingTop: '12px',
    //     paddingBottom: '12px',
    //     minW: '600px',
    //   },
    //   okButtonProps: {
    //     colorScheme: 'red',
    //     borderRadius: '10px',
    //   },
    //   cancelButtonProps: {
    //     colorScheme: 'blue',
    //     borderRadius: '10px',
    //   },
    //   cancelText: 'Move members to another role',
    //   okText: 'Delete anyway',
    //   onOK: () => {
    //     return deleteRole({
    //       variables: {
    //         role: item?.name
    //       }
    //     })
    //   },
    //   onCancel: () => onCancelPress(item),
    // });
  };

  const onDeleteAnywayHandler = () => {
    return deleteRole({
      variables: {
        role: showData?.selectedRole?.name,
      },
    });
  };

  const onMoveMembersToAnotherRoleHandler = () => {
    if (showData?.selectedRole?.members?.length > 0) {
      setShowData({
        ...showData,
        initialModal: false,
        itemsModal: true,
        noDataModal: false,
      });
    } else {
      setShowData({
        ...showData,
        initialModal: false,
        itemsModal: false,
        noDataModal: true,
      });
    }
  };

  const onSearchHandler = (e) => {
    setSearchQuery(e?.target?.value);
    if (!e?.target?.value) {
      setRolesListData(initialRolesListData);
    } else {
      const _rolesData = initialRolesListData?.filter((role) => {
        return role?.name
          ?.toLowerCase()
          ?.includes(e?.target?.value?.toLowerCase());
      });
      setRolesListData(_rolesData);
    }
  };

  const onSortChangeHandler = (value) => {
    let _initialRolesListData: IRoleData[] = JSON.parse(
      JSON.stringify(initialRolesListData)
    );
    let sortedData;
    switch (value?.value) {
      case 'Last Updated':
        sortedData = _initialRolesListData?.sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
        setRolesListData(sortedData);
        break;
      case 'Last Created':
        sortedData = _initialRolesListData?.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setRolesListData(sortedData);
        break;
      case 'Alphabetically':
        sortedData = _initialRolesListData?.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setRolesListData(sortedData);
        break;
      case 'Active':
        sortedData = _initialRolesListData?.filter(
          (role) => role?.status?.toLowerCase() === 'valid'
        );
        setRolesListData(sortedData);
        break;
      case 'Inactive':
        sortedData = _initialRolesListData?.filter(
          (role) => role?.status?.toLowerCase() === 'invalid'
        );
        setRolesListData(sortedData);
        break;
      default:
        setRolesListData(initialRolesListData);
        return;
    }
  };

  const jobMembersClickHandler = (
    members: IMembersDataEntity[],
    title: string
  ) => {
    setJobListModalData({
      show: true,
      membersData: members,
      title: title,
    });
  };

  const onUserChatClick = (userId: string) => {
    setJobListModalData({ ...jobMembersListModalData, show: false });
    setSelectedUserId(userId);
    setShowUserChatModal(true);
  };

  return (
    <>
      <SearchFilterHeader
        searchQuery={searchQuery}
        onSearch={onSearchHandler}
        onSortChange={onSortChangeHandler}
      />
      <Box>
        <SortingTable
          customWrapperStyles={{ marginTop: '1rem' }}
          colorScheme='blue'
          emptyData={{
            content: searchQuery ? (
              <EmptyState
                image='Search'
                title={t('header:result_not_found')}
                description={t('header:not_found_desc')}
              />
            ) : (
              <EmptyState
                image='DataCenter'
                title={t('role:noJobPresent')}
                description={
                  userData?.authRole === AuthRole.SUPER_ADMIN ||
                  userData?.authRole === AuthRole.ADMIN
                    ? t('role:noRolePresentDescSAA')
                    : t('role:noRolePresentDescLO')
                }
                height='55vh'
                showButton={
                  (userData?.authRole === AuthRole.SUPER_ADMIN ||
                    userData?.authRole === AuthRole.ADMIN ||
                    userData?.authRole === AuthRole.LOCATION_OWNER) &&
                  userData?.type === 'user'
                }
                buttonProps={
                  <PrimaryButton
                    title={
                      userData?.authRole === AuthRole.SUPER_ADMIN ||
                      userData?.authRole === AuthRole.ADMIN
                        ? t('role:addJob')
                        : t('role:contactAdmin')
                    }
                    onClick={() =>
                      userData?.authRole === AuthRole.SUPER_ADMIN ||
                      userData?.authRole === AuthRole.ADMIN
                        ? jobModalHandler()
                        : history.push(CHAT_URL)
                    }
                    variant='solid'
                    colorScheme='blue'
                    size='lg'
                  />
                }
              />
            ),
          }}
          page={1}
          onPageChange={() => {}}
          columns={getColumns(
            false,
            Text,
            t,
            jobMembersClickHandler,
            userData?.authRole
          )}
          data={getTableData(
            false,
            rolesListData,
            clickedItemsHandler,
            FilledHorizontal,
            faTrash,
            faPenToSquare,
            t,
            userData?.authRole
          )}
          // data={[]}
          isLoading={getAllUsesLoading}
          totalRegisters={10}
          isResponsive
          isHidePagination
        />
      </Box>
      {showData?.initialModal && (
        <GenericModal
          isOpen={showData?.initialModal}
          onClose={() =>
            setShowData({
              ...showData,
              initialModal: false,
              itemsModal: false,
              noDataModal: false,
              selectedRole: {
                color: '',
                eid: '',
                updatedAt: '',
                createdBy: { image: '', name: '' },
                members: [],
                name: '',
                status: '',
              },
            })
          }
          title={t('category:delete_name', {
            value: showData?.selectedRole?.name,
          })}
          closeIcon={closeIcon}
          lineColor={'#b5e4ca'}
          isCentered={true}
          buttonsObject={[
            {
              mt: 0,
              type: 'button',
              size: 'lg',
              isLoading: false,
              disabled: false,
              title: t('role:moveJobMembers'),
              leftIcon: null,
              rightIcon: null,
              onClick: onMoveMembersToAnotherRoleHandler,
              style: {
                width: 'fit-content',
                marginRight: '1rem',
              },
              variant: 'solid',
              colorScheme: 'blue',
              className: null,
              value: null,
            },
            {
              mt: 0,
              type: 'button',
              size: 'lg',
              isLoading: deleteAnwayLoading,
              disabled: deleteAnwayLoading,
              title: t('category:delete_anyway'),
              leftIcon: null,
              rightIcon: null,
              onClick: onDeleteAnywayHandler,
              style: {
                width: 'fit-content',
                backgroundColor: 'rgba(255, 106, 85, 1)',
              },
              variant: 'solid',
              colorScheme: 'blue',
              className: null,
              value: null,
            },
          ]}
          buttonsPosition='right'
          paddingLeft={10}
          paddingRight={10}
          paddingTop={10}
          paddingBottom={10}
          modalSizes={{
            sm: '800px',
            md: '800px',
            base: '800px',
            xl: '800px',
            ['2xl']: '800px',
          }}
          modalMinSizes={{
            sm: '800px',
            md: '800px',
            base: '800px',
            xl: '800px',
            ['2xl']: '800px',
          }}
        >
          <div
            style={{
              marginTop: '13px',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '1.63',
              letterSpacing: '-0.16px',
              marginLeft: '20px',
              width: '93%',
            }}
          >
            <Flex
              fontSize={'14px'}
              fontWeight={400}
              color='#33383F'
              bg='rgba(177, 229, 252, 0.4)'
              py='12px'
              px='16px'
              borderRadius='10px'
              gap='12px'
              align='center'
            >
              <FontAwesomeIcon
                icon={faTrashCan as IconProp}
                color={'#2A85FF'}
              />
              {t('role:deleteJobWarning')}
            </Flex>
          </div>
        </GenericModal>
      )}
      {showData?.itemsModal && (
        <MoveMembersToAnotherRoleModal
          setShowData={setShowData}
          showData={showData}
          jobHeading
          roleData={showData?.selectedRole}
          isOpen={showData?.itemsModal}
          onClose={() => {
            setShowData({ ...showData, itemsModal: false });
            getAllUsersHandler();
          }}
          selectedAlignCenter
        />
      )}
      {showData?.noDataModal && (
        <NoRoleModal
          isOpen={showData?.noDataModal}
          onClose={() => setShowData({ ...showData, noDataModal: false })}
          onDeletePress={onDeleteAnywayHandler}
          roleData={showData?.selectedRole}
          setShowData={setShowData}
          showData={showData}
          loading={deleteAnwayLoading}
        />
      )}
      {newRole && (
        <AddJobModal
          open={newRole}
          type={jobModalType}
          onClose={() => setNewRole(false)}
          onRoleCreate={(value: string) => {
            getUser({ variables: {} });
          }}
          roleName={showData?.selectedRole?.name}
        />
      )}
      <JobMembersListModal
        isOpen={jobMembersListModalData?.show}
        onClose={() =>
          setJobListModalData({ ...jobMembersListModalData, show: false })
        }
        title={jobMembersListModalData?.title}
        _membersData={jobMembersListModalData?.membersData}
        onUserChatClick={onUserChatClick}
      />
      {showUserChatModal && (
        <SingleUserChatModal
          onClose={() => {
            setShowUserChatModal(false);
            setJobListModalData({ ...jobMembersListModalData, show: true });
          }}
          selectedUser={selectedUserId}
        />
      )}
    </>
  );
};

JobsListContainer.displayName =
  'displayName:pages/Teams/Jobs/JobsListContainer';

export default JobsListContainer;
