import { SelectOption } from 'atoms';
import { LauncherConfig } from 'sub-components/Launcher/launcher-config/common/launcher-config.fragment';
import { createStore } from 'zustandStore';
import { immer } from 'zustandStore/middleware';
import {
  ExtendedLauncherConfig,
  PhaseData,
  ViewMode,
} from './project-detail-phase.types';
import { phaseData } from './static-data';

export interface ProjectDetailPhaseState {
  launcherData: ExtendedLauncherConfig | undefined;
  view: ViewMode;
}

export interface ProjectDetailPhaseAction extends ProjectDetailPhaseState {
  updateLauncherData: (data: ExtendedLauncherConfig | undefined) => void;
  updateView: (view: ViewMode) => void;
}

export const useProjectDetailPhaseStore =
  createStore<ProjectDetailPhaseAction>();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createProjectDetailPhaseStore = () => {
  return createStore<ProjectDetailPhaseAction>()(
    immer((set) => ({
      launcherData: undefined as ExtendedLauncherConfig | undefined,
      view: 'view' as ViewMode,
      updateLauncherData: (view) => {
        set((state) => {
          state.launcherData = view;
        });
      },
      updateView: (view) => {
        set((state) => {
          state.view = view;
        });
      },
    }))
  );
};
