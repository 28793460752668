import React, { FC } from 'react';
import { useLauncherConfigContext } from '../common';
import { BoardByCategoryLists } from './by-category';
import BoardSectionLists from './BoardSectionLists';

interface IProps {}

const SectionListWrapper: FC<IProps> = () => {
  const groupBy = useLauncherConfigContext((state) => state.groupBy);

  if (groupBy === 'category') {
    return <BoardByCategoryLists />;
  }

  return <BoardSectionLists />;
};

export default SectionListWrapper;
