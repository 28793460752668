import React, { FC } from 'react';
import {
  Badge,
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import { ReactComponent as FilterIcon } from 'assets/images/nexus/compliance/filled.svg';
import BaseFilter from './BaseFilter';
import MainContainer from './MainContainer';
import { Filter, FiltersType } from '../../ComplianceListContainer';
import { EntityLocations, LocationsEntity } from 'sop-commons/src/client';
import { UserEntityData } from 'shared/graphql/shared-types';

export type ClearFilterType = 'all' | 'locations' | 'uploadedBy';

interface FilterContainerProps {
  filters: FiltersType;
  setFilters: React.Dispatch<React.SetStateAction<FiltersType>>;
  handleExpiredDocFilter: () => void;
  handleLocationSelection: (
    e: React.ChangeEvent<HTMLInputElement>,
    location: LocationsEntity | EntityLocations
  ) => void;
  handleUploadedBySelection: (
    e: React.ChangeEvent<HTMLInputElement>,
    user: UserEntityData
  ) => void;
  clearFilters: (type?: ClearFilterType) => void;
  complianceLoader: any;
}

const FilterContainer: FC<FilterContainerProps> = ({
  filters,
  handleExpiredDocFilter,
  setFilters,
  handleLocationSelection,
  handleUploadedBySelection,
  clearFilters,
  complianceLoader,
}) => {
  const filterCount = filters?.totalFiltersTypes;

  return (
    <Popover placement='left-end' isLazy>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            {filterCount > 0 ? (
              <Box position='relative' display='inline-block'>
                <Badge
                  position='absolute'
                  top='-6px'
                  right='-5px'
                  bgColor='#2A85FF'
                  borderRadius='full'
                  textAlign='center'
                  w='18px'
                  fontSize='12px'
                  fontWeight={600}
                  color='white'
                >
                  {filterCount}
                </Badge>
                <FilterIcon cursor='pointer' />
              </Box>
            ) : (
              <Box>
                <FilterIcon cursor='pointer' />
              </Box>
            )}
          </PopoverTrigger>
          <PopoverContent p={'10px'} borderRadius={'12px'}>
            <PopoverArrow />
            <PopoverBody>
              <MainContainer
                filters={filters}
                setFilters={setFilters}
                handleExpiredDocFilter={handleExpiredDocFilter}
                handleLocationSelection={handleLocationSelection}
                handleUploadedBySelection={handleUploadedBySelection}
                clearFilters={clearFilters}
                complianceLoader={complianceLoader}
              />
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default FilterContainer;
