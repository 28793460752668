// @ts-ignore
import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { Tooltip } from '@chakra-ui/react';
import { AssignedToUsersEntity } from '../training.types';
import Image from '../../../ui-components/Image/Image';
import { useTranslation } from 'react-i18next';

import { getImage } from '../../../utils';

const Wrapper = styled.div<{ status: string }>`
  margin-bottom: 16px;

  ${({ status }) => ({
    backgroundColor:
      status === 'active' || status === 'draft' ? 'none' : 'white',
    opacity: status === 'active' || status === 'draft' ? '1' : '.5',
    cursor: status === 'active' || status === 'draft' ? 'default' : 'no-drop',
  })}

  .avatar-list {
    position: relative;
    display: flex;
    width: max-content;
    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      cursor: ${({ status }) =>
        status === 'active' || status === 'draft' ? 'pointer' : 'no-drop'};

      :not(:first-of-type) {
        margin-left: -8px;
      }
    }
  }

  .more-button {
    font-size: 12px;
    font-weight: 500;
    color: #6f767e;
    padding-top: 2px;
  }
`;

interface IProps {
  assignees: Array<{
    eid: string;
    name: string;
    profilePic: string;
    status: string;
  }>;
  status: string;
}

const Assignee: FC<IProps> = ({ assignees = [], status }) => {
  const { t } = useTranslation('training');
  const [data, moreAssignee] = useMemo(() => {
    // const more = assignees.length - 4;
    // return [assignees?.slice(0, 4) || [], more];
    let activeAssignees = assignees?.filter(
      (assignee) => assignee?.status === 'active'
    );
    const more = activeAssignees.length - 4;
    return [activeAssignees?.slice(0, 4) || [], more];
  }, [assignees]);

  return (
    <Wrapper status={status}>
      <Tooltip
        label={assignees?.map((value) => value.name)?.join(', ')}
        hasArrow
        p='8px 12px'
        borderRadius='8px'
      >
        <div className='avatar-list'>
          {data.map((user) => {
            return (
              <Image
                width={20}
                height={20}
                key={user.eid}
                src={getImage(user.profilePic, user.name)}
              />
            );
          })}
        </div>
      </Tooltip>
      {moreAssignee > 0 && (
        <div className='more-button'>
          {t('count_more', {
            value: moreAssignee,
          })}
        </div>
      )}
    </Wrapper>
  );
};

Assignee.displayName = 'pages/Training/Training/Assignee';

export default Assignee;
