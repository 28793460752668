import React, { FC, useEffect, useState } from 'react';
import { CloseButton, Flex } from '@chakra-ui/react';
import { BoxHeader } from '../../../../../ui-components';
import { HeaderColors } from '../../../../../configs';

import { EditorConfigTaskForm, useLauncherConfigContext } from '../../common';

interface IProps {}

const EditorTaskForm: FC<IProps> = () => {
  const [isLoading, updateLoading] = useState(false);
  const taskForm = useLauncherConfigContext((state) => state.taskForm);

  const action = useLauncherConfigContext((state) => ({
    closeTaskForm: state.closeTaskForm,
  }));

  const checkValue = `${taskForm?.taskIndex}-${taskForm?.sectionIndex}-${taskForm?.taskEid}`;

  useEffect(() => {
    (async () => {
      updateLoading(true);
      setTimeout(() => updateLoading(false), 100);
    })();
  }, [checkValue]);

  if (typeof taskForm?.taskIndex !== 'number') {
    return <Flex flex={5} flexDir='column' gap={4} overflow='auto'></Flex>;
  }

  const phaseName = taskForm?.phase?.category;

  return (
    <Flex flex={5} flexDir='column' gap={4} overflow='auto'>
      <Flex gap={4} justify='space-between' align='center'>
        <BoxHeader
          title={
            taskForm?.taskEid
              ? `Edit task in ${phaseName}`
              : `Add task to ${phaseName}`
          }
          isTruncated
          color={HeaderColors.Purple}
          fontSize='18px'
        />
        <CloseButton onClick={action.closeTaskForm} />
      </Flex>
      {isLoading ? null : <EditorConfigTaskForm bg='white' />}
    </Flex>
  );
};

EditorTaskForm.displayName = 'sc/L/lc/ev/etf/EditorTaskForm';

export default EditorTaskForm;
