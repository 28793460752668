import {
  EntityLabel,
  EntityTypes,
  LocationTypeOption,
} from 'pages/Locations/AddLocation/static-data';
import { Schema } from './types';

export const existingJsonSchema: Schema = {
  Address: {
    value: {
      type: 'string',
      required: {
        flag: true,
        message: 'Address is required',
      },
    },
  },
  City: {
    value: {
      type: 'string',
      required: {
        flag: true,
        message: 'City is required',
      },
    },
  },
  'Country of formation': {
    value: {
      type: 'string',
      required: {
        flag: false,
        message: '',
      },
    },
  },
  'Email address': {
    value: {
      type: 'string',
      required: {
        flag: true,
        message: 'Email address is required',
      },
      validate: {
        pattern: '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$',
        message: 'Email must be a valid email format',
      },
    },
  },
  'Entity name': {
    value: {
      type: 'string',
      required: {
        flag: false,
        message: '',
      },
    },
  },
  'Entity type': {
    value: {
      type: 'string',
      required: {
        flag: false,
        message: '',
      },
      values: {
        data: EntityTypes.map((value) => ({
          value: value,
          label: EntityLabel[value],
        })),
        message:
          'Added option for Entity type is invalid. Please select correct option from dropdown.',
      },
    },
  },
  'Location name': {
    value: {
      type: 'string',
      required: {
        flag: true,
        message: 'Location name is required',
      },
      unique: {
        flag: true,
        message: 'Location name must be unique',
      },
      // validate: {
      //   pattern: '^[a-zA-Z0-9()\\- ]+$',
      //   message:
      //     'Location name can only include letters, numbers, hyphens, and brackets',
      // },
    },
  },
  'Location type': {
    value: {
      type: 'string',
      required: {
        flag: true,
        message: 'Location type is required',
      },
      values: {
        data: LocationTypeOption,
        message:
          'Added option for Location type is invalid. Please select correct option from dropdown.',
      },
    },
  },
  'Phone number': {
    value: {
      type: 'string',
      required: {
        flag: true,
        message: 'Phone number is required',
      },
      validate: {
        pattern: '^\\d{3}-\\d{3}-\\d{4}$',
        message: 'Phone number must be in the format XXX-XXX-XXXX',
      },
    },
  },
  State: {
    value: {
      type: 'string',
      required: {
        flag: true,
        message: 'State is required',
      },
    },
  },
  'State of formation': {
    value: {
      type: 'string',
      required: {
        flag: false,
        message: '',
      },
    },
  },
  'Tax Payer Id': {
    value: {
      type: 'string',
      required: {
        flag: false,
        message: '',
      },
    },
  },
  Zipcode: {
    value: {
      type: 'string',
      required: {
        flag: true,
        message: 'Zipcode is required',
      },
      // validate: {
      //   pattern: '^\\d{5}(-\\d{4})?$',
      //   message: 'Zipcode must be in the format XXXXX or XXXXX-XXXX',
      // },
    },
  },
};

export const preLaunchJsonSchema: Schema = {
  Address: {
    value: {
      type: 'string',
      required: {
        flag: false,
        message: '',
      },
    },
  },
  City: {
    value: {
      type: 'string',
      required: {
        flag: false,
        message: '',
      },
    },
  },
  'Country of formation': {
    value: {
      type: 'string',
      required: {
        flag: false,
        message: '',
      },
    },
  },
  'Email address': {
    value: {
      type: 'string',
      required: {
        flag: false,
        message: '',
      },
      validate: {
        pattern: '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$',
        message: 'Email must be a valid email format',
      },
    },
  },
  'Entity name': {
    value: {
      type: 'string',
      required: {
        flag: false,
        message: '',
      },
    },
  },
  'Entity type': {
    value: {
      type: 'string',
      required: {
        flag: false,
        message: '',
      },
      values: {
        data: EntityTypes.map((value) => ({
          value: value,
          label: EntityLabel[value],
        })),
        message:
          'Added option for Entity type is invalid. Please select correct option from dropdown.',
      },
    },
  },
  'Location name': {
    value: {
      type: 'string',
      required: {
        flag: true,
        message: 'Location name is required',
      },
      unique: {
        flag: true,
        message: 'Location name must be unique',
      },
      // validate: {
      //   pattern: '^[a-zA-Z0-9()\\- ]+$',
      //   message:
      //     'Location name can only include letters, numbers, hyphens, and brackets',
      // },
    },
  },
  'Location type': {
    value: {
      type: 'string',
      required: {
        flag: true,
        message: 'Location type is required',
      },
      values: {
        data: LocationTypeOption,
        message:
          'Added option for Location type is invalid. Please select correct option from dropdown.',
      },
    },
  },
  'Phone number': {
    value: {
      type: 'string',
      required: {
        flag: false,
        message: '',
      },
      validate: {
        pattern: '^\\d{3}-\\d{3}-\\d{4}$',
        message: 'Phone number must be in the format XXX-XXX-XXXX',
      },
    },
  },
  State: {
    value: {
      type: 'string',
      required: {
        flag: false,
        message: '',
      },
    },
  },
  'State of formation': {
    value: {
      type: 'string',
      required: {
        flag: false,
        message: '',
      },
    },
  },
  'Tax Payer Id': {
    value: {
      type: 'string',
      required: {
        flag: false,
        message: '',
      },
    },
  },
  Zipcode: {
    value: {
      type: 'string',
      required: {
        flag: false,
        message: '',
      },
      // validate: {
      //   pattern: '^\\d{5}(-\\d{4})?$',
      //   message: 'Zipcode must be in the format XXXXX or XXXXX-XXXX',
      // },
    },
  },
};
