import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface IProps {
  emptyBoard?: boolean;
}

const BoardHeader: FC<IProps> = ({ emptyBoard }) => {
  const { t } = useTranslation('launcher');

  return (
    <Flex flexDir='column'>
      <Box fontSize='24px' fontWeight='700' color='#272B30'>
        {t('myBoards')}
      </Box>

      <Box fontSize='15px' fontWeight='400' color='#6F767E'>
        {t(emptyBoard ? 'myBoardsDesc' : 'myBoardsDesc2')}
      </Box>
    </Flex>
  );
};

export default BoardHeader;
