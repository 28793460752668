import React, { FC, useMemo } from 'react';
import { Flex, useModalContext } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '../../../../../../../../ui-components';

interface IProps {
  onAddClick: () => void;
}

const FooterComponent: FC<IProps> = ({ onAddClick }) => {
  const { t } = useTranslation(['common', 'task']);
  const { onClose } = useModalContext();
  const taskSteps = useWatch({
    name: 'taskStepSelection',
  });

  const isDisabled = useMemo(() => {
    return !Object.values(taskSteps || {})?.filter(Boolean)?.length;
  }, [taskSteps]);

  return (
    <Flex justify='space-between' px={3}>
      <ActionButton
        minW='140px'
        variant='outline'
        borderWidth='2px'
        size='lg'
        fontSize='15px'
        fontWeight='700'
        borderRadius='12px'
        close={onClose}
      >
        {t('common:cancel')}
      </ActionButton>

      <ActionButton
        minW='180px'
        colorScheme='blue'
        size='lg'
        fontSize='15px'
        fontWeight='700'
        borderRadius='12px'
        close={onClose}
        actionFn={onAddClick}
        isDisabled={isDisabled}
      >
        {t('task:addToTask')}
      </ActionButton>
    </Flex>
  );
};

FooterComponent.displayName =
  'sub-components/Launcher/launcher-config/common/config-task-form/task-steps/resource/common/FooterComponent';

export default FooterComponent;
