import { gql } from '@apollo/client';
import {
  LauncherAssignee,
  LauncherTrigger,
} from 'sub-components/Launcher/launcher-config/common/launcher-config.fragment';
import { LauncherTaskInput } from 'sub-components/Launcher/launcher-config/common/launcher-config.store';
import { TriggerDetails } from 'types/location-launcher/details/location-tasks';
import { ITaskStatus } from 'ui-components/TaskStatusIcon/types';

export const LAUNCHER_LOCATION_BY_ID = gql`
  query LocationLaunchById($eid: String!) {
    LocationLaunchById(eid: $eid) {
      entityId
      launcherId
      locationId
      supervisors
      createdBy
      startDate
      liveDate
      isLive
      contents {
        category
        selected
        assignedUser
        assignedType
        isCompleted
        completedAt
        completedBy
        startDate
        dueDate
        triggerAssignment
        trigger {
          triggerChoice
          activationTrigger {
            value
            unit
            order
            dependentOn
            itemId
            deadlineType
            deadlineValue
            deadlineUnit
          }
          deadlineTrigger {
            value
            unit
            order
            dependentOn
            itemId
          }
        }
        triggerDetails {
          triggerChoice
          activationOffset
          deadlineOffset
          triggerItemId
          activationOrder
          deadlineOrder
          dependantOn
          deadlineType
          startDate
          dueDate
        }
        tasks {
          title
          description
          docRequired
          isRequired
          duration
          dependency
          startDate
          dueDate
          steps {
            stepId
            title
            isCompleted
            type
            formId
            sopId
            trainingAssignee
            trainingId
            formResponseId
            completedAt
            completedBy
          }
          buffer
          assignedUser
          assignedType
          category
          files {
            name
            url
            type
            mimetype
            fileSize
            createdBy
            createdAt
            updatedAt
          }
          trigger {
            triggerChoice
            activationTrigger {
              value
              unit
              order
              dependentOn
              itemId
              deadlineType
              deadlineValue
              deadlineUnit
            }
            deadlineTrigger {
              value
              unit
              order
              dependentOn
              itemId
            }
          }
          triggerDetails {
            triggerChoice
            activationOffset
            deadlineOffset
            triggerItemId
            activationOrder
            deadlineOrder
            dependantOn
            deadlineType
            startDate
            dueDate
          }
          isCompleted
          completedAt
          completedBy
          eid
        }
        eid
      }
      username
      password
      details {
        timezone
        address {
          address
          city
          zipCode
          state
          country
          _id
        }
        locationType
        locationStatus
        locationName
        locationPhone
        locationEmail
        entityName
        entityType
        countryOfFormation
        stateOfFormation
        taxPayerId
        _id
      }
      version
      createdAt
      updatedAt
      eid
      tasks
      location {
        name
      }
    }
  }
`;

export const ADD_TASK_TO_LAUNCHER = gql`
  mutation AddNewTaskToLauncher($input: AddNewLocationLauncherTaskInputInput) {
    AddNewTaskToLauncher(input: $input) {
      eid
    }
  }
`;
export interface LocationLaunchByIdResponse {
  LocationLaunchById: {
    launcherId: string;
    locationId: string;
    supervisors: string[];
    startDate: Date;
    liveDate: Date;
    isLive: boolean;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    eid: string;
    details: {
      locationName: string;
    };
    location: {
      name: string;
    };
    contents: LocationPhaseDetails[];
    tasks?: PhaseTask[];
  };
}

export interface LocationPhaseDetails
  extends Partial<LauncherAssignee>,
    LauncherTrigger {
  eid: string;
  category: string;
  tasks: PhaseTask[];
  isCompleted: boolean;
  startDate: Date;
  dueDate: Date;
  completedAt: Date;
  triggerDetails: TriggerDetails;
}

export interface PhaseTask extends LauncherTaskInput {
  isCompleted: boolean;
  startDate: Date;
  dueDate: Date;
  status: ITaskStatus;
  completedAt: Date;
  phaseEid?: string;
  launcherId?: string;
  triggerDetails: TriggerDetails;
  phaseName: string;
  phase?: string;
  files: {
    name: string;
    type: string;
    mimetype: string;
    url: string;
    fileSize: number;
    createdBy: string;
    createdAt: string;
    updatedAt: string;
  }[];
  unlockMessage?: string;
  taskScheduleMessage?: string;
}
