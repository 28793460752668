import React, { useCallback, useRef } from 'react';
import { useConfirm, UseConfirm } from '../../../../../../ui-components';
import { LauncherTrigger } from '../../launcher-config.fragment';
import TaskTriggerContainer from './TaskTriggerContainer';

export interface TaskTriggerInput extends LauncherTrigger {
  eid?: string;
}

interface IProps {
  taskName: string;
  defaultValue: TaskTriggerInput;
  onSaveChanges?: (data: LauncherTrigger) => void;
}

type TaskSetting = (props: IProps) => void;

export const useTaskTriggerSetting = (): TaskSetting => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  return useCallback((props) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      content: (
        <TaskTriggerContainer
          taskName={props.taskName}
          defaultValue={props.defaultValue}
          onSaveChanges={(values) => {
            props.onSaveChanges?.(values);
            setTimeout(confirmRef.current?.destroy!, 100);
          }}
        />
      ),
      contentProps: {
        minWidth: '1200px',
        borderRadius: '12px',
      },
      bodyProps: {
        padding: 0,
      },
      footer: null,
    });

    return confirmRef.current;
  }, []);
};
