import { useReactiveVar } from '@apollo/client';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { Divider } from 'antd';
import { useUserDataSelector } from 'hooks';
import moment from 'moment';
import React, { FC, useMemo } from 'react';
import { usersEntityObj } from 'sub-components/Header';
import { PhaseTask } from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import { BoxHeader } from 'ui-components';
import { getImage } from 'utils';
import TaskStep from './TaskStep';
import CustomProgress from './CustomProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
  createdAt?: string;
  createdBy?: string;
  taskData: PhaseTask | undefined;
  handleStatusChange: (
    step: PhaseTask['steps'][number],
    completed: boolean
  ) => Promise<void>;
  loadingStepIds: string[];
  isTaskLoading: boolean;
};

const LongTasks: FC<Props> = ({
  createdAt,
  createdBy,
  taskData,
  handleStatusChange,
  loadingStepIds,
  isTaskLoading,
}) => {
  const loggedInUserDetails = useUserDataSelector((state) => ({
    name: state?.name,
    profilePic: state?.profilePic,
  }));
  const entityData = useReactiveVar(usersEntityObj);
  const completedSteps =
    taskData?.steps?.filter((step) => step?.completedAt)?.length || 0;
  const totalSteps = taskData?.steps?.length || 0;
  const percentCompletion = ((completedSteps / totalSteps) * 100)?.toFixed(2);
  const taskCompleted = !!(
    (taskData?.completedAt &&
      taskData?.steps?.every((step) => step?.completedAt)) ||
    taskData?.status === 'locked' ||
    taskData?.status === 'yetToStart' ||
    taskData?.status === 'yetToStart/locked'
  );

  const createdByUser = useMemo(() => {
    return (
      entityData
        ?.filter((en) => en?.eid === createdBy)
        ?.map((user) => user?.name)?.[0] || '-'
    );
  }, [createdBy, entityData]);

  return (
    <Flex>
      <Flex flexDir={'column'} w='full'>
        <Stack>
          <Text
            fontWeight={600}
            fontSize={18}
            lineHeight={26}
            color={'#272B30'}
          >
            {taskData?.title}
          </Text>
          <Text
            fontWeight={400}
            fontSize={14}
            lineHeight={22}
            color={'#6F767E'}
          >
            {taskData?.description}
          </Text>
        </Stack>
        {totalSteps > 0 && (
          <Stack gap={4} mt={'12px'}>
            <Stack gap={4}>
              <Flex justifyContent={'space-between'}>
                <BoxHeader
                  title={
                    totalSteps === 1 ? '1 subtask' : `${totalSteps} subtasks`
                  }
                  color='#FFD88D'
                />
                {totalSteps > 0 && (
                  <Text fontWeight={500} color={'#6F767E'}>
                    {percentCompletion}% Done
                  </Text>
                )}
              </Flex>
              {totalSteps > 0 && (
                <CustomProgress
                  max={totalSteps}
                  value={completedSteps}
                  profileUrl={getImage(
                    loggedInUserDetails?.profilePic,
                    loggedInUserDetails?.name
                  )}
                />
              )}
            </Stack>
            <Stack gap={2} overflowY='scroll' maxH='38vh' minH='20vh'>
              {completedSteps === totalSteps && (
                <Flex
                  align='center'
                  gap='5px'
                  bg='rgba(255, 106, 85, 0.13)'
                  p='12px 16px'
                  borderRadius='12px'
                >
                  <FontAwesomeIcon
                    icon={faTriangleExclamation as IconProp}
                    color='rgba(111, 118, 126, 1)'
                  />
                  <Box as='span' color='rgba(111, 118, 126, 1)'>
                    The previous responses will be lost if you edit or uncheck
                    any task
                  </Box>
                </Flex>
              )}
              {taskData?.steps?.map((step) => {
                const isStepLoading = loadingStepIds?.includes(step?.stepId);
                const isDisabled =
                  isTaskLoading ||
                  (loadingStepIds?.length > 0 && !isStepLoading);
                return (
                  <Flex key={step?.stepId} flexDir='column'>
                    <TaskStep
                      key={step?.stepId}
                      title={step?.title}
                      type={step?.type}
                      taskCompleted={taskCompleted}
                      completed={!!step?.completedAt}
                      addedOn={moment?.utc(createdAt)?.toDate()}
                      createdBy={createdByUser}
                      taskImage={'-'}
                      handleStatusChange={(value: boolean) =>
                        handleStatusChange(step, value)
                      }
                      isLoading={isStepLoading}
                      disabled={isDisabled}
                    />
                    <Divider />
                  </Flex>
                );
              })}
            </Stack>
          </Stack>
        )}
      </Flex>
    </Flex>
  );
};

LongTasks.displayName =
  'displayName:sub-components/Launcher/tasks/components/task-details/components/LongTasks';

export default LongTasks;
