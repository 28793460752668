import React, { FC } from 'react';
import { Box, Button, Center, Flex, Link, Stack, Text } from '@chakra-ui/react';
import AddNewOwner from './AddNewOwner';
import { AppRoute, useHistory } from 'routes';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';

interface IProps {
  locationHeaderDetails: {
    name: string;
    locationId: string;
    startDate?: Date;
    liveDate?: Date;
    isLive: boolean;
    createdAt: Date;
  };
}

interface IParams {
  locationId: string;
}

const LaunchLocationHeader: FC<IProps> = ({
  locationHeaderDetails: { name, locationId, liveDate, isLive, createdAt },
}) => {
  const history = useHistory();
  const { locationId: locId } = useParams<IParams>();

  const getDevelopmentStatus = () => {
    if (!isLive)
      return {
        status: 'In-Development',
        date: moment(createdAt).format('Do MMMM'),
      };
    else
      return {
        status: 'Live',
        date: moment(liveDate).format('Do MMMM'),
      };
  };

  return (
    <Flex justifyContent='space-between' alignItems='center'>
      <Box display='flex'>
        <div>
          <Flex gap={4}>
            <FontAwesomeIcon
              cursor='pointer'
              icon={faArrowLeft as IconProp}
              fontSize='25px'
              onClick={() => history.goBack()}
            />
            <Text fontSize={24} fontWeight={600} lineHeight='48px'>
              {name}
            </Text>
            <Link
              color='#2A85FF'
              fontSize={15}
              fontWeight={700}
              textDecoration='underline'
              textUnderlineOffset={4}
              onClick={() =>
                history.push({
                  pathname: AppRoute.LOCATION_DETAIL,
                  params: {
                    locationId: locationId,
                  },
                  state: { backToTitle: 'Back to Launcher Location details' },
                })
              }
            >
              View details
            </Link>
          </Flex>
          <Text color='#6F767E' fontSize={15} ml='40px' marginTop={1}>
            {getDevelopmentStatus().status} since {getDevelopmentStatus().date}
          </Text>
        </div>
      </Box>
      <Stack direction='row' spacing={4}>
        <Button
          fontSize={15}
          fontWeight={600}
          color='#2A85FF'
          variant='outline'
          border='1px solid #DDDDDD'
          background='#FFFFFF'
          onClick={() =>
            history.push({
              pathname: AppRoute.LAUNCHER_DOCUMENT_LISTING,
              params: {
                locationId: locationId,
              },
            })
          }
        >
          Document listing
        </Button>
        <AddNewOwner branchIds={[locationId]} />
      </Stack>
    </Flex>
  );
};

LaunchLocationHeader.displayName =
  'sub-components/Launcher/dashboard/Location/LaunchLocationHeader';

export default LaunchLocationHeader;
