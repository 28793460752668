import {
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Box,
  Image,
  Text,
  Center,
  Tooltip,
  Checkbox,
} from '@chakra-ui/react';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Searchbox,
  CategoryListing,
  ComplianceTable,
  VersionHistoryModal,
  DefaultTableEmptyState,
} from './components';
// import { FilterChip, FilterContainer } from './components/Filter';
import { FilterContainer, FilterChip } from './components/Filter-v2';

import { useLazyQuery, useReactiveVar } from '@apollo/client';

import {
  GET_COMPLIANCE_CATEGORIES,
  GET_COMPLIANCE_LISTING,
} from '../query/compliance.graphql';
import {
  EntityComplianceCategoriesType,
  TableDataType,
} from '../types/compliance.types';
import { AuthRole } from '../../../../authorization';

import { generateComplianceTableData, reverseArr } from './helper';
import { eventBus } from '../../../../shared/eventEmit';
import { useLatest, useUserDataSelector } from '../../../../hooks';
import { ReactComponent as LocationIcon } from 'assets/images/location-green.svg';
import { ReactComponent as DocumentIcon } from 'assets/images/nexus/compliance/document.svg';
import type { TableProps } from 'antd';
import { getImage } from 'utils';
import ComplianceUpdate from './components/ComplianceUpdate';
import DeleteDocumentModal from './components/DeleteDocumentModal';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDownShortWide,
  faCaretDown,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import EmptyStateCompliance from 'sub-components/nexus/Compliance/EmptyStateCompliance';
import Loader from 'sub-components/Loader';
import LocationFilter from './components/LocationFilter';
import { EntityLocations, LocationsEntity } from 'sop-commons/src/client';
import { usersEntityObj } from 'sub-components/Header';
import { UserEntityData } from 'shared/graphql/shared-types';

export interface Filter {
  filterby: ('expired' | 'users')[];
  users: SelectedMember[];
}
export type SelectedMember = {
  eid: string;
  name: string;
};

export type FiltersType = {
  expiredDocs: boolean;
  uploadedBy: any[];
  locations: LocationsEntity[] | EntityLocations[] | undefined;
  selectedLocations: any[];
  selectedUploadedBy: any[];
  selectedLocationsId: string[];
  selectedUploadedById: string[];
  mergedFiltersArray: any[];
  totalFiltersTypes: number;
};

const ComplianceListContainer: FC = () => {
  const entityData = useReactiveVar(usersEntityObj);
  const [isRenameFieldVisible, setIsRenameFieldVisible] =
    useState<boolean>(false);

  const [complianceCategory, setComplianceCategory] = useState<
    EntityComplianceCategoriesType[]
  >([]);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(0);
  const [tableData, setTableData] = useState<TableDataType[]>([]);
  const [searchFieldText, setSearchFieldText] = useState<string>('');
  const [selectedExpiryFilter, setSelectedExpiryFilter] = useState<string>('');
  const [complianceCategoryId, setComplianceCategoryId] = useState<string>('');
  const [previousLocations, setPreviousLocations] = useState<string[]>([]);
  const [selectedLocationFilters, setSelectedLocationFilters] = useState<
    string[]
  >([]);

  const [appliedFilter, setAppliedFilter] = useState<Filter>({
    filterby: [],
    users: [],
  });

  const [filters, setFilters] = useState<FiltersType>({
    expiredDocs: false,
    totalFiltersTypes: 0,
    uploadedBy: [],
    locations: [],
    selectedLocations: [],
    selectedUploadedBy: [],
    selectedLocationsId: [],
    selectedUploadedById: [],
    mergedFiltersArray: [],
  });

  const { userAuthRole, loggedInUserLocations, loggedInUserId, locationList } =
    useUserDataSelector((state) => ({
      userAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations,
      loggedInUserId: state?.eid,
      locationList: state?.entity?.locations,
    }));
  let loggedInUserLocationIds = loggedInUserLocations?.map((loc) => loc?.eid);

  //get location list
  let locationFilterArray =
    userAuthRole === AuthRole?.LOCATION_OWNER
      ? loggedInUserLocations
      : locationList;

  //Get list of uploaded by members
  const uploadedByMemberList = useCallback(() => {
    const users = entityData?.filter(
      (entity) =>
        entity?.type === 'user' &&
        [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(entity?.authRole)
    );

    setFilters((prevFilterState) => {
      return {
        ...prevFilterState,
        uploadedBy: users,
        locations: locationFilterArray,
      };
    });
  }, [entityData, locationFilterArray]);

  const [getComplianceCategories, { loading: complianceCategoryLoader }] =
    useLazyQuery(GET_COMPLIANCE_CATEGORIES, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        let sortedData = reverseArr(data?.EntityComplianceCategories);
        setComplianceCategory(sortedData);
      },
    });

  const [getComplianceListing, { loading: complianceLoader }] = useLazyQuery(
    GET_COMPLIANCE_LISTING,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setTotalRowsCount(data?.EntityCompliancePagination?.count);
        const complianceTableData = generateComplianceTableData(
          data?.EntityCompliancePagination?.items
        );
        setTableData(complianceTableData);
        setPreviousLocations(selectedLocationFilters);
        getComplianceCategories();
      },
    }
  );

  const getComplianceFilters = (
    pageIndex: number = 1,
    categoryId: string = '',
    sort: string = '_ID_DESC'
  ) => {
    let filter: {
      query?: string;
      locationId?: string;
      categoryId?: string;
      expiryDate?: string;
      createdBy?: string[];
      locationIds?: string[];
    } = {
      query: '',
    };
    if (appliedFilter.filterby.length > 0) {
      const { filterby, users } = appliedFilter;
      if (filterby.includes('expired')) {
        filter.expiryDate = new Date().toISOString();
      }
      if (users.length > 0) {
        const createdByUserIds = users.map((user) => user.eid);
        filter.createdBy = createdByUserIds;
      }
      if (filterby.includes('expired') && users.length > 0) {
        filter.expiryDate = new Date().toISOString();
        filter.createdBy = users.map((user) => user.eid);
      }
    }

    if (filters.expiredDocs) {
      filter.expiryDate = new Date().toISOString();
    }

    if (filters.selectedUploadedById?.length > 0) {
      filter.createdBy = filters.selectedUploadedById;
    }

    if (filters.selectedLocationsId?.length > 0) {
      filter.locationIds = filters.selectedLocationsId;
    }

    if (searchFieldText) {
      filter.query = searchFieldText;
    }

    if (categoryId) {
      setComplianceCategoryId(categoryId);
      filter.categoryId = categoryId;
    } else {
      setComplianceCategoryId('');
    }

    if (userAuthRole === AuthRole?.LOCATION_OWNER) {
      filter.locationIds = loggedInUserLocationIds;
    }

    if (selectedLocationFilters?.length > 0) {
      filter.locationIds = selectedLocationFilters;
    }

    return {
      filter: filter,
      sort: sort,
      page: pageIndex,
      perPage: 10,
    };
  };

  //Filters logic begin

  //code to handle expired doc filter
  const handleExpiredDocFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters((prevFilterState) => {
      if (e?.target?.checked) {
        return {
          ...prevFilterState,
          expiredDocs: e?.target?.checked,
          totalFiltersTypes: prevFilterState.totalFiltersTypes + 1,
          mergedFiltersArray: [
            ...prevFilterState.mergedFiltersArray,
            {
              eid: 'static-id-123',
              expiryDoc: true,
              customType: 'expiryDoc',
              name: 'Expired document only',
            },
          ],
        };
      } else {
        return {
          ...prevFilterState,
          expiredDocs: e?.target?.checked,
          totalFiltersTypes: prevFilterState.totalFiltersTypes - 1,
          mergedFiltersArray: prevFilterState.mergedFiltersArray?.filter(
            (item) => item?.eid !== 'static-id-123'
          ),
        };
      }
    });
  };

  //code to handle location filter
  const handleLocationSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    location: LocationsEntity | EntityLocations
  ) => {
    if (e?.target?.checked) {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedLocations: [...prevFilterState?.selectedLocations, location],
          selectedLocationsId: [
            ...prevFilterState?.selectedLocationsId,
            location?.eid,
          ],
          totalFiltersTypes: prevFilterState.totalFiltersTypes + 1,
          mergedFiltersArray: [
            ...prevFilterState.mergedFiltersArray,
            { ...location, customType: 'location' },
          ],
        };
      });
    } else {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedLocations: filters?.selectedLocations?.filter(
            (item) => item?.eid !== location?.eid
          ),
          selectedLocationsId: filters?.selectedLocationsId?.filter(
            (item) => item !== location?.eid
          ),
          totalFiltersTypes: prevFilterState.totalFiltersTypes - 1,
          mergedFiltersArray: prevFilterState.mergedFiltersArray?.filter(
            (item) => item?.eid !== location?.eid
          ),
        };
      });
    }
  };

  //code to handle uploaded by filter
  const handleUploadedBySelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    user: UserEntityData
  ) => {
    if (e?.target?.checked) {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedUploadedBy: [...prevFilterState?.selectedUploadedBy, user],
          selectedUploadedById: [
            ...prevFilterState?.selectedUploadedById,
            user?.eid,
          ],
          totalFiltersTypes: prevFilterState.totalFiltersTypes + 1,
          mergedFiltersArray: [
            ...prevFilterState.mergedFiltersArray,
            { ...user, customType: 'user' },
          ],
        };
      });
    } else {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedUploadedBy: filters?.selectedUploadedBy?.filter(
            (item) => item?.eid !== user?.eid
          ),
          selectedUploadedById: filters?.selectedUploadedById?.filter(
            (item) => item !== user?.eid
          ),
          totalFiltersTypes: prevFilterState.totalFiltersTypes - 1,
          mergedFiltersArray: prevFilterState.mergedFiltersArray?.filter(
            (item) => item?.eid !== user?.eid
          ),
        };
      });
    }
  };

  //code to clear filters
  const clearFilters = (type = 'all') => {
    if (type === 'all') {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          expiredDocs: false,
          totalFiltersTypes: 0,
          selectedLocations: [],
          selectedUploadedBy: [],
          selectedLocationsId: [],
          selectedUploadedById: [],
          mergedFiltersArray: [],
        };
      });
    }

    if (type === 'locations') {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedLocations: [],
          selectedLocationsId: [],
          totalFiltersTypes:
            prevFilterState.totalFiltersTypes -
            prevFilterState.selectedLocationsId?.length,
          mergedFiltersArray: prevFilterState.mergedFiltersArray?.filter(
            (item) => item?.customType !== 'location'
          ),
        };
      });
    }

    if (type === 'uploadedBy') {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          selectedUploadedBy: [],
          selectedUploadedById: [],
          totalFiltersTypes:
            prevFilterState.totalFiltersTypes -
            prevFilterState.selectedUploadedById?.length,
          mergedFiltersArray: prevFilterState.mergedFiltersArray?.filter(
            (item) => item?.customType !== 'user'
          ),
        };
      });
    }
  };

  //Filters logic end

  const handleExpirySort = (filterType: string) => {
    getComplianceListing({
      variables: getComplianceFilters(1, '', filterType),
    });
  };

  const columns: TableProps<TableDataType>['columns'] = [
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Location name
        </Text>
        // <Flex gap={2} alignItems={'center'}>
        //   <Text fontWeight={500} color={'#6F767E'}>
        //     Location name
        //   </Text>
        //   {selectedLocationFilters?.length > 0 && (
        //     <Text fontWeight={500} color={'#6F767E'}>
        //       ({selectedLocationFilters?.length})
        //     </Text>
        //   )}

        //   <LocationFilter
        //     selectedLocationFilters={selectedLocationFilters}
        //     setSelectedLocationFilters={setSelectedLocationFilters}
        //     locationList={locationFilterArray}
        //     getComplianceListing={getComplianceListing}
        //     getComplianceFilters={getComplianceFilters}
        //     setPreviousLocations={setPreviousLocations}
        //     previousLocations={previousLocations}
        //   />
        // </Flex>
      ),
      dataIndex: 'location',
      key: 'location',
      render: (_, values) => {
        return (
          <Flex>
            <Flex
              gap={2}
              borderRadius={'10px'}
              p={'5px 10px'}
              minW={'fit-content'}
              bgColor={values?.location?.isRoot ? '#EFEFEF' : '#B5E4CA66'}
            >
              {!values?.location?.isRoot && (
                <LocationIcon height={'24px'} width={'24px'} />
              )}
              <Tooltip
                label={
                  values?.location?.isRoot
                    ? 'HQ'
                    : values?.location?.name
                    ? values?.location?.name
                    : '--'
                }
              >
                <Text isTruncated width={'150px'}>
                  {values?.location?.isRoot
                    ? 'HQ'
                    : values?.location?.name
                    ? values?.location?.name
                    : '--'}
                </Text>
              </Tooltip>
              {!values?.location && <Box height={'24px'} width={'24px'}></Box>}
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Document name
        </Text>
      ),
      dataIndex: 'title',
      key: 'title',
      render: (_, values) => {
        return (
          <Flex>
            <Flex alignItems={'center'} gap={2}>
              <Flex
                alignItems={'center'}
                justifyContent={'center'}
                borderRadius={'50%'}
                p={'5px'}
                bgColor={values?.location ? '#B5E4CA66' : '#EFEFEF'}
              >
                <DocumentIcon />
              </Flex>
              <Tooltip label={values?.title}>
                <Text width={'200px'} isTruncated>
                  {values?.title}
                </Text>
              </Tooltip>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Uploaded by
        </Text>
      ),
      dataIndex: 'uploadedBy',
      key: 'uploadedBy',
      render: (_, values) => {
        return (
          <Flex gap={2}>
            <Image
              borderRadius={'5px'}
              fit={'cover'}
              w={'24px'}
              h={'24px'}
              src={getImage(values?.uploadedBy[2], values?.uploadedBy[0])}
            />
            <Flex>
              <Tooltip label={values?.uploadedBy[0]}>
                <Text maxWidth={'100px'} isTruncated fontWeight={600}>
                  {values?.uploadedBy[0]}, &nbsp;
                </Text>
              </Tooltip>
              <Text color={'#6F767E'}> {values?.uploadedBy[1]}</Text>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={500} color={'#6F767E'}>
            Expiry date
          </Text>
          <Popover placement='bottom'>
            <PopoverTrigger>
              <Box>
                <FontAwesomeIcon
                  color={selectedExpiryFilter ? '#2A85FF' : '#6F767E'}
                  cursor={'pointer'}
                  icon={faArrowDownShortWide as IconProp}
                />
              </Box>
            </PopoverTrigger>
            <PopoverContent borderRadius={'10px'} w={'220px'}>
              <PopoverArrow />
              <PopoverBody>
                <Flex gap={2} flexDir={'column'}>
                  <Text
                    onClick={() => {
                      setSelectedExpiryFilter('');
                      handleExpirySort('_ID_DESC');
                    }}
                    cursor={'pointer'}
                    borderRadius='12px'
                    padding='6px 12px'
                    _hover={{
                      bg: '#EFEFEF',
                      color: 'black',
                    }}
                    color={'#6F767E'}
                  >
                    No sort
                  </Text>
                  <Text
                    backgroundColor={
                      selectedExpiryFilter === 'older' ? '#EFEFEF' : 'white'
                    }
                    color={
                      selectedExpiryFilter === 'older' ? 'black' : '#6F767E'
                    }
                    onClick={() => {
                      setSelectedExpiryFilter('older');
                      handleExpirySort('EXPIRYDATE_ASC');
                    }}
                    cursor={'pointer'}
                    borderRadius='12px'
                    padding='6px 12px'
                    _hover={{
                      bg: '#EFEFEF',
                      color: 'black',
                    }}
                  >
                    Oldest expiry date first
                  </Text>
                  <Text
                    backgroundColor={
                      selectedExpiryFilter === 'newest' ? '#EFEFEF' : 'white'
                    }
                    color={
                      selectedExpiryFilter === 'newest' ? 'black' : '#6F767E'
                    }
                    onClick={() => {
                      setSelectedExpiryFilter('newest');
                      handleExpirySort('EXPIRYDATE_DESC');
                    }}
                    cursor={'pointer'}
                    borderRadius='12px'
                    padding='6px 12px'
                    _hover={{
                      bg: '#EFEFEF',
                      color: 'black',
                    }}
                  >
                    Newest expiry date first
                  </Text>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
      ),
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (_, values) => {
        return (
          <Flex flexDir={'column'}>
            <Text
              fontWeight={values?.isExpiring && 600}
              color={values?.isExpiring ? '#FF2047' : '#33383F'}
            >
              {values?.expiryDate && moment(values?.expiryDate)?.isValid()
                ? moment(values?.expiryDate).format('DD MMM YYYY')
                : '--'}
            </Text>
            <Text color={'#6F767E'} fontSize={'12px'}>
              {values?.expiryDate && moment(values?.expiryDate)?.isValid()
                ? moment(values?.expiryDate).format('hh:mm A')
                : '--'}
            </Text>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Actions
        </Text>
      ),
      key: 'action',
      render: (values) => {
        return (
          <Flex gap={2}>
            {userAuthRole === AuthRole?.SUPER_ADMIN ? (
              <>
                <VersionHistoryModal complianceId={values?.key} />
                <ComplianceUpdate complianceId={values.eid} />
                <DeleteDocumentModal
                  getComplianceFilters={getComplianceFilters}
                  getComplianceListing={getComplianceListing}
                  eid={values?.key}
                />
              </>
            ) : userAuthRole === AuthRole?.ADMIN &&
              loggedInUserId === values?.createdBy ? (
              <>
                <VersionHistoryModal complianceId={values?.key} />
                <ComplianceUpdate complianceId={values.eid} />
                <DeleteDocumentModal
                  getComplianceFilters={getComplianceFilters}
                  getComplianceListing={getComplianceListing}
                  eid={values?.key}
                />
              </>
            ) : (
              <>
                <VersionHistoryModal complianceId={values?.key} />
                <ComplianceUpdate disabled={true} complianceId={values.eid} />
                <DeleteDocumentModal
                  disabled={true}
                  getComplianceFilters={getComplianceFilters}
                  getComplianceListing={getComplianceListing}
                  eid={values?.key}
                />
              </>
            )}
          </Flex>
        );
      },
    },
  ];

  let locationOwnerColumns: TableProps<TableDataType>['columns'] = [
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Document name
        </Text>
      ),
      dataIndex: 'title',
      key: 'title',
      render: (_, values) => {
        return (
          <Flex>
            <Flex alignItems={'center'} gap={2}>
              <Flex
                alignItems={'center'}
                justifyContent={'center'}
                borderRadius={'50%'}
                p={'5px'}
                bgColor={values?.location ? '#B5E4CA66' : '#EFEFEF'}
              >
                <DocumentIcon />
              </Flex>
              <Text>{values?.title}</Text>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Location name
        </Text>
        // <Flex gap={2} alignItems={'center'}>
        //   <Text fontWeight={500} color={'#6F767E'}>
        //     Location name
        //   </Text>
        //   {selectedLocationFilters?.length > 0 && (
        //     <Text fontWeight={500} color={'#6F767E'}>
        //       ({selectedLocationFilters?.length})
        //     </Text>
        //   )}

        //   <LocationFilter
        //     selectedLocationFilters={selectedLocationFilters}
        //     setSelectedLocationFilters={setSelectedLocationFilters}
        //     locationList={locationFilterArray}
        //     getComplianceListing={getComplianceListing}
        //     getComplianceFilters={getComplianceFilters}
        //     setPreviousLocations={setPreviousLocations}
        //     previousLocations={previousLocations}
        //   />
        // </Flex>
      ),
      dataIndex: 'location',
      key: 'location',
      render: (_, values) => {
        return (
          <Flex>
            <Flex
              gap={2}
              borderRadius={'10px'}
              p={'5px 10px'}
              minW={'fit-content'}
              bgColor={values?.location?.isRoot ? '#EFEFEF' : '#B5E4CA66'}
            >
              {!values?.location?.isRoot && (
                <LocationIcon height={'24px'} width={'24px'} />
              )}
              <Text isTruncated width={'150px'}>
                {values?.location?.isRoot
                  ? 'HQ'
                  : values?.location?.name
                  ? values?.location?.name
                  : '--'}
              </Text>
              {!values?.location?.isRoot && (
                <Box height={'24px'} width={'24px'}></Box>
              )}
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Uploaded by
        </Text>
      ),
      dataIndex: 'uploadedBy',
      key: 'uploadedBy',
      render: (_, values) => {
        return (
          <Flex gap={2}>
            <Image
              borderRadius={'5px'}
              fit={'cover'}
              w={'24px'}
              h={'24px'}
              src={getImage(values?.uploadedBy[2], values?.uploadedBy[0])}
            />
            <Flex>
              <Text fontWeight={600}>{values?.uploadedBy[0]}, &nbsp;</Text>
              <Text color={'#6F767E'}> {values?.uploadedBy[1]}</Text>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={500} color={'#6F767E'}>
            Expiry date
          </Text>
          <Popover placement='bottom'>
            <PopoverTrigger>
              <Box>
                <FontAwesomeIcon
                  color={selectedExpiryFilter ? '#2A85FF' : '#6F767E'}
                  cursor={'pointer'}
                  icon={faArrowDownShortWide as IconProp}
                />
              </Box>
            </PopoverTrigger>
            <PopoverContent borderRadius={'10px'} w={'220px'}>
              <PopoverArrow />
              <PopoverBody>
                <Flex gap={2} flexDir={'column'}>
                  <Text
                    onClick={() => {
                      setSelectedExpiryFilter('');
                      handleExpirySort('_ID_DESC');
                    }}
                    cursor={'pointer'}
                    borderRadius='12px'
                    padding='6px 12px'
                    _hover={{
                      bg: '#EFEFEF',
                      color: 'black',
                    }}
                    color={'#6F767E'}
                  >
                    No sort
                  </Text>
                  <Text
                    backgroundColor={
                      selectedExpiryFilter === 'older' ? '#EFEFEF' : 'white'
                    }
                    color={
                      selectedExpiryFilter === 'older' ? 'black' : '#6F767E'
                    }
                    onClick={() => {
                      setSelectedExpiryFilter('older');
                      handleExpirySort('EXPIRYDATE_ASC');
                    }}
                    cursor={'pointer'}
                    borderRadius='12px'
                    padding='6px 12px'
                    _hover={{
                      bg: '#EFEFEF',
                      color: 'black',
                    }}
                  >
                    Oldest expiry date first
                  </Text>
                  <Text
                    backgroundColor={
                      selectedExpiryFilter === 'newest' ? '#EFEFEF' : 'white'
                    }
                    color={
                      selectedExpiryFilter === 'newest' ? 'black' : '#6F767E'
                    }
                    onClick={() => {
                      setSelectedExpiryFilter('newest');
                      handleExpirySort('EXPIRYDATE_DESC');
                    }}
                    cursor={'pointer'}
                    borderRadius='12px'
                    padding='6px 12px'
                    _hover={{
                      bg: '#EFEFEF',
                      color: 'black',
                    }}
                  >
                    Newest expiry date first
                  </Text>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
      ),
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (_, values) => {
        return (
          <Flex flexDir={'column'}>
            <Text
              fontWeight={values?.isExpiring && 600}
              color={values?.isExpiring ? '#FF2047' : '#33383F'}
            >
              {moment(values?.expiryDate)?.isValid()
                ? moment(values?.expiryDate).format('DD MMM YYYY')
                : '--'}
            </Text>
            <Text color={'#6F767E'} fontSize={'12px'}>
              {moment(values?.expiryDate)?.isValid()
                ? moment(values?.expiryDate).format('hh:mm A')
                : '--'}
            </Text>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Actions
        </Text>
      ),
      key: 'action',
      render: (values) => {
        return (
          <Flex gap={2}>
            <VersionHistoryModal complianceId={values?.key} />
          </Flex>
        );
      },
    },
  ];

  locationOwnerColumns =
    //@ts-ignore
    loggedInUserLocationIds?.length > 1
      ? locationOwnerColumns
      : locationOwnerColumns?.filter((item, i) => i !== 1);

  useEffect(() => {
    getComplianceCategories();
  }, []);

  useEffect(() => {
    uploadedByMemberList();
  }, [entityData]);

  //Debounce API call for compliance pagination based on filters
  useEffect(() => {
    const timer = setTimeout(() => {
      getComplianceListing({
        variables: getComplianceFilters(),
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, [filters]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getComplianceListing({
        variables: getComplianceFilters(),
      });
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchFieldText]);

  const filterRef = useLatest(getComplianceFilters);

  useEffect(() => {
    const catListener = eventBus.on('refetchCategory', () => {
      getComplianceCategories();
    });
    const listener = eventBus.on('refetchCompliance', () => {
      getComplianceListing({
        variables: filterRef.current(),
      });
    });
    return () => {
      listener();
      catListener();
    };
  }, []);

  return (
    <>
      {complianceCategory.length > 0 ? (
        <Flex gap={8} flexDir='column'>
          <Flex justifyContent='space-between' gap={4} alignItems='center'>
            <Searchbox
              searchFieldText={searchFieldText}
              setSearchFieldText={setSearchFieldText}
              placeholder='Search by document name'
            />

            {/* <FilterContainer
              appliedFilter={appliedFilter}
              setAppliedFilter={setAppliedFilter}
            /> */}

            {/* New Filter Container */}
            <FilterContainer
              filters={filters}
              setFilters={setFilters}
              handleExpiredDocFilter={handleExpiredDocFilter}
              handleLocationSelection={handleLocationSelection}
              handleUploadedBySelection={handleUploadedBySelection}
              clearFilters={clearFilters}
              complianceLoader={complianceLoader}
            />
          </Flex>

          {/* {(appliedFilter.filterby.includes('expired') ||
            appliedFilter.users.length > 0) && (
            <FilterChip
              appliedFilter={appliedFilter}
              setAppliedFilter={setAppliedFilter}
            />
          )} */}

          {/* New Filter Chip */}
          {filters?.totalFiltersTypes > 0 && (
            <FilterChip filters={filters} setFilters={setFilters} />
          )}

          {(userAuthRole === AuthRole?.SUPER_ADMIN ||
            userAuthRole === AuthRole?.ADMIN) && (
            <CategoryListing
              userAuthRole={userAuthRole}
              loggedInUserId={loggedInUserId}
              isRenameFieldVisible={isRenameFieldVisible}
              setIsRenameFieldVisible={setIsRenameFieldVisible}
              complianceCategory={complianceCategory}
              getComplianceCategories={getComplianceCategories}
              getComplianceFilters={getComplianceFilters}
              getComplianceListing={getComplianceListing}
              complianceLoader={complianceLoader}
              complianceCategoryLoader={complianceCategoryLoader}
            />
          )}
          <ComplianceTable
            complianceCategoryId={complianceCategoryId}
            complianceLoader={complianceLoader}
            tableData={tableData}
            searchFieldText={searchFieldText}
            filters={appliedFilter?.filterby?.length > 0 ? true : false}
            totalRowsCount={totalRowsCount}
            getComplianceListing={getComplianceListing}
            getComplianceFilters={getComplianceFilters}
            columns={
              userAuthRole === AuthRole?.LOCATION_OWNER
                ? locationOwnerColumns
                : columns
            }
          />
        </Flex>
      ) : (
        <>
          {complianceCategoryLoader ? (
            <Flex justifyContent={'center'} height={'80vh'}>
              <Center>
                <Loader />
              </Center>
            </Flex>
          ) : (
            <EmptyStateCompliance userAuthRole={userAuthRole} />
          )}
        </>
      )}
    </>
  );
};

ComplianceListContainer.displayName =
  'sub-components/nexus/Compliance/Listing/ComplianceListContainer';

export default ComplianceListContainer;
