import React, { FC } from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { TemplateContent } from './template.graphql'; // TODO
import TemplateTaskItem from './TemplateTaskItem';

interface IProps {
  content: TemplateContent; // TODO
}

const TemplatePhase: FC<IProps> = ({ content }) => {
  const { t } = useTranslation('launcher');
  const taskLength = content.tasks.length;
  return (
    <AccordionItem
      borderTopWidth='0'
      borderColor='#EEEEEE'
      borderBottomWidth='1px'
      _last={{
        borderBottomWidth: 0,
      }}
    >
      <AccordionButton borderRadius='12px' px={0} py={3} _hover={{}}>
        <Flex flex={1} flexDir='column' align='start'>
          <Box fontSize='15px' fontWeight='600' color='#272B30'>
            {content.category}{' '}
            <Box as='span' fontWeight='400'>
              ({t('noOfTask', { count: taskLength })})
            </Box>
          </Box>
        </Flex>
        {taskLength ? <AccordionIcon /> : null}
      </AccordionButton>
      <AccordionPanel py={0} px={0}>
        {content.tasks.map((task) => (
          <TemplateTaskItem key={task.eid} task={task} />
        ))}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default TemplatePhase;
