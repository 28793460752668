import { Box, Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import {
  ExtendedLauncherContent,
  PhaseData,
} from '../../../store/project-detail-phase.types';
import { PhaseInfo, RightSection, Selection } from './components';

interface IProps {
  index: number;
  eid: string;
}

const PhaseButtonUpper: FC<IProps> = ({ index, eid }) => {
  return (
    <Flex w='full' justify='space-between' gap={2}>
      <Flex align='baseline' gap='20px'>
        <Selection eid={eid} />
        <PhaseInfo eid={eid} index={index} />
      </Flex>
      <RightSection index={index} eid={eid} />
    </Flex>
  );
};

PhaseButtonUpper.displayName =
  'displayName:pages/LocationsNew/Components/ProjectDetailPhase/components/PhaseAccordionButton/phase-button-upper/PhaseButtonUpper';

export default PhaseButtonUpper;
