import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthRole, withAuthorization } from 'authorization';
import { LAUNCHER_SETUP, LAUNCHER_URL } from 'appRoutes';
import { useUserEntity } from 'hooks';
import DashboardContainer from 'sub-components/DashboardContainer';
import LauncherTaskSetup from './LauncherTaskSetup';

const Content: FC = () => {
  const entityId = useUserEntity((entity) => entity?.eid);
  // const haveLauncher = useUserEntity((entity) => !!entity?.launcher?.length);

  if (!entityId) {
    return null;
  }
  //
  // if (!haveLauncher) {
  //   return <Redirect to={LAUNCHER_SETUP} />;
  // }

  return <LauncherTaskSetup />;
};

const LauncherStepsConfig: FC = () => {
  return (
    <DashboardContainer>
      <Content />
    </DashboardContainer>
  );
};

// export default LauncherStepsConfig;

export default withAuthorization({
  permittedFor: ['user'],
  permittedRoles: [AuthRole.SUPER_ADMIN, AuthRole.ADMIN],
  redirectTo: LAUNCHER_URL,
})(LauncherStepsConfig);
