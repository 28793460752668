import { Box } from '@chakra-ui/react';
import { SearchInput } from 'atoms';
import Dropdown, { SelectOption } from 'atoms/Dropdown';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  searchQuery: string;
  selectedViewOption: {
    label: string;
    value: string;
  };
  trainingPathData: any;
  handleQueryChange: (e: any) => void;
  viewChangeHandler: (value: SelectOption<any>) => void;
}

const TrainingAssigneeSearch: FC<IProps> = (props) => {
  const {
    searchQuery,
    selectedViewOption,
    trainingPathData,
    handleQueryChange,
    viewChangeHandler,
  } = props;
  const { t } = useTranslation(['common', 'training']);
  return (
    <div className='training-search-container'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            width: '16.02px',
            height: '32.04px',
            background: '#cabdff',
            borderRadius: '4.00549px',
          }}
        ></div>
        <div
          style={{
            fontWeight: '600',
            lineHeight: '1.70',
            fontSize: '18px',
            marginLeft: '16px',
          }}
        >
          {/* @ts-ignore */}
          {t('training:training_details_section.assignees_count', {
            count:
              (trainingPathData?.assignedToUsers || [])?.filter(
                (user: any) => user?.status === 'active'
              )?.length || 0,
          })}
        </div>
      </div>
      {trainingPathData && trainingPathData?.assignedToUsers && (
        <Box display='flex' gap='4' width={'60%'} justifyContent='flex-end'>
          <Box width={'60%'}>
            <SearchInput
              disabled={true}
              size='md'
              hideShortcuts={true}
              placeholder={t(
                'training:training_details_section.search_members_locations_status_placeholder'
              )}
              onChange={handleQueryChange}
              value={searchQuery}
              className='training-search'
            />
          </Box>

          {trainingPathData?.visibility === 'public' && (
            <Box width={'40%'}>
              <Dropdown
                value={selectedViewOption}
                options={[
                  { label: t('common:all'), value: 'All' },
                  {
                    label: t('training:assignees_table_heading'),
                    value: 'Assignees',
                  },
                  { label: t('common:guests'), value: 'Guests' },
                ]}
                onChange={(value) => viewChangeHandler(value)}
              />
            </Box>
          )}
        </Box>
      )}
    </div>
  );
};

TrainingAssigneeSearch.displayName =
  'displayName:pages/Training/TrainingPath/TrainingAssigneeSearch';

export default TrainingAssigneeSearch;
