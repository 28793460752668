import React, { FC } from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Deadline from './Deadline';
import { TriggerInput } from '../../trigger';

interface IProps {}

const ActivationDeadlineSelect: FC<IProps> = () => {
  const { t } = useTranslation('launcher');

  return (
    <Controller<TriggerInput, 'trigger.activationTrigger.deadlineType'>
      name='trigger.activationTrigger.deadlineType'
      defaultValue='goLive'
      rules={{
        required: 'Please select a deadline option',
      }}
      render={({ field, fieldState }) => (
        <FormControl isInvalid={fieldState.invalid}>
          <RadioGroup {...field}>
            <Flex
              gap='32px'
              align='start'
              sx={{
                '.chakra-radio__label': {
                  color: '#2A85FF',
                  fontSize: 14,
                  fontWeight: 600,
                  whiteSpace: 'nowrap',
                },
              }}
            >
              <Radio size='lg' value='goLive'>
                {t('trigger.goLiveDate')}
              </Radio>
              <Flex flexDir='column' gap={3}>
                <Radio size='lg' value='duration'>
                  {t('trigger.basedOnTaskDuration')}
                </Radio>
                <Deadline value='duration' />
              </Flex>
            </Flex>
          </RadioGroup>
          <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

ActivationDeadlineSelect.displayName =
  'sc/L/lc/c/ctf/tts/ActivationDeadlineSelect';

export default ActivationDeadlineSelect;
