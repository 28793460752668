import React, { FC, useEffect, useMemo, useRef } from 'react';
import {
  Divider,
  Flex,
  useControllableState,
  usePopoverContext,
} from '@chakra-ui/react';
import { Button, SearchInput, SelectOption } from '../../../../../../../atoms';
import LocationItem from './LocationItem';
import { useSafeState } from '../../../../../../../hooks';
import { searchRegExp } from '../../../../../../../utils';

interface IProps {
  options?: SelectOption[];
  value?: SelectOption;
  onChange?: (value: SelectOption) => void;
}

const LocationLists: FC<IProps> = ({
  options = [],
  value: defaultValue,
  onChange,
}) => {
  const { onClose } = usePopoverContext();
  const [searchQuery, updateQuery] = useSafeState<string>('');

  const selectedRef = useRef<HTMLDivElement>(null);

  const [value, updateValue] = useControllableState<SelectOption>({
    defaultValue: defaultValue,
  });

  const onAddClick = () => {
    setTimeout(onClose);
    onChange?.(value);
  };

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      });
    }
  }, []);

  const filteredOptions = useMemo(() => {
    if (searchQuery) {
      const reg = searchRegExp(searchQuery, 'gi');

      return options?.filter((value) => {
        return value.label?.match(reg);
      });
    }
    return options;
  }, [options, searchQuery]);

  return (
    <Flex flexDir='column' gap={2}>
      <SearchInput
        placeholder='Search locations'
        hideShortcuts
        onChange={(event) => updateQuery(event.target.value)}
      />
      <Flex
        role='list'
        flexDir='column'
        gap='6px'
        maxH='300px'
        overflowY='auto'
      >
        {filteredOptions?.map((option, index) => (
          <React.Fragment key={option.value}>
            {index !== 0 && <Divider />}
            <LocationItem
              option={option}
              onClick={() => updateValue(option)}
              isSelected={value?.value === option?.value}
              ref={value?.value === option?.value ? selectedRef : undefined}
            />
          </React.Fragment>
        ))}
      </Flex>
      <Button size='lg' colorScheme='blue' onClick={onAddClick}>
        Select
      </Button>
    </Flex>
  );
};

LocationLists.displayName =
  'displayName:sub-components/nexus/Compliance/Create/components/common/location-select/LocationLists';

export default LocationLists;
