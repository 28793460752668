import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { toArray } from '../../../../../../utils';
import {
  LAUNCHER_TASK_CATEGORY,
  TaskCategoryResponse,
} from '../../launcher-config.graphql';

export const useConfigCategoryList = () => {
  const { loading, data } = useQuery<TaskCategoryResponse>(
    LAUNCHER_TASK_CATEGORY
  );

  const categories = useMemo(() => {
    return toArray(data?.EntityLauncherTaskCategories)
      .map((item) => ({
        eid: item.eid,
        label: item.name,
        value: item.eid,
      }))
      .reverse();
  }, [data?.EntityLauncherTaskCategories]);

  return { loading, categories };
};
