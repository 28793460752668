import React, { FC, useCallback, useEffect } from 'react';
import { Flex, IconButton } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { useSafeState } from '../../../../../hooks';
import { useLauncherConfigContext } from '../../common';

type Direction = 'left' | 'right';

const TRACK_SIZE = 360;

interface IProps {}

const BoardViewNavigation: FC<IProps> = () => {
  const groupBy = useLauncherConfigContext((state) => state.groupBy);
  const [status, setStatus] = useSafeState<Direction>();

  function handleResize(ref: HTMLElement) {
    const { scrollLeft, clientWidth, scrollWidth } = ref;
    if (Math.round(scrollLeft) === Math.round(scrollWidth - clientWidth)) {
      setStatus('right');
    } else if (scrollLeft === 0) {
      setStatus('left');
    } else {
      setStatus(undefined);
    }
  }

  const handleThumbPosition = useCallback((event) => {
    handleResize(event.currentTarget);
  }, []);

  useEffect(() => {
    const element = document.getElementById('board-view-sections');

    const observer = new ResizeObserver(() => {
      handleResize(element!);
    });

    element && observer.observe(element);

    element?.addEventListener('scroll', handleThumbPosition);
    return () => {
      element && observer.unobserve(element);
      element?.removeEventListener('scroll', handleThumbPosition);
    };
  }, [groupBy]);

  const getScrollAmount = (
    direction: Direction,
    element: HTMLElement
  ): number => {
    // console.log(lastRef.current, direction);
    // if (lastRef.current === direction) {
    //   return direction === 'right' ? TRACK_SIZE : -TRACK_SIZE;
    // }
    // console.log(element.scrollLeft + element.clientWidth, TRACK_SIZE);

    // if (direction === 'right') {
    //   return (
    //     TRACK_SIZE - ((element.scrollLeft + element.clientWidth) % TRACK_SIZE)
    //   );
    // }
    //
    // return (-element.scrollLeft % TRACK_SIZE) - 1;

    const newLeft = element.clientWidth - (element.clientWidth % TRACK_SIZE);

    return direction === 'right' ? newLeft : -newLeft;
  };

  const handleScrollButton = (direction: Direction) => {
    const element = document.getElementById('board-view-sections');

    if (element) {
      const scrollAmount = getScrollAmount(direction, element);
      element.scrollBy({ left: scrollAmount, behavior: 'smooth' });

      // const scrollAmount = direction === 'right' ? TRACK_SIZE : -TRACK_SIZE;
      // element.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      // lastRef.current = direction;
    }
  };

  return (
    <Flex gap={1}>
      <IconButton
        size='lg'
        isDisabled={status === 'left'}
        style={{
          backgroundColor: 'white',
        }}
        aria-label='prev'
        icon={
          <FontAwesomeIcon icon={faChevronLeft as IconProp} color='#6F767E' />
        }
        onClick={() => handleScrollButton('left')}
      />

      <IconButton
        size='lg'
        isDisabled={status === 'right'}
        style={{
          backgroundColor: 'white',
        }}
        aria-label='next'
        icon={
          <FontAwesomeIcon icon={faChevronRight as IconProp} color='#6F767E' />
        }
        onClick={() => handleScrollButton('right')}
      />
    </Flex>
  );
};

export default BoardViewNavigation;
