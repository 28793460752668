import React, { FC } from 'react';
import SidebarItem from 'sub-components/Sidebar/SidebarItem';
import { JOBS_PAGE, LOCATIONS_PAGE, MEMBERS_PAGE } from 'appRoutes';
import { Authorize, AuthRole } from 'authorization';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import SidebarSection from 'sub-components/Sidebar/SidebarSection';

import { ReactComponent as MembersIcon } from '../../../../assets/images/sidebar/member.svg';
import { ReactComponent as JobIcon } from '../../../../assets/images/sidebar/job.svg';
import { ReactComponent as LocationIcon } from '../../../../assets/images/sidebar/location.svg';

const TeamSpaceSection: FC = () => {
  const { t } = useTranslation('sidebar');
  const pathname = useLocation().pathname;
  return (
    <SidebarSection title={t('teamSpaceSection')}>
      <Authorize
        permittedRoles={[
          AuthRole.SUPER_ADMIN,
          AuthRole.ADMIN,
          AuthRole.LOCATION_OWNER,
        ]}
        permittedFor='user'
      >
        <SidebarItem
          icon={MembersIcon}
          title={t('members')}
          isSelected={pathname === MEMBERS_PAGE}
          link={MEMBERS_PAGE}
        />
      </Authorize>
      <Authorize
        permittedRoles={[
          AuthRole.SUPER_ADMIN,
          AuthRole.ADMIN,
          AuthRole.LOCATION_OWNER,
        ]}
        permittedFor='user'
      >
        <SidebarItem
          icon={JobIcon}
          title={t('jobs')}
          isSelected={pathname === JOBS_PAGE}
          link={JOBS_PAGE}
        />
      </Authorize>
      <Authorize
        permittedRoles={[
          AuthRole.SUPER_ADMIN,
          AuthRole.ADMIN,
          AuthRole.LOCATION_OWNER,
        ]}
        permittedFor='user'
      >
        <SidebarItem
          icon={LocationIcon}
          title={t('locations')}
          isSelected={pathname === LOCATIONS_PAGE}
          link={LOCATIONS_PAGE}
        />
      </Authorize>
    </SidebarSection>
  );
};

TeamSpaceSection.displayName =
  'sub-components/Sidebar/sections/teamspace/TeamSpaceSection';

export default TeamSpaceSection;
