import { Flex, Text } from '@chakra-ui/react';
import React, { FC, useCallback, useContext } from 'react';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import SupervisedTaskDetailsDataContext from '../../store/supervised-task-details-context';
import AssigneeBadgeStack from '../../../Components/sub-components/AssigneeBadgeStack';
import moment from 'moment-timezone';
import IconText from '../../../Components/sub-components/IconText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarCheck,
  faCalendarDay,
  faRepeat,
} from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import styled from '@emotion/styled';
import { taskRepeat } from 'utils/taskRepeat';
import { RepeatDetails } from 'sub-components/tasks/create-task/task.types';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import { AuthRole } from 'authorization';
import { getLOFilteredUsersList } from '../../utils/utils';
import { IAssigneeUsersEntity } from 'pages/TasksDashboard/tasks.types';
import { userTimezoneConvertedDateTime } from 'pages/TasksDashboard/MyTasks/component/MyTaskDetails/sub-components/Details/Details';

const DetailsTableWrapper = styled.div`
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 1em;
  }

  tr {
    height: 37px;
  }

  td {
    &:first-of-type {
      width: 30%;
    }

    &:last-of-type {
      width: 70%;
    }
  }
`;

const getTimezoneAbbreviation = (date: moment.Moment, timezone: string) => {
  let abbr = moment.tz(date, timezone).format('z');
  return abbr;
};

export const showTimeText = (dueDate: moment.Moment, timezone: string) => {
  let formattedString = '';
  let formattedDueDate = dueDate?.format('lll');
  let abbrevatedTimezone = timezone
    ? getTimezoneAbbreviation(dueDate, timezone)
    : '';
  if (formattedDueDate && abbrevatedTimezone) {
    formattedString = `${formattedDueDate} (${abbrevatedTimezone})`;
  } else {
    formattedString = formattedDueDate;
  }
  return formattedString;
};

const Details: FC = () => {
  const supervisedCtx = useContext(SupervisedTaskDetailsDataContext);
  const userData = useReactiveVar(userObj);
  let taskStartDate = userTimezoneConvertedDateTime(
    supervisedCtx?.taskDetails?.startDate,
    supervisedCtx?.taskDetails?.timezone
  );

  const getUserData = useCallback(() => {
    let assignedUsers: IAssigneeUsersEntity[] = [];
    if (userData?.authRole === AuthRole.LOCATION_OWNER) {
      let filteredUsers = getLOFilteredUsersList(
        userData?.locations || [],
        supervisedCtx?.taskDetails?.lastSession?.assigneeUsers.filter(
          (assignee) => ['active', 'pending']?.includes(assignee.status)
        ) ||
          supervisedCtx?.taskDetails?.assignedToUsers?.filter((assignee) =>
            ['active', 'pending']?.includes(assignee?.status)
          ) ||
          []
      );
      assignedUsers = filteredUsers;
    } else {
      assignedUsers =
        supervisedCtx?.taskDetails?.lastSession?.assigneeUsers.filter(
          (assignee) => ['active', 'pending']?.includes(assignee.status)
        ) ||
        supervisedCtx?.taskDetails?.assignedToUsers?.filter((assignee) =>
          ['active', 'pending']?.includes(assignee?.status)
        ) ||
        [];
    }

    /**
     * Return unique users (remove duplicate entries)
     */
    let uniqueUsers = Array?.from(
      new Map([...assignedUsers].map((item) => [item.eid, item])).values()
    );
    // return uniqueUsers;
    return assignedUsers;
  }, [supervisedCtx?.taskDetails]);

  const taskItems =
    supervisedCtx?.taskDetails?.lastSession?.taskItems ||
    supervisedCtx?.taskDetails?.taskItems ||
    [];

  return (
    <Flex bg='white' borderRadius='8px' p={6} flexDir='column' gap='10px'>
      <GenericColorHeader color='#CABDFF' title='Details' />
      <DetailsTableWrapper>
        <table>
          <tbody>
            <tr>
              <td>
                <Text as='b'>Due date:</Text>
              </td>
              <td>
                <IconText
                  icon={
                    <FontAwesomeIcon
                      icon={faCalendarCheck as IconProp}
                      color='#33383F'
                    />
                  }
                  // text={moment
                  //   ?.utc(supervisedCtx?.taskDetails?.lastSession?.dueDate)
                  //   ?.format('LLL')}
                  text={
                    supervisedCtx?.taskDetails?.lastSession?.dueDate
                      ? showTimeText(
                          moment?.utc(
                            supervisedCtx?.taskDetails?.lastSession?.dueDate
                          ),
                          supervisedCtx?.taskDetails?.timezone
                        )
                      : '-'
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <Text as='b'>Assignees:</Text>
              </td>
              <td>
                <AssigneeBadgeStack assignees={getUserData()} status='active' />
              </td>
            </tr>
            <tr>
              <td>
                <Text as='b'>Steps:</Text>
              </td>
              <td>
                <IconText
                  icon={
                    <FontAwesomeIcon
                      icon={faCircleCheck as IconProp}
                      color='#92929D'
                    />
                  }
                  text={`${taskItems?.length} ${
                    taskItems?.length === 1 ? 'Step' : 'Steps'
                  }`}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Text as='b'>Publish date: </Text>
              </td>
              <td>
                <IconText
                  icon={
                    <FontAwesomeIcon
                      icon={faCalendarDay as IconProp}
                      color='#33383F'
                    />
                  }
                  // text={moment(supervisedCtx?.taskDetails?.createdAt)?.format(
                  //   'LLL'
                  // )}
                  text={showTimeText(
                    taskStartDate,
                    supervisedCtx?.taskDetails?.timezone
                  )}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Text as='b'>Repeat: </Text>
              </td>
              <td>
                <IconText
                  icon={
                    <FontAwesomeIcon
                      icon={faRepeat as IconProp}
                      color='#33383F'
                    />
                  }
                  // text={supervisedCtx?.taskDetails?.repeatCycle}
                  text={taskRepeat(
                    supervisedCtx?.taskDetails
                      ?.repeatDetails as unknown as RepeatDetails<Date>
                  )}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </DetailsTableWrapper>
    </Flex>
  );
};

export default Details;

Details.displayName = 'TaskDashboard/TaskDetails/C/Details';
