import { useCallback } from 'react';
import { Any } from '../../../../types';
import { eventBus } from '../../../../shared/eventEmit';
import { ITaskInput } from './config-task-form/task-form.types';
import { findTriggerItem } from './config-store.helper';
import { mapConfigToObject } from './context.helper';

class CircularDependencyError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'CircularDependencyError';
  }
}

export const isDependencyError = (error: Error): boolean => {
  return error.name === 'CircularDependencyError';
};

/**
 * ### We need to focus on duration deadline to be attached as trigger.
 * #### Because next phase/ task will activate only after task/phase completion.
 *
 * 1. You can't add task/phase as depend on that have no any duration deadline (be cant expect a Go live date deadline)
 * 2. You can't add task/phase as depend on that have no any trigger
 *    i. if you added task as trigger and that task have no trigger, but phase have trigger ✅
 *    ii. if only some task have duration deadline in a phase, then you can't select that phase (because there is no any duration deadline available). But ypu can select the task that have valid deadline.
 * 3. In Trigger first node always be project start date
 * 4. Trigger node should not be in circular dependency.
 * 5. AS I change the deadline of task/phase, what should happen to next dependent task/phase? ❓
 *
 */

export const useTaskBeforeSave = () => {
  function circularCheck(
    data: Record<string, Any>,
    itemId: string,
    visited = new Set()
  ) {
    if (visited.has(itemId)) {
      throw new CircularDependencyError(
        `Circular dependency detected involving task ID: ${itemId}`
      );
    }
    visited.add(itemId);

    const nextObj = data[itemId];

    if (nextObj) {
      const [nextId] = findTriggerItem(nextObj.trigger);
      if (nextId) {
        circularCheck(data, nextId, visited);
      }
    }
  }

  return useCallback(async (task: ITaskInput) => {
    const config = await new Promise<Record<string, any>>((resolve) => {
      eventBus.emit('GET_CONFIG', (state: any) => {
        resolve(mapConfigToObject(state.config?.contents));
      });
    });

    const [itemId] = findTriggerItem(task?.trigger);

    if (itemId) {
      circularCheck(config, itemId, new Set([task.eid]));
    }
  }, []);
};
