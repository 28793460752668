import { gql } from '@apollo/client';

export interface LauncherResponse {
  UpdateLauncherTasks: {
    eid: string;
    title: string;
  };
  DeleteLauncher: {
    succeed: boolean;
  };
  CloneLauncher: {
    eid: string;
    title: string;
  };
}

export const RENAME_LAUNCHER_CONFIG = gql`
  mutation UpdateLauncherTasks($input: UpdateLauncherInputInput) {
    UpdateLauncherTasks(input: $input) {
      eid
      title
    }
  }
`;

export const DELETE_LAUNCHER_CONFIG = gql`
  mutation DeleteLauncher($eid: String!, $deleteLocation: Boolean) {
    DeleteLauncher(eid: $eid, deleteLocation: $deleteLocation) {
      succeed
    }
  }
`;

export const DUPLICATE_LAUNCHER_CONFIG = gql`
  mutation CloneLauncher($eid: String, $title: String) {
    CloneLauncher(eid: $eid, title: $title) {
      eid
      title
    }
  }
`;
