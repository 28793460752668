import React, { FC } from 'react';
import {
  AlertDialogCloseButton,
  Box,
  Flex,
  useModalContext,
} from '@chakra-ui/react';
import { ActionButton, BoxHeader } from '../../../../../../ui-components';
import { HeaderColors } from '../../../../../../configs';
import { BaseBoardProps } from './delete-board.types';

interface IProps extends BaseBoardProps {}

const ConfirmMoveBoard: FC<IProps> = ({ deleteProps, actionHandler }) => {
  const { onClose } = useModalContext();

  const _onSaveClick = async () => {
    if (deleteProps?.onDeleteClick) {
      await deleteProps.onDeleteClick?.(false);
      onClose();
    }
  };

  return (
    <Flex flexDir='column' gap={5}>
      <Flex paddingTop={3} justify='space-between'>
        <BoxHeader
          color={HeaderColors.Blue}
          title='Confirm moving locations to Pre-launch ?'
          fontSize='18px'
          boxWidth={3}
        />

        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
        />
      </Flex>

      <Box fontSize='16px' fontWeight={500} color='#6F767E'>
        All locations will lose their progress and all members of these
        locations will be deactivated. You can activate them again when you’re
        ready to launch these locations.
      </Box>

      <Flex gap={4}>
        <ActionButton
          flex={1}
          size='lg'
          variant='outline'
          actionFn={() => actionHandler('back', deleteProps)}
        >
          Back
        </ActionButton>
        <ActionButton
          flex={1}
          size='lg'
          colorScheme='blue'
          actionFn={_onSaveClick}
        >
          Move location/s to Pre-launch
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default ConfirmMoveBoard;
