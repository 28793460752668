import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { IFormInput } from '../../types';
import RightSectionCard from './RightSectionCard';

interface IProps {
  selectedSection: number;
  control: Control<IFormInput>;
  setValue: UseFormSetValue<IFormInput>;
}

const RightSectionLists: FC<IProps> = ({
  control,
  selectedSection,
  setValue,
}) => {
  const sections = useWatch<IFormInput, 'sectionData'>({
    control,
    name: 'sectionData',
  });

  return (
    <Flex flex={1} flexDir='column' gap='10px' overflow='auto'>
      {sections?.map((section, index) => (
        <RightSectionCard
          key={index}
          section={section}
          index={index}
          isSelected={selectedSection === index}
          onClick={() => setValue('selectedSection', index)}
        />
      ))}
    </Flex>
  );
};

export default React.memo(RightSectionLists);
