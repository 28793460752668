import { v4 as uuidv4 } from 'uuid';
import { toArray } from '../../../../utils';

import { LauncherConfig, LauncherTaskStep } from '../common';

import { TemplateContent } from './template.graphql'; // TODO
import { getAssignedType, getAssignedUser } from '../common/helpers';

export const stepsMapWithId = (
  steps?: LauncherTaskStep[]
): LauncherTaskStep[] => {
  return toArray(steps).map((value) => {
    return {
      stepId: uuidv4(),
      title: value.title,
      type: value.type,
      formId: value.formId || undefined,
      sopId: value.sopId || undefined,
      trainingId: value.trainingId || undefined,
    };
  });
};

export const transformTemplate = (
  contents: Array<TemplateContent> = [],
  title: string = '',
  categoryId?: string // Default category id
): LauncherConfig => {
  return {
    eid: undefined!,
    published: undefined!,
    title: title,
    contents: contents.map((content) => ({
      eid: content.eid,
      category: content.category,
      selected: true,
      // assignedType: getAssignedType(content as never),
      // assignedUser: getAssignedUser(content as never),
      tasks: content.tasks.map((task) => ({
        selected: true,
        category: task.category || categoryId,
        eid: task.eid,
        duration: task.duration,
        completed: !!(task.title?.trim() && task.dependency && task.duration),
        dependency: task.dependency,
        docRequired: task.docRequired,
        title: task.title,
        description: task.description,
        steps: stepsMapWithId(task.steps),

        // assignedType: getAssignedType(task as never),
        // assignedUser: getAssignedUser(task as never),
      })),
    })),
  };
};
