import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { get } from 'react-hook-form';

export const TaskItemWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  //align-items: start;
  gap: 8px;
  //padding-bottom: 16px;
  //padding-top: 8px;
  padding-block: 20px;
  padding-inline: 24px;
  background: #ffffff;

  cursor: pointer;
  border-style: solid;
  border-block-width: 1px;
  border-color: #dcdcdc;

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 0;
    width: 10px;
    background-color: transparent;
    transition: background-color 200ms linear;
  }

  &[aria-selected='true']:before {
    background-color: #8e59ff;
  }

  &:hover {
    //box-shadow: ${(props) => get(props.theme, 'shadows.lg')};
    //background: #efefef;
  }
`;
