import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { match } from 'ts-pattern';
import { useTranslation } from 'react-i18next';
import { useLatest } from '../../../../../../hooks';
import { LauncherTrigger } from '../../launcher-config.fragment';
import { useLauncherAlert } from '../../useLauncherAlert';
import {
  TaskTriggerInput,
  useTaskTriggerSetting,
} from '../task-trigger-setting';
import BaseSwitchTrigger, { BaseDetailView } from './BaseSwitchTrigger';

interface IProps {
  taskName: string;
}

const ActivationData: FC<IProps> = ({ taskName }) => {
  const { t } = useTranslation('launcher');
  const changeAlert = useLauncherAlert();
  const addTrigger = useTaskTriggerSetting();
  const { getValues, setValue } = useFormContext<TaskTriggerInput>();
  const value = useWatch<LauncherTrigger, 'trigger.triggerChoice'>({
    name: 'trigger.triggerChoice',
  });

  const activation = useWatch<LauncherTrigger, 'trigger.activationTrigger'>({
    name: 'trigger.activationTrigger',
  });

  const changeRef = useLatest(() => {
    addTrigger({
      taskName: taskName,
      defaultValue: {
        eid: getValues('eid'),
        trigger: {
          triggerChoice: 'activation',
        },
      },
      onSaveChanges: (values) => {
        setValue('trigger', values.trigger, {
          shouldDirty: true,
        });
      },
    });
  });

  if (value === 'activation') {
    let description = match(activation?.deadlineType)
      .with('goLive', () => `Deadline: Project Go-Live date`)
      .with(
        'duration',
        () =>
          `Deadline: ${activation?.deadlineValue} ${activation?.deadlineUnit} after activation`
      )
      .otherwise(() => '');

    return (
      <BaseDetailView
        type={value}
        trigger={activation!}
        description={description}
        onEditClick={() => {
          addTrigger({
            taskName: taskName,
            defaultValue: {
              eid: getValues('eid'),
              trigger: getValues('trigger'),
            },
            onSaveChanges: (values) => {
              setValue('trigger', values.trigger, {
                shouldDirty: true,
              });
            },
          });
        }}
      />
    );
  }

  return (
    <BaseSwitchTrigger
      title='Erase deadline triggers and add a dynamic activation trigger'
      desc='Note: All your deadline trigger settings will be reset to default if you choose to add a activation trigger'
      buttonText='Switch to activation trigger'
      onClick={() => {
        changeAlert({
          description: t('trigger.deadlineAlert'),
          buttonText: t('trigger.deadlineAlertBtn'),
          onConfirm: changeRef.current,
        });
      }}
    />
  );
};

export default ActivationData;
