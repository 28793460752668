import {
  IAssigneeUsersEntity,
  ILastSessionAssigneesEntity,
} from 'pages/TasksDashboard/tasks.types';
import { IEntityUser } from 'shared/global-types';
import { AuthRole, UserData } from 'sop-commons/src/client';
import { getImage } from '../../../../utils';

export const getUserData = (progress: any[], filteredUsersMap: any): any[] => {
  const userData: any[] = [];
  progress?.forEach((_progress) => {
    const filter = filteredUsersMap?.[_progress?.userId];
    if (filter) {
      userData?.push({
        name: filter?.name,
        role: filter?.role,
        location: filter?.locations?.[0]?.name,
        progressPercent: _progress?.completedPercent,
        profilePic: getImage(filter?.profilePic, filter?.name),
        timezone: filter?.timezone,
      });
    }
  });
  return userData;
};

export const getMappedUserData = (
  entityUsers: IEntityUser[] = [],
  assignees: ILastSessionAssigneesEntity[] = [],
  userData: Pick<UserData, 'authRole' | 'locations' | 'status'>
) => {
  const mappedArray: any[] = entityUsers
    .map((entityUser) => {
      const correspondingAssignee = assignees.find(
        (assignee) => assignee.userId === entityUser.eid
      );

      if (correspondingAssignee) {
        return {
          eid: entityUser?.eid,
          name: entityUser?.name,
          role: entityUser?.role,
          location:
            entityUser.locations.length > 1
              ? `${entityUser.locations[0]?.name} (+${
                  entityUser.locations.length - 1
                } more)`
              : entityUser.locations[0]?.name,
          locations: entityUser.locations,
          progressPercent: correspondingAssignee?.completedPercent,
          profilePic: getImage(entityUser?.profilePic, entityUser?.name),
          timezone: entityUser?.timezone,
        };
      }
    })
    .filter(Boolean) as any[];
  if (userData?.authRole === AuthRole.LOCATION_OWNER) {
    // let branches = entityUsers?.filter((entity) => entity?.type === 'branch');
    const filteredAssigneeUsers = mappedArray?.filter((user: any) => {
      return user?.locations?.some((location: any) => {
        return userData?.locations?.some((descendant) => {
          return descendant?.eid === location?.eid;
        });
      });
    });
    const filteredBranches = mappedArray?.filter((user: any) => {
      return userData?.locations?.some((descendant) => {
        return descendant?.eid === user?.eid;
      });
    });
    return [...filteredAssigneeUsers, ...filteredBranches];
  }
  return mappedArray;
};

export const getUserDataForScheduledTasks = (
  entityUsers: IEntityUser[] = [],
  assignees: IAssigneeUsersEntity[] = []
) => {
  // const mappedArray: any[] = entityUsers
  //   ?.map((entityUser) => {
  //     const correspondingAssignee = entityUsers.find(
  //       (assignee) => assignee.eid === entityUser.eid
  //     );
  //     if (correspondingAssignee) {
  //       return {
  //         name: correspondingAssignee?.name,
  //         role: correspondingAssignee?.role,
  //         location:
  //           correspondingAssignee.locations.length > 1
  //             ? `${correspondingAssignee.locations[0]?.name} (+${
  //                 correspondingAssignee.locations.length - 1
  //               } more)`
  //             : correspondingAssignee.locations[0]?.name,
  //         progressPercent: 0,
  //         profilePic: getImage(
  //           correspondingAssignee?.profilePic,
  //           correspondingAssignee?.name
  //         ),
  //         timezone: correspondingAssignee?.timezone,
  //       };
  //     }
  //   })
  //   .filter(Boolean) as any[];
  // return mappedArray;
  const mappedArray: any[] = assignees
    ?.map((assignee) => {
      const correspondingAssignee = entityUsers.find(
        (entity) => entity.eid === assignee.eid
      );
      if (correspondingAssignee) {
        return {
          name: correspondingAssignee?.name,
          role: correspondingAssignee?.role,
          location:
            correspondingAssignee.locations.length > 1
              ? `${correspondingAssignee.locations[0]?.name} (+${
                  correspondingAssignee.locations.length - 1
                } more)`
              : correspondingAssignee.locations[0]?.name,
          progressPercent: 0,
          profilePic: getImage(
            correspondingAssignee?.profilePic,
            correspondingAssignee?.name
          ),
          timezone: correspondingAssignee?.timezone,
        };
      }
    })
    .filter(Boolean) as any[];
  return mappedArray;
};
