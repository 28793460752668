import React, { FC, useMemo } from 'react';
import { Flex, Image, Tooltip } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useReactiveVar } from '@apollo/client';

import { IconImage, IconsPlaceholder } from '../../../../ui-components';
import { usersEntityObj } from '../../../../sub-components/Header';
import { LauncherAssignee } from '../common';
import TaskAssigneeList from './TaskAssigneeList';

interface AProps {
  userIds?: string[];
}

const AssigneeList: FC<AProps> = ({ userIds = [] }) => {
  const entityObjData = useReactiveVar(usersEntityObj);

  const users = useMemo(() => {
    const _userId = userIds.slice().splice(0, 2);
    return entityObjData?.filter((u) => _userId.includes(u.eid));
  }, [entityObjData, userIds]);

  return (
    <React.Fragment>
      {users?.map((user) => {
        return (
          <IconImage
            key={user.eid}
            name={user.name}
            thumbnail={user.profilePic}
            borderRadius='100%'
            boxSize={28}
          />
        );
      })}
    </React.Fragment>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: end;

  > img,
  > div {
    margin-left: -12px;
  }
`;

interface IProps extends LauncherAssignee {}

const TaskAssignee: FC<IProps> = ({ assignedType, assignedUser = [] }) => {
  if (assignedType === 'user') {
    const remaining = assignedUser?.length - 2;
    return (
      <Wrapper>
        <AssigneeList userIds={assignedUser} />

        {remaining > 0 ? (
          <TaskAssigneeList userIds={assignedUser}>
            <Flex
              justify='center'
              align='center'
              boxSize='28px'
              borderRadius='full'
              color='#696974'
              fontSize='12px'
              border='1px solid #E2E2EA'
              cursor='pointer'
              bg='#FFFFFF'
            >
              +{remaining}
            </Flex>
          </TaskAssigneeList>
        ) : null}
      </Wrapper>
    );
  }

  return (
    <Flex justify='end'>
      <Tooltip
        label='Assign to all location owners'
        hasArrow
        borderRadius='6px'
        padding='10px'
        placement='bottom-end'
        maxW='200px'
        bg='#000000CC'
        fontSize='12px'
      >
        <Image
          src={IconsPlaceholder['location']}
          borderRadius='full'
          boxSize='28px'
          cursor='pointer'
        />
      </Tooltip>
    </Flex>
  );
};

export default TaskAssignee;
