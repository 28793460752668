import React, { FC } from 'react';
import { AuthRole, withAuthorization } from 'authorization';
import { AppRoute } from '../../routes';
import { DashboardContainer } from '../../sub-components';
import { LauncherBoardContainer } from './boards';

interface IProps {}

const LauncherBoardPage: FC<IProps> = () => {
  return (
    <DashboardContainer>
      <LauncherBoardContainer />
    </DashboardContainer>
  );
};

export default withAuthorization({
  permittedFor: 'user',
  permittedRoles: [AuthRole.SUPER_ADMIN],
  redirectTo: AppRoute.LAUNCHER_URL,
})(LauncherBoardPage);
