import { ExtendedLauncherContent } from '../store/project-detail-phase.types';
import { PhaseDependency } from '../types';

/**
 * `dependentPhases`: Array of phases that depend on this phase
 *
 * `dependsOnPhases`: Array of phases that this phase depends on
 */

export const analyzePhaseDependencies = (
  contents: ExtendedLauncherContent[]
): PhaseDependency[] => {
  const dependencies: PhaseDependency[] = contents.map((phase) => ({
    phaseId: phase.eid,
    category: phase.category,
    dependentPhases: [],
    dependsOnPhases: [],
    dependsOnStartDate: false,
  }));

  contents.forEach((currentPhase) => {
    const currentDependency = dependencies.find(
      (d) => d.phaseId === currentPhase.eid
    )!;

    // Check if phase depends on project start date
    if (
      currentPhase.trigger?.triggerChoice === 'activation' &&
      currentPhase.trigger.activationTrigger?.dependentOn === 'startDate'
    ) {
      currentDependency.dependsOnStartDate = true;
    }

    // Check activation trigger dependencies
    if (
      currentPhase.trigger?.triggerChoice === 'activation' &&
      currentPhase.trigger.activationTrigger?.itemId
    ) {
      const dependsOnPhaseId = currentPhase.trigger.activationTrigger.itemId;
      const dependsOnPhase = contents.find((p) => p.eid === dependsOnPhaseId);

      if (dependsOnPhase) {
        // Add to current phase's dependsOn list
        currentDependency.dependsOnPhases.push({
          id: dependsOnPhaseId,
          category: dependsOnPhase.category,
        });

        // Add current phase to the dependent list of the phase it depends on
        const dependsOnDependency = dependencies.find(
          (d) => d.phaseId === dependsOnPhaseId
        );
        if (dependsOnDependency) {
          dependsOnDependency.dependentPhases.push({
            id: currentPhase.eid,
            category: currentPhase.category,
          });
        }
      }
    }

    // Check meta dependencies
    if (currentPhase.meta?.triggerItemId) {
      const metaDependsOnPhaseId = currentPhase.meta.triggerItemId;
      const metaDependsOnPhase = contents.find(
        (p) => p.eid === metaDependsOnPhaseId
      );

      if (metaDependsOnPhase) {
        // Add to current phase's dependsOn list if not already present
        const alreadyExists = currentDependency.dependsOnPhases.some(
          (p) => p.id === metaDependsOnPhaseId
        );

        if (!alreadyExists) {
          currentDependency.dependsOnPhases.push({
            id: metaDependsOnPhaseId,
            category: metaDependsOnPhase.category,
          });
        }

        // Add current phase to the dependent list of the phase it depends on
        const dependsOnDependency = dependencies.find(
          (d) => d.phaseId === metaDependsOnPhaseId
        );
        if (dependsOnDependency) {
          const alreadyInDependent = dependsOnDependency.dependentPhases.some(
            (p) => p.id === currentPhase.eid
          );

          if (!alreadyInDependent) {
            dependsOnDependency.dependentPhases.push({
              id: currentPhase.eid,
              category: currentPhase.category,
            });
          }
        }
      }
    }
  });

  return dependencies;
};
