import React, { FC } from 'react';
import { Box, Divider, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import TitleHeader from '../../../../../CardEditor/TitleHeader';
import BaseTrigger from '../../trigger/BaseTrigger';
import ActivationDeadlineSelect from './ActivationDeadlineSelect';

interface IProps {
  type: 'phase' | 'task';
}

const ActivationTrigger: FC<IProps> = ({ type }) => {
  const { t } = useTranslation('launcher');

  return (
    <Flex
      flexDir='column'
      bgColor='#FFFFFF'
      border='1px solid #F4F4F4'
      padding={4}
      rowGap={3}
      borderRadius='12px'
    >
      <Box fontSize='13px' fontWeight='600' color='#33383F'>
        {t('trigger.setDynActTrigger')}
      </Box>

      <BaseTrigger triggerType='activationTrigger' type={type} />

      <Divider borderColor='#EFEFEF' />

      <Box>
        <TitleHeader
          title={t('trigger.setDeadline')}
          desc={t('trigger.setDeadlineDesc', { context: type })}
          titleStyle={{ fontSize: '13px' }}
          descStyle={{ color: '#999999' }}
        />
      </Box>

      <ActivationDeadlineSelect />
    </Flex>
  );
};

ActivationTrigger.displayName = 'sc/L/lc/c/ctf/tts/ActivationTrigger';

export default ActivationTrigger;
