import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

interface BProps {
  title: string;
  onRemoveClick?: () => void;
}

export const FilterBaseItem: FC<BProps> = ({ title, children, ...rest }) => {
  return (
    <Flex
      bg='#CABDFF'
      border='1px solid #EFEFEF'
      borderRadius='12px'
      paddingX='16px'
      height='42px'
      color='#1A1D1F'
      fontWeight='600'
      userSelect='none'
      align='center'
      gap={2}
      whiteSpace='nowrap'
      {...rest}
    >
      {title}
      {children}
    </Flex>
  );
};

interface IProps extends BProps {
  onRemoveClick?: () => void;
}

const FilterViewItem: FC<IProps> = ({ title, onRemoveClick }) => {
  return (
    <FilterBaseItem title={title}>
      <CloseIcon cursor='pointer' boxSize='10px' onClick={onRemoveClick} />
    </FilterBaseItem>
  );
};

export default FilterViewItem;
