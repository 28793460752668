import React, { FC, useMemo } from 'react';
import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Radio,
  RadioGroup,
  useModalContext,
} from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';
import { useForm, Controller, ControllerRenderProps } from 'react-hook-form';
import { SelectOption } from '../../../../../atoms';
import { AuthRole } from '../../../../../authorization';
import { ActionButton } from '../../../../../ui-components';

import { MenuContainer } from '../../../../../sub-components/CustomDropdowns/CustomSelectComponent/components';
import { usersEntityObj } from '../../../../../sub-components/Header';

import { LauncherAssignee } from '../../common';

interface IFormInput extends LauncherAssignee {}

interface IProps {
  value?: IFormInput;
  onChange?: (value: IFormInput) => void | Promise<void>;
}

const AddAssigneeContent: FC<IProps> = ({ value, onChange }) => {
  const { onClose } = useModalContext();
  const entityObjData = useReactiveVar(usersEntityObj);
  const { control, watch, handleSubmit } = useForm<IFormInput>({
    defaultValues: value,
  });
  const assignedType = watch('assignedType');

  const options = useMemo(() => {
    if (entityObjData) {
      return entityObjData.reduce<SelectOption[]>((acc, user) => {
        if (
          user.authRole === AuthRole.SUPER_ADMIN &&
          ['active', 'inactive'].includes(user.status) &&
          user.type === 'user'
        ) {
          acc.push({
            label: user.name,
            value: user.eid,
            authRole: user.authRole,
            profilePic: user?.profilePic,
            locations: user?.locations,
            role: user?.role,
          });
        }

        return acc;
      }, []);
    }
    return [];
  }, [entityObjData]);

  async function _onAddClick(values: IFormInput) {
    if (values.assignedType === 'location') {
      await onChange?.({
        assignedType: values.assignedType,
        assignedUser: [],
      });
    } else {
      await onChange?.({
        assignedType: values.assignedType,
        assignedUser: values.assignedUser,
      });
    }
    setTimeout(onClose, 1);
  }

  function handleOptionClick(
    this: ControllerRenderProps<IFormInput, 'assignedUser'>,
    eid: string
  ) {
    let newSelectedValues = [];

    if (this.value?.includes(eid)) {
      newSelectedValues = this.value?.filter((val) => val !== eid);
    } else {
      newSelectedValues = [...(this.value || []), eid];
    }
    this.onChange(newSelectedValues);
  }

  return (
    <Flex flexDir='column' gap={4}>
      <Controller
        control={control}
        name='assignedType'
        render={({ field }) => {
          return (
            <RadioGroup
              {...field}
              display='flex'
              flexDir='column'
              gap={3}
              px={2}
              sx={{
                '.chakra-radio__label': {
                  color: '#33383F',
                  fontSize: 14,
                  fontWeight: 400,
                  _checked: {
                    color: '#2A85FF',
                    fontWeight: 500,
                  },
                },
                '.chakra-radio__control': {
                  _checked: {
                    borderColor: 'blue.500',
                    background: 'white',
                    color: 'blue.500',
                    _hover: {
                      background: 'white',
                    },
                  },
                },
              }}
            >
              <Flex
                justify='space-between'
                align='center'
                fontSize='15px'
                fontWeight='500'
              >
                <span>Location Owners of launching location</span>
                <Radio size='lg' value='location' />
              </Flex>

              <Divider />

              <Flex flexDir='column'>
                <Flex
                  justify='space-between'
                  align='center'
                  fontSize='15px'
                  fontWeight='500'
                >
                  <span>Superadmins</span>
                  <Radio size='lg' value='user' />
                </Flex>

                <Box color='#6F767E' fontSize='13px'>
                  Tasks assigned to internal people will not be visible to
                  location owners.
                </Box>
              </Flex>
            </RadioGroup>
          );
        }}
      />

      <Flex flexDir='column'>
        <Controller
          control={control}
          name='assignedUser'
          rules={{
            required: {
              value: assignedType === 'user',
              message: 'Superadmin assignee is required',
            },
          }}
          render={({ field, fieldState }) => {
            if (assignedType !== 'user') {
              return <React.Fragment />;
            }

            return (
              <FormControl isInvalid={fieldState.invalid}>
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
                <MenuContainer
                  showSearchField
                  showDivider
                  showSelectAll
                  searchFieldPlaceholder='Search by name or role'
                  options={options}
                  handleOptionClick={handleOptionClick.bind(field)}
                  selectedValues={field.value || []}
                  setSelectedValues={field.onChange}
                />
              </FormControl>
            );
          }}
        />

        <Flex paddingX={1}>
          <ActionButton
            width='full'
            colorScheme='blue'
            actionFn={handleSubmit(_onAddClick)}
          >
            Add assignee
          </ActionButton>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AddAssigneeContent;
