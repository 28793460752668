import { gql, useApolloClient } from '@apollo/client';

const UNIQUE_ID_QUERY = gql`
  query GetNewEid {
    GetNewEid
  }
`;

export const useIdGenerator = () => {
  const client = useApolloClient();

  return async () => {
    const response = await client.query({
      query: UNIQUE_ID_QUERY,
      fetchPolicy: 'network-only',
    });

    if (response.errors) {
      throw response.errors;
    }
    return response.data.GetNewEid as string;
  };
};
