import moment from 'moment-timezone';
import {
  EntityComplianceCategoriesType,
  EntityComplianceType,
  FileType,
  isComplianceDocumentExpiringType,
  ReminderType,
  TableDataType,
} from '../types/compliance.types';

//type definitions
type generateComplianceTableDataType = (
  arr: EntityComplianceType[]
) => TableDataType[];

type reverseArrType = (arr: any[]) => any[];

type getDefaultCategoryNameType = (
  categoryList: EntityComplianceCategoriesType[]
) => string;

type compareArrayType = (arr1: string[], arr2: string[]) => boolean;
//helper functions
export const reverseArr: reverseArrType = (arr) => {
  var reversedArray = new Array();
  for (var i = arr.length - 1; i >= 0; i--) {
    reversedArray.push(arr[i]);
  }
  return reversedArray;
};

export const generateComplianceTableData: generateComplianceTableDataType = (
  arr
) => {
  const complianceTableData = arr?.map((item) => {
    let tempObj: TableDataType = Object?.assign({
      eid: item?.eid,
      key: item?.eid,
      uploadedBy: [
        item?.createdByUser?.name,
        item?.createdByUser?.role,
        item?.createdByUser?.profilePic,
      ],
      docType: item?.category?.category,
      title: item?.title,
      location: item?.location?.name,
      expiryDate: item?.expiryDate,
      fileVersions: item?.files,
      createdAt: item?.createdAt,
      createdBy: item?.createdBy,
      isExpiring: isComplianceDocumentExpiring(
        item?.expiryDate,
        item?.reminder,
        'expired'
      ),
    });

    return tempObj;
  });

  return complianceTableData;
};

export const getDefaultCategoryName: getDefaultCategoryNameType = (
  categoryList
) => {
  const countArray: number[] = [];
  let missingNumber = 0;
  let defaultName = '';
  let catCount = 0;

  categoryList?.forEach((item) => {
    if (item?.category?.includes('New Category')) {
      catCount += 1;
      let itemCategoryArr = item?.category?.split(' ');
      let count = itemCategoryArr[itemCategoryArr?.length - 1];
      if (count !== 'Category') {
        countArray?.push(Number(count));
      }
    }
  });

  countArray?.sort();
  for (let i = 1; i <= 100; i++) {
    if (!countArray?.includes(i)) {
      missingNumber = i;
      break;
    }
  }

  if (catCount === 0) {
    defaultName = 'New Category';
  } else if (catCount > 0) {
    if (missingNumber !== 0) {
      defaultName = `New Category ${missingNumber}`;
    }
  }
  return defaultName;
};

export const isComplianceDocumentExpiring: isComplianceDocumentExpiringType = (
  expiryDate,
  reminderObj,
  expireType
) => {
  let response;
  let isExpiring: boolean = false;
  let isExpired: boolean = false;
  let remind;

  if (!expiryDate || !moment(expiryDate)?.isValid()) {
    return undefined;
  }

  const currentDate = moment();
  const expiry = moment(expiryDate);
  if (reminderObj?.remindDate) {
    remind = moment(reminderObj?.remindDate);
  } else if (reminderObj?.remindBefore && reminderObj?.remindType) {
    remind = expiry?.subtract(
      reminderObj.remindBefore,
      `${reminderObj.remindType}`
    );
  } else {
    remind = expiry?.subtract(7, 'days');
  }
  if (
    currentDate.isSameOrAfter(remind) &&
    currentDate.isSameOrBefore(moment(expiryDate))
  ) {
    isExpiring = true;
  } else if (currentDate.isAfter(moment(expiryDate))) {
    isExpired = true;
  }
  if (expireType === 'expired') {
    response = isExpired;
  } else {
    response = isExpiring;
  }
  return response;
};

export const compareArray: compareArrayType = (arr1, arr2) => {
  // Check if lengths are different
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  // Compare each element
  for (let i = 0; i < arr1?.length; i++) {
    if (arr1?.[i] !== arr2?.[i]) {
      return false;
    }
  }

  return true; // Arrays are equal
};
