import React, { FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { Flex, Text, Box, Button } from '@chakra-ui/react';
import { FiltersType } from '../../ComplianceListContainer';
import { ReactComponent as GroupiconIcon } from 'assets/images/groupIcon.svg';
import { ReactComponent as LocationIcon } from 'assets/images/sidebar/location.svg';
import { ReactComponent as FilepreviewIcon } from 'assets/images/filePreview.svg';
import { ReactComponent as CloseicondarkIcon } from 'assets/images/closeIconDark.svg';
import { ReactComponent as ChevronbackwardIcon } from 'assets/images/chevronBackward.svg';
import { ReactComponent as ChevronforwardIcon } from 'assets/images/chevronForward.svg';
import { reverseArray } from '../../helper';

interface IProps {
  filters: FiltersType;
  setFilters: React.Dispatch<SetStateAction<FiltersType>>;
}

const FilterChip: FC<IProps> = ({ filters, setFilters }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState(false); // Track overflow state
  const [isAtStart, setIsAtStart] = useState(true); // Check if scrolled to start
  const [isAtEnd, setIsAtEnd] = useState(false); // Check if scrolled to the end

  const renderIcons = (type = '') => {
    if (type === 'expiryDoc') {
      return <FilepreviewIcon />;
    } else if (type === 'user') {
      return <GroupiconIcon />;
    } else if (type === 'location') {
      return <LocationIcon />;
    } else {
      return <FilepreviewIcon />;
    }
  };

  // Function to update the scroll state
  const updateScrollState = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;

      // Check if the content overflows (scrollWidth > clientWidth)
      setIsScrollable(scrollWidth > clientWidth);
      setIsAtStart(scrollLeft === 0); // At the start
      setIsAtEnd(scrollLeft + clientWidth >= scrollWidth); // At the end
    }
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  // Update scroll state on mount and handle resize events
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      updateScrollState(); // Check overflow immediately on mount
      scrollContainer.addEventListener('scroll', updateScrollState);
      window.addEventListener('resize', updateScrollState); // Recheck on window resize

      return () => {
        scrollContainer.removeEventListener('scroll', updateScrollState);
        window.removeEventListener('resize', updateScrollState);
      };
    }
  }, [filters]);

  const handleClearCheckboxFromFilterChip = (action = '', payload: any) => {
    if (action === 'expiryDoc') {
      setFilters((prevFilterState) => {
        return {
          ...prevFilterState,
          expiredDocs: false,
          totalFiltersTypes: prevFilterState.totalFiltersTypes - 1,
          mergedFiltersArray: prevFilterState.mergedFiltersArray?.filter(
            (item) => item?.eid !== 'static-id-123'
          ),
        };
      });
    }

    if (action === 'user') {
      setFilters((prevFilterState) => ({
        ...prevFilterState,
        selectedUploadedBy: filters?.selectedUploadedBy?.filter(
          (item) => item?.eid !== payload?.eid
        ),
        selectedUploadedById: filters?.selectedUploadedById?.filter(
          (item) => item !== payload?.eid
        ),
        totalFiltersTypes: prevFilterState.totalFiltersTypes - 1,
        mergedFiltersArray: prevFilterState.mergedFiltersArray?.filter(
          (item) => item?.eid !== payload?.eid
        ),
      }));
    }

    if (action === 'location') {
      setFilters((prevFilterState) => ({
        ...prevFilterState,
        selectedLocations: filters?.selectedLocations?.filter(
          (item) => item?.eid !== payload?.eid
        ),
        selectedLocationsId: filters?.selectedLocationsId?.filter(
          (item) => item !== payload?.eid
        ),
        totalFiltersTypes: prevFilterState.totalFiltersTypes - 1,
        mergedFiltersArray: prevFilterState.mergedFiltersArray?.filter(
          (item) => item?.eid !== payload?.eid
        ),
      }));
    }
  };

  return (
    <Flex gap={2} alignItems='center'>
      <Text
        whiteSpace='nowrap'
        color='#6F767E'
        fontWeight={500}
        fontSize='12px'
      >
        Filters applied:{' '}
      </Text>

      {/* Show the left scroll button only if there's overflow */}
      {isScrollable && (
        <Button
          disabled={isAtStart}
          _hover={{ backgroundColor: 'transparent' }}
          backgroundColor={'transparent'}
          cursor='pointer'
          onClick={scrollLeft}
        >
          <ChevronbackwardIcon />
        </Button>
      )}

      <Flex
        position='relative'
        ref={scrollContainerRef}
        whiteSpace='nowrap'
        width='100%'
        overflowX='auto'
        gap={2}
      >
        {reverseArray(filters?.mergedFiltersArray)?.map((item) => {
          return (
            <Flex
              key={item?.eid}
              borderRadius='8px'
              p='6px 8px'
              gap={2}
              alignItems='center'
              backgroundColor='#E8DEF8'
            >
              {renderIcons(item?.customType)}
              <Text fontWeight={500}>{item?.name}</Text>
              <CloseicondarkIcon
                cursor='pointer'
                onClick={() =>
                  handleClearCheckboxFromFilterChip(item?.customType, item)
                }
                height='18px'
                width='18px'
              />
            </Flex>
          );
        })}
      </Flex>

      {/* Show the right scroll button only if there's overflow */}
      {isScrollable && (
        <Button
          disabled={isAtEnd}
          _hover={{ backgroundColor: 'transparent' }}
          backgroundColor={'transparent'}
          cursor='pointer'
          onClick={scrollRight}
        >
          <ChevronforwardIcon />
        </Button>
      )}
    </Flex>
  );
};

export default FilterChip;
