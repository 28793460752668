import { ApolloClient } from '@apollo/client';
import moment from 'moment-timezone';
import { getTaskById } from 'pages/TasksDashboard/MyTasks/services/MyTasks.service';
import {
  ISupervisedTasksEntity,
  ITaskOverviewEntity,
} from 'pages/TasksDashboard/tasks.types';
import { IEntityUser } from 'shared/global-types';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { UserData } from 'sop-commons/src/client';
import { RepeatDetails } from 'sub-components/tasks/create-task/task.types';
import { getTaskStatus } from './getTaskStatus';
import { getTaskStatusUpdated } from './getTaskStatusUpdated';
import {
  getMappedUserData,
  getUserData,
  getUserDataForScheduledTasks,
} from './getUserData';
import { IItem } from './propcess-supervised-data.types';

export const timeLeftHandler = (hours: number, minutes: number) => {
  // Convert the total time to minutes
  const totalMinutes = hours * 60 + minutes;

  // Calculate years, months, days, hours, and minutes
  const years = Math.floor(totalMinutes / (60 * 24 * 365));
  const months = Math.floor((totalMinutes % (60 * 24 * 365)) / (60 * 24 * 30));
  const days = Math.floor((totalMinutes % (60 * 24 * 30)) / (60 * 24));
  const remainingHours = Math.floor((totalMinutes % (60 * 24)) / 60);
  const remainingMinutes = totalMinutes % 60;

  // Create an array of time units
  const timeUnits = [
    { value: years, unit: 'y' },
    { value: months, unit: 'mo' },
    { value: days, unit: 'd' },
    { value: remainingHours, unit: 'h' },
    { value: remainingMinutes, unit: 'm' },
  ];

  // Filter out zero values and take the first two non-zero units
  const nonZeroUnits = timeUnits.filter((unit) => unit.value > 0).slice(0, 2);

  // Format the output string
  let timeLeft = nonZeroUnits.map((unit) => unit.value + unit.unit).join(' ');

  return timeLeft || '';
};

// const timeLeftHandler = (hours: number, minutes: number) => {
//   let timeLeft = '';
//   if (hours && hours > 0 && minutes && minutes > 0) {
//     timeLeft += hours + 'h ' + minutes + 'm';
//   } else if (hours && hours > 0) {
//     timeLeft += hours + 'h';
//   } else if (minutes && minutes > 0) {
//     timeLeft += minutes + 'm';
//   }
//   return timeLeft || '';
// };

export const processSupervisedData = (
  taskData: ISupervisedTasksEntity[] | undefined,
  timezone: string,
  entityUserData: IEntityUser[],
  userData: Pick<UserData, 'authRole' | 'locations'>
) => {
  const overdue: IItem[] = [];
  const dueToday: IItem[] = [];
  const thisWeek: IItem[] = [];
  const scheduled: IItem[] = [];
  const totalTasks: IItem[] = [];
  const partiallyDone: IItem[] = [];
  const completed: IItem[] = [];
  const upcoming: IItem[] = [];
  const terminated: IItem[] = [];

  let userTimezone = timezone;
  taskData?.map((item: ISupervisedTasksEntity) => {
    let lastSession = item?.lastActiveSession;
    let taskDueDate = moment.utc(lastSession?.dueDate);
    const now = moment.utc(
      moment().tz(userTimezone).format('DD-MMM-YYYY hh:mm a'),
      'DD-MMM-YYYY hh:mm a'
    );
    let diffInMilliseconds = taskDueDate?.diff(now);
    let duration = moment?.duration(diffInMilliseconds);
    let hours = Math?.floor(duration?.asHours());
    let minutes = Math?.floor(duration?.asMinutes()) - hours * 60;
    let durationString = timeLeftHandler(hours, minutes);
    // let _userData: any[] = getUserData(
    //   lastSession?.progress,
    //   filteredUsersMap
    // );
    let _userData: any[] = [];
    if (lastSession) {
      _userData = getMappedUserData(
        entityUserData,
        lastSession?.assignees,
        userData
      );
    } else {
      _userData = getUserDataForScheduledTasks(
        entityUserData,
        item?.assignedToUsers
      );
    }
    let updatedStatus = getTaskStatusUpdated(item, userTimezone, _userData);
    let status = '';
    if (updatedStatus === 'pending' || updatedStatus === 'partiallyDone') {
      status = getTaskStatus(item, userTimezone, _userData);
    }
    let _item: IItem = {
      eid: item?.eid,
      sessionId: lastSession?.eid,
      // title: lastSession?.title || item?.title,
      title: item?.title,
      date: taskDueDate?.format('MMM DD'),
      dateMoment: taskDueDate,
      timeDuration: null,
      duration: durationString,
      createdBy: item?.createdBy,
      repeatString: item?.repeatCycle,
      userProgressData: _userData,
      repeatDetails: item?.repeatDetails as unknown as RepeatDetails<Date>,
      taskDetails: item,
    };
    if (updatedStatus === 'completed') {
      completed.push({ ..._item, status: updatedStatus });
    } else if (updatedStatus === 'overdue') {
      overdue.push({ ..._item, status: updatedStatus });
    } else if (updatedStatus === 'scheduled') {
      scheduled.push({
        ..._item,
        status: updatedStatus,
        dateMoment: moment.utc(
          moment(item?.startDate)
            .tz(userTimezone)
            .format('DD-MMM-YYYY hh:mm a'),
          'DD-MMM-YYYY hh:mm a'
        ),
        date: moment
          .utc(
            moment(item?.startDate)
              .tz(userTimezone)
              .format('DD-MMM-YYYY hh:mm a'),
            'DD-MMM-YYYY hh:mm a'
          )
          ?.format('MMM DD'),
      });
    } else if (status === 'dueToday') {
      dueToday.push({ ..._item, status });
    } else if (status === 'thisWeek') {
      thisWeek.push({ ..._item, status });
    } else if (status === 'upcoming') {
      upcoming.push({ ..._item, status });
    } else if (updatedStatus === 'terminated') {
      terminated.push({ ..._item, status: updatedStatus });
    }
    // switch (status) {
    //   case 'completed':
    //     completed.push({ ..._item, status });
    //     break;
    //   case 'overdue':
    //     overdue.push({ ..._item, status });
    //     break;
    //   case 'dueToday':
    //     dueToday.push({ ..._item, status });
    //     break;
    //   case 'thisWeek':
    //     thisWeek.push({ ..._item, status });
    //     break;
    //   case 'partiallyCompleted':
    //     partiallyDone.push({ ..._item, status });
    //     break;
    //   case 'upcoming':
    //     upcoming.push({ ..._item, status });
    //     break;
    //   case 'scheduled':
    //     scheduled.push({ ..._item, status });
    //     break;
    //   default:
    //     console.log('NOT IN ANY SLOT : ', {
    //       ..._item,
    //       status: 'NO STATUS FOUND',
    //     });
    // }

    totalTasks.push({ ..._item, status: 'totalTasks' });
  });

  let taskDetailsObj = [
    { type: 'overdue' as ITaskOverviewEntity, data: overdue },
    { type: 'dueToday' as ITaskOverviewEntity, data: dueToday },
    { type: 'thisWeek' as ITaskOverviewEntity, data: thisWeek },
    { type: 'scheduled' as ITaskOverviewEntity, data: scheduled },
    { type: 'totalTasks' as ITaskOverviewEntity, data: totalTasks },
    { type: 'partiallyDone' as ITaskOverviewEntity, data: partiallyDone },
    { type: 'upcoming' as ITaskOverviewEntity, data: upcoming },
    { type: 'completed' as ITaskOverviewEntity, data: completed },
    { type: 'terminated' as ITaskOverviewEntity, data: terminated },
  ];
  let taskOverviewObj = [
    {
      id: 'currentRunning' as ITaskOverviewEntity,
      name: 'Current Running',
      count: 0,
      selected: false,
      color: HeaderColors.Yellow,
      show: false,
    },
    {
      id: 'overdue' as ITaskOverviewEntity,
      name: 'Overdue tasks',
      count: overdue?.length || 0,
      selected: false,
      color: HeaderColors.Red.Light,
      show: true,
    },
    {
      id: 'dueToday' as ITaskOverviewEntity,
      name: 'Due today',
      count: dueToday?.length || 0,
      selected: false,
      color: HeaderColors.Purple,
      show: true,
    },
    {
      id: 'thisWeek' as ITaskOverviewEntity,
      name: "This week's tasks",
      count: thisWeek?.length || 0,
      selected: false,
      color: '#B1E5FC',
      show: true,
    },
    {
      id: 'scheduled' as ITaskOverviewEntity,
      name: 'Scheduled tasks',
      count: scheduled?.length || 0,
      selected: false,
      color: '#B1E5FC',
      show: false,
    },
    {
      id: 'upcoming' as ITaskOverviewEntity,
      name: 'Upcoming tasks',
      count: upcoming?.length || 0,
      selected: false,
      color: '#B1E5FC',
      show: false,
    },
    {
      id: 'totalTasks' as ITaskOverviewEntity,
      name: 'Total tasks',
      count: totalTasks?.length || 0,
      selected: false,
      color: HeaderColors.Yellow,
      show: true,
    },
    {
      id: 'completed' as ITaskOverviewEntity,
      name: 'Completed',
      count: completed?.length || 0,
      selected: false,
      color: HeaderColors.Green,
      show: false,
    },
    {
      id: 'terminated' as ITaskOverviewEntity,
      name: 'Terminated',
      count: terminated?.length || 0,
      selected: false,
      color: '#FFBC99',
      show: false,
    },
  ];
  return { taskDetails: taskDetailsObj, taskOverview: taskOverviewObj };
};
