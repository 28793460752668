import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  chakra,
  Checkbox,
  Flex,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  useDisclosure,
} from '@chakra-ui/react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import PrimaryButton from 'atoms/PrimaryButton';
import { userObj } from 'sop-commons/src/client/clientFactory';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { ReactComponent as ManagerIcon } from '../../../../assets/images/managerBlue.svg';

import { ContributorResponse, getContributors } from './contributor.graphql';
import { IFormInput } from '../form.types';
import { toArray } from 'utils/utils';
import ContributorSelect from './ContributorSelect';
import { AmplitudeEventNames } from 'shared/amplitudeEvents/amplitude-events-types';
import { deployEvent } from 'shared/amplitudeEvents/AmplitudeEvents';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import { CustomSelectComponent } from 'sub-components/CustomDropdowns';
import { HeaderColors } from 'shared/headerColors/header-colors';

interface SelectOption {
  id: string;
  label: string;
  value: string;
  url?: string;
  authRole?: string;
}

interface IProps {
  submitting?: boolean;
  onPublish: (values: IFormInput) => void;
}

const Allowed: string[] = ['active', 'inactive'];

const FormContributor: FC<IProps> = ({ submitting, onPublish }) => {
  const { t } = useTranslation(['common', 'form']);
  const { handleSubmit, control } = useFormContext<IFormInput>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const authUser = useReactiveVar(userObj);

  const [members, setMembers] = useState<SelectOption[]>([]);
  const [contributorDetails, setContributorDetails] = useState({
    isHiddenFromLO: false,
    contributorCount: 0,
  });

  const [getUsersData, { loading }] = useLazyQuery<ContributorResponse>(
    getContributors,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const _userData: ContributorResponse['AllowedContributorsList'] =
          data?.AllowedContributorsList?.filter((value) => {
            return (
              value.type !== 'branch' &&
              Allowed.includes(value.status) &&
              value.eid !== authUser?.eid
            );
          });

        const _supervisors: SelectOption[] = _userData
          ?.filter((user) => ['active', 'pending']?.includes(user.status))
          .map((user) => {
            return {
              id: user.eid,
              label: user.name,
              value: user.eid,
              url: user?.profilePic,
              role: user?.role,
              locations: user?.locations,
              authRole: user?.authRole,
              status: user?.status,
            };
          });

        setMembers(_supervisors);
      },
    }
  );

  useEffect(() => {
    getUsersData({
      variables: {
        entityId: authUser?.entityId,
      },
    });
  }, [authUser?.entityId]);

  return (
    <>
      <PrimaryButton
        title={t('form:add_contributor')}
        // width='191px'
        width='auto'
        variant='outline'
        colorScheme='blue'
        disabled={submitting}
        onClick={() => {
          deployEvent(
            AmplitudeEventNames.FORMS_ADD_NEW_FORM_BUTTON_ADD_CONTRIBUTOR_CLICK
          );
          onOpen();
        }}
      />
      <Modal isOpen={isOpen} onClose={onClose} size='3xl'>
        <ModalOverlay />
        <ModalContent p={'6'} pb={8} borderRadius='21px'>
          <ModalHeader>Add Contributors</ModalHeader>
          <ModalCloseButton m={'7'} />
          <ModalBody marginTop={2}>
            <Flex
              px={5}
              py={4}
              bg='rgba(177, 229, 252, 0.4)'
              mt={5}
              mb={3}
              borderRadius='10px'
            >
              <Box mr={3}>
                <ManagerIcon />
              </Box>
              <div>
                <Trans t={t} i18nKey={'form:contributor_helper'} />
              </div>
            </Flex>
            <div>
              {/* <Box borderRadius='7px' border='1px solid #efefef' p={6}> */}
              {/* <GenericColorHeader
                  title={t('form:who_get_notified')}
                  color='#b1e5fc'
                /> */}
              <div style={{ margin: '2rem 0rem' }}>
                <TitleHeader title={t('form:select_contributor')} isRequired />
                {/* <chakra.label
                    className='label'
                    fontSize='14px'
                    fontWeight='600'
                    mb='0.5rem'
                    display='inline-block'
                  >
                    {t('form:select_contributor')}
                  </chakra.label> */}
                <Controller
                  control={control}
                  name='contributors'
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl mt={2} isInvalid={!!fieldState?.error}>
                        <CustomSelectComponent
                          options={members}
                          value={field.value}
                          placeholder='Select contributors(s)'
                          showHeader
                          headerTitle='Select contributors(s)'
                          headerBarColor={HeaderColors.Purple}
                          showHeaderCloseIcon
                          showSearchField
                          showDivider
                          bottomOutsideBtn={{
                            show: true,
                            title: 'Add selected members',
                          }}
                          showFilter
                          showSelectAll
                          onChange={(event) => {
                            field.onChange(event);
                            setContributorDetails((prevState) => {
                              return {
                                ...prevState,
                                contributorCount: event?.length,
                              };
                            });
                          }}
                          loading={loading}
                        />
                      </FormControl>
                    );
                  }}
                />

                <Flex
                  px={5}
                  py={4}
                  bg='#CABDFF66'
                  mt={5}
                  mb={3}
                  borderRadius='10px'
                >
                  <div>
                    <Trans t={t} i18nKey={'form:contributorLocationHelper'} />
                  </div>
                </Flex>

                <Controller
                  name='responseVisibility'
                  control={control}
                  defaultValue='public'
                  render={({ field }) => {
                    return (
                      <Flex
                        gap='1rem'
                        mt='16px'
                        align='center'
                        justify='space-between'
                        p={5}
                        bg='#F7F7F7'
                        borderRadius='10px'
                      >
                        <Flex flexDir='column' gap='6px'>
                          <Box color='#333B4F' fontSize='16px' fontWeight='600'>
                            Hide responses from LO?
                          </Box>
                          <Box color='#6F767E' fontSize='14px' fontWeight='500'>
                            Hide location employees responses from respective
                            Location Owners
                          </Box>
                        </Flex>
                        <Switch
                          size='md'
                          isChecked={field.value === 'private'}
                          onChange={(event) => {
                            if (event.target.checked) {
                              field.onChange('private');
                              setContributorDetails((prevState) => {
                                return { ...prevState, isHiddenFromLO: true };
                              });
                            } else {
                              field.onChange('public');
                              setContributorDetails((prevState) => {
                                return { ...prevState, isHiddenFromLO: false };
                              });
                            }
                          }}
                          ref={field.ref}
                        />
                      </Flex>
                    );
                  }}
                />

                {/* <Controller
                    name={'notifyVia'}
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => {
                      let value = toArray(field.value);

                      const onChange = (val: 'email' | 'sms') => {
                        if (value.includes(val)) {
                          value = value.filter((v) => v !== val);
                        } else {
                          value.push(val);
                        }
                        field.onChange(value);
                      };

                      return (
                        <Flex
                          direction='column'
                          gap='0.5rem'
                          width='max-content'
                          mt='16px'
                        >
                          <Checkbox
                            size={'md'}
                            isChecked={value.includes('sms')}
                            onChange={() => onChange('sms')}
                          >
                            {t('form:notify_sms')}
                          </Checkbox>
                          <Checkbox
                            size={'md'}
                            isChecked={value.includes('email')}
                            onChange={() => onChange('email')}
                          >
                            {t('form:notify_email')}
                          </Checkbox>
                        </Flex>
                      );
                    }}
                  /> */}
              </div>
              {/* </Box> */}
              <PrimaryButton
                isLoading={submitting}
                disabled={submitting}
                title={t('form:saveContributors')}
                colorScheme='blue'
                onClick={(event) => {
                  // event.preventDefault();
                  // handleSubmit(onPublish).call(null, event);
                  deployEvent(AmplitudeEventNames?.ADD_CONTRIBUTOR, {
                    form_id: '',
                    create_form_funnel_id: 5,
                    total_contributors: contributorDetails?.contributorCount,
                    hide_responses_from_lo: contributorDetails?.isHiddenFromLO,
                    edit_form_funnel_id: 3,
                  });
                  onClose();
                }}
                style={{
                  borderRadius: '12px',
                  color: 'white',
                  height: '48px',
                }}
              />
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FormContributor;
