import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { toArray } from '../../../../utils';
import { useLauncherConfigContext } from '../common';
import { EditorSectionItem } from './sections';
import AddPhaseInEditor from './AddPhaseInEditor';

interface IProps {}

const EditorSectionLists: FC<IProps> = () => {
  const contents = useLauncherConfigContext((state) => {
    return toArray(state.config.contents).map((con) => con.eid);
  });

  return (
    <Flex flex={4} flexDir='column'>
      <Flex
        flexDir='column'
        overflow='auto'
        css={{
          '&::-webkit-scrollbar': {
            height: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#c1c1c1',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#a8a8a8',
          },
        }}
      >
        {contents.map((_, index) => {
          return <EditorSectionItem key={index} sectionIndex={index} />;
        })}
        <Flex id='editor-new-section-scroll' />
      </Flex>

      <Flex flexDir='column' position='sticky' bottom={0} mt={3}>
        <AddPhaseInEditor />
      </Flex>
    </Flex>
  );
};

EditorSectionLists.displayName = 'sc/L/lc/ev/EditorSectionList';

export default EditorSectionLists;
