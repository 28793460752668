import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { IFormInput } from '../../types';
import RightSectionHeader from './RightSectionHeader';
import RightSectionLists from './RightSectionLists';

interface IProps {
  onBackClick: () => void;
  selectedSection: number;
  control: Control<IFormInput>;
  setValue: UseFormSetValue<IFormInput>;
}

const RightSection: FC<IProps> = ({
  selectedSection,
  setValue,
  onBackClick,
  control,
}) => {
  return (
    <Flex
      position='sticky'
      top='100px'
      width='280px'
      bg='white'
      borderRadius='8px'
      p={4}
      gap={4}
      alignSelf='start'
      overflow='hidden'
      flexDirection='column'
    >
      <RightSectionHeader control={control} onBackClick={onBackClick} />
      <RightSectionLists
        selectedSection={selectedSection}
        control={control}
        setValue={setValue}
      />
    </Flex>
  );
};

export default RightSection;
