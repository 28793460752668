import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useLauncherConfigContext } from '../../common';
import SectionTasks from './SectionTasks';
import SectionHeader from './SectionHeader';
import AddTaskButton from './AddTaskButton';
import { useSectionFilterCheck } from './useSectionFilterCheck';

interface IProps {
  sectionIndex: number;
}

const BoardSectionItem: FC<IProps> = ({ sectionIndex }) => {
  const openTaskForm = useLauncherConfigContext((state) => state.openTaskForm);

  const isHidden = useSectionFilterCheck(sectionIndex);

  if (isHidden) {
    return null;
  }

  return (
    <Flex flexDir='column' gap={3} width='340px' minW='340px'>
      <SectionHeader sectionIndex={sectionIndex} />
      <AddTaskButton
        onAddClick={() => openTaskForm({ sectionIndex, taskIndex: -1 })}
      />
      <SectionTasks sectionIndex={sectionIndex} />
    </Flex>
  );
};

export default BoardSectionItem;
