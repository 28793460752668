import React, { FC } from 'react';
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Text,
  VStack,
  Image,
  Box,
  Button,
  ModalOverlay,
} from '@chakra-ui/react';

import { LiaCheckCircle } from 'react-icons/lia';
import confirmation from '../../../../../assets/images/confirmation.svg';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const ConfirmationModal: FC<IProps> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius={'12px'} textAlign={'center'} pt={'24px'}>
        <Box
          display={'flex'}
          flexDirection='row'
          alignItems={'center'}
          justifyContent={'center'}
          width='100%'
          mb='20px'
        >
          <Box
            fontSize={'14px'}
            color={'#33383F'}
            fontWeight={500}
            width={'70%'}
          >
            Your XAPI File has been successfully
            <Text
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={1}
            >
              uploaded <LiaCheckCircle color='#83BF6E' />
            </Text>
          </Box>
          <ModalCloseButton mt={3} />
        </Box>
        <ModalBody>
          <VStack spacing={4}>
            <Box position='relative' w='200px' h='200px'>
              <Image
                src={confirmation}
                alt='Confirmation Illustration'
                w='100%'
                h='100%'
              />
            </Box>

            <Text fontSize={'20px'} fontWeight={600} color={'#33383F'}>
              Your content is being processed!
            </Text>
            <Text color={'#6F767E'} fontSize={'14px'} fontWeight={500}>
              This may take 24-48 hours. We'll notify you as soon as your
              training path is ready to view
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter mb={'24px'}>
          <Button colorScheme='blue' onClick={onClose} borderRadius={'12px'}>
            Sure!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
