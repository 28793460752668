import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from '@chakra-ui/react';
import { getTaskCategoryLabel } from 'sub-components/Launcher/dashboard/Location/helper';
import { LTaskCategoryEntity } from 'sub-components/Launcher/launcher-config/common/launcher-config.graphql';

interface IProps {
  categories: LTaskCategoryEntity[];
  selectedCategories: string[];
  setSelectedCategories: (category: string[]) => void;
}

const CategoryDropdown = ({
  categories,
  selectedCategories,
  setSelectedCategories,
}: IProps) => {
  const handleChange = (value: string[]) => {
    if (value.includes('All categories')) {
      setSelectedCategories(
        selectedCategories.includes('All categories') ? [] : ['All categories']
      );
    } else {
      setSelectedCategories(value);
    }
  };

  const selectedText =
    selectedCategories.length === 0 ||
    selectedCategories.includes('All categories')
      ? 'All categories'
      : selectedCategories
          .map((opt) => getTaskCategoryLabel(categories, opt as string))
          .join(', ');

  return (
    <Menu closeOnSelect={false}>
      <Tooltip
        label={selectedText}
        hasArrow
        fontSize={15}
        fontWeight={500}
        padding='8px 16px'
        background='#000000C7'
        isDisabled={selectedText === 'All categories'}
      >
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          width='225px'
          border='2px solid #EFEFEF'
          borderRadius='.75rem'
          backgroundColor='#FCFCFC'
          padding='8px 16px'
          sx={{
            bg: 'transparent',
            _hover: { bg: 'transparent' },
            _active: { bg: 'transparent' },
            _focus: { boxShadow: 'none' },
          }}
        >
          <Box
            as='span'
            width='165px'
            textOverflow='ellipsis'
            overflow='hidden'
            whiteSpace='nowrap'
            display='inline-block'
            marginTop='5px'
            textAlign='start'
            fontSize={15}
            fontWeight={700}
          >
            {selectedText}
          </Box>
        </MenuButton>
      </Tooltip>
      <MenuList
        padding='1rem'
        borderRadius='1rem'
        maxHeight='35vh'
        overflowY='scroll'
      >
        <CheckboxGroup value={selectedCategories} onChange={handleChange}>
          <Box display='flex' flexDirection='column' gap={1}>
            <MenuItem
              closeOnSelect={false}
              sx={{
                _hover: {
                  bg: '#F4F4F4',
                  color: '#1A1D1F',
                },
                bg: selectedCategories.includes('All categories')
                  ? '#F4F4F4'
                  : 'transparent',
                color: selectedCategories.includes('All categories')
                  ? '#1A1D1F'
                  : '#6F767E',
                padding: '12px',
              }}
              color='#6F767E'
            >
              <Checkbox
                value='All categories'
                gap={1}
                fontSize={15}
                fontWeight={600}
                sx={{
                  '.chakra-checkbox__control': {
                    borderColor: '#6F767E',
                    bg: 'transparent',
                    _hover: {
                      borderColor: '#6F767E',
                    },
                  },
                  '.chakra-checkbox__control[data-checked]': {
                    bg: 'transparent',
                    borderColor: '#6F767E',
                    color: '#6F767E',
                  },
                }}
              >
                All categories
              </Checkbox>
            </MenuItem>

            {[...categories].reverse().map((category) => {
              const isSelected = selectedCategories.includes(category.eid);

              return (
                <MenuItem
                  key={category.eid}
                  closeOnSelect={false}
                  sx={{
                    _hover: {
                      bg: '#F4F4F4',
                      color: '#1A1D1F',
                    },
                    bg: isSelected ? '#F4F4F4' : 'transparent',
                    color: isSelected ? '#1A1D1F' : '#6F767E',
                    padding: '12px',
                  }}
                  color='#6F767E'
                >
                  <Checkbox
                    value={category.eid}
                    gap={1}
                    fontSize={15}
                    fontWeight={600}
                    sx={{
                      '.chakra-checkbox__control': {
                        borderColor: '#6F767E',
                        bg: 'transparent',
                        _hover: {
                          borderColor: '#6F767E',
                        },
                      },
                      '.chakra-checkbox__control[data-checked]': {
                        bg: 'transparent',
                        borderColor: '#6F767E',
                        color: '#6F767E',
                      },
                    }}
                  >
                    {category.name}
                  </Checkbox>
                </MenuItem>
              );
            })}
          </Box>
        </CheckboxGroup>
      </MenuList>
    </Menu>
  );
};

export default CategoryDropdown;
