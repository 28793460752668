import { AddIcon } from '@chakra-ui/icons';
import { Image, Stack, Box, Text, Flex } from '@chakra-ui/react';
import { Authorize, AuthRole } from 'authorization';
import React, { FC } from 'react';
import { ActionButton } from 'ui-components';
import { ReactComponent as IllustrationsIcon } from 'assets/images/Illustrations.svg';
import { ReactComponent as LocationMemberEmptyStateIcon } from 'assets/images/empty-state/location-member-empty-state.svg';

interface IProps {
  view: 'locations' | 'uploadedBy';
}

const EmptyState: FC<IProps> = ({ view }) => {
  let title = 'No locations found';
  let description = 'Oops! Try a different search to get results.';

  if (view === 'locations') {
    title = 'No locations found';
    description = 'Oops! Try a different search to get results.';
  } else if (view === 'uploadedBy') {
    title = 'No members found';
    description = 'Oops! Try a different search to get results.';
  }

  const renderEmptyStateIcon = () => {
    if (view === 'locations') {
      return <IllustrationsIcon width={'160px'} height={'160px'} />;
    } else if (view === 'uploadedBy') {
      return <LocationMemberEmptyStateIcon width={'160px'} height={'160px'} />;
    } else {
      return <IllustrationsIcon width={'160px'} height={'160px'} />;
    }
  };

  return (
    <Flex p={'10px'} alignItems={'center'} flexDir={'column'}>
      {renderEmptyStateIcon()}

      <Text fontWeight={700} fontSize={'18px'}>
        {title}
      </Text>
      <Text
        align={'center'}
        color={'#9E9E9E'}
        fontWeight={500}
        fontSize={'14px'}
      >
        {description}
      </Text>
    </Flex>
  );
};

EmptyState.displayName =
  'sub-components/nexus/Compliance/Listing/components/Filter-v2/EmptyState';

export default EmptyState;
