import React, { FC } from 'react';
import { Center, Flex } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useLauncherConfigContext } from '../../common';
import { useAddAssignee } from '../../common/add-assignee';

import SectionAssignee from './SectionAssignee';

interface IProps {
  sectionIndex: number;
}

const AddSectionAssignee: FC<IProps> = ({ sectionIndex }) => {
  const addAssignee = useAddAssignee();

  const data = useLauncherConfigContext((state) => {
    const value = state.config.contents?.[sectionIndex];

    return {
      eid: value?.eid,
      assignedType: value?.assignedType,
      assignedUser: value?.assignedUser,
      updateAssignee: state.updateSectionAssignee,
    };
  });

  const onAddAssigneeClick = () => {
    addAssignee({
      onChange: (value) => {
        data.updateAssignee(sectionIndex, value);
      },
    });
  };

  if (!data.assignedType) {
    return (
      <Flex
        borderRadius='50px'
        border='2px solid #EEEEEE'
        bg='#FFFFFF'
        minH='28px'
        align='center'
        paddingLeft='12px'
        fontSize='11px'
        fontWeight='500'
        color='#33383F'
        gap={2}
        cursor='pointer'
        userSelect='none'
        sx={{
          div: {
            marginBlock: '-2px',
            marginRight: '-2px',
          },
        }}
        onClick={onAddAssigneeClick}
      >
        Add phase assignee
        <Center boxSize='28px' border='2px solid #EEEEEE' borderRadius='full'>
          <AddIcon boxSize='9px' color='#33383F' />
        </Center>
      </Flex>
    );
  }

  return (
    <SectionAssignee
      isBoardView
      assignedType={data?.assignedType}
      assignedUser={data?.assignedUser || []}
    />
  );
};

export default AddSectionAssignee;
