import React, { FC } from 'react';
import { ReactComponent as NotFound } from 'assets/images/Illustrations.svg';
import { ReactComponent as EmptyTable } from 'assets/images/nexus/compliance/table_empty_state.svg';
import { Flex, Text } from '@chakra-ui/react';

interface IProps {
  searchFieldText?: string;
  selectedModules?: any[];
}
const DefaultEmptyState: FC<IProps> = ({
  searchFieldText,
  selectedModules,
}) => {
  let title, description;

  if (searchFieldText) {
    title = `Result not found`;
    description =
      'Whoops ... this information is not available for a moment, maybe try another keyword!';
  } else if (selectedModules?.length > 0) {
    title = `No results found`;
    description =
      'Your selected filters didn’t match any items. Try adjusting your filters.';
  } else {
    title = 'No notifications';
    description = 'No notifications available at the moment.';
  }

  const renderEmptyStateIcon = () => {
    if (searchFieldText || selectedModules?.length > 0) {
      return <NotFound height={'180px'} width={'180px'} />;
    }
    return <NotFound height={'180px'} width={'180px'} />;
  };

  return (
    <Flex alignItems={'center'} justifyContent={'center'} h={'70vh'}>
      <Flex alignItems={'center'} flexDir={'column'}>
        {renderEmptyStateIcon()}
        <Text mt={'20px'} fontSize={'20px'} fontWeight={600} color={'#33383F'}>
          {title}
        </Text>
        <Text mt={'8px'} fontSize={'14px'} fontWeight={500} color={'#6F767E'}>
          {description}
        </Text>
      </Flex>
    </Flex>
  );
};

export default DefaultEmptyState;
