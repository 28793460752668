import React, { useCallback, useRef } from 'react';
import { useConfirm, UseConfirm } from '../../../../../ui-components';
import { useLatest } from '../../../../../hooks';
import { useLauncherConfigContext } from '../context';
import PhaseSettingContainer from './PhaseSettingContainer';

interface IProps {
  sectionIndex: number;
}

type PhaseSetting = (props: IProps) => void;

export const usePhaseSetting = (): PhaseSetting => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  const { getSectionData, updateSection } = useLauncherConfigContext(
    (state) => {
      return {
        getSectionData: state.getSectionData,
        updateSection: state.updateSection,
      };
    }
  );

  const sectionRef = useLatest(getSectionData);
  const updateRef = useLatest(updateSection);

  return useCallback(({ sectionIndex }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    const data = sectionRef.current(sectionIndex);

    confirmRef.current = confirm({
      content: (
        <PhaseSettingContainer
          eid={data.eid!}
          category={data.category}
          trigger={data.trigger}
          triggerAssignment={data.triggerAssignment}
          assignedType={data.assignedType}
          assignedUser={data.assignedUser}
          tasks={data.tasks}
          onSaveChanges={(value) => {
            updateRef.current(sectionIndex, value);

            setTimeout(confirmRef.current?.destroy!, 100);
          }}
        />
      ),
      afterClose: () => {
        // @ts-ignore
        setTimeout(() => document.activeElement?.blur(), 10);
      },
      contentProps: {
        minWidth: '1200px',
        borderRadius: '12px',
      },
      bodyProps: {
        padding: 0,
      },
      footer: null,
    });

    return confirmRef.current;
  }, []);
};
