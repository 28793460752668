import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Divider,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { Button } from 'atoms';
import moment from 'moment';
import React, { FC, useState, useEffect, useCallback } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import './style.css';

interface DateRangePickerProps {
  selectedRange: DateRange | undefined;
  updateDateRange: (range: DateRange | undefined) => void;
  onPopoverClose: (range: DateRange | undefined) => void;
}

const DateRangePicker: FC<DateRangePickerProps> = ({
  selectedRange,
  updateDateRange,
  onPopoverClose,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [internalRange, setInternalRange] = useState<DateRange | undefined>(
    selectedRange
  );
  const previousMonth = moment().subtract(1, 'month').toDate();

  // Sync internalRange with selectedRange when popover opens or selectedRange changes
  // useEffect(() => {
  //   if (isOpen) {
  //     setInternalRange(selectedRange);
  //   }
  // }, [isOpen, selectedRange]);

  const handleSelect = useCallback((range: DateRange | undefined) => {
    setInternalRange(range);
  }, []);

  const handleClear = useCallback(() => {
    setInternalRange({ from: undefined, to: undefined });
    updateDateRange({ from: undefined, to: undefined });
    onClose();
  }, [updateDateRange, onClose]);

  const handleSelectConfirm = useCallback(() => {
    // updateDateRange(internalRange);
    onPopoverClose(internalRange);
    onClose();
  }, [updateDateRange, internalRange, onPopoverClose, onClose]);

  const handlePopoverClose = useCallback(() => {
    setInternalRange(selectedRange);
    onClose();
  }, [selectedRange, onClose]);

  return (
    <Popover
      placement='bottom-start'
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handlePopoverClose} // Handle popover close by resetting internalRange
      closeOnBlur={true} // Close when clicking outside
    >
      <PopoverTrigger>
        <Flex
          border='2px solid rgba(239, 239, 239, 1)'
          borderRadius='12px'
          p='8px 20px'
          align='center'
          gap='5px'
          justify='space-between'
          cursor='pointer'
          color='rgba(111, 118, 126, 1)'
          minW='190px'
          maxW='fit-content'
        >
          <Box fontWeight={600} minW='fit-content'>
            {selectedRange?.from && selectedRange?.to
              ? `${moment(selectedRange.from).format('DD MMM YYYY')} - ${moment(
                  selectedRange.to
                ).format('DD MMM YYYY')}`
              : 'Select date range'}
          </Box>
          <ChevronDownIcon fontSize='20px' />
        </Flex>
      </PopoverTrigger>
      <PopoverContent w='fit-content'>
        <PopoverBody boxShadow='lg' p={4}>
          <Box>
            <DayPicker
              mode='range'
              numberOfMonths={2}
              selected={internalRange}
              defaultMonth={previousMonth}
              // Uncomment and adjust the disabled ranges as needed
              // disabled={[
              //   { before: new Date() },
              //   { after: new Date(2024, 10, 25) },
              // ]}
              onSelect={handleSelect}
              footer={
                <Flex w='full' flexDir='column' gap={2} justify='center'>
                  <Divider />
                  <Flex align='center' gap={2} justify='flex-end'>
                    <Button variant='outline' onClick={handleClear}>
                      Clear
                    </Button>
                    <Button
                      variant='solid'
                      colorScheme='blue'
                      onClick={handleSelectConfirm}
                      isDisabled={!internalRange?.from || !internalRange?.to}
                    >
                      Select
                    </Button>
                  </Flex>
                </Flex>
              }
            />
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

DateRangePicker.displayName =
  'sub-components/audits/range-date-picker/RangeDatePicker';

export default React.memo(DateRangePicker);
