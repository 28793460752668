import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import SearchInput from '../../../../../atoms/SearchInput';

interface IProps {
  placeholder: string;
  searchFieldText?: string;
  setSearchFieldText?: React.Dispatch<React.SetStateAction<string>>;
}

const Searchbox: FC<IProps> = ({
  placeholder,
  setSearchFieldText,
  searchFieldText,
}) => {
  return (
    <Flex width={'100%'}>
      <SearchInput
        disabled={true}
        size='md'
        hideShortcuts={true}
        placeholder={placeholder}
        onChange={(e) => setSearchFieldText(e.target.value)}
        value={searchFieldText}
      />
    </Flex>
  );
};

Searchbox.displayName =
  'displayName:sub-components/nexus/Compliance/Listing/components/Searchbox';

export default Searchbox;
