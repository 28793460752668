import React, { useCallback } from 'react';
import { Flex, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { HeaderColors } from '../../../../configs';
import { BoxHeader, useConfirm } from '../../../../ui-components';

interface IProps {
  onConfirm?: () => void;
  onCancel?: () => void;
  onChange?: (value: 'future' | 'both') => void;
}

type AssigneeConfirm = (props: IProps) => void;

export const useUpdateAssigneeConfirm = (): AssigneeConfirm => {
  const confirm = useConfirm();

  return useCallback(({ onConfirm, onCancel, onChange }) => {
    confirm({
      title: <BoxHeader color={HeaderColors.Purple} title='Update Assignee' />,
      isCentered: true,
      content: (
        <Flex flexDir='column' gap={6} pt={2}>
          <Flex fontWeight='500' fontSize='16px' color='#6F767E'>
            This action will update this task’s assignees across all locations
            linked to this board. Which locations should this change apply to?
          </Flex>

          <RadioGroup defaultValue='future' onChange={onChange}>
            <Stack
              spacing={5}
              sx={{
                '.chakra-radio__label': {
                  color: '#33383F',
                  fontSize: 15,
                  fontWeight: 600,
                },
              }}
            >
              <Radio size='lg' value='future'>
                Only Future launching locations
              </Radio>
              <Radio size='lg' value='both'>
                Both current & future launching locations
              </Radio>
            </Stack>
          </RadioGroup>
        </Flex>
      ),

      contentProps: {
        minWidth: '600px',
        borderRadius: '12px',
        paddingY: '12px',
      },

      okText: 'Confirm',
      onOK: onConfirm,
      onCancel: onCancel,

      // footerProps: {
      //   flexDir: 'column-reverse',
      //   gap: 2,
      // },
      okButtonProps: {
        width: 'full',
        size: 'lg',
      },
      cancelButtonProps: { width: 'full', size: 'lg', variant: 'outline' },
    });
  }, []);
};
