import { Heading, AuditResponse, FlaggedItems } from './components';
import { generateValidId } from './helper';
import MediaSummary from './MediaSummary';
import { FC } from 'react';
import AuditorSignature from './AuditorSignature';
import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import {
  AssignmentType,
  SectionDetailsResponseType,
  SessionDetailEntity,
} from './types/report.types';
import { useTranslation } from 'react-i18next';

type IProps = {
  mappedQuestionData: any;
  auditData: SessionDetailEntity;
  assignment: AssignmentType;
  additionalData: any;
  type?: string;
};

const RenderAllSections: FC<IProps> = ({
  type,
  auditData,
  assignment,
  additionalData,
  mappedQuestionData,
}) => {
  const { t } = useTranslation(['audit']);

  const tempArray = [
    'Flagged Items',
    'Auditor Section',
    'Auditor Signature',
    'Media Summary',
  ];
  const flaggedItemsResponseNewFormat = {};
  const otherSectionResponses = {};
  const flaggedItemsId = [];
  let totalSections = 0;

  mappedQuestionData?.[0]?.responses?.forEach((item) => {
    flaggedItemsId.push(item?.qId);
    if (!flaggedItemsResponseNewFormat.hasOwnProperty(item?.section)) {
      flaggedItemsResponseNewFormat[item?.section] = [item];
    } else {
      flaggedItemsResponseNewFormat[item?.section].push(item);
    }
  });

  mappedQuestionData?.forEach((item) => {
    if (
      !otherSectionResponses.hasOwnProperty(item?.title) &&
      !tempArray?.includes(item?.title)
    ) {
      if (item?.title !== 'Miscellaneous') {
        totalSections += 1;
      }
      otherSectionResponses[item?.title] = [
        { ...item, section: totalSections },
      ];
    } else if (!tempArray?.includes(item?.title)) {
      otherSectionResponses[item?.title].push({
        ...item,
        section: totalSections,
      });
    }
  });

  return (
    <>
      {/* Flagged Items */}
      <Box
        style={{
          border: '1px solid #EFEFEF',
          borderRadius: '12px',
          margin: '2rem 0px',
          scrollMargin: '8vh',
          backgroundColor: '#FF6A550D',
        }}
        id={`${generateValidId('Flagged Items')}`}
      >
        <Heading headingColor={'FFBC99'} title={'Flagged Items'} />
        {mappedQuestionData?.[0]?.responses?.length > 0 ? (
          <Flex flexDir={'column'} mt={'24px'}>
            {Object.keys(flaggedItemsResponseNewFormat)?.map((key, index) => {
              return (
                <>
                  <Text
                    my={'16px'}
                    mx={'1.5rem'}
                    fontWeight={600}
                    color={'#56130A'}
                  >
                    {`Section ${index + 1}: ${key}`}
                  </Text>

                  <Flex mx={'1.5rem'}>
                    <Divider />
                  </Flex>

                  {flaggedItemsResponseNewFormat[key]?.map((val) => {
                    return (
                      <AuditResponse
                        key={`${val?.qType}-${index}`}
                        section={val?.title}
                        index={index}
                        {...val}
                        isFlaggedSection={true}
                      />
                    );
                  })}
                </>
              );
            })}
          </Flex>
        ) : (
          <Flex
            justifyContent={'center'}
            style={{
              borderRadius: '12px',
              backgroundColor: '#FF6A550D',
              margin: '38px 1.5rem 10px 1.5rem',
            }}
          >
            <Text
              lineHeight={'48px !important'}
              fontWeight={500}
              fontSize={'20px'}
            >
              No Flagged Responses
            </Text>
          </Flex>
        )}
      </Box>
      {/* Responses */}
      <Box
        style={{
          border: '1px solid #EFEFEF',
          borderRadius: '12px',
          margin: '2rem 0px',
          scrollMargin: '8vh',
        }}
      >
        <Heading headingColor={'B1E5FC'} title={'Responses'} />
        <Flex mt={'24px'} flexDir={'column'}>
          {Object.keys(otherSectionResponses)?.map((key, index) => {
            return (
              <Flex
                key={key}
                scrollMargin={'8vh'}
                flexDir={'column'}
                id={`${generateValidId(key)}`}
              >
                <Flex
                  justifyContent={'space-between'}
                  mx={'1.5rem'}
                  p={'16px 24px'}
                  backgroundColor={'#2A85FF1A'}
                >
                  <Flex gap={2}>
                    <Text
                      style={{
                        marginLeft: '1rem',
                        color: '#2A85FF',
                        fontWeight: '600',
                        fontSize: '20px',
                      }}
                    >
                      {key}
                    </Text>
                    {/* {key !== 'Miscellaneous' && (
                      <Text
                        fontWeight={500}
                        fontSize={'14px'}
                        color={'#33383F'}
                      >
                        (Section {otherSectionResponses[key]?.[0]?.['section']}/
                        {totalSections})
                      </Text>
                    )} */}
                  </Flex>

                  <Flex gap={2} alignItems={'center'}>
                    {key !== 'Miscellaneous' && (
                      <Flex gap={2} alignItems={'center'}>
                        <Text fontWeight={600}>
                          {otherSectionResponses[key]?.[0]?.['section']}/
                          {totalSections}
                        </Text>
                        <Text fontWeight={500} color={'#6F767E'}>
                          Sections
                        </Text>
                        &bull;
                      </Flex>
                    )}
                    <Flex gap={2} alignItems={'center'}>
                      <Text fontWeight={600}>
                        {
                          otherSectionResponses[key]?.[0]?.score?.split(
                            ' '
                          )?.[0]
                        }
                      </Text>
                      <Text fontWeight={500} color={'#6F767E'}>
                        Points
                      </Text>
                    </Flex>
                    &bull;
                    <Flex gap={2} alignItems={'center'}>
                      <Text fontWeight={600}>
                        {otherSectionResponses[key]?.[0]?.score
                          ?.split(' ')?.[1]
                          ?.replaceAll('(', '')
                          ?.replaceAll(')', '')}
                      </Text>
                      <Text fontWeight={500} color={'#6F767E'}>
                        Score
                      </Text>
                    </Flex>
                    &bull;
                    <Flex gap={2} alignItems={'center'}>
                      <Text fontWeight={600}>
                        {otherSectionResponses[key]?.[0]?.flaggedResponseCount}
                      </Text>
                      <Text fontWeight={500} color={'#6F767E'}>
                        {otherSectionResponses[key]?.[0]?.flaggedResponseCount >
                        1
                          ? 'Flags'
                          : 'Flag'}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                {otherSectionResponses?.[key]?.[0]?.responses?.map(
                  (val, index) => {
                    return (
                      <AuditResponse
                        key={`${val?.qType}-${index}`}
                        section={val?.title}
                        index={index}
                        {...val}
                        flaggedItemsId={flaggedItemsId}
                      />
                    );
                  }
                )}
              </Flex>
            );
          })}
        </Flex>
      </Box>
      {/* <Box>Auditor Section</Box> */}
      <AuditorSignature
        response={mappedQuestionData?.[mappedQuestionData?.length - 3]}
        type={type}
        additionalData={additionalData}
        assignment={assignment}
      />
      {/* Media Summary */}
      <Box
        id={`${generateValidId('Media Summary')}`}
        style={{
          border: '1px solid #EFEFEF',
          borderRadius: '12px',
          margin: '2rem 0px',
          scrollMargin: '8vh',
        }}
      >
        <Flex m={'1.5rem'} alignItems={'center'} gap={2}>
          <Box
            borderRadius={'4px'}
            height={'32px'}
            width={'16px'}
            backgroundColor={'#FFD88D'}
          ></Box>
          <Text style={{ fontWeight: '600', fontSize: '24px' }}>
            Media Summary
          </Text>
        </Flex>

        <Box
          style={{
            // border:
            //   item?.response?.length > 0 ? '1px solid #EFEFEF' : undefined,
            borderRadius: '12px',
            margin: '2rem 0px',
            scrollMargin: '8vh',
          }}
          // key={index}
          // id={`${generateValidId(item?.title)}`}
        >
          {mappedQuestionData?.[mappedQuestionData?.length - 1]?.responses
            ?.length > 0 && (
            <>
              <MediaSummary
                response={
                  mappedQuestionData?.[mappedQuestionData?.length - 1]
                    ?.responses
                }
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

RenderAllSections.displayName =
  'sub-components/audits/report/RenderAllSections';
export default RenderAllSections;
