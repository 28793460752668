import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import GenericModal from 'ui-components/GenericModal';
import closeIcon from '../../../../assets/images/closeIcon.png';

interface IProps {
  showShowArchiveModal: boolean;
  closeShowArchiveModal: () => void;
  archiveFormHandler: () => void;
  status: string | undefined;
  responseCount: string | undefined;
}

const AllFormResponseArchiveModal: FC<IProps> = ({
  showShowArchiveModal,
  closeShowArchiveModal,
  archiveFormHandler,
  status,
  responseCount,
}) => {
  const { t } = useTranslation('form');
  const { t: tc } = useTranslation('common');

  // Determine the modal text based on the status
  const modalText =
    status === 'inactive' ? t('activate_form_warn') : t('deactivate_form_warn');

  // Determine the description based on the status and responseCount
  const modalDescription =
    status === 'inactive'
      ? t('activate_form_description')
      : responseCount && parseInt(responseCount) > 0
      ? t('deactivate_form_description2')
      : t('deactivate_form_description');

  return (
    <GenericModal
      isOpen={showShowArchiveModal}
      onClose={closeShowArchiveModal}
      title={modalText}
      closeIcon={closeIcon}
      lineColor={'#CABDFF'}
      isCentered={true}
      buttonsObject={[
        {
          mt: 6,
          type: 'button',
          size: 'lg',
          isLoading: false,
          disabled: false,
          title: tc('cancel'),
          leftIcon: null,
          rightIcon: null,
          onClick: closeShowArchiveModal,
          style: {
            width: '160px',
            backgroundColor: 'white',
            marginRight: '1rem',
          },
          variant: 'outline',
          colorScheme: 'blue',
          className: null,
          value: null,
        },
        {
          mt: 6,
          type: 'button',
          size: 'lg',
          isLoading: false,
          disabled: false,
          title:
            status === 'active' ? t('mark_as_inactive') : t('reactivate_form'),
          leftIcon: null,
          rightIcon: null,
          onClick: archiveFormHandler,
          style: { width: '160px' },
          variant: 'solid',
          colorScheme: 'blue',
          className: null,
          value: null,
        },
      ]}
      buttonsPosition='right'
      paddingLeft={'24px'}
      paddingRight={'24px'}
      paddingTop={'24px'}
      paddingBottom={'24px'}
      modalSizes={{
        sm: '800px',
        md: '800px',
        base: '800px',
        xl: '800px',
        ['2xl']: '800px',
      }}
      modalMinSizes={{
        sm: '800px',
        md: '800px',
        base: '800px',
        xl: '800px',
        ['2xl']: '800px',
      }}
    >
      <div
        style={{
          marginTop: '13px',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '1.63',
          letterSpacing: '-0.16px',
          marginLeft: '20px',
          width: '93%',
          color: '#6F767E',
        }}
      >
        {modalDescription}
      </div>
    </GenericModal>
  );
};

export default AllFormResponseArchiveModal;
