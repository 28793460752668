import { gql } from '@apollo/client';

type NotificationConfigTaskNewTaskNotification = {
  browser: boolean;
  email: boolean;
  pushNotification: boolean;
  sms: boolean;
};

type NotificationConfigAudit = {
  completionNotification: NotificationConfigTaskNewTaskNotification;
  newAudit: NotificationConfigTaskNewTaskNotification;
  reminderNotification: NotificationConfigTaskNewTaskNotification;
  updatesNotification: NotificationConfigTaskNewTaskNotification;
};
type NotificationConfigChat = {
  channelMessage: NotificationConfigTaskNewTaskNotification;
  chatLog: NotificationConfigTaskNewTaskNotification;
  directMessage: NotificationConfigTaskNewTaskNotification;
  groupMessage: NotificationConfigTaskNewTaskNotification;
};
type NotificationConfigForm = {
  responseNotification: NotificationConfigTaskNewTaskNotification;
};
type NotificationConfigLocation = {
  newLocation: NotificationConfigTaskNewTaskNotification;
};
type NotificationConfigSop = {
  newChapterAdded: NotificationConfigTaskNewTaskNotification;
  newFolderAdded: NotificationConfigTaskNewTaskNotification;
  updatesNotification: NotificationConfigTaskNewTaskNotification;
};
type NotificationConfigTask = {
  assigneeNotification: NotificationConfigTaskNewTaskNotification;
  completedNotification: NotificationConfigTaskNewTaskNotification;
  newTaskNotification: NotificationConfigTaskNewTaskNotification;
  overdueNotification: NotificationConfigTaskNewTaskNotification;
  reminderNotification: NotificationConfigTaskNewTaskNotification;
};
type NotificationConfigTraining = {
  assignmentNotification: NotificationConfigTaskNewTaskNotification;
  completedNotification: NotificationConfigTaskNewTaskNotification;
  newTrainingCreated: NotificationConfigTaskNewTaskNotification;
};
type NotificationConfigOther = {
  complianceReminder: NotificationConfigTaskNewTaskNotification;
  conversationLogAdded: NotificationConfigTaskNewTaskNotification;
};
export interface NotificationConfig {
  audit: NotificationConfigAudit;
  chat: NotificationConfigChat;
  form: NotificationConfigForm;
  location: NotificationConfigLocation;
  sop: NotificationConfigSop;
  task: NotificationConfigTask;
  training: NotificationConfigTraining;
  other: NotificationConfigOther;
}
export interface GetNotificationsResponse {
  FetchUserMeta: {
    notification: boolean;
    notificationConfig: NotificationConfig;
  };
}

export const GET_USER_NOTIFICATION_CONFIG = gql`
  query FetchUserMeta($userId: String!) {
    FetchUserMeta(userId: $userId) {
      notificationConfig {
        task {
          taskCreated {
            pushNotification
            sms
            email
          }
          taskAssigned {
            pushNotification
            sms
            email
          }
          taskCompleted {
            pushNotification
            sms
            email
          }
          taskReminder {
            pushNotification
            sms
            email
          }
        }
        training {
          trainingCreated {
            pushNotification
            sms
            email
          }
          trainingAssignment {
            pushNotification
            sms
            email
          }
          trainingCompleted {
            pushNotification
            sms
            email
          }
        }
        chat {
          directMessage {
            pushNotification
            sms
            email
          }
          groupMessage {
            pushNotification
            sms
            email
          }
        }
        form {
          formResponse {
            pushNotification
            sms
            email
          }
        }
        audit {
          auditCreated {
            pushNotification
            sms
            email
          }
          auditReminder {
            pushNotification
            sms
            email
          }
          auditCompletion {
            pushNotification
            sms
            email
          }
          auditUpdates {
            pushNotification
            sms
            email
          }
        }
        location {
          newLocation {
            pushNotification
            sms
            email
          }
        }
        sop {
          sopUpdates {
            pushNotification
            sms
            email
          }
          sopAdded {
            pushNotification
            sms
            email
          }
          newFolderAdded {
            pushNotification
            sms
            email
          }
        }
        other {
          complianceReminder {
            pushNotification
            sms
            email
          }
          conversationLogAdded {
            pushNotification
            sms
            email
          }
        }
        systemSetting {
          delightreeUpdates {
            pushNotification
            sms
            email
          }
          newFeatures {
            pushNotification
            sms
            email
          }
        }
      }
    }
  }
`;
export interface UpdateUserMetaInput {
  input: {
    eid: string;
    notificationConfig: NotificationConfig;
  };
}
export interface UpdateNotificationsResponse {
  UpdateUserMeta: {
    notificationConfig: NotificationConfig;
  };
}
export const UPDATE_USER_NOTIFICATION_CONFIG = gql`
  mutation UpdateUserMeta($input: UpdateUserMetaInputInput) {
    UpdateUserMeta(input: $input) {
      notification
    }
  }
`;

export const GET_NOTIFICATION_HISTORY = gql`
  query Notifications(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyNotificationInput
    $sort: SortFindManyNotificationInput
  ) {
    Notifications(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        isSeen
        channels
        action
        category
        createdAt
        eid
        entityId
        metaData
        seenAt
        predicate
        resourceId
        subCategory
        subject
        templateId
        updatedAt
        userId
      }
    }
  }
`;
export interface MarkAllReadInput {
  startDate?: Date;
  endDate?: Date;
}
export const MARK_ALL_READ = gql`
  mutation MarkAllNotificationSeen($startDate: Date, $endDate: Date) {
    MarkAllNotificationSeen(startDate: $startDate, endDate: $endDate) {
      succeed
    }
  }
`;

export const MARK_READ_UNREAD_SINGLE_NOTIFICATION = gql`
  mutation MarkNotificationSeen($eid: String!, $isSeen: Boolean!) {
    MarkNotificationSeen(eid: $eid, isSeen: $isSeen) {
      eid
    }
  }
`;

export interface MarkGroupedNotificationReadInput {
  isSeen: boolean;
  eids: string[];
}
export const MARK_GROUPED_NOTIFICATIONS_READ = gql`
  mutation MarkNotificationsSeen($isSeen: Boolean!, $eids: [String!]) {
    MarkNotificationsSeen(isSeen: $isSeen, eids: $eids) {
      succeed
    }
  }
`;

export const GET_UNREAD_NOTIFICATIONS = gql`
  query Query {
    UnseenNotificationCount
  }
`;
