import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useFormState } from 'react-hook-form';
import { ActionButton } from '../../../../../ui-components';

interface IProps {
  onClick?: () => void;
  onDiscardClick?: () => void;
}

const SaveChangeButton: FC<IProps> = ({ onClick, onDiscardClick }) => {
  const { isDirty } = useFormState();

  return (
    <Flex gap={8}>
      <ActionButton
        flex={1}
        variant='outline'
        borderRadius='12px'
        actionFn={onDiscardClick}
        isDisabled={!isDirty}
      >
        Discard changes
      </ActionButton>

      <ActionButton
        flex={1}
        colorScheme='blue'
        borderRadius='12px'
        actionFn={onClick}
        isDisabled={!isDirty}
      >
        Save changes
      </ActionButton>
    </Flex>
  );
};

SaveChangeButton.displayName = 'sc/L/lc/c/pd/SaveChangeButton';

export default SaveChangeButton;
