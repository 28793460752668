import React, { FC } from 'react';
import { Center } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../../atoms';

import BoardFilterPopup from './BoardFilterPopup';
import { useLauncherConfigContext } from '../../../common';

interface IProps {}

const BoardFilter: FC<IProps> = () => {
  const { t } = useTranslation('common');

  const filterLength = useLauncherConfigContext((state) => {
    return Object.values(state.filterBy || {}).reduce<number>((acc, v) => {
      return acc + Object.values(v).filter(Boolean).length;
    }, 0);
  });

  return (
    <BoardFilterPopup>
      <Button
        position='relative'
        size='lg'
        variant='outline'
        bg='#FFFFFF'
        color='#6F767E'
        maxWidth='300px'
        aria-selected={filterLength > 0}
        rightIcon={
          <FontAwesomeIcon fontSize={16} icon={faChevronDown as IconProp} />
        }
        _selected={{ borderColor: '#6F767E', color: '#6F767E' }}
      >
        {t('filter')}
        {filterLength ? (
          <Center
            pos='absolute'
            top='-8px'
            right='-8px'
            fontSize='10px'
            fontWeight='600'
            boxSize='20px'
            bgColor='#2A85FF'
            color='#FFFFFF'
            borderRadius='full'
          >
            {filterLength}
          </Center>
        ) : null}
      </Button>
    </BoardFilterPopup>
  );
};

export default BoardFilter;
