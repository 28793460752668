import { Flex } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import React, { FC, useMemo } from 'react';
import { TaskType } from 'sub-components/Launcher/dashboard/dashboard-grapghql';
import {
  LocationPhaseDetails,
  PhaseTask,
} from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import { ILocationLaunchTask } from 'sub-components/Launcher/location-owner/types';
import { BoxHeader } from 'ui-components';
import { GroupedTasksData } from '../types';
import TaskContent from './TaskContent';

interface IProps {
  categories: TaskType[];
  data: GroupedTasksData[];
  refetch?: (
    resData?: ILocationLaunchTask,
    phaseDetails?: LocationPhaseDetails[],
    task?: PhaseTask
  ) => void;
}

const ByTask: FC<IProps> = ({ categories, data, refetch }) => {
  const byTaskData = useMemo(() => {
    const statusOrder = [
      'overdue',
      'duesoon',
      'inProgress',
      'locked',
      'completed',
    ];

    let tasksArr: ILocationLaunchTask[] = [];
    data?.map((_data) => {
      _data?.groupedPhases?.map((group) => {
        group?.tasks?.forEach((task) =>
          tasksArr.push({
            ...task,
            locationName: _data?.locationName,
          })
        );
      });
    });

    const groupedTasks = tasksArr?.reduce((acc, task) => {
      const category =
        categories?.filter((cat) => cat?.eid === task?.taskCategoryEid)?.[0]
          ?.name || 'No type';

      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(task);
      return acc;
    }, {} as Record<string, ILocationLaunchTask[]>);

    return Object.entries(groupedTasks)?.map(([category, tasks]) => ({
      categoryName: category,
      data: tasks.sort((a, b) => {
        const statusA = statusOrder?.indexOf(a?.status);
        const statusB = statusOrder?.indexOf(b?.status);
        return statusA - statusB;
      }),
    }));
  }, [data, categories]);

  return (
    <Flex flexDir='column' gap={4} mt={4}>
      {byTaskData?.map((_data, dIndex) => {
        return (
          <Flex key={dIndex} flexDir='column' gap={4}>
            {_data?.categoryName ? (
              <BoxHeader
                color={HeaderColors.Purple}
                title={_data?.categoryName}
              />
            ) : null}
            <Flex flexDir='column' gap={4}>
              {_data?.data?.map((task, tIndex) => {
                return (
                  <TaskContent
                    key={tIndex}
                    categories={categories}
                    locationName={task?.locationName || ''}
                    task={task}
                    refetch={refetch}
                  />
                );
              })}
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};

ByTask.displayName =
  'displayName:sub-components/Launcher/tasks/components/ByTask';

export default ByTask;
