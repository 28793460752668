import React, { FC, useMemo, useState } from 'react';
import { Box, Flex, useToast } from '@chakra-ui/react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { SearchInput } from 'atoms';
import { userObj } from 'sop-commons/src/client';
import { toArray } from '../../../../utils';
import { AuthRole } from '../../../../authorization';
import { useUserDataSelector } from '../../../../hooks';
import { usersEntityObj } from 'sub-components/Header';
import { ReportShareMemberList } from 'sub-components/audits/report/components';
import {
  FormToUserResponse,
  FormToUserVariable,
  SEND_FORM_QUERY,
} from '../../../forms/modules/SendForm/send-form.graphql';
import { ShareMemberEntity } from '../../../../ui-components/ShareToMember';
import { useHistory } from '../../../../routes';

const ShareReportViaChat: FC = () => {
  const { t } = useTranslation(['audit']);
  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();

  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const authUserId = useUserDataSelector((state) => state.eid);
  const usersData = useReactiveVar(usersEntityObj);

  const members = useMemo(() => {
    return toArray(usersData).filter(
      (user) =>
        [AuthRole.SUPER_ADMIN, AuthRole.ADMIN].includes(user?.authRole) &&
        user.status === 'active' &&
        user.eid !== authUserId
    );
  }, [usersData]);

  const filteredMembers = useMemo(() => {
    if (searchQuery) {
      const reg = new RegExp(searchQuery, 'gi');
      return members.filter((value) => {
        return value.name?.match(reg);
      });
    }
    return members;
  }, [members, searchQuery]);

  const [submitFormData] = useMutation<FormToUserResponse, FormToUserVariable>(
    SEND_FORM_QUERY
  );

  const onSendClick = async (value: ShareMemberEntity) => {
    await submitFormData({
      variables: {
        senderId: userObj().eid,
        receiverIds: [value.eid],
        message: `Hi there! Here’s the report: ${process.env.REACT_APP_FRONTEND_URL}${history.location.pathname}`,
      },
    }).then((response) => {
      if (response.data?.sendDirectMessage?.succeed) {
        toast({
          status: 'success',
          title: t('success'),
          description: t('audit:auditReportSuccessSent', {
            name: value.name,
          }),
        });
      }
    });
  };

  return (
    <Flex
      gap={4}
      flexDir='column'
      width={'100%'}
      paddingX={5}
      paddingY={4}
      borderRadius='8px'
      border='1px solid #C7C7C7'
      maxH='calc(100vh - 88px)'
    >
      <Box fontWeight='600' fontSize='15px'>
        Send via CHAT
      </Box>
      <SearchInput
        value={searchQuery}
        hideShortcuts
        placeholder={'Search members'}
        onChange={(e) => setSearchQuery(e?.target?.value)}
      />
      <ReportShareMemberList
        search={searchQuery}
        members={filteredMembers}
        loading={false}
        onSendClick={onSendClick}
      />
    </Flex>
  );
};

export default ShareReportViaChat;
