import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { TriggerAlert, TriggerInput, TriggerOr } from '../../trigger';
import DynamicActivationTrigger from './DynamicActivationTrigger';
import DynamicDeadlineTrigger from './DynamicDeadlineTrigger';

interface IProps {}

const TaskTriggerSetting: FC<IProps> = () => {
  const value = useWatch<TriggerInput, 'trigger.triggerChoice'>({
    name: 'trigger.triggerChoice',
  });

  const isDefault = !value || value === 'default';
  return (
    <Flex flexDir='column' gap={isDefault ? 3 : '20px'}>
      {isDefault ? <TriggerAlert type='task' /> : null}
      <DynamicActivationTrigger type='task' />
      {isDefault ? <TriggerOr type='task' /> : null}
      <DynamicDeadlineTrigger type='task' />
    </Flex>
  );
};

TaskTriggerSetting.displayName = 'sc/L/lc/c/ctf/tts/TaskTriggerSetting';

export default TaskTriggerSetting;
