import React, { FC } from 'react';
import { DashboardContainer } from './../../sub-components';
import { NotificationSettings } from '../../sub-components/NotificationCenter/Settings';

const NotificationCenter: FC = () => {
  return (
    <DashboardContainer>
      <NotificationSettings />
    </DashboardContainer>
  );
};

export default NotificationCenter;
