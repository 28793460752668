import React, { FC } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { useUserDataSelector } from '../../../../hooks';
import { AuthRole } from '../../../../authorization';
import { AUDIT_LIST } from '../../../../appRoutes';

import { BetaTag, SidebarBaseItem } from '../../common';

import { ReactComponent as AuditIcon } from '../../../../assets/images/sidebar/audit.svg';

const AuditTitle: FC = () => {
  const { t } = useTranslation(['sidebar']);
  return (
    <>
      <span>{t('sidebar:audits')}</span>
      <BetaTag />
    </>
  );
};

const QUERY = gql`
  query AuditAssignedCount($filter: FilterFindManyAuditSessionInput) {
    AuditSessionPagination(filter: $filter) {
      count
    }
  }
`;

interface Response {
  AuditSessionPagination: { count: number };
}

interface IProps {
  isSelected: boolean;
}

const SidebarAudit: FC<IProps> = ({ isSelected }) => {
  const isAdmin = useUserDataSelector(
    (state) => state.type === 'user' && state.authRole === AuthRole.ADMIN
  );

  const { data } = useQuery<Response>(QUERY, {
    skip: !isAdmin,
    variables: {
      filter: {
        isAssigned: true,
      },
    },
  });

  const onClick = () => {
    if (isAdmin) {
      if (!data?.AuditSessionPagination?.count) {
        sessionStorage.setItem('auditRootTab', '1');
      } else {
        sessionStorage.setItem('auditInitialTab', 'myAudits');
      }
    }
  };

  return (
    <SidebarBaseItem
      icon={AuditIcon}
      title={<AuditTitle />}
      link={AUDIT_LIST}
      isSelected={isSelected}
      onClick={onClick}
    />
  );
};

SidebarAudit.displayName =
  'sub-components/Sidebar/sections/resources/SidebarAudit';

export default SidebarAudit;
