import { LauncherConfig } from 'pages/launcher/config-editor/common';
import { ExtendedLauncherConfig } from '../store/project-detail-phase.types';
import { getPhaseDependencies } from './getPhaseDependencies';

type AssignmentType = 'location' | 'user';

interface BaseAssignment {
  assignmentType: AssignmentType;
  assignedUsers: string[];
}

interface PhaseAssignment extends BaseAssignment {
  phaseId: string;
  phaseTitle: string;
  tasks: TaskAssignment[];
}

interface TaskAssignment extends BaseAssignment {
  taskId: string;
  taskTitle: string;
}

export function processLauncherAssignments(
  launcherData: ExtendedLauncherConfig
): ExtendedLauncherConfig {
  const updatedContents = launcherData.contents.map((phase) => {
    const phaseAssignmentType = phase.assignedType || 'location';
    const phaseAssignedUsers =
      phase.assignedType === 'user' ? phase.assignedUser || [] : [];

    const updatedTasks = phase.tasks.map((task) => {
      const taskAssignmentType = task.assignedType || phaseAssignmentType;
      const assignedUsers = getAssignedUsers(task, phaseAssignedUsers);

      return {
        ...task,
        assignedType: taskAssignmentType,
        assignedUser: assignedUsers,
      };
    });

    return {
      ...phase,
      assignedType: phaseAssignmentType,
      assignedUser: phaseAssignedUsers,
      tasks: updatedTasks,
    };
  });

  return { ...launcherData, contents: updatedContents };
}

function getAssignedUsers(task: any, phaseAssignedUsers: string[]): string[] {
  if (task?.assignedType !== 'user') {
    return [];
  }

  const taskAssignedUsers = task.assignedUser || [];
  return taskAssignedUsers.length ? taskAssignedUsers : phaseAssignedUsers;
}
