import { Accordion, Divider, Flex } from '@chakra-ui/react';
import PhaseAccordionItem from './PhaseAccordionItem';
import PhaseAccordionFooter from './PhaseAccordionFooter';
import React, { FC } from 'react';
import { useProjectDetailPhaseContext } from '../store/context';

interface IProps {}

const AccordionSection: FC<IProps> = () => {
  const { phaseData } = useProjectDetailPhaseContext((state) => ({
    phaseData: state?.launcherData,
  }));

  return (
    <Accordion allowMultiple allowToggle>
      {phaseData?.contents?.map((phase, index) => (
        <Flex key={phase?.eid} flexDir='column'>
          <PhaseAccordionItem eid={phase?.eid} index={index} />
          <Divider my={4} />
        </Flex>
      ))}
      <PhaseAccordionFooter />
    </Accordion>
  );
};

AccordionSection.displayName = 'p/LN/C/PDP/c/AccordionSection';

export default AccordionSection;
