import { Box, Flex, Image, Text } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import timer from 'assets/images/dashboard/timer.svg';
import { LocationPhaseDetails, PhaseTask } from '../LauncherLocation.graphql';
import { getPhaseAssignee, getPhaseDependency } from '../helper';
import PhaseAssigneeList from '../PhaseAssigneeList';
import lockedTaskIcon from 'assets/images/tasks/lock.svg';
import LauncherLocationTask from '../LauncherLocationTask';
import { LTaskCategoryEntity } from 'sub-components/Launcher/launcher-config/common/launcher-config.graphql';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'authorization';
import { statusOrder } from './GroupByStatus';

interface IProps {
  keyName: string;
  phase: LocationPhaseDetails;
  getAssigneeDetailsById: (userId: string) => {
    name: string;
    role: string;
  };
  launchId?: string;
  groupedTasks: GroupedTask;
  locationId: string;
  categories: LTaskCategoryEntity[];
  phaseDependencyDetails: PhaseDependencyDetails | undefined;
  refetchData?: () => void;
  phaseDetails: LocationPhaseDetails[];
}

export interface PhaseDependencyDetails {
  dependencyText: string;
  dependencyValue: string;
  isLocked: boolean;
  targetDate: string;
}

export interface GroupedTask {
  [key: string]: {
    tasks: PhaseTask[];
    phaseId: string;
    phaseStartDate: Date;
    phaseTrigger: any;
    phase: LocationPhaseDetails;
    goLiveDate: Date;
  };
}

export const reorderTasks = (tasksArray: any) => {
  return tasksArray.sort((a, b) => {
    const statusA = statusOrder.indexOf(a.status.toLowerCase());
    const statusB = statusOrder.indexOf(b.status.toLowerCase());
    return statusA - statusB;
  });
};

const GroupByPhase: FC<IProps> = ({
  keyName,
  phase,
  getAssigneeDetailsById,
  groupedTasks,
  launchId,
  locationId,
  categories,
  phaseDependencyDetails,
  refetchData,
  phaseDetails,
}) => {
  const phaseAssignee = getPhaseAssignee(phase);
  const { currentUserId, currentUserAuthRole } = useUserDataSelector(
    (state) => ({
      currentUserId: state.eid,
      currentUserAuthRole: state.authRole,
    })
  );

  const shouldShowPhase = () => {
    if (
      currentUserAuthRole === AuthRole.SUPER_ADMIN ||
      currentUserAuthRole === AuthRole.ADMIN
    ) {
      return true;
    }

    const currentPhase = groupedTasks[keyName].phase;
    const currentUserTasks = groupedTasks[keyName].tasks.filter(
      (task) =>
        task.assignedUser?.includes(currentUserId) ||
        (task.assignedType === 'location' &&
          currentUserAuthRole === AuthRole.LOCATION_OWNER)
    );

    if (currentUserTasks.length === 0) return false;

    if (
      (currentPhase.assignedType === 'location' &&
        currentUserAuthRole === AuthRole.LOCATION_OWNER) ||
      currentPhase.tasks.some(
        (task) =>
          task.assignedUser?.includes(currentUserId) ||
          task.assignedType === 'location'
      )
    ) {
      return true;
    }

    return false;
  };

  return shouldShowPhase() ? (
    <Flex flexDirection='column' gap={4}>
      <Flex justifyContent='space-between' alignItems='center'>
        <Flex gap={3}>
          {phaseDependencyDetails?.isLocked && (
            <Image
              src={lockedTaskIcon}
              width='24px'
              height='24px'
              marginTop={2}
            />
          )}
          <Flex flexDirection='column' gap={1}>
            <Flex alignItems='center' gap={3}>
              <Text
                color='#111315'
                fontSize={16}
                fontWeight={600}
                lineHeight='24px'
                maxWidth='40rem'
              >
                {keyName}
              </Text>

              {/* Phase assignee */}
              {phaseAssignee.hasPhaseAssignee && (
                <Flex
                  borderRadius='full'
                  border='2px solid #EEEEEE'
                  alignItems='center'
                  paddingLeft='10px'
                  gap={4}
                >
                  <Text fontSize={11} fontWeight={500} color='#33383F'>
                    {
                      getAssigneeDetailsById(phaseAssignee.phaseAssignee[0])
                        .name
                    }
                  </Text>

                  <PhaseAssigneeList
                    assignedUser={phaseAssignee.phaseAssignee}
                    assignedType={phaseAssignee.phaseAssigneeType}
                  />
                </Flex>
              )}
            </Flex>
            <Box
              as='span'
              color='#111315'
              fontSize={12}
              fontWeight={500}
              lineHeight='12px'
            >
              {phaseDependencyDetails?.dependencyText}{' '}
              {phaseDependencyDetails?.dependencyValue}
            </Box>
          </Flex>
        </Flex>
        {phaseDependencyDetails?.targetDate && (
          <Flex
            gap={2}
            border='2px solid #EFEFEF'
            borderRadius='8px'
            padding='8px 10px'
            alignItems='center'
            background='#FCFCFC'
          >
            <Image src={timer} height='30px' width='30px' />
            <Flex flexDirection='column'>
              <Box as='span' fontSize={12} fontWeight={500} color='#6F767E'>
                Target date
              </Box>
              <Box as='span' fontSize={14} fontWeight={600} color='#1A1D1F'>
                {phaseDependencyDetails?.targetDate}
              </Box>
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex flexDirection='column' gap={2}>
        {reorderTasks(groupedTasks[keyName]?.tasks || []).map((task, index) => (
          <LauncherLocationTask
            key={index}
            task={task}
            locationId={locationId}
            launchId={launchId}
            categories={categories}
            refetchData={refetchData}
            phaseDetails={phaseDetails}
            goLiveDate={groupedTasks[keyName].goLiveDate}
          />
        ))}
      </Flex>
    </Flex>
  ) : null;
};

GroupByPhase.displayName =
  'displayName:sub-components/Launcher/dashboard/Location/GroupBy/GroupByPhase';
export default GroupByPhase;
