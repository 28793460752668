import React from 'react';
import { forwardRef } from '@chakra-ui/react';
import { Dropdown, SelectOption } from '../../../../../../atoms';
import { useConfigPhaseOptions } from './useConfigPhaseOptions';

const toValue = (value?: string): SelectOption | null => {
  if (value) {
    return { value: value, label: '' };
  }
  return null;
};

interface IProps {
  isDisabled?: boolean;
  value?: string;
  onChange?: (...event: any[]) => void;
}

const TaskPhaseSelect = forwardRef<IProps, any>((props, ref) => {
  const phaseList = useConfigPhaseOptions();

  return (
    <Dropdown
      size='lg'
      {...props}
      value={toValue(props.value)}
      onChange={(newValue) => props.onChange?.(newValue.value)}
      getOptionLabel={(option) => {
        if (!option?.label && option?.value) {
          return phaseList.find((o) => o.value === option?.value)?.label;
        }
        return option?.label;
      }}
      ref={ref}
      options={phaseList}
      placeholder='Select phase'
      selectStyles={{
        container: { flex: 2 },
        singleValue: {
          fontSize: '14px',
          fontWeight: '600',
          color: '#6F767E',
        },
        dropdownIndicator: {
          color: '#6F767E',
        },
      }}
      inputStyle={{
        borderWidth: '2px',
        borderRadius: '12px',
        outline: 'none',
        backgroundColor: 'white',
      }}
    />
  );
});

TaskPhaseSelect.displayName = 'sc/L/lc/c/ctf/c/TaskPhaseSelect';

export default TaskPhaseSelect;
