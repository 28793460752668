import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { LOCATION_LAUNCH_TASK_BY_ID } from 'sub-components/Launcher/tasks/components/task-details/api';
import { ADD_LAUNCHER_TASK_FILES } from 'sub-components/LocationLaunchDetail/OpeningTask/TaskListing/TaskDetailDrawer/task-detail-drawer.graphql';
import {
  AddLauncherTaskFilesResponse,
  AddLauncherTaskFilesVariables,
  FileInputEntity,
} from 'sub-components/LocationLaunchDetail/OpeningTask/TaskListing/TaskDetailDrawer/task-detail-drawer.types';
import { FileTypeEntity } from 'types';

interface IProps {
  file: FileTypeEntity[];
  launchId: string;
  taskId: string;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useAddLauncherTaskFiles = () => {
  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });
  const [addLauncherTaskFiles] = useMutation<
    AddLauncherTaskFilesResponse,
    AddLauncherTaskFilesVariables
  >(ADD_LAUNCHER_TASK_FILES);

  const addFilesToTask = ({
    file,
    launchId,
    taskId,
    onSuccess,
    onError,
  }: IProps) => {
    const fileObj: FileInputEntity[] = file?.map((f) => ({
      fileSize: f?.fileSize,
      mimetype: f?.mimetype || '',
      name: f?.name,
      type: f?.type,
      url: f?.url,
      createdBy: f?.createdBy?.eid,
    }));
    addLauncherTaskFiles({
      variables: {
        addLauncherTaskFilesInput2: {
          files: fileObj,
          launchId,
          taskId,
        },
      },
    })
      ?.then((res) => {
        if (res?.data) {
          onSuccess?.();
        }
      })
      ?.catch((err) => {
        onError?.();
      });
  };

  return addFilesToTask;
};
