import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AlertDialogCloseButton,
  Box,
  Center,
  Flex,
  Image,
} from '@chakra-ui/react';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { BoxHeader, useConfirm } from 'ui-components';
import ModalContent from './ModalContent';
import BulbIcon from 'assets/images/bulb.svg';

interface IProps {
  onOkPress?: (...args: any[]) => any | PromiseLike<any>;
  onCancelPress?: (...args: any[]) => any | PromiseLike<any>;
}

type IStillConfused = (props: IProps) => void;

export const useStillConfused = (): IStillConfused => {
  const { t } = useTranslation(['common', 'invite']);
  const confirm = useConfirm();

  return useCallback(
    ({ onOkPress, onCancelPress }) => {
      confirm({
        title: (
          <Flex justify='space-between'>
            <Flex align='center' gap='10px'>
              <Center bg='#8E59FF' borderRadius='50%' boxSize='38px' p='4px'>
                <Image src={BulbIcon} />
              </Center>
              <Box fontSize='16px' fontWeight={600}>
                Editing assigned tasks and phases
              </Box>
              <Box
                p='4px 8px'
                borderRadius='6px'
                fontSize='14px'
                bg='#8E59FF'
                color='white'
                fontWeight={600}
              >
                NEW!
              </Box>
            </Flex>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
              onClick={() => onCancelPress?.()}
            />
          </Flex>
        ),
        content: <ModalContent />,
        isCentered: true,
        size: 'sm',
        contentProps: {
          minW: '625px',
          borderRadius: '12px',
        },
        headerProps: {
          p: '16px 16px 0px 16px',
        },
        bodyProps: {
          p: '16px',
        },
        okText: null,
        cancelText: null,
        footer: null,
      });
    },
    [confirm]
  );
};
