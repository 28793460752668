import { LocationStatusOption } from './static-data';
import { IFormInput } from './add-location.types';

export const getDefaultFormData = (): Partial<IFormInput> => {
  return {
    // Basic details ==
    // locationType: undefined,
    locationStatus: LocationStatusOption[0],
    // licenceDocument: undefined,
    locationName: '',

    // Entity details ==
    // entityType: '',
    taxPayerId: '',

    // Address ==
    address: '',
    zipCode: '',
    country: '',
    city: '',
    state: '',
    // timezone: '',

    // Contact ==
    locationEmail: [{ email: '' }],
    locationPhone: [{ phone: '' }],

    checkListData: [],

    // Login Details ==
    username: '',
    password: '',
    confirmPassword: '',

    locationOwners: [],
    // locationOwners: [
    //   {
    //     owner: '' as never,
    //   },
    // ],

    currentTab: 0,
    loading: false,
    estimatedGoLiveDate: new Date(),
  };
};
