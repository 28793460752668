import { createContext, FC, useContext, useEffect, useRef } from 'react';
import { useApolloClient } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';
import { useShallow, useStore } from '../../../../zustandStore';
import { useHistory } from '../../../../routes';
import { eventBus } from '../../../../shared/eventEmit';

import {
  createLauncherConfigStore,
  LauncherConfigAction,
  LauncherConfigStore,
} from './launcher-config.store';
import {
  LAUNCHER_CONFIG,
  LauncherConfigResponse,
} from './launcher-config.graphql';
import {
  fetchTaskCategories,
  getLauncherConfig,
  mapConfigToObject,
} from './context.helper';
import ConfigNavigationAlert from './ConfigNavigationAlert';
import EditorInitialSetup from './EditorInitialSetup';

const LauncherConfigContext = createContext<LauncherConfigStore | null>(null);

const LauncherConfigProvider: FC = ({ children }) => {
  const storeRef = useRef<LauncherConfigStore>();
  const client = useApolloClient();

  const history = useHistory();
  const locationState = useLocation().state as any;
  const boardId = useParams<{ boardId?: string }>().boardId;

  if (!storeRef.current) {
    storeRef.current = createLauncherConfigStore();
  }
  useEffect(() => {
    const remove = eventBus.on('GET_CONFIG', (callback) => {
      callback(storeRef.current?.getState());
    });

    return () => remove();
  }, []);

  useEffect(() => {
    storeRef.current?.setState((state) => {
      state.config = {} as never;
      state.configMapObject = {};
      state.taskCategory = {};
      state.isConfigDirty = false;
      state.initializing = true;
      state.taskForm = undefined;
      state.selectedSection = undefined;
      state.addNewSection = false;
    });

    fetchTaskCategories(client, storeRef);

    if (boardId) {
      client
        .query<LauncherConfigResponse>({
          query: LAUNCHER_CONFIG,
          fetchPolicy: 'network-only',
          variables: {
            eid: boardId,
          },
        })
        .then((response) => {
          if (response.data) {
            storeRef.current?.setState((state) => {
              // @ts-ignore
              state.config = getLauncherConfig(response.data.LauncherById);
            });
          }
          if (response.error) {
            // eslint-disable-next-line no-console
            console.log(response.error);
            // TODO Add error handler
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          // TODO Add error handler
        })
        .finally(() => {
          storeRef.current?.setState((state) => {
            state.initializing = false;
          });
        });
    } else {
      if (locationState?.templatedData) {
        storeRef.current?.setState((state) => {
          // @ts-ignore
          state.config = getLauncherConfig(locationState?.templatedData);
          state.isConfigDirty = true;
          state.initializing = false;
        });

        if (history.location.search?.includes('viewMode=editor')) {
          storeRef.current?.setState((state) => {
            state.selectedSection = 0;
            state.openTaskForm({
              sectionIndex: 0,
              taskIndex: -1,
            });
          });
        }
      } else {
        history.goBack();
      }
    }
  }, [boardId]);

  return (
    <LauncherConfigContext.Provider value={storeRef.current}>
      {children}
      <EditorInitialSetup />
      <ConfigNavigationAlert />
    </LauncherConfigContext.Provider>
  );
};

function useLauncherConfigContext<T>(
  selector: (state: LauncherConfigAction) => T
): T {
  const store = useContext(LauncherConfigContext);
  if (!store)
    throw new Error('Missing LauncherConfigContext.Provider in the tree');

  return useStore(store, useShallow(selector));
}

export { LauncherConfigProvider, useLauncherConfigContext };
