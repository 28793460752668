import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  DefaultTriggerItem,
  NewCustomTriggerInfo,
  TriggerOr,
} from '../../trigger';

import {
  TaskTriggerInput,
  useTaskTriggerSetting,
} from '../task-trigger-setting';
import { LauncherTrigger } from '../../launcher-config.fragment';
import DeadlineData from './DeadlineData';
import ActivationData from './ActivationData';
import SwitchToTaskTrigger from './SwitchToTaskTrigger';
import { Button } from '../../../../../../atoms';
import { useLauncherAlert } from '../../useLauncherAlert';

interface IProps {
  sectionIndex: number;
  taskIndex: number;
}

const ActivationDetails: FC<IProps> = ({ sectionIndex, taskIndex }) => {
  const launcherAlert = useLauncherAlert();
  const addTrigger = useTaskTriggerSetting();
  const value = useWatch<LauncherTrigger, 'trigger.triggerChoice'>({
    name: 'trigger.triggerChoice',
  });

  const phaseTrigger = useWatch({ name: 'phase.trigger' });
  const title = useWatch({ name: 'title' });

  const { getValues, setValue } = useFormContext<TaskTriggerInput>();

  const isDefault = !value || value === 'default';

  const taskName = `Phase ${sectionIndex + 1} Task ${taskIndex + 1}: ${title}`;

  const onResetClick = () => {
    launcherAlert({
      title: 'Please note',
      description: (
        <div>
          All your trigger settings for this task will be{' '}
          <strong>reset to default activation date or deadline.</strong>
        </div>
      ),
      buttonText: 'Erase task triggers',
      onConfirm: () => {
        setValue(
          'trigger',
          {
            triggerChoice: 'default',
            activationTrigger: null,
            deadlineTrigger: null,
          },
          {
            shouldDirty: true,
          }
        );
      },
    });
  };

  if (isDefault) {
    if (
      phaseTrigger?.triggerChoice &&
      phaseTrigger.triggerChoice !== 'default'
    ) {
      return (
        <Flex flexDir='column' gap={6}>
          <SwitchToTaskTrigger
            trigger={phaseTrigger}
            onAddClick={() => {
              addTrigger({
                taskName: taskName,
                defaultValue: {
                  eid: getValues('eid'),
                  trigger: getValues('trigger'),
                },
                onSaveChanges: (values) => {
                  setValue('trigger', values.trigger, {
                    shouldDirty: true,
                  });
                },
              });
            }}
          />
        </Flex>
      );
    }

    return (
      <Flex flexDir='column' gap={6}>
        <DefaultTriggerItem
          label='Default Activation date'
          value='Project Start Date'
        />

        <DefaultTriggerItem label='Default Deadline' value='Go-live date' />

        <NewCustomTriggerInfo
          type='task'
          onAddClick={() => {
            addTrigger({
              taskName: taskName,
              defaultValue: {
                eid: getValues('eid'),
                trigger: getValues('trigger'),
              },
              onSaveChanges: (values) => {
                setValue('trigger', values.trigger, {
                  shouldDirty: true,
                });
              },
            });
          }}
        />
      </Flex>
    );
  }

  return (
    <Flex flexDir='column' gap={3}>
      <Box fontSize='16px' fontWeight={600} color='#33383F' marginBottom={3}>
        Activation and Deadline details{' '}
        <Box as='span' fontSize='14px' color='#6F767E'>
          (Optional)
        </Box>
      </Box>

      <ActivationData taskName={taskName} />

      <TriggerOr type='phase' />

      <DeadlineData taskName={taskName} />

      <Button colorScheme='red' borderRadius='12px' onClick={onResetClick}>
        Reset to default
      </Button>
    </Flex>
  );
};

ActivationDetails.displayName = 'sc/L/lc/c/ctf/ad/ActivationDetails';

export default ActivationDetails;
