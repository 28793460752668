import React, { FC } from 'react';
import { Box, Center, Flex, Spacer } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../../../../ui-components';
// import { useHistory } from '../../../../routes';
import { BoardNameButton, useLauncherConfigContext } from '../common';
import { useHistory } from 'react-router-dom';

interface IProps {}

const EditorViewHeader: FC<IProps> = () => {
  const { t } = useTranslation(['launcher']);
  const history = useHistory();

  return (
    <Flex align='center' mb={5} gap={3}>
      <Center
        height='36px'
        alignSelf='start'
        onClick={history.goBack}
        cursor='pointer'
      >
        <ArrowBackIcon color='#000000' boxSize='25px' />
      </Center>
      <Box>
        <Box fontSize='24px' fontWeight='700' color='#272B30'>
          Adding task details
        </Box>
        <Box fontSize='15px' fontWeight='400' color='#6F767E'>
          Now add finer details to your tasks with ease.
        </Box>
      </Box>

      <Spacer />

      <BoardNameButton />

      <ActionButton
        size='lg'
        variant='outline'
        fontWeight='700'
        colorScheme='blue'
        borderColor='transparent'
        bg='white'
        _hover={{
          background: 'whiteAlpha.700',
        }}
        _active={{
          background: 'whiteAlpha.600',
        }}
        actionFn={() => history.replace('?viewMode=board')}
        // actionFn={onViewModeChange}
      >
        {t('launcher:switchToBoardView')}
      </ActionButton>
    </Flex>
  );
};

EditorViewHeader.displayName = 'sc/L/lc/ev/EditorViewHeader';

export default EditorViewHeader;
