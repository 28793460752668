import React, { FC, useMemo } from 'react';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  useControllableState,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSafeState } from '../../../../../../hooks';
import { SearchInput } from '../../../../../../atoms';
import { IconImage, RoleAndAuthRole } from '../../../../../../ui-components';
import { useAssigneeUsers } from '../../../common/add-assignee';

interface IProps {
  value?: string[];
  onChange?: (value: string[]) => void;
}

const TaskAssigneeList: FC<IProps> = ({ value, onChange }) => {
  const { t } = useTranslation('launcher');
  const [query, updateQuery] = useSafeState('');
  const categories = useAssigneeUsers();

  const [internalValue, updateValue] = useControllableState({
    value: value,
    onChange: onChange,
  });

  const filterList = useMemo(() => {
    if (!query) return categories;
    return categories.filter((category) =>
      category.label.toLowerCase().includes(query.toLowerCase())
    );
  }, [categories, query]);

  return (
    <Flex
      flexDir='column'
      border='1px solid #EFEFEF'
      bg='#FCFCFC'
      borderRadius='10px'
      padding='12px 16px'
      gap='10px'
    >
      <SearchInput
        hideShortcuts
        placeholder={t('searchByName')}
        onChange={(ev) => updateQuery(ev.target.value)}
      />

      <CheckboxGroup value={internalValue} onChange={updateValue as never}>
        {filterList.map((assignee) => (
          <React.Fragment key={assignee.value}>
            <Flex align='center' gap='10px'>
              <IconImage
                name={assignee.value}
                thumbnail={assignee.profilePic}
                boxSize={40}
                borderRadius='40px'
              />
              <Flex flex={1} flexDir='column' overflow='hidden'>
                <Box fontSize='14px' fontWeight='600' isTruncated>
                  {assignee.label}
                </Box>
                <RoleAndAuthRole
                  role={assignee.role}
                  authRole={assignee.authRole}
                  roleProps={{ color: 'inherit' }}
                />
              </Flex>
              <Checkbox value={assignee.value} size='lg' />
            </Flex>
            <Divider borderColor='#EFEFEF' _last={{ display: 'none' }} />
          </React.Fragment>
        ))}
      </CheckboxGroup>
    </Flex>
  );
};

export default TaskAssigneeList;
