import React, { FC } from 'react';
import { Box, CloseButton, Flex, useModalContext } from '@chakra-ui/react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import TitleHeader from '../../../../../CardEditor/TitleHeader';
import { LauncherTrigger } from '../../launcher-config.fragment';
import TaskTriggerSetting from './TaskTriggerSetting';
import SaveChangeButton from '../../phase-details/SaveChangeButton';
import { Button } from '../../../../../../atoms';
import { useLauncherAlert } from '../../useLauncherAlert';

interface IProps {
  taskName: string;
  defaultValue: LauncherTrigger;
  onSaveChanges: (data: LauncherTrigger) => void;
}

const TriggerSettings: FC<IProps> = ({
  defaultValue,
  onSaveChanges,
  taskName = '',
}) => {
  const { onClose } = useModalContext();
  const launcherAlert = useLauncherAlert();

  const methods = useForm<LauncherTrigger>({
    defaultValues: defaultValue,
  });

  const handleSubmit = (values: LauncherTrigger) => {
    console.log(values);
    onSaveChanges(values);
  };

  const [indexData, taskTitle] = taskName?.split(':');

  const value = useWatch({
    control: methods.control,
    name: 'trigger.triggerChoice',
  });

  const isDefault = !value || value === 'default';

  const onResetClick = () => {
    launcherAlert({
      title: 'Please note',
      description: (
        <div>
          All your trigger settings for this task will be{' '}
          <strong>reset to default activation date or deadline.</strong>
        </div>
      ),
      buttonText: 'Erase task triggers',
      onConfirm: () => {
        methods.setValue(
          'trigger',
          {
            triggerChoice: 'default',
            activationTrigger: null,
            deadlineTrigger: null,
          },
          {
            shouldDirty: true,
          }
        );
      },
    });
  };

  return (
    <Flex
      width='50%'
      gap='20px'
      flexDir='column'
      borderRightRadius='12px'
      padding='24px 32px'
    >
      <CloseButton
        alignSelf='flex-end'
        size='sm'
        colorScheme='blue'
        borderRadius='full'
        onClick={(event) => {
          event.stopPropagation();
          onClose();
        }}
      />

      <FormProvider {...methods}>
        <Flex
          flexDir='column'
          padding={4}
          gap={2}
          bgColor='#B1E5FC33'
          borderRadius='12px'
          marginTop='20px'
        >
          <Box fontSize='13px' fontWeight='600' color='#33383F'>
            Task Details
          </Box>
          <Flex fontSize='14px' gap='6px'>
            <Box as='span' fontWeight='500' color='#6F767E' whiteSpace='nowrap'>
              {indexData}:
            </Box>
            <Box as='span' fontWeight='700' color='#33383F'>
              {taskTitle || '--'}
            </Box>
          </Flex>
        </Flex>

        <Flex justify='space-between' align='center'>
          <Box>
            <TitleHeader
              title='Activation and Deadline details'
              desc='Specify when the assignee can access this task and its completion deadline'
            />
          </Box>
          <Button
            size='sm'
            variant='outline'
            fontSize='12px'
            onClick={onResetClick}
            isDisabled={isDefault}
          >
            Reset to default
          </Button>
        </Flex>

        <TaskTriggerSetting />

        <SaveChangeButton
          onDiscardClick={() => methods.reset()}
          onClick={methods.handleSubmit(handleSubmit)}
        />
      </FormProvider>
    </Flex>
  );
};

TriggerSettings.displayName = 'sc/L/lc/c/ctf/tts/TriggerSettings';

export default TriggerSettings;
