import { Badge, Box, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

interface IProps {
  text: string;
  index: number;
}

const FormType: FC<IProps> = ({ text, index }) => {
  const colorSetHandler = (index: number) => {
    let colorObj = ['#cbecda', '#ffe8bb', '#b1e5fc'];
    return colorObj[index % 3];
  };

  return (
    <Badge
      whiteSpace={'unset'}
      style={{
        blockSize: 'fit-content',
        textTransform: 'capitalize',
        backgroundColor: colorSetHandler(index),
        padding: '4px 6px',
        borderRadius: '4px',
        fontWeight: '600',
      }}
    >
      {text}
    </Badge>
  );
};

export default FormType;
