import React, { FC, useMemo, useState } from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  Text,
  useMergeRefs,
} from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { gql, useApolloClient } from '@apollo/client';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';

import { Dropdown, FormInput } from 'atoms';
import { BoxHeader } from 'ui-components';
import { Loader } from 'sub-components';
import { GOOGLE_MAPS_API_KEY } from 'utils/constants';
import { toArray } from 'utils/utils';

import TitleHeader from 'sub-components/CardEditor/TitleHeader';

import { IFormInput } from '../AddLocation/add-location.types';
import { ADD_PRE_LAUNCH_LOCATION } from 'appRoutes';
import {
  getLocationByZipCode,
  getTimezoneForLocation,
} from 'shared/graphql/SharedGraphql';

const getAddress = (data: Record<string, string>) => {
  const address: string[] = [];
  if (data.street_number) {
    address.push(data.street_number);
  }
  if (data.route) {
    address.push(data.route);
  }
  if (data.sublocality) {
    address.push(data.sublocality);
  }
  return address.join(', ');
};

interface TimeZoneData {
  dstOffset: number;
  rawOffset: number;
  status: string;
  timeZone: string;
  timeZoneId: string;
  timeZoneName: string;
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface IProps {
  isDisabled?: boolean;
}

const Address: FC<IProps> = ({ isDisabled }) => {
  const { t } = useTranslation(['location']);

  const client = useApolloClient();

  const [isFetching, setIsFetching] = useState(false);

  const { control, getValues, setValue, watch } = useFormContext<IFormInput>();
  const zipCode = watch('zipCode');

  const isPreLaunch =
    location?.pathname === ADD_PRE_LAUNCH_LOCATION ||
    getValues('locationStatus.value') === 'preLaunch';

  const timezonesDataWatch = useWatch<IFormInput, 'timezonesData'>({
    control,
    name: 'timezonesData',
  });

  const timezoneOptions = useMemo(() => {
    return timezonesDataWatch?.map((t) => ({ label: t, value: t })) || [];
  }, [timezonesDataWatch]);

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_MAPS_API_KEY,
    onPlaceSelected: async (place) => {
      const newAddress = toArray<AddressComponent>(
        place?.address_components
      ).reduce<Record<string, string>>((previousValue, currentValue) => {
        (currentValue.types || []).forEach((value) => {
          if (value !== 'political' && !previousValue[value]) {
            previousValue[value] = currentValue.long_name;
          }
        });

        return previousValue;
      }, {});

      setValue('state', newAddress.administrative_area_level_1);
      setValue('city', newAddress.locality);
      setValue('zipCode', newAddress.postal_code);
      setValue('address', getAddress(newAddress));
      setIsFetching(true);

      const location = place?.geometry?.location;
      try {
        const timezoneData = await getTimezoneForLocation(client, {
          lat: location?.lat()?.toString(),
          lng: location?.lng()?.toString(),
        });
        if (timezoneData) {
          setValue('timezone', timezoneData?.value);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsFetching(false);
      }
    },
    options: {
      // types: ['(regions)'],
      types: ['address'],
    },
  });
  const fetchTimezoneForCoordinates = async (lat: string, lng: string) => {
    setIsFetching(true);
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/timezone/json?key=${GOOGLE_MAPS_API_KEY}&location=${lat},${lng}&timestamp=${Math.floor(
          Date.now() / 1000
        )}`
      );
      const data = await response.json();

      if (data.status === 'OK') {
        const exists = timezoneOptions.some(
          (option) => option.value === data.timeZoneId
        );

        if (exists) {
          setValue('timezone', data.timeZoneId);
        }
      }
    } catch (error) {
      console.error('Error fetching timezones:', error);
    } finally {
      setIsFetching(false);
    }
  };

  const fetchLocationDataFromZipCode = async (zipCode: string) => {
    setIsFetching(true);
    try {
      const locationData = await getLocationByZipCode(client, zipCode);

      if (locationData) {
        setValue('city', locationData?.city || '');
        setValue('state', locationData?.state || '');

        const timezoneData = await getTimezoneForLocation(
          client,
          locationData.coordinates
        );
        if (timezoneData) {
          setValue('timezone', timezoneData?.value);
        }
      }
    } catch (error) {
      console.error('Error fetching location data:', error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Flex bg='white' borderRadius='8px' p={8} gap='20px' flexDir='column'>
      <BoxHeader
        fontSize='18px'
        color='#FFBC99'
        title={t('location:address')}
      />

      <Grid templateColumns='repeat(3, 1fr)' gap='20px'>
        <Controller
          control={control}
          name='address'
          rules={{
            required: {
              value: !isPreLaunch,
              message: t('location:validation.addressRequired'),
            },
          }}
          // rules={{
          //   required: t('location:validation.addressRequired'),
          //   validate: (value) => {
          //     if (value?.trim()?.length === 0) {
          //       return t('location:validation.addressInvalid');
          //     }
          //   },
          // }}
          render={({ field, fieldState }) => {
            return (
              <FormControl
                isDisabled={isDisabled}
                gridColumn='1/4'
                isInvalid={!!fieldState.error}
                isReadOnly={isFetching}
              >
                <Flex align='center'>
                  <TitleHeader
                    title={t('location:address')}
                    isRequired={!isPreLaunch}
                  />
                  {isPreLaunch && <Text>&nbsp;(Optional)</Text>}
                </Flex>
                <Flex height={2} />
                <FormInput
                  size='lg'
                  placeholder={t('location:placeholder.address')}
                  {...field}
                  ref={useMergeRefs(ref, field.ref)}
                  rightIcon={
                    isFetching ? (
                      <Loader size='sm' thickness='2px' />
                    ) : undefined
                  }
                />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        {/*<Controller*/}
        {/*  control={control}*/}
        {/*  name='timezone'*/}
        {/*  render={({ field, fieldState }) => {*/}
        {/*    return (*/}
        {/*      <FormControl isReadOnly isInvalid={!!fieldState.error}>*/}
        {/*        <TitleHeader title={t('location:timezone')} />*/}

        {/*        <FormInput mt={2} size='lg' {...field} />*/}
        {/*        <FormErrorMessage>*/}
        {/*          <span>{fieldState.error?.message}</span>*/}
        {/*        </FormErrorMessage>*/}
        {/*      </FormControl>*/}
        {/*    );*/}
        {/*  }}*/}
        {/*/>*/}

        <Controller
          control={control}
          name='state'
          rules={{
            required: {
              value: !isPreLaunch,
              message: t('location:validation.stateRequired'),
            },
          }}
          // rules={{
          //   required: {
          //     value: !isDisabled,
          //     message: t('location:validation.stateRequired'),
          //   },
          // }}
          render={({ field, fieldState }) => {
            return (
              <FormControl isInvalid={!!fieldState.error}>
                <Flex align='center'>
                  <TitleHeader
                    title={t('location:state')}
                    isRequired={!isPreLaunch}
                  />
                  {isPreLaunch && <Text>&nbsp;(Optional)</Text>}
                </Flex>

                <FormInput
                  mt={2}
                  size='lg'
                  placeholder={t('location:placeholder.addressState')}
                  {...field}
                />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='city'
          rules={{
            required: {
              value: !isPreLaunch,
              message: t('location:validation.cityRequired'),
            },
          }}
          // rules={{
          //   required: {
          //     value: !isDisabled,
          //     message: t('location:validation.cityRequired'),
          //   },
          // }}
          render={({ field, fieldState }) => {
            return (
              <FormControl isInvalid={!!fieldState.error}>
                <Flex align='center'>
                  <TitleHeader
                    title={t('location:city')}
                    isRequired={!isPreLaunch}
                  />
                  {isPreLaunch && <Text>&nbsp;(Optional)</Text>}
                </Flex>

                <FormInput
                  mt={2}
                  size='lg'
                  placeholder={t('location:placeholder.addressCity')}
                  {...field}
                />

                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='zipCode'
          rules={{
            required: {
              value: !isPreLaunch,
              message: t('location:validation.zipCodeRequired'),
            },
            // required: {
            //   value: !isDisabled,
            //   message: t('location:validation.zipCodeRequired'),
            // },
            validate: (value) => {
              if (value && value?.trim()?.length === 0) {
                return t('location:validation.zipCodeInvalid');
              }
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl
                isDisabled={isDisabled}
                isInvalid={!!fieldState.error}
              >
                <Flex align='center'>
                  <TitleHeader
                    title={t('location:zipCode')}
                    isRequired={!isPreLaunch}
                  />
                  {isPreLaunch && <Text>&nbsp;(Optional)</Text>}
                </Flex>

                <FormInput
                  mt={2}
                  size='lg'
                  placeholder={t('location:placeholder.addressZipCode')}
                  {...field}
                  onBlur={async (e) => {
                    field.onBlur();
                    if (e.target.value) {
                      await fetchLocationDataFromZipCode(e.target.value);
                    }
                  }}
                />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />
        <Controller
          control={control}
          name='timezone'
          rules={{
            required: {
              value: !isPreLaunch,
              message: 'Timezone is required',
            },
          }}
          render={({ field, fieldState }) => (
            <FormControl gridColumn='1/4' isInvalid={!!fieldState.error}>
              <TitleHeader
                title={t('location:timezone')}
                isRequired={!isPreLaunch}
              />
              <Dropdown
                size='lg'
                isDisabled={isFetching || !zipCode}
                isLoading={isFetching}
                value={{ label: field?.value, value: field?.value }}
                placeholder='Select timezone'
                onChange={(value) => field.onChange(value?.value)}
                options={timezoneOptions?.map((option) => ({
                  label: option?.label,
                  value: option?.value,
                }))}
              />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          )}
        />
      </Grid>
    </Flex>
  );
};

Address.displayName = 'displayName:pages/Locations/Components/Address';

export default Address;
