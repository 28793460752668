import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { Box, Button, Flex, StylesProvider, Text } from '@chakra-ui/react';
import {
  components,
  MenuProps,
  MultiValue,
  OptionProps,
  Select,
  SelectInstance,
} from 'chakra-react-select';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { IconImage } from '../../../../ui-components';
import { AuthRole } from '../../../../authorization';
import { getChakraSelectStyles } from '../../../../atoms/Dropdown/dropdown.styles';

export interface SelectOption {
  label: string;
  value: string;
  authRole?: AuthRole;
  locations?: {
    eid: string;
    name: string;
  }[];
  role?: string;
  url?: string;
  status: string;
}

const Option: FC<OptionProps<SelectOption, true>> = (props) => {
  const { isSelected, data, children } = props;

  return (
    <components.Option {...props}>
      <Flex align='center'>
        <IconImage
          name={data.label}
          thumbnail={data.url}
          boxSize={20}
          borderRadius='50%'
        />
        <Text flex={1} noOfLines={1} px='8px' color='#272b30'>
          {children}
        </Text>
        {isSelected && (
          <FontAwesomeIcon
            icon={faCircleCheck as IconProp}
            color='#83BF6E'
            size='lg'
          />
        )}
      </Flex>
    </components.Option>
  );
};

interface IProps {
  members: SelectOption[];
  placeholder?: string;
  value?: string[];
  onChange: (newValue: string[]) => void;
  isLoading?: boolean;

  hideChipsImage?: boolean;
  chipsColor?: string;
}

const MemberSelection: FC<IProps> = ({
  placeholder,
  members,
  value = [],
  onChange,
  isLoading,
  hideChipsImage,
  chipsColor,
}) => {
  const { t } = useTranslation('common');

  const ref = useRef<SelectInstance<SelectOption, true>>(null);
  const valueRef = useRef<MultiValue<SelectOption>>([]);
  const [innerValue, setInnerValue] = useState<MultiValue<SelectOption>>([]);

  const setValue = (newValues: MultiValue<SelectOption>) => {
    setInnerValue(newValues);
    valueRef.current = newValues;
  };

  const records = useMemo(() => {
    return members?.reduce<{ [key: string]: SelectOption }>(
      (previousValue, currentValue) => {
        if (!previousValue[currentValue.value]) {
          previousValue[currentValue.value] = currentValue;
        }
        return previousValue;
      },
      {}
    );
  }, [members]);

  const onApplyPress = useCallback(() => {
    const newValue = valueRef.current || [];
    if (onChange) {
      onChange(newValue.map((val) => val.value));
    }
    ref.current?.blur();
  }, [onChange]);

  const Menu = useCallback(
    (menuProps: MenuProps<SelectOption, true>) => {
      return (
        <components.Menu {...menuProps}>
          {menuProps.children}
          <Box p={3}>
            <Button isFullWidth colorScheme='blue' onClick={onApplyPress}>
              {t('apply')}
            </Button>
          </Box>
        </components.Menu>
      );
    },
    [onApplyPress]
  );

  const selectedData = useMemo(() => {
    return value
      ?.map((it) => {
        if (typeof it === 'object') {
          return it;
        }
        return records[it];
      })
      .filter((t) => t);
  }, [records, value]);

  const onMenuOpen = () => {
    setValue(selectedData);
    setTimeout(() => {
      const elem = document.getElementById('dashboard-content')!;
      elem.scrollTo(0, elem.scrollHeight);
    });
  };

  const handleRemove = useCallback(
    (item: SelectOption) => {
      onChange(value.filter((val) => val !== item.value));
    },
    [value, onChange]
  );

  return (
    <StylesProvider value={{}}>
      <Select<SelectOption, true>
        isLoading={isLoading}
        ref={ref}
        placeholder={placeholder}
        chakraStyles={getChakraSelectStyles<SelectOption, true>({
          menuList: {
            padding: '12px',
          },
        })}
        onMenuOpen={onMenuOpen}
        options={members}
        value={innerValue}
        isMulti
        onChange={(newValue) => setValue(newValue)}
        styles={{
          option: (base, optionProps) => ({
            ...base,
            borderBottom: '1px solid #efefef',
            borderRadius:
              optionProps?.isSelected || optionProps?.isFocused ? 8 : 0,
            ':last-child': {
              borderBottom: 'none',
            },
            backgroundColor: optionProps?.isSelected
              ? '#2A85FF1F'
              : optionProps?.isFocused
              ? '#EEEEEE'
              : 'transparent',
          }),
        }}
        getOptionValue={(option) => option?.value}
        hideSelectedOptions={false}
        controlShouldRenderValue={false}
        closeMenuOnSelect={false}
        isClearable={false}
        getOptionLabel={(option) => option?.label}
        components={{
          Option,
          Menu,
        }}
      />
      {selectedData?.length > 0 && (
        <Flex align='center' gap='10px' wrap='wrap' mt='1rem'>
          {selectedData?.map((item) => {
            return (
              <Flex
                key={item?.value}
                align='center'
                bg={chipsColor || '#b1e5fc'}
                p='5px 6px'
                borderRadius='5px'
                gap='5px'
              >
                {hideChipsImage ? (
                  <Box w='3px' />
                ) : (
                  <IconImage
                    name={item?.label}
                    thumbnail={item?.url}
                    boxSize={24}
                    borderRadius='5px'
                  />
                )}
                <Text as='span' fontWeight='600' pr='3px' noOfLines={1}>
                  {item?.label}
                </Text>
                <Box
                  cursor='pointer'
                  fontSize='20px'
                  fontWeight='500'
                  onClick={() => handleRemove(item)}
                  px={1}
                >
                  &times;
                </Box>
              </Flex>
            );
          })}
        </Flex>
      )}
    </StylesProvider>
  );
};

export default MemberSelection;
