import { FC, useEffect } from 'react';
import { useLatest, useSearchParam } from '../../../../hooks';
import { useLauncherConfigContext } from './context';

type ViewMode = 'editor' | 'board';

interface IProps {}

const EditorInitialSetup: FC<IProps> = () => {
  const viewMode = useSearchParam<{ viewMode: ViewMode }>().viewMode;

  const {
    getConfig,
    openTaskForm,
    selectSection,
    closeTaskForm,
    selectedSection,
  } = useLauncherConfigContext((state) => ({
    getConfig: state.getConfig,
    openTaskForm: state.openTaskForm,
    selectSection: state.selectSection,
    closeTaskForm: state.closeTaskForm,
    selectedSection: state.selectedSection,
  }));

  const selectRef = useLatest(selectedSection);

  useEffect(() => {
    if (viewMode === 'editor') {
      if (getConfig().contents?.length) {
        const task = getConfig().contents?.[0]?.tasks?.[0];

        if (selectRef.current === undefined || selectRef.current) {
          selectSection(0);
        }

        if (task) {
          openTaskForm({ sectionIndex: 0, taskIndex: 0, taskEid: task.eid });
        } else {
          openTaskForm({ sectionIndex: 0, taskIndex: -1 });
        }
      }
    } else {
      closeTaskForm();
    }
  }, [viewMode]);

  return null;
};

export default EditorInitialSetup;
